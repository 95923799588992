import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { IFindingStructure } from '@/Interfaces/RegionalReport';

// SINGLE ACTIONS
const fetchCountryReportsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_REPORTS_FETCH_LOADING,
    payload,
  };
};

const getCountryReportsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_REPORTS_GET_LOADING,
    payload,
  };
};

const setCountryReportsActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_REPORTS_ACTION_LOADING,
    payload,
  };
};

const setCountryReportPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_REPORT_TABLE_PAGINATION,
    payload,
  };
};

const setCountryReportMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_REPORT_TABLE_META,
    payload,
  };
};

const saveDefaultFindings = (payload: IFindingStructure) => {
  return {
    type: ACTION_TYPES.SET_DATA_FINDINGS,
    payload,
  };
};

const resetCountryReportsReducer = () => {
  return {
    type: ACTION_TYPES.RESET_COUNTRY_REPORTS_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_REPORTS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_REPORTS_FAILURE,
  };
};

const fetchCountryReports = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(fetchCountryReportsLoading(true));
    await API.fetchCountryReports(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setCountryReportMeta(meta));
          dispatch(setCountryReportPagination(payload));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_COUNTRY_REPORT_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_COUNTRY_REPORT_FAILURE,
  };
};

const createCountryReport = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.createCountryReport(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
          Utils.redirect(ROUTERS.MY_REPORT);
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_BY_ID_COUNTRY_REPORT_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_BY_ID_COUNTRY_REPORT_FAILURE,
  };
};

const getCountryReportById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getCountryReportsLoading(true));
    await API.getCountryReportById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const getForVisitorSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FOR_VISITOR_COUNTRY_REPORT_SUCCESS,
    payload,
  };
};

const getForVisitorFail = () => {
  return {
    type: ACTION_TYPES.GET_FOR_VISITOR_COUNTRY_REPORT_FAILURE,
  };
};

const getForVisitorCountryReport = (
  payload: {
    countryId: string;
    year: string;
  },
  hiddenAlert?: boolean
) => {
  return async (dispatch: any) => {
    dispatch(getCountryReportsLoading(true));
    await API.getForVisitorCountryReport(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(
          response,
          true,
          hiddenAlert
        );
        if (!result) {
          await dispatch(getForVisitorFail());
        } else {
          const getForVisitor = _.get(result, 'main');
          const getPrevious = _.get(result, 'previous');
          const getCharts = _.get(result, 'chart');
          const getNews = _.get(getForVisitor, 'country.post');
          const resolved = _.map(getCharts, (el, key) => {
            return Utils.generateCountryReportBarChart(key, el);
          });
          await dispatch(
            getForVisitorSuccess({
              getForVisitor,
              getPrevious,
              getCharts: resolved,
              chartData: getCharts,
              getNews,
            })
          );
        }
      })
      .catch(async (error) => {
        if (!hiddenAlert) await Utils.resolveFailureResponse(error);
        await dispatch(getForVisitorFail());
      });
  };
};

const updateSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_COUNTRY_REPORT_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_COUNTRY_REPORT_FAILURE,
  };
};

const updateCountryReport = (
  id: string,
  payload: FormData,
  isSendToReviewer?: boolean
) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.updateCountryReport(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
          if (isSendToReviewer) Utils.redirect(ROUTERS.MY_REPORT);
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const publishSuccess = () => {
  return {
    type: ACTION_TYPES.PUBLISH_COUNTRY_REPORT_SUCCESS,
  };
};

const publishFail = () => {
  return {
    type: ACTION_TYPES.PUBLISH_COUNTRY_REPORT_FAILURE,
  };
};

const publishCountryReport = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.publishCountryReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(publishFail());
        else {
          dispatch(publishSuccess());
          dispatch(fetchCountryReports(payload));
          Utils.redirect(ROUTERS.COUNTRY_REPORT_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(publishFail());
      });
  };
};

const unPublishSuccess = () => {
  return {
    type: ACTION_TYPES.UNPUBLISH_COUNTRY_REPORT_SUCCESS,
  };
};

const unpublishFail = () => {
  return {
    type: ACTION_TYPES.UNPUBLISH_COUNTRY_REPORT_FAILURE,
  };
};

const unPublishCountryReport = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.unPublishCountryReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(unpublishFail());
        else {
          dispatch(unPublishSuccess());
          dispatch(fetchCountryReports(payload));
          Utils.redirect(ROUTERS.COUNTRY_REPORT_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(unpublishFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_COUNTRY_REPORT_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_COUNTRY_REPORT_FAILURE,
  };
};

const activeCountryReport = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.activeCountryReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(activeFail());
        else {
          await dispatch(activeSuccess());
          await dispatch(fetchCountryReports(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_COUNTRY_REPORT_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_COUNTRY_REPORT_FAILURE,
  };
};

const deactiveCountryReport = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.deactiveCountryReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(deactiveFail());
        else dispatch(deactiveSuccess());
        await dispatch(fetchCountryReports(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

const terminateSuccess = () => {
  return {
    type: ACTION_TYPES.TERMINATE_COUNTRY_REPORT_SUCCESS,
  };
};

const terminateFail = () => {
  return {
    type: ACTION_TYPES.TERMINATE_COUNTRY_REPORT_FAILURE,
  };
};

const terminateCountryReport = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setCountryReportsActionLoading(true));
    await API.terminateCountryReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(terminateFail());
        else dispatch(terminateSuccess());
        await dispatch(fetchCountryReports(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(terminateFail());
      });
  };
};

export default {
  fetchCountryReports,
  resetCountryReportsReducer,
  createCountryReport,
  getCountryReportById,
  getForVisitorCountryReport,
  updateCountryReport,
  publishCountryReport,
  unPublishCountryReport,
  activeCountryReport,
  deactiveCountryReport,
  terminateCountryReport,
  saveDefaultFindings,
};
