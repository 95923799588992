import {
  IRegionalDropdown,
  IRegionalFindings,
} from '@/Interfaces/RegionalReport';

const REGIONAL_REPORT_OPTIONS: IRegionalDropdown[] = [
  {
    label: `Executive Summary`,
    value: 'recommendations',
  },
  {
    label: 'CS Overview',
    value: 'overview',
  },
  {
    label: 'Background',
    value: 'background',
  },
  {
    label: `Findings`,
    value: 'areas',
  },
  {
    label: `Recommendations`,
    value: 'acknowledgements',
  },
  {
    label: 'Preview PDF',
    value: 'preview_pdf',
  },
];

const REPORT_KEY = [
  {
    label: 'Number of registered organizations',
    value: 'numberOfRegisteredOrganizations',
  },
  {
    label: 'Key civil society laws',
    value: 'keyCivilSocietyLaws',
  },
  {
    label: 'Relevant changes in legal frameworks',
    value: 'relevantChangesInLegalFrameworks',
  },
  {
    label: 'State funding (key bodies and amounts)',
    value: 'stateFunding',
  },
  {
    label: 'Human resources (employees and volunteers)',
    value: 'humanResources',
  },
  {
    label:
      'CSO-Government Cooperation (relevant/new body: consultation mechanism)',
    value: 'csoGovernmentCooperation',
  },
  {
    label: 'Other key challenges',
    value: 'otherKeyChallenges',
  },
];

const DEFAULT_FINDING = [
  { label: 'Finding', value: 'finding' },
  {
    label: 'Recommendations',
    value: 'recommendations',
  },
];

const DEFAULT_REGIONAL_REPORT = {
  id: '',
  title: '',
  year: '',
  fileReport: null,
  civilSociety: [
    {
      name: '',
      value: '',
      content: {
        numberOfRegisteredOrganizations: '',
        keyCivilSocietyLaws: '',
        relevantChangesInLegalFrameworks: '',
        stateFunding: '',
        humanResources: '',
        csoGovernmentCooperation: '',
        otherKeyChallenges: '',
      },
    },
  ],
  recommendation: {
    findings: [{ findings: '', recommentdations: '' }],
    executive: '',
  },
  acknowledgment: '',
  background: '',
  finding: [
    {
      findingState: {
        area_1: { area_1_subArea_1_1: '', area_1_subArea_1_2: '' },
        area_2: {
          area_2_subArea_2_1: '',
          area_2_subArea_2_2: '',
          area_2_subArea_2_3: '',
        },
        area_3: {
          area_3_subArea_3_1: '',
          area_3_subArea_3_2: '',
          area_3_subArea_3_3: '',
        },
      },
    },
    { recommendations: '' },
  ],
};

const KEY_FINDINGS: IRegionalFindings[] = [
  {
    label: 'Key Findings',
    value: 'findings',
  },
  {
    label: 'Key Recommendations',
    value: 'recommendations',
  },
];

export {
  REGIONAL_REPORT_OPTIONS,
  REPORT_KEY,
  DEFAULT_FINDING,
  DEFAULT_REGIONAL_REPORT,
  KEY_FINDINGS,
};
