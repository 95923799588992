import React, { useEffect, useState, useMemo } from 'react';

import { TextField, MenuItem, SxProps } from '@mui/material';

import { IDropdownOption } from '@interfaces/Dropdown.interface';
import { LIST } from '@/Constants';

const { DROPDOWN_LIST } = LIST;
const {
  GENDER,
  ROLE_LIST,
  ORDER_BY_LIST,
  USER_STATUS_LIST,
  NEWS_STATUS_LIST,
  ROLE_LIST_ADMIN,
  ABOUT_MENU,
  GALLERY_STATUS_LIST,
} = DROPDOWN_LIST;

interface ISectionProps {
  value?: string;
  label?: string;
  onChange?(value: string | number): void;
  listOf?:
    | 'GENDER'
    | 'ROLES'
    | 'ROLES_ADMIN'
    | 'ORDER_BY'
    | 'USER_STATUS'
    | 'YEAR'
    | 'NEWS_STATUS'
    | 'ABOUT_MENU'
    | 'GALLERY_STATUS'
    | 'NEWS_STATUS_USER';
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
}

const Dropdown: React.FC<ISectionProps> = ({
  value = '',
  label = 'Gender',
  onChange,
  listOf = 'GENDER',
  sx,
  disabled,
  required,
}) => {
  // Constructors
  const [optionState, setOptionState] = useState<IDropdownOption[]>([]);
  const [currentValue, setCurrentValue] = useState('');

  const _getOptions = useMemo((): IDropdownOption[] => {
    switch (listOf) {
      case 'ROLES':
        return ROLE_LIST;
      case 'ROLES_ADMIN':
        return ROLE_LIST_ADMIN;
      case 'ORDER_BY':
        return ORDER_BY_LIST;
      case 'USER_STATUS':
        return USER_STATUS_LIST;
      case 'NEWS_STATUS':
        return NEWS_STATUS_LIST;
      case 'ABOUT_MENU':
        return ABOUT_MENU;
      case 'GALLERY_STATUS':
        return GALLERY_STATUS_LIST;
      default:
        return GENDER;
    }
  }, [listOf]);

  useEffect(() => {
    setOptionState(_getOptions);
  }, []);

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  // Events
  const onChangeOption = (e: any) => {
    if (onChange) onChange(e);
  };

  // Renders
  const _renderOptions = () => {
    if (!optionState)
      return (
        <MenuItem value={''} disabled>
          No Options
        </MenuItem>
      );
    return optionState.map((option: IDropdownOption, index: number) => (
      <MenuItem key={`${option.label}-${index}`} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <TextField
      select
      required={required}
      fullWidth
      label={label}
      defaultValue={''}
      value={currentValue}
      onChange={(e) => onChangeOption(e.target.value)}
      sx={{ ...sx }}
      disabled={disabled}
    >
      {_renderOptions()}
    </TextField>
  );
};

export default Dropdown;
