import _ from 'lodash';
import { Grid, Skeleton } from '@mui/material';

interface ISkeletonLoading {
  numberRender: number;
  sx?: object;
}

const SkeletonLoading: React.FC<ISkeletonLoading> = ({
  numberRender,
  sx,
}: ISkeletonLoading) => {
  return (
    <Grid container sx={sx}>
      {_.map(new Array(numberRender), (_i, index) => {
        return (
          <Grid item xs={12} key={`skeleton${index}`}>
            <Skeleton
              sx={{
                height: 160,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SkeletonLoading;
