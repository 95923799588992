import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: { ...DEFAULT_PAGINATION, searchBy: 'name' },
  meta: null,
  userTable: [],
  userProfile: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_USER_TABLE_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_USER_TABLE_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: payload,
      };
    }
    case ACTION_TYPES.SET_USER_MANAGEMENT_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_USER_MANAGEMENT_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_USER_MANAGEMENT_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_USER_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_USERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        userTable: payload,
      };
    case ACTION_TYPES.FETCH_USERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };

    case ACTION_TYPES.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        userProfile: payload,
      };
    case ACTION_TYPES.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        userProfile: payload,
        isActionLoading: false,
      };
    case ACTION_TYPES.CREATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        userProfile: payload,
        isActionLoading: false,
      };
    case ACTION_TYPES.UPDATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DEACTIVE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DEACTIVE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.TERMINATE_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.TERMINATE_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };

    default:
      return state;
  }
};
