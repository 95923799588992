import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  ICompareAcrossCountries,
  ICompareAcrossYears,
  IFilterOfCountry,
  IFilterOfYear,
} from '@interfaces/CompareAndExplore.interface';

// SINGLE ACTIONS
const setFetchCompareLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COMPARE_AND_EXPORE_FETCH_LOADING,
    payload,
  };
};

const resetCompareAndExploreReducer = (clearAll?: boolean) => {
  return {
    type: ACTION_TYPES.RESET_COMPARE_EXPLORE_REDUCER,
    payload: clearAll,
  };
};

// ASYNC ACTIONS
const fetchYearsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_YEARS_SUCCESS,
    payload,
  };
};

const fetchYearsFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_YEARS_FAILURE,
  };
};

const fetchCompareAndExploreByYears = (payload: ICompareAcrossYears) => {
  return async (dispatch: any) => {
    dispatch(setFetchCompareLoading(true));
    await API.fetchCompareAndExploreByYears(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchYearsFail());
        else {
          const resolved = _.map(result, (el, key) => {
            const splitedKey = key.split('_');
            const area = `${splitedKey[0]} ${splitedKey[1]}`;
            const subArea = `${splitedKey[2]}-${splitedKey[3]} ${splitedKey[4]}.${splitedKey[5]}`;
            const standard = `${splitedKey[6]} ${splitedKey[7]}`;
            return {
              label: `${area} > ${subArea} > ${standard}`.toUpperCase(),
              chartData: Utils.generateCompareBarChart(el),
              code: {
                areaCode: `${splitedKey[0]}_${splitedKey[1]}`,
                subCode: `${splitedKey[0]}_${splitedKey[1]}_${splitedKey[2]}_${splitedKey[3]}_${splitedKey[4]}_${splitedKey[5]}`,
                standardCode: key,
              },
            };
          });
          dispatch(fetchYearsSuccess(resolved));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchYearsFail());
      });
  };
};

const fetchCountriesSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_COUNTRIES_SUCCESS,
    payload,
  };
};

const fetchCountriesFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_COUNTRIES_FAILURE,
  };
};

const saveCompareAndExploreByYears = (payload: IFilterOfYear) => {
  return {
    type: ACTION_TYPES.SAVE_COMPARE_EXPLORE_BY_YEARS,
    payload,
  };
};

const saveCompareAndExploreByCountries = (payload: IFilterOfCountry) => {
  return {
    type: ACTION_TYPES.SAVE_COMPARE_EXPLORE_BY_COUNTRIES,
    payload,
  };
};

const fetchCompareAndExploreByCountries = (
  payload: ICompareAcrossCountries
) => {
  return async (dispatch: any) => {
    dispatch(setFetchCompareLoading(true));
    await API.fetchCompareAndExploreByCountries(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchCountriesFail());
        else {
          const resolved = _.map(Object.keys(result), (key) => {
            const splitedKey: any = key && key.split('_');
            const area = `${splitedKey[0]} ${splitedKey[1]}`;
            const subArea = `${splitedKey[2]}-${splitedKey[3]} ${splitedKey[4]}.${splitedKey[5]}`;
            const standard = `${splitedKey[6]} ${splitedKey[7]}`;
            return {
              label: `${area} > ${subArea} > ${standard}`.toUpperCase(),
              chartData: Utils.generateCompareBarChart(
                result[key as keyof typeof result]
              ),
              code: {
                areaCode: `${splitedKey[0]}_${splitedKey[1]}`,
                subCode: `${splitedKey[0]}_${splitedKey[1]}_${splitedKey[2]}_${splitedKey[3]}_${splitedKey[4]}_${splitedKey[5]}`,
                standardCode: key,
              },
            };
          });
          dispatch(fetchCountriesSuccess(resolved));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchCountriesFail());
      });
  };
};

export default {
  fetchCompareAndExploreByYears,
  fetchCompareAndExploreByCountries,
  resetCompareAndExploreReducer,
  saveCompareAndExploreByYears,
  saveCompareAndExploreByCountries,
};
