import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    page: 0,
    limit: 0,
    postId: '',
  },
  comments: [],
  commentById: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_COMMENT_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COMMENT_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COMMENT_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_COMMENT_REDUCER:
      return DEFAULT_STATES;
    case ACTION_TYPES.FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        comments: payload,
      };
    case ACTION_TYPES.FETCH_COMMENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };

    case ACTION_TYPES.GET_COMMENT_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        commentById: payload,
      };
    case ACTION_TYPES.GET_COMMENT_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_COMMENT_SUCCESS: {
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        Comments: payload,
      };
    }
    case ACTION_TYPES.CREATE_COMMENT_FAILURE: {
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    }
    case ACTION_TYPES.UPDATE_COMMENT_SUCCESS: {
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        Comments: payload,
      };
    }
    case ACTION_TYPES.UPDATE_COMMENT_FAILURE: {
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    }
    case ACTION_TYPES.DEACTIVE_COMMENT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DEACTIVE_COMMENT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
