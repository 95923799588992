import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Grid, Box, Hidden } from '@mui/material';

import { BreadCrumb } from '@/Components/LayoutPart';
import DefaultLayout from '@/Components/DefaultLayout';
import { NoDataWereFound, SkeletonLoading } from '@/Components/Common';

import AboutNav from './AboutNav';

import Utils from '@/Utils';
import { BackgroundActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';

import { ICreateBackground } from '@/Interfaces/PageManagement.interface';

const { getBackgroundHistory } = BackgroundActions;

const Background: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.BACKGROUND_HISTORY, 'isGetLoading')
  );
  const backgroundContent: ICreateBackground = useSelector((state: RootState) =>
    _.get(state.BACKGROUND_HISTORY, 'backgroundContent')
  );

  const [details, setDetails] = useState<ICreateBackground>({
    title: 'Background',
    content: '',
  });

  useEffect(() => {
    dispatch(getBackgroundHistory());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(backgroundContent)) {
      const { title, content } = backgroundContent;
      setDetails({
        title,
        content,
      });
    }
  }, [backgroundContent]);

  const _renderContent = (
    <Box
      sx={{
        img: {
          maxWidth: 1,
          objectFit: 'cover',
          wordWrap: 'break-word',
        },
        minHeight: 700,
      }}
      dangerouslySetInnerHTML={Utils.createMarkup(details.content)}
    />
  );

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadCrumb title="Background" />
        </Grid>
        <Hidden mdDown>
          <Grid item md={12} lg={2}>
            <AboutNav />
          </Grid>
        </Hidden>

        {!isGetLoading ? (
          <Grid item md={12} sm={12} lg={10}>
            {backgroundContent ? (
              <Grid item xs={12}>
                {_renderContent}
              </Grid>
            ) : (
              <Grid item md={12} lg={10}>
                <NoDataWereFound />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <SkeletonLoading numberRender={5} />
          </Grid>
        )}
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default Background;
