import React, { useEffect } from 'react';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { ActiveBreadCrumb } from '@/Components/Common';
import { useState } from 'react';
import DefaultLayout from '@/Components/DefaultLayout';
import { Grid, MenuItem, TextField } from '@mui/material';
import { REGIONAL_REPORT_OPTIONS, ROUTERS, DEFAULT_CONFIRM } from '@/Constants';
import {
  Overview,
  Recommendations,
  Background,
  Acknowledgments,
  ReportPDF,
  Subareas,
} from './ReportParts';
import { LoadingButton } from '@mui/lab';
import Utils from '@/Utils';
import {
  IRecommendations,
  IRegionalReportStructure,
  IReportTemplate,
  ISubareas,
  TStepRegionalReports,
} from '@interfaces/RegionalReport';
import { DEFAULT_REGIONAL_REPORT } from '@/Constants/RegionalReport';
import { RootState, useTypedDispatch } from '@/store';
import { RegionalActions } from '@/Actions';
import { useSelector } from 'react-redux';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { Dialogs } from '@/Components/LayoutPart';
const { createRegionalReport, clearDataCountryReports } = RegionalActions;

const NewReports: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [selectedParts, setSelectedParts] =
    useState<TStepRegionalReports>('overview');
  const [fileReport, setFileReport] = useState<File[]>([]);
  const [year, setYear] = useState<string>();
  const [formRegional, setFormRegional] = useState<IRegionalReportStructure>(
    DEFAULT_REGIONAL_REPORT
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'isActionLoading')
  );
  const [confirmBack, setConfirmBack] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    dispatch(clearDataCountryReports());
  }, []);

  const handleChangePage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setSelectedParts(e.target.value as TStepRegionalReports);
  };

  const handleChangeFilePDF = (newFiles: File[]) => {
    if (newFiles) {
      setFileReport(newFiles);
    } else {
      setFileReport([]);
    }
  };

  const handleBack = () => {
    setConfirmBack({
      isOpen: true,
      message: 'Changes are not saved',
      state: {
        id: '',
        status: '',
      },
    });
  };

  const handleSubmitBack = () => {
    Utils.redirect(ROUTERS.REGIONAL_REPORT_MANAGEMENT);
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const onCancelBack = () => {
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    if (formRegional.title)
      formData.append('title', _.trim(formRegional.title));
    if (year) formData.append('year', _.trim(year));
    if (formRegional.acknowledgment)
      formData.append('acknowledgment', _.trim(formRegional.acknowledgment));
    if (formRegional.background)
      formData.append('background', _.trim(formRegional.background));
    if (formRegional.civilSociety) {
      const civilSociety = _.filter(
        formRegional.civilSociety,
        (item) =>
          _.trim(item?.content?.csoGovernmentCooperation) ||
          _.trim(item?.content?.humanResources) ||
          _.trim(item?.content?.keyCivilSocietyLaws) ||
          _.trim(item?.content?.numberOfRegisteredOrganizations) ||
          _.trim(item?.content?.otherKeyChallenges) ||
          _.trim(item?.content?.relevantChangesInLegalFrameworks) ||
          _.trim(item?.content?.stateFunding)
      );
      formData.append('civilSociety', JSON.stringify(civilSociety));
    }
    if (formRegional.recommendation) {
      const finding = _.filter(
        formRegional.recommendation.findings,
        (item) => _.trim(item.findings) || _.trim(item.recommendations)
      );
      const recommendation = {
        findings: finding,
        executive: formRegional.recommendation.executive,
      };
      formData.append('recommendation', JSON.stringify(recommendation));
    }
    if (formRegional.finding)
      formData.append('finding', JSON.stringify(formRegional.finding));
    if (!_.isEmpty(fileReport)) formData.append('fileReport', fileReport[0]);
    dispatch(createRegionalReport(formData));
  };

  const _renderPages = (value: TStepRegionalReports) => {
    switch (value) {
      case 'overview':
        return (
          <Overview
            onUnfocus={(data: IReportTemplate[]) => {
              setFormRegional({ ...formRegional, civilSociety: data });
            }}
          />
        );
      case 'recommendations':
        return (
          <Recommendations
            onUnfocus={(data: IRecommendations) => {
              setFormRegional({ ...formRegional, recommendation: data });
            }}
          />
        );
      case 'acknowledgements':
        return (
          <Acknowledgments
            onUnfocus={(data: string) => {
              setFormRegional({ ...formRegional, acknowledgment: data });
            }}
          />
        );
      case 'background':
        return (
          <Background
            onUnfocus={(data: string) => {
              setFormRegional({ ...formRegional, background: data });
            }}
          />
        );
      case 'preview_pdf':
        return (
          <ReportPDF
            defaultURL=""
            file={fileReport}
            onFileChange={handleChangeFilePDF}
          />
        );
      case 'areas':
        return (
          <Subareas
            onUnfocus={(data: ISubareas) => {
              setFormRegional({ ...formRegional, finding: data });
            }}
            hiddenToolbar
          />
        );
      default:
        return '';
    }
  };

  const _renderTitle = () => {
    return (
      <Grid item sx={{ mb: 3 }} xs={12}>
        <ActiveBreadCrumb screen="REGIONAL_REPORT" />
      </Grid>
    );
  };
  const _renderYear = () => {
    return (
      <DatePicker
        inputFormat="YYYY"
        views={['year']}
        label="Year"
        value={year || null}
        onChange={(newValue) => {
          if (!_.isEmpty(newValue)) setYear(dayjs(newValue).year().toString());
        }}
        maxDate={new Date()}
        renderInput={(params: any) => (
          <TextField {...params} required fullWidth />
        )}
        // disabled={isActionLoading}
      />
    );
  };

  const _renderOptions = () => {
    return (
      <TextField
        select
        fullWidth
        defaultValue={selectedParts}
        key={`Parts${selectedParts}`}
        label="Parts"
        onChange={(e) => handleChangePage(e)}
      >
        {_.map(REGIONAL_REPORT_OPTIONS, (option, index) => {
          return (
            <MenuItem key={`${option.label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const renderMain = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        sx={{ width: 1 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Dialogs.Confirm
          confirm={confirmBack}
          onCancel={onCancelBack}
          callback={() => handleSubmitBack()}
        />
        <Grid item>{_renderTitle()}</Grid>
        <Grid item px={2} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {_renderYear()}
            </Grid>
            <Grid item xs={9}>
              <TextField
                label="Title"
                defaultValue={formRegional.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormRegional({ ...formRegional, title: e.target.value });
                }}
                fullWidth
                // disabled={isActionLoading}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              {_renderOptions()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item px={2} xs={12}>
          {_renderPages(selectedParts)}
        </Grid>
        <Grid item my={2} xs={12}>
          <Grid container px={2} spacing={2} justifyContent="end">
            <Grid item>
              <LoadingButton
                variant="contained"
                color="fuzzyWuzzyBrown"
                loading={isActionLoading}
                onClick={() => handleBack()}
              >
                Back
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                color="oceanGreen"
                loading={isActionLoading}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default NewReports;
