import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Grid, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { TextEditor } from '@/Components/Common';

import { GuidelinesActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { ICreateGuideline } from '@/Interfaces/PageManagement.interface';
import Dialogs from '@/Components/LayoutPart/Dialogs';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '@/Constants';

const { getGuidelines, resetGuidelinesReducer, createGuidelines } =
  GuidelinesActions;

const APGuidelines: React.FC = () => {
  const dispatch = useTypedDispatch();
  // const confirm = useConfirm();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.GUIDELINES, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.GUIDELINES, 'isActionLoading')
  );
  const guidelinesContent: ICreateGuideline = useSelector((state: RootState) =>
    _.get(state.GUIDELINES, 'guidelinesContent')
  );

  const [details, setDetails] = useState<ICreateGuideline>({
    title: 'EU CS Guidelines',
    content: '',
  });
  const [confirm, setConfirm] = useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [renderKey, setRenderkey] = useState<number>();

  useEffect(() => {
    dispatch(getGuidelines());
    return () => {
      dispatch(resetGuidelinesReducer());
    };
  }, []);

  useEffect(() => {
    if (guidelinesContent) {
      const { title = 'EU CS Guidelines', content = '' } = guidelinesContent;
      setDetails({
        title,
        content,
      });
    }
    setRenderkey(Math.random());
  }, [guidelinesContent]);

  const handleInputChange = (newValue: string) =>
    setDetails({ ...details, content: newValue });

  const handleSubmit = () => {
    setConfirm({
      ...confirm,
      isOpen: false,
    });
    if (!_.isEmpty(details)) {
      dispatch(createGuidelines(details));
    }
  };

  const handleCancel = () => {
    setConfirm({
      ...confirm,
      isOpen: false,
    });
  };

  const _renderTitle = () => {
    return (
      <Grid item xs={12}>
        <BreadCrumb title="EU CS Guidelines" />
      </Grid>
    );
  };

  const _renderContent = () => {
    return (
      <Grid item xs={12}>
        <TextEditor
          key={renderKey}
          value={details.content}
          onChange={(e: any) => handleInputChange(e)}
          height={700}
          disabled={isGetLoading || isActionLoading}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 2,
            mt: 2,
            mb: 2,
          }}
        >
          <LoadingButton
            variant="contained"
            onClick={() =>
              setConfirm({
                ...confirm,
                message: `Are you sure you want to change the content?`,
                isOpen: true,
              })
            }
            color="oceanGreen"
            loading={isGetLoading || isActionLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container direction="column">
        <Dialogs.Confirm
          onCancel={handleCancel}
          confirm={confirm}
          callback={handleSubmit}
        />
        {_renderTitle()}
        {_renderContent()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default APGuidelines;
