import _ from 'lodash';
import { ACTION_TYPES, ROUTERS, ENUMS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  ICreateScore,
  IUpdateScore,
} from '@interfaces/CountryScoring.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetUserScoringLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_SCORING_GET_LOADING,
    payload,
  };
};

const setFetchUserScoringLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_SCORING_FETCH_LOADING,
    payload,
  };
};

const setUserScoringActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_SCORING_ACTION_LOADING,
    payload,
  };
};

const setUserScoringPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_USER_SCORING_PAGINATION,
    payload,
  };
};

const setUserScoringMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_USER_SCORING_META,
    payload,
  };
};

const resetUserScoringReducer = () => {
  return {
    type: ACTION_TYPES.RESET_USER_SCORING_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_USER_SCORING_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_USER_SCORING_FAILURE,
  };
};

const fetchUserScoring = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchUserScoringLoading(true));
    await API.fetchCountryScoring(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setUserScoringMeta(meta));
          dispatch(setUserScoringPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateScore) => {
  return {
    type: ACTION_TYPES.GET_USER_SCORING_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_USER_SCORING_BY_ID_FAILURE,
  };
};

const getUserScoringById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetUserScoringLoading(true));
    await API.getCountryScoringById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = (payload: IUpdateScore) => {
  return {
    type: ACTION_TYPES.CREATE_USER_SCORING_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_SCORING_FAILURE,
  };
};

const createNewScoring = (payload: ICreateScore) => {
  return async (dispatch: any) => {
    dispatch(setUserScoringActionLoading(true));
    await API.createCountryScoring(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
          if (
            payload.status === ENUMS.STATUS.REVIEW ||
            payload?.status === ENUMS.STATUS.DRAFT
          )
            Utils.redirect(ROUTERS.MY_SCORING);
          else {
            const id = _.get(result, 'id');
            Utils.redirect(ROUTERS.UPDATE_SCORING, { id });
          }
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = (payload: IUpdateScore) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_SCORING_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_SCORING_FAILURE,
  };
};

const updateUserScoring = (payload: IUpdateScore, id: string) => {
  return async (dispatch: any) => {
    dispatch(setUserScoringActionLoading(true));
    await API.updateCountryScoring(payload, id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          if (payload.status === ENUMS.STATUS.REVIEW)
            Utils.redirect(ROUTERS.MY_SCORING);
          dispatch(updateSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const fetchAllSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_USER_SCORING_SUCCESS,
    payload,
  };
};

const fetchAllFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_USER_SCORING_FAILURE,
  };
};

const fetchAllUserScoring = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchUserScoringLoading(true));
    await API.fetchCountryScoring(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(setUserScoringMeta(meta));
          dispatch(setUserScoringPagination(payload));
          dispatch(fetchAllSuccess(items));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllFail());
      });
  };
};

export default {
  fetchUserScoring,
  getUserScoringById,
  resetUserScoringReducer,
  createNewScoring,
  updateUserScoring,
  fetchAllUserScoring,
};
