import ENUMS from '@/Constants/Enums';

export default {
  [ENUMS.STATUS.ACTIVE]: '#28ae2f',
  [ENUMS.STATUS.INACTIVE]: '#ffc400',
  [ENUMS.STATUS.TERMINATED]: '#e53935',
  [ENUMS.STATUS.APPROVAL]: '#28ae2f',
  [ENUMS.STATUS.EDITING]: '#00897b',
  [ENUMS.STATUS.DENY]: '#e53935',
  [ENUMS.STATUS.PUBLISH]: '#0091ea',
  [ENUMS.STATUS.WAITING_PUBLISH]: '#fb8c00',
  [ENUMS.STATUS.DRAFT]: '#E84F0E',
  [ENUMS.STATUS.REVIEW]: '#ffc400',
  [ENUMS.STATUS.ACCEPT]: '#28ae2f',
  [ENUMS.STATUS.PENDING]: '#ffc400',
  [ENUMS.STATUS.WAITING_APPROVAL]: '#fb8c00',
};
