import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  Box,
  Skeleton,
  Breadcrumbs,
} from '@mui/material';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { useTypedDispatch, RootState } from '@/store';
import Utils from '@utils';
import { NewCarouselActions } from '@actions';
import { ROUTERS } from '@/Constants';

import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';
import { newsContent } from '../NewsContent/NewsContent.Style';
import { cartStyles, titleItemStyles, categoryStyles } from './Carousel.styles';

const { fetchNewsCarousel } = NewCarouselActions;

interface ICarouselStructure {
  id: string;
  title: string;
  publishAt: string;
  category: string;
  content: string;
}

interface SectionProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const RESPONSIVE = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const Carousel: React.FC<SectionProps> = () => {
  const dispatch = useTypedDispatch();

  const carouselNews: ICarouselStructure[] = useSelector((state: RootState) =>
    _.get(state.NEWS_CAROUSEL, 'carouselNews')
  );

  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.NEWS_CAROUSEL, 'pagination')
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS_CAROUSEL, 'isFetchLoading')
  );

  React.useEffect(() => {
    dispatch(fetchNewsCarousel(pagination));
  }, []);

  const _renderSkeleton = () => {
    return Array.from(new Array(12)).map((_el, index) => {
      return (
        <Card
          sx={{
            marginX: 0.6,
          }}
          key={index}
        >
          <CardContent>
            <Skeleton
              animation="wave"
              height={50}
              width={0.9}
              style={{ marginTop: 6, marginLeft: 12 }}
            />
            <Skeleton
              animation="wave"
              height={80}
              width={0.9}
              style={{ marginLeft: 12 }}
            />
            <Skeleton
              animation="wave"
              height={60}
              width={0.3}
              style={{ marginLeft: 12 }}
            />
          </CardContent>
        </Card>
      );
    });
  };

  const itemCarousels = React.useMemo(() => {
    const news = _.cloneDeep(carouselNews);
    const getLastestPost = news.sort((a: any, b: any) => {
      const aTime = Date.parse(a.publishAt);
      const bTime = Date.parse(b.publishAt);
      return bTime - aTime;
    });
    return _.map(getLastestPost, (item: ICarouselStructure) => {
      return (
        <Card
          sx={cartStyles(carouselNews)}
          key={item.id}
          // onClick={() => Utils.redirect(ROUTERS.NEWS_DETAIL, { id: item.id })}
        >
          <CardContent>
            <Breadcrumbs separator="|" aria-label="breadcrumb">
              <Typography sx={categoryStyles}>{item?.category}</Typography>
              <Typography variant="caption" color="text.secondary" gutterBottom>
                {item?.publishAt && Utils.getFormatDate(item.publishAt)}
              </Typography>
            </Breadcrumbs>
            <Typography sx={titleItemStyles} mb={1} variant="h6">
              {item.title}
            </Typography>
            <Box
              sx={{
                ...newsContent,
                WebkitLineClamp: 3,
                textOverflow: 'ellipsis',
                height: 1,
                overflow: 'hidden',
                '& *': {
                  fontSize: '14px',
                  fontWeight: '400',
                  py: 0,
                  my: 0,
                },
                img: {
                  display: 'none!important',
                },
              }}
              component="div"
              dangerouslySetInnerHTML={Utils.createMarkup(item?.content)}
            />
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 'auto',
            }}
          >
            <Button
              onClick={() =>
                Utils.redirect(ROUTERS.NEWS_DETAIL, { id: item.id })
              }
              variant="outlined"
              startIcon={<DragIndicatorIcon />}
              size="small"
            >
              Read more
            </Button>
          </CardActions>
        </Card>
      );
    });
  }, [carouselNews]);

  return (
    <Box
      sx={{
        '& .alice-carousel__wrapper': {
          paddingY: 1,
        },
      }}
    >
      {itemCarousels && (
        <AliceCarousel
          keyboardNavigation={true}
          infinite={true}
          autoPlay={true}
          mouseTracking
          swipeExtraPadding={400}
          animationDuration={350}
          autoPlayInterval={3000}
          items={isFetchLoading ? _renderSkeleton() : itemCarousels}
          responsive={RESPONSIVE}
          disableButtonsControls={true}
        />
      )}
    </Box>
  );
};

export default Carousel;
