import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { TransitionSlide } from '@/Components/Common';
import { useTypedDispatch, RootState } from '@/store';
import { AuthActions } from '@actions';

import { IUpdateSelfPassword } from '@interfaces/User.interface';

const { updateSelfPassword } = AuthActions;
interface ISectionProps {
  open: boolean;
  handleClose(): void;
}

type IKeyPassword = 'oldPassword' | 'newPassword' | 'confirmationNewPassword';

interface IShowPassStructure {
  oldPassword: boolean;
  newPassword: boolean;
  confirmationNewPassword: boolean;
}

const DEFAULT_STATE: IUpdateSelfPassword = {
  oldPassword: '',
  newPassword: '',
  confirmationNewPassword: '',
};

const UserInformationDialog: React.FC<ISectionProps> = (
  props: ISectionProps
) => {
  // Constructors
  const { open, handleClose } = props;
  const dispatch = useTypedDispatch();

  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );
  const isChangePasswordSuccess = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isChangePasswordSuccess')
  );
  const [passwordForm, setPasswordForm] =
    useState<IUpdateSelfPassword>(DEFAULT_STATE);

  const [isShowPass, setIsShowPass] = useState<IShowPassStructure>({
    oldPassword: false,
    newPassword: false,
    confirmationNewPassword: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    newPass: '',
    confirmNewPass: '',
  });

  useEffect(() => {
    if (isChangePasswordSuccess) onClose();
  }, [isChangePasswordSuccess]);

  const onClose = () => {
    setIsShowPass({
      oldPassword: false,
      newPassword: false,
      confirmationNewPassword: false,
    });
    setPasswordForm(DEFAULT_STATE);
    setErrorMessages({
      newPass: '',
      confirmNewPass: '',
    });
    handleClose();
  };

  const handleShowPassword = (key: IKeyPassword, value: boolean) => {
    setIsShowPass((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let currentMsg = '';
    if (
      passwordForm.oldPassword &&
      passwordForm.newPassword &&
      passwordForm.oldPassword === passwordForm.newPassword
    ) {
      currentMsg = `New password can't be the same as old password.`;
      setErrorMessages({
        ...errorMessages,
        newPass: currentMsg,
      });
    } else if (
      passwordForm.newPassword &&
      passwordForm.newPassword.length < 6
    ) {
      currentMsg = 'New password must be at least 6 characters';
      setErrorMessages({
        ...errorMessages,
        newPass: currentMsg,
      });
    } else if (
      passwordForm.newPassword &&
      passwordForm.confirmationNewPassword &&
      passwordForm.newPassword !== passwordForm.confirmationNewPassword
    ) {
      currentMsg = 'New password and confirm new password does not match';
      setErrorMessages({
        ...errorMessages,
        confirmNewPass: currentMsg,
      });
    }
    if (!currentMsg) dispatch(updateSelfPassword(passwordForm));
  };

  // Renders
  const _renderForm = () => {
    return (
      <Grid container spacing={3} direction="column" minWidth={350}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            type={isShowPass.oldPassword ? 'text' : 'password'}
            disabled={isActionLoading}
            label="Old Password"
            key={`old${open}`}
            onChange={(e) => {
              setErrorMessages({ ...errorMessages, newPass: '' });
              setPasswordForm({
                ...passwordForm,
                oldPassword: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleShowPassword('oldPassword', !isShowPass.oldPassword)
                    }
                  >
                    {isShowPass.oldPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            type={isShowPass.newPassword ? 'text' : 'password'}
            disabled={isActionLoading}
            label="New Password"
            key={`new${open}`}
            error={!_.isEmpty(errorMessages.newPass)}
            helperText={errorMessages.newPass}
            onChange={(e) => {
              setErrorMessages({ ...errorMessages, newPass: '' });
              setPasswordForm({
                ...passwordForm,
                newPassword: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleShowPassword('newPassword', !isShowPass.newPassword)
                    }
                  >
                    {isShowPass.newPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            type={isShowPass.confirmationNewPassword ? 'text' : 'password'}
            disabled={isActionLoading}
            label="Confirm New Password"
            key={`confirmNew${open}`}
            error={!_.isEmpty(errorMessages.confirmNewPass)}
            helperText={errorMessages.confirmNewPass}
            onChange={(e) => {
              setErrorMessages({ ...errorMessages, confirmNewPass: '' });
              setPasswordForm({
                ...passwordForm,
                confirmationNewPassword: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleShowPassword(
                        'confirmationNewPassword',
                        !isShowPass.confirmationNewPassword
                      )
                    }
                  >
                    {isShowPass.confirmationNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
    >
      <DialogTitle>Change Password</DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent>{_renderForm()}</DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            color="fuzzyWuzzyBrown"
            onClick={() => onClose()}
            variant="contained"
            disabled={isActionLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={isActionLoading}
            variant="contained"
            color="oceanGreen"
          >
            Change
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserInformationDialog;
