import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { ISocial } from '@interfaces/SocialLink.interface';

// SINGLE ACTIONS
const setFetchSocialLinkLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_SOCIAL_LINKS_FETCH_LOADING,
    payload,
  };
};

const setSocialLinkActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_SOCIAL_LINKS_ACTION_LOADING,
    payload,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_SOCIAL_LINKS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_SOCIAL_LINKS_FAILURE,
  };
};

const fetchSocialLinks = () => {
  return async (dispatch: any) => {
    dispatch(setFetchSocialLinkLoading(true));
    await API.fetchSocialLinks()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else dispatch(fetchSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const createSuccess = (payload: ISocial) => {
  return {
    type: ACTION_TYPES.CREATE_SOCIAL_LINKS_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_SOCIAL_LINKS_FAILURE,
  };
};

const createNewSocialLinks = (payload: ISocial) => {
  return async (dispatch: any) => {
    dispatch(setSocialLinkActionLoading(true));
    await API.createSocialLink(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

export default {
  fetchSocialLinks,
  createNewSocialLinks,
};
