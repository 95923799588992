/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  TextFieldProps,
  Collapse,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import DefaultLayout from '@/Components/DefaultLayout';
import { Dropdown, UploadImage } from '@/Components/Common';
import { BreadCrumb, Dialogs } from '@/Components/LayoutPart';
import { useTypedDispatch, RootState } from '@/store';
import {
  IUpdateUser,
  IUserData,
  IMessageAccount,
} from '@interfaces/User.interface';
import { AuthActions } from '@actions';
import Utils from '@/Utils';

const { getSelfProfile, updateSelfProfile } = AuthActions;
const DEFAULT_USERDATA: IUserData = {
  firstName: '',
  lastName: '',
};

const MESSAGE_ACCOUNT = {
  firstName: '',
  lastName: '',
  dob: '',
};

const AdminAccountProfile = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const selfProfile: IUpdateUser | null = useSelector(
    (state: RootState) => _.get(state.AUTH, 'selfProfile') || null
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );
  const [tabActive, setTabActive] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>(DEFAULT_USERDATA);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [isMessage, setIsMessage] = useState<IMessageAccount>(MESSAGE_ACCOUNT);
  const [avatar, setAvatar] = useState<File[]>([]);

  useEffect(() => {
    dispatch(getSelfProfile());
  }, []);

  useEffect(() => {
    if (selfProfile) {
      setUserData(_.get(selfProfile, 'userData'));
      generateDetails();
      setRenderKey(Math.random());
    }
  }, [selfProfile]);

  const generateDetails = async () => {
    const isInternal =
      selfProfile?.userData?.avatar?.storageService === 'Internal';
    if (selfProfile?.userData?.avatar) {
      const avatarSrc = isInternal
        ? await Utils.getFileFromURL(
            _.get(selfProfile, 'userData.avatar.path') || '',
            _.get(selfProfile, 'userData.avatar.nameOriginal') || ''
          )
        : await Utils.getAWSFileAsBlob(
            _.get(selfProfile, 'userData.avatar.path') || '',
            _.get(selfProfile, 'userData.avatar.nameOriginal') || ''
          );
      if (avatarSrc) setAvatar([avatarSrc as File]);
    }
  };

  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let message: IMessageAccount = isMessage;
    if (!userData.firstName)
      message = { ...message, firstName: 'FirstName cannot be empty' };
    if (!userData.lastName)
      message = { ...message, lastName: 'Last name cannot be empty' };
    if (!dayjs(userData.dob).isValid() && userData?.dob)
      message = { ...message, dob: 'Dob invalid date/time format' };
    if (_.find(message, (mess) => mess.length > 0)) {
      setIsMessage(message);
    } else {
      if (selfProfile) {
        const formData = new FormData();
        _.map(userData, (el: any, key) => {
          if (key !== 'id' && key !== 'avatar') {
            formData.append(`userData[${key}]`, el || '');
          }
        });
        if (!_.isEmpty(avatar)) formData.append('userData[avatar]', avatar[0]);
        else formData.append('avatarRemove', JSON.stringify(true));
        dispatch(updateSelfProfile(formData));
      }
    }
  };

  const handleOnChange = (key: string, value: string | number | Dayjs) => {
    setUserData({ ...userData, [key]: value });
    setIsMessage(MESSAGE_ACCOUNT);
  };

  const handleChangeDate = (key: string, newValue: Dayjs | null) => {
    setUserData({
      ...userData,
      [key]: newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
    });
    setIsMessage({ ...isMessage, [key]: '' });
  };

  const handleFilesChange = (key: 'avatar', items: any[]) =>
    key === 'avatar' && setAvatar(items);

  // Renders
  const _renderMainForm = (
    childern: JSX.Element,
    title: string,
    isHasExpand?: boolean
  ) => (
    <Grid item>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            {!isHasExpand && (
              <Grid item sx={{ py: 0.5, px: 1 }}>
                <Typography variant="h5">{title}</Typography>
              </Grid>
            )}
            {isHasExpand && (
              <ListItemButton
                onClick={() => setTabActive(!tabActive)}
                sx={{ py: 0.5, px: 1, borderRadius: 1 }}
              >
                <ListItemText
                  primary={<Typography variant="h5">{title}</Typography>}
                />
                {tabActive ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {childern}
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderUserInformation = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <UploadImage
            label=""
            onFileChange={(file) => handleFilesChange('avatar', file)}
            files={avatar}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                defaultValue={userData?.firstName || ''}
                key={`firstName${renderKey}`}
                label="First Name"
                error={isMessage.firstName.length > 0}
                helperText={isMessage.firstName}
                disabled={isGetLoading || isActionLoading}
                onChange={(e) => handleOnChange('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                label="Last name"
                defaultValue={userData?.lastName || ''}
                key={`lastName${renderKey}`}
                error={isMessage.lastName.length > 0}
                helperText={isMessage.lastName}
                required
                disabled={isGetLoading || isActionLoading}
                onChange={(e) => handleOnChange('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <DatePicker
                label="DOB"
                inputFormat="MM/DD/YYYY"
                value={userData?.dob ? dayjs(userData?.dob) : null}
                views={['day', 'month', 'year']}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={isMessage.dob.length > 0}
                    helperText={isMessage.dob}
                    // required
                  />
                )}
                disabled={isActionLoading}
                onChange={(e: Dayjs | null) => handleChangeDate('dob', e)}
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Dropdown
                key={`gender${renderKey}`}
                value={userData?.gender || ''}
                disabled={isGetLoading || isActionLoading}
                onChange={(val) => handleOnChange('gender', val)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderUserContact = () => {
    return (
      <Collapse in={tabActive} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Phone Number"
              defaultValue={userData?.phoneNumber || ''}
              key={`phoneNumber${renderKey}`}
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('phoneNumber', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="City"
              defaultValue={userData?.city || ''}
              key={`city${renderKey}`}
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Zip Code"
              defaultValue={userData?.zipCode || ''}
              key={`zipCode${renderKey}`}
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('zipCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Address"
              defaultValue={userData?.address || ''}
              key={`address${renderKey}`}
              rows={5}
              multiline
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('address', e.target.value)}
            />
          </Grid>
        </Grid>
      </Collapse>
    );
  };

  const renderMain = () => {
    return (
      <>
        <Dialogs.ChangePassword
          open={isOpen}
          handleClose={() => setIsOpen(false)}
        />
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" rowSpacing={4}>
            <BreadCrumb title="Account Profile" />
            {_renderMainForm(_renderUserInformation(), 'Information')}
            {_renderMainForm(_renderUserContact(), 'Contact', true)}
            <Grid container justifyContent="end" spacing={2} my={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="burntSienna"
                  onClick={() => setIsOpen(true)}
                  disabled={isGetLoading || isActionLoading}
                >
                  Change Password
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="oceanGreen"
                  loading={isGetLoading || isActionLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default AdminAccountProfile;
