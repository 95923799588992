const USER_TABLE_HEADER = [
  {
    label: 'Full Name',
    value: 'userData.fullName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone Number',
    value: 'userData.phoneNumber',
  },
  {
    label: 'Country',
    value: 'country.name',
  },
  {
    label: 'Country Review',
    value: 'countryReview',
  },
  {
    label: 'Role',
    value: 'role.roleCode',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const COUNTRY_REPORT_TABLE_HEADER = [
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Country',
    value: 'country.name',
  },
  {
    label: 'Creator',
    value: 'user.email',
  },
  {
    label: 'Reviewer',
    value: 'country.reviewer.email',
  },
];

const USER_COUNTRY_REPORT_TABLE_HEADER = [
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Reviewer',
    value: 'country.reviewer.email',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Updated At',
    value: 'updatedAt',
  },
];

const REVIEWER_COUNTRY_REPORT_TABLE_HEADER = [
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Country',
    value: 'country.name',
  },
  {
    label: 'Created By',
    value: 'user.email',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Updated At',
    value: 'updatedAt',
  },
];

const NEWS_TABLE_HEADER = [
  {
    label: 'Country',
    value: 'country.name',
  },
  {
    label: 'Tag',
    value: 'tag',
  },
  {
    label: 'Source',
    value: 'source',
  },
];

const COUNTRY_SCORING_TABLE_HEADER = [
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Country',
    value: 'country.name',
  },
  {
    label: 'Creator',
    value: 'user.email',
  },
  {
    label: 'Reviewer',
    value: 'country.reviewer.email',
  },
];

const COUNTRY_TABLE_HEADER = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Reviewer',
    value: 'reviewer.email',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const REGIONAL_TABLE_HEADER = [
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Updated At',
    value: 'updatedAt',
  },
];

const MY_SCORING_USER_TABLE_HEADER = [
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Reviewer',
    value: 'country.reviewer.email',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Updated At',
    value: 'updatedAt',
  },
];

const MY_SCORING_REVIEWER_TABLE_HEADER = [
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Country',
    value: 'country.name',
  },
  {
    label: 'Created By',
    value: 'user.email',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Updated At',
    value: 'updatedAt',
  },
];

export default {
  USER_TABLE_HEADER,
  NEWS_TABLE_HEADER,
  COUNTRY_REPORT_TABLE_HEADER,
  USER_COUNTRY_REPORT_TABLE_HEADER,
  REVIEWER_COUNTRY_REPORT_TABLE_HEADER,
  COUNTRY_TABLE_HEADER,
  COUNTRY_SCORING_TABLE_HEADER,
  REGIONAL_TABLE_HEADER,
  MY_SCORING_USER_TABLE_HEADER,
  MY_SCORING_REVIEWER_TABLE_HEADER,
};
