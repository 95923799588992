import { sendRequest } from '@/Configs';
import { API } from '@constants';
import {
  ICreateMethodology,
  ICreateGuideline,
  ICreateBackground,
} from '@interfaces/PageManagement.interface';

const { METHODOLOGY, TOOL_KIT, GUIDELINE, BACKGROUND } = API;

export const createMethodology = async (payload: ICreateMethodology) => {
  return sendRequest(METHODOLOGY.ROOT, 'POST', payload);
};

export const getMethodology = async () => {
  return sendRequest(METHODOLOGY.ROOT, 'GET');
};

export const createGuideLine = async (payload: ICreateGuideline) => {
  return sendRequest(GUIDELINE.ROOT, 'POST', payload);
};

export const getGuideLine = async () => {
  return sendRequest(GUIDELINE.ROOT, 'GET');
};

export const createBackground = async (payload: ICreateBackground) => {
  return sendRequest(BACKGROUND.ROOT, 'POST', payload);
};

export const getBackground = async () => {
  return sendRequest(BACKGROUND.ROOT, 'GET');
};

export const createMMToolkit = async (payload: FormData) => {
  return sendRequest(TOOL_KIT.ROOT, 'FORM_DATA', payload);
};

export const getMMToolkit = async () => {
  return sendRequest(TOOL_KIT.ROOT, 'GET');
};
