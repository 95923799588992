import { Button, Grid, Paper, Typography } from '@mui/material';

import { ROUTERS } from '@/Constants';
import CountryScoringTable from './CounTryDataTable';
import Utils from '@/Utils';
import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';

const DATA_EXAMPLE = [
  {
    year: '2022',
    reviewer: 'Phuc Luffy',
    processing: 'Inprogess',
    status: 'Active',
  },
];

const DEFAULT_META = {
  currentPage: 1,
  itemCount: 1,
  itemsPerPage: 10,
  totalItems: 1,
  totalPages: 1,
};

const CountryScoring: React.FC = () => {
  const onRowAction = (report?: { id: string }, action?: 'edit') => {
    if (report) {
      if (action === 'edit') Utils.redirect(ROUTERS.COUNTRY_SCORING);
    }
  };

  const onPageAction = () => {};

  const _renderMain = () => {
    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <BreadCrumb title="Country scoring" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container justifyContent="end">
            <Button
              sx={{ mb: 2 }}
              onClick={() => Utils.redirect(ROUTERS.MY_SCORING)}
              variant="outlined"
            >
              Start New Scoring
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Grid spacing={1} container>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6">My Reports</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <CountryScoringTable
                    meta={DEFAULT_META}
                    onRowAction={() => onRowAction()}
                    onPageAction={() => onPageAction()}
                    payload={DATA_EXAMPLE}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6">Notifications</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Paper sx={{ minHeight: 400, padding: 1 }} elevation={2}>
                    <Typography>My Notifications</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout content={_renderMain()} />;
};

export default CountryScoring;
