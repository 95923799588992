import Cookies from 'universal-cookie';
import { COOKIE_KEYS, LOCALE } from '@constants';

const cookies = new Cookies();

const saveLocale = (locale: string) => {
  localStorage.setItem(COOKIE_KEYS.LOCALE_KEY, JSON.stringify(locale));
};

const getSaveLocale = () => {
  const locale = localStorage.getItem(COOKIE_KEYS.LOCALE_KEY);
  if (!locale) return LOCALE.EN;

  saveLocale(JSON.parse(locale));
  return JSON.parse(locale);
};

const saveIsRemember = (isRemember: boolean) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_REMEMBER, JSON.stringify(isRemember));
};

const getSaveIsRemember = () => {
  const isRemember = localStorage.getItem(COOKIE_KEYS.SAVED_REMEMBER);
  if (isRemember) return JSON.parse(isRemember);

  return false;
};

const getMaxAge = () =>
  getSaveIsRemember() ? 60 * 60 * 24 * 30 : 60 * 60 * 24; // 30 days or 1 day

const saveToken = (token: string) => {
  cookies.set(COOKIE_KEYS.SAVED_SECURE_TOKEN, token, {
    path: '/',
    maxAge: getMaxAge(),
  });
};

const getSavedToken = () => {
  const token = cookies.get(COOKIE_KEYS.SAVED_SECURE_TOKEN);
  if (token) return token;
};

const saveRefreshToken = (token: string) => {
  cookies.set(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN, token, {
    path: '/',
    maxAge: 60 * 60 * 24 * 30,
  });
};

const getSavedRefreshToken = () => {
  return cookies.get(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN);
};

const saveFullName = (name: string) => {
  cookies.set(COOKIE_KEYS.SAVED_FULL_NAME, name, {
    path: '/',
    maxAge: getMaxAge(),
  });
};

const getFullName = () => {
  return cookies.get(COOKIE_KEYS.SAVED_FULL_NAME);
};

const getSavedUserData = () => {
  const userData = localStorage.getItem(COOKIE_KEYS.SAVED_USER_DATA);
  if (userData && userData !== 'undefined') return JSON.parse(userData);
  return null;
};

const saveUserData = (userData: any) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_USER_DATA, JSON.stringify(userData));
};

const saveUserRole = (role: string) => {
  cookies.set(COOKIE_KEYS.ROLE_KEY, role, { path: '/', maxAge: getMaxAge() });
};

const getUserRole = () => {
  return cookies.get(COOKIE_KEYS.ROLE_KEY);
};

const saveUserEmail = (email: string) => {
  cookies.set(COOKIE_KEYS.SAVED_USER_EMAIL, email, {
    path: '/',
    maxAge: getMaxAge(),
  });
};

const getSavedUserEmail = () => {
  return cookies.get(COOKIE_KEYS.SAVED_USER_EMAIL);
};

const saveUserId = (id: string) => {
  cookies.set(COOKIE_KEYS.SAVED_USER_ID, id, {
    path: '/',
    maxAge: getMaxAge(),
  });
};

const getSavedUserId = () => {
  return cookies.get(COOKIE_KEYS.SAVED_USER_ID);
};

const clearAllSavedData = () => {
  cookies.remove(COOKIE_KEYS.SAVED_SECURE_TOKEN, { path: '/' });
  cookies.remove(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN, { path: '/' });
  cookies.remove(COOKIE_KEYS.SAVED_FULL_NAME, { path: '/' });
  cookies.remove(COOKIE_KEYS.ROLE_KEY, { path: '/' });
  cookies.remove(COOKIE_KEYS.SAVED_USER_EMAIL, { path: '/' });
  cookies.remove(COOKIE_KEYS.SAVED_USER_ID, { path: '/' });
  localStorage.removeItem(COOKIE_KEYS.SAVED_USER_DATA);
  localStorage.removeItem(COOKIE_KEYS.SAVED_REMEMBER);
};

const saveCookieConsent = (value: boolean) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_COOKIE_CONSENT, JSON.stringify(value));
};

const getCookieConsent = () => {
  const cookieConsentValue = localStorage.getItem(
    COOKIE_KEYS.SAVED_COOKIE_CONSENT
  );
  if (cookieConsentValue && cookieConsentValue !== 'undefined')
    return JSON.parse(cookieConsentValue);
  return null;
};

export {
  saveToken,
  getSavedToken,
  saveRefreshToken,
  getSavedRefreshToken,
  saveFullName,
  getFullName,
  clearAllSavedData,
  saveUserData,
  getSavedUserData,
  saveUserRole,
  getUserRole,
  saveIsRemember,
  getSaveIsRemember,
  saveLocale,
  getSaveLocale,
  saveUserEmail,
  getSavedUserEmail,
  saveUserId,
  getSavedUserId,
  saveCookieConsent,
  getCookieConsent,
};
