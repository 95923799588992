import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  methodologyContent: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_METHODOLOGY_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_METHODOLOGY_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_METHODOLOGY_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.GET_METHODOLOGY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        methodologyContent: payload,
      };
    case ACTION_TYPES.GET_METHODOLOGY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_METHODOLOGY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        methodologyContent: payload,
        isActionLoading: false,
      };
    case ACTION_TYPES.CREATE_METHODOLOGY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
