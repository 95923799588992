/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  Grid,
  Box,
  Typography,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { TextEditor } from '@/Components/Common';
import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { CommonColors } from '@/Themes';
import { MethodologyActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';

import { ICreateMethodology } from '@/Interfaces/PageManagement.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '@/Constants';
import Dialogs from '@/Components/LayoutPart/Dialogs';

const { getMethodology, resetMethodologyReducer, createMethodology } =
  MethodologyActions;

type SubHeaderKey =
  | 'fullyDisablingEnvironment'
  | 'disablingEnvironment'
  | 'partiallyEnablingEnvironment'
  | 'enablingEnvironment'
  | 'fullyEnablingEnvironment';

type TypeSubHeaderKey = 'legislation' | 'practice';

interface SubKey {
  legislation: string;
  practice: string;
  note?: string;
}

type IExtraDataStructure = {
  [key in SubHeaderKey]: SubKey;
};

const DEFAULT_TITLE_TABLE = [
  {
    title: 'Fully disabling environment (1)',
    color: CommonColors.red,
  },
  {
    title: 'Disabling environment (2)',
    color: CommonColors.orange,
  },
  {
    title: 'Partially enabling environment (3)',
    color: CommonColors.yellow,
  },
  { title: 'Enabling environment (4)', color: CommonColors.limeGreen },
  { title: 'Fully enabling environment (5)', color: CommonColors.forestGreen },
];

const DEFAULT_EXTRA_DATA = {
  fullyDisablingEnvironment: {
    legislation: '',
    practice: '',
  },
  disablingEnvironment: {
    legislation: '',
    practice: '',
  },
  partiallyEnablingEnvironment: {
    legislation: '',
    practice: '',
  },
  enablingEnvironment: {
    legislation: '',
    practice: '',
  },
  fullyEnablingEnvironment: {
    legislation: '',
    practice: '',
  },
  note: {
    fullyDisablingEnvironment: '',
    disablingEnvironment: '',
    partiallyEnablingEnvironment: '',
    enablingEnvironment: '',
    fullyEnablingEnvironment: '',
  },
};

const Methodology: React.FC = () => {
  const dispatch = useTypedDispatch();
  // const confirm = useConfirm();

  const [expanded, setExpanded] = React.useState<SubHeaderKey | string>(
    'fullyDisablingEnvironment'
  );
  const [confirm, setConfirm] =
    React.useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.METHODOLOGY, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.METHODOLOGY, 'isActionLoading')
  );
  const methodologyContent: ICreateMethodology = useSelector(
    (state: RootState) => _.get(state.METHODOLOGY, 'methodologyContent')
  );

  const [details, setDetails] = useState<ICreateMethodology>({
    title: 'Methodology',
    content: '',
    extraData: DEFAULT_EXTRA_DATA,
  });
  const [renderKey, setRenderkey] = useState<number>();

  useEffect(() => {
    dispatch(getMethodology());
    return () => {
      dispatch(resetMethodologyReducer());
    };
  }, []);

  useEffect(() => {
    if (methodologyContent) {
      const {
        title = 'Methodology',
        content = '',
        extraData,
      } = methodologyContent;
      setDetails({
        title,
        content,
        extraData: extraData || DEFAULT_EXTRA_DATA,
      });
    }
    setRenderkey(Math.random());
  }, [methodologyContent]);

  const handleExpanded = (key: SubHeaderKey) =>
    key !== expanded ? setExpanded(key) : setExpanded('');

  const handleInputChange = (newValue: string) => {
    setDetails({ ...details, content: newValue });
  };

  const handleChangeExtraData = (
    key: SubHeaderKey,
    subKey: TypeSubHeaderKey,
    value: string
  ) => {
    const newExtraData = {
      ...details.extraData,
      [key]: { ...details.extraData[key], [subKey]: value },
    };
    if (newExtraData) setDetails({ ...details, extraData: newExtraData });
  };

  const handleChangeNoteExtraData = (value: string, key: string) => {
    const newDetails = {
      ...details,
      extraData: {
        ...details.extraData,
        note: {
          ...details.extraData.note,
          [key]: value,
        },
      },
    };
    setDetails(newDetails);
  };

  const handleSubmit = () => {
    setConfirm({ ...confirm, isOpen: false });
    if (!_.isEmpty(details)) {
      dispatch(createMethodology(details));
    }
  };

  const _renderTitle = () => {
    return (
      <Grid item xs={12}>
        <BreadCrumb title="Methodology" />
      </Grid>
    );
  };

  const _renderContent = () => {
    return (
      <Grid item xs={12} mt={-2}>
        <TextEditor
          value={details.content}
          disabled={isGetLoading || isActionLoading}
          onChange={(e: any) => handleInputChange(e)}
          height={700}
          key={renderKey}
        />
      </Grid>
    );
  };

  const _renderTable = () => {
    const _renderDataTable = () => {
      let myKey = 0;
      const resolveExtraData = _.omit(details.extraData, ['note']);
      const getNoteList = details.extraData?.note || [];

      return _.map(
        resolveExtraData,
        (data: IExtraDataStructure, key: SubHeaderKey) => {
          const _renderSubKey = () => {
            const result = [];
            for (const [subKey, value] of Object.entries(data)) {
              result.push(
                <Grid item xs={6} md={6} key={subKey}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        component="h2"
                        textAlign={'center'}
                        mb={1}
                        fontWeight={500}
                      >
                        {_.upperCase(subKey)}
                      </Typography>
                      <TextField
                        multiline
                        rows={5}
                        fullWidth
                        value={value || ''}
                        onChange={(e: any) =>
                          handleChangeExtraData(
                            key,
                            subKey as TypeSubHeaderKey,
                            e.target.value
                          )
                        }
                        disabled={isGetLoading || isActionLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
            return result;
          };
          myKey++;
          return (
            <List sx={{ width: '100%' }} component="nav" key={key}>
              <ListItemButton
                sx={{
                  background: DEFAULT_TITLE_TABLE[myKey - 1]?.color,
                  '&:hover': {
                    background: DEFAULT_TITLE_TABLE[myKey - 1]?.color,
                  },
                  mb: 2,
                }}
                onClick={() => handleExpanded(key)}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      {DEFAULT_TITLE_TABLE[myKey - 1]?.title}
                    </Typography>
                  }
                />
                {expanded === key ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={expanded === key} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>
                  {_renderSubKey()}
                  <Grid item xs={12}>
                    <Typography
                      placeholder="Note"
                      component="h2"
                      fontWeight={500}
                    >
                      Note:
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      value={getNoteList[key]}
                      onChange={(e: any) =>
                        handleChangeNoteExtraData(e.target.value, key)
                      }
                      disabled={isGetLoading || isActionLoading}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </List>
          );
        }
      );
    };

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              Assessment Table
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {_renderDataTable()}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const _renderButtonGroup = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={() =>
            setConfirm({
              ...confirm,
              isOpen: true,
              message: 'Are you sure you want to change the content?',
            })
          }
          color="oceanGreen"
          loading={isGetLoading || isActionLoading}
        >
          Save
        </LoadingButton>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Grid container direction="column" spacing={2}>
        <Dialogs.Confirm
          callback={handleSubmit}
          confirm={confirm}
          onCancel={() => setConfirm({ ...confirm, isOpen: false })}
        />
        {_renderTitle()}
        {_renderContent()}
        {_renderTable()}
        {_renderButtonGroup()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Methodology;
