import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IUpdateScore } from '@interfaces/CountryScoring.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetCountryScoringLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_SCORING_MANAGEMENT_GET_LOADING,
    payload,
  };
};

const setFetchCountryScoringLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_SCORING_MANAGEMENT_FETCH_LOADING,
    payload,
  };
};

const setScoringActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_SCORING_MANAGEMENT_ACTION_LOADING,
    payload,
  };
};

const setCountryScoringPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_SCORING_PAGINATION,
    payload,
  };
};

const setCountryScoringMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_SCORING_META,
    payload,
  };
};

const resetCountryScoringReducer = () => {
  return {
    type: ACTION_TYPES.RESET_COUNTRY_SCORING_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_SCORING_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_SCORING_FAILURE,
  };
};

const fetchCountryScoring = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchCountryScoringLoading(true));
    await API.fetchCountryScoring(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setCountryScoringMeta(meta));
          dispatch(setCountryScoringPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateScore) => {
  return {
    type: ACTION_TYPES.GET_COUNTRY_SCORING_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_COUNTRY_SCORING_BY_ID_FAILURE,
  };
};

const getCountryScoringById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetCountryScoringLoading(true));
    await API.getCountryScoringById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const removeSuccess = () => {
  return {
    type: ACTION_TYPES.REMOVE_COUNTRY_SCORING_SUCCESS,
  };
};

const removeFail = () => {
  return {
    type: ACTION_TYPES.REMOVE_COUNTRY_SCORING_FAILURE,
  };
};

const removeScoring = (
  id: string,
  payload: IPaginationFilter,
  location?: string
) => {
  return async (dispatch: any) => {
    dispatch(setScoringActionLoading(true));
    await API.removeCountryScoring(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(removeFail());
        else {
          dispatch(removeSuccess());
          if (location) Utils.redirect(location);
          else dispatch(fetchCountryScoring(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(removeFail());
      });
  };
};

const unApproveSuccess = () => {
  return {
    type: ACTION_TYPES.UNAPPROVE_COUNTRY_SCORING_SUCCESS,
  };
};

const unApproveFail = () => {
  return {
    type: ACTION_TYPES.UNAPPROVE_COUNTRY_SCORING_FAILURE,
  };
};

const unApproveScoring = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setScoringActionLoading(true));
    await API.unApprovalCountryScoring(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(unApproveFail());
        else {
          dispatch(unApproveSuccess());
          dispatch(fetchCountryScoring(payload));
          Utils.redirect(ROUTERS.COUNTRY_SCORING_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(unApproveFail());
      });
  };
};

const approveSuccess = () => {
  return {
    type: ACTION_TYPES.APPROVE_COUNTRY_SCORING_SUCCESS,
  };
};

const approveFail = () => {
  return {
    type: ACTION_TYPES.APPROVE_COUNTRY_SCORING_FAILURE,
  };
};

const approveScoring = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setScoringActionLoading(true));
    await API.approvalCountryScoring(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(approveFail());
        else {
          dispatch(approveSuccess());
          dispatch(fetchCountryScoring(payload));
          Utils.redirect(ROUTERS.COUNTRY_SCORING_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(approveFail());
      });
  };
};

export default {
  fetchCountryScoring,
  getCountryScoringById,
  resetCountryScoringReducer,
  removeScoring,
  approveScoring,
  unApproveScoring,
};
