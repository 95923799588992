import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

import { IFooterStructure } from '@/Interfaces/Footer.inteface';
// SINGLE ACTIONS
const setGetFooterLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_FOOTER_GET_LOADING,
    payload,
  };
};

const setFooterActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_FOOTER_ACTION_LOADING,
    payload,
  };
};

const resetFooterReducer = () => {
  return {
    type: ACTION_TYPES.RESET_FOOTER_REDUCER,
  };
};

// ASYNC ACTIONS
const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FOOTER_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_FOOTER_FAILURE,
  };
};

const getFooter = () => {
  return async (dispatch: any) => {
    dispatch(setGetFooterLoading(true));
    await API.getFooter()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else dispatch(getSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_FOOTER_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_FOOTER_FAILURE,
  };
};

const createFooter = (payload: IFooterStructure) => {
  return async (dispatch: any) => {
    dispatch(setFooterActionLoading(true));
    await API.createFooter(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

export default {
  getFooter,
  resetFooterReducer,
  createFooter,
};
