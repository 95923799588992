import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import DefaultLayout from '@/Components/DefaultLayout';
import { MapComponent } from '@/Components/LayoutPart';

import Utils from '@/Utils';
import { useTypedDispatch, RootState } from '@/store';
import { NotificationActions } from '@/Actions';

import { mainStyles } from './Home.styles';

const { fetchNotifications, resetNotificationsReducer } = NotificationActions;

const Home: React.FC = () => {
  const dispatch = useTypedDispatch();

  const notifications = useSelector((state: RootState) =>
    _.get(state.USER_NOTIFICATION, 'notifications')
  );

  const roleName = Utils.getUserRole();

  useEffect(() => {
    return () => {
      dispatch(resetNotificationsReducer());
    };
  }, []);

  const token = Utils.getSavedToken();

  useEffect(() => {
    if (!_.isEmpty(roleName) && _.isEmpty(notifications)) {
      const validateToken = Utils.checkTokenLifeTime(token);
      if (validateToken) dispatch(fetchNotifications());
    }
  }, [roleName]);

  const renderMain = () => {
    return (
      <Box sx={mainStyles}>
        <MapComponent />
      </Box>
    );
  };

  return <DefaultLayout content={renderMain()} showCarousel />;
};

export default Home;
