import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';

import PreviewIcon from '@mui/icons-material/Preview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { PaginationActions } from '@/Components/Common';

import { CountryReportsActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';

import Utils from '@/Utils';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ENUMS, ROUTERS, DEFAULT_CONFIRM } from '@constants';

import { IDropdownOption } from '@/Interfaces/Dropdown.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import Dialogs from '../Dialogs';

const STATUS_DROPDOWN = [
  {
    label: 'Published',
    value: ENUMS.STATUS.PUBLISH,
    disabled: false,
  },
  {
    label: 'Reviewing',
    value: ENUMS.STATUS.REVIEW,
    disabled: false,
  },
  {
    label: 'In progress',
    value: ENUMS.STATUS.WAITING_PUBLISH,
    disabled: false,
  },
  {
    label: 'Editing',
    value: ENUMS.STATUS.EDITING,
    disabled: false,
  },
  {
    label: 'Deleted',
    value: ENUMS.STATUS.TERMINATED,
    disabled: false,
  },
];

const { COUNTRY_REPORT_TABLE_HEADER } = TABLES;
// Declare actions
const {
  fetchCountryReports,
  terminateCountryReport,
  publishCountryReport,
  unPublishCountryReport,
} = CountryReportsActions;

const CountryReportTable: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const [confirm, setConfirm] = useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const [confirmDelete, setConfirmDelete] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const [valueChange, setValueChange] = useState<any>({
    id: '',
    status: '',
  });
  const countryReportTable: any =
    useSelector((state: RootState) =>
      _.get(state.COUNTRY_REPORTS, 'payload')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isFetchLoading')
  );
  const colSpan = COUNTRY_REPORT_TABLE_HEADER.length + 3;

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(
      fetchCountryReports({
        ...pagination,
        limit: page,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(
      fetchCountryReports({
        ...pagination,
        limit: perPage,
      })
    );
  };

  const generateStatusMessages = (status: string) => {
    const messages = {
      terminated: 'This country report will be delete.',
      publish: 'This country report will be publish.',
      review: 'This country report will be send back to reviewer.',
    };
    return messages[status as keyof typeof messages] || '';
  };

  const handleChangeStatus = (status: string, id: string) => {
    setValueChange({ id, status });
    setConfirm({
      isOpen: true,
      message: generateStatusMessages(status),
      state: {
        id: id,
        status: status,
      },
    });
  };

  const handleClickDelete = (id: string) => {
    setConfirmDelete({
      isOpen: true,
      message: `This report will be delete.`,
      state: {
        id: id,
        status: '',
      },
    });
  };

  const handleSubmitDelete = (state: { id?: string; status: string }) => {
    if (state?.id) dispatch(terminateCountryReport(state.id, pagination));
    setConfirmDelete(DEFAULT_CONFIRM);
  };

  const onCancelDelete = () => {
    setConfirmDelete(DEFAULT_CONFIRM);
  };

  const handleSubmit = (state: { id?: string; status: string }) => {
    const { id, status } = state;
    switch (status) {
      case ENUMS.STATUS.TERMINATED:
        if (id) dispatch(terminateCountryReport(id, pagination));
        break;
      case ENUMS.STATUS.PUBLISH:
        if (id) dispatch(publishCountryReport(id, pagination));
        break;
      case ENUMS.STATUS.REVIEW:
        if (id) dispatch(unPublishCountryReport(id, pagination));
        break;
      default:
    }
    dispatch(fetchCountryReports(pagination));
    setValueChange({ id: '', status: '' });
    setConfirm({
      ...confirm,
      isOpen: false,
    });
  };

  const onCancel = () => {
    setConfirm({ ...confirm, isOpen: false });
    setValueChange({ id: '', status: '' });
    dispatch(fetchCountryReports(pagination));
  };

  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(COUNTRY_REPORT_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`} sx={{ fontWeight: '700' }}>
            {item.label || ''}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Status
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [COUNTRY_REPORT_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(countryReportTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            There is no country report to display
          </TableCell>
        </TableRow>
      );
    return _.map(countryReportTable, (item, index) => {
      const colorGet = valueChange?.id === item?.id && valueChange?.status;
      return (
        <TableRow hover key={index}>
          {_.map(COUNTRY_REPORT_TABLE_HEADER, (head, innerKey) => {
            return (
              <TableCell key={`row${innerKey}-${index}`}>
                {head.value === 'year'
                  ? item?.year
                  : _.get(item, head.value) ?? 'N/I'}
              </TableCell>
            );
          })}
          <TableCell key={`status.${index}`} align="center">
            <TextField
              defaultValue={item?.status}
              key={`status-${item?.id}`}
              size="small"
              select
              onChange={(e: any) =>
                handleChangeStatus(e.target.value, item?.id)
              }
              disabled={
                item?.status === ENUMS.STATUS.TERMINATED ||
                item?.status === ENUMS.STATUS.REVIEW ||
                item?.status === ENUMS.STATUS.EDITING
              }
              sx={{
                minWidth: 150,
                '& .MuiSelect-select': {
                  opacity: 1,
                  color:
                    _.get(
                      TableStatusColor,
                      colorGet || _.get(item, 'status')
                    ) || CommonColors.black,
                },
                '& .MuiSelect-select.MuiOutlinedInput-input.Mui-disabled': {
                  opacity: 1,
                  WebkitTextFillColor: 'unset',
                },
              }}
            >
              {STATUS_DROPDOWN.map(
                (option: IDropdownOption, innerKey: number) => {
                  return (
                    <MenuItem
                      key={`${option.label}-${innerKey}`}
                      value={option.value}
                      disabled={option?.disabled}
                      sx={{
                        '&.Mui-disabled.MuiMenuItem-gutters.Mui-selected': {
                          opacity: 1,
                        },
                        color:
                          _.get(TableStatusColor, _.get(option, 'value')) ||
                          CommonColors.black,
                        display:
                          item?.status === option?.value ||
                          option?.value === ENUMS.STATUS.TERMINATED ||
                          option?.value === ENUMS.STATUS.EDITING ||
                          (item?.status === ENUMS.STATUS.PUBLISH &&
                            option?.value === ENUMS.STATUS.WAITING_PUBLISH)
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <Typography fontSize={14}>
                        {_.upperCase(option.label)}
                      </Typography>
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </TableCell>

          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View Detail">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() => {
                  Utils.redirect(ROUTERS.COUNTRY_REPORT_DETAIL, {
                    id: item.id,
                  });
                }}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  sx={{ color: CommonColors.bismark }}
                  onClick={() => handleClickDelete(item.id)}
                  disabled={
                    item?.status === ENUMS.STATUS.TERMINATED ||
                    item?.status === ENUMS.STATUS.REVIEW ||
                    item?.status === ENUMS.STATUS.EDITING
                  }
                >
                  <DeleteOutlineIcon
                    color={
                      item?.status === ENUMS.STATUS.REVIEW ||
                      item?.status === ENUMS.STATUS.TERMINATED ||
                      item?.status === ENUMS.STATUS.EDITING
                        ? 'inherit'
                        : 'error'
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Dialogs.Confirm
        confirm={confirm}
        callback={() => handleSubmit(confirm.state)}
        onCancel={() => onCancel()}
      />
      {_renderTable()}
      <Dialogs.Confirm
        confirm={confirmDelete}
        onCancel={() => onCancelDelete()}
        callback={() => handleSubmitDelete(confirmDelete.state)}
      />
    </Box>
  );
};

export default CountryReportTable;
