import { IDropdownArea } from './Dropdown.interface';

export class ITreeSelectCountryStandard {
  value: any;

  data: IDropdownArea[];

  constructor(value: any, data: IDropdownArea[]) {
    this.value = value;
    this.data = data;
  }

  public getParent() {
    const parent = (() => {
      if ('subAreas' in this.value) {
        return null;
      } else if ('standards' in this.value) {
        return (
          this.data.find(({ subAreas }) =>
            subAreas.some(({ value }) => value === this.value.value)
          ) || null
        );
      } else {
        for (const { subAreas } of this.data) {
          const state = subAreas.find((sub) => {
            if (sub.standards)
              return sub.standards.some(
                ({ value }) => value === this.value.value
              );
            return null;
          });
          if (state) {
            return state;
          }
        }
        return null;
      }
    })();
    return parent ? new ITreeSelectCountryStandard(parent, this.data) : parent;
  }

  public getChildren() {
    if ('subAreas' in this.value) {
      return this.value.subAreas.map(
        (sub: any) => new ITreeSelectCountryStandard(sub, this.data)
      );
    } else if ('standards' in this.value) {
      return this.value.standards.map(
        (sta: any) => new ITreeSelectCountryStandard(sta, this.data)
      );
    } else {
      return null;
    }
  }

  public isBranch() {
    return 'subAreas' in this.value || 'standards' in this.value;
  }

  public isEqual(to: ITreeSelectCountryStandard) {
    return to.value.value === this.value.value;
  }

  public toString() {
    return this.value.label;
  }
}
