import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  Button,
  Grid,
  TextField,
  Collapse,
  Typography,
  Divider,
  TextFieldProps,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import {
  UploadImage,
  ActiveBreadCrumb,
  Dropdown,
  CountryAsyncDropdown,
} from '@/Components/Common';
import DefaultLayout from '@/Components/DefaultLayout';

import Utils from '@/Utils';
import { ROUTERS, ENUMS } from '@/Constants';
import { UserManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import { IUserData } from '@interfaces/User.interface';

const { createNewUser } = UserManagementActions;

const DEFAULT_USERDATA: IUserData = {
  firstName: '',
  lastName: '',
};

const DEFAULT_USERACCOUNT = {
  email: '',
};

const NewUser: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'isActionLoading')
  );

  const [tabActive, setTabActive] = useState<boolean>(true);
  const [userData, setUserData] = useState<IUserData>(DEFAULT_USERDATA);
  const [userAccount, setUserAccount] = useState(DEFAULT_USERACCOUNT);
  const [roleCode, setRoleCode] = useState<any>('');
  const [countryId, setCountryId] = useState<string>('');
  const [countryAssignIds, setCountryAssignIds] = useState<string[]>([]);
  const [avatar, setAvatar] = useState<File[]>([]);
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');

  const handleOnChange = (key: string, value: string | number | Dayjs) => {
    setUserData({ ...userData, [key]: value });
  };

  const handleFilesChange = (key: 'avatar', items: any[]) =>
    key === 'avatar' && setAvatar(items);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if ((userData?.dob && dayjs(userData?.dob).isValid()) || !userData?.dob) {
      const formData = new FormData();
      if (roleCode === ENUMS.ROLES.REVIEWER) {
        _.forEach(countryAssignIds, (id, index) => {
          formData.append(`countryAssignIds[${index}]`, id);
        });
      }
      formData.append('roleCode', roleCode);
      if (roleCode === ENUMS.ROLES.USER)
        formData.append('countryId', countryId);
      _.map(userAccount, (el, key) => {
        formData.append(key, el);
      });
      _.map(userData, (el, key) => {
        if (el) formData.append(`userData[${key}]`, el);
      });
      if (!_.isEmpty(avatar)) formData.append('userData[avatar]', avatar[0]);
      dispatch(createNewUser(formData));
    } else setIsValidDateMsg('Dob invalid date/time format');
  };

  // Renders
  const _renderMainForm = (
    childern: JSX.Element,
    title: string,
    isHasExpand?: boolean
  ) => (
    <Grid item>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            {!isHasExpand && (
              <Grid item sx={{ py: 0.5, px: 1 }}>
                <Typography variant="h5">{title}</Typography>
              </Grid>
            )}
            {isHasExpand && (
              <ListItemButton
                onClick={() => setTabActive(!tabActive)}
                sx={{ py: 0.5, px: 1, borderRadius: 1 }}
              >
                <ListItemText
                  primary={<Typography variant="h5">{title}</Typography>}
                />
                {tabActive ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {childern}
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderUserAccount = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            required
            label="Email"
            type="email"
            autoComplete="email"
            disabled={isActionLoading}
            onChange={(e) =>
              setUserAccount({ ...userAccount, email: e.target.value })
            }
          />
        </Grid>
      </Grid>
    );
  };

  const _renderUserInformation = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <UploadImage
            disabled={isActionLoading}
            label=""
            onFileChange={(file) => handleFilesChange('avatar', file)}
            files={avatar}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                label="First Name"
                disabled={isActionLoading}
                onChange={(e) => handleOnChange('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                label="Last name"
                required
                disabled={isActionLoading}
                onChange={(e) => handleOnChange('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <DatePicker
                label="DOB"
                inputFormat="MM/DD/YYYY"
                value={userData?.dob ? dayjs(userData?.dob) : null}
                views={['day', 'month', 'year']}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={!_.isEmpty(isValidDateMsg)}
                    helperText={isValidDateMsg}
                    // required
                  />
                )}
                disabled={isActionLoading}
                onChange={(e: Dayjs | null) =>
                  handleOnChange('dob', e ? dayjs(e).format('YYYY-MM-DD') : '')
                }
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Dropdown
                listOf="GENDER"
                value={userData.gender}
                disabled={isActionLoading}
                onChange={(val) => handleOnChange('gender', val)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Dropdown
                label="Role"
                listOf="ROLES_ADMIN"
                value={roleCode || ''}
                required
                disabled={isActionLoading}
                onChange={(val) => setRoleCode(val)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              {roleCode === ENUMS.ROLES.REVIEWER && (
                <CountryAsyncDropdown
                  label="Countries"
                  roleCode="reviewer"
                  multiples
                  disabled={isActionLoading}
                  getFor="UNASSIGNED_FOR_REVIEW"
                  defaultValue={countryAssignIds}
                  onChange={(ids) => setCountryAssignIds(ids)}
                  required
                />
              )}
              {roleCode === ENUMS.ROLES.USER && (
                <CountryAsyncDropdown
                  label="Country"
                  roleCode="user"
                  getFor="UNASSIGNED_FOR_USER"
                  disabled={isActionLoading}
                  defaultValue={countryId}
                  onChange={(id) => setCountryId(id)}
                  required
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderUserContact = () => {
    return (
      <Collapse in={tabActive} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Phone Number"
              type="number"
              disabled={isActionLoading}
              onChange={(e) => handleOnChange('phoneNumber', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="City"
              disabled={isActionLoading}
              onChange={(e) => handleOnChange('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Zip Code"
              type="number"
              disabled={isActionLoading}
              onChange={(e) => handleOnChange('zipCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Address"
              rows={5}
              multiline
              disabled={isActionLoading}
              onChange={(e) => handleOnChange('address', e.target.value)}
            />
          </Grid>
        </Grid>
      </Collapse>
    );
  };

  const renderMain = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" rowSpacing={4}>
          <ActiveBreadCrumb screen="USER_MANAGEMENT" />
          {_renderMainForm(_renderUserAccount(), 'Account')}
          {_renderMainForm(_renderUserInformation(), 'Information')}
          {_renderMainForm(_renderUserContact(), 'Contact', true)}
          <Grid container justifyContent="end" spacing={2} mt={2}>
            <Grid item>
              <Button
                variant="contained"
                color="fuzzyWuzzyBrown"
                onClick={() => Utils.redirect(ROUTERS.USER_MANAGEMENT)}
                disabled={isActionLoading}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                color="oceanGreen"
                loading={isActionLoading}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default NewUser;
