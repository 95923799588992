import { sendRequest } from '@/Configs';
import { API } from '@constants';
import {
  ICreateComment,
  IFecthPostIdComments,
  IUpdateComment,
} from '@interfaces/Comment.interface';

const { COMMENT } = API;

export const createComment = async (payload: ICreateComment) => {
  return sendRequest(COMMENT.ROOT, 'POST', payload);
};

export const updateComment = async (payload: IUpdateComment) => {
  return sendRequest(`${COMMENT.ROOT}${payload.id}`, 'PUT', payload);
};

export const fetchComments = async (payload: IFecthPostIdComments) => {
  return sendRequest(COMMENT.ROOT, 'GET', payload);
};

export const getCommentById = async (id: string) => {
  return sendRequest(`${COMMENT.GET_BY_ID}${id}`, 'GET');
};

export const activeComment = async (id: string) => {
  return sendRequest(`${COMMENT.ACTIVE}${id}`, 'PUT');
};

export const deactiveComment = async (id: string) => {
  return sendRequest(`${COMMENT.DEACTIVE}${id}`, 'PUT');
};
