import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { TextField, Grid } from '@mui/material';
import CollapseContent from './CollapseContent';
import EvaluationForm from './EvaluationForm';
import Explanation from './Explanation';
import {
  IIndicatorTemplate,
  ICreateIndicatorScore,
} from '@interfaces/CountryScoring.interface';
interface ISectionProps {
  indicator: IIndicatorTemplate;
  callback(data: { description?: string; evaluate: number }): void;
  defaultValue?: ICreateIndicatorScore;
  disabled?: boolean;
}

const Scoring: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { indicator, callback, defaultValue, disabled } = props;
  const [callbackValue, setCallbackValue] = useState<{
    description?: string;
    evaluate: number;
  }>({
    description: '',
    evaluate: 0,
  });
  const [renderKey, setRenderKey] = useState<number>(Math.random());

  useEffect(() => {
    if (defaultValue) {
      setCallbackValue({ ...callbackValue, ...defaultValue });
    } else {
      setCallbackValue({ description: '', evaluate: 0 });
      setRenderKey(Math.random());
    }
  }, [defaultValue]);

  const handleOnChange = (key: string, value: string) => {
    let newCallbackValue = { ...callbackValue };
    if (!newCallbackValue.evaluate && key === 'description')
      newCallbackValue = { ...callbackValue, evaluate: 1 };
    callback({ ...newCallbackValue, [key]: value });
  };

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <Explanation content={indicator.explanation} />
      </Grid>
      {!_.isEmpty(indicator.mandatory) && (
        <Grid item>
          <CollapseContent type="MANDATORY" payload={indicator.mandatory} />
        </Grid>
      )}
      {!_.isEmpty(indicator.additional) && (
        <Grid item>
          <CollapseContent type="ADDIONTIONAL" payload={indicator.additional} />
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column" rowSpacing={2}>
          <Grid item xs={12}>
            <EvaluationForm
              payload={indicator.catergories}
              onChange={(value: any) => handleOnChange('evaluate', value)}
              value={callbackValue?.evaluate}
              disabled={disabled}
            />
          </Grid>
          <Grid item>
            <TextField
              placeholder={disabled ? '' : 'Please add your text here'}
              multiline
              key={renderKey}
              defaultValue={defaultValue?.description}
              fullWidth
              disabled={disabled}
              rows={15}
              onChange={(e) => handleOnChange('description', e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Scoring;
