import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
} from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { NoDataWereFound } from '@/Components/Common';

import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { KEY_FINDINGS } from '@/Constants/RegionalReport';

import { IExecutiveSummaryStructure } from '@/Interfaces/CountryReport.interface';

interface ISectionProps {
  payload: IExecutiveSummaryStructure;
}

const DEFAULT_DETAILS = {
  keyFindingsAndRecommendations: [
    {
      finding: '',
      recommendation: '',
    },
  ],
  executiveSummary: '',
};

const ExecutiveSummary: React.FC<ISectionProps> = ({ payload }) => {
  const [details, setDetails] =
    useState<IExecutiveSummaryStructure>(DEFAULT_DETAILS);

  useEffect(() => {
    if (payload) setDetails(payload);
  }, [payload]);

  const _renderKeyFindings = () => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              borderLeft: '1px solid #E0E0E0',
              borderTop: '1px solid #E0E0E0',
            }}
          ></TableCell>
          {_.map(KEY_FINDINGS, (item) => (
            <TableCell
              align="center"
              key={`head-${item.value}`}
              sx={{
                border: '1px solid #E0E0E0',
              }}
            >
              <Typography fontWeight="bold">{item.label}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(details?.keyFindingsAndRecommendations, (item, index) => (
          <TableRow key={index}>
            <TableCell
              sx={{
                borderLeft: '1px solid #E0E0E0',
                textAlign: 'center',
              }}
            >
              {++index}
            </TableCell>
            <TableCell
              sx={{
                verticalAlign: 'top',
                width: '50%',
                borderLeft: '1px solid #E0E0E0',
                borderRight: '1px solid #E0E0E0',
              }}
            >
              {item?.finding}
            </TableCell>
            <TableCell
              sx={{
                verticalAlign: 'top',
                borderRight: '1px solid #E0E0E0',
              }}
            >
              {item?.recommendation}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const _renderExecutiveSummary = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <AcUnitIcon
          sx={{
            color: CommonColors.mainColor,
            fontSize: 14,
          }}
        />
        <Typography ml={1} sx={{ fontWeight: 550 }}>
          Executive Summary
        </Typography>
      </Box>
      <Box
        ml={2}
        component="div"
        dangerouslySetInnerHTML={Utils.createMarkup(details?.executiveSummary)}
      />
    </Box>
  );

  const renderMain = () =>
    payload ? (
      <Grid container mt={1}>
        <Grid item xs={12}>
          {_renderExecutiveSummary()}
        </Grid>
        <Grid item xs={12} mt={2}>
          {_renderKeyFindings()}
        </Grid>
      </Grid>
    ) : (
      <NoDataWereFound />
    );

  return renderMain();
};

export default ExecutiveSummary;
