export default {
  submit: 'Submit',
  clear: 'Clear',
  create: 'Create',
  search: 'Search {{name}}',
  cancel: 'Cancel',
  adding: 'adding New', // action
  editting: 'editting', // action
  reset: 'Reset Default',
  clearAllFields: 'Clear all fields',
  modify: 'Modify',
  closeDialog: 'Close dialog',
  logo: 'Logo',
  submitForm: 'Submit form',
  clearForm: 'Clear form',
  resetFilter: 'Reset filter form',
  changePassword: 'Change Password',
  save: 'Save',
  all: 'All',
  unRead: 'Unread',
  markAllAsRead: 'Mark all as read',
  loadMore: 'Load more',
  cancelAction: 'Cancel {{action}} {{name}}',
  clickToEdit: 'Click to edit this {{name}}',
  clickToDownloadFile: 'Click to download file uploaded',
  doubleClickToView: 'Double-click to view full content',
  saveAction: 'Save {{name}} changes',
  addAction: 'Add new {{name}}',
  remove: 'Click to remove this {{name}}',
  back: 'Back',
  backTo: 'Back to {{name}}',
  export: 'Export',
  exportTable: 'Export table',
  downloadCV: 'Download CV',
  viewDetails: 'Click to view {{name}} details',
  viewDocumentInNewTab: 'View document in new tab',
  viewVideoInNewTab: 'View video in new tab',
  userRegistrationForm: 'User Registration Form',
  activateMessage: 'Click to activate this {{name}}',
  deactivateMessage: 'Click to deactivate this {{name}}',

  denyUser: 'Deny User',
  rejectUser: 'Reject User',
  confirmUser: 'Confirm User',
  userWasDenied: '{{key}} was denied',
  userWasRejected: '{{key}} was rejected',
  userWasConfirmed: '{{key}} was confirmed',

  //sponsor
  unorderSponsorAction: 'Add this sponsor to order',
  orderSponsorAdd: 'Add Order Sponsor',
  orderSponsorRemove: 'Click to remove this sponsor',
};
