import React, { useState, useMemo, ReactElement } from 'react';
import {
  Popper as MUIPopper,
  PopperProps,
  PopperPlacementType,
  Grow,
  Paper,
  Box,
} from '@mui/material';

interface IPopperStructure extends PopperProps {
  content: ReactElement | null;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  placement?: PopperPlacementType;
  style?: any;
}

const Popper: React.FC<IPopperStructure> = (props) => {
  const {
    placement = 'top',
    open,
    content,
    children,
    style,
    ...otherProps
  } = props;
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  const renderMain = useMemo(() => {
    return (
      <Box>
        {React.cloneElement(children, { ...children.props, ref: setChildNode })}
        <MUIPopper
          open={open}
          anchorEl={childNode}
          placement={placement}
          transition
          disablePortal={true}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: 'viewport',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: false,
                rootBoundary: 'viewport',
                padding: 8,
              },
            },
          ]}
          sx={{ zIndex: 99, minWidth: '500px', ...style }}
          {...otherProps}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper>
                <Paper>
                  <Box sx={{ p: 3 }} className="main-popper-content">
                    {content}
                  </Box>
                </Paper>
              </Paper>
            </Grow>
          )}
        </MUIPopper>
      </Box>
    );
  }, [props]);

  return renderMain;
};

export default Popper;
