import { Outlet } from 'react-router-dom';

import { ROUTERS } from '@constants';
import { UserContainer } from '@containers';

import ProtectedRoute from './Protected.router';
import OpenNewTabFindings from '@/Components/LayoutPart/NewTab/OpenNewTabFindings';
import NewTabCountryReport from '@/Components/LayoutPart/NewTab/NewTabCountryReport';

const UserRouter = {
  path: ROUTERS.HOME,
  element: <Outlet />,
  children: [
    {
      path: ROUTERS.HOME,
      element: <UserContainer.Home />,
    },
    {
      path: ROUTERS.AUTH,
      element: <UserContainer.Authentication />,
    },
    {
      path: ROUTERS.FORGOT_PASSWORD,
      element: <UserContainer.ForgotPassword />,
    },
    {
      path: ROUTERS.VERIFY_ACCOUNT,
      element: <UserContainer.VerifyAccount />,
    },
    { path: ROUTERS.ABOUT_BACKGROUND, element: <UserContainer.Background /> },
    {
      path: ROUTERS.ABOUT_MMTOOLKIT,
      element: <UserContainer.MMToolkit />,
    },
    {
      path: ROUTERS.GUIDELINES,
      element: <UserContainer.Guidelines />,
    },
    {
      path: ROUTERS.METHODOLOGY,
      element: <UserContainer.Methodology />,
    },
    {
      path: ROUTERS.PROFILE,
      element: (
        <ProtectedRoute location={ROUTERS.PROFILE}>
          <UserContainer.UserProfile />
        </ProtectedRoute>
      ),
    },
    { path: ROUTERS.COUNTRY_REPORT, element: <UserContainer.CountryReport /> },
    {
      path: ROUTERS.NEWS,
      element: <UserContainer.NewsList />,
    },
    {
      path: ROUTERS.NEWS_DETAIL,
      element: <UserContainer.NewsDetails />,
    },
    {
      path: ROUTERS.REGIONAL_REPORT,
      element: <UserContainer.RegionalReport />,
    },
    {
      path: ROUTERS.COUNTRY_SCORING,
      element: <UserContainer.CountryScoring />,
    },
    {
      path: ROUTERS.SCORING_REPORT,
      element: (
        <ProtectedRoute location={ROUTERS.SCORING_REPORT}>
          <UserContainer.ScoringReport />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.MY_SCORING,
      element: (
        <ProtectedRoute location={ROUTERS.MY_SCORING}>
          <UserContainer.MyScoring />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.UPDATE_SCORING,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_SCORING}>
          <UserContainer.UpdateScoringReport />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.COMPARE_EXPLORE_COUNTRY,
      element: <UserContainer.CompareAcrossCountry />,
    },
    {
      path: ROUTERS.COMPARE_EXPLORE_YEAR,
      element: <UserContainer.CompareAcrossYear />,
    },
    {
      path: ROUTERS.MY_REPORT,
      element: (
        <ProtectedRoute location={ROUTERS.MY_REPORT}>
          <UserContainer.MyReportList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.NEW_COUNTRY_REPORT,
      element: (
        <ProtectedRoute location={ROUTERS.NEW_COUNTRY_REPORT}>
          <UserContainer.UPCreateCountryReport />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.UPDATE_MY_COUNTRY_REPORT,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_MY_COUNTRY_REPORT}>
          <UserContainer.UPUpdateCountryReport />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.REGIONAL_REPORTS_DETAIL,
      element: <OpenNewTabFindings />,
    },
    {
      path: ROUTERS.MY_REPORT_CHART,
      element: <NewTabCountryReport />,
    },
  ],
};

const ForbidenRouter = {
  path: '*',
  element: <UserContainer.NotFound />,
};

export default UserRouter;

export { ForbidenRouter };
