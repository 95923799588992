import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: { ...DEFAULT_PAGINATION },
  meta: null,
  nofitications: [],
  details: null,
  count: {
    read: 0,
    unread: 0,
  },
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_NOTIFICATIONS_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_NOTIFICATIONS_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_NOTIFICATIONS_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_NOTIFICATIONS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_NOTIFICATIONS_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COUNT_NOTIFICATIONS: {
      return {
        ...state,
        count: payload,
      };
    }

    case ACTION_TYPES.RESET_NOTIFICATIONS_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        notifications: payload,
      };
    case ACTION_TYPES.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        details: payload,
      };
    case ACTION_TYPES.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.MASK_AT_READ_NOFITICATIONS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        notifications: payload,
        isActionLoading: false,
      };
    case ACTION_TYPES.MASK_AT_READ_NOFITICATIONS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
