import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import { Box } from '@mui/material';
import { Dialogs } from '@/Components/LayoutPart';
import { IFileDetailsStructure } from '@/Interfaces/File.interface';
import { SkeletonLoading } from '@/Components/Common';

import Utils from '@/Utils';
import { ENUMS } from '@/Constants';

interface IEditorStructure {
  value?: string;
  onChange(e?: string): void;
  config?: { [key: string]: any };
  height?: number;
  disabled?: boolean;
  message?: string;
  hiddenToolbar?: boolean;
}

const DEFAULT_PLUGIN: string[] = [
  'advlist',
  'autolink',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'tableofcontents',
  'wordcount',
  'lists',
  'help',
  'pagebreak',
  'quickbars',
  'codesample',
];

// const DEFAULT_MENU_BAR = 'edit insert format table tools';
const DEFAULT_TOOLBAR = [
  { name: 'history', items: ['undo', 'redo'] },
  { name: 'styles', items: ['styles', 'fontsize'] },
  { name: 'bgColor', items: ['backcolor'] },
  { name: 'textColor', items: ['forecolor'] },
  {
    name: 'formatting',
    items: ['bold', 'italic', 'underline', 'bullist', 'numlist', 'pagebreak'],
  },
  {
    name: 'alignment',
    items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
  },
  { name: 'indentation', items: ['outdent', 'indent'] },
  { name: 'table', items: ['table'] },
];

const DEFAULT_TOOLBAR_FINDING = [
  { name: 'history', items: ['undo', 'redo'] },
  {
    name: 'formatting',
    items: ['bold', 'italic', 'underline', 'bullist', 'numlist', 'pagebreak'],
  },
  {
    name: 'alignment',
    items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
  },
  { name: 'indentation', items: ['outdent', 'indent'] },
  { name: 'table', items: ['table'] },
];

const TextEditor: React.FC<IEditorStructure> = ({
  value = '',
  onChange,
  config,
  height = 500,
  disabled,
  message = '',
  hiddenToolbar = false,
}) => {
  const roleName = Utils.getUserRole();
  const refEditor = useRef<Editor>(null);
  const [content, setContent] = useState('');
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [selectedImage, setSelectedImage] =
    useState<IFileDetailsStructure | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setContent(value);
    return () => {
      setSelectedImage(null);
    };
  }, []);

  useEffect(() => {
    if (!value) setContent('');
  }, [value]);

  return (
    <Box
      className={!_.isEmpty(message) ? 'required' : ''}
      component="div"
      sx={{
        position: 'relative',
        '&.required .tox.tox-tinymce': {
          outline: '1px solid #d32f2f',
          borderColor: 'transparent',
        },
      }}
    >
      <Box
        component="input"
        id="local-file"
        type="file"
        name="local-file"
        style={{ display: 'none' }}
      />
      {isLoading && <SkeletonLoading numberRender={3} />}
      <Editor
        apiKey={'5jd1eg7ebbgv0nxph53w1lrsulk3wpfhk5p4blxc989cqqp6'}
        value={content}
        disabled={disabled}
        ref={refEditor}
        init={{
          plugins: DEFAULT_PLUGIN,
          // menubar: DEFAULT_MENU_BAR,
          menubar: 'edit customInsert format table tools help',
          quickbars_selection_toolbar:
            'bold italic underline | blocks | bullist numlist | blockquote quicklink',
          quickbars_insert_toolbar: false,
          menu: {
            customInsert: {
              title: 'Insert',
              // items: 'link media image customImage table insertdatetime',
              items: 'link customImage charmap insertdatetime ',
            },
          },
          toolbar:
            hiddenToolbar && hiddenToolbar
              ? DEFAULT_TOOLBAR_FINDING
              : DEFAULT_TOOLBAR,
          branding: false,
          contextmenu: false,
          image_uploadtab: true,
          automatic_uploads: false,
          resize: true,
          setup: (editor) => {
            editor.on('init', () => setIsLoading(false));
            editor.on('focus', (e) => {
              if (e?.target?.editorContainer) {
                e.target.editorContainer.classList.add('focused');
              }
            });
            editor.on('blur', (e) => {
              if (e?.target?.editorContainer) {
                e.target.editorContainer.classList.remove('focused');
              }
            });
            editor.ui.registry.addMenuItem('customImage', {
              icon: 'image',
              text: 'Image',
              onAction: () => setIsOpenGallery(true),
            });
          },
          height,
          ...config,
        }}
        onEditorChange={(e, editor) => {
          setContent(e);
          const count =
            editor.plugins.wordcount.body.getCharacterCountWithoutSpaces();
          if (count === 1 || (count === 0 && !e.includes('&nbsp;')))
            onChange(e);
          if (count === 0 && e.includes('&nbsp;')) onChange('');
        }}
        onBlur={() => {
          const tinyBookmark = refEditor.current?.editor;
          if (tinyBookmark) {
            const count =
              tinyBookmark.plugins.wordcount.body.getCharacterCountWithoutSpaces();
            if (count === 0 && !content.includes('img')) onChange('');
            else onChange(content);
          }
        }}
        onPaste={(e) => onChange((e?.currentTarget as HTMLElement).innerHTML)}
      />
      {roleName !== ENUMS.ROLES.REVIEWER && (
        <Dialogs.Media
          onOpen={{ status: isOpenGallery, file: selectedImage }}
          onClose={() => {
            setIsOpenGallery(false);
            setSelectedImage(null);
          }}
          callback={(
            file: IFileDetailsStructure,
            size: { width: string | number; height: string | number }
          ) => {
            setIsOpenGallery(false);
            if (file && refEditor?.current?.editor) {
              const tinyBookmark =
                refEditor.current?.editor?.selection.getBookmark(2);
              refEditor?.current?.editor.focus();
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              tinyBookmark &&
                refEditor?.current?.editor.selection.moveToBookmark(
                  tinyBookmark
                );
              refEditor?.current?.editor.insertContent(
                `<img style='width: ${size.width}px; height: ${size.height}px;' src=${file.path} />`
              );
            }
          }}
        />
      )}
    </Box>
  );
};

export default TextEditor;
