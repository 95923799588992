import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
  ENUMS,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    ...DEFAULT_PAGINATION,
    status: ENUMS.STATUS.PUBLISH,
    sortBy: 'publishAt',
    orderBy: 'DESC',
    limit: 12,
    searchBy: 'title',
  },
  meta: null,
  newsList: [],
  newsDetail: null,
  newsByCountry: [],
  carouselNews: [],
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_USER_NEWS_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_USER_NEWS_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_USER_NEWS_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_USER_NEWS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_NEWS_REDUCER:
      return DEFAULT_STATES;
    case ACTION_TYPES.RESET_USER_NEWS_DETAIL:
      return {
        ...state,
        newsDetail: null,
      };
    case ACTION_TYPES.RESET_USER_NEWS_BY_COUNTRY:
      return {
        ...state,
        newsByCountry: [],
      };

    case ACTION_TYPES.FETCH_NEWS_LIST_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        newsList: payload,
      };
    case ACTION_TYPES.FETCH_NEWS_LIST_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.FETCH_NEWS_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        newsByCountry: payload,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.FETCH_NEWS_BY_COUNTRY_FAILURE:
      return {
        ...state,
        newsByCountry: [],
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_NEWS_DETAIL_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        newsDetail: payload,
      };
    case ACTION_TYPES.GET_NEWS_DETAIL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    default:
      return state;
  }
};
