import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ActiveBreadCrumb } from '@/Components/Common';
import DefaultLayout from '@/Components/DefaultLayout';
import { DataTable } from '@/Components/LayoutPart';

import { CountryScoringAction, CountryActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import { DEFAULT_PAGINATION, ENUMS } from '@/Constants';
import Utils from '@/Utils';

import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

interface ICountryPayloadStructure {
  id: string;
  name: string;
  status: string | boolean;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  extraData?: any;
}

interface ICountryOptions {
  label: string;
  value: string;
  code: string;
}

const { fetchAllCountries } = CountryActions;
const { fetchCountryScoring, resetCountryScoringReducer } =
  CountryScoringAction;

const STATUS_OPTIONS = [
  { label: 'All', value: 'all' },
  {
    label: 'Approved',
    value: ENUMS.STATUS.APPROVAL,
  },
  {
    label: 'Waiting for Approval',
    value: ENUMS.STATUS.WAITING_APPROVAL,
    disabled: true,
  },
  {
    label: 'Deleted',
    value: ENUMS.STATUS.TERMINATED,
    disabled: true,
  },
];

const CountryScoringManagement: React.FC = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const countries: ICountryPayloadStructure[] = useSelector(
    (state: RootState) => _.get(state.COUNTRY, 'countries') || null
  );

  const [isMessage, setIsMessage] = useState<string>('');
  const [filter, setFilter] = useState<IPaginationFilter>(DEFAULT_PAGINATION);
  const [countryOptions, setCountryOptions] = useState<ICountryOptions[]>([]);

  useEffect(() => {
    dispatch(fetchAllCountries());
    return () => {
      setFilter(DEFAULT_PAGINATION);
    };
  }, []);

  useEffect(() => {
    if (filter) {
      const statusResolve = filter?.status === 'all' ? '' : filter?.status;
      dispatch(fetchCountryScoring({ ...filter, status: statusResolve }));
    }
    return () => {
      dispatch(resetCountryScoringReducer());
    };
  }, [filter]);

  useEffect(() => {
    if (!_.isEmpty(countries)) {
      const resolveCountries = _.map(
        countries,
        (country: ICountryPayloadStructure) => {
          return {
            label: country.name,
            value: country.id,

            code:
              // countryCode alpha 2: Kosovo
              country?.extraData?.code === 'CS-KM'
                ? 'XK'
                : Utils.getCountryISO2(country?.extraData?.code) || '',
          };
        }
      );

      setCountryOptions(resolveCountries);
    }
  }, [countries]);

  const handleChangeYearFilter = (newValue: Date | null) => {
    if (newValue === null) {
      setFilter({
        ...filter,
        year: '',
      });
      setIsMessage('');
    } else {
      if (dayjs(newValue).isValid()) {
        setFilter({
          ...filter,
          year: _.toString(dayjs(newValue).year()),
        });
        setIsMessage('');
      } else setIsMessage('Please choose year valid');
    }
  };
  const _renderTopSection = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            {' '}
            <TextField
              select
              sx={{ width: 300 }}
              label="Search by country"
              value={filter?.countryId ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFilter({ ...filter, countryId: e.target.value })
              }
            >
              {_.map(countryOptions, (country, index) => {
                return (
                  <MenuItem key={index} value={country.value}>
                    <Box
                      component="div"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    >
                      <Box
                        component="img"
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${_.lowerCase(
                          country.code
                        )}.png`}
                        srcSet={`https://flagcdn.com/w40/${_.lowerCase(
                          country.code
                        )}.png 2x`}
                        alt=""
                      />
                      {country.label}
                    </Box>
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item>
            <DatePicker
              inputFormat="YYYY"
              views={['year']}
              label="Year"
              value={filter?.year || null}
              onChange={handleChangeYearFilter}
              minDate={new Date('2013')}
              maxDate={new Date()}
              PopperProps={{
                placement: 'bottom-start',
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={!!isMessage}
                  sx={{ width: 180 }}
                  helperText={isMessage}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              select
              sx={{ width: 250 }}
              label="Status"
              value={filter?.status || 'all'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFilter({
                  ...filter,
                  status: e.target.value,
                })
              }
            >
              {_.map(STATUS_OPTIONS, (status, index) => {
                return (
                  <MenuItem key={index} value={status.value}>
                    {_.upperCase(status.label)}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              sx={{ height: 55 }}
              onClick={() => setFilter(DEFAULT_PAGINATION)}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderMainSection = () => {
    return (
      <Grid item>
        <DataTable.CountryScoringManagement />
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container direction="column" rowSpacing={4}>
        <Grid item xs={12}>
          <ActiveBreadCrumb screen="COUNTRY_SCORING_ADMIN" />
        </Grid>
        {_renderTopSection()}
        {_renderMainSection()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default CountryScoringManagement;
