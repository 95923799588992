export default {
  dashboard: 'DASHBOARD',
  profile: 'PROFILE',
  events: 'EVENTS',
  eventsList: 'EVENTS LIST',
  eventReport: 'EVENT REPORT',
  reportStatistics: 'REPORT STATISTICS',
  //Event Admin
  eventAdmins: 'EVENT ADMINS',

  boothsList: 'BOOTHS LIST',
  pitchingEvent: 'PITCHING EVENT',

  //Sponsor
  sponsors: 'SPONSOR',
};
