import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemText,
  Card,
  Typography,
  LinearProgress,
  Checkbox,
  CardHeader,
  Collapse,
  ListItemButton,
  TextField,
  Stack,
  Chip,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CompareIcon from '@mui/icons-material/Compare';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { CountryAsyncDropdown, ChartComponent } from '@/Components/Common';
import { RootState, useTypedDispatch } from '@/store';
import { CompareAndExporeActions } from '@/Actions';
import { COMPARE_AND_EXPLORE, ROUTERS } from '@constants';
import Utils from '@/Utils';
import { IMMToolkitTemplate } from '@interfaces/CountryScoring.interface';
import { IFilterOfYear } from '@interfaces/CompareAndExplore.interface';
import { CommonColors } from '@/Themes';
import MandatoryField from '@/Components/LayoutPart/Dialogs/MandatoryField';

const {
  fetchCompareAndExploreByYears,
  saveCompareAndExploreByYears,
  resetCompareAndExploreReducer,
} = CompareAndExporeActions;

const { COMPARE_AREA_1, COMPARE_AREA_2, COMPARE_AREA_3 } = COMPARE_AND_EXPLORE;

const CompareYear: React.FC = () => {
  const dispatch = useTypedDispatch();
  const yearBarCharts: any =
    useSelector((state: RootState) =>
      _.get(state.COMPARE_EXPLORE, 'yearBarCharts')
    ) || [];
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'isFetchLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'isActionLoading')
  );
  const requestHasError = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'requestHasError')
  );
  const previousFilter: IFilterOfYear = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'previousYears')
  );
  const [filterOfYear, setFilterOfYear] = useState<{
    countryId: string;
    listStandardsOfYear: string[];
    collapsedSubAreaOfYear: string[];
    listYears: number[];
  }>({
    countryId: '',
    collapsedSubAreaOfYear: [],
    listStandardsOfYear: [],
    listYears: [],
  });
  const [isEmptyYear, setIsEmptyYear] = useState(false);
  const [message, setMessage] = useState({
    country: '',
    years: '',
    standards: '',
  });
  const refChart = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      dispatch(resetCompareAndExploreReducer());
    };
  }, []);

  useEffect(() => {
    setFilterOfYear(previousFilter);
    if (!_.some(previousFilter, (filter) => _.isEmpty(filter)))
      dispatch(
        fetchCompareAndExploreByYears({
          listYears: previousFilter.listYears,
          countryId: previousFilter.countryId,
          listStandards: _.orderBy(previousFilter.listStandardsOfYear),
        })
      );
  }, [previousFilter]);

  useEffect(() => {
    if (refChart?.current && !requestHasError && !_.isEmpty(yearBarCharts)) {
      refChart.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [requestHasError, yearBarCharts]);
  // Events
  const handleChangeStandardOfYear = (code: string) => {
    if (_.includes(filterOfYear.listStandardsOfYear, code))
      setFilterOfYear({
        ...filterOfYear,
        listStandardsOfYear: _.filter(
          filterOfYear.listStandardsOfYear,
          (el) => el !== code
        ),
      });
    else
      setFilterOfYear({
        ...filterOfYear,
        listStandardsOfYear: [...filterOfYear.listStandardsOfYear, code],
      });
  };

  const handleCollapseSubareaOfYear = (code: string) => {
    if (_.includes(filterOfYear.collapsedSubAreaOfYear, code))
      setFilterOfYear({
        ...filterOfYear,
        collapsedSubAreaOfYear: _.filter(
          filterOfYear.collapsedSubAreaOfYear,
          (el) => el !== code
        ),
      });
    else
      setFilterOfYear({
        ...filterOfYear,
        collapsedSubAreaOfYear: [...filterOfYear.collapsedSubAreaOfYear, code],
      });
  };

  const handleChangeMultipleYears = (years: DateObject[]) => {
    const newYears = _.map(years, (year) => new DateObject(year).year);
    setFilterOfYear({
      ...filterOfYear,
      listYears: _.sortBy(newYears),
    });
    if (isEmptyYear) setIsEmptyYear(false);
  };

  const startCompareYear = () => {
    if (
      _.isEmpty(filterOfYear.listStandardsOfYear) ||
      _.isEmpty(filterOfYear.countryId) ||
      _.isEmpty(filterOfYear.listYears)
    ) {
      setMessage({
        standards: _.isEmpty(filterOfYear.listStandardsOfYear)
          ? 'Please choose list standards'
          : '',
        country: _.isEmpty(filterOfYear.countryId)
          ? 'Please choose country'
          : '',
        years: _.isEmpty(filterOfYear.listYears) ? 'Please choose years' : '',
      });
    } else {
      dispatch(saveCompareAndExploreByYears(filterOfYear));
      dispatch(
        fetchCompareAndExploreByYears({
          listYears: filterOfYear.listYears,
          countryId: filterOfYear.countryId,
          listStandards: _.orderBy(filterOfYear.listStandardsOfYear),
        })
      );
    }
  };
  // Renders
  const _renderOptionsForYears = (area: IMMToolkitTemplate) => {
    const { subAreas } = area;
    return (
      <Grid item xs={12} md={4} key={`year${area.code}`} p={2}>
        <Typography
          variant="h6"
          height={70}
        >{`${area.codeName}: ${area.name}`}</Typography>
        <List dense component="div" role="list" key={`year${area.code}`}>
          {_.map(subAreas, (subArea) => {
            const { standards } = subArea;
            const isSelected = _.includes(
              filterOfYear.collapsedSubAreaOfYear,
              subArea.code
            );
            return (
              <Box key={`year${subArea.code}`}>
                <ListItemButton
                  onClick={() => handleCollapseSubareaOfYear(subArea.code)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {subArea.codeName}: {subArea.name}
                      </Typography>
                    }
                  />
                  {isSelected ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse
                  in={isSelected}
                  timeout="auto"
                  unmountOnExit
                  key={`year${subArea.code}`}
                >
                  <Box key={`year${subArea.code}`} pl={2}>
                    <List component="div" disablePadding>
                      {_.map(standards, (sta) => {
                        return (
                          <Box key={`year${sta.code}box`} pl={2}>
                            <ListItemButton
                              onClick={() =>
                                handleChangeStandardOfYear(sta.code)
                              }
                              title={sta.name}
                            >
                              <Checkbox
                                checked={_.includes(
                                  filterOfYear.listStandardsOfYear,
                                  sta.code
                                )}
                              />
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    <b>{sta.codeName}:</b>
                                    <br />
                                    {sta.originalName}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </Box>
                        );
                      })}
                    </List>
                  </Box>
                </Collapse>
              </Box>
            );
          })}
        </List>
      </Grid>
    );
  };

  const _renderBarChart = (chart: any, key: string) => {
    return _.map(chart, (barChart: any) => {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          key={`${key}${_.get(barChart, 'label')}`}
        >
          <ChartComponent.BarChart
            title=""
            verticalText="Score (%)"
            data={barChart?.chartData}
            subtitle={{
              display: true,
              align: 'start',
              position: 'bottom',
              text: '(*) Click on the graph to get the reports for this country',
            }}
            horizontalText={_.get(barChart, 'label')}
            axis="x"
            onClick={(data) =>
              Utils.redirect(ROUTERS.COUNTRY_REPORT, {
                code: {
                  area: barChart?.code?.areaCode,
                  subArea: barChart?.code?.subCode,
                  standard: barChart?.code?.standardCode,
                },
                country: filterOfYear.countryId,
                year: data?.targetKey,
              })
            }
          />
        </Grid>
      );
    });
  };

  const _renderByYears = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <CountryAsyncDropdown
            disabled={isFetchLoading}
            defaultValue={filterOfYear.countryId}
            onChange={(id: string) => {
              if (id) setMessage({ ...message, country: '' });
              setFilterOfYear({ ...filterOfYear, countryId: id });
            }}
            required
            label="Choose one country"
            message={message.country}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DatePicker
            onlyYearPicker
            value={_.map(
              filterOfYear.listYears,
              (year) => new Date(_.toString(year))
            )}
            disabled={isFetchLoading}
            onChange={(newValue: DateObject[]) => {
              if (newValue) setMessage({ ...message, years: '' });
              handleChangeMultipleYears(newValue);
            }}
            plugins={[<DatePanel key={0} sort="date" markFocused />]}
            multiple
            hideYear
            maxDate={new Date()}
            minDate={new Date('2013')}
            required
            render={(_value: any, openCalendar: any) => {
              return (
                <TextField
                  fullWidth
                  label={`${filterOfYear.listYears.length} years chosen`}
                  required
                  disabled
                  onClick={openCalendar}
                  error={!_.isEmpty(message.years)}
                  helperText={message.years}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: !_.isEmpty(message.years)
                          ? CommonColors.error
                          : 'inherit', //color error MUI
                      },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={openCalendar}>
                          <CalendarMonthIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={3}>
          <Stack direction="row" spacing={1} rowGap={1} flexWrap="wrap">
            {_.map(filterOfYear.listYears, (year, index) => {
              return (
                <Chip key={`year${index}`} label={year} variant="outlined" />
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                md: 'flex-start',
                sm: 'flex-start',
                lg: 'flex-end',
              },
            }}
          >
            <Button
              size="large"
              variant="outlined"
              disabled={isActionLoading}
              onClick={() => {
                setMessage({
                  country: '',
                  years: '',
                  standards: '',
                });
                dispatch(resetCompareAndExploreReducer(true));
                setFilterOfYear({
                  countryId: '',
                  collapsedSubAreaOfYear: [],
                  listStandardsOfYear: [],
                  listYears: [],
                });
              }}
            >
              Clear all
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              ':hover': {
                transform: 'none',
              },
            }}
          >
            <CardHeader
              sx={{ px: 2, py: 1 }}
              title="Choose standards"
              subheader={`${
                filterOfYear.listStandardsOfYear.length
              }/${24} standards selected`}
              action={
                <LoadingButton
                  loading={isFetchLoading}
                  variant="contained"
                  color="burntSienna"
                  onClick={() => startCompareYear()}
                  startIcon={<CompareIcon />}
                  disabled={filterOfYear.listStandardsOfYear.length === 0}
                >
                  Start Compare ({filterOfYear.listStandardsOfYear.length})
                </LoadingButton>
              }
            />
            <Divider />
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {_renderOptionsForYears(COMPARE_AREA_1)}
              {_renderOptionsForYears(COMPARE_AREA_2)}
              {_renderOptionsForYears(COMPARE_AREA_3)}
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {isFetchLoading && <LinearProgress />}
          <Grid container spacing={2} ref={refChart}>
            {yearBarCharts && _renderBarChart(yearBarCharts, 'year')}
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    yearBarCharts,
    filterOfYear,
    isFetchLoading,
    isEmptyYear,
    previousFilter,
    message,
  ]);

  const renderMain = () => {
    return (
      <Grid container pb={2}>
        <MandatoryField
          messages={[message.standards]}
          isOpen={!!message.standards}
          onCancel={() => setMessage({ ...message, standards: '' })}
          title="Mandatory fields"
        />
        <Grid item xs={12}>
          <BreadCrumb title="Compare Across Years" />
        </Grid>
        <Grid item xs={12}>
          {_renderByYears}
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="USER" content={renderMain()} />;
};

export default CompareYear;
