import React from 'react';
// import { Button, IconButton, Tooltip } from '@mui/material';
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// import { StickyHeadTable } from '@/Components/Common/Table';

import { IPaginationMeta } from '@/Interfaces/PaginationMeta.interface';
// import dayjs from 'dayjs';

// interface IReportDataStructure {
//   year: string;
//   reviewer: string;
//   processing: string;
//   status: JSX.Element;
//   action: JSX.Element;
// }

interface ISectionProps {
  payload: any[];
  meta?: IPaginationMeta;
  onRowAction(report?: any, action?: 'edit'): void;
  onPageAction(value: number, type: 'limit' | 'page'): void;
}

// const COUNTRY_SCORING_COLUMNS = [
//   { id: 'year', label: 'Year', minWidth: 5, align: 'center' },
//   { id: 'reviewer', label: 'Reviewer', minWidth: 100, align: 'center' },
//   { id: 'processing', label: 'Processing', minWidth: 100, align: 'center' },
//   { id: 'status', label: 'Status', minWidth: 80, align: 'center' },
//   { id: 'action', label: 'Actions', minWidth: 80, align: 'center' },
// ];

// const createDataTable = (
//   year: string,
//   reviewer: string,
//   processing: string,
//   status: JSX.Element,
//   action: JSX.Element
// ): IReportDataStructure => {
//   return {
//     year,
//     reviewer,
//     processing,
//     status,
//     action,
//   };
// };

const CountryScoringTable: React.FC<ISectionProps> = (
  {
    // payload,
    // meta,
    // onRowAction,
    // onPageAction,
  }
) => {
  // const [rows, setRows] = React.useState<any[]>([]);

  // React.useEffect(() => {
  //   const resolveRows: IReportDataStructure[] = [];
  //   if (!_.isEmpty(payload)) {
  //     _.forEach(payload, (item: IReportDataStructure) => {
  //       const { year, reviewer, processing } = item;
  //       const isValidTime = dayjs(year).isValid();
  //       resolveRows.push(
  //         createDataTable(
  //           isValidTime ? dayjs(year).format('YYYY') : 'N/I',
  //           reviewer,
  //           processing,
  //           <Button size="small" color="success" variant="outlined">
  //             Active
  //           </Button>,
  //           <Tooltip title="Click to Edit">
  //             <IconButton onClick={() => onRowAction(item, 'edit')}>
  //               <DragIndicatorIcon />
  //             </IconButton>
  //           </Tooltip>
  //         )
  //       );
  //       setRows(resolveRows);
  //     });
  //   }
  //   setRows(resolveRows);
  // }, [payload]);

  return (
    // <StickyHeadTable
    //   columns={COUNTRY_SCORING_COLUMNS}
    //   rows={rows}
    //   paginate={meta}
    //   onAction={(value: number, type: 'limit' | 'page') =>
    //     onPageAction(value, type)
    //   }
    // />
    <div></div>
  );
};

export default CountryScoringTable;
