import _ from 'lodash';
import { ROUTERS, ACTION_TYPES, ENUMS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  ILoginStructure,
  IRefreshToken,
  IForgotPasswordStructure,
  IResetPasswordStructure,
  IVefiryAccount,
} from '@interfaces/Auth.interface';
import { IUpdateUser, IUpdateSelfPassword } from '@interfaces/User.interface';

// SINGLE ACTIONS
const setLogout = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};

const setAuthGetLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_AUTH_GET_LOADING,
    payload,
  };
};

const setAuthActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_AUTH_ACTION_LOADING,
    payload,
  };
};

const setForgotPasswordStep = (payload: number) => {
  return {
    type: ACTION_TYPES.SET_RESET_PASSWORD_STEP,
    payload,
  };
};

const clearChangePasswordStatus = () => {
  return {
    type: ACTION_TYPES.RESET_CHANGE_PASSWORD_STATUS,
  };
};

const logout = () => {
  return async (dispatch: any) => {
    Utils.clearAllSavedData();
    dispatch(setLogout());
    Utils.replace(ROUTERS.AUTH);
  };
};

// ASYNC ACTIONS
const loginSuccess = () => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
  };
};

const loginFail = () => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
  };
};

const login = (payload: ILoginStructure) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading(true));
    await API.login(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(loginFail());
        else {
          const token = _.get(result, 'accessToken.token') || '';
          const refreshToken = _.get(result, 'accessToken.refreshToken') || '';
          const userPayload = _.get(result, 'user.userData');
          const userRole = _.get(result, 'user.role.roleCode') || '';
          const fullname = _.get(result, 'user.userData.fullName') || '';
          const email = _.get(result, 'user.email') || '';
          const userId = _.get(result, 'user.id') || '';
          Utils.saveToken(token);
          Utils.saveRefreshToken(refreshToken);
          Utils.saveUserData(userPayload);
          Utils.saveUserRole(userRole);
          Utils.saveFullName(fullname);
          Utils.saveUserEmail(email);
          Utils.saveUserId(userId);
          await Utils.sleep(2500);
          if (userRole) {
            if (userRole === ENUMS.ROLES.ADMIN)
              Utils.redirect(ROUTERS.ADMIN_DASHBOARD);
            if (userRole === ENUMS.ROLES.REVIEWER)
              Utils.redirect(ROUTERS.REVIEWER_DASHBOARD);
            if (userRole === ENUMS.ROLES.USER) Utils.redirect(ROUTERS.HOME);
          }
          dispatch(loginSuccess());
        }
        return true;
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(loginFail());
      });
  };
};

const refreshTokenSuccess = () => {
  return {
    type: ACTION_TYPES.REFRESH_TOKEN_SUCCESS,
  };
};

const refreshTokenFailure = () => {
  return {
    type: ACTION_TYPES.REFRESH_TOKEN_FAILURE,
  };
};

const checkRefreshToken = (payload: IRefreshToken) => {
  return async (dispatch: any) => {
    await API.checkRefreshToken(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) {
          dispatch(logout());
          await dispatch(refreshTokenFailure());
        } else {
          const token = _.get(result, 'accessToken.token') || '';
          Utils.saveToken(token);
          await dispatch(refreshTokenSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(refreshTokenFailure());
        dispatch(logout());
      });
  };
};

const requestForgotPasswordSuccess = () => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS,
  };
};

const requestForgotPasswordFailure = () => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE,
  };
};

const requestForgotPassword = (payload: IForgotPasswordStructure) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading(true));
    await API.forgotPassword(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(requestForgotPasswordFailure());
        else {
          await dispatch(requestForgotPasswordSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(requestForgotPasswordFailure());
      });
  };
};

const resetPasswordSuccess = () => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_SUCCESS,
  };
};

const resetPasswordFailure = () => {
  return {
    type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE,
  };
};

const resetPassword = (payload: IResetPasswordStructure) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading(true));
    await API.resetPassword(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(resetPasswordFailure());
        else {
          await dispatch(resetPasswordSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(resetPasswordFailure());
      });
  };
};

const getSelfSuccess = (payload: IUpdateUser) => {
  return {
    type: ACTION_TYPES.GET_SELF_PROFILE_SUCCESS,
    payload,
  };
};

const getSelfFailure = () => {
  return {
    type: ACTION_TYPES.GET_SELF_PROFILE_FAILURE,
  };
};

const getSelfProfile = () => {
  return async (dispatch: any) => {
    dispatch(setAuthGetLoading(true));
    await API.getSelfProfile()
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getSelfFailure());
        else await dispatch(getSelfSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(getSelfFailure());
      });
  };
};

const updateSelfSuccess = (payload: IUpdateUser) => {
  return {
    type: ACTION_TYPES.UPDATE_SELF_PROFILE_SUCCESS,
    payload,
  };
};

const updateSelfFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_SELF_PROFILE_FAILURE,
  };
};

const updateSelfProfile = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading(true));
    await API.updateSelfProfile(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);

        if (!result) await dispatch(updateSelfFailure());
        else {
          const userPayload = _.get(result, 'userData');
          const fullname = _.get(result, 'userData.fullName') || '';
          Utils.saveUserData(userPayload);
          Utils.saveFullName(fullname);
          await dispatch(updateSelfSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(updateSelfFailure());
      });
  };
};

const updateSelfPasswordSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_SELF_PASSWORD_SUCCESS,
  };
};

const updateSelfPasswordFailure = () => {
  return {
    type: ACTION_TYPES.UPDATE_SELF_PASSWORD_FAILURE,
  };
};

const updateSelfPassword = (payload: IUpdateSelfPassword) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading(true));
    await API.updateSelfPassword(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateSelfPasswordFailure());
        else {
          await dispatch(updateSelfPasswordSuccess());
          await dispatch(clearChangePasswordStatus());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(updateSelfPasswordFailure());
      });
  };
};

const verifyAccountSuccess = () => {
  return {
    type: ACTION_TYPES.VERIFY_ACCOUNT_SUCCESS,
  };
};

const verifyAccountFailure = () => {
  return {
    type: ACTION_TYPES.VERIFY_ACCOUNT_FAILURE,
  };
};

const verifyAccount = (payload: IVefiryAccount) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading(true));
    await API.verifyAccount(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(verifyAccountFailure());
        else {
          await dispatch(verifyAccountSuccess());
          Utils.replace(ROUTERS.AUTH);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        dispatch(verifyAccountFailure());
      });
  };
};

export default {
  login,
  logout,
  setLogout,
  checkRefreshToken,
  requestForgotPassword,
  resetPassword,
  getSelfProfile,
  updateSelfProfile,
  updateSelfPassword,
  verifyAccount,
  setForgotPasswordStep,
};
