import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  Button,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tooltip,
  Stack,
  Pagination,
  Box,
  TextField,
  InputAdornment,
  Grow,
} from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HistoryIcon from '@mui/icons-material/History';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';

import { BreadCrumb, Popup, Dialogs } from '@/Components/LayoutPart';
import DefaultLayout from '@/Components/DefaultLayout';
import {
  Dropdown,
  NoDataWereFound,
  SkeletonLoading,
  LazyLoad,
  LightboxImage,
} from '@/Components/Common';

import { useTypedDispatch, RootState } from '@/store';
import { GalleryActions } from '@actions';
import { DEFAULT_CONFIRM, DEFAULT_PAGINATION, ENUMS } from '@/Constants';

import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';
import {
  IFileDetailsStructure,
  IGalleryStructure,
} from '@/Interfaces/File.interface';

const {
  fetchGalleries,
  resetGalleryReducer,
  activeGallery,
  deactiveGallery,
  terminateGallery,
} = GalleryActions;

type IOpenKey = 'uploads' | 'details' | 'lightbox';

type IOpenStructure = {
  [key in IOpenKey]: boolean;
};

const DEFAULT_GALLERIES_PAGINATION = {
  ...DEFAULT_PAGINATION,
  page: 1,
  limit: 12,
  status: ENUMS.STATUS.ACTIVE,
};

const GalleryManagement: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'isFetchLoading')
  );

  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'pagination')
  );

  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'meta')
  );

  const galleries = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'galleries')
  );

  const [details, setDetails] = useState<IGalleryStructure[] | null>(null);

  const [isOpen, setIsOpen] = useState<IOpenStructure>({
    uploads: false,
    details: false,
    lightbox: false,
  });

  const [confirm, setConfirm] = useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [filter, setFilter] = useState<IPaginationFilter>(pagination);
  const [selectedImage, setSelectedImage] =
    useState<IFileDetailsStructure | null>(null);

  useEffect(() => {
    dispatch(fetchGalleries(DEFAULT_GALLERIES_PAGINATION));
    return () => {
      dispatch(resetGalleryReducer());
      setSelectedImage(null);
      setConfirm({ ...confirm, isOpen: false });
    };
  }, []);

  useEffect(() => {
    setDetails(galleries);
  }, [galleries]);

  const handleOpen = (key: IOpenKey, value: boolean) => {
    setIsOpen((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(fetchGalleries({ ...pagination, page: value }));
  };

  const handleClear = () => {
    setFilter(DEFAULT_GALLERIES_PAGINATION);
    dispatch(fetchGalleries(DEFAULT_GALLERIES_PAGINATION));
  };

  const handleSubmit = () => {
    setConfirm({ ...confirm, isOpen: false });
    const { id, status } = confirm.state;
    if (id)
      switch (filter.status) {
        case ENUMS.STATUS.ACTIVE:
          dispatch(deactiveGallery(id, filter));
          break;
        case ENUMS.STATUS.INACTIVE:
          if (status && status === ENUMS.STATUS.ACTIVE)
            dispatch(activeGallery(id, filter));
          else dispatch(terminateGallery(id, filter));
          break;
      }
    return true;
  };

  const _renderTopSection = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <TextField
          label="Search by name"
          placeholder="Search by name"
          value={filter.keyword}
          onChange={(e) => setFilter({ ...filter, keyword: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              dispatch(
                fetchGalleries({
                  ...filter,
                  keyword: _.trim(filter?.keyword),
                })
              );
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => dispatch(fetchGalleries(filter))}
                  disabled={!filter.keyword}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Dropdown
          sx={{ width: 150 }}
          label="Search by status"
          listOf="GALLERY_STATUS"
          value={filter?.status || ''}
          onChange={(value: string) => {
            setFilter({ ...filter, status: value });
            dispatch(
              fetchGalleries({
                ...filter,
                status: value,
              })
            );
          }}
        />
        <Button size="medium" variant="outlined" onClick={() => handleClear()}>
          Clear Filters
        </Button>
      </Box>
      <Box>
        <Button
          size="large"
          variant="contained"
          startIcon={<FileUploadIcon />}
          onClick={() => handleOpen('uploads', !isOpen.uploads)}
        >
          Upload Image
        </Button>
      </Box>
    </Box>
  );

  const _renderGallery = useCallback(() => {
    if (_.isEmpty(details) && _.isEmpty(galleries)) return <NoDataWereFound />;
    return (
      <Box sx={{ minHeight: 700 }}>
        <ImageList
          sx={{ width: '100%', minHeight: 1 }}
          cols={4}
          gap={20}
          variant="standard"
        >
          {_.map(details, (item: IGalleryStructure) => {
            const statusLabel =
              item.status === ENUMS.STATUS.ACTIVE ? 'deactivate' : 'terminate';
            return (
              <ImageListItem
                key={item.id}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedImage(item?.file || null);
                  setIsOpen({ ...isOpen, lightbox: true });
                }}
              >
                <LazyLoad
                  src={`${item.file.path}`}
                  alt={item.file.nameConvert}
                  style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                />
                <ImageListItemBar
                  sx={{ transition: 'background 1s' }}
                  title={item.file.nameConvert}
                  actionIcon={
                    <Stack flexDirection="row">
                      {item.status === ENUMS.STATUS.INACTIVE && (
                        <Tooltip title={`Activate this image`}>
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setConfirm({
                                ...confirm,
                                state: {
                                  ...confirm.state,
                                  id: item.id,
                                  status: ENUMS.STATUS.ACTIVE,
                                },
                                isOpen: true,
                                message: `This will activate this file: ${item.file.nameConvert}`,
                              });
                            }}
                          >
                            <HistoryIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title={`${_.startCase(statusLabel)} this image`}>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setConfirm({
                              ...confirm,
                              state: {
                                ...confirm.state,
                                id: item.id,
                                status: ENUMS.STATUS.TERMINATED,
                              },
                              isOpen: true,
                              message: `This will ${statusLabel} this file: ${item.file.nameConvert}`,
                            });
                          }}
                        >
                          {item.status === ENUMS.STATUS.ACTIVE ? (
                            <HighlightOffIcon />
                          ) : (
                            <DeleteOutlineIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
        <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>
          <Pagination
            sx={{
              display: 'flex',
              justifyContent: 'center',
              transitionDelay: '1s',
            }}
            count={meta?.totalPages || 1}
            page={meta?.currentPage || 1}
            shape="rounded"
            onChange={handleChangePage}
          />
        </Grow>
      </Box>
    );
  }, [meta, details, galleries]);

  const _renderLightboxImage = () => {
    if (!details) return;
    const resolvePayload = _.map(details, (item) => {
      return {
        src: item.file.path,
        alt: item.file.nameConvert,
      };
    });
    const findIndex = _.findIndex(
      details,
      (item) => item?.file?.path === selectedImage?.path
    );

    return (
      <LightboxImage
        payload={resolvePayload}
        open={isOpen.lightbox}
        onClose={() => {
          handleOpen('lightbox', !isOpen.lightbox);
          setSelectedImage(null);
        }}
        index={findIndex}
      />
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadCrumb title="Gallery Management" />
        </Grid>
        <Grid item xs={12}>
          {_renderTopSection()}
        </Grid>
        <Grid item xs={12}>
          {!isFetchLoading ? (
            _renderGallery()
          ) : (
            <SkeletonLoading numberRender={5} />
          )}
          {_renderLightboxImage()}
        </Grid>

        <Grid item xs={12}>
          <Popup.UploadMedia
            open={isOpen.uploads}
            onClose={() => handleOpen('uploads', !isOpen.uploads)}
          />
          <Dialogs.Confirm
            callback={() => handleSubmit()}
            confirm={confirm}
            onCancel={() => setConfirm({ ...confirm, isOpen: false })}
          />
        </Grid>
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default GalleryManagement;
