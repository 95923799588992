import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { ICreateRole, IUpdateRole } from '@interfaces/Role.interface';

const { ROLE } = API;

export const createRole = async (payload: ICreateRole) => {
  return sendRequest(ROLE.ROOT, 'POST', payload);
};

export const updateRole = async (payload: IUpdateRole) => {
  return sendRequest(`${ROLE.ROOT}${payload.id}`, 'PUT', payload);
};

export const fetchRoles = async () => {
  return sendRequest(ROLE.ROOT, 'GET');
};

export const getRoleById = async (id: string) => {
  return sendRequest(`${ROLE.GET_BY_ID}${id}`, 'GET');
};

export const activeRole = async (id: string) => {
  return sendRequest(`${ROLE.ACTIVE}${id}`, 'PUT');
};

export const deactiveRole = async (id: string) => {
  return sendRequest(`${ROLE.DEACTIVE}${id}`, 'PUT');
};
