import React, { useState, useEffect, memo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { CircularProgress, Autocomplete, TextField, Box } from '@mui/material';

import { useTypedDispatch, RootState } from '@/store';
import { CountryActions } from '@actions';
import Utils from '@utils';

import { IDropdownOption } from '@interfaces/Dropdown.interface';

const {
  fetchAllCountries,
  fetchAllAvaiableCountries,
  fetchCountryUnassignedForReview,
  fetchCountryUnassignedForUser,
  resetCountryReducer,
} = CountryActions;

type ITypeGetFor =
  | 'ALL'
  | 'AVAIABLE'
  | 'REVIEWER'
  | 'UNASSIGNED_FOR_REVIEW'
  | 'UNASSIGNED_FOR_USER';
interface ISectionProps {
  roleCode?: 'user' | 'reviewer';
  onChange?(value: any, labels?: string[] | string): void;
  disabled?: boolean;
  getFor?: ITypeGetFor;
  multiples?: boolean;
  defaultValue?: any;
  onChangeFullOptions?(value: any): void;
  required?: boolean;
  isEditAssignCountries?: boolean;
  label?: string;
  message?: string;
  getForNews?: boolean;
}

const CountryAsynchronousDropdown: React.FC<ISectionProps> = (
  props: ISectionProps
) => {
  const {
    onChange,
    disabled,
    getFor,
    multiples,
    defaultValue,
    onChangeFullOptions,
    roleCode = 'user',
    required,
    isEditAssignCountries = false,
    label,
    message,
    getForNews = false,
  } = props;

  const dispatch = useTypedDispatch();
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'isGetLoading')
  );
  const countries = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'countries')
  );
  const avaiableCountries = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'avaiableCountries')
  );
  const unassignedForUser = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'unassignedForUser')
  );
  const unassignedForReview = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'unassignedForReview')
  );

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const [renderKey, setRenderKey] = useState(Math.random());
  const [currentValue, setCurrentValue] = useState<any>(null);

  useEffect(() => {
    return () => {
      dispatch(resetCountryReducer());
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(options) || defaultValue) {
      const rawOption = (type?: ITypeGetFor) => {
        switch (type) {
          case 'AVAIABLE':
            return avaiableCountries;
          case 'UNASSIGNED_FOR_REVIEW':
            return unassignedForReview;
          case 'UNASSIGNED_FOR_USER':
            return unassignedForUser;
          default:
            return countries;
        }
      };
      const results: IDropdownOption[] = [];
      const userId = Utils.getSavedUserId();
      if (rawOption) {
        _.forEach(rawOption(getFor), (country) => {
          //remove countries not reviewed by user
          if (userId !== _.get(country, 'reviewer.id') && getFor === 'REVIEWER')
            return;
          const countryCode =
            _.get(country, 'extraData.code') === 'CS-KM'
              ? 'XK'
              : Utils.getCountryISO2(_.get(country, 'extraData.code') || '');
          results.push({
            label: _.get(country, 'name') || '',
            value: _.get(country, 'id') || '',
            code: countryCode || '',
          });
        });
      }
      setOptions(results);
      setRenderKey(Math.random());
    }
  }, [countries, avaiableCountries, unassignedForReview, unassignedForUser]);

  useEffect(() => {
    choseTypeRequest();
  }, [roleCode]);

  const choseTypeRequest = () => {
    switch (getFor) {
      case 'AVAIABLE': {
        dispatch(fetchAllAvaiableCountries());
        break;
      }
      case 'UNASSIGNED_FOR_REVIEW': {
        dispatch(fetchCountryUnassignedForReview());
        break;
      }
      case 'UNASSIGNED_FOR_USER': {
        dispatch(fetchCountryUnassignedForUser());
        break;
      }
      default: {
        dispatch(fetchAllCountries(getForNews));
        break;
      }
    }
  };

  // Events
  const onchangeOptions = (
    option: IDropdownOption | IDropdownOption[] | null
  ) => {
    if (onChange) {
      setCurrentValue(option);
      if (_.isArray(option))
        onChange(
          _.map(option, (e) => e.value),
          _.map(option, (e) => e.label)
        );
      else onChange(option?.value, option?.label);
    }
    if (onChangeFullOptions) onChangeFullOptions(option);
  };

  const getDefaultValue = (): IDropdownOption[] | IDropdownOption | null => {
    if (multiples) {
      const results: IDropdownOption[] = [];
      _.forEach(options, (option) => {
        const findData = _.includes(defaultValue, option.value);
        if (findData) results.push(option);
      });
      return results;
    }
    return _.find(options, ['value', defaultValue]) || null;
  };

  const handleRequiredField = () => {
    if (multiples && (getFor === 'UNASSIGNED_FOR_REVIEW' || getFor === 'ALL')) {
      if (isEditAssignCountries) return false;
      if (currentValue)
        return currentValue && _.size(currentValue) === 0 ? true : false;
    }
    return required;
  };

  return (
    <Autocomplete
      className={!_.isEmpty(message) ? 'required' : ''}
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option?.label === value?.label}
      getOptionLabel={(option: any) => option.label}
      options={options}
      loading={isGetLoading}
      disabled={disabled}
      multiple={multiples}
      onChange={(_e, option) => {
        onchangeOptions(option as any);
      }}
      value={getDefaultValue()}
      defaultValue={getDefaultValue()}
      key={renderKey}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!_.isEmpty(message)}
          helperText={message ?? ''}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            required: handleRequiredField(),
            endAdornment: (
              <React.Fragment>
                {isGetLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      noOptionsText={'No Options'}
      renderOption={(extendProps, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...extendProps}
        >
          {option.code && (
            <Box
              component="img"
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${_.lowerCase(option.code)}.png`}
              srcSet={`https://flagcdn.com/w40/${_.lowerCase(
                option.code
              )}.png 2x`}
              alt=""
            />
          )}

          {option.label}
        </Box>
      )}
    />
  );
};

export default memo(CountryAsynchronousDropdown);
