import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Box,
  Typography,
  MenuItem,
  Menu,
  Avatar,
  Tooltip,
  Grid,
  IconButton,
  Card,
  CardHeader,
  CardMedia,
  Badge,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { logoStyles, mainStyles } from './AppBar.styles';
import { CommonStyles, RichTooltip } from '@/Components/Common';
import Utils from '@utils';
import { BASIC_ROUTERS, ENUMS, LIST, ROUTERS } from '@constants';

import { useTypedDispatch, RootState } from '@/store';
// import mainLogo from '@/Assets/Logos/mainLogo.png';
import mainLogo from '@/Assets/Logos/MonitoringMatrix.svg';
import { AuthActions } from '@/Actions';
import NoAvatar from '@/Assets/NoAvatar/NoAvatar.png';

import { ISideBar } from '@interfaces/Menu.interface';

const { USER_SETTINGS, ADMIN_SETTINGS, REVIEWER_SETTINGS, MENU_LIST } = LIST;

const { logout } = AuthActions;
interface SectionProps {
  onOpenNotifications: (open?: boolean) => void;
}

const AppBarDesktop: React.FC<SectionProps> = ({ onOpenNotifications }) => {
  // Package constants
  const dispatch = useTypedDispatch();
  // Constructors
  const pathName = useLocation().pathname;
  const isBreakLine = useMediaQuery('(max-width:1192px)');
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const userData = Utils.getSavedUserData();
  const roleName = Utils.getUserRole();
  const fullname = Utils.getFullName();
  const email = Utils.getSavedUserEmail();
  const token = Utils.getSavedToken();
  const count: { read: number; unread: number } = useSelector(
    (state: RootState) => _.get(state.USER_NOTIFICATION, 'count')
  );

  const [seletedItem, setSelectedItem] = useState<string | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClickCardSetting = (value: string) => {
    switch (roleName) {
      case ENUMS.ROLES.ADMIN:
        return Utils.redirect(ROUTERS.ACCOUNT_ADMIN);
      case ENUMS.ROLES.REVIEWER:
        return Utils.redirect(ROUTERS.ACCOUNT_REVIEWER);
      case ENUMS.ROLES.USER:
        return Utils.redirect(value);
      default:
        return '';
    }
  };

  const handleClickLabelSetting = (value: string) => {
    if (value === ROUTERS.AUTH) dispatch(logout());
    else Utils.redirect(value);
  };

  const checkRoleName = () => {
    switch (roleName) {
      case ENUMS.ROLES.ADMIN:
        return ADMIN_SETTINGS;
      case ENUMS.ROLES.REVIEWER:
        return REVIEWER_SETTINGS;
      case ENUMS.ROLES.USER:
        return USER_SETTINGS;
      default:
        return [];
    }
  };

  // Renders
  const _renderSettings = () => {
    const settings = checkRoleName();
    return _.map(settings, (setting, index) => {
      if (setting.label === 'Notifications') {
        return (
          <MenuItem
            key={index}
            onClick={() => {
              onOpenNotifications(true);
              setAnchorElUser(null);
            }}
          >
            <Badge
              badgeContent={count?.unread || 0}
              color="primary"
              sx={{ mr: 2 }}
            >
              {setting.icon}
            </Badge>
            <Typography align="center" sx={{ textTransform: 'capitalize' }}>
              {setting.label}
            </Typography>
          </MenuItem>
        );
      }
      return (
        <MenuItem
          key={index}
          onClick={() => handleClickLabelSetting(setting.value)}
        >
          {setting.icon}
          <Typography align="center" sx={{ textTransform: 'capitalize' }}>
            {setting.label}
          </Typography>
        </MenuItem>
      );
    });
  };

  const _renderUserSettings = () => {
    return (
      <Box>
        {userData && token && (
          <Tooltip title="Open settings">
            <Badge badgeContent={count?.unread || 0} color="primary">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    background: 'rgba(0,0,0,0.2)',
                    borderRight: 0.5,
                  }}
                  alt="userAvatar"
                  src={userData?.avatar?.path || NoAvatar}
                />
              </IconButton>
            </Badge>
          </Tooltip>
        )}
        {(!userData || !token) && pathName !== BASIC_ROUTERS.AUTH && (
          <Button
            variant="contained"
            color="burntSienna"
            onClick={() => Utils.replace(ROUTERS.AUTH)}
          >
            Login
          </Button>
        )}
        {userData && token && (
          <Menu
            sx={{ mt: '50px', zIndex: 10000 }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}
          >
            <MenuItem
              sx={{ width: 300 }}
              onClick={() => handleClickCardSetting(ROUTERS.PROFILE)}
            >
              <Card sx={{ width: 1 }}>
                <CardHeader
                  title={fullname || 'Anonymous'}
                  subheader={email || ''}
                />
                <CardMedia
                  component="img"
                  height="100"
                  sx={{
                    objectFit: userData?.avatar?.path ? 'cover' : 'contain',
                    p: 1,
                  }}
                  image={userData?.avatar?.path || NoAvatar}
                  alt="Avatar"
                />
              </Card>
            </MenuItem>
            {_renderSettings()}
          </Menu>
        )}
      </Box>
    );
  };

  const _renderOptions = (options: ISideBar[], key: string) => {
    return (
      <List
        key={key}
        component="nav"
        disablePadding
        onMouseLeave={() => setSelectedItem(null)}
      >
        {_.map(options, (option, index) => {
          const isActive = pathName === option.value;
          const activeEffect = isActive
            ? CommonStyles.activeBorderEffect
            : null;
          return (
            <Box key={index}>
              <ListItemButton
                key={index}
                sx={{ ...CommonStyles.hoverBorderEffect, ...activeEffect }}
                onClick={() => Utils.redirect(option.value)}
              >
                <ListItemText primary={option.label} />
              </ListItemButton>
              <Divider />
            </Box>
          );
        })}
      </List>
    );
  };

  const _renderNavs = () => {
    return _.map(MENU_LIST.USER_MENU, (item, index) => {
      const { subOptions, value } = item;
      const activeOnSub = _.find(
        subOptions || [],
        (el) => el.value === pathName
      );
      const isActive = pathName === value;
      const activeEffect =
        isActive || activeOnSub ? CommonStyles.activeBorderEffect : null;

      const elKey = `parent${index}`;
      if (subOptions)
        return (
          <Grid item key={index}>
            <RichTooltip
              key={elKey}
              open={seletedItem === elKey}
              placement="bottom-start"
              onClose={() => setSelectedItem(null)}
              content={_renderOptions(subOptions, elKey)}
            >
              <Grid
                container
                px={2}
                py={1}
                sx={{
                  ...CommonStyles.hoverBorderEffect,
                  ...activeEffect,
                }}
                onMouseOver={() => setSelectedItem(elKey)}
              >
                <Grid item>{item.icon}</Grid>
                <Grid item>{item.label}</Grid>
                <Grid item>
                  <ExpandMoreIcon />
                </Grid>
              </Grid>
            </RichTooltip>
          </Grid>
        );
      return (
        <Grid item key={index}>
          <Grid
            container
            px={2}
            py={1}
            sx={{ ...CommonStyles.hoverBorderEffect, ...activeEffect }}
            onClick={() => Utils.redirect(item.value)}
            onMouseOver={() => setSelectedItem(null)}
          >
            <Grid item>{item.icon}</Grid>
            <Grid item>{item.label}</Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container sx={CommonStyles.displayInDesktop}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isBreakLine ? 'space-between' : 'inherit',
          mx: 4,
          gap: 2,
          ...mainStyles,
          minHeight: 60,
        }}
      >
        <Box
          onClick={() => Utils.redirect(ROUTERS.HOME)}
          component="img"
          alt="monitoringmatrix"
          src={mainLogo}
          sx={logoStyles}
        />
        {!isBreakLine && (
          <Grid container alignItems="center" justifyContent="center">
            {_renderNavs()}
          </Grid>
        )}
        {_renderUserSettings()}
      </Grid>
      {isBreakLine && (
        <Grid container alignItems="center" justifyContent="center">
          {_renderNavs()}
        </Grid>
      )}
    </Grid>
  );
};

export default AppBarDesktop;
