import { Outlet } from 'react-router-dom';
import { ROUTERS } from '@constants';

// Protected Route
import ProtectedRoute from './Protected.router';
import { AdminContainer } from '@containers';

const AdminRouters = {
  element: <Outlet />,
  path: ROUTERS.ADMIN_DASHBOARD,
  children: [
    {
      path: `${ROUTERS.ADMIN_DASHBOARD}`,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_DASHBOARD}>
          <AdminContainer.AdminDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.USER_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.USER_MANAGEMENT}>
          <AdminContainer.UserList />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.CREATE_USER}`,
      element: (
        <ProtectedRoute location={ROUTERS.CREATE_USER}>
          <AdminContainer.NewUser />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.UPDATE_USER}`,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_USER}>
          <AdminContainer.UpdateUser />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ACCOUNT_ADMIN}`,
      element: (
        <ProtectedRoute location={ROUTERS.ACCOUNT_ADMIN}>
          <AdminContainer.AdminAccount />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.NEWS_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.NEWS_MANAGEMENT}>
          <AdminContainer.NewsManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.NEW_POST}`,
      element: (
        <ProtectedRoute location={ROUTERS.NEW_POST}>
          <AdminContainer.NewPost />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.SOCIAL_LINKS}`,
      element: (
        <ProtectedRoute location={ROUTERS.SOCIAL_LINKS}>
          <AdminContainer.SocialLinks />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.FOOTER_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.FOOTER_MANAGEMENT}>
          <AdminContainer.FooterManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.UPDATE_NEWS}`,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_NEWS}>
          <AdminContainer.UpdateNews />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ADMIN_BACKGROUND}`,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_BACKGROUND}>
          <AdminContainer.APBackground />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ADMIN_GUIDELINES}`,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_GUIDELINES}>
          <AdminContainer.APGuidelines />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ADMIN_METHODOLOGY}`,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_METHODOLOGY}>
          <AdminContainer.APMethodology />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ADMIN_MMTOOLKIT}`,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_MMTOOLKIT}>
          <AdminContainer.APMMToolkit />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.COUNTRY_REPORT_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.COUNTRY_REPORT_MANAGEMENT}>
          <AdminContainer.APCountryReport />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.COUNTRY_REPORT_DETAIL}`,
      element: (
        <ProtectedRoute location={ROUTERS.COUNTRY_REPORT_DETAIL}>
          <AdminContainer.APUpdateCountryReport />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.REGIONAL_REPORT_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.REGIONAL_REPORT_MANAGEMENT}>
          <AdminContainer.APRegionalReport />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.NEW_REPORT}`,
      element: (
        <ProtectedRoute location={ROUTERS.NEW_REPORT}>
          <AdminContainer.NewReports />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.UPDATE_REGIONAL_REPORT}`,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_REGIONAL_REPORT}>
          <AdminContainer.UpdateReport />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.COUNTRY_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.COUNTRY_MANAGEMENT}>
          <AdminContainer.CountryManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.COUNTRY_SCORING_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.COUNTRY_SCORING_MANAGEMENT}>
          <AdminContainer.CountryScoringManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.UPDATE_COUNTRY}`,
      element: (
        <ProtectedRoute location={ROUTERS.UPDATE_COUNTRY}>
          <AdminContainer.UpdateCountry />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ADMIN_COUNTRY_SCORING_DETAIL}`,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_COUNTRY_SCORING_DETAIL}>
          <AdminContainer.AdminScoringDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.GALLERY_MANAGEMENT}`,
      element: (
        <ProtectedRoute location={ROUTERS.GALLERY_MANAGEMENT}>
          <AdminContainer.GalleryManagement />
        </ProtectedRoute>
      ),
    },
  ],
};

export default AdminRouters;
