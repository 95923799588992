import React, { useEffect, useMemo } from 'react';
import { Grid, Container } from '@mui/material';

import { AppBarComponent, Footer, Carousel } from '../LayoutPart';
import Utils from '@utils';
import { CookieConsentDialog } from '@/Components/LayoutPart';

// import Utils from '@utils';

interface ISectionProps {
  content: JSX.Element;
  showCarousel?: boolean;
}

// Declare utils
// const { checkAppMode } = Utils;

const UserLayout: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { content, showCarousel } = props;

  useEffect(() => {
    const token = Utils.getSavedToken();
    if (!token) {
      Utils.clearAllSavedData();
    }
  }, []);

  // Renders.
  const _renderCarousel = useMemo(() => {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item sx={{ width: 0.9, maxWidth: 1495 }}>
          <Carousel />
        </Grid>
      </Grid>
    );
  }, []);

  return (
    <Grid container direction="column" flexWrap="nowrap" sx={{ height: 1 }}>
      <AppBarComponent />
      <Grid
        sx={{ width: 1 }}
        item
        px={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        py={{ xs: 1, sm: 2, md: 2, lg: 2 }}
        mt={2}
        // minHeight={'60vh'}
      >
        <Container maxWidth="xl">{content}</Container>
      </Grid>
      {showCarousel && _renderCarousel}
      <Grid item mt="auto">
        <Footer />
      </Grid>
      <CookieConsentDialog />
    </Grid>
  );
};

export default UserLayout;
