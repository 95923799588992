import CommonColor from '@/Themes/CommonColor';
import { SxProps, Theme } from '@mui/system';

export const titleItemStyles: SxProps<Theme> = {
  height: 30,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  mt: 1,
};

export const cartStyles = (data: any): SxProps<Theme> => {
  const length = data.length;
  return {
    marginX: 0.6,
    minHeight: 210,
    maxWidth: length < 3 ? 300 : 'unset',
    display: 'flex',
    flexDirection: 'column',
    // cursor: 'pointer',
    ':hover': {
      boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
      transform: 'translate(0px, -3px)',
    },
  };
};

export const categoryStyles: SxProps<Theme> = {
  fontSize: '14px',
  color: CommonColor.orangeRoughy,
  textTransform: 'uppercase',
  fontWeight: 600,
  maxWidth: { xs: 140, md: 180 },
  display: 'inline-block',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden !important',
  mt: 1,
};
