import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IDialogMessages {
  messages?: any[];
  isOpen?: boolean;
  onCancel?(): void;
  onContinue(e: any, confirm?: boolean): void;
}

const DialogMessages: React.FC<IDialogMessages> = (props: IDialogMessages) => {
  const { isOpen = false, messages, onCancel, onContinue } = props;
  const [totalMsg, setTotalMsg] = useState<number>(0);

  useEffect(() => {
    if (!_.isEmpty(messages)) setTotalMsg(_.size(messages));
  }, [messages]);

  const _renderMessages = () => {
    if (_.isEmpty(messages)) return null;
    return _.map(
      messages && (totalMsg > 5 ? _.slice(messages, 0, 5) : messages),
      (message, index) => (
        <DialogContentText key={index}>{message}</DialogContentText>
      )
    );
  };

  const _renderMoreMessages = () =>
    totalMsg > 5 && (
      <DialogContentText>
        and {totalMsg - 5} fields have not been filled in completely.
      </DialogContentText>
    );

  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <DialogContentText>
          {
            <WarningAmberIcon
              fontSize="large"
              sx={{
                color: 'orange',
              }}
            />
          }
        </DialogContentText>
        You are providing not enough information
      </DialogTitle>
      <DialogContent>
        {_renderMessages()}
        {_renderMoreMessages()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="fuzzyWuzzyBrown" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="oceanGreen"
          onClick={(e) => onContinue(e, true)}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMessages;
