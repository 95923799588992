import { create, ApisauceInstance } from 'apisauce';
import _ from 'lodash';
import Utils from '@utils';
import { API, ROUTERS } from '@constants';

export type API_METHOD =
  | 'GET'
  | 'PUT'
  | 'POST'
  | 'DEL'
  | 'FORM_DATA'
  | 'PUT_FORM_DATA';
export type ERROR_TYPE = 'ERROR' | 'WANRING' | 'SERVER_ERROR';
const AUTHORISED_ERROR = [401];
const INTERAL_SERVER_ERROR = [500, 501];
const BAD_REQUEST_ERROR = [400, 422];
const WRONG_URL_ERROR = [404];
const EXCLUDE_API = [
  API.AUTH.LOGIN,
  API.AUTH.FORGOT_PASSWORD,
  API.AUTH.RESET_PASSWORD,
  API.AUTH.VERIFY_ACCOUNT,
  API.AUTH.REFRESH_TOKEN,
  API.METHODOLOGY.ROOT,
  API.BACKGROUND.ROOT,
  API.GUIDELINE.ROOT,
  API.TOOL_KIT.ROOT,
  API.POST.ROOT,
  API.FOOTER.ROOT,
  API.COUNTRY.ROOT,
  API.SOCIAL_LINKS.ROOT,
  API.REGIONAL_REPORT.GET_FOR_VISITOR,
  API.COUNTRY_REPORT.GET_FOR_VISITOR,
  API.COMMENT.ROOT,
  API.COMPARE_EXPLORE.YEARS,
  API.COMPARE_EXPLORE.COUNTRIES,
];

const getAPIConfig = (url: string) => {
  const token = Utils.getSavedToken();
  const lang = Utils.getSaveLocale();

  const BASE_URL = import.meta.env.VITE_BE_URL;
  const api = create({
    baseURL: `${BASE_URL}api/`,
    headers: {
      Accept: 'application/json',
    },
  });
  api.setHeader('Authorization', `Bearer ${token}`);
  if (lang) api.setHeader('lang', lang);
  if (!_.includes(EXCLUDE_API, url) && !_.includes(url, API.POST.GET_BY_ID)) {
    const validateToken = Utils.checkTokenLifeTime(token);
    if (!validateToken) Utils.replace(ROUTERS.AUTH);
  }
  return api;
};

// Handle error response
const handleErrorResponse = (
  type: ERROR_TYPE,
  params: { message: string; duration: number }
) => {
  const { message, duration } = params;
  const response = {
    type,
    message,
    duration,
  };
  return response;
};

// Handle response
const handleResponse = (res: any, resolve: any, reject: any) => {
  const message = _.get(res, 'data.message');
  const duration = _.get(res, 'duration') || 0;
  const status = _.get(res, 'status');
  const problem = _.get(res, 'problem');
  if (_.includes(AUTHORISED_ERROR, status))
    return reject(handleErrorResponse('WANRING', { message, duration }));
  if (_.includes(INTERAL_SERVER_ERROR, status))
    return reject(handleErrorResponse('ERROR', { message, duration }));
  if (_.includes(BAD_REQUEST_ERROR, status))
    return reject(
      handleErrorResponse('WANRING', {
        message: `Bad request: ${message}`,
        duration,
      })
    );
  if (_.includes(WRONG_URL_ERROR, status))
    return reject(
      handleErrorResponse('WANRING', { message: `URL not found`, duration })
    );
  if (problem)
    return reject(handleErrorResponse('SERVER_ERROR', { message, duration }));
  return resolve(res);
};

const post = async (api: ApisauceInstance, url: string, data?: any) => {
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const postFormData = async (api: ApisauceInstance, url: string, data?: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const res = await api.post(url, data, { headers });
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const putFormData = async (api: ApisauceInstance, url: string, data?: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const res = await api.put(url, data, { headers });
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const get = async (api: ApisauceInstance, url: string, data?: any) => {
  const res = await api.get(url, data);
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const put = async (api: ApisauceInstance, url: string, data?: any) => {
  const res = await api.put(url, data);
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const del = async (api: ApisauceInstance, url: string, data?: any) => {
  const res = api.delete(url, data);
  return new Promise((resolve, reject) => {
    return handleResponse(res, resolve, reject);
  });
};

const sendRequest = async (url: string, method: API_METHOD, params?: any) => {
  const api = getAPIConfig(url);
  if (!api) return;
  if (method === 'POST') return post(api, url, params);
  if (method === 'GET') return get(api, url, params);
  if (method === 'PUT') return put(api, url, params);
  if (method === 'FORM_DATA') return postFormData(api, url, params);
  if (method === 'DEL') return del(api, url, params);
  if (method === 'PUT_FORM_DATA') return putFormData(api, url, params);
};

export default sendRequest;
