import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, CircularProgress, Typography } from '@mui/material';
import DefaultLayout from '@/Components/DefaultLayout';

import { AuthActions } from '@actions';
import { RootState, useTypedDispatch } from '@/store';
import Utils from '@/Utils';
import { ROUTERS } from '@/Constants';

const { verifyAccount } = AuthActions;

const VerifyAccount: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const requestHasError = useSelector((state: RootState) =>
    _.get(state.AUTH, 'requestHasError')
  );
  const [message, setMessage] = useState<string>(
    ' Your account is verifying, please wait....'
  );
  useEffect(() => {
    const email = searchParams.get('e') || '';
    const code = searchParams.get('c') || '';
    if (email && code) {
      const payload = { email, code };
      dispatch(verifyAccount(payload));
    } else {
      setMessage('Email and Code cannot be empty !');
    }
  }, []);

  useEffect(() => {
    if (requestHasError) {
      setMessage('Invalid code, system will redirect after 5 seconds');
      Utils.sleep(5000)
        .then(() => Utils.replace(ROUTERS.AUTH))
        .catch(() => Utils.replace(ROUTERS.AUTH));
    }
  }, [requestHasError]);

  const renderMain = () => (
    <Grid container justifyContent="center" sx={{ my: 12 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress sx={{ my: 4 }} size="60px" />
        <Typography variant="h6" textAlign="center">
          {message}
        </Typography>
      </Box>
    </Grid>
  );
  return <DefaultLayout content={renderMain()} />;
};

export default VerifyAccount;
