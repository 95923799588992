import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { INewsList } from '@/Interfaces/News.interface';

// SINGLE ACTIONS
const setGetNewsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_NEWS_GET_LOADING,
    payload,
  };
};

const setFetchNewsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_NEWS_FETCH_LOADING,
    payload,
  };
};

const resetNewsDetail = () => {
  return {
    type: ACTION_TYPES.RESET_USER_NEWS_DETAIL,
  };
};

const resetNewsByCountry = () => {
  return {
    type: ACTION_TYPES.RESET_USER_NEWS_BY_COUNTRY,
  };
};

const setNewsPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_USER_NEWS_PAGINATION,
    payload,
  };
};

const setNewsMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_USER_NEWS_META,
    payload,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_LIST_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_LIST_FAILURE,
  };
};

const fetchNews = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchNewsLoading(true));
    await API.fetchPosts(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setNewsMeta(meta));
          dispatch(setNewsPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

// ASYNC ACTIONS
const fetchByCountrySuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_BY_COUNTRY_SUCCESS,
    payload,
  };
};

const fetchByCountryFail = () => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_BY_COUNTRY_FAILURE,
  };
};

const fetchNewsByCountry = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchNewsLoading(true));
    await API.fetchPosts(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchByCountryFail());
        else {
          const items = _.get(result, 'items');
          await dispatch(fetchByCountrySuccess(items));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchByCountryFail());
      });
  };
};

const getByIdSuccess = (payload: INewsList) => {
  return {
    type: ACTION_TYPES.GET_NEWS_DETAIL_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_NEWS_DETAIL_FAILURE,
  };
};

const getNewsById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetNewsLoading(true));
    await API.getPostById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

export default {
  fetchNews,
  getNewsById,
  fetchNewsByCountry,
  resetNewsDetail,
  resetNewsByCountry,
};
