import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  IUpdateScore,
  ICommentScore,
} from '@interfaces/CountryScoring.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetReviewerScoringLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_SCORING_GET_LOADING,
    payload,
  };
};

const setFetchReviewerScoringLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_SCORING_FETCH_LOADING,
    payload,
  };
};

const setReviewerScoringActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_SCORING_ACTION_LOADING,
    payload,
  };
};

const setReviewerScoringPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_SCORING_PAGINATION,
    payload,
  };
};

const setReviewerScoringMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_SCORING_META,
    payload,
  };
};

const resetReviewerScoringReducer = () => {
  return {
    type: ACTION_TYPES.RESET_REVIEWER_SCORING_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_REVIEWER_SCORING_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_REVIEWER_SCORING_FAILURE,
  };
};

const fetchReviewerScoring = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchReviewerScoringLoading(true));
    await API.fetchCountryScoring(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setReviewerScoringMeta(meta));
          dispatch(setReviewerScoringPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateScore) => {
  return {
    type: ACTION_TYPES.GET_REVIEWER_SCORING_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_REVIEWER_SCORING_BY_ID_FAILURE,
  };
};

const getReviewerScoringById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetReviewerScoringLoading(true));
    await API.getCountryScoringById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const updateSuccess = (payload: IUpdateScore) => {
  return {
    type: ACTION_TYPES.UPDATE_REVIEWER_SCORING_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_REVIEWER_SCORING_FAILURE,
  };
};

const updateReviewerScoring = (payload: ICommentScore, id: string) => {
  return async (dispatch: any) => {
    dispatch(setReviewerScoringActionLoading(true));
    await API.reviewCountryScoring(payload, id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const fetchAllSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_REVIEWER_SCORING_SUCCESS,
    payload,
  };
};

const fetchAllFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_REVIEWER_SCORING_FAILURE,
  };
};

const fetchAllReviewerScoring = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchReviewerScoringLoading(true));
    await API.fetchCountryScoring(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchAllSuccess(items));
          dispatch(setReviewerScoringMeta(meta));
          dispatch(setReviewerScoringPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllFail());
      });
  };
};

const editSuccess = () => {
  return {
    type: ACTION_TYPES.EDITTING_REVIEWER_SCORING_SUCCESS,
  };
};

const editFail = () => {
  return {
    type: ACTION_TYPES.EDITTING_REVIEWER_SCORING_FAILURE,
  };
};

const sendScoringBackToUser = (payload: ICommentScore, id: string) => {
  return async (dispatch: any) => {
    dispatch(setReviewerScoringActionLoading(true));
    await API.editCountryScoring(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(editFail());
        else {
          dispatch(editSuccess());
          Utils.redirect(ROUTERS.REVIEW_SCORING);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(editFail());
      });
  };
};

const sendSuccess = () => {
  return {
    type: ACTION_TYPES.SEND_APPROVE_REVIEWER_SCORING_SUCCESS,
  };
};

const sendFail = () => {
  return {
    type: ACTION_TYPES.SEND_APPROVE_REVIEWER_SCORING_FAILURE,
  };
};

const sendScoringToAdmin = (payload: ICommentScore, id: string) => {
  return async (dispatch: any) => {
    dispatch(setReviewerScoringActionLoading(true));
    await API.reviewCountryScoring(payload, id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(sendFail());
        else {
          const innerResponse: any = await API.sendCountryScoringToAdmin(id);
          const resolved: any = await Utils.resolveResponse(innerResponse);
          if (!resolved) await dispatch(sendFail());
          else {
            dispatch(sendSuccess());
            Utils.redirect(ROUTERS.REVIEW_SCORING);
          }
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(sendFail());
      });
  };
};

export default {
  fetchReviewerScoring,
  getReviewerScoringById,
  resetReviewerScoringReducer,
  updateReviewerScoring,
  fetchAllReviewerScoring,
  sendScoringBackToUser,
  sendScoringToAdmin,
};
