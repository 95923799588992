import { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { IComment, IFecthPostIdComments } from '@/Interfaces/Comment.interface';
import { CommonColors } from '@/Themes';
import _ from 'lodash';
import Utils from '@/Utils';
import { CommentActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '@/Constants';
import { Dialogs } from '@/Components/LayoutPart';
import { NoDataWereFound } from '@/Components/Common';

const { deactiveComment, fetchComments } = CommentActions;

interface IPropsComments {
  open: boolean;
  onClose(value?: string): void;
  locationState: string;
}

const DialogsCommment: React.FC<IPropsComments> = ({
  open,
  onClose,
  locationState,
}: IPropsComments) => {
  const dispatch = useTypedDispatch();
  const [confirmDelete, setConfirmDelete] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const pagination: IFecthPostIdComments = useSelector((state: RootState) =>
    _.get(state.COMMENT, 'pagination')
  );
  const comments: any = useSelector((state: RootState) =>
    _.get(state.COMMENT, 'comments')
  );

  useEffect(() => {
    if (locationState) {
      dispatch(fetchComments({ ...pagination, postId: locationState }));
    }
  }, [locationState]);

  const onCancel = (type: 'delete') => {
    if (type === 'delete')
      setConfirmDelete({ ...confirmDelete, isOpen: false });
  };

  const handleDelete = (state: { id?: string; status: string }) => {
    const { id } = state;
    if (id) dispatch(deactiveComment(id, locationState));
    setConfirmDelete({ ...confirmDelete, isOpen: false });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 850,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  sx={{
                    color: CommonColors.mainColor,
                    mb: 1,
                  }}
                  variant="h5"
                >
                  Comments
                </Typography>
              </Grid>
              <Grid item>
                <CloseIcon
                  sx={{
                    cursor: 'pointer',
                    border: 1,
                    borderRadius: '50%',
                    '&:hover': {
                      backgroundColor: CommonColors.fuzzyWuzzyBrown,
                      color: CommonColors.white,
                    },
                  }}
                  onClick={() => onClose()}
                />
              </Grid>
            </Grid>
            <Divider sx={{ background: CommonColors.mainColor }} />
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent sx={{ pt: 0 }}>
        {comments?.length ? (
          <List>
            {_.map(comments, (newComment: IComment, index: number) => {
              return (
                <Box key={index}>
                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={8}>
                            <ListItemText
                              sx={{
                                fontSize: '0.9rem',
                                color: CommonColors.blue,
                              }}
                            >
                              {newComment?.email} ({newComment?.name})
                            </ListItemText>
                          </Grid>
                          <Grid item xs={4} textAlign="end">
                            <ListItemText
                              sx={{
                                fontSize: '0.9rem',
                                color: CommonColors.silverChalice,
                              }}
                            >
                              {newComment?.createdAt &&
                                Utils.getFormatDate(newComment?.createdAt)}
                            </ListItemText>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} mx={1.5}>
                        <Grid container>
                          <Grid item xs={11}>
                            <ListItemText>{newComment?.comment}</ListItemText>
                          </Grid>
                          {Utils.getUserRole() === 'admin' && (
                            <Grid
                              item
                              xs={1}
                              textAlign="end"
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setConfirmDelete({
                                  isOpen: true,
                                  message: `This will permanently terminated this comment.`,
                                  state: {
                                    id: newComment?.id,
                                    status: '',
                                  },
                                });
                              }}
                            >
                              <DeleteOutlineIcon color="error" />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>
              );
            })}
          </List>
        ) : (
          <NoDataWereFound />
        )}
      </DialogContent>
      <Dialogs.Confirm
        title="Are you sure you want to terminated this comment?"
        confirm={confirmDelete}
        onCancel={() => onCancel('delete')}
        callback={() => handleDelete(confirmDelete.state)}
      />
    </Dialog>
  );
};

export default DialogsCommment;
