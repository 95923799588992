import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

import { PaginationActions } from '@/Components/Common';

import { UserScoringActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ENUMS, ROUTERS } from '@constants';

import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import Utils from '@/Utils';

const { MY_SCORING_USER_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchAllUserScoring } = UserScoringActions;

const MyScoringUser: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const scoringList: any =
    useSelector((state: RootState) =>
      _.get(state.USER_SCORING, 'scoringList')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'isFetchLoading')
  );
  const colSpan = MY_SCORING_USER_TABLE_HEADER.length + 2;
  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(fetchAllUserScoring({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchAllUserScoring({ ...pagination, limit: perPage }));
  };

  useEffect(() => {
    dispatch(fetchAllUserScoring(pagination));
  }, []);

  const generateStatusLabel = (status: string) => {
    switch (status) {
      case ENUMS.STATUS.TERMINATED:
        return ENUMS.STATUS.DELETED;
      case ENUMS.STATUS.REVIEW:
        return ENUMS.STATUS.REVIEWING;
      case ENUMS.STATUS.APPROVAL:
        return ENUMS.STATUS.APPROVED;
      default:
        return status;
    }
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        <TableCell width={450} sx={{ fontWeight: 700 }}>
          Title
        </TableCell>
        {_.map(MY_SCORING_USER_TABLE_HEADER, (item) => (
          <TableCell
            key={`head-${item.value}`}
            width={170}
            sx={{ fontWeight: 700 }}
          >
            {item.label}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: 700, minWidth: 135 }}>
          Status
        </TableCell>
        <TableCell align="center" width={75} sx={{ fontWeight: 700 }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [MY_SCORING_USER_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(scoringList))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no scoring to display
          </TableCell>
        </TableRow>
      );
    return _.map(scoringList, (item, index) => {
      return (
        <TableRow hover key={index}>
          <TableCell>{item?.title}</TableCell>
          {_.map(MY_SCORING_USER_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`}>
              {_.get(item, head.value)}
            </TableCell>
          ))}
          <TableCell key={`status.${index}`} align="center">
            <Typography
              component="span"
              sx={{
                fontSize: 14,
                textTransform: 'uppercase',
                pl: 0.4,
                color:
                  _.get(TableStatusColor, _.get(item, 'status')) ||
                  CommonColors.black,
              }}
            >
              {_.startCase(generateStatusLabel(_.get(item, 'status')))}
            </Typography>
          </TableCell>
          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View detail">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() =>
                  Utils.redirect(ROUTERS.UPDATE_SCORING, {
                    id: _.get(item, 'id'),
                  })
                }
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{ ...tableContainerStyles, width: { xs: '60vh', md: 1 } }}
      >
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan + 1} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default MyScoringUser;
