/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TextEditor, UploadImage } from '@/Components/Common';

import { BreadCrumb, Dialogs } from '@/Components/LayoutPart';
import DefaultLayout from '@/Components/DefaultLayout';

import { FooterActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';
import { DEFAULT_CONFIRM } from '@constants';

import { IFooterStructure } from '@/Interfaces/Footer.inteface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const { getFooter, createFooter, resetFooterReducer } = FooterActions;

const DEFAULT_DETAILS = {
  rulesOwnership: {
    title: 'RULES AND OWNERSHIP',
    content: '',
  },
  about: { title: 'What Is the Monitoring Matrix?', content: '' },
  contact: { title: 'Contact Information', content: '' },
  extraData: '',
};

type FILE_KEY_TYPES = 'logoRuleOwnership' | 'logoContact' | 'logoAbout';

const FooterManagement: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.FOOTER, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.FOOTER, 'isActionLoading')
  );
  const footerContent: any = useSelector((state: RootState) =>
    _.get(state.FOOTER, 'footerContent')
  );

  const [details, setDetails] = useState<IFooterStructure>(DEFAULT_DETAILS);
  const [logoRuleOwnershipState, setLogoRuleOwnership] = useState<File[]>([]);
  const [logoAboutState, setAbout] = useState<File[]>([]);
  const [logoContactState, setLogoContact] = useState<File[]>([]);
  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [renderKey, setRenderkey] = useState<number>();

  useEffect(() => {
    dispatch(getFooter());
    return () => {
      setDetails(DEFAULT_DETAILS);
      dispatch(resetFooterReducer());
    };
  }, []);

  useEffect(() => {
    generateDetails();
  }, [footerContent]);

  const generateDetails = async () => {
    if (!_.isEmpty(footerContent)) {
      const {
        rulesOwnership,
        about,
        contact,
        logoRuleOwnership,
        logoAbout,
        logoContact,
      } = footerContent;
      const resolveDetails = {
        rulesOwnership: JSON.parse(rulesOwnership),
        about: JSON.parse(about),
        contact: JSON.parse(contact),
      };
      if (logoRuleOwnership) {
        const isInternal = logoRuleOwnership?.storageService === 'Internal';
        const logoRuleOwnershipSrc = isInternal
          ? await Utils.getFileFromURL(
              logoRuleOwnership.path,
              logoRuleOwnership.nameOriginal
            )
          : await Utils.getAWSFileAsBlob(
              logoRuleOwnership.path,
              logoRuleOwnership.nameOriginal
            );
        if (logoRuleOwnershipSrc)
          setLogoRuleOwnership([logoRuleOwnershipSrc as File]);
      }
      if (logoAbout) {
        const isInternal = logoAbout?.storageService === 'Internal';
        const logoAboutSrc = isInternal
          ? await Utils.getFileFromURL(logoAbout.path, logoAbout.nameOriginal)
          : await Utils.getAWSFileAsBlob(
              logoAbout.path,
              logoAbout.nameOriginal
            );
        if (logoAboutSrc) setAbout([logoAboutSrc as File]);
      }
      if (logoContact) {
        const isInternal = logoContact?.storageService === 'Internal';
        const logoContactSrc = isInternal
          ? await Utils.getFileFromURL(
              logoContact.path,
              logoContact.nameOriginal
            )
          : await Utils.getAWSFileAsBlob(
              logoContact.path,
              logoContact.nameOriginal
            );
        if (logoContactSrc) setLogoContact([logoContactSrc as File]);
      }
      setDetails(resolveDetails);
      setRenderkey(Math.random());
    }
  };

  const onCancel = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const handleFilesChange = (key: FILE_KEY_TYPES, items: any[]) => {
    if (key === 'logoRuleOwnership') setLogoRuleOwnership(items);
    if (key === 'logoContact') setLogoContact(items);
    if (key === 'logoAbout') setAbout(items);
  };

  const handleSubmit = async () => {
    if (!_.isEmpty(details)) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(details)) {
        if (_.trim(value as string))
          formData.append(key, _.trim(JSON.stringify(value)));
      }
      if (!_.isEmpty(logoRuleOwnershipState))
        formData.append('logoRuleOwnership', logoRuleOwnershipState[0]);
      else formData.append('logoRuleOwnershipRemove', JSON.stringify(true));
      if (!_.isEmpty(logoContactState))
        formData.append('logoContact', logoContactState[0]);
      else formData.append('logoContactRemove', JSON.stringify(true));
      if (!_.isEmpty(logoAboutState))
        formData.append('logoAbout', logoAboutState[0]);
      else formData.append('logoAboutRemove', JSON.stringify(true));

      setConfirmDialog({ ...confirmDialog, isOpen: false });
      dispatch(createFooter(formData as any));
    }
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadCrumb title="Footer" />
        </Grid>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                ':hover': {
                  backgroundColor: '#efefef',
                },
              }}
            >
              <Typography variant="h6">Home Section</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    value={details.about?.title}
                    fullWidth
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        about: {
                          ...details.about,
                          title: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item lg={12} md={12}>
                  <TextEditor
                    height={400}
                    value={details.about.content}
                    key={`whatis${renderKey}`}
                    onChange={(newContent: string) => {
                      if (newContent)
                        setDetails({
                          ...details,
                          about: {
                            ...details.about,
                            content: newContent,
                          },
                        });
                    }}
                    disabled={isGetLoading || isActionLoading}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                ':hover': {
                  backgroundColor: '#efefef',
                },
              }}
            >
              <Typography variant="h6">Left Footer Section</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    value={details.contact?.title}
                    fullWidth
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        contact: {
                          ...details.contact,
                          title: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item lg={8} md={12}>
                  <TextEditor
                    height={400}
                    value={details.contact.content}
                    key={`about${renderKey}`}
                    onChange={(newContent: string) => {
                      if (newContent)
                        setDetails({
                          ...details,
                          contact: {
                            ...details.contact,
                            content: newContent,
                          },
                        });
                    }}
                    disabled={isGetLoading || isActionLoading}
                  />
                </Grid>
                <Grid item lg={4} md={12}>
                  <UploadImage
                    label="Logo"
                    files={logoContactState}
                    onFileChange={(items: File[]) =>
                      handleFilesChange('logoContact', items)
                    }
                    disabled={isGetLoading || isActionLoading}
                    type="LOGO"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                ':hover': {
                  backgroundColor: '#efefef',
                },
              }}
            >
              <Typography variant="h6">Right Footer Section</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    value={details.rulesOwnership?.title}
                    fullWidth
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        rulesOwnership: {
                          ...details.rulesOwnership,
                          title: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={8} lg={8} md={12}>
                  <TextEditor
                    height={400}
                    value={details.rulesOwnership.content}
                    key={`rules${renderKey}`}
                    onChange={(newContent: string) => {
                      if (newContent)
                        setDetails({
                          ...details,
                          rulesOwnership: {
                            ...details.rulesOwnership,
                            content: newContent,
                          },
                        });
                    }}
                    disabled={isGetLoading || isActionLoading}
                  />
                </Grid>
                <Grid item xs={4} lg={4} md={12}>
                  <UploadImage
                    label="First Logo"
                    files={logoRuleOwnershipState}
                    onFileChange={(items: File[]) =>
                      handleFilesChange('logoRuleOwnership', items)
                    }
                    disabled={isGetLoading || isActionLoading}
                    type="LOGO"
                  />
                  <UploadImage
                    label="Second Logo"
                    files={logoAboutState}
                    onFileChange={(items: File[]) =>
                      handleFilesChange('logoAbout', items)
                    }
                    disabled={isGetLoading || isActionLoading}
                    type="LOGO"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}
          >
            <LoadingButton
              variant="contained"
              onClick={() =>
                setConfirmDialog({
                  ...confirmDialog,
                  isOpen: true,
                  message: `Are you sure you want to change the content?`,
                })
              }
              color="oceanGreen"
              loading={isGetLoading || isActionLoading}
            >
              Save
            </LoadingButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Dialogs.Confirm
            confirm={confirmDialog}
            onCancel={() => onCancel()}
            callback={() => handleSubmit()}
          />
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} portalFor="ADMIN" />;
};

export default FooterManagement;
