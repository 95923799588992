import * as AppServiceUtils from './AppServices.utils';
import * as ResolveResponse from './ResolveResponse.utils';
import * as CookieUtils from './Cookie.utils';

import * as WidgetUtils from './Widget.utils';
import * as ChartUtils from './Chart.utils';

export default {
  ...AppServiceUtils,
  ...ResolveResponse,
  ...CookieUtils,
  ...WidgetUtils,
  ...ChartUtils,
};
