import React from 'react';
import { Grid } from '@mui/material';

import { SideBarComponent } from '../LayoutPart';

// import Utils from '@utils';

interface ISectionProps {
  content: JSX.Element;
}

// Declare utils
// const { checkAppMode } = Utils;
const ReviewerLayout: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { content } = props;

  return (
    <Grid container width={1} height="100%" flexWrap="nowrap">
      <SideBarComponent useFor="REVIEWER" />
      <Grid item p={4} xs={12}>
        {content}
      </Grid>
    </Grid>
  );
};

export default ReviewerLayout;
