import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  Grid,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Pagination,
  Stack,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import {
  NoDataWereFound,
  LazyLoad,
  SkeletonLoading,
} from '@/Components/Common';
import { Popup } from '@/Components/LayoutPart';
import { useTypedDispatch, RootState } from '@/store';
import { GalleryActions } from '@actions';
import { ENUMS } from '@/Constants';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';

import {
  IFileDetailsStructure,
  IGalleryStructure,
} from '@/Interfaces/File.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';

interface ISectionProps {
  onOpen: { status: boolean; file?: IFileDetailsStructure | null };
  onClose(): void;
  callback?(
    file?: IFileDetailsStructure,
    size?: {
      width: string | number;
      height: string | number;
    }
  ): void;
}
const { fetchGalleries } = GalleryActions;
const DEFAULT_GALLERY_PAGINATION = {
  page: 1,
  limit: 12,
  keyword: '',
  status: ENUMS.STATUS.ACTIVE,
};

const Media: React.FC<ISectionProps> = ({ onOpen, onClose, callback }) => {
  const dispatch = useTypedDispatch();
  const selectedFileRef = useRef<any>(null);

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'isFetchLoading')
  );
  const galleries: IGalleryStructure[] = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'galleries')
  );
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.GALLERY, 'meta')
  );

  const [selectedFile, setSelectedFile] =
    useState<IFileDetailsStructure | null>(null);
  const [isOpenResize, setIsOpenResize] = useState<boolean>(false);
  const [isOpenUpload, setIsOpenUpload] = useState<boolean>(false);
  const [size, setSize] = useState<{
    width: string | number;
    height: string | number;
  }>({
    //ratio 16/9
    width: 168,
    height: 105,
  });

  const [filter, setFilter] = useState<IPaginationFilter>(
    DEFAULT_GALLERY_PAGINATION
  );

  useEffect(() => {
    const roleName = Utils.getUserRole();
    if (
      (roleName === ENUMS.ROLES.ADMIN && _.isEmpty(galleries)) ||
      (roleName === ENUMS.ROLES.USER && _.isEmpty(galleries))
    )
      dispatch(fetchGalleries(DEFAULT_GALLERY_PAGINATION));
  }, []);

  useEffect(() => {
    if (!onOpen.status) setSelectedFile(null);
  }, [onOpen.status]);

  const handleOpenResize = () => setIsOpenResize(true);

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(fetchGalleries({ ...filter, page: value, limit: 12 }));
  };

  const _renderVituralImage = useCallback(
    () =>
      selectedFile && (
        <Box
          id="virtual-selected-image"
          ref={selectedFileRef}
          onLoad={() =>
            selectedFileRef.current &&
            setSize({
              width: selectedFileRef.current.naturalWidth,
              height: selectedFileRef.current.naturalHeight,
            })
          }
          component="img"
          src={selectedFile.path}
          alt={selectedFile?.nameOriginal}
          sx={{ width: 0, height: 0 }}
        />
      ),
    [selectedFile]
  );

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <TextField
            label="Search by name"
            placeholder="Search by name"
            value={filter.keyword}
            onChange={(e) => setFilter({ ...filter, keyword: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                dispatch(
                  fetchGalleries({
                    ...filter,
                    status: ENUMS.STATUS.ACTIVE,
                    limit: 12,
                  })
                );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    disabled={!filter.keyword}
                    onClick={() =>
                      dispatch(
                        fetchGalleries({
                          ...filter,
                          status: ENUMS.STATUS.ACTIVE,
                          limit: 12,
                        })
                      )
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: '400px' }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedFile(null);
              setFilter(DEFAULT_GALLERY_PAGINATION);
              dispatch(fetchGalleries(DEFAULT_GALLERY_PAGINATION));
            }}
          >
            Clear Filters
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            startIcon={<FileUploadIcon />}
            variant="contained"
            size="large"
            onClick={() => setIsOpenUpload(true)}
          >
            Upload Image
          </Button>
          <Button
            variant="contained"
            size="large"
            color="burntSienna"
            onClick={() => handleOpenResize()}
            disabled={!selectedFile}
          >
            Choose
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderGallery = () => {
    if (_.isEmpty(galleries)) return <NoDataWereFound />;
    return (
      <ImageList
        sx={{ width: '100%', height: '100%', p: 2 }}
        cols={4}
        gap={20}
        variant="standard"
      >
        {_.map(galleries, (item: IGalleryStructure) => (
          <ImageListItem key={item.id}>
            <LazyLoad
              src={`${item.file.path}`}
              alt={item.file.nameConvert}
              style={{ width: '100%', height: '150px' }}
            />
            <ImageListItemBar
              sx={{ transition: 'background 1s' }}
              title={item.file.nameConvert}
              actionIcon={
                <Tooltip title="Select this image">
                  <Checkbox
                    checked={selectedFile?.path === item.file.path}
                    onClick={() =>
                      setSelectedFile(
                        selectedFile?.path === item.file.path ? null : item.file
                      )
                    }
                  />
                </Tooltip>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Dialog
          fullScreen
          open={onOpen.status}
          onClose={onClose}
          sx={{ zIndex: 10000 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
              >
                <Typography variant="h4" sx={{ color: CommonColors.mainColor }}>
                  Please choose an image
                </Typography>
                <Button
                  color="fuzzyWuzzyBrown"
                  variant="contained"
                  onClick={onClose}
                >
                  Close
                </Button>
              </Box>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {_renderTopSection()}
            </Grid>
            <Grid item xs={12}>
              {!isFetchLoading ? (
                _renderGallery()
              ) : (
                <SkeletonLoading numberRender={5} />
              )}
            </Grid>
          </Grid>
          {!_.isEmpty(galleries) && (
            <Stack alignItems={'center'} py={2}>
              <Pagination
                count={meta?.totalPages || 1}
                page={meta?.currentPage || 1}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Stack>
          )}
        </Dialog>
      </Grid>

      <Dialog
        open={isOpenResize}
        onClose={() => setIsOpenResize(false)}
        sx={{ zIndex: 10001 }}
      >
        <DialogTitle textAlign={'center'} sx={{ fontWeight: 700 }}>
          Adjust image size
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Width"
                type="number"
                fullWidth
                variant="standard"
                value={size.width}
                onChange={(e) => setSize({ ...size, width: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Height"
                type="number"
                fullWidth
                variant="standard"
                value={size.height}
                onChange={(e) => setSize({ ...size, height: e.target.value })}
              />
            </Grid>
            {_renderVituralImage()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenResize(false)}
            color="fuzzyWuzzyBrown"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!_.isEmpty(size) && callback && selectedFile) {
                callback(selectedFile, size);
                setIsOpenResize(false);
                setFilter(DEFAULT_GALLERY_PAGINATION);
                dispatch(fetchGalleries(DEFAULT_GALLERY_PAGINATION));
              }
            }}
            color="oceanGreen"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Popup.UploadMedia
        type="EDITOR"
        sx={{ zIndex: 10001 }}
        open={isOpenUpload}
        onClose={() => setIsOpenUpload(!isOpenUpload)}
      />
    </Grid>
  );
};

export default Media;
