import NotificationAdd from '@mui/icons-material/NotificationsActive';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { IMenuSettings } from '@/Interfaces/Menu.interface';
import { ROUTERS } from '@constants';
import { CommonColors } from '@/Themes';

const USER_SETTINGS: IMenuSettings[] = [
  {
    label: 'profile',
    value: ROUTERS.PROFILE,
    icon: <AccountBoxIcon sx={{ mr: 2 }} color="info" />,
  },
  {
    label: 'Notifications',
    value: '',
    icon: <NotificationAdd sx={{ color: CommonColors.mainColor }} />,
  },
  {
    label: 'My Scoring',
    value: ROUTERS.MY_SCORING,
    icon: <AssessmentIcon sx={{ mr: 2 }} color="success" />,
  },
  {
    label: 'My Reports',
    value: ROUTERS.MY_REPORT,
    icon: <SummarizeIcon sx={{ mr: 2 }} color="secondary" />,
  },
  {
    label: 'logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon sx={{ mr: 2 }} color="error" />,
  },
];

const ADMIN_SETTINGS: IMenuSettings[] = [
  {
    label: 'Dashboard',
    value: ROUTERS.ADMIN_DASHBOARD,
    icon: <DashboardIcon sx={{ mr: 2 }} color="info" />,
  },
  {
    label: 'logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon sx={{ mr: 2 }} color="error" />,
  },
];

const REVIEWER_SETTINGS: IMenuSettings[] = [
  {
    label: 'Dashboard',
    value: ROUTERS.REVIEWER_DASHBOARD,
    icon: <DashboardIcon sx={{ mr: 2 }} color="info" />,
  },
  {
    label: 'logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon sx={{ mr: 2 }} color="error" />,
  },
];

export { USER_SETTINGS, ADMIN_SETTINGS, REVIEWER_SETTINGS };
