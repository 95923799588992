import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { CSSObject } from '@mui/material/styles';

import { mainStyles, AppBar } from './AppBar.styles';
import AppBarDesktop from './AppBarDesktop';
import AppBarMobile from './AppBarMobile';
import { UserNotifications } from '@/Components/LayoutPart';

import { useTypedDispatch, RootState } from '@/store';
import { NotificationActions } from '@/Actions';
import Utils from '@/Utils';

const { fetchNotifications } = NotificationActions;

interface SectionProps {
  attributes?: CSSObject;
}

const AppBarComponent: React.FC<SectionProps> = () => {
  const dispatch = useTypedDispatch();
  const token = Utils.getSavedToken();
  const notifications = useSelector((state: RootState) =>
    _.get(state.USER_NOTIFICATION, 'notifications')
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      const validateToken = Utils.checkTokenLifeTime(token);
      if (validateToken) dispatch(fetchNotifications());
    }
  }, [token]);

  return (
    <AppBar position="sticky" sx={mainStyles}>
      <AppBarDesktop
        onOpenNotifications={(open?: boolean) => {
          if (open) setIsOpen(open);
        }}
      />
      <AppBarMobile payload={notifications} />
      <UserNotifications
        onClose={() => setIsOpen(false)}
        open={isOpen}
        payload={notifications}
      />
    </AppBar>
  );
};

export default AppBarComponent;
