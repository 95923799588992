import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Button, Typography, Drawer } from '@mui/material';
import CommonColor from '@/Themes/CommonColor';
import Utils from '@/Utils';

const CookieConsentDialog: React.FC = () => {
  // Constructors
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const cookieConsent = Utils.getCookieConsent();
    if (_.isNull(cookieConsent)) {
      setIsOpen(true);
    }
  }, []);

  const handleClick = (value: boolean) => {
    Utils.saveCookieConsent(value);
    setIsOpen(false);
  };

  const _renderContent = () => {
    return (
      <Grid
        container
        alignItems="center"
        p={2}
        sx={{
          bgcolor: CommonColor.pickledBluewood,
          color: CommonColor.white,
        }}
      >
        <Grid item xs={12} md={8}>
          <Typography
            variant="subtitle1"
            textAlign={{
              xs: 'center',
              md: 'unset',
            }}
            sx={{ fontSize: '0.9em' }}
          >
            We use cookies on our website to give you the most relevant
            experience by remembering your preferences and repeat visits. By
            clicking &#34;Accept All&#34;, you consent to the use of ALL the
            cookies. However, you may visit &#34;Cookie Settings&#34; to provide
            a controlled consent.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid
            container
            justifyContent={{ xs: 'center', md: 'end' }}
            sx={{
              mt: {
                xs: 1,
                md: 0,
              },
            }}
            spacing={1}
          >
            <Grid item>
              <Button
                variant="contained"
                color="bismark"
                sx={{ fontSize: '0.8em', fontWeight: '600' }}
                onClick={() => handleClick(false)}
              >
                Decline
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ fontSize: '0.8em', fontWeight: '600' }}
                onClick={() => handleClick(true)}
              >
                Accept All
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {isOpen ? (
        <Drawer
          anchor={'bottom'}
          variant="permanent"
          open={false}
          onClose={() => {}}
        >
          {_renderContent()}
        </Drawer>
      ) : null}
    </>
  );
};

export default CookieConsentDialog;
