/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  TextFieldProps,
  Collapse,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers';
import Utils from '@/Utils';

import DefaultLayout from '@/Components/DefaultLayout';
import { Dropdown, UploadImage } from '@/Components/Common';
import { BreadCrumb, Dialogs } from '@/Components/LayoutPart';
import { useTypedDispatch, RootState } from '@/store';
import { IUpdateUser, IUserData } from '@interfaces/User.interface';
import { AuthActions } from '@actions';

const { getSelfProfile, updateSelfProfile } = AuthActions;

const DEFAULT_USERDATA: IUserData = {
  firstName: '',
  lastName: '',
};

const AdminAccountProfile: React.FC = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const selfProfile: IUpdateUser | null = useSelector(
    (state: RootState) => _.get(state.AUTH, 'selfProfile') || null
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );
  const [tabActive, setTabActive] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>(DEFAULT_USERDATA);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [country, setCountry] = useState<string>('');
  const [messages, setMessages] = useState<{
    date: string;
    firstName: string;
    lastName: string;
  }>({ date: '', firstName: '', lastName: '' });
  const [avatar, setAvatar] = useState<File[]>([]);

  useEffect(() => {
    dispatch(getSelfProfile());
    return () => {
      setMessages({ date: '', firstName: '', lastName: '' });
    };
  }, []);

  useEffect(() => {
    if (selfProfile) {
      setUserData({
        ..._.get(selfProfile, 'userData'),
        email: _.get(selfProfile, 'email'),
      });
      setCountry(_.get(selfProfile, 'country.name') || '');
      generateDetails();
    }
    setRenderKey(Math.random());
  }, [selfProfile]);

  const generateDetails = async () => {
    const isInternal =
      selfProfile?.userData?.avatar?.storageService === 'Internal';
    if (selfProfile?.userData?.avatar) {
      const avatarSrc = isInternal
        ? await Utils.getFileFromURL(
            _.get(selfProfile, 'userData.avatar.path') || '',
            _.get(selfProfile, 'userData.avatar.nameOriginal') || ''
          )
        : await Utils.getAWSFileAsBlob(
            _.get(selfProfile, 'userData.avatar.path') || '',
            _.get(selfProfile, 'userData.avatar.nameOriginal') || ''
          );
      if (avatarSrc) setAvatar([avatarSrc as File]);
    }
  };

  const handleOnChange = (key: string, value: string | number) => {
    const requiredKey = ['firstName', 'lastName'];
    if (
      _.includes(requiredKey, key) &&
      !Utils.isEmptyOrSpaces(_.toString(value))
    )
      setMessages({
        ...messages,
        [key]: '',
      });
    setUserData({ ...userData, [key]: value });
  };

  const handleChangeDate = (key: string, newValue: Dayjs | null) => {
    setUserData({
      ...userData,
      [key]: newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
    });
    setMessages({ ...messages, date: '' });
  };

  const handleFilesChange = (key: 'avatar', items: any[]) =>
    key === 'avatar' && setAvatar(items);

  const handleSubmit = () => {
    if (
      !_.isEmpty(userData?.firstName) &&
      !_.isEmpty(userData?.lastName) &&
      _.every(messages, (message) => _.isEmpty(message))
    ) {
      if (_.isNil(userData?.dob) || dayjs(userData.dob).isValid()) {
        const formData = new FormData();
        _.map(userData, (el: any, key) => {
          if (key !== 'id' && key !== 'avatar') {
            if (!_.isNull(el)) formData.append(`userData[${key}]`, el);
          }
        });
        if (!userData?.dob) formData.append(`userData[dob]`, '');
        if (!_.isEmpty(avatar)) formData.append('userData[avatar]', avatar[0]);
        else formData.append('avatarRemove', JSON.stringify(true));
        dispatch(updateSelfProfile(formData));
      } else {
        setMessages({
          ...messages,
          date: 'Dob invalid format',
        });
      }
    } else {
      setMessages({
        ...messages,
        firstName: _.isEmpty(userData?.firstName)
          ? 'First Name cannot be empty'
          : '',
        lastName: _.isEmpty(userData?.lastName)
          ? 'Last Name cannot be empty'
          : '',
      });
    }
  };

  // Renders
  const _renderMainForm = (
    children: JSX.Element,
    title: string,
    isHasExpand?: boolean
  ) => (
    <Grid item>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            {!isHasExpand && (
              <Grid item sx={{ py: 0.5, px: 1 }}>
                <Typography variant="h5">{title}</Typography>
              </Grid>
            )}
            {isHasExpand && (
              <ListItemButton
                onClick={() => setTabActive(!tabActive)}
                sx={{ py: 0.5, px: 1, borderRadius: 1 }}
              >
                <ListItemText
                  primary={<Typography variant="h5">{title}</Typography>}
                />
                {tabActive ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderUserAccount = () => {
    return (
      <Grid container spacing={2}>
        <Grid sx={{ cursor: 'not-allowed' }} item xs={12} mt={1} md={4}>
          <TextField
            fullWidth
            required
            defaultValue={userData?.email || ''}
            key={`email${renderKey}`}
            label="Email"
            disabled={true}
            onChange={() => {}}
          />
        </Grid>
      </Grid>
    );
  };

  const _renderUserInformation = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <UploadImage
                label=""
                onFileChange={(file) => handleFilesChange('avatar', file)}
                files={avatar}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                key={`firstName${renderKey}`}
                label="First Name"
                value={userData?.firstName}
                disabled={isGetLoading || isActionLoading}
                onChange={(e) => handleOnChange('firstName', e.target.value)}
                error={!_.isEmpty(messages.firstName)}
                helperText={messages.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last name"
                value={userData?.lastName}
                key={`lastName${renderKey}`}
                required
                disabled={isGetLoading || isActionLoading}
                onChange={(e) => handleOnChange('lastName', e.target.value)}
                error={!_.isEmpty(messages.lastName)}
                helperText={messages.lastName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                label="DOB"
                inputFormat="MM/DD/YYYY"
                views={['day', 'month', 'year']}
                value={userData?.dob}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    fullWidth
                    {...params}
                    // required
                    error={!_.isEmpty(messages.date)}
                    helperText={messages.date}
                  />
                )}
                disabled={isGetLoading || isActionLoading}
                onChange={(e: Dayjs | null) => handleChangeDate('dob', e)}
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Dropdown
                listOf="GENDER"
                key={`gender${renderKey}`}
                value={userData?.gender || ''}
                disabled={isGetLoading || isActionLoading}
                onChange={(val) => handleOnChange('gender', val)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderUserContact = () => {
    return (
      <Collapse in={tabActive} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Phone Number"
              defaultValue={userData?.phoneNumber || ''}
              key={`phoneNumber${renderKey}`}
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('phoneNumber', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="City"
              defaultValue={userData?.city || ''}
              key={`city${renderKey}`}
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Zip Code"
              defaultValue={userData?.zipCode || ''}
              key={`zipCode${renderKey}`}
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('zipCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Country"
              defaultValue={country || ''}
              disabled
              key={`country${renderKey}`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Address"
              defaultValue={userData?.address || ''}
              key={`address${renderKey}`}
              rows={5}
              multiline
              disabled={isGetLoading || isActionLoading}
              onChange={(e) => handleOnChange('address', e.target.value)}
            />
          </Grid>
        </Grid>
      </Collapse>
    );
  };

  const renderMain = () => {
    return (
      <>
        <Dialogs.ChangePassword
          open={isOpen}
          handleClose={() => setIsOpen(false)}
        />
        <Grid container direction="column" rowSpacing={4}>
          <BreadCrumb title="Account Profile" />
          {_renderMainForm(_renderUserAccount(), 'Account')}
          {_renderMainForm(_renderUserInformation(), 'Information')}
          {_renderMainForm(_renderUserContact(), 'Contact', true)}
          <Grid container justifyContent="end" spacing={2} my={2}>
            <Grid item>
              <Button
                variant="contained"
                color="burntSienna"
                onClick={() => setIsOpen(true)}
                disabled={isGetLoading || isActionLoading}
              >
                Change Password
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                color="oceanGreen"
                onClick={() => handleSubmit()}
                loading={isGetLoading || isActionLoading}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default AdminAccountProfile;
