import {
  ICountryReportTemplate,
  ICountryReportStructure,
} from '@/Interfaces/CountryReport.interface';

const COUNTRY_REPORT: ICountryReportTemplate = {
  implementedLogo: [],
  reportFiles: [],
  executiveSummary: [
    {
      code: 'civilSocietyOverView',
      name: 'Civil Society Overview',
      content: '',
    },
    {
      code: 'keyFindingsAndRecommendations',
      name: 'Key Findings & Recommendations',
      content: '',
    },
  ],
  findings: [
    {
      code: 'area_1',
      codeName: 'Area 1',
      name: 'BASIC LEGAL GUARANTEES OF FREEDOMS',
      subAreas: [
        {
          code: 'area_1_sub_area_1_1',
          codeName: 'Sub-Area 1.1',
          name: 'Freedom of association',
          standards: [
            {
              code: 'area_1_sub_area_1_1_standard_1',
              codeName: 'Standard 1.1.1',
              name: 'Establishment of and Participation in CSOs',
              content: '',
            },
            {
              code: 'area_1_sub_area_1_1_standard_2',
              codeName: 'Standard 1.1.2',
              name: 'State Interference',
              content: '',
            },
            {
              code: 'area_1_sub_area_1_1_standard_3',
              codeName: 'Standard 1.1.3',
              name: 'Securing Financial Resources',
              content: '',
            },
          ],
        },
        {
          code: 'area_1_sub_area_1_2',
          codeName: 'Sub-Area 1.2',
          name: 'State Interference',
          standards: [
            {
              code: 'area_1_sub_area_1_2_standard_1',
              codeName: 'Standard 1.2.1',
              name: 'Freedom of Peaceful Assembly',
              content: '',
            },
            {
              code: 'area_1_sub_area_1_2_standard_2',
              codeName: 'Standard 1.2.2',
              name: ' Freedom of Expression',
              content: '',
            },
            {
              code: 'area_1_sub_area_1_2_standard_3',
              codeName: 'Standard 1.2.3',
              name: 'Access to Information',
              content: '',
            },
          ],
        },
      ],
      statistical: [],
    },
    {
      code: 'area_2',
      codeName: 'Area 2',
      name: 'FRAMEWORK FOR CSO FINANCIAL VIABILITY AND SUSTAINABILITY',
      subAreas: [
        {
          code: 'area_2_sub_area_2_1',
          codeName: 'Sub-Area 2.1',
          name: 'Tax/fiscal treatment for CSOs and their donors',
          standards: [
            {
              code: 'area_2_sub_area_2_1_standard_1',
              codeName: 'Standard 2.1.1',
              name: 'Tax Benefits',
              content: '',
            },
            {
              code: 'area_2_sub_area_2_1_standard_2',
              codeName: 'Standard 2.1.2',
              name: 'Incentives for Individual/Corporate Giving',
              content: '',
            },
          ],
        },
        {
          code: 'area_2_sub_area_2_2',
          codeName: 'Sub-Area 1.2',
          name: 'State support',
          standards: [
            {
              code: 'area_2_sub_area_2_2_standard_1',
              codeName: 'Standard 2.2.1',
              name: 'Public Funding Availability',
              content: '',
            },
            {
              code: 'area_2_sub_area_2_2_standard_2',
              codeName: 'Standard 2.2.2',
              name: 'Public Funding Distribution',
              content: '',
            },
            {
              code: 'area_2_sub_area_2_2_standard_3',
              codeName: 'Standard 2.2.3',
              name: 'Accountability, Monitoring and Evaluation of Public Funding',
              content: '',
            },
            {
              code: 'area_2_sub_area_2_2_standard_4',
              codeName: 'Standard 2.2.4',
              name: 'Non-Financial Support',
              content: '',
            },
          ],
        },
        {
          code: 'area_2_sub_area_2_3',
          codeName: ' Human resources',
          name: 'State support',
          standards: [
            {
              code: 'area_2_sub_area_2_3_standard_1',
              codeName: 'Standard 2.3.1',
              name: 'Employment in CSOs',
              content: '',
            },
            {
              code: 'area_2_sub_area_2_3_standard_2',
              codeName: 'Standard 2.3.2',
              name: 'Volunteering in CSOs',
              content: '',
            },
            {
              code: 'area_2_sub_area_2_3_standard_3',
              codeName: 'Standard 2.3.3',
              name: 'Non-Formal Education',
              content: '',
            },
          ],
        },
      ],
      statistical: [],
    },
    {
      code: 'area_3',
      codeName: 'Area 3',
      name: 'GOVERNMENT - CSO RELATIONSHIP',
      subAreas: [
        {
          code: 'area_3_sub_area_3_1',
          codeName: 'Sub-Area 3.1',
          name: 'Framework and practices for cooperation',
          standards: [
            {
              code: 'area_3_sub_area_3_1_standard_1',
              codeName: 'Standard 3.1.1',
              name: 'State Policies and Strategies for Development of and Cooperation with Civil Society',
              content: '',
            },
            {
              code: 'area_3_sub_area_3_1_standard_2',
              codeName: 'Standard 3.1.2',
              name: 'Institutions and Mechanisms for Development of and Cooperation with Civil Society',
              content: '',
            },
          ],
        },
        {
          code: 'area_3_sub_area_3_2',
          codeName: 'Sub-Area 3.2',
          name: 'Framework and practices for cooperation',
          standards: [
            {
              code: 'area_3_sub_area_3_1_standard_1',
              codeName: 'Standard 3.2.1',
              name: 'Standards for CSO Involvement',
              content: '',
            },
            {
              code: 'area_3_sub_area_3_2_standard_2',
              codeName: 'Standard 3.2.2',
              name: 'Public Access to Draft Policies and Laws',
              content: '',
            },
            {
              code: 'area_3_sub_area_3_2_standard_3',
              codeName: 'Standard 3.2.3',
              name: 'CSOs’ Representation in Cross-Sector Bodies',
              content: '',
            },
          ],
        },
        {
          code: 'area_3_sub_area_3_3',
          codeName: 'Sub-Area 3.3',
          name: 'Collaboration in social provision',
          standards: [
            {
              code: 'area_3_sub_area_3_3_standard_1',
              codeName: 'Standard 3.3.1',
              name: 'CSO Engagement in Service Provision and Competition for State Contracts',
              content: '',
            },
            {
              code: 'area_3_sub_area_3_3_standard_2',
              codeName: 'Standard 3.3.2',
              name: 'State Funding for CSO-Provided Services',
              content: '',
            },
            {
              code: 'area_3_sub_area_3_3_standard_3',
              codeName: 'Standard 3.3.3',
              name: 'Procedures for Contracting Services',
              content: '',
            },
            {
              code: 'area_3_sub_area_3_3_standard_4',
              codeName: 'Standard 3.3.4',
              name: 'Accountability, Monitoring and Evaluation of Service Provision',
              content: '',
            },
          ],
        },
      ],
      statistical: [],
    },
  ],
  background: {
    code: 'background',
    name: ' Background Country Overview',
    content: '<p>Background Country Overview</p>',
  },
  conclusionsAndRecommendations: {
    code: 'conclusionsAndRecommendations',
    name: 'Conclusions & Recommendations',
    content: '<p>Conclusions & Recommendations</p>',
  },
};

const DEFAULT_FINDING = {
  area_1: {
    area_1_sub_area_1_1: {
      area_1_sub_area_1_1_standard_1: '',
      area_1_sub_area_1_1_standard_2: '',
      area_1_sub_area_1_1_standard_3: '',
    },
    area_1_sub_area_1_2: {
      area_1_sub_area_1_2_standard_1: '',
      area_1_sub_area_1_2_standard_2: '',
      area_1_sub_area_1_2_standard_3: '',
    },
  },
  area_2: {
    area_2_sub_area_2_1: {
      area_2_sub_area_2_1_standard_1: '',
      area_2_sub_area_2_1_standard_2: '',
    },
    area_2_sub_area_2_2: {
      area_2_sub_area_2_2_standard_1: '',
      area_2_sub_area_2_2_standard_2: '',
      area_2_sub_area_2_2_standard_3: '',
      area_2_sub_area_2_2_standard_4: '',
    },
    area_2_sub_area_2_3: {
      area_2_sub_area_2_3_standard_1: '',
      area_2_sub_area_2_3_standard_2: '',
      area_2_sub_area_2_3_standard_3: '',
    },
  },
  area_3: {
    area_3_sub_area_3_1: {
      area_3_sub_area_3_1_standard_1: '',
      area_3_sub_area_3_1_standard_2: '',
    },
    area_3_sub_area_3_2: {
      area_3_sub_area_3_2_standard_1: '',
      area_3_sub_area_3_2_standard_2: '',
      area_3_sub_area_3_2_standard_3: '',
    },
    area_3_sub_area_3_3: {
      area_3_sub_area_3_3_standard_1: '',
      area_3_sub_area_3_3_standard_2: '',
      area_3_sub_area_3_3_standard_3: '',
      area_3_sub_area_3_3_standard_4: '',
    },
  },
};

const DEFAULT_COUNTRY_REPORT: ICountryReportStructure = {
  title: '',
  year: '',
  countryId: '',
  logo: null,
  fileEnglish: null,
  fileLocal: null,
  backgroundCountry: '',
  executive: {
    key: { keyFindingsAndRecommendations: [], executiveSummary: '' },
    civil: {
      numberOfRegistered: '',
      mainCivil: '',
      relevant: '',
      stateFunding: '',
      humanResources: '',
      cso: '',
      otherkey: '',
    },
  },
  finding: DEFAULT_FINDING,
  conclusionRecommendation: '',
};

const DEFAULT_EXECUTIVE_TREE = [
  {
    label: 'Civil Society Overview',
    children: '',
  },
  {
    label: 'Key Findings & Recommendations',
    children: '',
  },
];

const DEFAULT_FINDING_TREE = [
  {
    label: 'Area 1: BASIC LEGAL GUARANTEES OF FREEDOMS',
    children: [
      {
        label: 'Sub-Area 1.1: Freedom of association',
        children: [
          {
            label: 'Standard 1.1.1: Establishment of and Participation in CSOs',
            children: '',
          },
          {
            label: 'Standard 1.1.2: State Interference',
            children: '',
          },
          {
            label: 'Standard 1.1.3: Securing Financial Resources',
            children: '',
          },
        ],
      },
      {
        label: 'Sub-Area 1.2: State Interference',
        children: [
          {
            label: 'Standard 1.2.1: Freedom of Peaceful Assembly',
            children: '',
          },
          {
            label: 'Standard 1.2.2:  Freedom of Expression',
            children: '',
          },
          {
            label: 'Standard 1.2.3: Access to Information',
            children: '',
          },
        ],
      },
    ],
  },
  {
    label: 'Area 2: FRAMEWORK FOR CSO FINANCIAL VIABILITY AND SUSTAINABILITY',
    children: [
      {
        label: 'Sub-Area 2.1: Tax/fiscal treatment for CSOs and their donors',
        children: [
          {
            label: 'Standard 2.1.1: Tax Benefits',
            children: '',
          },
          {
            label: 'Standard 2.1.2: Incentives for Individual/Corporate Giving',
            children: '',
          },
        ],
      },
      {
        label: 'Sub-Area 1.2: State support',
        children: [
          {
            label: 'Standard 2.2.1: Public Funding Availability',
            children: '',
          },
          {
            label: 'Standard 2.2.2: Public Funding Distribution',
            children: '',
          },
          {
            label:
              'Standard 2.2.3: Accountability, Monitoring and Evaluation of Public Funding',
            children: '',
          },
          {
            label: 'Standard 2.2.4: Non-Financial Support',
            children: '',
          },
        ],
      },
      {
        label: ' Human resources: State support',
        children: [
          {
            label: 'Standard 2.3.1: Employment in CSOs',
            children: '',
          },
          {
            label: 'Standard 2.3.2: Volunteering in CSOs',
            children: '',
          },
          {
            label: 'Standard 2.3.3: Non-Formal Education',
            children: '',
          },
        ],
      },
    ],
  },
  {
    label: 'Area 3: GOVERNMENT - CSO RELATIONSHIP',
    children: [
      {
        label: 'Sub-Area 3.1: Framework and practices for cooperation',
        children: [
          {
            label:
              'Standard 3.1.1: State Policies and Strategies for Development of and Cooperation with Civil Society',
            children: '',
          },
          {
            label:
              'Standard 3.1.2: Institutions and Mechanisms for Development of and Cooperation with Civil Society',
            children: '',
          },
        ],
      },
      {
        label: 'Sub-Area 3.2: Framework and practices for cooperation',
        children: [
          {
            label: 'Standard 3.2.1: Standards for CSO Involvement',
            children: '',
          },
          {
            label: 'Standard 3.2.2: Public Access to Draft Policies and Laws',
            children: '',
          },
          {
            label:
              'Standard 3.2.3: CSOs’ Representation in Cross-Sector Bodies',
            children: '',
          },
        ],
      },
      {
        label: 'Sub-Area 3.3: Collaboration in social provision',
        children: [
          {
            label:
              'Standard 3.3.1: CSO Engagement in Service Provision and Competition for State Contracts',
            children: '',
          },
          {
            label: 'Standard 3.3.2: State Funding for CSO-Provided Services',
            children: '',
          },
          {
            label: 'Standard 3.3.3: Procedures for Contracting Services',
            children: '',
          },
          {
            label:
              'Standard 3.3.4: Accountability, Monitoring and Evaluation of Service Provision',
            children: '',
          },
        ],
      },
    ],
  },
];

const DEFAULT_EXECUTIVE = {
  keyFindingsAndRecommendations: [
    {
      finding: '',
      recommendation: '',
    },
  ],
  civilSocietyOverview: {
    numberOfRegistered: '',
    mainCivil: '',
    relevant: '',
    stateFunding: '',
    humanResources: '',
    cso: '',
    otherkey: '',
  },
};

const DEFAULT_LABEL_EXECUTIVE = {
  civilSocietyOverview: 'Civil Society Overview',
  keyFindingsAndRecommendations: 'Key Findings & Recommendations',
};

const DEFAULT_LABEL_CIVIL_SOCIETY = {
  numberOfRegistered: 'Number of registered organizations',
  mainCivil: 'Main civil society laws',
  relevant: 'Relevant changes in legal framework',
  stateFunding: `State funding (for the previous year)`,
  humanResources: `Human resources (employees and volunteers)`,
  cso: `CSO-Government Cooperation (body/ consultation mechanism)`,
  otherkey: 'Other key challenges',
};

const DEFAULT_LABEL_SUB_AREA = {
  area_1_sub_area_1_1: 'Sub-Area 1.1: FREEDOM OF ASSOCIATION',
  area_1_sub_area_1_2: 'Sub-Area 1.2: STATE INTERFERENCE',
  area_2_sub_area_2_1:
    'Sub-Area 2.1: TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
  area_2_sub_area_2_2: 'Sub-Area 2.2: STATE SUPPORT',
  area_2_sub_area_2_3: 'Sub-Area 2.3: HUMAN RESOURCES',
  area_3_sub_area_3_1: 'Sub-Area 3.1: FRAMEWORK AND PRACTICES FOR COOPERATION',
  area_3_sub_area_3_2:
    'Sub-Area 3.2: INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
  area_3_sub_area_3_3: 'Sub-Area 3.3: COLLABORATION IN SERVICE PROVISION',
};

const KEY_FINDINGS = [
  {
    label: 'Key Findings',
    value: 'finding',
  },
  {
    label: 'Key Recommendations',
    value: 'recommendation',
  },
];

export {
  DEFAULT_FINDING,
  COUNTRY_REPORT,
  DEFAULT_COUNTRY_REPORT,
  DEFAULT_EXECUTIVE_TREE,
  DEFAULT_FINDING_TREE,
  DEFAULT_LABEL_CIVIL_SOCIETY,
  DEFAULT_EXECUTIVE,
  DEFAULT_LABEL_EXECUTIVE,
  KEY_FINDINGS,
  DEFAULT_LABEL_SUB_AREA,
};
