import React, { useState, useEffect } from 'react';
import {
  Stack,
  IconButton,
  ClickAwayListener,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Dialogs from '@/Components/LayoutPart/Dialogs';

import MainPopper from './MainPopper';

import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '@/Constants';

const GalleryPopper: React.FC = () => {
  // Constructors
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  const _renderGalleryActionPopup = () => {
    return (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Stack>
          <MenuItem
            onClick={() =>
              setConfirm({
                ...confirm,
                isOpen: true,
                message: 'Are you sure you want to change the content?',
              })
            }
          >
            <ListItemIcon
              sx={{
                minWidth: '8px',
              }}
            >
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          <Dialogs.Confirm
            callback={() => {}}
            confirm={confirm}
            onCancel={() => setConfirm({ ...confirm, isOpen: false })}
          />
        </Stack>
      </ClickAwayListener>
    );
  };

  return (
    <MainPopper
      content={_renderGalleryActionPopup()}
      open={isOpen}
      placement="left-end"
      style={{ minWidth: 'unset' }}
      onClose={() => setIsOpen(false)}
      sx={{
        '.main-popper-content': {
          p: 0,
        },
      }}
    >
      <IconButton
        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <InfoIcon />
      </IconButton>
    </MainPopper>
  );
};

export default GalleryPopper;
