import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    ...DEFAULT_PAGINATION,
    orderBy: 'DESC',
    sortBy: 'year',
  },
  meta: null,
  scoringList: [],
  scoringDetail: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_USER_SCORING_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_USER_SCORING_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_USER_SCORING_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_USER_SCORING_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_USER_SCORING_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_USER_SCORING_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_USER_SCORING_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        scoringList: payload,
      };
    case ACTION_TYPES.FETCH_USER_SCORING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.FETCH_ALL_USER_SCORING_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        scoringList: payload,
      };
    case ACTION_TYPES.FETCH_ALL_USER_SCORING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_USER_SCORING_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        scoringDetail: payload,
      };
    case ACTION_TYPES.GET_USER_SCORING_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_USER_SCORING_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        scoringDetail: payload,
      };
    case ACTION_TYPES.CREATE_USER_SCORING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.UPDATE_USER_SCORING_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        scoringDetail: payload,
      };
    case ACTION_TYPES.UPDATE_USER_SCORING_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
