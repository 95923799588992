export default {
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  confirm: 'Confirm',
  reject: 'Reject',
  warningGetDetailsFailure: `An error occurred while getting current {{name}}'s info. Please try later!`,
  warningGetUserInfoFailure:
    'We meet some trouble when detect your information.\n We will redirect you to login page!',
  warningGetCurrentUserIdFailure:
    'We meet some trouble when getting current user id. Please try again!',
  warningGetCurrentEventIdToAssignCoHostFailure: `We can't detect current event id to assign co-host. Please try again!`,
  warningGetEventByIdFailure: `Can't detect current event. We will direct you to {{page}} page!`,
  warningGetEventDetailsFailure:
    'We meet some trouble to redirect to the event details.\n Please try again!',
  confirmMessage: 'Are you sure you want to {{status}} {{name}}?', //if you don't use [status, name] , you can set its value to an empty string
  activateMessage: 'Click to activate this {{name}}',
  deactivateMessage: 'Click to deactivate this {{name}}',
  warningBeforeClearForm: 'Are you sure you want to clear all fields?',
  warningBeforeClearModifyEventAdminForm:
    'Are you sure you want to clear all fields except username and email?',
  warningWebsiteHasSomeTrouble:
    'Website has some trouble. You will direct to login page. Please re-login and try again',
  clearSuccess: 'Clear form success',

  //sponsor
  getIDSponsorFailure:
    "Can't detect current Sponsor. We will direct you to Sponsor List page!",
  modifyFailure:
    'We meet some trouble when getting current sponsor id. Please re-modify!',
  addSponsorTo: 'Add this sponsor to',
  //Booth
  warningSelectEventFirst: 'Please select event first',
  warningRedirectToEventDetails:
    'Are you sure you want to direct to details of event {{name}}. \nWe will save all of them to temp',
  warningCantDetectInfo: `Can't detect some information. Please try again!`,
};
