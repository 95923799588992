import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import PublicIcon from '@mui/icons-material/Public';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import FlagIcon from '@mui/icons-material/Flag';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CompareIcon from '@mui/icons-material/Compare';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { ISideBarList } from '@interfaces/Menu.interface';
import { ROUTERS } from '@constants';

const USER_MENU: ISideBarList[] = [
  {
    label: 'Home',
    value: ROUTERS.HOME,
    icon: <HomeIcon />,
  },
  {
    label: 'About',
    value: ROUTERS.ABOUT_BACKGROUND,
    icon: <InfoIcon />,
    subOptions: [
      {
        label: 'Background',
        value: ROUTERS.ABOUT_BACKGROUND,
      },
      {
        label: 'EU CS Guidelines',
        value: ROUTERS.GUIDELINES,
      },
      {
        label: 'Methodology',
        value: ROUTERS.METHODOLOGY,
      },
      {
        label: 'MM Toolkit',
        value: ROUTERS.ABOUT_MMTOOLKIT,
      },
    ],
  },
  {
    label: 'Country Reports',
    value: ROUTERS.COUNTRY_REPORT,
    icon: <PublicIcon />,
  },
  {
    label: 'Regional Reports',
    value: ROUTERS.REGIONAL_REPORT,
    icon: <DescriptionIcon />,
  },
  {
    label: 'News',
    value: ROUTERS.NEWS,
    icon: <NewspaperIcon />,
  },
  {
    label: 'Compare/Explore',
    value: ROUTERS.COMPARE_EXPLORE_YEAR,
    icon: <CompareIcon />,
    subOptions: [
      {
        label: 'Compare Across Years',
        value: ROUTERS.COMPARE_EXPLORE_YEAR,
      },
      {
        label: 'Compare Across Countries',
        value: ROUTERS.COMPARE_EXPLORE_COUNTRY,
      },
    ],
  },
];

const REVIEWER_MENU: ISideBarList[] = [
  {
    label: 'User Portal',
    value: ROUTERS.HOME,
    icon: <HomeIcon />,
  },
  {
    label: 'Dashboard',
    value: ROUTERS.REVIEWER_DASHBOARD,
    icon: <DashboardIcon />,
  },
  {
    label: 'Country Scoring',
    value: ROUTERS.REVIEW_SCORING,
    icon: <NewspaperIcon />,
  },
  {
    label: 'Country Reports',
    value: ROUTERS.REVIEW_COUNTRY_REPORT,
    icon: <SummarizeIcon />,
  },
  {
    label: 'Account',
    value: ROUTERS.ACCOUNT_REVIEWER,
    icon: <SettingsIcon />,
  },
  {
    label: 'Logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon />,
  },
];

const ADMIN_MENU: ISideBarList[] = [
  {
    label: 'User Portal',
    value: ROUTERS.HOME,
    icon: <HomeIcon />,
  },
  {
    label: 'Dashboard',
    value: ROUTERS.ADMIN_DASHBOARD,
    icon: <DashboardIcon />,
  },
  {
    label: 'Managements',
    value: 'managerment',
    icon: <AccountTreeIcon />,
    subOptions: [
      {
        label: 'Users',
        value: ROUTERS.USER_MANAGEMENT,
      },
      {
        label: 'News',
        value: ROUTERS.NEWS_MANAGEMENT,
      },
      {
        label: 'Countries',
        value: ROUTERS.COUNTRY_MANAGEMENT,
      },
      {
        label: 'Galleries',
        value: ROUTERS.GALLERY_MANAGEMENT,
      },
    ],
  },

  {
    label: 'Reports',
    value: 'report',
    icon: <FlagIcon />,
    subOptions: [
      {
        label: 'Country Scoring',
        value: ROUTERS.COUNTRY_SCORING_MANAGEMENT,
      },
      {
        label: 'Country Reports',
        value: ROUTERS.COUNTRY_REPORT_MANAGEMENT,
      },
      {
        label: 'Regional Reports',
        value: ROUTERS.REGIONAL_REPORT_MANAGEMENT,
      },
    ],
  },
  {
    label: 'Components',
    value: 'components',
    icon: <SettingsInputComponentIcon />,
    subOptions: [
      {
        label: 'Footer',
        value: ROUTERS.FOOTER_MANAGEMENT,
      },
      {
        label: 'Social Links',
        value: ROUTERS.SOCIAL_LINKS,
      },
    ],
  },
  {
    label: 'Pages',
    value: 'pages',
    icon: <ViewCarouselIcon />,
    subOptions: [
      {
        label: 'Background',
        value: ROUTERS.ADMIN_BACKGROUND,
      },
      {
        label: 'EU CS Guidelines',
        value: ROUTERS.ADMIN_GUIDELINES,
      },
      {
        label: 'Methodology',
        value: ROUTERS.ADMIN_METHODOLOGY,
      },
      {
        label: 'MM Toolkit',
        value: ROUTERS.ADMIN_MMTOOLKIT,
      },
    ],
  },
  {
    label: 'Account',
    value: ROUTERS.ACCOUNT_ADMIN,
    icon: <SettingsIcon />,
  },
  {
    label: 'Logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon />,
  },
];

export default {
  USER_MENU,
  REVIEWER_MENU,
  ADMIN_MENU,
};
