import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Avatar,
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  Container,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IFormStructure } from '@/Interfaces/Auth.interface';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import { ROUTERS } from '@constants';
import Utils from '@/Utils';

const { login } = AuthActions;

const DEFAULT_PAYLOAD = {
  account: '',
  password: '',
};

const SignIn: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const isLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );
  const [loginForm, setLoginForm] = useState({
    account: '',
    password: '',
    remember: false,
  });

  const [payload, setPayload] = React.useState<IFormStructure>(DEFAULT_PAYLOAD);
  const [msg, setMsg] = React.useState<IFormStructure>(DEFAULT_PAYLOAD);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [allowRender, setAllowRender] = useState<boolean>(false);
  useEffect(() => {
    const token = Utils.getSavedToken();
    const refreshToken = Utils.getSavedRefreshToken();
    if (token && refreshToken) Utils.redirect(ROUTERS.HOME);
    else setAllowRender(true);
  }, []);

  const handleChange = (key: string, value: string) => {
    const isExistMsg = _.get(msg, key);
    if (isExistMsg) {
      setMsg({
        ...msg,
        [key]: '',
      });
    }
    const newValue = {
      [key]: value,
    };
    setLoginForm({ ...loginForm, [key]: value });
    setPayload({ ...payload, ...newValue });
  };

  const validatePayload = () => {
    const requiredPayload: {
      key: string;
      label: string;
      value: any;
      type: string;
    }[] = [
      {
        key: 'account',
        label: 'account',
        value: payload.account,
        type: 'account',
      },
      {
        key: 'password',
        label: 'Password',
        value: payload.password,
        type: 'password',
      },
    ];

    const result = Utils.detectValidPayload(requiredPayload);

    return result;
  };
  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { isValid, invalidMsg } = validatePayload();
    if (!isValid) setMsg(invalidMsg);
    else {
      dispatch(login(loginForm));
    }
  };

  const _renderInputs = () => (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        error={!_.isEmpty(msg.account)}
        margin="normal"
        fullWidth
        disabled={isLoading}
        key="email"
        helperText={msg.account}
        label="Email Address"
        type="email"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e: any) => handleChange('account', e.target.value)}
        required
      />
      <TextField
        error={!_.isEmpty(msg.password)}
        margin="normal"
        disabled={isLoading}
        fullWidth
        name="password"
        helperText={msg.password}
        label="Password"
        type={showPassword ? 'text' : 'password'}
        key="password"
        autoComplete="current-password"
        onChange={(e) => handleChange('password', e.target.value)}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {!showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            value="remember"
            color="primary"
            disabled={isLoading}
            onChange={(e) => {
              Utils.saveIsRemember(e.target.checked);
              setLoginForm({ ...loginForm, remember: e.target.checked });
            }}
          />
        }
        label="Remember me"
      />
      <LoadingButton
        type="submit"
        color="burntSienna"
        fullWidth
        variant="contained"
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </LoadingButton>
      <Grid container>
        <Grid item xs>
          <Typography
            variant="body2"
            color="primary"
            onClick={() => Utils.redirect(ROUTERS.FORGOT_PASSWORD)}
            sx={{ cursor: 'pointer' }}
          >
            Forgot password?
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const renderMain = () => {
    if (!allowRender) return <></>;
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Container maxWidth="xs">
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Avatar sx={{ m: 1, bgcolor: CommonColors.fuzzyWuzzyBrown }}>
                  <LockOutlinedIcon />
                </Avatar>
              </Grid>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {_renderInputs()}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default SignIn;
