import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Typography,
  Box,
  // useMediaQuery,
} from '@mui/material';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
// import { useTheme } from '@mui/material/styles';

import { REPORT_KEY } from '@/Constants/RegionalReport';
import { RootState } from '@/store';
import { NoDataWereFound } from '@/Components/Common';

const OverViewUserTable: React.FC = () => {
  // Constructors
  // const theme = useTheme();
  // const isXs = useMediaQuery(theme.breakpoints.up('lg'));

  const [defaultTable, setDefaultTable] = useState<any>(null);
  // const [scrollState, setScrollState] = useState<any>(0);
  // const [initialization, setInitialization] = useState<boolean>(false);
  // const [isDisableScroll, setIsDisableScroll] = useState<boolean>(true);
  const dataRegional = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORTS_USER, 'dataRegional')
  );
  // const refTable = useRef<HTMLDivElement>(null);
  // const refWidthTable = useRef<HTMLTableElement>(null);

  // useEffect(() => {
  //   if (!initialization) setInitialization(true);
  //   else {
  //     const tableWidth = refWidthTable.current?.clientWidth || 0;
  //     if (tableWidth > 1440) setIsDisableScroll(false);
  //   }
  // }, [initialization]);

  // const handleScrollTable = (direction: string) => {
  //   if (refTable && refTable?.current) {
  //     if (isXs) {
  //       let numberScroll = 1500;
  //       if (direction === 'left') numberScroll *= -1;
  //       refTable.current.scrollLeft += numberScroll;
  //       setScrollState((refTable.current.scrollLeft += numberScroll));
  //     } else {
  //       let numberScroll = 150;
  //       if (direction === 'left') numberScroll *= -1;
  //       refTable.current.scrollLeft += numberScroll;
  //       setScrollState((refTable.current.scrollLeft += numberScroll));
  //     }
  //   }
  // };

  useEffect(() => {
    if (!_.isEmpty(dataRegional)) {
      const civilSociety = _.get(dataRegional, 'civilSociety');
      const data = JSON.parse(civilSociety);
      setDefaultTable(data);
    } else {
      setDefaultTable(null);
    }
  }, [dataRegional]);

  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        <TableCell
          sx={{
            position: 'sticky',
            left: 0,
            background: 'white',
            zIndex: 999,
            width: 200,
            maxWidth: 250,
          }}
        />
        {_.map(defaultTable, (item) => (
          <TableCell
            sx={{ fontWeight: 'bold', width: 250 }}
            align="center"
            key={`head-${item.value}`}
          >
            {item.name}
          </TableCell>
        ))}
      </TableRow>
    );
  }, [defaultTable]);

  const _renderTableBody = () => {
    return _.map(REPORT_KEY, (item, index) => {
      return (
        <TableRow key={`row${item.value}${index}`}>
          <TableCell
            align="center"
            sx={{
              fontWeight: 'bold',
              position: 'sticky',
              left: 0,
              background: 'white',
              zIndex: 999,
              borderRight: '1px solid #E0E0E0',
            }}
          >
            <Typography
              component="p"
              sx={{
                fontWeight: 'bold',
                background: 'white',
              }}
            >
              {item.label}
            </Typography>
          </TableCell>
          {_.map(defaultTable, (row, innerKey) => (
            <TableCell
              sx={{
                fontSize: 15,
                maxWidth: 150,
                borderRight: '1px solid #E0E0E0',
                wordWrap: 'break-word',
              }}
              align="left"
              key={`row${innerKey}-${index}`}
            >
              {_.get(row?.content, item?.value)}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const _renderTable = () => {
    return (
      <Box>
        {defaultTable?.length > 0 ? (
          <Box sx={{ position: 'relative' }}>
            {/* <Button
              sx={{
                position: 'absolute',
                top: '50%',
                left: '-80px',
                zIndex: 1000,
                '@media(max-width: 1024px)': {
                  display: 'none',
                },
              }}
              onClick={() => handleScrollTable('left')}
              disabled={scrollState < 1}
            >
              <KeyboardDoubleArrowLeftIcon />
            </Button>
            <Button
              sx={{
                position: 'absolute',
                top: '50%',
                right: '-80px',
                zIndex: 1000,
                '@media(max-width: 1024px)': {
                  display: 'none',
                },
              }}
              disabled={scrollState > 1500 || isDisableScroll}
              onClick={() => handleScrollTable('right')}
            >
              <KeyboardDoubleArrowRightIcon />
            </Button> */}
            <Paper
              sx={{
                position: 'relative',
                '@media(max-width: 1024px)': {
                  overflowX: 'scroll',
                },
              }}
            >
              <Table>
                <TableHead>{_renderTableHeader}</TableHead>
                <TableBody>{_renderTableBody()}</TableBody>
              </Table>
            </Paper>
          </Box>
        ) : (
          <NoDataWereFound height={300} />
        )}
      </Box>
    );
  };

  return _renderTable();
};

export default OverViewUserTable;
