import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import {
  ICreateComment,
  IFecthPostIdComments,
  IUpdateComment,
} from '@/Interfaces/Comment.interface';

// SINGLE ACTIONS
const setGetCommetLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COMMENT_GET_LOADING,
    payload,
  };
};

const setFetchCommentLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COMMENT_FETCH_LOADING,
    payload,
  };
};

const setCommentActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COMMENT_ACTION_LOADING,
    payload,
  };
};
const resetCommentReducer = () => {
  return {
    type: ACTION_TYPES.RESET_COMMENT_REDUCER,
  };
};
// ASYNC ACTIONS
const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_COMMENT_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_COMMENT_FAILURE,
  };
};

const createComment = (payload: ICreateComment) => {
  return async (dispatch: any) => {
    dispatch(setCommentActionLoading(true));
    await API.createComment(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
          await dispatch(fetchComments({ postId: payload.postId }));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = (payload: IUpdateComment) => {
  return {
    type: ACTION_TYPES.UPDATE_COMMENT_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_COMMENT_FAILURE,
  };
};

const updateComment = (payload: IUpdateComment) => {
  return async (dispatch: any) => {
    dispatch(setCommentActionLoading(true));
    await API.updateComment(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COMMENT_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COMMENT_FAILURE,
  };
};

const fetchComments = (payload: IFecthPostIdComments) => {
  return async (dispatch: any) => {
    dispatch(setFetchCommentLoading(true));
    await API.fetchComments(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          dispatch(fetchSuccess(items));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateComment) => {
  return {
    type: ACTION_TYPES.GET_COMMENT_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_COMMENT_BY_ID_FAILURE,
  };
};

const getCommentById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetCommetLoading(true));
    await API.getCommentById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_COMMENT_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_COMMENT_FAILURE,
  };
};

const deactiveComment = (id: string, postId: string) => {
  return async (dispatch: any) => {
    dispatch(setCommentActionLoading(true));
    await API.deactiveComment(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(deactiveFail());
        else dispatch(deactiveSuccess());
        await dispatch(fetchComments({ postId: postId }));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

export default {
  createComment,
  resetCommentReducer,
  updateComment,
  getCommentById,
  fetchComments,
  deactiveComment,
};
