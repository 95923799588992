import { SxProps, Theme } from '@mui/system';

export const mainStyles: SxProps<Theme> = {
  position: 'relative',
  height: '100%',
  overflow: 'auto',
  fontFamily: 'Lato',
  // background: CommonColors.mainBackground,
};

export const portalStyles: SxProps<Theme> = {
  flexGrow: 1,
  display: 'flex',
  height: '100%',
};

export const landingStyles: SxProps<Theme> = {
  flexGrow: 1,
  display: 'flex',
  height: '100%',
};
