import { INewsList } from '@/Interfaces/News.interface';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';
import {
  Breadcrumbs,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { newsContent } from './NewsContent.Style';

export interface INewsCard {
  data: INewsList;
}

const CardNewsContent: React.FC<INewsCard> = ({ data }: INewsCard) => {
  const breadcrumbs = [
    <Typography
      sx={{
        color: CommonColors.orangeRoughy,
        textTransform: 'uppercase',
        fontWeight: 600,
        maxWidth: { xs: 80, md: 60, lg: 1 },
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden !important',
        pt: 1,
      }}
      key="1"
    >
      {data.category}
    </Typography>,
    <Typography key="2">
      {data?.publishAt && Utils.getFormatDate(data.publishAt)}
    </Typography>,
  ];

  return (
    <Card
      sx={{
        height: '100%',
        p: 1,
        cursor: 'pointer',
        transition: '.4s transform linear, .2s box-shadow linear',
        ':hover': {
          boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
          transform: 'translate(0px, -3px)',
        },
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs separator="|" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sx={{ height: 210 }}>
            <Typography
              sx={{
                ...newsContent,
                WebkitLineClamp: 1,
              }}
              variant="h5"
              my={1}
            >
              {data.title}
            </Typography>
            <Box
              sx={{
                ...newsContent,
                WebkitLineClamp: { xs: 3, md: 4 },
                textOverflow: 'ellipsis',
                maxHeight: '80%',
                '& *': {
                  fontSize: '17px',
                  fontWeight: '400',
                },
                '& img': {
                  display: 'none !important',
                },
              }}
              component="div"
              dangerouslySetInnerHTML={Utils.createMarkup(data?.content)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                maxWidth: 220,
                display: 'inline-block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden !important',
              }}
              color={CommonColors.hippieBlue}
            >
              {data.source}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default CardNewsContent;
