import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IUpdateSelfPassword } from '@interfaces/User.interface';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { USER } = API;

export const fetchUsers = async (payload: IPaginationFilter) => {
  return sendRequest(USER.FETCH, 'GET', payload);
};

export const getUserById = async (id: string) => {
  return sendRequest(`${USER.GET_BY_ID}${id}`, 'GET');
};

export const getSelfProfile = async () => {
  return sendRequest(USER.GET_SELF, 'GET');
};

export const createUser = async (payload: FormData) => {
  return sendRequest(`${USER.REGISTER}`, 'FORM_DATA', payload);
};

export const updateUser = async (payload: FormData, id: string) => {
  payload.append('_method', 'PUT');
  return sendRequest(`${USER.UPDATE}${id}`, 'PUT_FORM_DATA', payload);
};

export const updateSelfProfile = async (payload: FormData) => {
  return sendRequest(USER.UPDATE_SELF, 'PUT_FORM_DATA', payload);
};

export const updateSelfPassword = async (payload: IUpdateSelfPassword) => {
  return sendRequest(USER.UPDATE_SELF_PASSWORD, 'PUT', payload);
};

export const activeUser = async (id: string) => {
  return sendRequest(`${USER.ACTIVE}${id}`, 'PUT');
};

export const deactiveUser = async (id: string) => {
  return sendRequest(`${USER.DEACTIVE}${id}`, 'PUT');
};

export const terminateUser = async (id: string) => {
  return sendRequest(`${USER.TERMINATE}${id}`, 'PUT');
};
