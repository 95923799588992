import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const mainStyles: SxProps<Theme> = {
  py: 2,
  px: 1,
};

export const mainTitleStyles: SxProps<Theme> = {
  color: CommonColors.gallery,
  fontSize: '50px',
  lineHeight: '50px',
  fontWeight: '900',
  letterSpacing: '5px',
};

export const mainHeaderStyles: SxProps<Theme> = {
  color: CommonColors.tundora,
  paddingBottom: '50px',
  textAlign: 'center',
  fontSize: '32px',
  lineHeight: '32px',
  fontWeight: '900',
  letterSpacing: '5px',
  paddingTop: '30px',
  paddingLeft: '15px',
  paddingRight: '15px,',
};

export const mainContentStyles: SxProps<Theme> = {
  display: 'block',
  boxSizing: 'border-box',
  mx: 3,
  marginBottom: '35px',
};

export const mainContentItemStyles: SxProps<Theme> = {
  color: CommonColors.black,
  fontSize: '12pt',
};

export const headerTable: SxProps<Theme> = {
  background: CommonColors.cosmic,
};

export const textTable: SxProps<Theme> = {
  textAlign: 'left',
  boxSizing: 'inherit',
  borderRight: '1px solid #E0E0E0',
  fontSize: 'inherit',
};
export const textTableRight: SxProps<Theme> = {
  textAlign: 'center',
  boxSizing: 'inherit',
  fontSize: 'inherit',
};
export const titleTable: SxProps<Theme> = {
  textAlign: 'center',
  boxSizing: 'inherit',
  color: CommonColors.white,
  fontSize: '1rem',
  fontWeight: 700,
  border: 'none',
};
