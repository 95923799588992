export default {
  noDataWereFound: 'No data were found',
  rowPerPage: 'Rows per page',

  //Event
  eventName: 'Event Name',
  eventTime: 'Event Time',
  eventDate: 'Event Date',
  eventType: 'Event Type',
  eventAdmin: 'Event Admin',

  //Sponsor
  sponsorName: 'Sponsor Name',
  sponsorWeb: 'Sponsor Web',
  sponsorLogo: 'Logo',
};
