export default {
  mainBackground: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
  mainColor: 'rgb(52, 71, 103)',
  burntSienna: '#E87844',
  bismark: '#517284',
  pickledBluewood: '#324A58',
  loblolly: '#C2D2D6',
  oceanGreen: '#4BB180',
  fuzzyWuzzyBrown: '#C95454',
  diSerria: '#DA9555',
  mystic: '#E7ECEF',
  astronaut: '#274C77',
  hippieBlue: '#6096BA',
  perano: '#A3CEF1',
  lemonGrass: '#8B8C89',
  black: '#000000',
  tundora: '#444444',
  cosmic: '#79405A',
  red: '#FF0000',
  orange: '#FFA500',
  yellow: '#FFFF00',
  limeGreen: '#32CD32',
  forestGreen: '#228B22',
  alto: '#e0e0e0',
  alabaster: '#f7f7f7',
  gallery: '#EEEEEE',
  nobel: '#B5B0B0',
  gray: '#808080',
  silverChalice: '#A8A8A8',
  white: '#FFFFFF',
  warmGold: '#F0bF60',
  blue: '#0000FF',
  blazeOrange: '#FF6600',
  orangeRoughy: '#CF471E',
  error: '#d32f2f',
};
