import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';
import { IUpdateCountry } from '@interfaces/Country.interface';
const { COUNTRY } = API;

export const fetchCountries = async (payload: IPaginationFilter) => {
  return sendRequest(COUNTRY.ROOT, 'GET', payload);
};

export const getCountryById = async (id: string) => {
  return sendRequest(`${COUNTRY.GET_BY_ID}${id}`, 'GET');
};

export const fetchAvaiableCountries = async () => {
  return sendRequest(COUNTRY.AVAIABLE, 'GET');
};

export const updateCountry = async (payload: IUpdateCountry) => {
  return sendRequest(`${COUNTRY.UPDATE}${payload.id}`, 'PUT', payload);
};

export const fetchCountryUnassignedForReview = async () => {
  return sendRequest(COUNTRY.UNASSIGN_FOR_REVIEW, 'GET');
};

export const fetchCountryUnassignedForUser = async () => {
  return sendRequest(COUNTRY.UNASSIGN_FOR_USER, 'GET');
};
