import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  MenuItem,
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import MainPopper from './MainPopper';
import { Dropdown } from '@/Components/Common';

import { DEFAULT_PAGINATION, ENUMS } from '@constants';

import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

interface ISectionProps {
  data: IPaginationFilter;
  onApply(data: IPaginationFilter): void;
  filterFor?: 'USER_STATUS' | 'NEWS_STATUS' | 'NEWS_STATUS_USER';
}

const { ROLES } = ENUMS;

const FilterPopper: React.FC<ISectionProps> = (props: ISectionProps) => {
  const { data, onApply, filterFor = 'USER_STATUS' } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [paginationState, setPaginationState] =
    useState<IPaginationFilter>(DEFAULT_PAGINATION);
  const [renderKey, setRenderKey] = useState(Math.random());
  useEffect(() => {
    if (data) {
      setPaginationState(data);
      setRenderKey(Math.random());
    }
  }, [data]);

  const handleApply = () => {
    if (paginationState)
      onApply({ ...paginationState, keyword: _.trim(paginationState.keyword) });
    setIsOpen(false);
  };

  const handleFieldChange = (key: string, value: string) =>
    setPaginationState({ ...paginationState, [key]: value });

  const onReset = () => {
    setIsOpen(false);
    onApply({
      ...DEFAULT_PAGINATION,
      status: filterFor === 'NEWS_STATUS_USER' ? 'publish' : '',
      limit: filterFor === 'NEWS_STATUS_USER' ? 12 : 25,
      searchBy: `${filterFor === 'USER_STATUS' ? 'name' : 'title'}`,
    });
  };

  const _renderBodyFilter = (value: string) => {
    switch (value) {
      case 'USER_STATUS':
        return _renderFilterForUser();
      case 'NEWS_STATUS':
        return _renderFilterForNews();
      case 'NEWS_STATUS_USER':
        return _renderFilterForNewsUser();
      default:
        return '';
    }
  };

  const _renderFilterForUser = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel>Search by</FormLabel>
            <RadioGroup
              row
              value={paginationState?.searchBy || ''}
              onChange={(e) => handleFieldChange('searchBy', e.target.value)}
            >
              <FormControlLabel value="name" control={<Radio />} label="Name" />
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="Email"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            label="Role"
            value={paginationState.roleCode || 'all'}
            defaultValue=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFieldChange(
                'roleCode',
                e.target.value === 'all' ? '' : e.target.value
              )
            }
          >
            <MenuItem key="all" value={'all'}>
              ALL
            </MenuItem>
            {_.map(ROLES, (value, label) => {
              return (
                <MenuItem key={value} value={value || ''}>
                  {_.upperCase(label)}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Keyword"
            value={paginationState?.keyword || ''}
            onChange={(e) => handleFieldChange('keyword', e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (paginationState) onApply(paginationState);
                setIsOpen(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            key={renderKey}
            label="Status"
            listOf={filterFor}
            value={paginationState?.status || 'all'}
            onChange={(value: string) =>
              handleFieldChange('status', value === 'all' ? '' : value)
            }
          />
        </Grid>
      </Grid>
    );
  };

  const _renderFilterForNews = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Search by</FormLabel>
            <RadioGroup
              row
              value={paginationState?.searchBy || ''}
              onChange={(e) => handleFieldChange('searchBy', e.target.value)}
            >
              <FormControlLabel
                value="title"
                control={<Radio />}
                label="Title"
              />
              <FormControlLabel value="tag" control={<Radio />} label="Tag" />
              <FormControlLabel
                value="source"
                control={<Radio />}
                label="Source"
              />
              <FormControlLabel
                value="country"
                control={<Radio />}
                label="Country"
              />
              <FormControlLabel value="text" control={<Radio />} label="Text" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Keyword"
            value={paginationState?.keyword || ''}
            onChange={(e) => handleFieldChange('keyword', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            key={renderKey}
            label="Status"
            listOf={filterFor}
            value={paginationState?.status || 'all'}
            onChange={(value: string) =>
              handleFieldChange('status', value === 'all' ? '' : value)
            }
          />
        </Grid>
      </Grid>
    );
  };
  const _renderFilterForNewsUser = () => {
    return (
      <Grid container spacing={2} width={{ xs: 'auto', sm: 500 }}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Search by</FormLabel>
            <RadioGroup
              row
              value={paginationState?.searchBy || ''}
              onChange={(e) => handleFieldChange('searchBy', e.target.value)}
            >
              <FormControlLabel
                value="title"
                control={<Radio />}
                label="Title"
              />
              <FormControlLabel value="tag" control={<Radio />} label="Tag" />
              <FormControlLabel
                value="source"
                control={<Radio />}
                label="Source"
              />
              <FormControlLabel
                value="country"
                control={<Radio />}
                label="Country"
              />
              <FormControlLabel value="text" control={<Radio />} label="Text" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Keyword"
            value={paginationState?.keyword || ''}
            onChange={(e) => handleFieldChange('keyword', e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleApply()}
          />
        </Grid>
      </Grid>
    );
  };

  const _renderFilterPopup = () => {
    if (!paginationState) return null;
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          {_renderBodyFilter(filterFor)}
        </Grid>
        <Grid
          item
          sx={{ display: 'flex', gap: 3, justifyContent: 'flex-end', mt: 5 }}
        >
          <Button onClick={onReset}>Clear Filters</Button>
          <Button
            onClick={() => setIsOpen(false)}
            variant="contained"
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          <Button onClick={handleApply} variant="contained" color="oceanGreen">
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <MainPopper
      content={_renderFilterPopup()}
      open={isOpen}
      placement="right-start"
      onClose={() => setIsOpen(false)}
      sx={{ width: { xs: 380, sm: 550 }, zIndex: 999 }}
    >
      <Button
        size="large"
        variant="outlined"
        color="primary"
        startIcon={<FilterAltIcon />}
        onClick={() => setIsOpen(true)}
      >
        Filter
      </Button>
    </MainPopper>
  );
};

export default FilterPopper;
