import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

import { ICreateBackground } from '@interfaces/PageManagement.interface';

// SINGLE ACTIONS
const setGetBackgroundLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_BACKGROUND_HISTORY_GET_LOADING,
    payload,
  };
};

const setBackgroundActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_BACKGROUND_HISTORY_ACTION_LOADING,
    payload,
  };
};

const resetBackgroundReducer = () => {
  return {
    type: ACTION_TYPES.RESET_BACKGROUND_HISTORY_REDUCER,
  };
};

// ASYNC ACTIONS
const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_BACKGROUND_HISTORY_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_BACKGROUND_HISTORY_FAILURE,
  };
};

const getBackgroundHistory = () => {
  return async (dispatch: any) => {
    dispatch(setGetBackgroundLoading(true));
    await API.getBackground()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else dispatch(getSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_BACKGROUND_HISTORY_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_BACKGROUND_HISTORY_FAILURE,
  };
};

const createBackgroundHistory = (payload: ICreateBackground) => {
  return async (dispatch: any) => {
    dispatch(setBackgroundActionLoading(true));
    await API.createBackground(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

export default {
  getBackgroundHistory,
  resetBackgroundReducer,
  createBackgroundHistory,
};
