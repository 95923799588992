/* eslint-disable max-len */
import { CommonColors } from '@/Themes';

import {
  ICountryScoringTemplate,
  ICategoryContent,
  IIndicatorTemplate,
  ICategoryTable,
  IScoringStep,
  ICreateAreaScore,
} from '@interfaces/CountryScoring.interface';

const EVALUATION_TABLE: ICategoryTable[] = [
  {
    title: 'Fully disabling environment',
    color: CommonColors.red,
    key: 'fullyDisablingEnviroment',
    score: 1,
  },
  {
    title: 'Disabling environment',
    color: CommonColors.diSerria,
    key: 'disablingEnvironment',
    score: 2,
  },
  {
    title: 'Partially enabling environment',
    color: CommonColors.orange,
    key: 'partiallyEnablingEnvironment',
    score: 3,
  },
  {
    title: 'Enabling environment',
    color: CommonColors.limeGreen,
    key: 'enablingEnvironment',
    score: 4,
  },
  {
    title: 'Fully enabling environment',
    color: CommonColors.forestGreen,
    key: 'fullyEnablingEnvironment',
    score: 5,
  },
];

const DEFAULT_SCORING_FORM: ICreateAreaScore[] = [
  {
    code: 'area_1',
    subArea: [
      {
        code: 'area_1_sub_area_1_1',
        standard: [
          {
            code: 'area_1_sub_area_1_1_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_1_sub_area_1_1_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_1_sub_area_1_1_standard_3',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
      {
        code: 'area_1_sub_area_1_2',
        standard: [
          {
            code: 'area_1_sub_area_1_2_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_1_sub_area_1_2_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_1_sub_area_1_2_standard_3',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
    ],
  },
  {
    code: 'area_2',
    subArea: [
      {
        code: 'area_2_sub_area_2_1',
        standard: [
          {
            code: 'area_2_sub_area_2_1_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_2_sub_area_2_1_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
      {
        code: 'area_2_sub_area_2_2',
        standard: [
          {
            code: 'area_2_sub_area_2_2_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_2_sub_area_2_2_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_2_sub_area_2_2_standard_3',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_2_sub_area_2_2_standard_4',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
      {
        code: 'area_2_sub_area_2_3',
        standard: [
          {
            code: 'area_2_sub_area_2_3_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_2_sub_area_2_3_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_2_sub_area_2_3_standard_3',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
    ],
  },
  {
    code: 'area_3',
    subArea: [
      {
        code: 'area_3_sub_area_3_1',
        standard: [
          {
            code: 'area_3_sub_area_3_1_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_3_sub_area_3_1_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
      {
        code: 'area_3_sub_area_3_2',
        standard: [
          {
            code: 'area_3_sub_area_3_2_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_3_sub_area_3_2_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_3_sub_area_3_2_standard_3',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
      {
        code: 'area_3_sub_area_3_3',
        standard: [
          {
            code: 'area_3_sub_area_3_3_standard_1',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_3_sub_area_3_3_standard_2',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_3_sub_area_3_3_standard_3',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
          {
            code: 'area_3_sub_area_3_3_standard_4',
            typeStandard: [
              { type: 'legislation', indicator: [] },
              { type: 'practice', indicator: [] },
            ],
          },
        ],
      },
    ],
  },
];

const _generateCategories = (category: string[]): ICategoryContent => {
  return {
    fullyDisablingEnviroment: category[0],
    disablingEnvironment: category[1],
    partiallyEnablingEnvironment: category[2],
    enablingEnvironment: category[3],
    fullyEnablingEnvironment: category[4],
  };
};

const _generateIndicator = (
  code: string,
  codeName: string,
  name: string,
  explanation: string[],
  mandatory: string[],
  additional: string[],
  catergories: string[]
): IIndicatorTemplate => {
  return {
    code,
    codeName,
    name,
    explanation,
    mandatory,
    additional,
    catergories: _generateCategories(catergories),
  };
};

const STEP_OF_SCORINGS: IScoringStep[] = [
  {
    label: 'Basic Legal Guarantees of Freedoms',
    code: 'area_1',
    step: 0,
  },
  {
    label: 'Framework for CSO Financial Viability and Sustainability',
    code: 'area_2',
    step: 1,
  },
  {
    label: 'Government-CSO Relationship',
    code: 'area_3',
    step: 2,
  },
];

const SCORING_TEMPLATE: ICountryScoringTemplate[] = [
  {
    code: 'area_1',
    codeName: 'Area 1',
    name: 'Basic Legal Guarantees of Freedoms',
    subAreas: [
      {
        code: 'area_1_sub_area_1_1',
        codeName: 'Sub-Area 1.1',
        name: 'FREEDOM OF ASSOCIATION',
        principle:
          'Freedom of association is guaranteed and exercised freely by everybody',
        standards: [
          {
            code: 'area_1_sub_area_1_1_standard_1',
            codeName: 'STANDARD 1.1.1',
            name: 'All individuals and legal entities can freely establish, join and participate in informal and/or registered organizations offline and online',
            originalName: 'Establishment of and Participation in CSOs',
            legislation: [
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_legislation_indicator_1',
                'Indicator 1',
                'There is a legal framework according to which any person can establish associations, foundations and other types of non-profit, non-governmental entities for any purpose',
                [
                  'The guarantees of freedom of association should be contained in the Constitution of a country, and also further developed and made functional through law. The freedom of association does not only mean that one can establish an association or other legal non-profit form, but can also join existing organizations as a member in everyday activities as well as governance. There is no any legal limitation on the number of organizations that can exist which have similar purposes. The objectives of registered CSOs should be diverse and the only limitations are the ones recognized by international law. CSOs should be allowed to operate in different areas and pursue all legitimate aims.',
                ],
                [],
                [],
                [
                  'The legal framework does not provide guarantees for exercising the right to freedom of association.',
                  'The legal framework provides certain guarantees for exercising the right to freedom of association.',
                  'The legal framework provides guarantees for exercising the right to freedom of association, but has certain limitations in terms of types of organizations and internal organization.',
                  'The legal framework provides guarantees for exercising the right to freedom of association in line with international standards on freedom of association and human rights laws.',
                  'The legal framework provides guarantees for exercising the right to freedom of association for registered and unregistered organizations, in line with international standards on freedom of association and human rights laws.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_legislation_indicator_2',
                'Indicator 2',
                'The legal framework allows both individual and legal persons to exercise the right of freedom of association without discrimination (age, nationality, legal capacity, gender).',
                [
                  'Freedom of association does not only extend to individuals but also to legal entities (e.g. corporations). Therefore, laws in the country should allow for businesses or other types of legal entities to establish or join associations, foundations, federations or other types of non-profit, non-governmental entities. Any person regardless of gender, nationality and age has the right to join (or not to join) an organization. While there may be some requirements with regard to the legal capacity and the legal age of the persons that can become founders of an organization, those requirements should not prevent them from freely deciding to become members, join or govern their CSOs.',
                ],
                [],
                [],
                [
                  'The legal framework on freedom of association has certain discriminatory limitations for individuals and legal persons.',
                  'The legal framework on freedom of association allows all citizens to exercise their right to freedom of association, but does not provide the same guarantees for foreigners and has certain limitations for legal persons.',
                  'The legal framework on freedom of association allows individual and legal persons to exercise the right to freedom of association without any discrimination, but has certain limitations for foreigners.',
                  'The legal framework on freedom of association allows individual and legal persons to exercise the right to freedom of association off-line and online, without any discrimination, including foreigners.',
                  'The legal framework on freedom of association allows individual and legal persons to exercise the right to freedom of association without any discrimination, including foreigners.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_legislation_indicator_3',
                'Indicator 3',
                'Registration is not mandatory, and in cases when organizations decide to register, the registration rules are clearly prescribed and allow for easy, timely and inexpensive registration and appeal process.',
                [
                  'There should be no legal requirement for registering CSOs and the decision to do so should rest solely with the founders/members. An example of a violation of this principle is the case where people who associate are fined or persecuted should they start with implementation of activities before their registration as a legal entity is confirmed. Where founders decide to register the organization, the procedure for acquiring legal status is clearly described in a law. Organizations that complete all the legal requirements are granted legal personality.',
                  'There are no burdensome requirements, such as the need to have a large number of founders (good practice is between 2 and 5 founders). The fees collected by the registering agency (court, ministry, central registry, etc.) are not considered high compared to the living standard in the respective country. They do not discourage application and everybody should be able to afford to pay them. Registration decisions are made quickly (between 5 and 30 days) and applicants are informed about the decision in writing. Negative decisions for registration are subject to an appeal in a court or another independent body. In cases where there is no decision within the prescribed deadline, there should be an assumption that organization can start operating and this is accepted by other state bodies (if there is a need for a following registration e.g. with the social security agency, it accepts that the organization is registered and does not require a written registration decision).',
                ],
                [],
                [],
                [
                  'Registration is mandatory and registration rules and procedures are complex and have certain limitations.',
                  'Registration is mandatory and registration rules and procedures are clear but have certain limitations.',
                  'Registration is not mandatory, but registration rules and procedures have certain limitations.',
                  'Registration is not mandatory and registration rules are clearly prescribed and allow for easy, timely and inexpensive registration and appeal process.',
                  'Registration is not mandatory and registration rules are clearly prescribed and allow for easy, timely and free of charge registration and appeal process.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_legislation_indicator_4',
                'Indicator 4',
                'The law allows for networking among organizations in the countries and abroad without prior notification.',
                [
                  'Freedom of association extends to networks of organizations, their coalitions, federations or other types of unions. Communication with groups outside of the country should be possible and there should be no limitations. It also extends to online association. Blocking social network initiatives with the attempt to hinder communication between people/organizations has to follow the allowed limitations which apply to freedom of association. An example of limitation on the right to join networks or associate outside of the country are requirements for CSOs to inform or gain permission from state authorities to do so, and face fines if they fail to do it.',
                ],
                [],
                [],
                [
                  'The legal framework does not allow for networking among organizations in the country and/or abroad.',
                  'The legal framework allows for networking among organizations in the country with prior notification, but does not allow networking with organizations abroad.',
                  'The legal framework allows for networking among organizations in the country without prior notification, but does require prior notification for networking with organization abroad.',
                  'The legal framework allows for networking among organizations in the country and/or abroad without prior notification, registration procedure, or excessive administrative requirements.',
                  'The legal framework allows for networking among organizations in the country and/or abroad without prior notification, registration procedure, or excessive administrative requirements.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_practice_indicator_1',
                'Indicator 1',
                'Every individual or legal entity in practice can form associations, foundations or other non-profit, non-governmental organizations offline or online.',
                [
                  'There are various types of CSOs in practice; there are examples of organizations set up by women, and people with limited legal capacity or other disadvantaged groups.',
                  'Companies and individuals or other groups can form associations, foundations and non-profit entities. By being a founder or president who does not receive remuneration, the individual is not considered an employee per se, and does not lose unemployment benefits or status.',
                  'The state does not consider working for a CSO to mean that the person is undertaking a public function and is therefore subject to the same scrutiny as a public official. There are no restrictions to Internet applications, Facebook groups are not banned, web sites of organizations or accounts blocked or hacked, their passwords are not stolen, or entries changed, and Skype or other similar chat tools are not monitored.',
                ],
                [
                  'Number of cases of denial to register which are controversial and indicate limitation of freedom of association;',
                  'Number of organizational bans on annual level which are controversial and indicate limitation of freedom of association;',
                  'Number of banned, hacked, blocked or deleted online groups by authorities which are controversial and indicate limitation of freedom of association;',
                  'Number of cases when a person was denied by the authorities to serve as a founding member/ trustee/ executive board member.',
                  'Number of CSOs that report cases when moderators of online groups or their members have been harassed or experienced restrictions on their activities.',
                ],
                [
                  'Number of CSOs that report that persons occupying governing functions (e.g. founding members and members of the executive board such as secretary, president etc.) in their organization for which they are not remunerated are treated as organizational employees by the state;',
                  'Cases of CSOs where persons have lost unemployment benefits/ status due to their engagement in governing positions in the organization (e.g. founding members, members of the executive board such as secretary, president etc. which are voluntary in nature);',
                  'Cases of CSOs where persons are scrutinized as public officials by the state (e.g. employees and members of governing bodies of CSOs are asked to report their assets publicly or become subject to other accountability demands which apply to public officials).',
                ],
                [
                  'There are limitations on exercising the right on freedom of association. There are many complaints from individual and/or legal persons for not able to register a CSO.',
                  'There are some limitations on exercising the right on freedom of association. There are complaints from marginalized groups for getting unexplained refusal from state authorities on their request for registering a CSO. There are also restrictions on using ICT tool for exercising the freedom of association.',
                  'There are few limitations in exercising the right to freedom of association. There are no cases of unreasonable refusals for establishing and registering an association, foundation or any other type of CSO. There are cases of excessive registration requirements for certain type of organizations.',
                  'There are no limitations in exercising the right to freedom of association. Every individual or legal entity in practice can form associations, foundations or other non-profit, non-governmental organizations offline or online.',
                  'There are no limitations in exercising the right to freedom of association. Every individual or legal entity in practice can form associations, foundations or other non-profit, non-governmental organizations offline or online. There is an online platform for registration of a CSO and the registration is free of charge.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_practice_indicator_2',
                'Indicator 2',
                'Individuals and legal entities are not sanctioned for not registering their organizations.',
                [
                  'The government in practice does not require founders to register organizations in order to undertake activities or impose fines for not registering legal entities. There are no cases or complaints of unreasonable or unjustified denials of registration. There are unregistered organizations in society. As a good practice, unregistered organizations are present in public life, and cooperate with other CSOs or state bodies.',
                ],
                [
                  'Cases in which individuals (members/ representatives of informal groups) or informal groups were sanctioned (e.g. financial fines) for not being registered;',
                  'Cases of informal CSOs that faced requests for registration by the state.',
                ],
                [
                  'Report presence of informal groups in public life and examples of their cooperation with other CSOs.',
                ],
                [
                  'Individuals and/or legal entities are sanctioned for not registering their organizations.',
                  'Individuals and/or legal entities are not sanctioned for not registering their organizations, but have certain limitations.',
                  'There are cases of unreasonable or unjustified denials of registration.',
                  'Individuals and legal entities are not sanctioned for not-registering their organizations. There are no cases or complaints of unreasonable or unjustified denials of registration. There are unregistered organizations in society. Unregistered organizations are present within the sector and cooperate with other CSOs and state bodies.',
                  'Individuals and legal entities are not sanctioned for not-registering their organizations. There are no cases or complaints of unreasonable or unjustified denials of registration. There are unregistered organizations in society. State authorities and public considers unregistered CSOs as an added value in pluralization of society.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_practice_indicator_3',
                'Indicator 3',
                'Registration is truly accessible within the legally prescribed deadlines; authorities decide on cases in a non-subjective and apolitical manner.',
                [
                  'There are no reported cases of political influence over the registration process.Decisions are based on the law and not based on subjective interpretation of the legal requirements. No additional documents, other than the ones described in the law, are required for registration.',
                ],
                [
                  'Number of newly registered CSOs (in the last two years) which were registered within the legally prescribed time;',
                  'Number of CSOs that report that the registration procedure was conducted impartially;',
                  'Number of CSOs reporting that the required registration documents exceeded those stipulated by law.',
                ],
                [
                  'Report cases of differential or subjective treatment (case study or illustrative examples of typical cases).',
                ],
                [
                  'Registration is not accessible within the legally prescribed deadlines. Most of the registration processes exceed the prescribed deadlines. There are cases of denial of registration because of political affiliations or other subjective perceptions.',
                  'Registration is not accessible within the legally prescribed deadlines. There some examples of registration processes exceeding the prescribed deadlines. There are no reported cases of denial of registration because of one’s political affiliations.',
                  'In most cases registration is accessible within the legally prescribed deadlines. There few cases showing that registration procedure lasted more than it is prescribed in law. There are no reported cases of denial of registration because of one’s political affiliations.',
                  'Registration is truly accessible within the legally prescribed deadlines. There are no complaints or registrations filed showing delayed registration as compared to deadlines. Registration procedure is usually done much faster than prescribed deadlines. Authorities decide on cases in a non-subjective and apolitical manner. There are no cases of registration refusals because of real or perceived political affiliations.',
                  'Registration is truly accessible within the legally prescribed deadlines. There are no complaints or registrations filed showing delayed registration as compared to deadlines. Registration procedure is usually done much faster than prescribed deadlines. Authorities decide on cases in a non-subjective and apolitical manner. There are no cases of registration refusals in general.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_1_practice_indicator_4',
                'Indicator 4',
                'Individuals and CSOs can form and participate in networks and coalitions, within and outside their home countries.',
                [
                  'The practice is enabling, and there are examples of free networking among organizations both within and outside of the country. Organizations can join international umbrella groups or federations, join networks online by using various ICT tools, can undertake activities and promote their goals. Registration of networks is easy. Organizations do not face burdensome bureaucratic requirements designed to make networking difficult, such as requiring organizations to notify certain state body if they plan to join a network.',
                ],
                [
                  'Number of CSOs that reported active participation in networks (domestic and/or international);',
                  'Number of cases when CSOs faced restrictions for their participation in a network.',
                ],
                [
                  'Report number (examples) of newly formed networks in the past year.',
                  'If restrictions are recorded, report prominent cases.',
                ],
                [
                  'Individuals and CSOs cannot form and/or participate in networks and coalitions, within and outside their home countries. Registration of networks is not allowed.',
                  'Individuals and CSOs have some barriers to form and/or participate in networks and coalitions, within and outside their home countries. Registration of networks is complicated and costly. There are limitations on using ICT tools for communication among member organizations that are abroad. There only few registered local networks of organizations.',
                  'Individuals and CSOs have few barriers to form and/or participate in networks and coalitions in their country and abroad. The registration procedures for local networks are simple, and free of charge. There are many registered local networks of CSO. Registration of networks among organization abroad the country is complicated and costly. There only few registered local networks of organizations. There are no limitations on using ICT tools for communication among member organizations of the network.',
                  'Individuals and CSOs can form and participate in networks and coalitions, within and outside their home countries. There are no cases or reports showing restrictions on participating in activities of other organizations, in the country or abroad. There are no cases of denied registration request for networks.',
                  'Individuals and CSOs can form and participate in networks and coalitions, within and outside their home countries. There are no cases or reports showing restrictions on participating in activities of other organizations, in the country or abroad. There are no cases of denied registration request for networks or restrictions on using ICT tools. There are many registered and unregistered CSO networks.',
                ]
              ),
            ],
          },
          {
            code: 'area_1_sub_area_1_1_standard_2',
            codeName: 'STANDARD 1.1.2',
            originalName: 'State Interference',
            name: 'CSOs operate freely without unwarranted state interference in their internal governance and activities',
            legislation: [
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_legislation_indicator_1',
                'Indicator 1',
                'The legal framework provides guarantees against state interference in internal matters of associations, foundations and other types of non-profit entities.',
                [
                  'There are very limited grounds for state interference with freedom of association and those are prescribed in international law. Most relevant for European countries is the ECHR and the case law of the European Court for Human rights which prescribes the grounds for limitations and provides further interpretations through case law. According to the ECHR, the limitations to the freedom of association are allowed if: (1) they are prescribed by law; (2) pursue a legitimate aim; and (3) are necessary in a democratic society. Further to point 2, ECHR allows the following aims as legitimate: (1) in the interest of national security or public safety; (2) for the prevention of disorder or crime; (3) for the protection of health or morals; and (4) for the protection of the rights and freedoms of others.',
                  'CSOs are autonomous from the state and the law should guarantee their right to regulate their internal structure and operating procedures. For example, the law should not allow the state to interfere in the internal management of their affairs. Requirements such as the mandatory participation of a state representative in the meetings of an organizational body would violate this indicator. Similarly, the law should not allow states to interfere in the process of appointment of Board members.',
                  'The law should not require that CSOs receive advance approval from the state for carrying out their activities. The state may require those organizations to undergo certain registration/ licensing for certain areas when organizations want to work in or where interests of disadvantaged groups should be protected (e.g., services for children). However, those situations must be clearly prescribed by law and CSOs should not be discriminated against compared with other providers of such services (see Subarea 3.3 for further discussion on this).',
                ],
                [],
                [],
                [
                  `The legal framework does not provide guarantees against state interference in internal matters of associations, foundations and other types of non-profit entities. This might include:
                    \n•	CSOs are not autonomous from the state and the law does not guarantee their right to regulate their internal structure and operating procedures.
                    \n•	The law requires mandatory participation of a state representative in the meetings of an organizational body.
                    \n•	The law allows states to interfere in the process of appointment of Board members.
                    \n•	The law requires that CSOs receive advance approval from the state for carrying out their activities.
                  `,
                  `The legal framework provides certain guarantees against state interference in internal matters of associations, foundations and other types of non-profit entities. This might include:
                    \n•	CSOs are autonomous from the state, however the right of CSOs to regulate their internal structure and operating procedures is unclear.
                    \n•	The law requires mandatory participation of a state representative in the meetings of an organizational body.
                    \n•	The law allows states to interfere in the process of appointment of Board members.
                    \n•	The law requires that CSOs receive advance approval from the state for carrying out their activities.
                  `,
                  `The legal framework provides guarantees against state interference in internal matters of associations, foundations and other types of non-profit entities, and minor issues are not regulated. This might include:
                    \n•	CSOs are autonomous from the state and the law provides guarantees of the right of CSOs to regulate their internal structure and operating procedures, with minor issues not being regulated.
                    \n•	The law does not require mandatory participation of a state representative in the meetings of an organizational body.
                    \n•	The law does not allow states to interfere in the process of appointment of Board members.
                    \n•	The law does not require that CSOs receive advance approval from the state for carrying out their activities.
                  `,
                  `The legal framework provides guarantees against state interference in internal matters of associations, foundations and other types of non-profit entities and is fully in line with international standards. This might include:
                    \n•	CSOs are autonomous from the state and the law provides guarantees of the right of CSOs to regulate their internal structure and operating procedures.
                    \n•	The law does not require mandatory participation of a state representative in the meetings of an organizational body.
                    \n•	The law does not allow states to interfere in the process of appointment of Board members.
                    \n•	The law does not require that CSOs receive advance approval from the state for carrying out their activities.
                  `,
                  `The legal framework provides guarantees against state interference in internal matters of associations, foundations and other types of non-profit entities fully in line and even surpasses international standards. This might include:
                    \n•	CSOs are autonomous from the state and the law provides guarantees of the right of CSOs to regulate their internal structure and operating procedures.
                    \n•	The law does not require mandatory participation of a state representative in the meetings of an organizational body.
                    \n•	The law does not allow states to interfere in the process of appointment of Board members.
                    \n•	The law does not require that CSOs receive advance approval from the state for carrying out their activities.
                    \n•	The state clearly prescribes in law if registration/licensing for certain areas of operation of CSOs is needed, and the law should not discriminate against CSOs compared with other providers of such services.
                  `,
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_legislation_indicator_2',
                'Indicator 2',
                'The state provides protection from interference by third parties.',
                [
                  'States have not only the negative obligation – not to violate the freedom of association and to allow the operation of CSOs, but also a positive obligation – to protect freedom of association, including preventing third parties from violating the freedom of association.',
                  'The state has set up measures to protect the right – e.g., police force to protect people against violations of their rights by state or non-state actors, and an independent judiciary able to provide remedies.  If a certain group’s operation is hindered by another (e.g. Roma associations or LGBT groups), the legal framework should have guarantees that the state can protect their operation.',
                ],
                [],
                [],
                [
                  'The legislation does not provide protection from interference by third parties. There are no measures to protect individuals and/or groups operations from interference by third parties.',
                  'The legislation provides protection from interference by third parties to a certain extent, with major issues not being regulated. There are very few provisions in legislation that provide protection of individuals and/or groups operations from interference by third parties.',
                  'The legislation provides protection of individuals and/or groups operations from interference by third parties, in line with international standards.',
                  'The legislation provides protection of individuals and/or groups operations from interference by third parties, in line with international standards.',
                  'The legislation provides protection of individuals and or/groups operations from interference by third parties and even surpasses the international standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_legislation_indicator_3',
                'Indicator 3',
                'Financial reporting (including money laundering regulations) and accounting rules take into account the specific nature of the CSOs and are proportionate to the size of the organization and its type/scope of activities.',
                [
                  'The regulations on CSO reporting to the state should be based on the specific characteristics of the work CSOs do. The reports they submit should be adapted to the CSOs activities.',
                  'A good regulatory practice is to have separate legislation on non-profit accounting and reporting forms for CSOs which are different from the reporting forms of businesses. In this way, the information collected by the authorities would give a better picture of what the CSO sector is actually doing without adding unnecessary burden on the sector. A good regulatory practice is to introduce lighter reporting requirements for small organizations that fall below a certain threshold. CSOs should not be subject to stricter requirements with regard to money laundering regulation than other similar subjects e.g. businesses or other types of non-profit organizations.',
                  'Regulations requiring specific financial reporting or specific measures regarding counter-terrorism requirements which are not proportionate to the size of the organizations and which treat CSOs differently from other providers would be in violation of this indicator.',
                ],
                [],
                [],
                [
                  'The regulations on financial reporting and accounting rules are not taking into consideration the specific characteristics of the CSOs and are not adopted to their activities. There is no separate legislation on non-profit accounting and reporting forms for CSOs which are different from the reporting forms of businesses. The reporting requirements are stricter for certain issues for CSOs in comparison to businesses. There is no difference in the reporting requirements, they are not proportionate to the size of the organizations.',
                  'The regulations on financial reporting and accounting rules consider the specific characteristics of the CSOs and are adopted to their activities to a certain extent, with major restrictions or issues which are not regulated. There is a separate legislation on non-profit accounting and reporting forms for CSOs which are different from the reporting forms of businesses. The reporting requirements are stricter or at least equal for certain subjects (e.g. money laundering) for CSOs in comparison to businesses. There are certain adjustments in the reporting requirements proportionate to the size of organizations.',
                  'The regulations on financial reporting and accounting rules consider the specific characteristics of the CSOs and are adopted to their activities to with minor restrictions or issues which are not regulated. There is a separate legislation on non-profit accounting and reporting forms for CSOs which are different from the reporting forms of businesses. The reporting requirements are almost or fully equal (e.g. money laundering, tax forms) for CSOs in comparison to businesses. There are certain adjustments in the reporting requirements proportionate to the size of organizations.',
                  'The regulations on financial reporting and accounting rules consider the specific characteristics of the CSOs and are adopted to their activities, in line with international standards. There is a separate legislation on non-profit accounting and reporting forms for CSOs which are different from the reporting forms of businesses. The reporting requirements are less strict for certain subjects (e.g. money laundering) for CSOs in comparison to businesses. The reporting requirements are adjusted proportionately to the size of organizations.',
                  'The regulations on financial reporting and accounting rules consider the specific characteristics of the CSOs and are adopted to their activities, and surpass the international standards. There is a separate legislation on non-profit accounting and reporting forms for CSOs which are different from the reporting forms of businesses. The reporting requirements are supportive towards CSOs (e.g. money laundering) in comparison to businesses. The reporting requirements are adjusted proportionately to the size of organizations, with particular reliefs and support for smaller and grass-root organizations.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_legislation_indicator_4',
                'Indicator 4',
                'Sanctions for breaching legal requirements should be based on applicable legislation and follow the principle of proportionality.',
                [
                  'All sanctions to which CSOs are subject should be prescribed by law and should be clear to avoid potential misinterpretation in practice. Sanctions for CSOs should be the same or lower as compared to the sanctions of companies for similar breaches. If there are material sanctions (fines, etc.) these should take into consideration the financial situation of the organization as well.',
                  'All sanctions should be proportionate to the breaches made. Fines to responsible individuals should be exceptions; they should only be applied in case of intentional and serious breach of law. Laws should not impose higher fines on responsible individuals than on the organization.',
                ],
                [],
                [],
                [
                  'The sanctions to which CSOs are subjected are not prescribed by law.',
                  'The sanctions to which CSOs are subjected are prescribed by law, with major issues which are not regulated or are restrictive. The law fails to respect the principle of proportionality. Thus, the material sanctions fail to consider the financial situation of the CSOs, and to be proportionate to the breaches that occurred. The law provides fines for individuals, and they are higher than those imposed on the organization.',
                  'The sanctions to which CSOs are subjected are prescribed by law, with minor issues which are not regulated or are restrictive. The principle of proportionality is partly respected, either by considering the financial situation of the CSOs, or/and the severity of the breach. The law provides fines for individuals with very specific guidance under what circumstances they are applied, and they are not higher than those imposed on the organization.',
                  'The sanctions to which CSOs are subjected are clearly prescribed by law in line with international standards. The principle of proportionality is respected, by both considering the financial situation of the CSOs, and the severity of the breach. The law provides fines for individuals only in cases with intentional and serious breach of law, and they are not higher than those imposed on the organization.',
                  'The sanctions to which CSOs are subjected are clearly prescribed by law and even surpass the international standards. The principle of proportionality is respected both by considering the financial situation of the CSOs when providing material sanction, and the severity of the breach. The law provides fines for individuals only in cases with intentional and serious breach of law, and they are not higher than those imposed on the organization.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_legislation_indicator_5',
                'Indicator 5',
                'Restrictions and the rules for dissolution and termination meet the standards of international law and are based on objective criteria which restrict arbitrary decision-making.',
                [
                  'The law should allow for CSO to choose to dissolve at any time by a resolution of the highest governing body. The law should prescribe precisely and narrowly the situations where organization have to dissolve and grounds for involuntary termination (e.g., in cases of violations of statute or law).',
                  'Termination should occur only as a last resort and appeal should be allowed. As a good practice termination should be decided in a court case. It should be applied only after previous sanctions have been exhausted and there is no other way to remedy the situation. An example of a disproportionate termination is a case in which an organization is terminated for failing to submit its annual report on time. Even if this is considered a serious violation, the organization should be given the chance e.g. to submit its report or pay a fine. Only if failing to do so should the sanction of termination be considered.',
                ],
                [],
                [],
                [
                  `The rules for dissolution and termination of the operations of CSOs are fully restrictive.
                    \n•	CSOs are not allowed to choose to dissolve at any time on their own terms (e.g. by the decision of their highest governing body).
                    \n•	The Law fails to prescribe situations where CSOs have to dissolve and grounds for involuntary termination.
                    \n•	The Law fails to ensure that such sanctions are provided only when other ways to remedy the situation are exhausted.
                    \n•	The Law does not provide possibility for an appeal.
                  `,
                  `The rules for dissolution and termination of the operations of CSOs are restrictive, but there is still space for CSOs to choose.
                    \n•	CSO can choose to dissolve at any time by a resolution of the highest governing body, but there are major issues and restrictions concerning this possibility.
                    \n•	The law vaguely prescribes the situations where organization have to dissolve and grounds for involuntary termination (e.g., in cases of violations of statute or law).
                    \n•	Even though termination should occur only as a last resort and appeal should be allowed, it is vaguely and inconsistently provided in the Law.
                    \n•	There are major issues which are unclear even though it is provided in Law that dissolution and termination is applied only when sanctions have been exhausted and there is no other way to remedy the situation.
                  `,
                  `The rules for dissolution and termination of the operations of CSOs partially restrictive.
                    \n•	CSO can choose to dissolve at any time by a resolution of the highest governing body, however there are minor issues that might restrict or provide difficulty for CSOs.
                    \n•	The law prescribes the situations where organizations have to dissolve and grounds for involuntary termination (e.g., in cases of violations of statute or law), with minor inconsistencies and lack of clarity.
                    \n•	Termination should occur only as a last resort and appeal should be allowed, however there are minor issues that might prove difficult for CSO.
                    \n•	Dissolution and termination is applied only when sanctions have been exhausted and there is no other way to remedy the situation, with minor inconsistencies.
                  `,
                  `The rules for dissolution and termination of the operations of CSOs are in line with standards.
                    \n•	CSO can choose to dissolve at any time by a resolution of the highest governing body.
                    \n•	The law prescribes precisely the situations where organization have to dissolve and grounds for involuntary termination (e.g., in cases of violations of statute or law).
                    \n•	Termination should occur only as a last resort and appeal should be allowed.
                    \n•	Dissolution and termination is applied only when sanctions have been exhausted and there is no other way to remedy the situation.
                  `,
                  `The rules for dissolution and termination of the operations of CSOs surpass international standards.
                    \n•	CSO can choose to dissolve at any time by a resolution of the highest governing body.
                    \n•	The law prescribes precisely the situations where organization have to dissolve and grounds for involuntary termination (e.g., in cases of violations of statute or law).
                    \n•	Termination should occur only as a last resort and appeal should be allowed.
                    \n•	Dissolution and termination is applied only when sanctions have been exhausted and there is no other way to remedy the situation.
                  `,
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_practice_indicator_1',
                'Indicator 1',
                'There are no cases of state interference in internal matters of associations, foundations and other types of non-profit entities.',
                [
                  'Practice shows that CSOs operate independently and there are no reports where CSOs have been subject to threats from the government in their work. There are no examples of government intrusion into the premises of private legal entities, or ‘surprise’ visits/inspections to the offices or board meetings. Requirements for CSOs to work only with specific government-controlled banks or use only government-provided services would not be in line with this indicator. There are no interferences especially against organizations which are critical of the government. There should be no examples of state attempts to harass such critical organizations.',
                ],
                [
                  'Number of CSOs which experienced threats by government officials;',
                  'Number of CSOs which experienced government intrusion into the internal work of the organization (e.g. during board meetings or events);',
                  'Number of CSOs which experienced unannounced inspections by state authorities;',
                  'Report cases of organizations critical of the government which were subject to government harassment (if any).',
                ],
                [
                  'Number of CSOs which were required to use government provided services;',
                  'Number of CSOs which were required to work with government-controlled banks.',
                ],
                [
                  'There are numerous cases of state interference in the internal matters of CSOs (systematic interference), heavily affecting their work. The cases might be all or some of these examples: CSOs being subject to threats, interference and harassment from the government especially to those critical to the government, government intrusion into the premises of private legal entities, or ‘surprise’ visits/inspections to the offices or board meetings, requirements to work only with specific government-controlled banks or use only government-provided services.',
                  'There are several cases of state interference in the internal matters of the CSOs, yet despite serious difficulties they manage to operate. The cases are either all or some of these examples: CSOs being subject to threats, interference and harassment from the government especially to those critical to the government, government intrusion into the premises of private legal entities, or ‘surprise’ visits/inspections to the offices or board meetings, requirements to work only with specific government-controlled banks or use only government-provided services.',
                  'There is one or very few cases of state interference in the internal matters of the CSOs which include restrictions and difficulties in the work of CSOs. The cases are either all or some of these examples: CSOs being subject to threats, interference and harassment from the government especially to those critical to the government, government intrusion into the premises of private legal entities, or ‘surprise’ visits/inspections to the offices or board meetings, requirements to work only with specific government-controlled banks or use only government-provided services.',
                  'There are no cases of state interference in the internal matters of the CSOs.',
                  'There are no cases of state interference in the internal matters of the CSOs. In addition, there are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_practice_indicator_2',
                'Indicator 2',
                'There are no practices of invasive oversight which impose burdensome reporting requirements.',
                [
                  'State interference/harassment could take the form of excessive audits of an organization with the purpose of putting administrative burden and sanctioning the organization. Other examples include targeted inspections and excessive control of employment regulations or other administrative measures.',
                ],
                [
                  'Number of CSOs which experienced invasive oversight (e.g. excessive audit, other burdensome administrative requirements, targeted inspections etc.).',
                  'Report prominent cases of invasive oversight and reporting.',
                ],
                [
                  'Number of CSOs that experienced excessive control over implementation of employment regulation or other administrative measures.',
                ],
                [
                  'There are numerous cases of CSOs experiencing invasive oversight which impose burdensome reporting requirements (systematic attack). The cases are either all or some of these examples: excessive audit with the purpose to put administrative burden and sanctions, invasive oversight and reporting, excessive control over implementation of employment regulation or other administrative measures.',
                  'There are several cases of CSOs experiencing invasive oversight which impose burdensome reporting requirements yet despite serious difficulties they manage to operate. The cases are either all or some of these examples: excessive audit with the purpose to put administrative burden and sanctions, invasive oversight and reporting, excessive control over implementation of employment regulation or other administrative measures.',
                  'There are one or very few cases of CSOs experiencing invasive oversight which imposes burdensome reporting requirements which include minor restrictions and difficulties in the work of CSOs. The cases are either all or some of these examples: excessive audit with the purpose to put administrative burden and sanctions, invasive oversight and reporting, excessive control over implementation of employment regulation or other administrative measures.',
                  'There are no cases of CSOs experiencing invasive oversight which impose burdensome reporting requirements.',
                  'There are no cases of CSOs experiencing invasive oversight which impose burdensome reporting requirements and there are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_2_practice_indicator_3',
                'Indicator 3',
                'Sanctions are applied in rare/extreme cases; they are proportional and are subject to a judicial review.',
                [
                  'Sanctioning an organization should have the aim of remedying a breach and not hindering the operation of the organization.',
                  'When imposing the sanction, attention should be paid to the specific character of the CSO and the type of the violation. The practice should show that when there are differences between minimum and maximum penalties the fines are applied with consideration of the size of the organization, the gravity of the breach and in general to show that there is a tendency to apply lower rather than higher fines.',
                  'Decisions to restrict the rights or terminate organizations in practice take place in a judicial process in an apolitical manner, decisions are explained and organizations can appeal against them.',
                ],
                [
                  'Report frequency of cases in which CSOs are sanctioned for noncompliance;',
                  'Report nature of sanctions (proportion of lower vs. higher fines for CSOs; number of terminations) by institutions;',
                  'Number of CSOs that consider the sanction is proportional vs. those that consider that the sanction is excessive for the breach.',
                  'Number of CSOs that use the opportunity to appeal in court/ complain (as reported in survey and by institutions).',
                ],
                [],
                [
                  'There are numerous cases of applying sanctions with the aim to hinder the operation of the organization, which are not proportional and subject to judicial review (systematic attack). The cases are either all or some of these examples: CSOs are sanctioned for noncompliance, hindering their operations, there is no differences between minimum and maximum penalties the fines are applied with consideration of the size of the organization, tendency to apply higher than lower fines, the decisions to restrict the rights or terminate organizations do not take place in a judicial process in an apolitical manner, the decisions are not explained, nor CSOs can appeal against them.',
                  'There are several cases of applying sanctions with the aim to impose difficulties in the operation of the organization, which are not proportional and subject to judicial review, yet CSOs can still find space to operate. The cases are either all or some of these examples: CSOs are sanctioned for noncompliance, hindering their operations, there is no differences between minimum and maximum penalties the fines are applied with consideration of the size of the organization, tendency to apply higher than lower fines, the decisions to restrict the rights or terminate organizations do not take place in a judicial process in an apolitical manner, the decisions are not explained, nor CSOs can appeal against them.',
                  'There are one or very few cases of applying sanctions over the operation of CSOs, which are not proportional and subject to judicial review, causing minor difficulties. The cases are either all or some of these examples: CSOs are sanctioned for noncompliance, hindering their operations, there is no differences between minimum and maximum penalties the fines are applied with consideration of the size of the organization, tendency to apply higher than lower fines, the decisions to restrict the rights or terminate organizations do not take place in a judicial process in an apolitical manner, the decisions are not explained, nor CSOs can appeal against them.',
                  'There are no cases of applying sanctions over the operation of CSOs, which are not proportional and subject to judicial review.',
                  'There are no cases of applying sanctions over the operation of CSOs, which are not proportional and subject to judicial review and even cases of best practices which surpass the standards and principles enshrined in MM standards when it comes to sanctioning.',
                ]
              ),
            ],
          },
          {
            code: 'area_1_sub_area_1_1_standard_3',
            codeName: 'STANDARD 1.1.3',
            originalName: 'Securing Financial Resources',
            name: 'CSOs can freely seek and secure financial resources from various domestic and foreign sources to support their activities',
            legislation: [
              //indicator 1
              _generateIndicator(
                'area_1_sub_area_1_1_standard_3_legislation_indicator_1',
                'Indicator 1',
                'Legislation allows CSOs to engage in economic activities.',
                [
                  'Economic activities” means the active sale of goods or services, referred to as “trade or business” activities; it entails sale of goods and services that are pursued with frequency or continuity. This can include: fees for services, sales, rents, investments and business ventures. Legislation should allow CSOs to engage both directly (as a CSO) and indirectly (by registering or owning shares of a commercial company) in economic activity. Internationally this is considered an important right as it allows CSOs to generate income through developing their own services, to improve reach to their communities, to adjust services to meet real needs, and to have a stable income source independent from the state.',
                  'There could be some limitation to engaging in a direct economic activity. For example: the economic activity may have to be related to the mission of the organization (e.g. an organization for people with disabilities could provide transportation services to such people, or association for blind people can sell walking canes); the economic activity may have to be additional to the non-profit activity; or rules may require that all income should be used for the purposes of the organization.  There should be a restriction on distributing net revenues to private parties who may be in a position to control the organization for personal gain, such as founders, members, officers, directors, agents, or employees (prohibition on the distribution of income). State authorities should provide guidance on engagement in economic activities to facilitate the process.',
                ],
                [],
                [],
                [
                  'The legislation does not allow for CSOs to engage in economic activities, and has explicit provisions that prohibit generation of funds from economic activity.',
                  'The legislation allows for CSOs to generate income through economic activity, however it does so by imposing major and severe limitations. Such limitations might include: there is a very difficult process of licensing or/and registering CSOs that engage in economic activity, the law imposes a very high tax rates on the income from economic activity, CSOs cannot engage in economic activity indirectly by registering or owning shares of a commercial company, etc.).',
                  'The legislation allows for CSOs to generate income through economic activity, however it does so with minor inconsistencies. Such inconsistencies might include: unclear rules for reporting, unclear tax rules, etc.).',
                  'The legislation allows for CSOs to generate income through economic activity and is in line with international standards. The limitations imposed are also in line with international standards and they might  include: the economic activity may have to be related to the mission of the organization (e.g. an organization for people with disabilities could provide transportation services to such people, or association for blind people can sell walking canes); the economic activity may have to be additional to the non-profit activity; or rules may require that all income should be used for the purposes of the organization. Then, there should be a restriction on distributing net revenues to private parties who may be in a position to control the organization for personal gain, such as founders, members, officers, directors, agents, or employees (prohibition on the distribution of income).',
                  'The legislation allows for CSOs to generate income through economic activity and there are provisions that surpass the international standards. The limitations imposed are also in line with international standards and they might  include: the economic activity may have to be related to the mission of the organization (e.g. an organization for people with disabilities could provide transportation services to such people, or association for blind people can sell walking canes); the economic activity may have to be additional to the non-profit activity; or rules may require that all income should be used for the purposes of the organization. Then, there should be a restriction on distributing net revenues to private parties who may be in a position to control the organization for personal gain, such as founders, members, officers, directors, agents, or employees (prohibition on the distribution of income). Furthermore, the state authorities should be obliged to prepare official guidance on engagement in economic activities to facilitate the process.',
                ]
              ),
              //indicator 2
              _generateIndicator(
                'area_1_sub_area_1_1_standard_3_legislation_indicator_2',
                'Indicator 2',
                'CSOs are allowed to receive foreign funding.',
                [
                  'Legislation should not limit the possibility for CSOs to receive funding from foreign public or private sources. The treatment of such funding should be similar to the treatment of funding received from local sources. Any legal requirements for registration of foreign grants (except funding from bilateral donors which falls under the EU regulations), their preliminary approval from the government, requirement to channel foreign funding through state controlled body or bank, are inconsistent with this indicator.',
                ],
                [],
                [],
                [
                  'The legislation does not allow or imposes severe restrictions over the possibility of CSOs to receive funding from foreign public or private sources. The CSOs are required to register as foreign agents and receive preliminary approval from the government, and channel the foreign funding through state controlled body or bank.',
                  'The legislation imposes major restrictions over the possibility of CSOs to receive funding from foreign public or private sources. The legislation still provides some limited space for CSOs to operate. It might be that the legislation imposes some of the following requirements: the CSOs are required to register as foreign agents, requirement to receive preliminary approval from the government, and the need to channel the foreign funding through state controlled body or bank.',
                  'The legislation imposes minor restrictions/burdens over the possibility of CSOs to receive funding from foreign public or private sources. It might be that the legislation imposes some of this requirements: burdensome procedure for registration of projects, etc.',
                  'The legislation allows CSOs the possibility to receive funding from foreign public or private sources in line with international standards. The treatment of the foreign funding is similar to the treatment of funding received from local sources.',
                  'The legislation allows CSOs the possibility to receive funding from foreign public or private sources surpasses the international standards. The treatment of the foreign funding is similar to the treatment of funding received from local sources. There are cases of legislation surpassing standards for e.g. the legislation provides benefits and exemptions for foreign donors, etc.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_3_legislation_indicator_3',
                'Indicator 3',
                'CSO are allowed to receive funding from individuals, corporations and other sources.',
                [
                  'There is no legal limitation that would prohibit the receipt of funds from any private or commercial person, the state, local authorities, foundations or other legal entities, agencies, etc. CSOs should be able to raise funds from individuals and corporations, and take part in public procurement or grant or service tenders by the state or donors.',
                ],
                [],
                [],
                [
                  'The legislation does not allow or provides severe restrictions for CSOs to receive funding from individuals, corporations and other sources. Namely, CSOs are prevented from raising funds from individuals and corporations, and to take part in public procurement or grant or service tenders by the state or donors.',
                  'The legislation allows for CSOs to receive funding from individuals, corporations and other sources, however with major restrictions and makes it difficult for CSOs to engage in raising funds. CSOs might be allowed under certain circumstances to take part in public procurement or grant or service tenders by the state or donors.',
                  'The legislation allows for CSOs to receive funding from individuals, corporations and other sources, however with minor restrictions and/or inconsistencies. CSOs are allowed to raise funds from individuals and corporations, and can take part in public procurement or grant or service tenders by the state or donors. However due to certain provisions, there are minor issues which still need to be improved and/or regulated (e.g. different reporting rules depending on the source; unequal and more difficult rules imposed on CSOs in public procurement, etc.)',
                  'The legislation allows for CSOs to receive funding from individuals, corporations and other sources, in line with international standards. CSOs are allowed to raise funds from individuals and corporations, and can take part in public procurement or grant or service tenders by the state or donors.',
                  'The legislation allows for CSOs to receive funding from individuals, corporations and other sources and even surpasses international standards. CSOs are allowed and encouraged (with incentives) to raise funds from individuals and corporations, and can take part in public procurement or grant or service tenders by the state or donors.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_1_sub_area_1_1_standard_3_practice_indicator_1',
                'Indicator 1',
                'Legislation on CSOs engaging in economic activities is implemented and is not burdensome for CSOs.',
                [
                  'CSOs engage in economic activities. They can set up companies for that purpose or directly engage. The state authorities do not impose implementing regulations that will limit this right.',
                  'When engaging in economic activity, CSOs are only subject to any licensing/registration or other specialized regime if it is required for everybody that engages in the respective field of work.',
                ],
                [
                  `\nNumber of CSOs that report income from economic activities (follow % of income reported if indicative of a trend)`,
                  `\nNumber of organizations that experienced administrative obstacles when engaging in economic activities in practice.`,
                ],
                [
                  `\nNumber of CSOs that have economic activities via a separate registered legal entity vs. number of organizations which engage directly in economic activities.`,
                ],
                [
                  'CSOs face severe restrictions and are unable to engage in economic activities. In case the engagement of CSOs in economic activities is regulated by legislation, it is extremely burdensome and hampers CSO work.',
                  'CSOs face certain restrictions and are unable to engage in economic activities. The implementation of the legislation has substantial challenges, and provides difficulties in the work of CSOs. CSOs are subject to licensing/registration even though it is not required for everybody that engages in the respective work field.',
                  'CSOs are engaged in economic activities, and face minor restrictions. The implementation of the legislation has certain challenges. There are numerous CSOs that set up companies for the purpose of generating funds via economic activities, however still face minor challenges.',
                  'CSOs are engaged in economic activities, and the legislation is implemented without any violations reported. There are numerous CSOs that set up companies for the purpose of generating funds via economic activities.',
                  'CSOs are engaged in economic activities, and the legislation is implemented without any violations reported. There are numerous CSOs that set up companies for the purpose of generating funds via economic activities. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_3_practice_indicator_2',
                'Indicator 2',
                'There are no restrictions (e.g. administrative or financial burden, preapprovals, or channeling such funds via specific bodies) on CSOs to receive foreign funding.',
                [
                  'CSOs receive foreign funds and can use them for any purpose for which the grant is given. In the case of receipt of foreign funding, there are no specific administrative procedures that obstruct organizations from receiving foreign funds. There are no special taxes for foreign funds received. If VAT exemption is provided, the procedure for obtaining it is simple, easy-to-implement and not too burdensome.',
                ],
                [
                  `\nNumber of CSOs that report income from foreign sources and number of organizations which dominantly finance themselves through foreign funding i.e. more than 50% of the overall budget.`,
                  `\nNumber of CSOs that faced any restrictions when receiving funding from foreign sources (special taxes, difficult procedure for obtaining VAT; administrative requirements for accessing funds).`,
                  `\nNumber of CSOs which were prevented to receive foreign funding by the state and the reasons for this. Report contentious cases, if any. `,
                ],
                [],
                [
                  'All or targeted groups of CSOs are restricted (prevented) from receiving foreign funding.',
                  'All or targeted groups of CSOs face substantial challenges and obstacles when receiving foreign funding (e.g. administrative or financial burden, preapprovals, or channeling such funds via specific bodies).',
                  'CSOs receive foreign funding with minor difficulties (e.g. administrative or financial burden, preapprovals, or channeling such funds via specific bodies).',
                  'All CSOs that have solicited foreign funding are able to receive it without any restrictions or difficulties',
                  'All CSOs that have solicited foreign funding are able to receive it without any restrictions or difficulties, and there are cases of best practices which surpass MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_1_standard_3_practice_indicator_3',
                'Indicator 3',
                'Receipt of funding from individuals, corporations and other sources is easy, effective and without any unnecessary cost or administrative burden.',
                [
                  'CSOs can freely receive funding from different private sources, and do not face complicated procedures to access the funding.',
                ],
                [
                  `\nNumber of organizations reporting income from private sources and number of organizations which dominantly finance themselves through private donations and gifts i.e. more than 40% of the overall budget; `,
                  `\nNumber of CSOs that report complicated administrative procedures to access funding from private sources.`,
                ],
                [
                  `\nReport examples (cases) of barriers that individuals and companies face when donating to organizations in practice.`,
                ],
                [
                  'CSOs report severe restrictions when receiving funding from individuals, corporations and other sources. The procedures are reported to be difficult, ineffective, and with high costs and serious administrative burden.',
                  'CSOs face certain restrictions when receiving funding from individuals, corporations and other sources. The procedures are reported to be difficult to a certain extent, and with medium costs and administrative burden.',
                  'CSOs can freely receive funding from individuals, corporations and other sources, with minor difficulties. The procedures are reported to be easy to a certain extent, and with certain costs and minor administrative burden.',
                  'CSOs freely receive funding from individuals, corporations and other sources, in line with MM standards. The procedures are reported to be easy, effective, and without unnecessary costs and no administrative burden.',
                  'CSOs freely receive funding from individuals, corporations and other sources, in line with MM standards. The procedures are reported to be easy, effective, and without unnecessary costs and no administrative burden. There are examples of best practices which surpass the MM standards.',
                ]
              ),
            ],
          },
        ],
      },
      {
        code: 'area_1_sub_area_1_2',
        codeName: 'Sub-Area 1.2',
        name: ' RELATED FREEDOMS',
        principle:
          'Freedoms of assembly and expression are guaranteed to everybody',
        standards: [
          {
            code: 'area_1_sub_area_1_2_standard_1',
            codeName: 'STANDARD 1.2.1',
            originalName: 'Freedom of Peaceful Assembly',
            name: 'CSO representatives, individually or through their organizations enjoy freedom of peaceful assembly',
            legislation: [
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_legislation_indicator_1',
                'Indicator 1',
                'The legal framework is based on international standards and provides the right for freedom of assembly for all without any discrimination.',
                [
                  'Freedom of peaceful assembly is a fundamental human right, enshrined in the International Covenant on Civil and Political Rights, the ECHR, the Charter of Fundamental Rights of the European Union and other international and regional treaties, and is inextricably intertwined with the freedom of association. The State has a positive obligation to facilitate and protect peaceful assembly freedom of assembly insofar as possible should be enjoyed without restrictions, guaranteed by law and should not be preempted by administrative procedures or undue regulation. The law should guarantee freedom of assembly to all, individuals and legal entities, stateless persons, refugees, foreign nationals, asylum seekers, migrants and others.',
                ],
                [],
                [],
                [
                  'The legal framework is fully restrictive and against MM standards, since it does not guarantee the right to freedom of assembly for all. The legal framework does not provide guarantees for exercising the right to freedom of assembly.',
                  'The legal framework is restrictive and not in line with MM standards. The legal framework provides certain guarantees for exercising the right to freedom of assembly.',
                  'The legal framework is partially meets and satisfies MM standards, and there are still minor legal restrictions or issues which are not regulated. The legal framework provides guarantees for exercising the right to freedom of assembly, but has certain limitations in terms of administrative procedures or undue regulation.',
                  'The legal framework guarantees the right to freedom of assembly to all. The legal framework is in line with MM standards. The legal framework provides guarantees for exercising the right to freedom of assembly in line with International Covenant on Civil and Political Rights, the ECHR, the Charter of Fundamental Rights of the European Union and other international and regional treaties, and is inextricably intertwined with the freedom of association',
                  'The legal framework guarantees the right to freedom of assembly to all. The legal framework is fully in line with MM standards. The legal framework provides guarantees for exercising the right to freedom of assembly and is fully in line with International Covenant on Civil and Political Rights, the ECHR, the Charter of Fundamental Rights of the European Union and other international and regional treaties, and is inextricably intertwined with the freedom of association.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_legislation_indicator_2',
                'Indicator 2',
                'The laws recognize and do not restrict spontaneous, simultaneous and counter-assemblies.',
                [
                  'The law should allow for peaceful assemblies to be held at the same time and in the same place (simultaneously) or for counter-assemblies (those of opposing groups) to take place. In case several groups plan to hold assemblies in the same place and the same time, they should be entitled to do so if physical circumstances permit. In case of counter-assemblies, the law has to recognize the state’s responsibility to undertake measures to protect the participants in the peaceful assembly from individuals or groups of individuals, including agents provocateurs and counter demonstrators, who aim at disrupting or dispersing such assemblies.',
                ],
                [],
                [],
                [
                  'The legal framework restricts spontaneous, simultaneous and counter assemblies',
                  'The legal framework recognizes spontaneous assemblies, but restricts simultaneous and/or counter assemblies.',
                  'The legal framework recognizes and does not restrict spontaneous, simultaneous and counter assemblies. However, the legal framework is not clear regarding state’s responsibility to protect participants in the assembly and/or has many limitations in terms of where a simultaneous and counter assemblies could take place.',
                  'The legal framework recognizes and does not restrict spontaneous, simultaneous and counter-assemblies, while also state authorities are responsible to undertake measures to protect participants in the peaceful assembly from individuals or groups of individuals, including agents provocateurs and counter demonstrators who aim at disrupting or dispersing assemblies.',
                  'The legal framework recognizes and does not restrict spontaneous, simultaneous and counter-assemblies, while also state authorities are responsible to undertake measures to protect participants in the peaceful assembly from individuals or groups of individuals, including agents provocateurs and counter demonstrators who aim at disrupting or dispersing assemblies. The legal framework does not provide any restrictions regarding time and localities where spontaneous, simultaneous and counter assemblies should take place.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_legislation_indicator_3',
                'Indicator 3',
                ' The exercise of the right is not subject to prior authorization by the authorities, but at the most to a prior notification procedure, which is not burdensome.',
                [
                  'The law should contain a presumption in favor of holding assemblies. Those seeking to assemble should not be required to obtain permission to do so. If such requirement exists it should be reduced to notification (information that it will happen), rather than authorization (asking for permission to do it). There should be no limitations on advertisement of the event before such permission is acquired. Requirements for notification may be justified by the state’s duty to protect public order, public safety, and the rights and freedoms of others.',
                ],
                [],
                [],
                [
                  'The legal framework is fully restrictive and against MM standards. The legal framework requires for individuals and/or organization to ask for prior authorization to the state authorities for exercising the right to freedom of assembly.',
                  'The legal framework is restrictive and not in line with MM standards. The legal framework requires for individuals and/or organization to ask for advance notification (i.e. more than 48 hours) to the state authorities for exercising the right to freedom of assembly for exercising the right to freedom of assembly.',
                  'The legal framework is partially meets and satisfies MM standards, and there are still minor legal restrictions or issues which are not regulated. The legal framework provides guarantees for exercising the right to freedom of assembly, but has certain limitations in terms of notification procedure.',
                  'The legal framework guarantees the right to freedom of assembly to all without burdensome administrative procedures. No authorization by the state authorities is required, while notification procedure prior to assemble is not burdensome. The legal framework is in line with MM standard and International Covenant on Civil and Political Rights, the ECHR, the Charter of Fundamental Rights of the European Union and other international and regional treaties, and is inextricably intertwined with the freedom of association.',
                  'The legal framework guarantees the right to freedom of assembly to all without burdensome administrative procedures. No authorization by the state authorities is required, while notification procedure prior to assemble is not burdensome. The legal framework is in line with MM standard and International Covenant on Civil and Political Rights, the ECHR, the Charter of Fundamental Rights of the European Union and other international and regional treaties, and is inextricably intertwined with the freedom of association.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_legislation_indicator_4',
                'Indicator 4',
                'Any restriction of the right based on law and prescribed by regulatory authority can be appealed by organizers.',
                [
                  'The law should guarantee the right to appeal against decisions to limit the assembly.',
                ],
                [],
                [],
                [
                  'The law does not guarantee the right to appeal against decisions to limit an assembly.',
                  'The legal framework guarantees the right to appeal against decisions to limit an assembly, but has certain burdensome limitations in terms of appeal procedure.',
                  'The legal framework guarantees the right to appeal against decisions to limit an assembly, but it has certain limitations regarding response to an appeal (more than 24 hours).',
                  'The law guarantees the right to appeal against decisions to limit an assembly. Where there is a failure to respond promptly, the legal framework presumes that the organizers of the assembly may proceed according to terms of notice.',
                  'The law guarantees the right to an accelerated appeal procedure against decisions to limit an assembly. Where there is a failure to respond promptly, the legal framework presumes that the organizers of the assembly may proceed according to terms of notice.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_practice_indicator_1',
                'Indicator 1',
                'There are no cases of encroachment of the freedom of assembly, and any group of people can assemble at desired place and time, in line with the legal provisions.',
                [
                  'There are cases of freedom of assembly practiced by CSOs (individually or through their organizations) without prior authorization. When notification is required it is submitted in a short period of time and does not limit the possibility to organize the assembly. There are no complaints about the restriction on assembly, groups are not required to ask for authorization, nor does the police require one when assemblies take place. Any restriction follows the legally prescribed cases for the limitation. Assemblies are organized in any area the groups want. A violation of this right will be situations where the state deliberately books a place in order to prevent assemblies to be organized.',
                ],
                [
                  `\nReport official police statistics (ratio) on held vs. forbidden and restricted assemblies;`,
                  `\nNumber of CSO representatives that report restrictions on holding and participating in public gatherings.`,
                ],
                [
                  `\nReport prominent cases of encroachment of the freedom of assembly as reported in media, by Ombudsman and in other CSOs reports.`,
                ],
                [
                  'There are numerous unjustified complaints from individuals, groups and organizations that they have been prevented from exercising the right to freedom of assembly.',
                  'There are few unjustified complaints from individuals, groups and organizations that they have been prevented from exercising the right to freedom of assembly.',
                  'There are very few unjustified complaints from individuals, groups and organizations that they have been prevented from exercising the right to freedom of assembly.',
                  'There are no unjustified complaints from individuals, groups, organizations that they are prevented from expressing their right to freedom of assembly.',
                  'There are no unjustified complaints from individuals, groups, organizations that they are prevented from expressing their right to freedom of assembly. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_practice_indicator_2',
                'Indicator 2',
                'Restrictions are justified with explanations of the reason for each restriction, which is  promptly communicated in writing to the organizer to guarantee the possibility of appeal.',
                [
                  'Regulatory authorities provide prompt, detailed and objective written explanation for any limitation to assembly. Where there is arbitrary denial or a failure of the regulatory authorities to respond promptly, then this is considered as an excessive restriction on freedom of assembly. ',
                  'Where there is a failure to respond promptly, then the law should presume that the organizers of the assembly may proceed according to terms of notice.  Where the authorities restrict this right, there is a possibility for appeal.',
                ],
                [
                  `\nNumber of cases of bans/restrictions for which authorities provided written explanation vs. number of cases of bans/ restrictions for which authorities did not provide written explanation to the organizers;`,
                  `\nNumber of formal appeals submitted by organizers to the police for banned or restricted assemblies.`,
                ],
                [
                  `\nNumber of CSOs reporting that the police did not provide justification for the restriction;`,
                  `\nNumber of CSOs reporting that the police did not communicate the restriction in a timely manner;`,
                  `\nNumber of cases when the organizations were not offered the possibility to appeal.`,
                ],
                [
                  'Restrictions to assembly are not justified by responsible state authorities and there is no a possibility for appeal.',
                  'Restrictions are justified, but state authorities do not provide detailed and objective written explanation for any limitation to assembly. However, there are many cases of arbitrary denial or a failure of the regulatory authorities to respond promptly.',
                  'Restrictions are justified with explanations of the reason for each restriction, which is promptly communicated in writing to the organizer to guarantee the possibility of appeal. However, where there is a failure to respond promptly, organizers of the assembly are not allowed to proceed according to terms of notice. There cases where such an assemblies were sanctioned/not allowed to take place.',
                  'Restrictions are justified with explanations of the reason for each restriction, which is promptly communicated in writing to the organizer to guarantee the possibility of appeal. Where there is a failure to respond promptly, organizers of the assembly proceed according to terms of notice and are not sanctioned for doing so.',
                  'Restrictions are justified with explanations of the reason for each restriction, which is promptly communicated in writing to the organizer to guarantee the possibility of appeal. Also, there are cases where state authorities have utilized accelerated procedures to respond to an appeal. Where there is a failure to respond promptly, organizers of the assembly proceed according to terms of notice and are not sanctioned for doing so.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_practice_indicator_3',
                'Indicator 3',
                ' Simultaneous, spontaneous and counter-assemblies can take place, and the state facilitates and protects groups to exercise their right against people who aim to prevent or disrupt the assembly.',
                [
                  'The state does not interfere in spontaneous assemblies by raising the argument for a need to notify the authorities. Simultaneous and counter-assemblies can take place and the law- enforcement authorities exercise necessary control to protect peaceful protestors, accepting that the assembly should take place within sound and sight of the target group. Assemblies are not prohibited at large because there is possibility for counter- assembly, rather protective methods are used to separate the groups, and minimize potential violence and disruption of public order.',
                ],
                [
                  `\nPresence of CSOs that report participation in different types of assembly (simultaneous, spontaneous and counter assemblies);`,
                  `\nNumber of CSOs which report lack of police protection during simultaneous and counter-assemblies;`,
                  `\nNumber of cases when assemblies were banned because of the possibility of counter-protests.`,
                ],
                [
                  `\nReport prominent cases of police failure to protect and assure peaceful assembly as reported in media, by Ombudsman and other CSOs reports.`,
                ],
                [
                  'State authorities are very restrictive on spontaneous, simultaneous and counter assemblies. There are many cases where spontaneous assemblies were restricted by raising the argument for a need to notify the authorities or any other unreasonable argument; and/or simultaneous and counter assemblies were not allowed to take place with no reasonable explanation.',
                  'State authorities are somehow restrictive on spontaneous, simultaneous and counter assemblies. While spontaneous, simultaneous and counter assemblies take place, there are cases where state authorities interfere by restricting spontaneous, and/or simultaneous and counter assemblies to take place without any reasonable explanation.',
                  'Simultaneous, spontaneous and counter-assemblies can take place, but there are cases where state fails facilitate and protect groups to exercise their right against people who aim to prevent or disrupt the assembly.',
                  'Simultaneous, spontaneous and counter-assemblies can take place, and the state facilitates and protects groups to exercise their right against people who aim to prevent or disrupt the assembly. The state authorities exercise necessary control to protect peaceful protestors, accepting that the assembly should take place within sound and sight of the target group.  In general, assemblies are not prohibited, except in cases as prescribed by the law and with detailed and reasonable explanation.',
                  'Simultaneous, spontaneous and counter-assemblies can take place, and the state facilitates and protects groups to exercise their right against people who aim to prevent or disrupt the assembly. The state authorities exercise necessary control to protect peaceful protestors, accepting that the assembly should take place within sound and sight of the target group.  In general, assemblies are not prohibited, except in cases as prescribed by the law and with detailed and reasonable explanation. There are cases where more than two assemblies have taken place at the same time, including counter assemblies, and state authorities have provided necessary safety conditions for them to happen.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_practice_indicator_4',
                'Indicator 4',
                'There are cases of freedom of assembly practiced by CSOs (individually or through their organizations) without prior authorization; when notification is required it is submitted in a short period of time and does not limit the possibility to organize the assembly.',
                [
                  'CSOs or individuals organize assemblies and these are not dispersed because there are no prior authorizations issued by the state authorities.',
                  'In case of spontaneous assemblies prior notification is not used as reason to disperse peaceful assemblies.',
                ],
                [
                  `\nNumber of CSOs reporting that spontaneous assembly was dispersed by police due to lack of authorization/ notification.`,
                  `\nNumber of assemblies which were dispersed in the past 12 months and the reasons for this.`,
                ],
                [
                  `\nReport prominent cases of dispersion of spontaneous protests by police as reported in media, by Ombudsman and other CSOs reports.`,
                ],
                [
                  'There are numerous and continuous cases where individuals and/or CSOs had to ask for authorization prior to assemble. There are many cases where assemblies were dispersed by the state authorities, including spontaneous assemblies because of not having prior authorization.',
                  'There are few and continuous cases where individuals and/or CSOs had to ask for authorization prior to assemble. There are many cases where assemblies were dispersed by the state authorities because of not having prior authorization.',
                  'There are cases where individuals and CSOs exercised the right to freedom of assembly without prior authorization by the state authorities. However, prior notification is required. There are few cases where individuals and CSOs failed to organize spontaneous assemblies due to the burdensome notification procedures.',
                  'There are many cases of freedom of assembly practiced by individuals and CSOs without prior authorization. When notification is required it is submitted in a short period of time and does not limit the possibility to organize the assembly. In addition, there are also cases of spontaneous assemblies.',
                  'There are many cases of freedom of assembly practiced by individuals and CSOs without prior authorization. When notification is required it is submitted in a short period of time and does not limit the possibility to organize the assembly. In addition, there are cases of spontaneous assemblies. Also there are no complaints for the notification procedures.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_practice_indicator_5',
                'Indicator 5',
                'No excessive use of force is exercised by law enforcement bodies, including preemptive detentions of organizers and participants.',
                [
                  'The participants of peaceful assemblies are not subject to detention. There are no cases where organizers of assemblies or known protestors are detained in order to prevent assembly from happening. Law enforcement officials do not use force against participants nor use such methods to disperse peaceful protests. There are no complaints of disproportionate use of force to the threat imposed.',
                ],
                [
                  `\nNumber of detentions by the police in the past 12 months and formal reasons for this;`,
                  `\nNumber of reported cases of police brutality (complaints of disproportionate use of force) in the past 12 months and number of cases in which the internal review found evidence of disproportionate use of force.`,
                  `\nNumber of CSOs reporting excessive use of force on participants of public assemblies;`,
                  `\nNumber of CSOs reporting detentions of participants of public assemblies.`,
                ],
                [
                  `\nReport prominent cases of excessive use of force and detentions as reported in relevant secondary sources.`,
                ],
                [
                  'There are many cases where an excessive use of force is exercised by state authorities, including preemptive detentions of organizers and participants. There are cases where organizers of assemblies or known protestors are detained in order to prevent assembly from happening.',
                  'There are cases where an excessive use of force is exercised by state authorities. State authorities use disproportionate force against participants as a method to disperse an assembly or protest.',
                  'There are no cases where organizers of assemblies or known protestors are detained in order to prevent assembly from happening. However, there are few cases where state authorities use disproportionate force against participants as a method to disperse an assembly or protest.',
                  'No excessive use of force is exercised by law enforcement bodies, including preemptive detentions of organizers and participants. There are no cases where organizers of assemblies or known protestors are detained in order to prevent assembly from happening. Law enforcement officials do not use force against participants nor use such methods to disperse peaceful protests. There are no complaints of disproportionate use of force to the threat imposed.',
                  'No excessive use of force is exercised by law enforcement bodies, including preemptive detentions of organizers and participants. There are no cases where organizers of assemblies or known protestors are detained in order to prevent assembly from happening. Law enforcement officials do not use force against participants nor use such methods to disperse peaceful protests. There are no complaints of disproportionate use of force to the threat imposed. Responsible law enforcement officials are regularly trained and well aware of the rights of participants in an assembly.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_1_practice_indicator_6',
                'Indicator 6',
                'Media should have as much access to the assembly as possible.',
                [
                  'As the main purpose of any assembly is to convey a message to the target group, media should be guaranteed access to the assembly.',
                ],
                [
                  `\nNumber of reported cases of restricted media access to public assemblies.`,
                ],
                [
                  `\nProminent cases of limited media access to public assemblies.`,
                ],
                [
                  'Media are not allowed access to assemblies. There are many cases where media representatives were arrested for participating in an assembly, or not allowed to do so; or/and state authorities imply fines or additional inspecting measures for media who participate and broadcast assemblies.',
                  'Media are allowed a controlled access to assemblies. Prior authorization by state authorities is required. There are cases where media representatives were not allowed access to an assembly or were arrested due to not having state authorization to participate in an assembly.',
                  'Media are allowed partial access to assemblies. No prior authorization by state authorities is required. However, there are cases when in escalating situations media were not allowed to film or follow the assembly.',
                  'Media have open and full access to assemblies. There are no cases where media representatives were prevented to access an assembly.',
                  'Media have open and full access to the assembly. There are no cases where media representatives were prevented to access an assembly. There are cases where state authorities provide targeted safety measures for media representatives.',
                ]
              ),
            ],
          },
          {
            code: 'area_1_sub_area_1_2_standard_2',
            codeName: 'STANDARD 1.2.2',
            originalName: 'Freedom of Expression',
            name: 'CSO representatives, individually or through their organizations enjoy freedom of expression',
            legislation: [
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_legislation_indicator_1',
                'Indicator 1',
                'The legal framework provides freedom of expression for all.',
                [
                  'Freedom of expression is a fundamental freedom which is regulated in several international documents including the ECHR and the EU Charter on Fundamental Rights. This is an important freedom for individuals and CSOs. It is particularly relevant for CSOs which are engaged in human rights and democracy promotion as it allows them not only to speak freely but also to engage in advocacy. The legal framework should guarantee this right to all. There are no legislative initiatives to limit the right to receive information and the freedom of expression, including on the internet.',
                ],
                [],
                [],
                [
                  'The legal framework does not guarantee the right to freedom of expression for all. This is restrictive to the operation of CSOs and their representatives and seriously obstructs and hampers their work. There are numerous legislative initiatives that aim to limit the right to receive information and freedom of expression (for. e.g. for human rights CSOs as they cannot speak freely nor engage in advocacy), including on the internet.',
                  'The legal framework contains major restrictions, however leaves some space for CSOs operation. There are few cases of legislative initiatives that aim to limit the right to receive information and freedom of expression (for. e.g. for human rights CSOs as they cannot speak freely nor engage in advocacy), including on the internet.',
                  'The legal framework contains minor legal restrictions or issues which are not regulated. There are rare cases of legislative initiatives that aim to limit the right to receive information and freedom of expression (for. e.g. for human rights CSOs as they cannot speak freely nor engage in advocacy), including on the internet.',
                  'The legal framework guarantees the right to freedom of expression to all. The legal framework is in line with international standards. CSOs which are engaged in human rights and democracy promotion are allowed not only to speak freely but also to engage in advocacy. There are no legislative initiatives to limit the right to receive information and the freedom of expression, including on the internet. ',
                  'The legal framework guarantees the right to freedom of expression to all and even surpasses international standards. CSOs which are engaged in human rights and democracy promotion are allowed not only to speak freely but also to engage in advocacy. There are no legislative initiatives to limit the right to receive information and the freedom of expression, including on the internet.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_legislation_indicator_2',
                'Indicator 2',
                'Restrictions, such as limitation of hate speech, imposed by legislation are clearly prescribed and in line with international law and standards.',
                [
                  'Any limitation to the freedom of expression should be prescribed by law, legitimate and necessary. An indication can be the comments to the existing laws by various experts, which recognize that the laws are in line with the international standards. Some violations that should be avoided include: the law should not require permission to speak publicly or deliver presentation/lecture; and there should not be a requirement that publications of organizations must be preapproved. The law should not contain broad, confusing statements that may give rise to subjective interpretation. The courts protect the right to freedom of expression when it is violated by undertaking cases and deciding in a timely manner.',
                ],
                [],
                [],
                [
                  'The legislation does not regulate restrictions such as limitations of hate speech, or violations such as permission to speak publicly or deliver presentation/lecture; requirement that publications of organizations must be pre-approved.',
                  'The legislation provides certain provisions to regulate restrictions such as limitations of hate speech, or violations such as permission to speak publicly or deliver presentation/lecture; requirement that publications of organizations must be pre-approved. However the legislation still allows some space for CSO operation.',
                  'The legislation provides provisions to regulate restrictions such as limitations of hate speech, etc, however with minor issues to be improved. The law does not require obtaining permission to speak publicly or deliver presentation/lecture; and there is no requirement that publications of organizations must be pre-approved.',
                  'The legislation provides provisions to regulate restrictions such as limitations of hate speech, etc, in line with international standards. The law does not require the need to obtain permission to speak publicly or deliver presentation/lecture; and there is no requirement that publications of organizations must be pre-approved.',
                  'The legislation provides provisions to regulate restrictions such as limitations of hate speech, etc, which even surpass international standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_legislation_indicator_3',
                'Indicator 3',
                'Libel is a misdemeanor rather than part of the penal code',
                [
                  'The penal code does not regulate libel. For purpose of the Matrix, this indicator should be considered as much as it affects CSOs and its members in exercising their freedom of expression.',
                ],
                [],
                [],
                [
                  'The penal code regulates libel. This is fully restrictive to the operation of CSOs and their representatives and seriously obstructs their work, particularly when exercising their freedom of expression.',
                  'The penal code regulates libel. This is restrictive to the operation of CSOs and their representatives and is making difficult their work, particularly when exercising their freedom of expression, however they still have some space to operate.',
                  'The penal code does not regulate libel, however there are minor restrictions in legislation and issues which should be better regulated.',
                  'The penal code does not regulate libel, and is in line with international standards. The legislation fully enables CSOs and its members when they exercise their freedom of expression.',
                  'The penal code does not regulate libel, and surpasses international standards. The legislation fully enables CSOs and its members when they exercise their freedom of expression.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_practice_indicator_1',
                'Indicator 1',
                'CSO representatives, especially those from human rights and watchdog organizations, enjoy the right to freedom of expression on matters they support and they are critical of.',
                [
                  'There are continuous cases in practice where human rights and watchdog organizations can exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. The tools for their communication (web site, emails, Facebook pages) are not blocked nor hacked.',
                ],
                [
                  `\nThere are continuous cases in practice where human rights and watchdog organizations can exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. The tools for their communication (web site, emails, Facebook pages) are not blocked nor hacked.`,
                  `\nNumber of CSOs reporting pressures for activities targeting state policies;`,
                  `\nNumber of CSOs that had their communication tools hacked or blocked.`,
                ],
                [
                  `\nReport prominent cases of limitation of the freedom of expression.`,
                ],
                [
                  'There are numerous and continuous cases where human rights and watchdog organizations are prevented to exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies (systematic attack). Furthermore, there are numerous cases where the tools for their communication (web site, emails, Facebook pages) are blocked or hacked. The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'There are several cases where human rights and watchdog organizations are prevented to exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. Furthermore, there are several cases where the tools for their communication (web site, emails, Facebook pages) are blocked or hacked. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases where human rights and watchdog organizations are prevented to exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. Furthermore, there are one or very few cases where the tools for their communication (web site, emails, Facebook pages) are blocked or hacked. CSOs do not face severe violation, however minor restrictions and difficulties in their work are reported.',
                  'There are no cases where any CSOs, and particularly not human rights and watchdog organizations are prevented to exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. Furthermore, there are no cases where the tools for their communication (web site, emails, Facebook pages) are blocked or hacked.',
                  'There are no cases in practice where any CSOs, and particularly not human rights and watchdog organizations are prevented to exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. Furthermore, there are no cases where the tools for their communication (web site, emails, Facebook pages) are blocked or hacked.  There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_practice_indicator_2',
                'Indicator 2',
                'There are no cases of encroachment of the right to freedom of expression for all.',
                [
                  'There are no unjustified complaints from the groups, organizations, media or individuals that they are in any way prevented from expressing their opinions or views, within legal limitations.',
                ],
                [],
                [
                  `\nReport presence of cases of encroachment of the right to freedom of expression (it clarifies that we need to collect additional data) of media, groups and individuals.`,
                ],
                [
                  'There are numerous unjustified complaints from the groups, organizations, media or individuals that they are prevented from expressing their opinions or views outside of legal limitations (systematic attack). The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'There are several unjustified complaints from the groups, organizations, media or individuals that they are prevented from expressing their opinions or views outside of legal limitations. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases where human rights and watchdog organizations are prevented to exercise their right to speak, criticize and undertake action to achieve their mission, which may target state policies. Furthermore, there are one or very few cases where the tools for their communication (web site, emails, Facebook pages) are blocked or hacked. CSOs do not face severe violation, however minor restrictions and difficulties in their work are reported.',
                  'There are one or very few unjustified complaints from the groups, organizations, media or individuals that they are prevented from expressing their opinions or views outside of legal limitations. CSOs do not face severe violation, however minor restrictions and difficulties in their work are reported.',
                  'There are no unjustified complaints from the groups, organizations, media or individuals that they are prevented from expressing their opinions or views outside of legal limitations. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_practice_indicator_3',
                'Indicator 3',
                'There are no cases where individuals, including CSO representatives, would be persecuted for critical speech in public or private.',
                [
                  'There are no unjustified complaints, media reports or reports by national or international organizations that groups, organizations or individuals are threatened or persecuted, or that they fear for their safety.',
                ],
                [
                  `\nNumber of cases when individuals, including CSO representatives were persecuted due to critical speech, in public or private should be reported.`,
                  `\nNumber of cases when individuals, including CSO representatives succumbed to self-censorship.`,
                ],
                [`\nProminent cases of persecution for critical speech.`],
                [
                  'There are numerous cases where there are unjustified complaints, media reports or reports by national or international organizations that groups, organizations or individuals are threatened or persecuted, or that they fear for their safety (systematic attack). The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'There are several cases where there are unjustified complaints, media reports or reports by national or international organizations that groups, organizations or individuals are threatened or persecuted, or that they fear for their safety. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases where there are unjustified complaints, media reports or reports by national or international organizations that groups, organizations or individuals are threatened or persecuted, or that they fear for their safety. CSOs do not face severe violation, however minor restrictions and difficulties in their work are reported.',
                  'There are no cases of unjustified complaints, media reports or reports by national or international organizations that groups, organizations or individuals are threatened or persecuted, or that they fear for their safety.',
                  'There are no cases of unjustified complaints, media reports or reports by national or international organizations that groups, organizations or individuals are threatened or persecuted, or that they fear for their safety. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_2_practice_indicator_4',
                'Indicator 4',
                'There is no sanction for critical speech, in public or private, under the penal code.',
                [
                  'Court cases regarding libel are dealt with at the misdemeanor courts. There is no prison sentence for critical speech. Compensation adjudicated in such cases is reasonable.',
                ],
                [
                  `\nNumber of CSOs which were sanctioned for critical speech.`,
                  `\nReport cases of imprisonment for critical speech (if any).`,
                ],
                [],
                [
                  'There are numerous cases and systematic attack of CSOs where they are sanctioned for critical speech. There are cases of prison sentences for critical speech. The compensation adjudicated is not reasonable.  The court cases regarding libel are not dealt in misdemeanor courts. The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'There are several cases of CSOs being sanctioned for critical speech. There are few cases of prison sentences for critical speech. The compensation adjudicated in most cases is not reasonable. The court cases regarding libel are mostly not dealt in misdemeanor courts. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases of CSOs being sanctioned for critical speech. There are one or very few cases of prison sentences for critical speech. The compensation adjudicated is mostly reasonable. The court cases regarding libel are rarely dealt outside of misdemeanor courts. CSOs do not face severe violation, however minor restrictions and difficulties in their work are reported.',
                  'There are no cases of CSOs being sanctioned for critical speech. There are no cases of prison sentences for critical speech. The compensation adjudicated is reasonable. The court cases regarding libel are dealt in misdemeanor courts.',
                  'There are no cases of CSOs being sanctioned for critical speech. There are no cases of prison sentences for critical speech. The compensation adjudicated is reasonable. The court cases regarding libel are dealt in misdemeanor courts. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
            ],
          },
          {
            code: 'area_1_sub_area_1_2_standard_3',
            codeName: 'STANDARD 1.2.3',
            originalName: 'Access to Information',
            name: 'Civil society representatives, individually and through their organizations, have the rights to safely receive and impart information through any media',
            legislation: [
              _generateIndicator(
                'area_1_sub_area_1_2_standard_3_legislation_indicator_1',
                'Indicator 1',
                'The legal framework provides the possibility to communicate via and access any source of information, including the Internet and ICT; if there are legal restrictions they are exceptional, limited and based on international human rights law.',
                [
                  'The law should not contain any prohibition about the way the internet or ICT is used in the country or beyond that is not justified under wider international standards. Internationally, the following legal provisions are used to limit the right: criminal laws applied to Internet expression; laws that impose liability on intermediaries for the failure to filter or block content deemed illegal, requirements for internet cafes to take information/photo of users of the internet; prohibitions on the use of Facebook, Skype, YouTube, Twitter or other ICT.',
                ],
                [],
                [],
                [
                  'The law prohibits the way the internet or ICT is used in the country or beyond to communicate via and access any source of information. The following legal provisions might be used to limit the right: criminal laws applied to Internet expression; laws that impose liability on intermediaries for the failure to filter or block content deemed illegal, requirements for internet cafes to take information/photo of users of the internet; prohibitions on the use of Facebook, Skype, YouTube, Twitter or other ICT.',
                  'The law contains major restrictions of the way the internet or ICT is used in the country or beyond to communicate via and access any source of information, but CSOs have some space to operate. The following legal provisions might be used to limit the right: criminal laws applied to Internet expression; laws that impose liability on intermediaries for the failure to filter or block content deemed illegal, requirements for internet cafes to take information/photo of users of the internet; prohibitions on the use of Facebook, Skype, YouTube, Twitter or other ICT.',
                  'The law does not contain provisions that prohibit the way the internet or ICT is used in the country or beyond to communicate via and access any source of information, however it has minor inconsistencies and issues to be improved. The following legal provisions might be rarely used to limit the right: criminal laws applied to Internet expression; laws that impose liability on intermediaries for the failure to filter or block content deemed illegal, requirements for internet cafes to take information/photo of users of the internet; prohibitions on the use of Facebook, Skype, YouTube, Twitter or other ICT.',
                  'The law does not contain provisions that prohibit the way the internet or ICT is used in the country or beyond to communicate via and access any source of information and is in line with international standards.',
                  'The law does not contain provisions that prohibit the way the internet or ICT is used in the country or beyond to communicate via and access any source of information and surpasses international standards.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_3_legislation_indicator_2',
                'Indicator 2',
                'The legal framework prohibits unjustified monitoring of communication channels, including Internet and ICT, or collecting users’ information by the authorities.',
                [
                  'The legal framework (specific provisions in the relevant laws, criminal law, or acts adopted to regulate communication and ICT) should contain specific guarantees against any illegal monitoring of communication channels and collecting users’ information.',
                ],
                [],
                [],
                [
                  'The legal framework (specific provisions in the relevant laws, criminal law, or acts adopted to regulate communication and ICT) does not contain specific guarantees against any illegal monitoring of communication channels and collecting users’ information.',
                  'The legal framework (specific provisions in the relevant laws, criminal law, or acts adopted to regulate communication and ICT) contains certain guarantees against illegal monitoring of communication channels and collecting users’.  This is restrictive to the operation of CSOs and their representatives and is making difficult their work, however they still have some space to operate.',
                  'The legal framework (specific provisions in the relevant laws, criminal law, or acts adopted to regulate communication and ICT) contains certain guarantees against illegal monitoring of communication channels and collecting users with minor issues which are not in line with international standards.',
                  'The legal framework (specific provisions in the relevant laws, criminal law, or acts adopted to regulate communication and ICT) contains specific guarantees against any illegal monitoring of communication channels and collecting users’ which are in line with international standards.',
                  'The legal framework (specific provisions in the relevant laws, criminal law, or acts adopted to regulate communication and ICT) contains specific guarantees against any illegal monitoring of communication channels and collecting users’ which surpass international standards.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_1_sub_area_1_2_standard_3_practice_indicator_1',
                'Indicator 1',
                'There are no cases in practice where restrictions are imposed on accessing any source of information, including the Internet or ICT.',
                [
                  'Restrictions on the right can include: technical measures such as blocking and filtering certain sites, whether the web site of the organization or social media. Practice should show that there are no complaints or cases.',
                ],
                [
                  `\nNumber of CSOs reporting that their channels of communication were blocked.`,
                  `\nNumber of CSOs reporting restrictions to access to information online or offline.`,
                ],
                [`\nProminent cases should be reported (case study).`],
                [
                  'There are numerous cases in practice where restrictions are imposed over CSOs on accessing any source of information, including the Internet or ICT (systemic attack). Those restrictions can be: blocking channels of communication, restrictions to access to information (online or offline), technical measures such as blocking and filtering certain sites, whether the web site of the organization or social media. The malpractices and restrictions heavily affect the work of CSOs and their representatives.',
                  'There are several cases in practice where restrictions are imposed over CSOs on accessing any source of information, including the Internet or ICT. Those restrictions can be: blocking channels of communication, restrictions to access to information (online or offline), technical measures such as blocking and filtering certain sites, whether the web site of the organization or social media. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases in practice where restrictions are imposed over CSOs on accessing any source of information, including the Internet or ICT. Those restrictions can be: blocking channels of communication, restrictions to access to information (online or offline), technical measures such as blocking and filtering certain sites, whether the web site of the organization or social media.',
                  'There are no cases in practice where restrictions are imposed over CSOs on accessing any source of information, including the Internet or ICT.',
                  'There are no cases in practice where restrictions are imposed over CSOs on accessing any source of information, including the Internet or ICT. There are cases when best practices are surpassed.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_3_practice_indicator_2',
                'Indicator 2',
                'The internet is widely accessible and affordable.',
                [
                  'Internet access is cheap and technically available for all. The government encourages and supports the setting up of public and other spaces with free internet/Wi-Fi access for all citizens to use. There are no requirements for users’ identification and data log keeping at the public points of access to Internet. While this indicator might be broad and general, it is important to testify to the overall situation in which CSOs work and have access to the internet/ITC.',
                ],
                [
                  `\n% of total population with access to the Internet to be reported (compare and report at least two sources for the most recent year available)`,
                  `\nReport data on the affordability of the internet (The recommended measure for affordability is 1GB of data priced at 2% or less of average income. See for example The Affordable Internet Alliance: https://a4ai.org/affordability-report/report/2018/).`,
                ],
                [],
                [
                  'Internet access is expensive and technically not available for all. The government does not support the setting up of public and other spaces with free internet/WiFi access for all citizens to use. There are requirements for users’ identification and data log keeping at the public points of access to the Internet. The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'Internet access is expensive and there are certain restrictions in terms of availability. The government does not support the setting up of public and other spaces with free internet/WiFi access for all citizens to use. There are requirements for users’ identification and data log keeping at the public points of access to the Internet. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'Internet access is affordable and there are certain minor restrictions in terms of availability. The government supports the setting up of public and other spaces with free internet/WiFi access for all citizens to use with minor challenges. Sometimes, there are requirements for users’ identification and data log keeping at the public points of access to the Internet.',
                  'Internet access is cheap and available for all. The government supports the setting up of public and other spaces with free internet/WiFi access for all citizens to use. There are no requirements for users’ identification and data log keeping at the public points of access to the Internet.',
                  'Internet access is cheap and available for all. The government supports the setting up of public and other spaces with free internet/WiFi access for all citizens to use. There are no requirements for users’ identification and data log keeping at the public points of access to the Internet. There are cases of surpassing best practices.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_3_practice_indicator_3',
                'Indicator 3',
                'There is no practice or cases of unjustified monitoring by the authorities of communication channels, including the Internet or ICT, or of collecting users’ information.',
                [
                  'There are no complaints, media reports or court cases, about cases of unjustified monitoring and collecting users’ and traffic information, especially not without court warrant based on law which complies with international standards.',
                ],
                [
                  `\nReport recorded cases of unjustified monitoring or collecting of users’ information.`,
                ],
                [
                  `\nNumber of CSOs that reported unjustified monitoring of communication channels.`,
                ],
                [
                  'There are numerous cases of complaints, media reports or court cases, about cases of unjustified monitoring and collecting users’ and traffic information, especially not without court warrant based on law which complies with international standards. The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'There are several cases of complaints, media reports or court cases, about cases of unjustified monitoring and collecting users’ and traffic information, especially without court warrant based on law which complies with international standards. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases of complaints, media reports or court cases, about cases of unjustified monitoring and collecting users’ and traffic information, especially without court warrant based on law which complies with international standards.',
                  'There are no cases of complaints, media reports or court cases, about cases of unjustified monitoring and collecting users’ and traffic information, especially not without court warrant based on law which complies with international standards.',
                  'There are no cases of complaints, media reports or court cases, about cases of unjustified monitoring and collecting users’ and traffic information, especially not without court warrant based on law which complies with international standards. There are cases in practice of surpassing best practices.',
                ]
              ),
              _generateIndicator(
                'area_1_sub_area_1_2_standard_3_practice_indicator_4',
                'Indicator 4',
                'There are no cases of police harassment of members of social networking groups.',
                [
                  'There are examples of forming groups online via social networks (e.g., Facebook). Individuals are not persecuted or detailed for belonging to a social network group, and there are no cases of blocking emails of social network group members.',
                ],
                [
                  `\nNumber of reported cases of police harassment of members of social network groups;`,
                  `\nNumber of CSOs and informal groups reporting persecution for activity in an online network/ initiative.`,
                ],
                [`\nReport prominent case studies of harassment.`],
                [
                  'There are numerous cases of police harassment of members of social networking groups. There are numerous cases when individuals are persecuted or detained for belonging to a social network group, and there are cases of blocking emails of social network group members. The malpractices and restrictions which are common heavily affect the work of CSOs and their representatives.',
                  'There are several cases of police harassment of members of social networking groups. There are cases when individuals are persecuted or detained for belonging to a social network group, and there are cases of blocking emails of social network group members. CSOs are hampered and face substantial challenges and obstacles in their operation, but their representatives can still operate.',
                  'There are one or very few cases of police harassment of members of social networking groups. There are one or very few cases when individuals are persecuted or detained for belonging to a social network group, and of blocking emails of social network group members.',
                  'There are no cases of police harassment of members of social networking groups. There are no cases when individuals are persecuted or detained for belonging to a social network group, and there are no cases of blocking emails of social network group members.',
                  'There are no cases of police harassment of members of social networking groups. There are no cases when individuals are persecuted or detained for belonging to a social network group, and there are no cases of blocking emails of social network group members. There are cases of surpassing best practices.',
                ]
              ),
            ],
          },
        ],
      },
    ],
  },
  {
    code: 'area_2',
    codeName: 'Area 2',
    name: 'Framework for CSO Financial Viability and Sustainability',
    subAreas: [
      {
        code: 'area_2_sub_area_2_1',
        codeName: 'Sub-Area 2.1',
        name: 'TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
        principle: 'CSOs and donors enjoy favourable tax treatment',
        standards: [
          {
            code: 'area_2_sub_area_2_1_standard_1',
            codeName: 'STANDARD 2.1.1',
            originalName: 'Tax Benefits',
            name: 'Tax benefits are available on various income sources of CSOs',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_legislation_indicator_1',
                'Indicator 1',
                'The law provides tax free treatment for all grants and donations supporting non-for-profit activity of CSOs.',
                [
                  'Income from traditional non-profit sources of CSOs such as private or corporate donations, grants and membership fees are not subject to income or corporate tax. The tax laws should contain clear exemptions from taxation of income from these types of sources.',
                ],
                [],
                [],
                [
                  'The legislation does not provide any tax free treatment for grants and donations supporting non-for-profit activity of CSOs. The income from traditional non-profit sources of CSOs such as private or corporate donations, grants and membership fees are subject to income or corporate tax.',
                  'The legislation provides tax free treatment for particular grants and donations supporting non-for-profit activity of CSOs. Most of the income coming from traditional non-profit sources of CSOs such as private or corporate donations, grants and membership fees are subject to income or corporate tax. This is making difficult the CSO operations, however they still have some legal opportunity to use exemptions.',
                  'The legislation provides tax free treatment for some of the grants and donations supporting non-for-profit activity of CSOs. Most of the income coming from traditional non-profit sources of CSOs such as private or corporate donations, grants and membership fees is not subject to income or corporate tax. There are some minor legal inconsistencies and issues which are not regulated and should be improved.',
                  'The legislation provides tax free treatment for all the grants and donations supporting non-for-profit activity of CSOs in line with international standards. The income coming from traditional non-profit sources of CSOs such as private or corporate donations, grants and membership fees is not subject to income or corporate tax.',
                  'The legislation provides tax free treatment for all the grants and donations supporting non-for-profit activity of CSOs and surpasses international standards. The income coming from traditional non-profit sources of CSOs such as private or corporate donations, grants and membership fees is not subject to income or corporate tax.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_legislation_indicator_2',
                'Indicator 2',
                'The law provides tax benefits for economic activities of CSOs.',
                [
                  'As a good regulatory practice, income from economic activities should be exempt from tax. The law could provide for full exemption for all CSOs, or full exemption for organizations involved in public benefit activities (or those that have acquired a special public benefit/charitable status). In case there is no full exemption, the law can tax economic activities but with a lower tax rate, or can provide exemption below a certain threshold. The income from rent of the CSO’s own property could also qualify as income from economic activity.',
                ],
                [],
                [],
                [
                  'The legislation does not provide benefits for economic activities of any organizational type (including CSOs). On the contrary, the legislation imposes hampering provisions that seriously obstruct CSO’s work.',
                  'The legislation does not provide benefits for economic activities of any organizational type (including CSOs). On the contrary, the legislation imposes hampering provisions that seriously obstruct CSO’s work.',
                  'The legislation provides benefits for economic activities to a wider group of CSOs (e.g. those that have acquired a special public benefit/charitable status, those that have income from rent of the own property, interest from bank deposits and dividends from owning a company or shares in it). However, even though there are provisions enabling some or all of these exemptions, there are minor inconsistencies and issues for improvement.',
                  'The legislation provides benefits for economic activities to all CSOs in line with international standards (e.g. those that have acquired a special public benefit/charitable status, those that have income from rent of the own property, interest from bank deposits and dividends from owning a company or shares in it).',
                  'The legislation provides benefits for economic activities to all CSOs and surpasses international standards',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_legislation_indicator_3',
                'Indicator 3',
                'The law provides tax benefits for passive investments of CSOs.',
                [
                  'Investing an organization’s assets to increase them is considered a passive investment. It is passive because that is not the primary purpose of the organization (as compared to investment companies) but once the organization has some assets it is best if it manages them with good care. It usually aims to help an organization develop long-term sustainability and stability of its operations. Passive investment would include income from bank deposits, currency exchange surplus, dividends, sale of own shares, etc. The most basic exemptions include the exemption from tax on the interest from bank deposits and the dividends from owning a company (or shares in it).',
                ],
                [],
                [],
                [
                  'The legislation does not provide tax benefits for passive investment (income from bank deposits, currency exchange surplus, dividends, sale of own shares, etc.) of CSOs. On the contrary, there are provisions that impose restrictions or higher burdens on those CSOs that engage in passive investment, which seriously obstruct the work of CSOs.',
                  'The legislation provides certain tax benefits for passive investment (such as income from bank deposits, currency exchange surplus, dividends, sale of own shares, etc.) of CSOs which are unclear and ambiguous. Namely, there are provisions that are making it difficult for CSOs to access those benefits, however there is still space for using them.',
                  'The legislation provides tax benefits for passive investment (such as income from bank deposits, currency exchange surplus, dividends, sale of own shares, etc.) of CSOs, with minor inconsistencies and issues for improvement.',
                  'The legislation provides tax benefits for passive investment (such as income from bank deposits, currency exchange surplus, dividends, sale of own shares, etc.) of CSOs in line with international standards.',
                  'The legislation provides tax benefits for passive investment (such as income from bank deposits, currency exchange surplus, dividends, sale of own shares, etc.) of CSOs that surpass international standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_legislation_indicator_4',
                'Indicator 4',
                'The law allows the establishment of and provides tax benefits for endowments.',
                [
                  'An endowment is the capital/assets of an organization which are invested, with only the income from the investment used for running the organization and its programs. Some of the main issues related to endowments are: who can establish such an endowment? who manages the endowment? what are the requirements for investing it? and what are the requirements for spending the endowment and its income? There should be a possibility for CSOs, under certain conditions, to invest their assets. In the classical endowment, there may be restrictions where the assets can be invested (to guarantee the stability of the organization). There may be limitations on spending the endowment – a requirement that the annual expenditure of an organization is a percentage of the endowment, or that the income from the endowment does not cover only administrative expenses. ',
                  'A very important issue is whether and what limits are legislatively put on spending the endowment itself to assess whether the law stimulates endowment building. The incentives for endowments are most often two types – incentives for the revenue of the invested endowment (similar to the incentives for passive investments) and incentives for donors providing large gifts for endowment building. An example of a benefit for a donor of an endowment is the case of where donations above 1 mil. EUR could be accounted and deducted from the income for a period of 10 years.',
                ],
                [],
                [],
                [
                  'The legislation does not allow establishment of nor provides benefits for endowments. There are no provisions in legislation that provide possibility for CSOs under any circumstances to invest their assets. This seriously obstruct the work of CSOs which consider this as a source of income.',
                  'The legislation might contain certain provisions in legislation that provide possibility for CSOs under specific circumstances to invest their assets. There are restrictions and limitations on: where those assets can be invested, requirement that the annual expenditure of an organization is a percentage of the endowment, or that the income from the endowment does not cover only administrative expenses. However, there are no incentives that stimulate endowment building. Thus, these provisions are making it difficult for CSOs to engage with endowments, however there is still space for using them.',
                  'The legislation might contain certain provisions in legislation that provide possibility for CSOs under specific circumstances to invest their assets. There are minor issues related to: where those assets can be invested, requirement that the annual expenditure of an organization is a percentage of the endowment, or that the income from the endowment does not cover only administrative expenses. Furthermore, there might be few incentives that stimulate endowment building.',
                  'The legislation provides the possibility for CSOs to invest their assets. The legislation is clear and effective when it comes to different issues such as where those assets can be invested, or if any other requirements are imposed requirement. Furthermore, there are important incentives that stimulate endowment building. Namely, the incentives for endowments are most often two types – incentives for the revenue of the invested endowment (similar to the incentives for passive investments) and incentives for donors providing large gifts for endowment building. An example of a benefit for a donor of an endowment is the case of where donations above 1 mil. EUR could be accounted and deducted from the income for a period of 10 years',
                  'The legislation provides the possibility for CSOs to invest their assets. The legislation is clear and effective when it comes to different issues such as where those assets can be invested, or if any other requirements are imposed requirement. Furthermore, there are important incentives that stimulate endowment building. Namely, the incentives for endowments are most often two types – incentives for the revenue of the invested endowment (similar to the incentives for passive investments) and incentives for donors providing large gifts for endowment building. There are even cases of legislation surpassing international standards.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_practice_indicator_1',
                'Indicator 1',
                'There is no direct or indirect (hidden) tax on grants reported.',
                [
                  'Indirect tax on grants may include any type of a hidden fee that has to be paid e.g. to the authorities where the seat of the organization is, or to the bank for operating with the grant. A normal bank charge would not fall under this category unless, for example, it is a percentage of the grant received.',
                ],
                [
                  `\nNumber of CSOs that experienced burdensome fees for the receipt of grants (e.g. paid to authorities, banks where grant is received).`,
                  `\nNumber of CSOs that experienced indirect taxes for the receipt of grants.`,
                ],
                [
                  `\nNumber of CSOs that experienced indirect taxes for the receipt of grants.`,
                ],
                [
                  'There are numerous cases of direct and/or indirect (hidden) tax on grants being reported. Such cases include: experiences with burdensome fees for the receipt of grants (e.g. paid to authorities, banks where grant is received, grants may include any type of a hidden fee that has to be paid e.g. to the authorities where the seat of the organization. Malpractices as such and restrictions are common and heavily affect the work of CSOs.',
                  'There are several cases of direct and/or indirect (hidden) tax on grants being reported. Such cases include: experiences with burdensome fees for the receipt of grants (e.g. paid to authorities, banks where grant is received, grants may include any type of a hidden fee that has to be paid e.g. to the authorities where the seat of the organization. Despite CSOs facing serious difficulties, they can still find space to operate and use the grants.',
                  'There are one or very few cases of direct and/or indirect (hidden) tax on grants being reported. Such cases include: experiences with burdensome fees for the receipt of grants (e.g. paid to authorities, banks where grant is received, grants may include any type of a hidden fee that has to be paid e.g. to the authorities where the seat of the organization. Severe violations are not common but minor difficulties in the work of CSOs are reported.',
                  'There are no cases of direct and/or indirect (hidden) tax on grants being reported.',
                  'There are no cases of direct and/or indirect (hidden) tax on grants being reported. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_practice_indicator_2',
                'Indicator 2',
                'Tax benefits for economic activities of CSOs are effective and support the operation of CSOs.',
                [
                  'Tax benefits for economic activity should be simple and straightforward. If there are too many conditions to be fulfilled in order to use them, this would create a practical problem for the CSO. Using tax benefits can be hard for CSOs when too many documents are required, considering the complex administrative procedures. If only a small number of organizations utilize the tax benefits, this may be an indicator that the tax benefits may be more discouraging than enabling.',
                ],
                [
                  `\n	Number of CSOs that use tax benefits for economic activities (e.g. if these apply only to PBOs, then clarify that).`,
                  `\n	Average score on the burdensomeness of administrative requirements for accessing tax benefits for economic activities.`,
                ],
                [],
                [
                  'In case there are certain tax benefits for economic activities, there are too many conditions to be fulfilled in order for CSOs to use them. There are numerous CSOs reporting that using tax benefits is hard when too many documents are required, considering the complex administrative procedures. There are none or very limited number of organizations that utilize the tax benefits. The malpractices and restrictions are common and heavily affect the work of CSOs.',
                  'There are too many conditions to be fulfilled in order for CSOs to use tax benefits for economic activities. There are numerous CSOs reporting that using tax benefits is hard when too many documents are required, considering the complex administrative procedures. There are a limited number of organizations that utilize the tax benefits. Despite CSOs facing serious difficulties, they can still find space to operate and use the tax benefits.',
                  'There are few conditions to be fulfilled in order for CSOs to use tax benefits for economic activities. There are numerous CSOs reporting that use tax benefits easily, and the documentation required is not too burdensome, and the administrative procedures are fairly simple. There are several organizations that utilize the tax benefits.',
                  'All of the CSOs report that the usage of tax benefits for economic activity are simple and straightforward (easy administrative procedure, simple documentation, etc.). There are numerous organizations that utilize the tax benefits.',
                  'All of the CSOs report that the usage of tax benefits for economic activity are simple and straightforward (easy administrative procedure, simple documentation, etc.). There are numerous organizations that utilize the tax benefits. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_practice_indicator_3',
                'Indicator 3',
                'Passive investments are utilized by CSOs and no sanctions are applied in doing so.',
                [
                  'Passive investment is understood and accepted as a concept by the tax authorities, and they differentiate it from CSO economic activity in practice.',
                ],
                [
                  `\nPassive investment is understood and accepted as a concept by the tax authorities, and they differentiate it from CSO economic activity in practice.`,
                  `\nNumber of CSOs utilizing passive investments (e.g. portfolio returns; income from rent) should be reported.`,
                ],
                [
                  `\nNumber of CSOs which receive tax benefits on passive investments.`,
                ],
                [
                  'There are no CSOs reporting to be generating income with passive investments, nor tax benefits. On the contrary, there are reported cases of sanctions being applied by the authorities to those CSOs that generate income from passive investments. The malpractices and restrictions are common and heavily affect the work of CSOs.',
                  'There are very few CSOs reporting to be generating income with passive investments, and even less using tax benefits. In addition, there are also few reported cases of sanctions being applied by the authorities to those CSOs that generate income from passive investments.  Despite CSOs facing serious difficulties, they can still find space to operate and generate income from passive investments and use the tax benefits.',
                  'There are several CSOs reporting to be generating income with passive investments, and few using tax benefits. In addition, there are very few reported cases of sanctions being applied by the authorities to those CSOs that generate income from passive investments. Severe violations are not common but minor difficulties in the work of CSOs are reported.',
                  'There are numerous CSOs reporting to be generating income with passive investments, and using tax benefits. There are no reported cases of sanctions being applied by the authorities to those CSOs that generate income from passive investments.',
                  'There are numerous CSOs reporting to be generating income with passive investments, and using tax benefits. There are no reported cases of sanctions being applied by the authorities to those CSOs that generate income from passive investments. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_1_practice_indicator_4',
                'Indicator 4',
                'Endowments are established without major procedural difficulties and operated freely, without administrative burden or high financial cost.',
                [
                  'There are already examples of endowments which operate in the country. The income from the endowments is sufficient to cover a large part of the operation of the endowed organizations. In addition, there are no complaints or reported cases of difficult procedures to establish or operate endowments.',
                ],
                [
                  `\nThere are already examples of endowments which operate in the country. The income from the endowments is sufficient to cover a large part of the operation of the endowed organizations. In addition, there are no complaints or reported cases of difficult procedures to establish or operate endowments.`,
                  `\nNumber of CSOs reporting difficulties related to establishment and operation of endowments. `,
                ],
                [],
                [
                  'There are numerous complaints or reported cases of difficult procedures to establish or operate endowments. Thus there are no examples of endowments which operate in the country. The malpractices and restrictions are common and heavily affect the work of CSOs.',
                  'There are very few examples of endowments which operate in the country, as a result of difficult procedures to establish or operate endowments which were reported. Despite CSOs facing serious difficulties, they can still find space to operate and generate income from passive investments and use the tax benefits.',
                  'There are several examples of endowments which operate in the country. There are several complaints or reported cases of difficult procedures to establish or operate endowments. Severe violations are not common but minor difficulties in the work of CSOs are reported.',
                  'There are numerous examples of endowments which operate in the country. There are no cases of complaints or reported cases of difficult procedures to establish or operate endowments.',
                  'There are numerous examples of endowments which operate in the country. There are no cases of complaints or reported cases of difficult procedures to establish or operate endowments. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
            ],
          },
          {
            code: 'area_2_sub_area_2_1_standard_2',
            codeName: 'STANDARD 2.1.2',
            originalName: 'Incentives for Individual/Corporate Giving',
            name: 'Incentives are provided for individual and corporate giving',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_1_standard_2_legislation_indicator_1',
                'Indicator 1',
                'The law provides tax deductions for individual and corporate donations to CSOs.',
                [
                  'Both individual and corporate donors should receive certain tax exemptions for making donations. The exemptions are usually in the form of deductions – decreasing the amount of the tax base on which the corporate/income tax is levied. E.g. if someone’s income is 1000 EUR and the person has made a donation of 100 EUR, then the tax would be levied on the difference – 900 EUR.',
                  'A less popular form of tax benefits is the tax credit; tax credits allow the donor to subtract part of the donated amount from the tax to be paid, i.e., they reduce the amount of tax owed. There may be a limit on the amount of donations that may be deducted, which could be based on the income or on the profit or turnover (in the case of a company). It is important to also consider that there may be differences in terms of who uses the tax benefit – donors to all CSOs, or donors only to a smaller segment (e.g. public benefit organizations or only organizations working in specific areas). Benefits exist not only for people working under labor contracts, but also for people having income from rent or from other independent activities (e.g. composers, writers, self-employed people).',
                ],
                [],
                [],
                [
                  'The legislation does not provide tax deductions for individual and corporate donations to CSOs. The legislation provides taxation on individual and corporate donations to CSOs. This seriously hampers the work of CSOs.',
                  'The legislation might contain at least one or few tax deductions for individual and corporate donations to CSOs. However, they are available for smaller segment of CSOs. The legislation is making it difficult for CSOs to access the deductions, however they still have space to use some benefits when it comes to individual and corporate donations.',
                  'The legislation provides tax deductions for individual and corporate donations to CSOs, however with minor inconsistencies and issues to be improved and regulated. There might be exemptions in the form of deductions, tax credit, a limit on the amount of donations that might be deducted (based on the income or profit or turnover). Most of the CSOs are able to access the tax deductions, and are available for people that generate income from rent or other independent activities.',
                  'The legislation provides tax deductions for individual and corporate donations to CSOs, in line with international standards. There are various forms of exemptions and mostly those are deductions, as well as tax credit, a certain limit on the amount of donations that might be deducted (based on the income or profit or turnover). All of the CSOs are able to access the tax deductions, and are available for people that generate income from rent or other independent activities.',
                  'The legislation provides tax deductions for individual and corporate donations to CSOs and surpasses international standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_2_legislation_indicator_2',
                'Indicator 2',
                'There are clear requirements/conditions for receiving deductible donations and these include a wide range of publicly beneficial activities.',
                [
                  'Tax benefits for donations are generally available either for activities which are generally considered for the public benefit (e.g. activities in the social, healthcare or education area, protection of human rights, etc.) or for organizations that have acquired a specific tax benefit status (e.g., public benefit status). In cases where the law lists the activities, it should embrace a large list of areas and include the fields of human rights and democracy (which often benefit most from donations). ',
                  'The laws should not prescribe burdensome procedures for claiming tax benefits, processes which require involvement and/or approval of several state bodies. All procedural requirements should be simple; donors should not be required to undergo burdensome procedures to justify the tax benefit. Tax benefits should be fully applicable in practice and the donor and recipient should have legal certainty on whether the donation is eligible for tax benefit prior to a donation commitment being made. Donors are not held responsible for the actual use of the donation for public benefit (except in cases of corruption).',
                ],
                [],
                [],
                [
                  'The legislation does not provide tax benefits for donations.',
                  'The legislation prescribes certain requirements/conditions for receiving deductible donations however they are not fully clear. The legislation tends to restricts the range of publicly beneficial activities for which deductible donations are available. There is a list of areas which exclude the fields of human rights and democracy (which often benefit most from donations). The law prescribes fairly burdensome procedures for claiming tax benefits, processes which require involvement and/or approval of several state bodies. Most of the procedural requirements are difficult; some donors might be required to undergo burdensome procedures to justify the tax benefit. The donor and recipient do not have legal certainty on whether the donation is eligible for tax benefit prior to a donation commitment being made. Donors might be held responsible for the actual use of the donation for public benefit.',
                  'The legislation prescribes certain requirements/conditions for receiving deductible donations which are clear, however with minor issues to be regulated. The legislation tends to allow a wide range of publicly beneficial activities for which deductible donations are available. There is a list of areas which is wide and includes the fields of human rights and democracy (which often benefit most from donations). The law prescribes simple procedures for claiming tax benefits, and processes which do not require involvement and/or approval of several state bodies. Most of the procedural requirements are simple; and donors are usually not required to undergo burdensome procedures to justify the tax benefit. Rarely, there might be provisions according to which donors might be held responsible for the actual use of the donation for public benefit.',
                  'The legislation prescribes clear requirements/conditions for receiving deductible donations in line with international standards. The legislation allows a wide range of publicly beneficial activities for which deductible donations are available. The list of areas is long and includes the fields of human rights and democracy. The law prescribes simple procedures for claiming tax benefits, and processes which do not require involvement and/or approval of several state bodies. All the procedural requirements are simple; and donors are not required to undergo burdensome procedures to justify the tax benefit. The donors are not held responsible for the actual use of the donation for public benefit',
                  'The legislation prescribes clear requirements/conditions for receiving deductible donations which surpass international standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_2_legislation_indicator_3',
                'Indicator 3',
                'State policies regarding corporate social responsibility consider the needs of CSOs and include them in their programs.',
                [
                  'In all strategic or policy documents related to corporate social responsibility, CSOs are considered as a key partner and beneficiary and the needs of CSOs are addressed.',
                ],
                [],
                [],
                [
                  'There are no state policies that deal with corporate social responsibility. In case there are, the CSOs are not considered as a key partner and beneficiary nor their needs are addressed. Furthermore, the policies include exclusionary provisions when it comes to the possibility of CSOs to be involved in the CSR agenda in any way, particularly as service providers.',
                  'In the state policies that deal with corporate social responsibility, CSOs are not considered as a key partner and beneficiary nor their needs are addressed. However, CSOs still have some space to be involved in the CSR agenda.',
                  'In the state policies that deal with corporate social responsibility, CSOs are usually considered as a key partner and beneficiary and most of their needs are addressed, with minor issues to be improved.',
                  'In the state policies that deal with corporate social responsibility, CSOs are considered as a key partner and beneficiary and their needs are fully addressed.',
                  'In the state policies that deal with corporate social responsibility, CSOs are considered as a key partner and beneficiary and their needs are fully addressed. there can even be cases of state policies surpassing standards and principles enshrined in MM.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_1_standard_2_practice_indicator_1',
                'Indicator 1',
                'There is a functional procedure in place to claim tax deductions for individual and corporate donations.',
                [
                  'The procedure for using tax benefits for donations is not complicated and the documents required are not burdensome to get.',
                  'There is a possibility for people not submitting annual tax declaration to use the benefits through their employer or in another way. Where there is a possibility to deduct a donation over a period of several years, this is correctly applied.',
                  'Data shows that people use their right to deduct donations (a negative sign would be if people prefer not to bother with “bureaucracy” and not submit documents for using the tax benefits because of complicated procedures).',
                ],
                [
                  `\nStatistics on number of individual and corporate donors which claimed tax deductions for their donations in the past year;`,
                  `\nNumber of CSOs that agree (strongly agree or agree) that individual donors face difficulties with access to tax deductions (e.g. the procedure is complicated; burdensome administrative requirements).`,
                  `\nNumber of CSOs that agree (strongly agree or agree) that corporate donors face difficulties with access to tax deductions (e.g. the procedure is complicated; burdensome administrative requirements).`,
                ],
                [
                  `\n•Report illustrative cases of barriers that individual and corporate donors face in practice when claiming tax deductions for donations.`,
                  `\n•Number of CSOs which report tax benefits for the organization for the receipt of individual or corporate donations. `,
                  `\n•Number of CSOs that agree that tax incentives for individuals are sufficiently encouraging;`,
                  `\n•Number of CSOs that agree that tax incentives for corporate donors are sufficiently encouraging.`,
                ],
                [
                  'The procedure for using tax benefits for donations is complicated and the documents are burdensome to obtain. There is no possibility for people that are not submitting annual tax declaration to use the benefits through their employer or in another way. There are no cases where people use their right to deduct donations (people prefer not to bother with “bureaucracy” and not submit documents for using the tax benefits because of complicated procedures). There are numerous cases reported where CSOs consider that individual and corporate donors face difficulties with access to tax deductions (e.g. the procedure is complicated; burdensome administrative requirements). Malpractices and restrictions are heavily affecting the operation of CSOs.',
                  'The procedure for using tax benefits for donations is complicated and the documents are burdensome to obtain to a certain extent. There is no possibility for people that are not submitting annual tax declaration to use the benefits through their employer or in another way. There are almost no cases where people use their right to deduct donations (people prefer not to bother with “bureaucracy” and not submit documents for using the tax benefits because of complicated procedures). There are several cases reported where CSOs consider that individual and corporate donors face difficulties with access to tax deductions (e.g. the procedure is complicated; burdensome administrative requirements). CSOs are hampered, and are facing substantial challenges, but still have space to obtain some benefits.',
                  'The procedure for using tax benefits for donations is fairly simple and the documents are not too burdensome to obtain. There is possibility for people that are not submitting annual tax declaration to use the benefits through their employer or in another way. There are few cases where people use their right to deduct donations. There are very few cases reported where CSOs consider that individual and corporate donors face difficulties with access to tax deductions (e.g. the procedure is complicated; burdensome administrative requirements). Severe restrictions are not common but minor inconsistencies and difficulties are reported.',
                  'The procedure for using tax benefits for donations is simple and the documents are easy to obtain. There is possibility for people that are not submitting annual tax declaration to use the benefits through their employer or in another way. There are numerous cases where people use their right to deduct donations. There are no cases reported where CSOs consider that individual and corporate donors face difficulties with access to tax deductions.',
                  'The procedure for using tax benefits for donations is simple and the documents are easy to obtain. There is possibility for people that are not submitting annual tax declaration to use the benefits through their employer or in another way. There are numerous cases where people use their right to deduct donations. There are no cases reported where CSOs consider that individual and corporate donors face difficulties with access to tax deductions. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_2_practice_indicator_2',
                'Indicator 2',
                'CSOs are partners to the state in promoting CSR.',
                [
                  'All policy documents in the area of corporate social responsibility are prepared and implemented in partnership with CSOs. There are examples and initiatives in practice on partnership that promote CSR for the civil sector.',
                ],
                [],
                [],
                [
                  'The process of preparation and implementation of policy documents in the area of corporate social responsibility does not include partnership with CSOs. There are no examples and initiatives in practice on partnerships that promote CSR for the civil sector. Furthermore, there are cases when CSOs were excluded from the discussion on CSR and implementation of policies.',
                  'The process of preparation and implementation of policy documents in the area of corporate social responsibility rarely includes partnership with CSOs. There are very few examples and initiatives in practice on partnerships that promote CSR for the civil sector.',
                  'The process of preparation and implementation of policy documents in the area of corporate social responsibility usually includes partnership with CSOs. There are few examples and initiatives in practice on partnerships that promote CSR for the civil sector.',
                  'The process of preparation and implementation of policy documents in the area of corporate social responsibility always includes partnership with CSOs. There are numerous examples and initiatives in practice on partnerships that promote CSR for the civil sector.',
                  'The process of preparation and implementation of policy documents in the area of corporate social responsibility always includes partnership with CSOs. There are numerous examples and initiatives in practice on partnerships that promote CSR for the civil sector. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_1_standard_2_practice_indicator_3',
                'Indicator 3',
                ': CSOs working in the main areas of public interest, including human rights and watchdog organizations, effectively enjoy tax deductible donations.',
                [
                  'In case of specific limitations on the types of CSOs that can receive tax-deductible donations, the list includes the most typical public benefit organizations e.g. human rights organizations, organizations working in the areas of healthcare, social services and education, etc. Whilst there may be other categories of recipients of tax-deductible donations (e.g. hospitals, galleries, museums) which are not CSOs, the benefits that these can use are the same as the benefits for CSO donors.',
                ],
                [
                  `\nReport presence of CSOs working in main areas of public interest on the PBO list and statistics on the % of registered PBOs per issue area/policy field;`,
                  `\nNumber of CSOs per policy field (including human rights and watchdog organizations, education, social services, health) that enjoy tax deductible donations from individual and corporate donors as reported in the survey. `,
                ],
                [
                  `\nNumber of CSOs per policy field that reported having PBO status.`,
                ],
                [
                  'There are no CSOs that are registered as working in areas of public interest (public benefit status).',
                  'There are very few CSOs that are registered as working in areas of public interest. CSOs working in the main areas of public interest, including human rights and watchdog organizations, are restricted from effectively enjoy tax deductible donations. There are specific limitations on the types of CSOs that can receive tax-deductible donations, namely the most typical public benefit organizations e.g. human rights organizations, organizations working in the areas of healthcare, social services and education, etc. are excluded.',
                  'There are several CSOs that are registered as working in areas of public interest (either there is or there is no opportunity for this provided by the state). CSOs working in the main areas of public interest, including human rights and watchdog organizations, are able from effectively enjoy tax deductible donations, with minor difficulties.',
                  'There are numerous CSOs that are registered as working in areas of public interest. CSOs working in the main areas of public interest, including human rights and watchdog organizations, are able to effectively enjoy tax deductible donations.',
                  'There are numerous CSOs that are registered as working in areas of public interest (either there is or there is no opportunity for this provided by the state). CSOs working in the main areas of public interest, including human rights and watchdog organizations, are able from effectively enjoy tax deductible donations. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
            ],
          },
        ],
      },
      {
        code: 'area_2_sub_area_2_2',
        codeName: 'Sub-Area 2.2',
        name: 'STATE SUPPORT',
        principle:
          'State support to CSOs is provided in a transparent way and spent in an accountable manner',
        standards: [
          {
            code: 'area_2_sub_area_2_2_standard_1',
            codeName: 'STANDARD 2.2.1',
            originalName: 'Public Funding Availability',
            name: 'Public funding is available for institutional development of CSOs, project support and cofinancing of EU and other grants.',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_legislation_indicator_1',
                'Indicator 1',
                'There is a law or national policy (document) that regulates state support for institutional development for CSOs, project support and co-financing of EU funded projects.',
                [
                  'The government has adopted a law, a strategy, a compact or another document which contains the steps the government has to take with regards to supporting CSDev or supporting CSOs and their work, especially financial support. It is important that this document targets civil society as a whole and not some segment or sub-sector (e.g. organizations providing social services only).',
                  `Examples of areas regulated in different countries include:
                  \nthe process of CSO and citizen participation in decision-making;
                  \nsupport for volunteerism;
                  \nsupport for philanthropy;
                  \nmeasures to support the financial sustainability of CSOs;
                  \nmeasures to support the financial sustainability of CSOs.
                  `,
                ],
                [],
                [],
                [
                  'The state support for institutional development for CSOs, project support and co-financing of EU funded projects is not regulated in law. Lack of regulation is seriously hampering the work of CSOs.',
                  'The legislation contains unclear provisions for state support for institutional development for CSOs, project support and co-financing of EU funded projects. However, the provisions only target segments or sub-sectors of civil society and not the whole sector. This makes the operation of CSOs difficult, however they have some space to obtain state funding.',
                  'The legislation contains provisions for state support for institutional development for CSOs, project support and co-financing of EU funded projects, with minor issues to be regulated and improved. Examples of areas regulated in might include: measures to support the financial sustainability of CSOs; regulation of a specific mechanism for state funding to CSOs, etc.',
                  'The legislation contains steps that the government undertakes with regards to providing state support for institutional development for CSOs, project support and co-financing of EU funded projects, in line with MM standards. The documents target civil society as a whole. Examples of areas regulated in might include: measures to support the financial sustainability of CSOs; regulation of a specific mechanism for state funding to CSOs, etc.',
                  'The legislation contains steps that the government undertakes with regards to providing state support for institutional development for CSOs, project support and co-financing of EU funded projects, and surpasses MM standards. The documents target civil society as a whole.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_legislation_indicator_2',
                'Indicator 2',
                'There is a national-level mechanism for distribution of public funds to CSOs.',
                [
                  'The mechanism for distribution of public funds should target CSOs specifically. The mechanism could be centralized (all funds for CSOs at the national level are distributed by one entity) or decentralized (there could be many agencies providing funds to CSOs in different areas and/or at local level). The mechanism could be managed by an especially set up fund (Estonia, Croatia), by a special agency (Albania), by line ministry/ies or could be contracted to a CSO (many of the NGO Funds under the EEA/ Norway grants). In most cases, the mechanism would be used to distribute grants.',
                ],
                [],
                [],
                [
                  'There is no distribution of public funds to CSO.',
                  'There are few legal documents that provide basis for the type of mechanism for distribution of public funds to CSO that exists (centralized and/or decentralized) with major issues that should be improved. The existing way of distribution of public funds does not target CSOs specifically, thus is unclear if this is a possibility they can use. This makes the operation of CSOs difficult, however they have some space to obtain state funding.',
                  'There are numerous legal documents, providing a centralized and/or decentralized mechanism for distribution of public funds to CSO that is regulated with minor inconsistencies and need for improvement. The existing way of distribution of public funds does target CSOs specifically. There is even a legal possibility for setting up a fund by a special agency, line ministry, or could be contracted to a CSO.',
                  'There are numerous legal documents, providing a centralized and/or decentralized mechanism for distribution of public funds to CSO that is regulated with minor inconsistencies and need for improvement. The existing way of distribution of public funds does target CSOs specifically. There is even a legal possibility for setting up a fund by a special agency, line ministry, or could be contracted to a CSO.',
                  'There is a centralized and/or decentralized mechanism for distribution of public funds to CSO, that surpasses MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_legislation_indicator_3',
                'Indicator 3',
                'Public funds for CSOs are clearly planned within the state budget.',
                [
                  'The annual state budget contains an allocation of funds that would be distributed only to CSOs. When the source of income for the funding mechanism comes from a specific source (e.g. lottery proceeds, environment licensing fees), the law precisely defines the amount or percentage to be allocated to CSO development/projects.',
                ],
                [],
                [],
                [
                  'There is no clear process of planning prescribed of the public funds for CSOs within the state budget. The legislation does not define precisely the amount or percentage allocated from the budget to CSOs development/projects. The lack of regulation of a clear planning process is seriously hampering the work and sustainability of CSOs, and leaving space for misuse of public funds (for e.g. for political gains).',
                  'There is a certain insufficiently clear process of planning prescribed of the public funds for CSOs within the state budget. The legislation does not define precisely the amount or percentage allocated from the budget to CSOs development/projects. This makes the operation of CSOs difficult, however they have some space to obtain state funding.',
                  'There is a regulation that prescribes the process of planning of the public funds for CSOs within the state budget, with minor legal restrictions or issues which are not regulated. The legislation defines the amount or percentage allocated from the budget to CSOs development/projects, again with minor inconsistencies.',
                  'The process of planning of the public funds for CSOs within the state budget is clearly prescribed in legislation and is in line with MM standards. The legislation clearly defines the amount or percentage allocated from the budget to CSOs development/projects.',
                  'The process of planning of the public funds for CSOs within the state budget is clearly prescribed in legislation and is surpassing the MM standards. The legislation clearly defines the amount or percentage allocated from the budget to CSOs development/projects.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_legislation_indicator_4',
                'Indicator 4',
                'There are clear procedures for CSO participation in all phases of the public funding cycle.',
                [
                  'There is a requirement that government consults with CSOs over funding priorities and programs for CSOs. CSO representatives are required to participate in the project selection under clear conflict of interest rules. To secure CSO participation in the selection process there could be a quota set for CSOs in the project evaluation committee. There is a requirement that CSO representatives participate in monitoring and evaluation phases of project/program implementation.',
                ],
                [],
                [],
                [
                  'There are no procedures prescribed in the law for CSO participation in all phases of the public funding cycle. There is no requirement that government should consult with CSOs over funding priorities and programs for CSOs. There are restrictions and CSO representatives are not allowed to participate in the project selection. There is no possibility for CSO representatives to participate in monitoring and evaluation phases of the project/ program implementation. ',
                  'There are some unclear procedures prescribed in the law for CSO participation in all phases of the public funding cycle with major issues that should be improved. There is some vaguely prescribed requirements that the government should consult with CSOs over funding priorities and programs for CSOs. There CSO representatives are not able to participate in the project selection. There is no possibility for CSO representatives to participate in monitoring and evaluation phases of project/ program implementation.',
                  'There are some unclear procedures prescribed in the law for CSO participation in all phases of the public funding cycle, with minor issues that should be improved. There is a requirement that the government should consult with CSOs over funding priorities and programs for CSOs, with minor inconsistencies. There are very few provisions for CSO representatives to participate in the project selection, and in monitoring and evaluation phases of project/ program implementation.',
                  'There are clear procedures prescribed in the law for CSO participation in all phases of the public funding cycle in line with MM standards. There is a requirement that the government should consult with CSOs over funding priorities and programs for CSOs. CSO representatives are required to participate in the project selection under clear conflict of interest rules. To secure CSO participation in the selection process there could be a quota set for CSOs in the project evaluation committee. There is a requirement that CSO representatives participate in monitoring and evaluation phases of project/ program implementation.',
                  'There are clear procedures prescribed in the law for CSO participation in all phases of the public funding cycle. There is a requirement that the government should consult with CSOs over funding priorities and programs for CSOs. CSO representatives are required to participate in the project selection under clear conflict of interest rules. To secure CSO participation in the selection process there could be a quota set for CSOs in the project evaluation committee. There is a requirement that CSO representatives participate in monitoring and evaluation phases of project/ program implementation. There are cases of legislation surpassing standards and principles enshrined in MM.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_practice_indicator_1',
                'Indicator 1',
                'Available public funding responds to the needs of the CSO sector.',
                [
                  'The amount distributed from the state to the CSO sector is not negligible. State funding is a recognizable source of income for CSOs and various CSOs can utilize it. ',
                  'While the amount of money that should be allocated to CSOs will vary between countries, it is important to consider several factors to decide if it meets their needs, such as: are other sources available to CSOs? To what extent do CSOs depend on state funding? What is the overall capacity of the sector? Which activities are unfunded? And what is the total allocation in the overall budget? ',
                  'The sector is consulted over funding priorities, and funding priorities include CSDev. There is funding for institutional support, as well as for specific projects in different areas. Public funding could be used for co-financing of EU- or other donor-funded projects.',
                ],
                [
                  `\nReport the amount of public funding planned for CSOs in the annual Budget i.e. report the total amount and funding amount per CSO on average`,
                  `\nReport the % share of public funding in the total income of the sector (aggregate data);`,
                  `\nReport the types of grants allocated/received in the past year (project, institutional, co-financing of EU and other projects);`,
                  `\nNumber of CSOs that agree (strongly agree and agree) that public funding meets the needs of CSOs vs. those that disagree.`,
                ],
                [
                  `\nNumber of CSOs which reported funding from public sources; also report percentage of those receiving public funding that have more than 50% of the overall budget from such source as reported in survey;`,
                  `\nReport the number of policy areas for which CSO funding is available (i.e. diversity of areas).`,
                ],
                [
                  'There is no available public funding for the CSO sector. State funding is not a recognizable source of income for CSOs and if there is some funding available is for a very limited group of organizations close to the government. The lack of available funding in practice is seriously hampering the work of CSOs.',
                  'There is some amount of available public funding for the CSO sector. State funding is almost a non-recognizable source of income for CSOs and if there is some funding available is for a very limited, for organizations close to the government. The lack of available funding in practice is presenting serious challenges for CSOs, but despite that they can still operate and find a way to use the funding.',
                  'There is a certain amount available public funding for the CSO sector, however there are minor challenges when it comes to satisfying the needs of the sector. The state funding is a recognizable source of income for CSOs and is available to various organizations.',
                  'There is a sufficient amount of available public funding that responds to the needs of the CSO sector. The state funding is a recognizable source of income for CSOs and is available to various organizations. There is funding for institutional support, as well as for specific projects in different areas. Public funding could be used for co-financing of EU- or other donor-funded projects.',
                  'There is a sufficient amount of available public funding that responds to the needs of the CSO sector. The state funding is a recognizable source of income for CSOs and is available to various organizations. There is funding for institutional support, as well as for specific projects in different areas. Public funding could be used for co-financing of EU- or other donor-funded projects. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_practice_indicator_2',
                'Indicator 2',
                'There are government bodies with a clear mandate for distribution and/or monitoring of the distribution of state funding.',
                [
                  'There could be one or several bodies which can be specifically established for the distribution of state funding to CSOs, or there may be existing institutions that are mandated with CSO funding as part of their obligations (e.g., Government liaison offices for cooperation with CSOs). The bodies responsible for grant-making also have the obligation to monitor the implementation of the funded projects.',
                ],
                [
                  `\nReport presence of state institution/s with a mandate to allocate the biggest share of state funding;`,
                  `\nReport the presence of state body for monitoring the implementation of state funded projects.`,
                ],
                [],
                [
                  'The government bodies do not have a clear mandate for distribution and/or monitoring of the distribution of state funding. In case there is one or several bodies which can be specifically established for the distribution of state funding to CSOs their mandate is not clear to the CSOs. There may be existing institutions that are mandated with CSO funding as part of their obligations (e.g., Government liaison offices for cooperation with CSOs), but their mandate is also unclear, and used for political purposes.',
                  'One or several government bodies are given the mandate for distribution and/or monitoring of the distribution of state funding, with severe limitations when it comes to distribution of funds and monitoring the implementation of the funds.',
                  'One or several government bodies are given the mandate for distribution and/or monitoring of the distribution of state funding, that they follow with minor inconsistencies when it comes to distribution of funds and monitoring the implementation of the funds.',
                  'One or several government bodies have a clear mandate for distribution and/or monitoring of the distribution of state funding.',
                  'One or several government bodies have a clear mandate for distribution and/or monitoring of the distribution of state funding. Their mandated is used in a manner that surpasses best practices.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_practice_indicator_3',
                'Indicator 3',
                'Funding is predictable, not cut drastically from one year to another; and the amount in the budget for CSOs is easy to identify.',
                [
                  'It is clear that state support of CSOs does not depend on changes in political power or of government. The amount is constant and without drastic decreases. In addition, the budget allocation for CSOs should be separate from allocations to other non-profit entities (e.g., in many countries sports clubs are registered as associations, or state bodies can form foundations. The state may claim support for civil society when it is in fact supporting sports or state-controlled institutions).',
                ],
                [
                  `\nReport if the amount of public funding allocated to CSOs in the budget includes allocations on sport clubs and semi-public institutions e.g. public foundations or it is stipulated as a separate category;`,
                  `\nReport % of increase/ decrease of funding for CSOs in comparison to previous year;`,
                  `\nReport % of increase/ decrease of funding for CSOs in comparison to previous year;`,
                ],
                [],
                [
                  'The state support of CSOs clearly depends on changes in political power or of the government interests. There are drastic decreases/increases from one year to another. Furthermore, the budget allocation for CSOs is not separate from allocations to other non-profit entities, thus creates confusion and is not predictable. The lack of predictability and stability around funding in practice is seriously hampering the work of CSOs.',
                  'There are cases when state support of CSO depends on the changes in political power or of government interests. There are certain decreases/increases from one year to another. The budget allocation for CSOs is separate from allocations to other non-profit entities, but it is not clearly presented in the budget. The lack of predictability and stability in funding in practice is presenting serious challenges for CSOs, but despite that they can still operate and find a way to use the funding.',
                  'There are very few cases when state support of CSO depends on the changes in political power or of government, and is usually predictable. There are minor decreases/increases from one year to another. The budget allocation for CSOs is separate from allocations to other non-profit entities, but it is not clearly presented in the budget.  ',
                  'The state support of CSO does not depend on the changes in political power or of government, and is predictable from year to year. The budget allocation for CSOs is separate from allocations to other non-profit entities, and is clearly presented in the budget.  ',
                  'The state support of CSO does not depend on the changes in political power or of government, and is predictable from year to year. The budget allocation for CSOs is separate from allocations to other non-profit entities, and is clearly presented in the budget. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_1_practice_indicator_4',
                'Indicator 4',
                'CSO participation in the public funding cycle is transparent and meaningful.',
                [
                  'The mechanism through which CSOs are selected for participation in the different cycles of the public funding process is transparent.',
                  'When CSO representatives are appointed for certain positions or in certain bodies, the announcement for that is public and the process is open. ',
                  'When joint bodies (with CSO and non-CSO members) are established, CSO representatives have equal powers (e.g. the right to vote) and a balance between the two quotas could exist (e.g. allowing equal numbers of CSO and other representatives).',
                ],
                [
                  `\nNumber of CSO representatives in CSOs-state Council and decision-making/advisory bodies of public foundations/ institutions allocating state funding vs. public and private sector representatives in such bodies;`,
                  `\nPresence of institutions/ bodies for which procedure of election of CSO representatives is open for all and appointments are made public; report cases where the selection is closed, and the appointments are not published; `,
                  `\nNumber of CSOs that agree that CSOs participate in setting of public funding priorities.`,
                ],
                [
                  `\nNumber of CSOs that agree that CSOs are well represented on bodies/ institutions responsible for allocating state funding; .`,
                  `\nNumber of CSOs that agree that the procedure for selecting CSOs representatives in bodies / institutions responsible for allocating state funding is transparent.`,
                ],
                [
                  'There is almost no participation of CSOs in the public funding cycle. In case there is some participation, the mechanism through which CSOs are selected for participation in the different cycles of the public funding process is closed and only reserved to organizations close to the government. This lack of participation in practice is heavily affecting the work and sustainability of the CSOs.',
                  'There is very little participation of CSOs in the public funding cycle. In case there is some participation, the mechanism through which CSOs are selected for participation in the different cycles of the public funding process is usually reserved to organizations close to the government. The lack of participation of CSOs is presenting serious challenges for CSOs, but despite that they can still operate and find a way to use the funding.',
                  'There is a certain participation of CSOs in public funding cycle. When CSO representatives are appointed for certain positions or in certain bodies, the announcement for that is usually public and the process is open, with minor inconsistencies in practice.',
                  'There is participation of CSOs in public funding cycle. When CSO representatives are appointed for certain positions or in certain bodies, the announcement for that is usually public and the process is open. When joint bodies (with CSO and non-CSO members) are established, CSO representatives have equal powers (e.g. the right to vote) and a balance between the two quotas (e.g. allowing equal numbers of CSO and other representatives).',
                  'There is participation of CSOs in public funding cycle. When CSO representatives are appointed for certain positions or in certain bodies, the announcement for that is usually public and the process is open. When joint bodies (with CSO and non-CSO members) are established, CSO representatives have equal powers (e.g. the right to vote) and a balance between the two quotas (e.g. allowing equal numbers of CSO and other representatives). There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
            ],
          },
          {
            code: 'area_2_sub_area_2_2_standard_2',
            codeName: 'STANDARD 2.2.2',
            originalName: 'Public Funding Distribution',
            name: 'Public funding is distributed in a prescribed and transparent manner',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_legislation_indicator_1',
                'Indicator 1',
                'The procedure for distribution of public funds is transparent and legally binding.',
                [
                  'There is a clear and legally binding mechanism through which the whole process of announcing the grant procedure and selecting the supported projects is carried out. The announcement for the procedure is public and it provides for sufficient time to prepare and submit project proposals and all required accompanying documents. Where there is a non-binding document, i.e., a Code, specific provisions should be included to ensure that the state authorities follow the procedure and remedies exist in case on non-implementation or breach.',
                ],
                [],
                [],
                [
                  'The procedure for distribution of public funds is not legally binding, nor it prescribes provisions on transparency, thus severely affects the work of CSOs. There is no clear and legally binding mechanism through which the whole process of announcing the grant procedure and selecting the supported projects is carried out.',
                  'There is a legally binding mechanism through which the whole process of announcing the grant procedure and selecting the supported projects should be carried out, however it has several serious shortcomings. There is a non-binding document, i.e., a Code, where specific provisions are included to ensure that the state authorities follow the procedure and remedies exist in case on non-implementation or breach.',
                  'There is a legally binding mechanism through which the whole process of announcing the grant procedure and selecting the supported projects should be carried out with minor shortcomings. It clearly provides the need for announcing a grant procedure and selecting supported projects. There is a non-binding document, i.e., a Code, where specific provisions are included to ensure that the state authorities follow the procedure and there are remedies exist in case on non-implementation or breach.',
                  'There is a legally binding mechanism through which the whole process of announcing the grant procedure and selecting the supported projects should be carried out and is in line with MM standards. It clearly provides the need for announcing a grant procedure and selecting supported projects. There is a non-binding document, i.e., a Code, where specific provisions are included to ensure that the state authorities follow the procedure and there are remedies exist in case on non-implementation or breach.',
                  'There is a legally binding mechanism through which the whole process of announcing the grant procedure and selecting the supported projects should be carried out and it surpasses MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_legislation_indicator_2',
                'Indicator 2',
                'The criteria for selection are clear and published in advance.',
                [
                  'The criteria and methodology for evaluation of projects are publicly known and available in advance. It is clear which components make a proposal stronger and how they will be evaluated. Rejected proposals receive information on the reasons for not being selected.',
                ],
                [],
                [],
                [
                  'The criteria and methodology for evaluation and selection of projects is not legally binding, nor the criteria are clearly prescribed with an obligation for them to be published in advance.',
                  'The criteria and methodology for evaluation and selection of projects is not legally binding, however there are certain criteria which are insufficiently clear. There might be a certain obligation for the institutions to publish certain criteria in advance which is not legally binding.',
                  'The criteria and methodology for evaluation and selection of projects are legally binding, and provide certain criteria to be followed with minor inconsistencies. These rules include an obligation for the institutions to publish the criteria in advance.',
                  'The criteria and methodology for evaluation and selection of projects are legally binding, and provide comprehensive criteria to be followed which are in line with MM standards. These rules include an obligation for the institutions to publish the criteria in advance as well as provision of information on the reasons for rejection.',
                  'The criteria and methodology for evaluation and selection of projects are legally binding, and provide comprehensive criteria to be followed which are in line with international standards and there are examples of surpassing best practices. These rules include an obligation for the institutions to publish the criteria in advance as well as provision of information on the reasons for rejection.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_legislation_indicator_3',
                'Indicator 3',
                'There are clear procedures addressing issues of conflict of interest in decision-making.',
                [
                  'Conflict of interest rules are part of the regulations or Codes and clearly prescribe the situations and how to avoid them. For example, the law can provide that individuals related to the applicant organizations cannot evaluate their proposals. The law should require that individuals should disclose the possible conflict in advance and they should be replaced with another qualified evaluator.',
                ],
                [],
                [],
                [
                  'The procedures for addressing issues of conflict of interest in decision-making are not regulated.',
                  'The procedures for addressing issues of conflict of interest in decision-making are part of certain regulations or Codes, however they are not clear (e.g. do not contain explicit veto on individuals related to applicant organizations not to evaluate their proposal), nor it provides a clearly described procedure on how to disclose possible conflicts of interest.',
                  'The procedures for addressing issues of conflict of interest in decision-making are part of regulations or Codes, however they contain minor inconsistencies (e.g. contain explicit veto on individuals related to applicant organizations not to evaluate their proposals). The rules might provide a clear procedure on how to disclose possible conflicts of interest.',
                  'The procedures for addressing issues of conflict of interest in decision-making are part of regulations or Codes and are in line with MM standards (e.g. contain explicit veto on individuals related to applicant organizations not to evaluate their proposals). The rules provide a clear procedure on how to disclose possible conflicts of interest in advance and provision of a replacement.',
                  'The procedures for addressing issues of conflict of interest in decision-making and procedure on how to disclose possible conflicts of interest in advance are part of regulations or Codes and are in line with international standards and surpass best practices.  ',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_practice_indicator_1',
                'Indicator 1',
                'Information relating to the procedures for funding and information on funded projects is publicly available.',
                [
                  'The government or the state institutions involved in the process of funding CSOs are required to publish online information on the funded projects – the name and contacts of the recipient organization, the amounts provided and the goals and activities of the supported projects.',
                ],
                [
                  `\nNumber of core funding allocation institutions that failed to publish the call for funding online;`,
                  `\nNumber of core funding allocation institutions that do not provide minimum information on websites of funded projects for the last funding cycle. Report cases of institutions that do not disclose such information. The minimum information that state institutions should disclose are the name and contacts of the recipient organizations, the amounts provided and the goals and activities of the supported projects.`,
                ],
                [
                  `\nNumber of CSOs that agree that information on funding procedures (e.g. calls for application) are publicly available;`,
                  `\nNumber of CSOs that agree that information on funded projects in their area of work is publicly available.`,
                ],
                [
                  'Almost all of the state institutions that allocate funds to CSOs fail to provide publicly available information relating to the procedures for funding and information on funded projects.',
                  'The majority of state institutions that allocate funds to CSOs fail to provide publicly available information relating to the procedures for funding and information on funded projects. Those that provide information are not sufficiently detailed.',
                  'The majority of state institutions that allocate funds to CSOs provide publicly available information relating to the procedures for funding and information on funded projects. However, most of them provide information which are not sufficiently detailed.',
                  'All state institutions that allocate funds to CSOs provide publicly available information relating to the procedures for funding and information on funded projects. However, some of them provide information which are not sufficiently detailed.',
                  'All state institutions that allocate funds to CSOs provide publicly available information relating to the procedures for funding and information on funded projects, and with sufficient details. There are examples of surpassing best practices.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_practice_indicator_2',
                'Indicator 2',
                'State bodies follow the procedure and apply it in a harmonized way.',
                [
                  'The required documents to be submitted with the application for a grant are not difficult to acquire and do not cost too much. They are limited in number and most of them are required at the stage of signing the contract. The application forms are clear and the whole application package is easy to prepare. There are no discriminatory requirements which limit the participation of a large number of organizations in advance.',
                ],
                [
                  `\nCases of formal complaints by CSOs related to inadequate procedure for funding`,
                  `\nNumber of formal complaints out of those submitted that were answered (including the outcome of the response) `,
                  `\nNumber of CSOs which agree that state bodies in their area of work follow the legally prescribed procedure for funding allocation.`,
                ],
                [
                  `\nReport cases of inconsistencies in the application of legal procedures for funding as reported in state audit report, other state reports, media or otherwise.`,
                ],
                [
                  'Almost all of the state bodies that allocate funds to CSOs fail to follow the procedure and apply it in a harmonized way. There are cases of formal complaints by CSOs related to inadequate procedure for funding.',
                  'The majority of state bodies that allocate funds to CSOs fail to follow the procedure and apply it in a harmonized way.',
                  'The majority of state bodies that allocate funds to CSOs manage to follow the procedure and apply it in a harmonized way, with minor inconsistencies.',
                  'All of the state bodies that allocate funds to CSOs follow the procedure and apply it in a harmonized way.',
                  'All of the state bodies that allocate funds to CSOs follow the procedure and apply it in a harmonized way. There are examples of surpassing best practices.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_practice_indicator_3',
                'Indicator 3',
                'The application requirements are not too burdensome for CSOs.',
                [
                  'There are no signals that the recipients of grants are somehow related to the institution or members of the commission that has taken decisions on the grant allocation. There are no cases where awarded organizations are created especially for the purpose of applying for particular funding.',
                ],
                [
                  `\nNumber of CSOs which agree that application requirements are burdensome (e.g. high costs, many documents; difficult to access documents);`,
                  `\nNumber of organizations which agree that application criteria are clear.`,
                  `\nNumber of CSOs which agree that state bodies in their area of work follow the legally prescribed procedure for funding allocation.`,
                ],
                [
                  `\nCases of major funding allocation bodies which stipulate criteria that exclude a large number of organizations (e.g. focusing on membership organizations only; organizations with certain size of budget or paid staff; organizations with service-oriented mission etc.)`,
                ],
                [
                  'Almost all of the state bodies impose, application requirements which are too burdensome for CSOs. Burdensome application requirements can be: required documents are difficult to acquire, the documents are costly, there is long and unclear list of required documents and are requested at the stage of signing the contract; unclear application forms; difficulty in preparation of the application package; discriminatory requirements that limit the participation of a large number of organizations (e.g. focusing on membership organizations only; organizations with certain size of budget or paid staff; organizations with service-oriented mission etc.).',
                  'The majority of state bodies impose application requirements which are too burdensome for CSOs. Burdensome application requirements can be: required documents are difficult to acquire, the documents are costly, there is long and unclear list of required documents and are requested at the stage of signing the contract; unclear application forms; difficulty in preparation of the application package; discriminatory requirements that limit the participation of a large number of organizations (e.g. focusing on membership organizations only; organizations with certain size of budget or paid staff; organizations with service-oriented mission etc.).',
                  'The majority of state bodies impose application requirements which are not too burdensome for CSOs. The application requirements can be: required documents are not difficult to acquire, the documents are not costly, there is a limited list of required documents and they are not requested at the stage of signing the contract; the application forms are clear to a certain extent; the preparation of the application package is not too difficult; there are no discriminatory requirements that limit the participation of a large number of organizations (e.g. focusing on membership organizations only; organizations with certain size of budget or paid staff; organizations with service-oriented mission etc.).',
                  'All of the state bodies impose application requirements which adjusted to the capacities and characteristics of CSOs. The application requirements can be: the required documents are easy and cheap to acquire, there is a limited list of required documents and they are not requested at the stage of signing the contract; the application forms are clear; the preparation of the application package is easy; there are no discriminatory requirements that limit the participation of a large number of organizations (e.g. focusing on membership organizations only; organizations with certain size of budget or paid staff; organizations with service-oriented mission etc.).',
                  'All of the state bodies impose application requirements which adjusted to the capacities and characteristics of CSOs. There are examples of surpassing best practices.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_2_practice_indicator_4',
                'Indicator 4',
                'Decisions on tenders are considered fair and conflict of interest situations are declared in advance.',
                [
                  'There are no signals that the recipients of grants are somehow related to the institution or members of the commission that has taken decisions on the grant allocation. There are no cases where awarded organizations are created especially for the purpose of applying for particular funding.',
                ],
                [
                  `\nNumber of appeals due to suspected conflict of interest in public funding allocation and cases in which conflict of interest was found;`,
                  `\nNumber of CSOs which agree that decisions on public funding allocation are fair.`,
                ],
                [
                  `\nCases of conflict of interest identified in state audit reports; media and other secondary sources including cases of organizations being founded just for the purpose to apply for funding.`,
                ],
                [
                  'There are numerous CSOs having the opinion or making cases of appeals on decisions on tenders which are considered not fair and with conflict of interest situations. Examples of cases of decisions which are not considered fair and with conflict of interest are: signals that the recipients of grants are somehow related to the institution or members of the commission that has taken decisions on the grant allocation, awarded organizations are created especially for the purpose of applying for particular funding.',
                  'There are several CSOs having the opinion or making cases of appeals on decisions on tenders which are considered not fair and with conflict of interest situations. Examples of cases of decisions which are not considered fair and with conflict of interest are: signals that the recipients of grants are somehow related to the institution or members of the commission that has taken decisions on the grant allocation, awarded organizations are created especially for the purpose of applying for particular funding.',
                  'The majority of CSOs consider the decisions on tenders as fair and with situations of conflict of interest declared in advance. Examples of cases of decisions which are not considered fair and with conflict of interest are: signals that the recipients of grants are somehow related to the institution or members of the commission that has taken decisions on the grant allocation, awarded organizations are created especially for the purpose of applying for particular funding.',
                  'All of the CSOs consider the decisions on tenders as fair and with situations of conflict of interest declared in advance. Examples of cases of decisions which are considered fair and with conflict of interest declared in advance are: cases when the institution or members of the commission taking decisions on the grant allocation declare relation to a potential recipient of grant, then clear criteria and published information on the awarded organizations show that the most appropriate organizations received funding.',
                  'All of the CSOs consider the decisions on tenders as fair and with situations of conflict of interest declared in advance. There are examples of surpassing best practices.',
                ]
              ),
            ],
          },
          {
            code: 'area_2_sub_area_2_2_standard_3',
            codeName: 'STANDARD 2.2.3',
            originalName:
              'Accountability, Monitoring and Evaluation of Public Funding',
            name: 'There is a clear system of accountability, monitoring and evaluation of public funding',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_3_legislation_indicator_1',
                'Indicator 1',
                'The procedure for distribution of public funds prescribes clear measures for accountability, monitoring and evaluation.',
                [
                  'The documents regulating the procedure contain clear rules about how the project funding will be evaluated and monitored and they are an integral part of the distribution system/process. All recipients of state funds are required to submit reports detailing the use of funds received. Both desk-based monitoring of documents and quality assessments through visits of activities is undertaken.',
                ],
                [],
                [],
                [
                  'Measures for accountability, monitoring and evaluation are not legally prescribed, which seriously affects the work of the civil society sector.',
                  'Some provisions might contain measures for accountability, monitoring and evaluation within certain legal acts however they are unclear about how the project funding will be evaluated and monitored and burdensome. The requirement around submission of reports and desk-based monitoring of documents as well as visits are not clear.',
                  'Measures for accountability, monitoring and evaluation are provided within legislation however they contain minor inconsistencies in terms of how the project funding will be evaluated and monitored, and the requirement around submission of reports and desk-based monitoring of documents as well as visits.',
                  'The procedure for distribution of public funds prescribes clear measures for accountability, monitoring and evaluation and is in line with MM standards (in terms of how the project funding will be evaluated and monitored, and the requirement around submission of reports and desk-based monitoring of documents as well as visits).',
                  'The procedure for distribution of public funds prescribes clear measures for accountability, monitoring and evaluation and is in line with international and MM standards. There are cases that surpass the standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_3_legislation_indicator_2',
                'Indicator 2',
                'There are prescribed sanctions for CSOs that misuse funds which are proportional to the violation of procedure.',
                [
                  'The prescribed sanctions for violations are proportionate to the level of the breach and consider the size of the grant and the organization. The law should provide for procedures whereby CSOs are given an opportunity to remedy the situation before the sanction is applied. When sanctions are applied, the respective authorities take into consideration the seriousness and the intent of the violation. The purpose of sanctions for small/first-time violations is to discipline the respective CSO.',
                ],
                [],
                [],
                [
                  'The prescribed sanctions for CSOs that misuse funds are extremely burdensome and not proportional to the violation of procedure (the authorities do not recognize the difference in size of the grant and the organization, difference in small/first-time violation). The legislation does not provide remedies for the situation before the sanction is applied.',
                  'The prescribed sanctions for CSOs that misuse funds are not proportional to the violation of procedure (the authorities do not recognize the difference in size of the grant and the organization, difference in small/first-time violation). However, the legislation does provide small space for potential remedies for the situation before the sanction is applied.',
                  'The prescribed sanctions for CSOs that misuse funds are proportional to the violation of procedure (the authorities tend to recognize the difference in size of the grant and the organization, as well as the difference in small/first-time violation) with minor inconsistencies. The legislation provides the possibilities of CSOs to use other remedies for the situation before the sanction is applied.',
                  'The prescribed sanctions for CSOs that misuse funds are proportional to the violation of procedure (the authorities recognize the difference in size of the grant and the organization, as well as the difference in small/first-time violation) and are in line with international and MM standards. The legislation provides the possibilities of CSOs to use other remedies for the situation before the sanction is applied.',
                  'The prescribed sanctions for CSOs that misuse funds are proportional to the violation of procedure (the authorities recognize the difference in size of the grant and the organization, as well as the difference in small/first-time violation), are in line with international and MM standards and there are even cases when the standards are surpassed. The legislation provides the possibilities of CSOs to use other remedies for the situation before the sanction is applied.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_3_practice_indicator_1',
                'Indicator 1',
                'Monitoring is carried out continuously and in accordance with predetermined and objective indicators.',
                [
                  'There are monitoring experts that pay visits to the implementing organization or the events/activities it organizes with public funding to evaluate the quality of implementation. Such visits are conducted with prior notification given to the organization. In addition, organizations’ expenditures are monitored against the proposed budget. There are no cases of administrative harassment on the side of the monitoring experts. There is a mechanism to amend project activities or budget based on reasonable requests.',
                ],
                [
                  `\nReport whether the biggest national bodies responsible for distribution of public funding carried regular monitoring of funded projects (report the number of monitored projects from the total number of CSOs’ projects funded per institution)`,
                  `\nNumber of CSOs which report that they have experienced monitoring on projects funded by the state vs. those that have not.`,
                  `\nNumber of CSOs that report that monitoring was not carried out in accordance with predefined criteria.`,
                  `\nNumber of CSOs that report that monitoring visits were not announced.`,
                ],
                [
                  `\nNumber of CSOs that report that expenditures were not monitored against the proposed budget.`,
                  `\nNumber of CSOs that report that they could adjust the budget to their needs;`,
                  `\nReport prominent cases of harassment by monitoring experts.`,
                ],
                [
                  'The state institutions do not carry out monitoring on the public funding distributed to CSOs. In case certain ad-hoc monitoring occurs it is not in accordance to predetermined and objective indicators in the form of visit its unannounced and burdensome. The state institutions monitor the organizations expenses beyond the proposed budget. There are cases of administrative harassment on the side of the monitoring experts. There is no possibility for CSOs to amend their project activities or budget based on reasonable requests.',
                  'The state institutions carry out monitoring however it is not always in accordance to predetermined and objective indicators. Sometimes monitoring in the form of visit might be unannounced and burdensome. There might be cases when state institutions monitor the organizations expenses beyond the proposed budget. There might be few cases of administrative harassment on the side of the monitoring experts. There possibility for CSOs to amend their project activities or budget based on reasonable requests is burdensome.',
                  'The state institutions carry out monitoring in accordance to predetermined and objective indicators however with minor inconsistencies. Mostly the monitoring in the form of visit is announced and not too difficult. There are very few cases when state institutions monitor the organizations expenses beyond the proposed budget. There are very few cases of administrative harassment on the side of the monitoring experts. There possibility for CSOs to amend their project activities or budget based on reasonable requests is with minor challenges.',
                  'The state institutions carry out monitoring in accordance to predetermined and objective indicators, in line with best practices. The monitoring in the form of visit is timely announced. There are no cases when state institutions monitor the organizations expenses beyond the proposed budget. There are no cases of administrative harassment on the side of the monitoring experts. There are numerous cases when CSOs amend their project activities or budget based on reasonable requests.',
                  'The state institutions carry out monitoring in accordance to predetermined and objective indicators, in line with best practices. The monitoring in the form of visit is timely announced. There are no cases when state institutions monitor the organizations expenses beyond the proposed budget. There are no cases of administrative harassment on the side of the monitoring experts. There are numerous cases when CSOs amend their project activities or budget based on reasonable requests.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_3_practice_indicator_2',
                'Indicator 2',
                'Regular evaluation on effects/impact of public funds is carried out by state bodies and is publicly available.',
                [
                  'The state administration carries out regular (every 3-5 years) evaluation of the public funding it provides to CSOs and determines how to improve the program. Such evaluation could be carried out by an independent entity. The reports from the evaluations are posted on the web site of the respective public institutions in an easily accessible manner.',
                ],
                [
                  `\nReport whether core state funding distributors carry out periodic (3-5 years) evaluation reports on the effects of state funding and report the institutions that do not carry such evaluation. `,
                  `\nReview the web sites of core distributors that carry out periodic reports and report the number of bodies that do not publish the evaluations online.`,
                ],
                [],
                [
                  'There are no examples of core state bodies that distribute public funding to CSOs that carry out evaluations on the effects of the used public funds. The decisions in terms of programs and strategic support to CSOs is politically decided, and hampers the sustainability of the civil society sector.',
                  'There are very few examples of core state bodies that distribute public funding to CSOs that carry out evaluations on the effects of the used public funds. However, those evaluations are not carried out by an independent entity, rather internally implemented and biased. Most of the decisions in terms of programs and strategic support to CSOs is politically decided, and hampers the sustainability of the civil society sector. There are also very few examples of evaluations being publicly available.',
                  'There are numerous examples of core state bodies that distribute public funding to CSOs that carry out evaluations on the effects of the used public funds. The evaluations are carried out regularly, either by an independent entity or internally or both. The state bodies that implement evaluations rely their decisions on improvement of the programs on the evaluation results. Most of the state bodies publish the evaluations online.',
                  'All the core state bodies that distribute public funding to CSOs carry out independent evaluations on the effects of the used public funds regularly. The state bodies improve their programs based on the evaluation results. The evaluations are publicly available.',
                  'All the core state bodies that distribute public funding to CSOs carry out independent evaluations on the effects of the used public funds regularly. The state bodies improve their programs based on the evaluation results. The evaluations are publicly available. There are cases of surpassing best practices.',
                ]
              ),
            ],
          },
          {
            code: 'area_2_sub_area_2_2_standard_4',
            codeName: 'STANDARD 2.2.4',
            originalName: 'Non-financial support',
            name: 'Non-financial support is available from the state',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_4_Non-financial supportlegislation_indicator_1',
                'Indicator 1',
                'Legislation allows state authorities to allocate non-financial support, such as state property, renting space without financial compensation (time bound), free training, consultations and other resources, to CSOs.',
                [
                  'There is a law or regulation which contains provision that authorize state authorities to provide non-financial support to CSOs. Other possible forms of non-financial support include the creation of consultative/ resource centers for CSOs that are supported by the state, and organization of specialized trainings for increasing the capacity of CSOs (free of charge), etc.',
                ],
                [],
                [],
                [
                  'The legislation restricts allocation of non-financial support (state property, renting, renting space without financial compensation (time bound), free training, consultations and other resources) to CSOs.',
                  'The legislation imposes major restrictions when it comes to allocation of non-financial support (state property, renting space without financial compensation (time bound), free training, consultations and other resources) to CSOs, however they still have some possibility for acquiring non-financial support.',
                  'The legislation imposes minor restrictions when it comes to allocation of non-financial support (state property, renting space without financial compensation (time bound), free training, consultations and other resources) to CSOs.',
                  'The legislation allows state institutions to allocate non-financial support (state property, renting space without financial compensation (time bound), free training, consultations and other resources) to CSOs, in line with international and MM standards.',
                  'The legislation allows and encourages state institutions to allocate non-financial support (state property, renting space without financial compensation (time bound), free training, consultations and other resources) to CSOs, in line with international and MM standards and there are cases when legislation is surpassing standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_4_legislation_indicator_2',
                'Indicator 2',
                'The non-financial support is provided under clearly prescribed processes, based on objective criteria and does not privilege any group.',
                [
                  'The procedure for providing non-financial support to CSOs is clear and could be used by different CSOs. Some of the support could be available for longer term periods. Termination of the agreement or renting the property or other type of longer term support could only happen for limited and objectively measurable conditions after sufficient advance notice is provided.',
                ],
                [],
                [],
                [
                  'The legislation fully restricts allocation and distribution of non-financial support to CSOs.',
                  'The legislation restricts allocation and distribution of non-financial support to CSOs. Even though certain state institutions provide non-financial support it is not based on clearly prescribed processes, objective criteria and they provide privileges to certain groups.',
                  'The legislation encompasses minor restrictions when it comes to allocation and distribution of non-financial support to CSOs. There are certain minor issues to be improved when it comes to clearly prescribing processes, objective criteria and privileges to certain groups.',
                  'The legislation provides for non-financial support to CSOs under clearly prescribing processes, it’s based on objective criteria and does not privilege any group.',
                  'The legislation provides for non-financial support to CSOs under clearly prescribing processes, it’s based on objective criteria and does not privilege any group. There are cases when the legislation surpasses best standards.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_2_standard_4_practice_indicator_1',
                'Indicator 1',
                'CSOs use non-financial state support.',
                [
                  'There are examples and reports of CSOs receiving property or other types of nonfinancial support. CSOs recognize the possibility and apply for such support.',
                ],
                [
                  `\nNumber of CSOs which report access to non-financial state support in the past year.`,
                  `\nReport number of contracts signed, and type of non-financial support provided by the state in the past year. `,
                ],
                [],
                [
                  'There are no cases of CSOs that use non-financial state support (nor that is a possibility according to legislation). ',
                  'There are very few cases of CSOs that use non-financial state support, with serious challenges (fearing eviction, burdensome administration, etc.).',
                  'There are several cases of CSOs that use non-financial state support, most of the CSOs do not recognize the possibility and apply for such support.',
                  'There are numerous CSOs that use non-financial state support, and they recognize the possibility and apply for such support.',
                  'There are numerous CSOs that use non-financial state support, and they recognize the possibility and apply for such support. There are cases that go beyond best practices.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_4_practice_indicator_2',
                'Indicator 2',
                'CSOs are treated in an equal or more supportive manner compared to other actors when providing state non-financial resources.',
                [
                  'CSOs, especially the ones carrying out public benefit activities, receive support free of charge or at a lower than market rate. CSOs do not report cases of burdensome requirements (high rent fee or criteria which only a small group of CSOs can meet), preferential treatment of certain groups or different procedures each time.',
                ],
                [
                  `\nNumber of CSOs which agree that requirements for accessing non-financial support are easy to meet; `,
                  `\nNumber of CSOs which agree that they have an advantage over other actors when accessing non-financial support.`,
                ],
                [
                  `\nIllustrative case of CSOs facing burdensome requirements to access non-financial support.`,
                ],
                [
                  'CSOs are treated in an unequal or less supportive manner compared to other actors when providing state non-financial resources (certain groups can only access the support). The procedures for receiving non-financial support are seriously restricted and burdensome (high rent fee or criteria which only a small group of CSOs can meet).',
                  'CSOs are treated in an unequal or less supportive manner compared to other actors when providing state non-financial resources. The procedures for receiving non-financial support are restricted and burdensome (high rent fee or criteria which only a small group of CSOs can meet), yet CSOs find space to access and use non-financial resources.',
                  'CSOs are treated in an equal manner compared to other actors when providing state non-financial resources. The procedures for receiving non-financial support contain minor restrictions and certain difficulties.',
                  'CSOs are treated in an equal or more supportive manner compared to other actors when providing state non-financial resources. The procedures for receiving non-financial support is easy and effective.',
                  'CSOs are treated in an equal or more supportive manner compared to other actors when providing state non-financial resources. The procedures for receiving non-financial support is easy and effective. There are cases where best practices are surpassed.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_2_standard_4_practice_indicator_3',
                'Indicator 3',
                'There are no cases of state authorities granting non-financial support only to CSOs which do not criticize its work; or of cases of depriving critical CSOs of support or otherwise discriminating based on loyalty, political affiliation or other unlawful terms.',
                [
                  'The process of granting non-financial support to CSOs is not politically biased and different organizations, including organizations which are critical to the government, may benefit from it.',
                  'There are no examples of organizations that have received property or other type of support under a previous government being expelled before the end of their contract because the new government associates them with a different political power.',
                ],
                [
                  `\nReport cases of CSOs being deprived from or not given non-financial support due to their political affiliation/ critical stance. `,
                  `\nNumber of CSOs which agree that organizations critical to the government can access non-financial state support; `,
                  `\nNumber of CSOs which agree that decisions for allocation of non-financial support are fair.`,
                ],
                [
                  `\nReport if organizations which reported receipt of non-financial support in the survey/ or those that signed contracts with state institutions (data for indicator 1 of this standard) work in diverse fields and there are some which are critical to the government.`,
                ],
                [
                  'The process of granting non-financial support to CSOs is severely restricted and politically biased and those CSOs critical to the government are not supported. There are numerous examples of CSOs that have received property or other type of support under a previous government being expelled before the end of their contract because the new government associates them with a different political power (discrimination based on loyalty, political affiliation or other unlawful terms).',
                  'The process of granting non-financial support to CSOs is restricted, to a certain extent politically biased and those CSOs critical to the government are not supported, however from time to time there are very few CSOs that might receive support. There are few examples of CSOs that have received property or other type of support under a previous government being expelled before the end of their contract because the new government associates them with a different political power (discrimination based on loyalty, political affiliation or other unlawful terms).',
                  'In the process of granting non-financial support to CSOs, there are few cases with minor restriction (including political bias, lack of support for those CSOs critical to the government), however mostly CSOs are properly supported. There are very few examples of CSOs that have received property or other type of support under a previous government being expelled before the end of their contract because the new government associates them with a different political power (discrimination based on loyalty, political affiliation or other unlawful terms).',
                  'The process of granting non-financial support to CSOs is not politically biased and different organizations, including organizations which are critical to the government, may benefit from it. There are no examples of organizations that have received property or other type of support under a previous government being expelled before the end of their contract because the new government associates them with a different political power.',
                  'The process of granting non-financial support to CSOs is not politically biased and different organizations, including organizations which are critical to the government, may benefit from it. There are no examples of organizations that have received property or other type of support under a previous government being expelled before the end of their contract because the new government associates them with a different political power. Furthermore, there are examples of surpassing best practices.',
                ]
              ),
            ],
          },
        ],
      },
      {
        code: 'area_2_sub_area_2_3',
        codeName: 'Sub-Area 2.3',
        name: 'HUMAN RESOURCES',
        principle:
          'State policies and the legal environment stimulate and facilitate employment, volunteering and other engagements with CSOs',
        standards: [
          {
            code: 'area_2_sub_area_2_3_standard_1',
            codeName: 'STANDARD 2.3.1',
            originalName: 'Employment in CSOs',
            name: 'CSOs are treated in an equal manner to other employers.',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_3_standard_1_legislation_indicator_1',
                'Indicator 1',
                'CSOs are treated in an equal manner to other employers by law and policies',
                [
                  'When CSOs employ people, there are no additional requirements or registrations for them as compared to commercial companies.',
                ],
                [],
                [],
                [
                  'The legislation contains provisions that restrict and/or severely hamper CSOs ability to employ people (by providing burdensome or restrictive requirements for registration as compared to commercial companies). Furthermore, CSOs are treated in an unequal manner to other employers (by providing burdensome requirements for registration as compared to commercial companies).',
                  'The legislation contains provisions that hamper CSOs ability to employ people and they are treated in an unequal manner to other employers (by providing burdensome requirements for registration as compared to commercial companies). However, CSOs have some space for operation.',
                  'The legislation enables CSOs to employ people, however there are still minor inconsistencies. CSOs are not recognized by the State as employer and treated in an equal manner to other employers, and for some provisions even in an unequal manner.',
                  'The legislation enables CSOs to employ people in line with international and MM standards. CSOs are treated in an equal manner to other employers.',
                  'The legislation enables CSOs to employ people in line with international and MM standards and there are cases when the standards are surpassed. CSOs are treated in an equal manner to other employers, and through some policies they are even further targeted and supported as employers by the State.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_3_standard_1_practice_indicator_1',
                'Indicator 1',
                'If there are state incentive programs for employment, CSOs are treated like all other sectors.',
                [
                  'CSOs are not excluded from different incentive programs for employment and they can receive support from the state on an equal basis to other sectors of the economy. Examples of incentive programs include supported employment of representatives of vulnerable groups, tax incentives for employers of representatives of vulnerable groups, supported practice in CSOs of newly graduated students, support for employment of graduates, subsidies for employing long-term/first-time employees, non-financial support, etc.',
                ],
                [
                  `\nNumber of CSOs that benefited from employment support programs vs. number of other entities that benefited from these programs. `,
                  `\nNumber of CSOs that report using state incentive programs for employment.`,
                ],
                [
                  `\nCases of supporting schemes from which CSOs are excluded in practice (not eligible or have high barriers for access).`,
                ],
                [
                  'Almost all of the CSOs are reporting of being excluded from different incentive programs for employment and cannot receive support from the state on an equal basis to other sectors of the economy. (e.g. supported employment of representatives of vulnerable groups, tax incentives for employers of representatives of vulnerable groups, supported practice in CSOs of newly graduated students, support for employment of graduates, subsidies for employing long-term/first-time employees, non-financial support, etc.).',
                  'Most of the CSOs are reporting of being excluded from different incentive programs for employment and face serious difficulties in receiving support from the state, and on unequal basis to other sectors of the economy. (e.g. supported employment of representatives of vulnerable groups, tax incentives for employers of representatives of vulnerable groups, supported practice in CSOs of newly graduated students, support for employment of graduates, subsidies for employing long-term/first-time employees, non-financial support, etc.).',
                  'There are few cases of CSOs reporting of being excluded from different incentive programs for employment and face minor difficulties in receiving support from the state, particularly when compared to other sectors of the economy. (e.g. supported employment of representatives of vulnerable groups, tax incentives for employers of representatives of vulnerable groups, supported practice in CSOs of newly graduated students, support for employment of graduates, subsidies for employing long-term/first-time employees, non-financial support, etc.).',
                  'There are no cases of CSOs report being excluded from using different incentive programs for employment and they easily receive the support from the state, particularly when compared to other sectors of the economy. (e.g. supported employment of representatives of vulnerable groups, tax incentives for employers of representatives of vulnerable groups, supported practice in CSOs of newly graduated students, support for employment of graduates, subsidies for employing long-term/first-time employees, non-financial support, etc.).',
                  'All CSOs are able to use different incentive programs for employment and they easily receive the support from the state, particularly when compared to other sectors of the economy. There are cases of best practices being surpassed.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_1_practice_indicator_2',
                'Indicator 2',
                'There are regular statistics on the number of employees in the non-profit sector.',
                [
                  'The national statistics collects information on the employed people in the civil society sector, as well as other information relevant to CSOs. ',
                  'The information could include full-time employees, part-time employees, consultants or people with short-term contracts and volunteers.',
                ],
                [
                  `\nReport if the national statistical office collects information on employment in the non-profit sector (Yes/No).`,
                  `\nReport the type of statistical data collected by the state i.e. report if it includes the following: aggregate number of employees; number of full-time employees, part-time employees, consultants or people with short-term contracts and volunteers.`,
                ],
                [],
                [
                  'The State does not collect and/or publish quality and reliable national statistics on the number of employed people in civil society sector (e.g. aggregate number of employees; number of full-time employees, part-time employees, consultants or people with short-term contracts and volunteers), nor any other relevant information to CSOs.',
                  'The State collects and/or publishes national statistics on the number of employed people in civil society sector and some further information to CSOs with substantial challenges in terms of quality and reliability. (e.g. aggregate number of employees; number of full-time employees, part-time employees, consultants or people with short-term contracts and volunteers).',
                  'The State collects and/or publishes certain quality and reliable national statistics on the number of employed people in civil society sector and some further information to CSOs with minor difficulties (e.g. some inconsistencies in numbers from one year to another, etc.).',
                  'The State continuously collects and/or publishes quality and reliable national statistics on the number of employed people in civil society sector and some further information to CSOs (e.g. aggregate number of employees; number of full-time employees, part-time employees, consultants or people with short-term contracts and volunteers).',
                  'The State continuously collects and/or publishes quality and reliable national statistics on the number of employed people in civil society sector and other relevant information to CSOs and there are cases of surpassing best practices.',
                ]
              ),
            ],
          },
          {
            code: 'area_2_sub_area_2_3_standard_2',
            codeName: 'STANDARD 2.3.2',
            originalName: 'Volunteering in CSOs',
            name: 'There are enabling volunteering policies and laws',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_3_standard_2_legislation_indicator_1',
                'Indicator 1',
                'Legislation stimulates volunteering and incorporates best regulatory practices, while at the same time allowing for spontaneous volunteering practices.',
                [
                  'The law contains minimum provisions necessary to protect volunteers and their organizations (definitions, rights and obligations, contractual provisions, tax and other types of benefits, liability rules) and leaves the rest to be defined by the parties. The law does not prevent spontaneous volunteering (for example by requiring a contract for every type of volunteering). Where a group of friends decide to clean their neighborhood, the law should not impose any burden on them such as preliminary registration, the need to have a contract with a hosting organization or the need to report to anybody. As a good regulatory practice, provisions which require registering volunteers with state authorities should not exist; it is enough if organizations keep records of volunteers and the time that they have spent volunteering.',
                ],
                [],
                [],
                [
                  'The legislation does not provide protection of the volunteers and their organizations (for e.g. definitions, rights and obligations, contractual provisions, tax and other types of benefits, liability rules). The legislation prevents spontaneous volunteering (for e.g. by requiring a contract for every type of volunteering).  There are provisions which require registering volunteers with state authorities. Thus the legislation is restrictive to the operation of CSOs and seriously hampers their work.',
                  'The legislation provides very limited protection of the volunteers and their organizations (for e.g. definitions, rights and obligations, contractual provisions, tax and other types of benefits, liability rules). The legislation contains certain provisions that limit spontaneous volunteering (for e.g. by requiring a contract for every type of volunteering). Thus the legislation is making difficult the operation of CSOs but will allow for some space for engaging with volunteerism.',
                  'The legislation provides protection of the volunteers and their organizations (for e.g. definitions, rights and obligations, contractual provisions, tax and other types of benefits, liability rules) with minor legal issues which are not regulated. The legislation enables spontaneous volunteering (for e.g. by requiring a contract for every type of volunteering) with minor inconsistencies and issues.',
                  'The legislation provides protection of the volunteers and their organizations (for e.g. definitions, rights and obligations, contractual provisions, tax and other types of benefits, liability rules) in line with international standards. The legislation enables spontaneous volunteering. There is no legal requirement for registering volunteers with state authorities; it is enough if organizations keep records of volunteers and the time that they have spent volunteering.',
                  'The legislation provides protection of the volunteers and their organizations (for e.g. definitions, rights and obligations, contractual provisions, tax and other types of benefits, liability rules) in line with international standards. The legislation enables spontaneous volunteering. There is no legal requirement for registering volunteers with state authorities; it is enough if organizations keep records of volunteers and the time that they have spent volunteering. There can be even cases of legislation surpassing standards and principles enshrined in MM.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_2_legislation_indicator_2',
                'Indicator 2',
                'There are incentives and state supported programs for the development and promotion of volunteering.',
                [
                  'The government can adopt policies and programs through which they promote volunteerism or provide special support and training for volunteers. It can also provide different incentives which are usually of two types – for the volunteers and for the organizations hosting or supporting volunteers. The incentives for volunteers include the possibility to use benefits (e.g. health insurance or social security), the right to receive reimbursement for covering expenses made in relation to volunteering (travel, accommodation, per diems, etc.) and exemption from income taxes on this reimbursement. The incentives for organizations could include a grants programs supporting volunteering engagement, providing non-financial support to volunteering organizations, etc. The law can prescribe that volunteers will receive a volunteering booklet or other type of record for the hours they have performed and types of services engaged in, which could be used as a reference for future engagements. ',
                ],
                [],
                [],
                [
                  'There are no incentives or state supported programs for the development and promotion of volunteering. Namely, the legislation if fully restrictive towards volunteerism, and seriously obstructs the work of CSOs.',
                  'There are very few incentives and unclear state supported programs for the development and promotion of volunteering. Namely, the legislation if restrictive towards volunteerism, however CSOs still have some space to promote and engage with volunteers.',
                  'There are certain incentives for both volunteers and organizations and state supported programs for the development and promotion of volunteering, with minor issues that are not regulated.',
                  'There are incentives for both volunteers and organizations and state supported programs for the development and promotion of volunteering. The incentives for volunteers include the possibility to use benefits (e.g. health insurance or social security), the right to receive reimbursement for covering expenses made in relation to volunteering (travel, accommodation, per diems, etc.) and exemption from income taxes on this reimbursement. The incentives for organizations could include a grants program supporting volunteering engagement, providing non-financial support to volunteering organizations, etc. The law can prescribe that volunteers will receive a volunteering booklet or other type of record for the hours they have performed and types of services engaged in, which could be used as a reference for future engagements.',
                  'There are incentives for both volunteers and organizations and state supported programs for the development and promotion of volunteering. The incentives for volunteers include the possibility to use benefits (e.g. health insurance or social security), the right to receive reimbursement for covering expenses made in relation to volunteering (travel, accommodation, per diems, etc.) and exemption from income taxes on this reimbursement. The incentives for organizations could include a grants program supporting volunteering engagement, providing non-financial support to volunteering organizations, etc. The law can prescribe that volunteers will receive a volunteering booklet or other type of record for the hours they have performed and types of services engaged in, which could be used as a reference for future engagements. There can be even cases of legislation surpassing standards and principles enshrined in MM.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_2_legislation_indicator_3',
                'Indicator 3',
                'There are clearly defined contractual relationships and protections covering organized volunteering.',
                [
                  'The law provides the basic regulation on what the responsibilities of volunteers and of organizations hosting volunteers are,  e.g. volunteers whose involvement exceeds a certain number of hours or is continuous over a certain period of time.',
                  'It defines what types of organizations can host organized volunteers. It stipulates where there needs to be a contract between the volunteer and the organization, but it leaves it to the organizer and the volunteer to agree on the specific conditions of the volunteering engagement. For example, contracts should be required only for volunteering above certain hours a month (e.g., 20 hours) and not in cases where volunteering is spontaneous. ',
                  'However, the law should guarantee that volunteers are informed about the conditions, rights, obligations and benefits before the start volunteering so that they are aware of all the circumstances and particulars of the engagement. Mandatory insurance may be required for volunteering in places where certain injuries are more likely to occur, e.g., construction areas.',
                ],
                [],
                [],
                [
                  'There are no defined contractual relationships and protection covering organized volunteering. The law does not provide the basic regulation on what the responsibilities of volunteers and of organizations hosting volunteers are, e.g. volunteers whose involvement exceeds a certain number of hours or is continuous over a certain period of time. The law does not define what types of organizations can host organized volunteers. and does not stipulate where there needs to be a contract between the volunteer and the organization. The law does not guarantee that volunteers are informed about the conditions, rights, obligations and benefits before the start volunteering so that they are aware of all the circumstances and particulars of the engagement. Namely, the legislation if hampering volunteerism, and seriously obstructs the work of CSOs.',
                  'The contractual relationships and protection covering organized volunteering is legislated to a certain extent with major restrictions. The law provides regulation on what the responsibilities of volunteers and of organizations hosting volunteers are (e.g. volunteers whose involvement exceeds a certain number of hours or is continuous over a certain period of time) but its ambiguous. The law does not clearly define what types of organizations can host organized volunteers and does not stipulate where there needs to be a contract between the volunteer and the organization. The law does not guarantee that volunteers are informed about the conditions, rights, obligations and benefits before the start volunteering so that they are aware of all the circumstances and particulars of the engagement. Namely, the legislation if hampering volunteerism, however CSOs still have some space to promote and engage with volunteers.',
                  'The contractual relationships and protection covering organized volunteering is legislated with minor restrictions or issues which are not regulated. The law provides regulation on what the responsibilities of volunteers and of organizations hosting volunteers are (e.g. volunteers whose involvement exceeds a certain number of hours or is continuous over a certain period of time) with minor issues to be improved. The law defines what types of organizations can host organized volunteers and stipulates where there needs to be a contract between the volunteer and the organization, again with minor inconsistencies. The law guarantees that volunteers are informed about the conditions, rights, obligations and benefits before the start volunteering so that they are aware of all the circumstances and particulars of the engagement, with minor inconsistencies.',
                  'The contractual relationships and protection covering organized volunteering is legislated in line with international and MM standards. The law provides regulation on what the responsibilities of volunteers and of organizations hosting volunteers are (e.g. volunteers whose involvement exceeds a certain number of hours or is continuous over a certain period of time) with minor issues to be improved. The law defines what types of organizations can host organized volunteers and stipulates where there needs to be a contract between the volunteer and the organization, again with minor inconsistencies. The law guarantees that volunteers are informed about the conditions, rights, obligations and benefits before the start volunteering so that they are aware of all the circumstances and particulars of the engagement.',
                  'The contractual relationships and protection covering organized volunteering is legislated in line with international and MM standards. The law provides regulation on what the responsibilities of volunteers and of organizations hosting volunteers are (e.g. volunteers whose involvement exceeds a certain number of hours or is continuous over a certain period of time) with minor issues to be improved. The law defines what types of organizations can host organized volunteers and stipulates where there needs to be a contract between the volunteer and the organization, again with minor inconsistencies. The law guarantees that volunteers are informed about the conditions, rights, obligations and benefits before the start volunteering so that they are aware of all the circumstances and particulars of the engagement. There can be even cases of legislation surpassing standards and principles enshrined in MM.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_3_standard_2_practice_indicator_1',
                'Indicator 1',
                'Incentives and programs are transparent and easily available to CSOs and the policy, strategic document or law is being fully implemented, monitored and evaluated periodically in a participatory manner.',
                [
                  'Volunteers can use the existing incentives without the need to go through a complicated procedure. ',
                  'There are real incentives for supporting volunteerism which work in practice e.g. grants programs. The incentives for volunteering and for organizations hosting volunteers are provided in a transparent way and the selection of the recipients of support is based on objective criteria. ',
                  'There is a state agency/ministry board which has a mandate to monitor and evaluate the implementation of the legislation and the different programs and incentives related to volunteering.',
                ],
                [
                  `\nNumber of CSOs which benefit from state programs for volunteering in the past year;`,
                  `\nInterviewees agree that the application procedure is easy;`,
                  `\nInterviewees agree that the procedure for provision of incentives for volunteers and hosting organizations is transparent.`,
                  `\nReport the presence of state body responsible for implementation of policy/strategic document/ law for volunteering and the existence of periodic monitoring and evaluation.`,
                ],
                [
                  `\nInterviewees agree that the procedure for provision of incentives for volunteers and hosting organizations is based on objective criteria.`,
                  `\nReport number of registered volunteers and % of change from previous year (increase in number of volunteers involved in the non-profit sector is suggestive of enabling environment for volunteering i.e. that programs are effective).`,
                ],
                [
                  'There are no CSOs which benefited from incentives. The procedure for using existing incentives by the volunteers is very complicated and does not function in practice. There are no CSOs that consider that incentives are provided in a transparent way and the selection of the recipients of support is based on objective criteria. There are no examples of state bodies responsible for implementation of policy/strategic document/ law for volunteering and the existence of periodic monitoring and evaluation.',
                  'There are very few CSOs which benefited from incentives. The procedure for using existing incentives by the volunteers is complicated and hardly functions in practice. There are very few CSOs that consider that incentives are provided in a transparent way and the selection of the recipients of support is based on objective criteria. There are very few state bodies responsible for implementation of policy/strategic document/ law for volunteering and the existence of periodic monitoring and evaluation.',
                  'There are several CSOs which benefited from incentives. The procedure for using existing incentives by the volunteers is complicated, however severe restrictions are not common and difficulties are reported. There are several CSOs that consider that incentives are provided in a transparent way and the selection of the recipients of support is based on objective criteria. There are several state bodies responsible for implementation of policy/strategic document/ law for volunteering and the existence of periodic monitoring and evaluation.',
                  'There are numerous CSOs which benefited from incentives. The procedure for using existing incentives by the volunteers is simple and well used in practice. There are numerous CSOs that consider that incentives are provided in a transparent way and the selection of the recipients of support is based on objective criteria. There are numerous state bodies responsible for implementation of policy/strategic document/ law for volunteering and the existence of periodic monitoring and evaluation.',
                  'There are numerous CSOs which benefited from incentives. The procedure for using existing incentives by the volunteers is simple and well used in practice. There are numerous CSOs that consider that incentives are provided in a transparent way and the selection of the recipients of support is based on objective criteria. There are numerous state bodies responsible for implementation of policy/strategic document/ law for volunteering and the existence of periodic monitoring and evaluation. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_2_practice_indicator_2',
                'Indicator 2',
                'Administrative procedures for organizers of volunteer activities or volunteers are not complicated and are without any unnecessary costs.',
                [
                  'The process for attracting volunteers or becoming a volunteer is not complicated and burdensome. Organizations can rely on volunteers without the need to register them. There is no requirement to register volunteers.',
                ],
                [
                  `\nNumber of organizations that agree that the administrative procedure for involving volunteers is easy vs. those do not agree;`,
                  `\nNumber of organizations that report that they had to register volunteers with the state.`,
                ],
                [
                  `\nReport cases of organizations that faced high costs and administrative barriers in involving volunteers.`,
                ],
                [
                  'The majority of CSOs consider that the administrative procedure for involving volunteers is very complicated and with high costs (e.g. numerous CSOs report that they need to register volunteers with the state). Thus the process for attracting volunteers or becoming a volunteer is very complicated and burdensome. These malpractices exist and are heavily affecting the work of CSOs.',
                  'Several CSOs consider that the administrative procedure for involving volunteers is very complicated and with high costs (e.g. numerous CSOs report that they need to register volunteers with the state). Thus the process for attracting volunteers or becoming a volunteer is somewhat complicated and burdensome. CSOs face several challenges but despite difficulties CSOs can still engage volunteers in practice.',
                  'Very few CSOs consider that the administrative procedure for involving volunteers is very complicated and with high costs. Thus the process for attracting volunteers or becoming a volunteer provides minor challenges in the work of CSOs.',
                  'The majority of CSOs consider that the administrative procedures for organizers of volunteer activities or volunteers are not complicated and are without any unnecessary costs.',
                  'The majority of CSOs consider that the administrative procedures for organizers of volunteer activities or volunteers are not complicated and are without any unnecessary costs. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_2_practice_indicator_3',
                'Indicator 3',
                'Volunteering can take place in any form; there are no cases of complaints of restrictions on volunteering.',
                [
                  'Spontaneous volunteering can take place and there are no examples of state authorities prohibiting it because of a lack of registration or approval.',
                  'There are no examples of state labor authorities requiring volunteers to have labor agreements or sanctioning organizations for the illegal use of a labor force.',
                ],
                [
                  `\nReport cases of sanctions or restrictions in cases when volunteers were engaged without contracts/ registration or state approval.`,
                  `\nNumber of organizations that report that there are sanctions for spontaneous volunteering (e.g. volunteering without state approval)`,
                ],
                [
                  `\nNumber of organizations which report that they were required to have labor agreements with volunteers.`,
                ],
                [
                  'There are numerous complaints on restrictions of volunteering (e.g. the form of volunteering is restricted, treatment of spontaneous volunteering as illegal use of labor force). There are numerous cases when the state is reporting cases of sanctions in cases when volunteers were engaged without contracts/registration or state approval. There are numerous examples of state labor authorities requiring volunteers to have labor agreements or sanctioning organizations for the illegal use of a labor force. Such restrictions are common and heavily affecting the work of CSOs.',
                  'There are several complaints on restrictions of volunteering (e.g. the form of volunteering is restricted, treatment of spontaneous volunteering as illegal use of labor force). There are several cases when the state is reporting cases of sanctions in cases when volunteers were engaged without contracts/registration or state approval. There are several examples of state labor authorities requiring volunteers to have labor agreements or sanctioning organizations for the illegal use of a labor force. CSOs are hampered but despite serious difficulties they can operate and engage volunteers.',
                  'There are very few complaints on restrictions of volunteering (e.g. the form of volunteering is restricted, treatment of spontaneous volunteering as illegal use of labor force). There are very few cases when the state is reporting cases of sanctions in cases when volunteers were engaged without contracts/registration or state approval. There are very few examples of state labor authorities requiring volunteers to have labor agreements or sanctioning organizations for the illegal use of a labor force.',
                  'There are no complaints on restrictions of volunteering (e.g. the form of volunteering is restricted, treatment of spontaneous volunteering as illegal use of labor force). There are no of the state reporting cases of sanctions in cases when volunteers were engaged without contracts/registration or state approval. There are no examples of state labor authorities requiring volunteers to have labor agreements or sanctioning organizations for the illegal use of a labor force.',
                  'There are no complaints on restrictions of volunteering (e.g. the form of volunteering is restricted, treatment of spontaneous volunteering as illegal use of labor force). There are no of the state reporting cases of sanctions in cases when volunteers were engaged without contracts/registration or state approval. There are no examples of state labor authorities requiring volunteers to have labor agreements or sanctioning organizations for the illegal use of a labor force. There are cases of best practices which surpass the standards and principles enshrined in MM standards.',
                ]
              ),
            ],
          },
          {
            code: 'area_2_sub_area_2_3_standard_3',
            codeName: 'STANDARD 2.3.3',
            originalName: 'Non-Formal Education',
            name: 'The educational system promotes civic engagement',
            legislation: [
              _generateIndicator(
                'area_2_sub_area_2_3_standard_3_legislation_indicator_1',
                'Indicator 1',
                'Non-formal education is promoted through policy/strategy/laws.',
                [
                  'Sustainability of human capital and general support and trust in civil society can be ensured through long term measures such as the promotion of civic engagement. There is a possibility for CSOs to take part of the informal education system. CSO-related topics are integrated in to informal education strategies and policies. ',
                ],
                [],
                [],
                [
                  'The legislation (policy/strategy/laws) does not allow non-formal education. There are no legal possibilities for CSOs to take part in the informal education system, nor CSO-related topics are integrated.',
                  'The legislation (policy/strategy/laws) allows for non-formal education, however under strict limitations. The legislation imposes severe restrictions over CSOs to take part in the informal education system. CSO-related topics are not integrated in the informal education strategies and policies.',
                  'The legislation (policy/strategy/laws) allows for non-formal education, however with minor inconsistencies and challenges. The legislation provides the opportunity for CSOs to take part in the informal education system, however they face minor difficulties. There are limited examples of CSO-related topics being integrated in the informal education strategies and policies.',
                  'The legislation (policy/strategy/laws) allows and promotes non-formal education, in line with international standards. The legislation provides the opportunity for CSOs to take part in the informal education system. There are numerous examples of CSO-related topics being integrated in the informal education strategies and policies.',
                  'The legislation (policy/strategy/laws) promotes non-formal education, in line with international standards and surpasses the best practices. The legislation encourages CSOs to take part in the informal education system. There are numerous examples of CSO-related topics being integrated in the informal education strategies and policies.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_3_legislation_indicator_2',
                'Indicator 2',
                'Civil society-related subjects are included in the official curriculum at all levels of the educational system.',
                [
                  'Both in schools and universities there are subjects which introduce pupils/students to civic engagement, philanthropy, civil society and other similar concepts.',
                ],
                [],
                [],
                [
                  'Civil society-related subjects are not allowed to be included in the official curriculum at all levels of the educational system.',
                  'Civil society-related subjects are allowed to be included in the official curriculum at all levels of the educational system, however with severe limitations.',
                  'Civil society-related subjects are allowed to be included in the official curriculum at all levels of the educational system, however with minor inconsistencies.',
                  'Civil society-related subjects are allowed to be included in the official curriculum at all levels of the educational system, in line with MM standards. Both schools and universities have subjects which introduce pupils/students to civic engagement, philanthropy, civil society and other similar concepts.',
                  'Civil society-related subjects are allowed to be included in the official curriculum at all levels of the educational system, in line with MM standards and they even surpass best practices. Both schools and universities have subjects which introduce pupils/students to civic engagement, philanthropy, civil society and other similar concepts.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_2_sub_area_2_3_standard_3_practice_indicator_1',
                'Indicator 1',
                'The educational system includes possibilities for civic engagement in CSOs.',
                [
                  'There are integrated programs for internships, fellowships or volunteerism with CSOs as part of the curriculum. ',
                  'Pupils/students are engaged in different social activities organized in partnership with CSOs.',
                ],
                [
                  `\nReport cases of volunteering possibilities with CSOs integrated within educational institutions (report the type of possibilities).`,
                ],
                [
                  `\nTotal number of programs for volunteering with CSOs (e.g. programs for internships, fellowships or volunteerism) for pupils and students.`,
                ],
                [
                  'There are no examples in the educational system for possibilities for civic engagement in CSOs. The state engages in severe restrictions when it comes to civic engagement in CSOs. There are no integrated programs for internships, fellowships or volunteerism with CSOs as part of the curriculum. Furthermore, pupils/ students are not engaged in different social activities organized in partnership with CSOs.',
                  'There are very few examples in the educational system where there are possibilities for civic engagement in CSOs. The state usually discourages civic engagement in CSOs. There are very few integrated programs for internships, fellowships or volunteerism with CSOs as part of the curriculum. Furthermore, pupils/ students are rarely engaged in different social activities organized in partnership with CSOs.',
                  'There are few examples in the educational system where there are possibilities for civic engagement in CSOs. The state enables civic engagement in CSOs, however there are few integrated programs for internships, fellowships or volunteerism with CSOs as part of the curriculum. Pupils/ students are sometimes engaged in different social activities organized in partnership with CSOs.',
                  'There are numerous examples in the educational system where there are possibilities for civic engagement in CSOs. The state encourages civic engagement in CSOs. There are numerous integrated programs for internships, fellowships or volunteerism with CSOs as part of the curriculum. Pupils/ students are usually engaged in different social activities organized in partnership with CSOs.',
                  'There are numerous examples in the educational system where there are possibilities for civic engagement in CSOs, that sometimes surpass the MM standards. The state actively promotes civic engagement in CSOs. Almost all parts of the educational system include integrated programs for internships, fellowships or volunteerism with CSOs as part of the curriculum. Pupils/ students are always encouraged to engage in different social activities organized in partnership with CSOs.',
                ]
              ),
              _generateIndicator(
                'area_2_sub_area_2_3_standard_3_practice_indicator_2',
                'Indicator 2',
                'Provision of non-formal education by CSOs is recognized.',
                [
                  'CSOs have the possibility to be recognized as training providers. Where there are specific requirements in order to become a trainer, CSOs can apply for such status or license.',
                ],
                [
                  `\Number of CSOs which are certified providers of training and non-formal education.`,
                  `\Prominent cases of CSOs that provide non-formal education.`,
                ],
                [],
                [
                  'There are no CSOs which are certified as providers of training and non-formal education by the state. There are no cases of CSOs, that provide non-formal education. The state engages in restrictive activity towards CSOs that consider providing non-formal education.',
                  'There are very few cases of CSOs being certified as providers of training and non-formal education by the state. There are very few cases of CSOs, that provide non-formal education. However, in practice, the state does not encourage CSOs to provide non-formal education.',
                  'There are several cases of CSOs being certified as providers of training and non-formal education by the state. There are few cases of CSOs, that provide non-formal education.',
                  'There are numerous cases of CSOs being certified as providers of training and non-formal education by the state. There are numerous cases of CSOs, that provide non-formal education.',
                  'There are numerous cases of CSOs being certified as providers of training and non-formal education by the state. There are numerous cases of CSOs, that provide non-formal education. There are cases of surpassing best practices.',
                ]
              ),
            ],
          },
        ],
      },
    ],
  },
  {
    code: 'area_3',
    codeName: 'Area 3',
    name: 'Government-CSO Relationship',
    subAreas: [
      {
        code: 'area_3_sub_area_3_1',
        codeName: 'Sub-Area 3.1',
        name: 'FRAMEWORK AND PRACTICES FOR COOPERATION',
        principle:
          'There is a strategic approach to furthering state-CSO cooperation and CSO development',
        standards: [
          {
            code: 'area_3_sub_area_3_1_standard_1',
            codeName: 'STANDARD 3.1.1',
            originalName:
              'State Policies and Strategies for Development of and Cooperation with Civil Society',
            name: 'The State recognizes, through policies and strategies, the importance of the development of and cooperation with the sector.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_legislation_indicator_1',
                'Indicator 1',
                'There are strategic documents dealing with the state-CSO relationship and CSDev.',
                [
                  'Civil society development and partnership with CSOs can be included in more general strategic documents e.g. the country development plans. Many countries, though, adopt specific documents related to civil society. These include unilateral documents (government strategies for CSDev which stipulate undertakings only for the government) or bilateral agreements (compacts, programs) which detail undertakings for both parties. All these documents should be developed together and formally approved by the government or parliament. These documents can also be adopted by line ministries for the specific cooperation with CSOs in their field of competence, in addition to or instead of one general document adopted by government or parliament. The existence of such documents provides the framework for overall cooperation, and details the priorities for support over a period of a year. In general, it is considered that the existence of such documents support the promotion of policies and laws for an enabling environment. ',
                ],
                [],
                [],
                [
                  'There is no specific state document that regulates the relationship between state and CSOs nor programs or strategic documents focused on an enabling environment for civil society.',
                  'There is no specific state document that regulates the cooperation between state and CSO in general, however few ministries/state authorities (Ministry of Culture, Ministry of Education etc.) have adopted documents for cooperation with CSOs and/or enabling environment for civil society.',
                  'There is a state document adopted by the Government/Assembly that provides the framework for overall cooperation. However, the document is rather vague and does not detail priorities for support.',
                  'There is a state document adopted by the Government/Assembly that provides the framework for overall cooperation, details the priorities for support over a period of a year, and promotes an enabling environment for civil society development.',
                  'There is a state document that regulates the overall cooperation between state and CSOs, while certain line ministries/state authorities have developed specific document for specific cooperation with CSOs in their field of competence.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_legislation_indicator_2',
                'Indicator 2',
                'The strategic document includes goals and measures as well as funding available and clear allocation of responsibilities (action plans including indicators).',
                [
                  'The strategic documents are usually accompanied by implementation/action plans which prescribe specific measures and actions to be taken, the responsible authorities, and provide the timeframe in which that should happen. It is very important that the implementation of activities under the strategies is supported financially from the state budget through specific budget allocations for the plan.',
                ],
                [],
                [],
                [
                  'There is a strategic document that provides a framework for cooperation between state and CSOs, however the document does not have an action plan, nor allocated funding and staff for its implementation.',
                  'There is a strategic document that provides a detailed action plan for the implementation of the framework for cooperation between state and CSOs. However, there is insufficient staff for its implementation and no specific budget is allocated.',
                  'The strategic document for cooperation with CSOs has a detailed action plan which prescribes specific measures and actions to be taken, the responsible authorities, timeframe in which that should happen, sufficient human resources for its implementation. However, there is no specific budget allocated.',
                  'The strategic document for cooperation with CSOs has a detailed action plan which prescribes specific measures and actions to be taken, the responsible authorities, timeframe in which that should happen, and specific budget and staff allocated for its implementation.',
                  'The strategic document for cooperation with CSOs has a detailed action plan which prescribes specific measures and actions to be taken, the responsible authorities, timeframe in which that should happen, and specific state budget and staff allocated for its implementation. The state regularly reviews the implementation of the action plan and decides for additional measures accordingly.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_legislation_indicator_3',
                'Indicator 3',
                'The strategic document embraces measures that have been developed in consultation with and/or recommended by CSOs.',
                [
                  'A true document on CSDev and/or partnership between CSOs and the state body cannot be developed without the participation of CSOs. Good policy-making requires that CSOs are included in the planning and drafting process from the very beginning. It is important that the process of inclusion of CSOs is public and transparent and it reflects the needs of most CSOs.',
                ],
                [],
                [],
                [
                  'The strategic document on CSDev has been drafted by state authorities in a non-transparent manner and does not reflect the needs of CSOs.',
                  'The strategic document on CSDev has been drafted by state authorities in a non-transparent manner with including only a targeted group of CSOs. The document does not reflect the needs of most CSOs.',
                  'The strategic document on CSDev has been drafted through an inclusive and transparent process, however the contribution of CSOs has not been taken into account in most of the cases.',
                  'The strategic document on CSDev has been drafted through an inclusive and transparent process and reflects the needs of most CSOs.',
                  'The strategic document on CSDev has been drafted through an inclusive and transparent process, while state authorities have considered additional, targeted consultations with marginalized groups. The document reflects the needs of most CSOs.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_practice_indicator_1',
                'Indicator 1',
                'CSOs from different areas of interest regularly participate in all phases of the strategic document development, implementation and evaluation.',
                [
                  'In addition to being developed with CSOs, the strategic document should involve CSOs in its implementation and monitoring. There should be attempts for this process to be as inclusive as possible and to involve CSOs from the main areas of the CSO sector. Joint reports on the implementation of the document would be one more concrete indicator that CSOs have been considered and consulted in implementation and assessment.',
                ],
                [
                  `\nNumber and types of organizations which participated in the consultation phase for the making of the Strategy; `,
                  `\nNumber of consultation meetings held (including those held outside of the capital city) and presence of opportunities for alternative input (via email; online consultation);`,
                  `\nReport if CSOs were included in activities for joint implementation of the strategy;`,
                  `\nReport if CSOs were included in monitoring and evaluation activities (e.g. reports on the strategy implementation include CSOs voices). `,
                ],
                [
                  `\nReport presence of open call for participation in consultation phase;`,
                  `\nState reports on the extent to which CSOs comments in the consultation phase where integrated in the final document;`,
                  `\nNumber and types CSOs included in implementation and assessment (type refers to organizational size, geographical location, core mission and policy field).`,
                ],
                [
                  'Development of the strategic document, implementation, monitoring and its evaluation is solely done by state authorities without the inclusion of CSOs.',
                  'CSOs from different areas of interest participate only in the later stages of the drafting process of the strategic document.',
                  'CSOs from different areas of interest participate in early stages of the drafting process of the strategic document.',
                  'CSOs from different areas of interest participate in early stages of the drafting process of the strategic document, and in the implementation and monitoring of the strategic document, while the evaluation of progress is assessed through a joint body composed of state officials and representatives of CSOs.',
                  'CSOs from different areas of interest are involved in the implementation and monitoring of the strategic document, while the evaluation of progress is assessed by CSOs.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_practice_indicator_2',
                'Indicator 2',
                'There are examples demonstrating that cooperation between state and CSOs and CSDev is improved and implemented according to or beyond the measures envisaged in the strategic document.',
                [
                  'Cooperation by the state with CSOs should become rooted in the operations of state institutions. It should be an integral part in the development of policies and legislation. There should be no examples of cooperation limitation e.g. by setting up consultative councils which are not used in the policy-making or involving CSOs in consultation processes just so that they can report that there is cooperation. There are reports and examples which show that the government and CSOs have undertaken activities together, or that the government has implemented obligations in adopted strategic documents.',
                ],
                [
                  `\nNumber of CSOs that report cooperation with state institutions in policymaking/ legislation drafting and activities `,
                  `\nReport cases of pro-forma cooperation of CSOs with the state (e.g. rubber-stamping bodies or marginalization of established bodies for CSO consultation).`,
                ],
                [
                  `\nNumber of targets achieved from the Strategy for cooperation with CSOs (self-reported by state institutions in monitoring report or external evaluation).`,
                ],
                [
                  'CSOs are not involved in the policy making processes as envisaged by the strategic document.',
                  'Only a targeted group of CSOs participate in policy making processes so the government could report that there is cooperation with CSOs.',
                  'CSOs participate in the majority of policy making processes, however there are cases that limit the participation of targeted CSOs.',
                  'CSOs participate in all policy making processes without any limitations and through meaningful consultation processes as envisaged by the strategic document. Moreover, there are joint initiatives where state authorities and CSOs have specific responsibilities.',
                  'CSOs participate in all policy making processes without any limitations and through meaningful consultation processes, surpassing principles as envisaged by the strategic document. Moreover, there are joint initiatives where state authorities and CSOs have specific responsibilities.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_practice_indicator_3',
                'Indicator 3',
                'The implementation of the strategic document is monitored, evaluated and revised periodically.',
                [
                  'There is a need to regularly monitor the implementation of the actions/ measures envisioned in the strategic document. This should happen annually, while a more general evaluation could be undertaken after a longer implementation period (e.g. 3 to 5 years). Based on such monitoring and evaluation, the strategic document should be revised to reflect the changes in the environment and make sure the document is up-to-date. The responsible body for implementation of the document should have developed guidance for monitoring and reporting on the results for other actors. CSOs should be part of the process.',
                ],
                [
                  `\nReport the last instance of annual review of strategy; `,
                  `\nReport the last instance of annual and periodic (3-5 years) monitoring and evaluation of the strategy; `,
                  `\nReport last update of the strategy based on annual and/ or periodic evaluation.`,
                ],
                [],
                [
                  'There is no system or guidance in place for regular monitoring and reporting on implementation of the strategic document.',
                  'The responsible body for implementation of the document has developed guidance for monitoring and reporting, but does not conduct regular and thorough reporting nor do CSOs get engaged in this process.',
                  'The responsible body for implementation of the document has developed guidance and conducts periodic monitoring and reporting to measure progress. However, findings from the monitoring process are not used to advance the implementation of the document.',
                  'The responsible body for implementation of the document has developed guidance and conducts periodic monitoring and reporting to measure progress and reflect changes in the environment. CSOs participate in the monitoring and reporting process also.',
                  'The responsible body for implementation of the document and CSOs jointly monitor and provide periodic reports on the progress of implementation while revising the document as needed in order to reflect changes in the environment.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_1_practice_indicator_4',
                'Indicator 4',
                'State policies for cooperation between state and CSOs and CSDev are based on reliable data collected by the national statistics taking into consideration the diversity of the sector.',
                [
                  'In order for the state policy for civil society to respond to the needs of the sector, it should be based on reliable data. This data should be collected by the national statistics or reports on the development of the sector by different organizations and donors. The information collected should be able to give answer to questions including (but not limited to): How many CSOs are active? In what sectors do they operate? How many people are employed by the sector full-time and part-time? How many volunteers are engaged with CSOs? What are the income sources of CSOs? What is their income? What is the product prepared by the sector? What are the main obstacles to the functioning of different sub-sectors? ',
                ],
                [
                  `\nReport evidence that policymakers when devising CSO strategy (CSDev policies) rely on comprehensive statistical data on the state of the sector.  `,
                ],
                [
                  `\nReport evidence that policymakers when devising CSO strategy (CSDev policies) rely on comprehensive statistical data on the state of the sector.
                     \nNumber of CSOs active;
                     \nAreas of work;
                     \nNumber of employees - full-time and part time, number of volunteers (Note: The availability of these statistics should be known to the researcher due to Sub-area 2.3.);
                     \nTotal income of the CSOs sector;
                     \nTypes of output;
                     \nMain barriers/ challenges to operation across sectors.`,
                ],
                [
                  'There are no reliable data for CSOs and CSDev in general.',
                  'There are reliable data for CSOs and CSDev, but the state does not use them when considering policies related to cooperation with CSOs.',
                  'There are reliable data for CSOs and CSDev, but do not cover all [D6] necessary information required for informed decision-making.',
                  'State policies for cooperation with civil society are based on reliable data provided by national statistics reports and different CSOs and donor organizations.',
                  'State policies for cooperation with civil society are based on reliable data provided by national statistics reports and different CSOs and donor organizations. All data are publicly available to interested parties in proper and easily accessible format.',
                ]
              ),
            ],
          },
          {
            code: 'area_3_sub_area_3_1_standard_2',
            codeName: 'STANDARD 3.1.2',
            originalName:
              'Institutions and Mechanisms for Development of and Cooperation with Civil Society',
            name: 'The State recognizes, through the operation of its institutions, the importance of the development of and cooperation with the sector.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_1_standard_2_legislation_indicator_1',
                'Indicator 1',
                'There is a national level institution or mechanism with a mandate to facilitate cooperation with CSOs (e.g., Unit/Office for cooperation; contact points in ministries; council). ',
                [
                  'In addition to being rooted in every administration and part of the government, the policy towards CSOs should be part of the mandate of a government ministry or agency. In addition, such policy could be supported by different mechanisms in order for its practical implementation and facilitation.',
                  ` \nExamples of such mechanisms include:
                    \nParliamentary committee dealing with civil society issues;
                    \nSeparate government agency/office dealing specifically, with civil society issues;
                    \nConsultative body/council consisting of representatives of both government and CSOs focusing especially on CSDev;
                    \nSeparate departments/contact points in different government agencies assisting CSOs.
                  `,
                  ' When consultative bodies are formed in general, a secretariat or administrative officer will be appointed to facilitate the work of such bodies and to make sure that they can achieve practical results.',
                ],
                [],
                [],
                [
                  'There is no institution or mechanism in place that is responsible to facilitate cooperation with CSOs.',
                  'There is a mechanism/institution at the national level responsible for cooperation with CSOs, however it does not have a clear mandate.',
                  'There is a mechanism/institution at the national level that is responsible to facilitate the cooperation with CSOs, but does not cover all levels of governance.',
                  'There is a mechanism/institution at the national level that is responsible to facilitate the cooperation with CSOs, covering all levels of governance.',
                  'There are targeted state mechanisms in place responsible for cooperation with CSOs per each level of governance.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_2_legislation_indicator_2',
                'Indicator 2',
                'There are binding provisions on the involvement of CSOs in the decisions taken by the competent institution or mechanism(s).',
                [
                  'Regardless of the type of mechanism chosen by the respective country, as a good practice CSOs should be consulted in decisions undertaken by the body which have an affect their work. E.g., CSOs should be able to take part in the acts it prepares which will define the engagement of the body with the sector (e.g., annual program, projects, etc). To foster this, regulations governing the work of these institutions should contain rules about CSO engagement and consultation. Where they are participating in a cross-sector body (e.g. a joint council, committee etc), they should be at least equal in number to the government representatives or be able to veto decisions which they do not support.',
                ],
                [],
                [],
                [
                  'There are no binding provisions on the involvement of CSOs in the decisions taken by the state authorities for policies/documents that affect CSOs’ work and engagement in policy making.',
                  'There are binding provision on the involvement of CSOs in the decisions taken by the state authorities for policies/documents that affect CSOs’ work and engagement in policy making but only for certain governance levels;',
                  'There are binding provisions on the involvement of CSOs in the decisions taken by the state authorities for policies/documents that affect CSOs’ work and engagement in policy making but do not guarantee equal representation in decision making bodies.',
                  'There are binding provisions on the involvement of CSOs in the decisions taken by the state authorities for policies/documents that affect CSOs’ work and engagement in policy making, while also providing equal representation in decision making bodies.',
                  'There are binding provisions on the involvement of CSOs in the decisions taken by the state authorities for policies/documents that affect CSOs’ work and engagement in policy making, while also providing overrepresentation of CSOs in decision making bodies.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_1_standard_2_practice_indicator_1',
                'Indicator 1',
                'The national level institution or mechanism(s) has sufficient resources and mandate for facilitating CSO-government dialogue, discussing the challenges and proposing the main policies for the development of civil society.',
                [
                  'The institution in charge of CSO policies has sufficient leverage within the government and its decisions are respected and followed by the ministries to which they are addressed. The institution can communicate with the CSOs without obstacles, it can invite them to meetings or attend their events without a need to obtain prior authorization from another government agency. For councils or commissions in which multiple government agencies participate, the level of the participating administration should be high enough to guarantee that the state representatives have decision-making power and what they decide will be followed by the institutions they represent. The body should also receive sufficient funding to make sure its decisions and activities can be implemented in practice. In addition, it should possess qualified staff members that understand the problems CSOs face and have expertise in the needs of the sector. Ideally, some of these officials should have a CSO background.',
                ],
                [
                  `\nReport any constraints in the work of the mechanism/institution;`,
                  `\nReport amount of allocated budget and % of change from last year;`,
                  `\nNumber of employees in the government institution/mechanism and change from last year;`,
                  `\nNumber of government representatives in the Council and their mandate;`,
                ],
                [
                  `\nReport cases of institution/ mechanism's proposals not being accepted/implemented by ministries/ government in the past year;`,
                  `\nEmployees/ representatives of the institution agree that the annual budget allocated to the institution/mechanism responds to their needs; `,
                  `\nEmployees/ representatives agree that human resources are sufficient/ adequate;`,
                  `\nNumber of employees with CSO background.`,
                ],
                [
                  'The national level institution or mechanism(s) do not have sufficient resources and clear mandate for facilitating CSO-government dialogue.',
                  'The national level institution or mechanism(s) have clear mandate for facilitating CSO-government dialogue but cannot communicate freely with CSOs, invite them to meetings or attend their activities without prior permission by a higher state authority.',
                  'The national level institution or mechanism(s) possess resources and can communicate with CSOs without prior permission by another state authority, but they do not have a decision-making power.',
                  'The national level institution or mechanism(s) possess qualified staff and can communicate with CSOs without prior permission by another state authority, while also having a decision-making power and proper funding corresponding to the functions of the body.',
                  'The national level institution or mechanism(s) possess qualified staff and can communicate with CSOs without prior permission by another state authority, while also having a decision-making power and proper funding corresponding to the functions of the body. Also, the institution/mechanism provides regular training to its staff on CSDev topics.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_1_standard_2_practice_indicator_2',
                'Indicator 2',
                'CSOs are regularly consulted and involved in processes and decision making by the competent institution or mechanism(s).',
                [
                  'There are examples of CSOs taking part in working groups, and all relevant decisions of the body are taken with CSO participation. ',
                  'Most of the decisions come out of CSO recommendations or are supported by CSOs.',
                ],
                [
                  `\nNumber and frequency of meetings organized which involve CSOs in the past year; `,
                  `\nNumber of CSOs that agree that they are informed about the work of the mechanism/institution;`,
                  `\nNumber of CSOs that agree that decisions by the mechanism/ institution are dominantly based on CSO input.`,
                ],
                [
                  `\nThe number of CSOs that participated (online or offline);`,
                  `\nNumber of CSOs which agree that all relevant decisions of the body are taken with CSO participation.`,
                ],
                [
                  'CSOs are not consulted and/or involved in decision making processes by the competent institution/mechanism.',
                  'There are rare cases where CSOs are consulted or involved in decision making processes by the competent institution/mechanism',
                  'In the majority of processes CSOs are consulted or involved in decision making processes by the competent institution/mechanism.',
                  'CSOs participate in all processes of decision making, while in the majority of the processes CSOs take part in working groups. Most of the decision that come out of the competent institution/mechanism are recommended by CSOs or supported by them.',
                  'CSOs participate in all processes of decision making, while also taking part in all working groups. Most of the decision that come out of the competent institution/mechanism are recommended by CSOs, while no decision could be made without votes/support by CSOs.',
                ]
              ),
            ],
          },
        ],
      },
      {
        code: 'area_3_sub_area_3_2',
        codeName: 'Sub-Area 3.2',
        name: ' INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
        principle:
          'CSOs are effectively included in the policy and decision-making process',
        standards: [
          {
            code: 'area_3_sub_area_3_2_standard_1',
            codeName: 'STANDARD 3.2.1',
            originalName: 'Standards for CSO Involvement',
            name: 'There are standards enabling CSO involvement in decision-making, which allow for CSO input in a timely manner.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_legislation_indicator_1',
                'Indicator 1',
                'There are clearly defined standards on the involvement of CSOs in the policy and decision making processes in line with best regulatory practices prescribing minimum  requirements which every policy-making process needs to fulfill.',
                [
                  'The legal framework should put forward a requirement for consultation on draft laws and policies with the public. CSOs should be involved from the very beginning of the legislative/policy process. Each policymaking process, in addition to other forms of consultation, should be subject to written consultation in which the text of the act is made publicly available and people are allowed to provide written comments to it. CSOs and the general public should be given a reasonable time to be able to acquaint themselves with the proposal and form an opinion. The legal framework should require written feedback on the received proposals with explanations why certain of them have not been accepted. CSOs should also have access to the discussions of the drafts developed and be given a chance to defend their positions. The legal framework should provide options for redress if the provisions for consultations are not respected. ',
                  'The above principles should be part of the basic legal provisions for consultation on draft laws and policies. In addition, the government/Parliament could adopt a stand-alone law, guidelines or code on public consultations. In the case of non-binding documents, particular measures should be taken to ensure that it is followed (e.g. more investment in raising awareness about the provisions, monitoring and training). Finally, such rules should also apply to participation in monitoring and evaluation of policy/law implementation and its effects.',
                ],
                [],
                [],
                [
                  'There are no standards on the involvement of CSOs in policy and decision making processes.',
                  'There are defined general principles on the involvement of CSOs in the policy and decision making processes.',
                  'There are clearly defined standards on the involvement of CSOs in the policy-making processes but they are not in line with the best regulatory practices and/or not mandatory for all policy making processes.',
                  'There is a basic legal framework that clearly defines standards on the involvement of CSOs in all policy-making processes, and in line with the best regulatory practices.',
                  'There is a basic legal framework that clearly defines standards on the involvement of CSOs in all policy-making processes, while line ministries/other public institutions have adopted specific consultation mechanisms for better outreach.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_legislation_indicator_2',
                'Indicator 2',
                'State policies provide for educational programs/training for civil servants on CSO involvement in the work of public institutions.',
                [
                  'Organizing public consultations requires specific knowledge and skills. Public servants engaged in the process of policymaking should undergo targeted trainings to acquire those as part of their on-the-job training. Such events could contain training on how to invite CSOs, how to draft questions for comments, what forms to use for consultation etc.',
                ],
                [],
                [],
                [
                  'There are no policies or training programs for civil servants on CSOs involvement in the work of public institutions.',
                  'There are general training programs for civil servant on public policy making covering also public consultations.',
                  'There are training programs for civil servants on CSO involvement in the work of public institutions but not in line with best regulatory practices and public consultation policies in place.',
                  'There are state policies providing regular, targeted training programs for civil servants on CSO involvement in the work of public institutions.',
                  'There are state policies and on-demand helpdesk services providing regular capacity building programs and information provision for civil servants on CSO involvement in the work of public institutions.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_legislation_indicator_3',
                'Indicator 3',
                'Internal regulations require specified units or officers in government, line ministries or other government agencies to coordinate, monitor and report CSO involvement in their work.',
                [
                  'Such officers/units organize the consultative processes of their respective agencies. They also monitor the level of involvement and report on the consultation processes organized. The existence of such a unit or officer also makes it easier for the public and CSOs to get in contact with the responsible state body to inquire further about the plans for consultation and engagement, and adoption of the document.',
                ],
                [],
                [],
                [
                  'There are no legal requirements for line ministries/other government institutions for having a specified unit or officers for coordinating, monitoring and reporting CSO involvement in their work.',
                  'Internal regulations require specified officers in government, line ministries or other government agencies to coordinate CSO involvement in their work, but are not accessible for contact to public/CSOs.',
                  'Internal regulations require specified officers in government, line ministries or other government agencies to coordinate CSO involvement in their work.',
                  'Internal regulations require specified units or officers in government, line ministries or other government agencies to coordinate, monitor, report CSO involvement in their work, and be accessible to public for information regarding CSO engagement in the work of public institutions.',
                  'Internal regulations require specified units or officers in government, line ministries or other government agencies to coordinate, monitor, report CSO involvement in their work, and be accessible to public for information regarding engagement in the work of public institutions. Internal regulations also require for specified units or officers to communicate regularly for planning purposes with their target groups/stakeholders.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_practice_indicator_1',
                'Indicator 1',
                'Public institutions routinely invite all interested CSOs to comment on policy/ legal initiatives at an early stage.',
                [
                  'There are numerous examples of policy initiatives to which CSOs are invited for consultation. Such consultation happens in the early stages in order for the CSO input to be considered when deciding the general direction of important initiatives. CSOs are given sufficient time to prepare an opinion (at least a weak advance notice for meetings) and are also given a chance to express it (e.g. they are given the floor). An ideal scenario would include at least 15-20 working days during which CSOs can present their opinion. There are no complaints about the lack of consultation on drafts or intensive law drafting in expedited procedures.',
                ],
                [
                  `\nNumber (or %) of public consultations held out of total number of passed laws/ bylaws. `,
                  `\nNumber of CSOs that report invitation and involvement in consultation over policy/ legislation.  `,
                  `\nReport % of CSOs of those that were consulted, which report involvement in an early stage of legislation drafting; `,
                  `\n% of involved CSOs which report that sufficient time to prepare and submit comments (around 15-20 days) was given.  `,
                ],
                [
                  `\nNumber of laws/bylaws and strategies for which working groups were established for their preparations and CSOs representatives were included`,
                  `\nCases of complaints on lack of consultation on drafts or intensive law drafting in expedited procedures. `,
                ],
                [
                  'Public institutions do not routinely invite public/CSOs to comment on policy initiatives at an early stage.',
                  'There are some cases where public institutions invite public/CSOs to comment on policy initiatives at an early stage.',
                  'Public institutions routinely invite public/CSOs to comment on policy initiatives at an early stage but they do not provide sufficient time for public to formulate and give their opinion (less than 7 days).',
                  'Public institutions routinely invite public/CSOs to comment on policy initiatives at an early stage and they provide sufficient time for public to formulate and give their opinion (more than 15 days).',
                  'Public institutions routinely invite public/CSOs to comment on policy initiatives at an early stage and they provide sufficient time for public to formulate and give their opinion (more than 15 days). For more complex policy initiatives additional time for consultation is given while also alternative consultation methods are applied (public consultations etc.)',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_practice_indicator_2',
                'Indicator 2',
                'CSOs are provided with adequate information on the content of the draft documents and details of the consultation with sufficient time to respond.',
                [
                  'The draft documents on which CSOs are required to present an opinion is made public. In addition, the administration can prepare a short summary that is not written in a technical language that explains the basic points of the documents. It could also contain the key issues which are under consideration and on which they would like to hear CSOs’ opinions. All related documents, such as analyses, opinions of other institutions, etc. should also be available publicly in order for the public to make an informed decision.',
                ],
                [
                  `\nNumber of involved CSOs that report availability of adequate draft documents;`,
                  `\nCases of institutions that do not make draft documents public before the consultation. `,
                ],
                [
                  `\nNumber of institutions that publish related documents (opinions, analyses) in addition to the draft documents under consultation.`,
                ],
                [
                  'CSOs are not provided with adequate information about details of consultation process, and/or the content of the draft document.',
                  'The draft document for consultation is publicly available, but there are no other information and/or sufficient time for CSOs to give their input.',
                  'The draft document for consultation is publicly available, and sufficient time is given for CSOs to comment on the document. However, no additional information for the document is provided.',
                  'CSOs are provided with adequate information on the content of the draft document such as a short summary that explains the basic points of the documents in jargon-free language, a list of the key issues which are under consideration and on which they would like to hear CSOs’ opinions, reports, analysis and other information that are related to the document. Sufficient time is given to CSOs to responds to the consultation process (min.15 days)',
                  'CSOs are provided with adequate information on the content of the draft document such as a short summary that explains the basic points of the documents on jargon-free language, a list of the key issues which are under consideration and on which they would like to hear CSOs’ opinions, reports, analysis and other information that are related to the document. Additional time is given to CSOs to responds to the consultation process (more than 15 days), while civil servants are willing to meet/provided additional information for the document if needed.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_practice_indicator_3',
                'Indicator 3',
                'Written feedback on the results of consultations is made publicly available by public institutions including reasons why some recommendations were not included',
                [
                  'A list of the people/CSOs/entities that have provided opinions, together with their opinions or a summary of collected opinions is made public and is part of the submission of the document for adoption. ',
                  'In addition to that, feedback to the opinions is also made public, including clear explanation why some opinions have not been taken into consideration. The feedback is provided within a reasonable time after the end of the consultative process. There are no major and frequent complaints that the CSOs’ opinions were not considered.',
                ],
                [
                  `\nNumber of instances when after the consultation a summary report was publicly available on the input from the consultation; `,
                  `\n% of these instances where a list of CSOs that participated is included with the report or online; `,
                  `\n% of these instances where feedback on the received input and justifications for why some recommendations were not adopted are provided; `,
                  `\nNumber of CSOs that report that their suggestions/comments were not considered at all in the consultation processes;`,
                ],
                [
                  `\nReport prominent cases of public complaints when CSOs opinions were not considered;`,
                  `\nCases of significantly delayed publication of feedback on input;`,
                  `\nNumber of CSOs that report that feedback was provided promptly after the consultation.`,
                ],
                [
                  'There is no feedback explaining reasons why recommendations provided by CSOs/public were included or not included in the document.',
                  'The responsible institution provides feedback on recommendations that were not included in the document, but does not provide a list of all comments made during the consultation process. The feedback is not publicly available.',
                  'The responsible institution provides a publicly available list of all comments made during the consultation process, and whether they were accepted or denied. However, there are no explanations or only vague ones on why a recommendation was denied.',
                  'The responsible institution provides a publicly available list of all comments made during the consultation process, and whether they were accepted or denied. A detailed and on time feedback is given for all denied recommendations.',
                  'The responsible institution provides a publicly available list of all comments made during the consultation process, and whether they were accepted or denied. A detailed and on time feedback is given for all denied and accepted recommendations.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_practice_indicator_4',
                'Indicator 4',
                'The majority of civil servants in charge of drafting public policies have successfully completed the necessary educational programs/training.',
                [
                  'There are records of training for civil servants on these topics. Civil servants apply the knowledge in practice, as can be seen through their initiative and openness to more effectively engage civil society.',
                ],
                [
                  `\nReport presence of designated public servants responsible for drafting policies in Ministries which have undergone training for engagement of CSOs in the consultation process.`,
                  `\nNumber of CSOs that agree that designated public servants facilitated effective engagement of CSOs in the consultation processes. `,
                ],
                [],
                [
                  'There are no records, data, or any type of information for civil servants in charge of drafting public policies that have participated in necessary training programs.',
                  'There are records for some civil servants in charge of drafting public policies that have participated in necessary training programs. However, there are gaps/misunderstandings on applying that knowledge in practice.',
                  'There are records showing that the majority of civil servants in charge of drafting public policies have participated in necessary training programs. However, there are gaps/misunderstandings on applying that knowledge in practice.',
                  'There are records showing that the majority of civil servants in charge of drafting public policies successfully participated in necessary training programs. Civil servants apply the knowledge in practice, as can be seen through their initiative and openness to more effectively engage civil society.',
                  'There are records showing that all of civil servants in charge of drafting public policies have participated in regular necessary training programs. Civil servants apply the knowledge in practice, as can be seen through their initiative and openness to more effectively engage civil society.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_1_practice_indicator_5',
                'Indicator 5',
                'Most of the units/officers coordinating and monitoring public consultations are functional and have sufficient capacity.',
                [
                  'Most institutions dealing with public policy have designated officers who are practically organizing public consultations. They show understanding of the issues and openness to engage CSOs, and they provide advice to CSOs on how to respond more meaningfully to the consultation process.',
                ],
                [
                  `\nNumber of institutions dealing with public policy that do not have designated officers for organizing public consultations vs. the number of those that have. `,
                  `\nNumber of CSOs that agree that majority of civil servants responsible for drafting documents have the necessary capacities to involve CSOs. `,
                ],
                [],
                [
                  'Most institutions dealing with public policies do not have designated staff responsible for coordinating and monitoring public consultations. The process is ran randomly by anyone while there are no sufficient capacities to deal with a consultation process.',
                  'Some institutions dealing with public policies have designated staff responsible for coordinating and monitoring public consultations. However, they show limited understanding on issues related to CSOs engagement in their work. They are not capable of guiding or providing advice to CSOs on how to engage and contribute to consultation processes.',
                  'Most institutions dealing with public policies have designated staff responsible for coordinating and monitoring public consultations. However, they show limited understanding on issues related to CSOs engagement in their work. They are not capable of guiding or providing advice to CSOs on how to engage and contribute to consultation processes.',
                  'Most institutions dealing with public policies have designated staff responsible for coordinating and monitoring public consultations. They show a good understanding of issues related to CSOs engagement in their work, and are capable of guiding or providing advice to CSOs on how to engage and contribute to consultation processes.',
                  'All institutions dealing with public policies have designated staff responsible for coordinating and monitoring public consultations. They show a good understanding of issues related to CSOs engagement in their work, and constantly provide advice to CSOs on how to engage and contribute to the consultation.',
                ]
              ),
            ],
          },
          {
            code: 'area_3_sub_area_3_2_standard_2',
            codeName: 'STANDARD 3.2.2',
            originalName: 'Public Access to Draft Policies and Laws',
            name: 'All draft policies and laws are easily accessible to the public in a timely manner.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_2_standard_2_legislation_indicator_1',
                'Indicator 1',
                'Existing legislation obliges public institutions to make all draft and adopted laws and policies public, and exceptions are clearly defined and in line with international norms and best practices.',
                [
                  'There should be a requirement for the government to publish draft legislation or policy documents. If citizens and CSOs do not know what is on the government agenda, they cannot be expected to take active part in the policy-making process. As a first step, government institutions should publish their legislative/policy agenda – a list of the topics/acts it wants to work on annually and for a period of several months. This will help interested CSOs to prepare and get in contact with the respective institutions. A second step is to publish the concept of the draft, and then the text of the draft documents. This should apply to both government and parliament. The publication of documents should not be based on requests for information but should be an obligation of the respective institutions.',
                ],
                [],
                [],
                [
                  'Existing legislation does not require for public institutions to make all draft and adopted laws and policies public.',
                  'Existing legislation does require for public institutions to make all draft and adopted laws and policies public, but does not oblige to do so.',
                  'Existing legislation oblige public institutions to make all draft and adopted laws and policies public, but exceptions are not defined and/or are not in line with international norms and best practices.',
                  'Existing legislation oblige public institutions, the Government and Parliament, to make all draft and adopted laws and policies public, other policy agendas, while exceptions are defined and are in line with international norms and best practices.',
                  'Existing legislation oblige public institutions to make public all draft and adopted laws, policies, legislative and other policy agendas, while exceptions are defined and are in line with international norms and best practices. The legislation applies to all levels of governance, including the Government, Parliament, Local Level or any intermediary level.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_2_legislation_indicator_2',
                'Indicator 2',
                'Clear mechanisms and procedures for access to public information/ documents exist.',
                [
                  'The legislation should provide clear rules on the obligation of the state authorities to provide information based on requests. There should be limited exceptions to the general requirement for provision of information, (usually related to national security) subject to a public interest test. The requests should be dealt with promptly and the timeline for answering such requests should not be too long (usually 14 days).',
                ],
                [],
                [],
                [
                  'There are no mechanisms or defined procedures for access to public information/documents.',
                  'The legislation provides mechanisms and procedures for access to public information/documents, but they are not clearly defined. There is no timeline set for answering requests.',
                  'The legislation provides clear mechanisms and procedures for access to public information/documents. There are many limitations for access to public information that are not in line with international standards. Timeline for answering requests is too long (more than 14 days).',
                  'The legislation provides clear mechanisms and procedures for access to public information/documents. There are few and clearly defined limitations for access to public information that are in line with international standards. Requests for access to information are dealt within 14 days.',
                  'The legislation provides clear mechanisms and procedures for access to public information/documents. There are few and clearly defined limitations for access to public information that are related to specific national security issues and in line with international standards. Requests for access to information are dealt within 7 days.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_2_legislation_indicator_3',
                'Indicator 3',
                'There are clearly prescribed sanctions for civil servants/units for breaching the legal requirements on access to public information.',
                [
                  'If the legal rules are not followed and the requested information is not provided, the court can sanction the wrongful party. This is true also for excessive delays in answering requests.',
                ],
                [],
                [],
                [
                  'Legislation does not prescribe sanctions for civil servants/units for breaching the legal requirements on access to public information.',
                  'Legislation prescribes sanctions for civil servants/units for breaching the legal requirements on access to public information, but they are not mandatory.',
                  'Legislation prescribes sanctions for civil servants/units for breaching the legal requirements on access to public information, but there are no sanctions prescribed for excessive delays in answering requests.',
                  'Legislation prescribes sanctions for civil servants/units for breaching the legal requirements on access to public information, including sanctions for excessive delays in answering request. Also, legislation allows for anyone to appeal refusals for access to information or excessive delays to the court.',
                  'Legislation prescribes sanctions for civil servants/units for breaching the legal requirements on access to public information, including sanctions for excessive delays in answering request. Legislation allow for anyone to appeal refusals for access to information or excessive delays to the court. Also, legislation allows for fast-track appeal procedures.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_2_standard_2_practice_indicator_1',
                'Indicator 1',
                'Public institutions actively publish draft and adopted laws and policies, unless they are subject to legally prescribed exceptions.',
                [
                  'Institutions publish concepts, draft and adopted laws in a proactive manner. The easiest way is to use the Internet – each institution can have a section on its website where it publishes draft documents for consultation. Some countries have also created unified portals where all laws subject to consultation are published. ',
                  'These are specialized websites where citizens can post their opinions also online. Adopted laws should also be made available to the public free of charge.',
                ],
                [
                  `\nReport if the country has unified portal where all laws subject to consultation are published. If yes, report if citizens can post their opinions on the portal;`,
                  `\nNumber (and %) of ministries that do not regularly publish adopted laws and policies on their websites; `,
                  `\nReport if all adopted laws can be accessed free of charge by citizens. `,
                ],
                [],
                [
                  'Public institutions do not publish draft and adopted laws and policies.',
                  'Few institutions publish some of the draft and/or adopted documents on their websites and/or centralized online platform.',
                  'Most of the institutions publish some of the draft and/or adopted documents on their websites and/or centralized online platform.',
                  'Public institutions publish all draft and adopted documents on an online platform that is accessible to all, unless they are subject to legally prescribed exceptions.',
                  'Public institutions publish all draft and adopted documents on a centralized online platform that is accessible to all, unless they are subject to legally prescribed exceptions. Individuals can post their opinions online.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_2_practice_indicator_2',
                'Indicator 2',
                'Public institutions answer the majority of requests for access to public information within the deadline prescribed by law, in a clear format, provide written explanations on the reasons for refusal, and highlight the right to appeal and the procedure for appealing.',
                [
                  'There are no complaints of violations of this principle or laws. Reports confirm that institutions are complying with the laws, are willing to collaborate and are generally open.',
                ],
                [
                  `\nNumber of answered vs. number of unanswered requests;`,
                  `\nNumber of complaints and most frequent reasons for complaints (as related to the timeframe; non-answering; quality of answers, acknowledging the right to appeal etc.);`,
                  `\nNumber of CSOs which have received information within the time limit prescribed by law; `,
                  `\nNumber of CSOs which were provided with an answer in a clear format vs. number of CSOs which were denied access without any explanation;`,
                  `\nNumber of CSOs which were advised on the possibility to appeal.`,
                ],
                [
                  '\nCSOs that did not receive any answer on their FoI request;',
                ],
                [
                  'Majority of requests for access to public information are not answered within the deadline prescribed by law. Reason for the refusal of the access are not provided in written.',
                  'Majority of requests for access to public information are answered only partially and/or with excessive delays. Reason for the refusal of access are provided in written only in some cases.',
                  'Majority of requests for access to public information are answered within the deadline prescribed by law. However, only in some cases/or not at all they provide written explanations on the reasons for refusal.',
                  'Majority of requests for access to public information are answered within the deadline prescribed by law, in a clear format, provide written explanations on the reasons for refusal, and highlight the right to appeal and the procedure for appealing.',
                  'Majority of requests for access to public information are answered within the deadline prescribed by law, in a clear format, provide detailed written explanations on the reasons for refusal, and highlight the right to appeal and the procedure for appealing. There are no complaints of violation of this principle of law, while reports/observations show that institutions are willing to collaborate with CSOs even beyond their obligations prescribed by law.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_2_practice_indicator_3',
                'Indicator 3',
                'Cases of violations of the law are sanctioned.',
                [
                  'The courts apply the law on access to information impartially, and any state institutions that hinder citizens’ right to information are sanctioned according to the law.',
                ],
                [
                  `\nNumber of sanctions for institutions following reviews of appeals by claimants (annual level data).`,
                ],
                [],
                [
                  'There are no cases sanctioned for violations of the law, for not giving access to information.',
                  'There are very few cases where courts apply sanctions to institutions that hinder citizens’ right to information.',
                  'There are cases where courts apply sanctions to institutions that hinder citizens’ right to information but with serious procedural delays.',
                  'The courts apply the law on access to information impartially, and any state institutions that hinder citizens’ right to information are sanctioned according to the law.',
                  'The courts apply the law on access to information impartially, and any state institutions that hinder citizens’ right to information are sanctioned according to the law. There are cases where courts proceed complaints on violation of the law on accelerated procedure.',
                ]
              ),
            ],
          },
          {
            code: 'area_3_sub_area_3_2_standard_3',
            codeName: 'STANDARD 3.2.3',
            originalName: 'CSOs Representation in Cross-Sector Bodies',
            name: 'CSO representatives are equal partners in discussions in cross-sector bodies and are selected through clearly defined criteria and processes.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_2_standard_3_legislation_indicator_1',
                'Indicator 1',
                'Existing legislation requires public institutions to invite CSO representatives on to different decision-making and/or advisory bodies created by public institutions.',
                [
                  'When the administration creates advisory bodies, there is a requirement to include in it representatives of civil society from the respective area in which the body is created. CSO representatives are subject to the same requirements as other members of the consultative body and have the same rights (they can vote, and are not limited to observer status).',
                ],
                [],
                [],
                [
                  'Legislation does not require for public institutions to invite CSO representatives on to decision-making and/or advisory bodies created by public institutions.',
                  'Legislation provides requirements that allow but do not oblige public institutions to invite CSO representatives on to decision-making and/or advisory bodies.',
                  'Legislation requires for public institutions to invite CSO representatives on to decision-making bodies and/or advisory bodies as observers (or any status similar that does not give them the right to vote)',
                  'Legislation require for public institutions to invite CSO representatives on to decision-making bodies and/or advisory bodies and CSO representatives have same rights and responsibilities as other members.',
                  'Legislation require for public institutions to invite more than one CSO representative on to decision-making bodies and/or advisory bodies and CSO representatives have same rights and responsibilities as other members.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_3_legislation_indicator_2',
                'Indicator 2',
                'There are clear guidelines on how to ensure appropriate representation from civil society, based on transparent and predetermined criteria.',
                [
                  'When CSOs are invited to consultative bodies, this should happen through a public procedure (the announcement for nominations is publicized) and there are clear criteria for the members. The process through which CSOs are selected is also transparent. Another option is to allow CSOs to nominate their own representatives. In the latter case, more time should be given for CSOs to be able to organize their internal selection process.',
                ],
                [],
                [],
                [
                  'There are no guidelines, clear procedures, or predetermined criteria that would ensure appropriate representation from civil society on to consultative/advisory bodies.',
                  'There are guidelines and criteria for inviting and involving CSO representative on to consultative/advisory bodies, but neither the procedure goes through a public call nor is it transparent.',
                  'There are guidelines and criteria for inviting and involving CSO representatives on to consultative/advisory bodies through a transparent public procedure, but CSO are not allowed to nominate their own representatives.',
                  'There are guidelines and criteria for inviting and involving CSO representatives on to consultative/advisory bodies through a transparent public procedure. CSO are allowed to nominate their own representatives and sufficient time is given for CSOs to organize their internal selection process.',
                  'There are guidelines and criteria for inviting and involving CSO representatives on to consultative/advisory bodies through a transparent public procedure. CSO are allowed to nominate their own representatives and sufficient time is given for CSOs to organize their internal selection process. If needed, public institutions provide advice and other forms of support for CSOs on how to nominate and select their representatives.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_2_standard_3_practice_indicator_1',
                'Indicator 1',
                'Decision-making and advisory bodies on issues and policies relevant for civil society generally include CSO representatives.',
                [
                  'Practice shows that CSO representatives take part in advisory/consultative/ working group bodies and committees. It is important that in such bodies representatives from associations, foundations and nonprofit companies participate, in addition to associations of municipalities or of businesses which in their legal form are also CSOs. The CSOs are informed about the meetings in timely manner to be able to organize themselves to attend and prepare for the meeting.',
                ],
                [
                  `\nNumber of CSOs that report that they were involved/ represented in the work of advisory/consultative/ working group bodies and committees in the past year;`,
                  `\nReport presence of advisory/consultative/ working group bodies and committees across institutions where CSOs are represented (this includes associations, foundations and nonprofit companies)`,
                ],
                [],
                [
                  'There are no cases where CSO representatives were included on to decision-making bodies for issues and policies relevant to civil society.',
                  'Associations of municipalities or of businesses are usually invited on to decision-making bodies for issues and policies relevant to civil society but not representative of other CSOs (associations, foundations, etc).',
                  'In most cases CSO representatives are invited on to decision-making bodies for issues and policies relevant to civil society, but usually they are not informed in timely manner or given enough time to organize themselves.',
                  'CSO representatives are invited on to decision-making bodies for issues and policies relevant to civil society, and usually they are informed in timely manner and/or given enough time to organize themselves.',
                  'CSO representatives are always invited on to decision-making bodies for issues and policies relevant to civil society, and usually they are informed in timely manner and/or given enough time to organize themselves.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_3_practice_indicator_2',
                'Indicator 2',
                'CSO representatives in these bodies are enabled to freely present and defend their positions, without being sanctioned.',
                [
                  'There should be no examples of CSO representatives that speak critically of the government and its policies during meetings of bodies being subjected to excessive state control or harassment. CSOs are given time during the discussions of the respective bodies to speak and express their positions and to debate on issues.',
                ],
                [
                  `\nNumber of CSOs that report being subject to excessive state control (e.g. inspections, sanctions) or harassment due to critical stances expressed in advisory/consultative/ working group bodies and committees.`,
                ],
                [],
                [
                  'CSO representatives that are critical of the government and its policies during meetings of bodies are usually subject to excessive state control or harassment and/or they are not allowed to speak and express their positions freely.',
                  'CSO representatives are not given proportionate time during the discussions of the bodies/working groups.',
                  'CSO representatives are enabled to freely present and defend their positions, but there are not given proportionate time during discussions as compared to other members.',
                  'CSO representatives are enabled to freely present and defend their positions without being sanctioned, and are treated impartially.',
                  'CSO representatives are enabled to freely present and defend their positions without being sanctioned, are treated impartially, and their contribution is considered valuable.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_3_practice_indicator_3',
                'Indicator 3',
                'CSO representatives are selected through selection processes which are considered fair and transparent.',
                [
                  'It is not enough to have CSO representatives in cross-sector bodies, but representatives should also be selected through a public and transparent procedure. There should be no examples of a nontransparent selection process. Sometimes state bodies may decide to invite CSOs with whom they cooperate, but such decision should be made in a transparent and timely manner, with other CSOs also having the right to request to attend. State authorities do not invite only those CSOs which are close to their opinions or tendencies. The work of such advisory bodies should be transparent. Information about their members, meetings and decisions should be public. In addition, there should be the possibility for nonmembers of the advisory councils to participate in the council meetings when issues of concern to such organizations are discussed.',
                ],
                [
                  `\nCases of non-transparent selection of representatives should be reported;`,
                  `\nNumber of CSO representatives that report that the selection of representatives on the bodies in their area of work was conducted through a transparent procedure; `,
                  `\nNumber of bodies which do not make public the names of their members, information on meetings and decisions;`,
                ],
                [
                  `\nReport presence of possibility for non-members to participate in meetings of bodies.`,
                ],
                [
                  'There is no procedure in place for selection of civil society representatives that ensures fair and transparent process.',
                  'There is a procedure in place for selection of civil society representatives, but with major transparency issues that do not allow for fair selection process (e.g. in most selection processes, CSO representatives are invited rather than selected through a competitive transparent procedure; state bodies invite only CSOs which are close to their opinions).',
                  'There is a procedure in place for selection of civil society representatives, but with minor transparency issues that do not allow for fair selection process (e.g. state authorities do not allow non-member organizations to attend meetings even when issues of concern to such organizations are discussed).',
                  'As a general practice, CSO representatives are selected through a public and transparent procedure. There are no cases of a nontransparent selection process. However, there are cases where state bodies decide to invite CSOs with whom they cooperate, but such decisions are made in a transparent and timely manner.',
                  'CSO representatives are selected through a public and transparent procedure. There are no cases of a nontransparent selection process. There are cases where state bodies decide to invite CSOs with whom they cooperate, but such decisions are made in a transparent and timely manner with the approval of CSOs.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_2_standard_3_practice_indicator_4',
                'Indicator 4',
                'Participation in these bodies does not prevent CSOs from using alternative ways of advocacy or promoting alternative standpoints that are not in line with the position of the respective body.',
                [
                  'Participation in cross-sector bodies is only one way for CSOs to achieve their mission. It does not preclude CSOs from organizing advocacy measures, demonstrations, petitions or any other advocacy measures to promote their opinions if they feel that they have not be considered or adopted. CSO representatives in the body should be able to communicate and consult with their counterparts in the sector, especially those that they have been selected to represent.',
                ],
                [
                  `\Number of CSOs that are involved in decision-making advisory bodies that report using alternative advocacy measures to promote their opinion (e.g. such advocacy measures may include media campaigns, demonstrations, petitions, mobilizing constituencies to send e-mails to representatives, joining and forming advocacy coalitions etc.).`,
                ],
                [],
                [
                  'In practice, CSO representatives are not allowed to disclose any information of the working group/state body that they are part of, including with their members or people/organizations that they represent.',
                  'There are many cases where CSO representatives were denied access to state bodies due to disclosing information with their members and/or constituency, or because of using alternative ways of advocacy.',
                  'There are some cases where CSO representatives were denied access to state bodies due to disclosing information with their members and/or constituency, or because of using alternative ways of advocacy.',
                  'There are no cases where CSO representatives, members of state bodies, were prohibited from disclosing any information, or prevented from using alternative ways of advocacy or promoting alternative standpoints which are not in line with the position of the respective body.',
                  'There are no cases where CSO representatives, members of state bodies were prohibited from disclosing any information, or prevented from using alternative ways of advocacy or promoting alternative standpoints which are not in line with the position of the respective body. State bodies recognize and appreciate CSO representatives who stand firm for their positions and communicate regularly with their members and/or constituency.',
                ]
              ),
            ],
          },
        ],
      },
      {
        code: 'area_3_sub_area_3_3',
        codeName: 'Sub-Area 3.3',
        name: 'COLLABORATION IN SERVICE PROVISION',
        principle:
          'There is a supportive environment  for CSO involvement in service provision',
        standards: [
          {
            code: 'area_3_sub_area_3_3_standard_1',
            codeName: 'STANDARD 3.3.1',
            originalName:
              'CSO Engagement in Service Provision and Competition for State Contracts',
            name: 'CSOs are engaged in different services and compete for state contracts on an equal basis to other providers.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_1_legislation_indicator_1',
                'Indicator 1',
                'Existing legislation allows CSOs to provide services in various areas, such as education, healthcare, social services.',
                [
                  'The law should allow governments to contract out or outsource certain services to CSOs. There are no legal obstacles to CSOs to provide services in the areas of education, social assistance and healthcare. Limitations could exist for CSOs only in areas which are traditionally not considered non-profit (e.g. banking, gambling) although CSOs should be allowed to set up commercial companies which can work in these areas. There is no automatic exclusion for CSOs from competition for the provision of services if there are no specific and objective reasons for that.',
                ],
                [],
                [],
                [
                  'Existing legislation does not allow CSOs to provide services in various areas, such as education, healthcare, social services.',
                  'Existing legislation allows CSOs to provide services in various areas, but with major issues (e.g. does not treat them equal to for-profit companies).',
                  'Existing legislation allows CSOs to provide services in areas of education, healthcare, social services, but with minor inconsistences and issues (e.g. excludes CSOs from competition for the provision of services in all other areas)',
                  'Existing legislation allows CSOs to provide services in various areas including education, healthcare, social services. There is no automatic exclusion for CSOs from competition for the provision of services.',
                  'Existing legislation allows CSOs to provide services in various areas including education, healthcare, social services. CSOs are given a preferential treatment for provision of social services compared to other for profit companies.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_1_legislation_indicator_2',
                'Indicator 2',
                'CSOs have no barriers to providing services that are not defined by law (“additional” services).',
                [
                  'The law should not prohibit CSOs from developing or providing new services even though such services are not defined by law. In this way, CSOs are able to bring innovative services and pilot new practices which could later be adopted by the government, if successful. If the services are in areas for which a preliminary registration or licensing is required, that should apply to CSOs as well (e.g. services for children).',
                ],
                [],
                [],
                [
                  'CSOs are not allowed to provide services that are not defined by law.',
                  'CSOs have barriers to providing services that are not defined by law and additional licensing and/or inspection procedures as compared to for-profit companies.',
                  'CSOs are allowed to providing services that are not defined by law, but need to go through detailed inspection.',
                  'CSOs have no barriers to providing new and innovative services.',
                  'CSOs are incentivized to come up with new and innovative services.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_1_legislation_indicator_3',
                'Indicator 3',
                'Existing legislation does not add additional burdensome requirements on CSOs that do not exist for other service providers.',
                [
                  'CSOs should not be subject to stricter requirements in the areas in which they provide services compared to other service providers. The service quality standards to which providers should be subject are the same for commercial companies, CSOs and other types of providers.',
                ],
                [],
                [],
                [
                  'Existing legislation has additional burdensome requirements on CSOs that do not exist for other service providers.',
                  'Existing legislation has additional burdensome requirements on CSO that do not exist for other service providers, in areas of education, healthcare, social services.',
                  'Existing legislation does not have additional burdensome requirements on CSOs with public benefit status, respectively treats them equally to other commercial service providers.',
                  'Existing legislation does not have additional burdensome requirements on CSOs that do not exist for other service providers.',
                  'Existing legislation does not add additional burdensome requirements on CSOs that do not exist for other service providers. In addition, legislation is positive/treats CSOs with preference.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_1_practice_indicator_1',
                'Indicator 1',
                'CSOs are able to obtain contracts in competition with other providers and are engaged in various services (e.g., education, health, research, and training).',
                [
                  'There are examples of CSOs being awarded contracts for services in various areas of service provision and this is not an exception. CSOs are one of the main providers of services in its traditional areas – social assistance, healthcare and education. CSOs are increasingly engaged in social entrepreneurship not only by providing services in social areas but also by engaging in other economic activities which support their mission, including providing employment to their beneficiaries.',
                ],
                [
                  `\nNumber of bids/ contracts for service provision won by CSOs across Ministries/ policy fields;`,
                  `\nReport the range of fields in which CSOs are contracted;`,
                  `\nNumber of CSOs that reported funding through state contracts. `,
                ],
                [],
                [
                  'There are no cases of CSOs being awarded contracts for services in various areas.',
                  'Even though there are cases of CSOs being awarded contracts for services in various areas, there no cases of CSOs being awarded contracts when in competition with other providers.',
                  'There are cases of CSOs being awarded contracts for services in various areas in competition with other providers, but they are rather an exception.',
                  'There are many cases of CSOs being awarded contracts for services in various areas in competition with other providers. In addition to contracts for services, CSOs are increasingly engaged in economic activity.',
                  'There are cases where preference for contracts for social services is given to CSOs. Majority of interested CSOs are engaged in some sort of economic activity.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_1_practice_indicator_2',
                'Indicator 2',
                'CSOs are included in all stages of developing and providing services (needs assessment, determining the services that best address the needs, monitoring and evaluation).',
                [
                  'CSOs are partners to the state not only in service provision. Very often CSOs are closer to people’s needs and could bring added value in determining the service needs and how it is best to address them. ',
                  'In addition, CSOs have expertise and could provide a different perspective on the process of evaluation of services provided by the state.',
                ],
                [
                  `\nNumber of CSOs that are involved in different stages of developing and evaluation of services.`,
                ],
                [],
                [
                  'The state does not include CSOs in planning, developing, or implementing social services. CSO representatives are not accepted as relevant and/or stakeholders for social services.',
                  'Only in few cases state institutions include CSOs in the process of planning social services. CSOs are seen as relevant stakeholders but not as partners.',
                  'CSO are considered as partners to the state in planning, developing, implementing, monitoring and evaluating social services. However, there are many cases where CSOs are not included in the process.',
                  'CSOs are partners to the state in planning, developing, implementing, monitoring and evaluating social services. CSOs are seen as an important stakeholder in those processes.',
                  'The state considers CSOs as partners in developing, implementing, monitoring and evaluating social services. There are cases where responsible state institutions outsource CSOs for either developing or monitoring, or evaluating certain services.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_1_practice_indicator_3',
                'Indicator 3',
                'When prior registration/licensing is required, the procedure for obtaining it is not overly burdensome.',
                [
                  'The requirements for prior registration/ licensing of service providers in certain areas should not push away potential CSO service providers because of burdensome procedures. Such procedures should be relatively quick and cheap.',
                ],
                [
                  `\n•	Number of CSOs providing public services which had to obtain a license;`,
                  `\n•	Number of CSOs which consider the process for obtaining license to be burdensome;`,
                  `\n•	Specific cases of burdensome procedures should be reported.`,
                ],
                [],
                [
                  'The registration/licensing procedures for CSOs providing social services are overly burdensome compared to other parties.',
                  'The registration/licensing procedures for CSOs providing social services are clear and identical to other parties, but burdensome.',
                  'The registration/licensing procedures for CSOs providing social services are clear, appropriate for the size and type of CSO and/or service. The registration/licensing procedure is relatively long and/or expensive.',
                  'The registration/licensing procedures for CSOs providing social services are clear, appropriate for the size and type of CSO and/or service. The registration/licensing procedure is relatively quick and/or cheap.',
                  'The registration/licensing procedures for CSOs providing social services are fully compatible with the nature of work and operation of CSOs. The registration/licensing procedure is very quick and/or free of charge. There are simplified procedures for CSOs how have already been providing social services.',
                ]
              ),
            ],
          },
          {
            code: 'area_3_sub_area_3_3_standard_2',
            codeName: 'STANDARD 3.3.2',
            originalName: 'State Funding for CSO-Provided Services',
            name: 'The state has committed to funding services and the funding is predictable and available over a longer-term period.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_2_legislation_indicator_1',
                'Indicator 1',
                'The budget provides funding for various types of services which could be provided by CSOs including multi-year funding.',
                [
                  'The state budget should provide enough resources to allow for the financing of basic services in the key areas in which CSOs traditionally operate (education, social area and healthcare). In the social area, social services are an important tool for improving the lives of vulnerable groups and cannot be replaced only by social assistance (provision of food, money or other types of material assistance to the most underprivileged). The legal framework should also allow for multi-year funding to promote further effectiveness in delivering the service and achieving results. ',
                ],
                [],
                [],
                [
                  'The legal framework does not provide funding for any type of services that could be provided by CSOs.',
                  'The legal framework provides partial funding/co-funding for basic social services provided by CSOs.',
                  'The legal framework provides funding for basic social services provided by CSOs, but does not provide multi-year funding.',
                  'The legal framework provides funding for basic and various types of social services provided by CSOs, including multi-year funding.',
                  'The legal framework provides for multi-year funding for social services provided by CSOs.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_2_legislation_indicator_2',
                'Indicator 2',
                'There are no legal barriers to CSOs receiving public funding for the provision of different services (either through procurement or through another contracting or grants mechanism).',
                [
                  'CSOs are allowed to participate in all of the mechanism through which the state delegates the provision of services to outside providers (public procurement, grants, social contracting, and other mechanisms).',
                ],
                [],
                [],
                [
                  'There are severe legal barriers to CSOs receiving funding for the provision of public services.',
                  'There are major legal barriers to CSOs receiving funding for the provision of public services.',
                  'There are minor legal barriers to CSOs receiving funding for the provision of public services.',
                  'There are no legal barriers to CSOs receiving public funding for the provision of different services. CSOs have impartial treatment in all state funding mechanisms including, public procurement, grants, social contracting, and other mechanisms.',
                  'There are no legal barriers to CSOs receiving public funding for the provision of different services. CSOs have preferential treatment in some state funding mechanisms.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_2_legislation_indicator_3',
                'Indicator 3',
                'CSOs can sign long-term contracts for provision of services.',
                [
                  'Even though in most countries the budget is adopted on an annual basis, competitions for service providers (including CSOs) cover longer periods of time and the contracts signed could be long-term. ',
                  'Once a contract is signed, the respective state agencies are obliged to provide the funding necessary for the provision of the contracted services for the whole duration of the contract and possibly to consider including advance payments.',
                ],
                [],
                [],
                [
                  'The legal framework does not allow for long-term contracts for provision of services.',
                  'The legal framework does not allow for long term contracts for provision of services by CSOs, but does allow so for other parties.',
                  'The legal framework allows for long-term contracts for provision of services by CSOs only for few social services (e.g. shelters).',
                  'The legal framework allows for long-term contracts for provision of services in general, including for services provided by CSOs.',
                  'The legal framework allows for long-term contracts for provision of services by CSOs.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_2_practice_indicator_1',
                'Indicator 1',
                'CSOs are recipients of funding for services.',
                [
                  'When the state contracts services, it needs to provide sufficient funding to cover the basic costs of the services they wish to receive (even though the providers may be non-profit organizations). ',
                  'In the service costs it should also calculate all the administrative and institutional costs associated with maintaining the service. Even though CSOs may use volunteers it should not be presupposed that CSOs rely only on volunteers, but that they will use employees or consultants.',
                ],
                [
                  `\nNumber of CSOs that agree that CSOs are excluded from public service tenders in their area of work.`,
                ],
                [
                  `\nIllustrative cases of CSOs being excluded from tender calls for public service provision.`,
                ],
                [
                  'There are no cases where CSOs are recipient of funding for social services.',
                  'CSOs are recipient of state funds for social services provided covering only basic costs of the services but not administrative or institutional costs.',
                  'CSOs are recipient of state funds for social services provided covering basic costs of the services, administrative and institutional costs associated with services provided but only for certain social services.',
                  'There are many cases where CSOs are recipient of funding for services provided. The state fund covers basic costs for services, administrative and institutional costs associated with services provided.',
                  'CSOs are recipients of funding for services covering basic costs for services provided, administrative costs, as well as organizational overhead. ',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_2_practice_indicator_2',
                'Indicator 2',
                'CSOs receive sufficient funding to cover the basic costs of the services they are contracted to provide, including proportionate institutional (overhead) costs.',
                [
                  'The CSO income for provision of services coming from the state is increasing. There are no complaints that CSOs are excluded from service tenders. ',
                ],
                [
                  `\nNumber of CSOs which report that funding for services was not sufficient to cover basic costs of the services; `,
                  `\nNumber of CSOs that report that funding for services did not include proportionate institutional and administrative (overhead) costs;`,
                ],
                [
                  `\nIllustrative cases of lack of sufficient funding for basic costs of service delivery should reported.`,
                ],
                [
                  'CSOs do not receive sufficient funding to cover basic costs of the services they are contracted to provide.',
                  'CSOs receive sufficient funding to cover basic costs of the services they are contracted to provide, but they do not receive any state fund for institutional costs (overhead).',
                  'CSOs receive sufficient funding to cover the basic costs of the services they are contracted to provide, including proportionate institutional (overhead) costs.',
                  'State fund for social services provided by CSOs are increasing. State funds cover all basic costs for services provided, including proportionate institutional costs (overhead).',
                  'State fund for social services provided by CSOs are increasing. State funds cover all basic costs for services provided, including proportionate institutional costs (overhead) and risk management costs.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_2_practice_indicator_3',
                'Indicator 3',
                'There are no delays in payments and the funding is flexible with the aim of providing the best quality of services.',
                [
                  'Once funding is provided to CSOs, it should be allowed to use the money in such a way as to maximize the impact and fulfil the respective quality standards. Where funding is provided under a budget, CSOs should be allowed to move money between different budget items, as long as they follow certain procedures. If funding is provided in tranches, there should be no delays and any losses suffered because of delays should be compensated.',
                ],
                [
                  `\nNumber of CSOs that have faced delays in payments;  `,
                  `\nNumber of CSOs that were not allowed reallocation (flexibility) of funds; `,
                  `\nNumber of CSOs that were compensated for the losses resulting from delays in payment.`,
                ],
                [],
                [
                  'There are always delays in payments for services provided by CSOs, funding is not flexible, and any losses suffered because of delays are not compensated. There are many cases where funding is provided under a budget.',
                  'There are delays in payment for services provided by CSOs, while any losses suffered because of delays are not compensated.',
                  'There are delays in payments for services provided by CSOs, but any losses suffered because of delays are compensated.',
                  'There are no delays in payments for services provided by CSOs and the funding is flexible aiming the provision of best quality services.',
                  'There are no delays in payments for services provided by CSOs and the funding is flexible aiming the provision of best quality services. There are cases where advanced payments are done for services provided by CSOs.',
                ]
              ),
            ],
          },
          {
            code: 'area_3_sub_area_3_3_standard_3',
            codeName: 'STANDARD 3.3.3',
            originalName: 'Procedures for Contracting Services',
            name: 'The state has clearly defined procedures for contracting services which allow for transparent selection of service providers, including CSOs.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_legislation_indicator_1',
                'Indicator 1',
                'There is a clear and transparent procedure through which the funding for services is distributed among providers.',
                [
                  'The selection of service providers is carried out under an open competition whereby all eligible providers are allowed to participate. The evaluation criteria are publicly announced in advance.',
                ],
                [],
                [],
                [
                  'The legal framework does not provide transparent procedure through which the funding for services is distributed among providers.',
                  'There are certain transparency provisions for distribution of funds among service providers, but with major issues that are not regulated (e.g. lack of rules for open competition, lack of evaluation criteria, lack of complaint mechanism).',
                  'There are certain transparency provisions for distribution of funds among service providers, but with minor issues that are not regulated.',
                  'The legal framework provides transparent and open competition procedure through which the funding for services is distributed among providers, while all eligible providers are allowed to participate and evaluation criteria are publicly announced in advance.',
                  'The legal framework provides transparent and open competition procedure through which the funding for services is distributed among providers, while all eligible providers are allowed to participate. All open calls are published in an online centralized platform.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_legislation_indicator_2',
                'Indicator 2',
                ' Price is not the lead criterion for selection of service providers and best value is determined by both service quality and a financial assessment of contenders.',
                [
                  'In certain areas of service provision e.g. social areas, the lead criteria for evaluation of bids should be the experience of the provider and the staff that will deliver the service. In this area, it is important to provide the best possible quality or to serve the biggest number of people for the budget that the state has, rather than make savings from the existing budget. In such areas, the financial bid should not be the most important evaluation criteria.',
                ],
                [],
                [],
                [
                  'The legal framework provides lowest price as lead criterion for selection of service provider.',
                  'The legal framework provides lowest price as lead criterion for selection of service provider, but takes into consideration the quality of services provided and the experience of the provider.',
                  'The legal framework provides service quality and a price as lead criteria for selection of services providers for social services.',
                  'Price is not the lead criterion for selection of service providers and best value is determined by both service quality and a financial assessment of contenders. Also, the legal framework requires the best possible quality to be served to the biggest number of people for the budget that the state has.',
                  'The legal framework provides service quality as lead criterion for selection of providers for social services.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_legislation_indicator_3',
                'Indicator 3',
                'There are clear guidelines on how to ensure transparency and avoid conflicts of interests.',
                [
                  'The evaluators of bids have to declare any conflict of interests in advance of the process. All procedures for selection of service providers are public, in line with the good regulatory practice on distribution of funding as outlined above.',
                ],
                [],
                [],
                [
                  'There are no guidelines on managing conflicts of interests and ensuring transparency.',
                  'There are guidelines on how to ensure transparency on the evaluation and selection of service providers, but there is no mechanism addressing conflicts of interests.',
                  'There are guidelines on how to ensure transparency on the evaluation and selection of service providers and managing conflicts of interests.',
                  'There are guidelines on how to ensure transparency on the evaluation and selection of service providers and managing conflicts of interests, in line with the good regulatory practices on distribution of funding.',
                  'There are guidelines on how to ensure transparency on the evaluation and selection of service providers and managing conflicts of interests, in line with the good regulatory practices on distribution of funding. There is also a centralized online platform showing evaluation grids for all service providers.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_legislation_indicator_4',
                'Indicator 4',
                'There is a right to appeal against competition results.',
                [
                  'The legal framework should allow for an appeal before an independent entity and eventually to the court.',
                ],
                [],
                [],
                [
                  'The legal framework does not allow the right to appeal against competition results.',
                  'The legal framework provides the right to appeal against competition results but the procedure is not clear/complicated and does not allow for appeal at an independent entity.',
                  'The legal framework provides the right to appeal against competition results on an executive/government entity.',
                  'The legal framework provides the right to appeal against competition results on an independent entity and eventually to the court.',
                  'The legal framework provides the right to appeal against competition results on an independent entity with an expedited procedure, and eventually to the court.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_practice_indicator_1',
                'Indicator 1',
                'Many services are contracted to CSOs.',
                [
                  'There are sufficient examples in different areas of CSOs receiving contracts for service provision from the state. In the traditional CSO areas such contracts are a usual practice, and CSOs (associations, foundations and non-profit companies) are recognized as important contractors of the state.',
                ],
                [
                  `\nNumber of CSOs across different areas that received contracts;`,
                  `\nReport % of CSOs that reported receiving contracts per different policy fields (cross-tabulation of CSOs with state contracts and CSO policy field);`,
                  `\nReport % of CSOs that reported receiving contracts per different legal forms.`,
                ],
                [],
                [
                  'There are very few examples of CSOs receiving contracts for service provision from state.',
                  'There are some examples of CSOs receiving contracts for provision of social services, usually those services where there are no other providers.',
                  'There are some examples of CSOs receiving contracts from the state for provision of services in different areas.',
                  'There are sufficient examples of CSOs receiving contracts from the state for provision of services in different areas. In the traditional CSO social service areas (health, education etc.) CSOs are recognized as an important contractors of the state.',
                  'CSOs receiving contracts from the state for provision of services has become a norm. In the traditional CSO social service areas (health, education etc.) CSOs are recognized as an important contractors of the state.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_practice_indicator_2',
                'Indicator 2',
                'Competitions are considered fair and conflicts of interest are avoided.',
                [
                  'The calls for service providers are not only formalistic and are not tailored only for a specific organization. There are no cases in which the procedure seems at first transparent, but organizations linked to the competition organizers receive the contracts. Explanations about conflicts of interest statements are available on request.',
                ],
                [
                  `\nNumber of CSOs that agree that the allocation of state contracts is transparent and fair; `,
                  `\nProminent cases of conflict of interest reported in anticorruption reports by the state/ CSOs or in media by journalists should be reported`,
                ],
                [],
                [
                  'Competition processes for contracts are not fair and there many cases of conflict of interest.',
                  'Most competition processes for contracts are not fair and there few cases of conflict of interest. Explanations about conflict of interest are not available.',
                  'Most competition processes for contracts are fair and explanations about conflict of interest are available upon request.',
                  'Competition processes for contracts are fair and explanations about conflict of interest are available upon request.',
                  'Competition processes for contracts are fair. There are no requests about explanations for conflict of interest.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_3_practice_indicator_3',
                'Indicator 3',
                'State officials have sufficient capacity to organize the procedures.',
                [
                  'The respective state officials are familiar with the procedures through which CSOs can be contracted to provide services and have no technical problems in organizing competitions. There are no cases of authorities deciding not to outsource the provision of services because they are not certain what procedure to use and are concerned that controlling institutions might sanction them. As a good practice, civil state officials are trained to undertake such processes.',
                ],
                [
                  `\nNumber of state officials that have undergone training for state contracting;`,
                  `\nNumber of CSOs which agree that state officials have the sufficient capacity (e.g. knowledge; training) to implement procedures for contracting of services. `,
                ],
                [],
                [
                  'State officials do not have sufficient capacity to organize procedure through which CSOs can be contracted to provide services.',
                  'There are many cases of authorities deciding not to outsource the provision of services because they were not certain what procedure to use.',
                  'State official are trained and familiar with the procedures through which CSOs can be contracted to provide services, but often they have technical problems in organizing competitions/open calls.',
                  'State officials are trained and have sufficient capacity to organize procedure through which CSOs can be contracted to provide services.',
                  'State officials have sufficient capacity to organize procedure through which CSOs can be contracted to provide services, and they are regularly trained on how to undertake such processes.',
                ]
              ),
            ],
          },
          {
            code: 'area_3_sub_area_3_3_standard_4',
            codeName: 'STANDARD 3.3.4',
            originalName:
              'Accountability, Monitoring and Evaluation of Service Provision',
            name: 'There is a clear system of accountability, monitoring and evaluation of service provision.',
            legislation: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_4_legislation_indicator_1',
                'Indicator 1',
                'There is legal possibility for monitoring both spending and the quality of service providers.',
                [
                  'Service providers have to be monitored for both the quality of the services they provide and for the spending related to the provision of these services. Monitors should have the right to inspect the premises in which services are provided, based on prior notification.',
                ],
                [],
                [],
                [
                  'The legal framework does not prescribe the process of monitoring spending and quality of services provided by contractors.',
                  'The legal framework provides the possibility for monitoring both spending and quality of services provided, but has stricter requirements for CSOs.',
                  'The legal framework allows and prescribes the process for monitoring spending related to services provided but does not prescribe the process of monitoring the quality of services provided.',
                  'The legal framework allows and prescribes the process for monitoring spending and quality of services provided, including the right to inspect the premises in which services are provided based on prior notification.',
                  'The legal framework allows and prescribes the process for monitoring spending and quality of services provided, including the right to inspect the premises in which services are provided based on prior notification. In addition, the legal framework takes into consideration the specificities of CSOs on financial issues.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_4_legislation_indicator_2',
                'Indicator 2',
                'There are clear quality standards and monitoring procedures for services.',
                [
                  'Monitoring is based on clear standards and criteria. For the main types of services, there are minimum quality standards which providers should follow. Information on awarded contracts, activities carried out and the number of people served is public.',
                ],
                [],
                [],
                [
                  'The legal framework does not provide standards and criteria for quality of services provided by contractors, nor prescribes a clear monitoring procedure.',
                  'The legal framework provides a clear monitoring procedure for services provided by contractors but does not set quality standards per each service.',
                  'The legal framework provides a clear monitoring procedure for services provided by contractors and general principles on the quality of services provided. However, there are no clear quality standards per each service.',
                  'The legal framework provides clear procedures for monitoring services provided by contractors, based on clearly set standards for the quality of all services.',
                  'The legal framework provides clear procedures for monitoring services provided by contractors, based on clearly set standards for the quality of all services. In addition, the legal framework provides the possibility of regular training on quality standards for contractors.',
                ]
              ),
            ],
            practice: [
              _generateIndicator(
                'area_3_sub_area_3_3_standard_4_practice_indicator_1',
                'Indicator 1',
                'CSOs are not subject to excessive control.',
                [
                  'CSOs are not subject to excessive control, as compared to other providers. Where onsite controls are performed they follow a prior notification which clearly indicates the purpose and timing of the visit.',
                ],
                [
                  `\nNumber of CSOs reporting that they have been subject to excessive control; `,
                  `\nNumber of CSOs reporting that onsite controls were conducted without prior notification;`,
                ],
                [
                  `\nReport cases of major complaints/ cases of excessive control.`,
                ],
                [
                  'CSOs are subject to excessive control as compared to other providers.',
                  'Service providers in general are subject to excessive control. When onsite controls are performed no prior notification is made.',
                  'Service providers in general are subject to excessive control. Prior notification is given to most on-site controls performed by authorities.',
                  'CSOs are not subject to excessive control by state authorities and they are treated impartially. Prior notification indicating the purpose and timing is given for all on-site control visits.',
                  'CSOs are not subject to excessive control by state authorities. Prior notification indicating the purpose and timing is given for all onsite control visits. There are examples showing that state authorities exercise less control over CSOs as compared to other providers.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_4_practice_indicator_2',
                'Indicator 2',
                'Monitoring is performed on a regular basis according to pre-announced procedures and criteria.',
                [
                  'CSOs are clear as to what and to whom they should report. They are regularly monitored.',
                ],
                [
                  `\nNumber of institutions that conduct monitoring on state contracts; `,
                  `\nNumber of institutions that have clear criteria for monitoring;`,
                  `\nNumber of CSOs that agree that accountability criteria are clear.`,
                ],
                [
                  `\nNumber of CSOs that agree that they know the body/ official to whom they should report.`,
                ],
                [
                  'Monitoring of service providers is performed sporadically. There are no pre-announced procedures and/or criteria for monitoring visits.',
                  'There are pre-announced procedures and/or criteria for monitoring visits, but most of monitoring visits are performed sporadically and in contradiction with the existing procedures.',
                  'There are pre-announced procedures and/or criteria for monitoring visits, but there are some cases of monitoring visits that are performed sporadically and in contradiction with the existing procedures.',
                  'Monitoring of service providers is performed on regular basis according to pre-announced procedures and/or criteria for monitoring visits. All monitoring visits are performed based on the existing procedures.',
                  'Monitoring of service providers is performed on regular basis according to pre-announced procedures and/or criteria for monitoring visits. All monitoring visits are performed based on the existing procedures. Monitoring visits are planned and published in the beginning of every year.',
                ]
              ),
              _generateIndicator(
                'area_3_sub_area_3_3_standard_4_practice_indicator_3',
                'Indicator 3',
                'Regular evaluation of quality and effects/impact of services provided is carried out and publicly available.',
                [
                  'State institutions make regular evaluation of the services provided and their impact.',
                  'The results of such evaluations are public and used to shape the policy of the government in the respective area.',
                ],
                [
                  `\nNumber of institutions that make evaluation of the service provision;`,
                  `\nNumber of institutions that publish the evaluations on their websites.`,
                ],
                [],
                [
                  'State institutions do not carry out evaluation processes for the services provided by contractors. There are no reports/information about the quality and impact that provided services have had.',
                  'State institutions do carry out evaluation processes for certain services provided by contractors focusing on quality of services and impact that they have had. However, evaluation of the impact for services provided is not a standard procedure/done regularly by state institutions.',
                  'State institutions do carry out evaluation processes for most services provided by contractors focusing on quality of services and impact that they have had. The evaluation reports are not public and results of evaluation reports are not used for shaping public policies in the respective area.',
                  'State institutions do carry out regular evaluation processes for services provided by contractors focusing on quality of services and impact that they have had. The evaluation reports are public and results of evaluation reports are used for shaping public policies in the respective area.',
                  'State institutions do carry out regular evaluation processes for services provided by contractors focusing on quality of services and impact that they have had. The results of evaluation reports are used for shaping public policies in the respective area, while all evaluation reports are published in a centralized online platform that is accessible to all.',
                ]
              ),
            ],
          },
        ],
      },
    ],
  },
];

export {
  SCORING_TEMPLATE,
  EVALUATION_TABLE,
  STEP_OF_SCORINGS,
  DEFAULT_SCORING_FORM,
};
