import { sendRequest } from '@/Configs';
import { API } from '@constants';
import {
  ICreateScore,
  IUpdateScore,
  ICommentScore,
} from '@interfaces/CountryScoring.interface';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { COUNTRY_SCORE } = API;

export const fetchCountryScoring = async (payload: IPaginationFilter) => {
  return sendRequest(COUNTRY_SCORE.ROOT, 'GET', payload);
};

export const getCountryScoringById = async (id: string) => {
  return sendRequest(`${COUNTRY_SCORE.GET_BY_ID}${id}`, 'GET');
};

export const createCountryScoring = async (payload: ICreateScore) => {
  return sendRequest(`${COUNTRY_SCORE.ROOT}`, 'POST', payload);
};

export const updateCountryScoring = async (
  payload: IUpdateScore,
  id: string
) => {
  return sendRequest(`${COUNTRY_SCORE.UPDATE}${id}`, 'PUT', payload);
};

export const editCountryScoring = async (
  id: string,
  payload: ICommentScore
) => {
  return sendRequest(`${COUNTRY_SCORE.EDITING}${id}`, 'PUT', payload);
};
export const reviewCountryScoring = async (
  payload: ICommentScore,
  id: string
) => {
  return sendRequest(`${COUNTRY_SCORE.EDITING}${id}`, 'PUT', payload);
};

export const sendCountryScoringToAdmin = async (id: string) => {
  return sendRequest(`${COUNTRY_SCORE.WAITING}${id}`, 'PUT');
};

export const unApprovalCountryScoring = async (id: string) => {
  return sendRequest(`${COUNTRY_SCORE.REVIEW}${id}`, 'PUT');
};

export const approvalCountryScoring = async (id: string) => {
  return sendRequest(`${COUNTRY_SCORE.APPROVAL}${id}`, 'PUT');
};

export const removeCountryScoring = async (id: string) => {
  return sendRequest(`${COUNTRY_SCORE.TERMINATE}${id}`, 'PUT');
};
