import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  dataRegional: null,
  dataCountry: null,
  chartForYear: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_REGIONAL_REPORTS_FINDINGS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_REGIONAL_REPORTS_FINDINGS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_NEW_TAB_FINDINGS_REDUCER: {
      return DEFAULT_STATES;
    }

    case ACTION_TYPES.GET_REGIONAL_REPORTS_FINDINGS_SUCCESS:
      return {
        ...state,
        dataRegional: payload?.getDataRegional,
        chartForYear: payload?.chartForYear,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.GET_REGIONAL_REPORTS_FINDINGS_FAILURE: {
      return {
        ...state,
        dataRegional: null,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    }
    case ACTION_TYPES.GET_COUNTRY_REPORTS_FINDINGS_SUCCESS:
      return {
        ...state,
        dataCountry: payload?.getDataCountryReport,
        chartForYear: payload?.chartForYear,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.GET_COUNTRY_REPORTS_FINDINGS_FAILURE: {
      return {
        ...state,
        dataCountry: null,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    }
    default:
      return state;
  }
};
