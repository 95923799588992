import MENU_LIST from './Menu.list';
import {
  USER_SETTINGS,
  ADMIN_SETTINGS,
  REVIEWER_SETTINGS,
} from './UserSettings.list';
import DROPDOWN_LIST from './Dropdown.list';

export default {
  MENU_LIST,
  USER_SETTINGS,
  DROPDOWN_LIST,
  ADMIN_SETTINGS,
  REVIEWER_SETTINGS,
};
