import React from 'react';
import { map } from 'lodash';
import { Box, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { CommonColors } from '@/Themes';

interface ISectionProps {
  color?: string;
  content: string[];
}

const Explanation: React.FC<ISectionProps> = ({ content, color }) => {
  const _renderContent = () =>
    map(content, (item: string, index) => (
      <Box component="p" sx={{ mb: 1 }} key={index}>
        {item}
      </Box>
    ));

  return (
    <Box
      sx={{
        background: color || CommonColors.warmGold,
        p: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'cetner', gap: 2 }}>
        <HelpOutlineIcon />
        <Typography component="h2" sx={{ fontWeight: 'bold' }}>
          Explanation:
        </Typography>
      </Box>

      {_renderContent()}
    </Box>
  );
};

export default Explanation;
