import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
  ENUMS,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    ...DEFAULT_PAGINATION,
    limit: 12,
    status: ENUMS.STATUS.PUBLISH,
    sortBy: 'publishAt',
    orderBy: 'DESC',
  },
  meta: null,
  carouselNews: [],
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_NEWS_CAROUSEL_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_NEWS_CAROUSEL_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_NEWS_CAROUSEL_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.FETCH_NEWS_CAROUSEL_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        carouselNews: payload,
      };
    case ACTION_TYPES.FETCH_NEWS_CAROUSEL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    default:
      return state;
  }
};
