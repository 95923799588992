import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { ROUTERS } from '@/Constants';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { INotificationStructure } from '@/Interfaces/Notification.interface';

// SINGLE ACTIONS
const setGetNotificationsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS_GET_LOADING,
    payload,
  };
};

const setFetchNotificationsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS_FETCH_LOADING,
    payload,
  };
};

const setActionNotificationsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS_ACTION_LOADING,
    payload,
  };
};

const setNotificationsPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS_PAGINATION,
    payload,
  };
};

const setNotificationsMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATIONS_META,
    payload,
  };
};

const setCountNotifications = (payload: { read: number; unread: number }) => {
  return {
    type: ACTION_TYPES.SET_COUNT_NOTIFICATIONS,
    payload,
  };
};

const resetNotificationsReducer = () => {
  return {
    type: ACTION_TYPES.RESET_NOTIFICATIONS_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONS_FAILURE,
  };
};

const fetchNotifications = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setFetchNotificationsLoading(true));
    await API.fetchNotifications(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          let read = 0;
          let unread = 0;
          _.map(items, (item: INotificationStructure) => {
            if (item.status === 'unread') unread++;
            else read++;
          });

          dispatch(setCountNotifications({ read, unread }));
          dispatch(fetchSuccess(items));
          dispatch(setNotificationsMeta(meta));
          dispatch(setNotificationsPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_NOTIFICATION_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_NOTIFICATION_BY_ID_FAILURE,
  };
};

const getNotificationById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetNotificationsLoading(true));
    await API.getNotificationById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const markAsReadSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.MASK_AT_READ_NOFITICATIONS_SUCCESS,
    payload,
  };
};

const markAsReadFail = () => {
  return {
    type: ACTION_TYPES.MASK_AT_READ_NOFITICATIONS_FAILURE,
  };
};

const markAsReadNotifications = (
  payload?: FormData,
  readAll?: boolean,
  recordId?: string,
  type?: string
) => {
  return async (dispatch: any) => {
    dispatch(setActionNotificationsLoading(true));
    await API.maskAsReadNotifications(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(
          response,
          !readAll ? true : false
        );
        if (!result) await dispatch(markAsReadFail());
        else {
          dispatch(markAsReadSuccess(result));
          if (!readAll) {
            if (type === 'country report')
              Utils.redirect(ROUTERS.UPDATE_MY_COUNTRY_REPORT, {
                id: recordId,
              });
            if (type === 'country score')
              Utils.redirect(ROUTERS.UPDATE_SCORING, { id: recordId });
            await dispatch(fetchNotifications());
          }
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(markAsReadFail());
      });
  };
};

export default {
  fetchNotifications,
  getNotificationById,
  markAsReadNotifications,
  resetNotificationsReducer,
};
