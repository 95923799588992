import React from 'react';
import _ from 'lodash';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IDialogMessages {
  title?: string;
  messages?: string | string[];
  isOpen?: boolean;
  onCancel?(): void;
}

const DialogMessages: React.FC<IDialogMessages> = (props: IDialogMessages) => {
  const {
    isOpen = false,
    messages,
    onCancel,
    title = 'Please complete all information',
  } = props;

  const _renderMessages = () => {
    if (messages) {
      if (_.isArray(messages))
        return _.map(messages, (message, index) => (
          <Typography
            key={index}
            component="p"
            color="error"
            sx={{ fontSize: '14px' }}
          >
            {message}
          </Typography>
        ));
      else
        return (
          <Typography component="p" color="error" sx={{ fontSize: '14px' }}>
            {messages}
          </Typography>
        );
    }
    return true;
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <DialogContentText>
          {
            <WarningAmberIcon
              fontSize="large"
              sx={{
                color: 'orange',
              }}
            />
          }
        </DialogContentText>
        {title}
      </DialogTitle>
      <DialogContent>{_renderMessages()}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="fuzzyWuzzyBrown" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMessages;
