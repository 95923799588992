interface ActionTypes {
  [key: string]: string;
}

const generateSyncActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== '') {
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const generateLoadingActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== '') {
      map[`SET_${name}_FETCH_LOADING`] = `SET_${name}_FETCH_LOADING`;
      map[`SET_${name}_GET_LOADING`] = `SET_${name}_GET_LOADING`;
      map[`SET_${name}_ACTION_LOADING`] = `SET_${name}_ACTION_LOADING`;
    }
  });
  return map;
};

const _asyncActions: ActionTypes = generateSyncActions([
  'LOGIN',
  'REFRESH_TOKEN',
  'RESET_PASSWORD',
  'REQUEST_FORGOT_PASSWORD',
  'GET_SELF_PROFILE',
  'UPDATE_SELF_PASSWORD',
  'UPDATE_SELF_PROFILE',
  'VERIFY_ACCOUNT',
  'FETCH_USERS',
  'GET_USER_BY_ID',
  'CREATE_USER',
  'UPDATE_USER',
  'DEACTIVE_USER',
  'ACTIVE_USER',
  'TERMINATE_USER',
  'FETCH_ALL_COUNTRIES',
  'FETCH_NEWS',
  'GET_NEWS_BY_ID',
  'CREATE_NEWS',
  'UPDATE_NEWS',
  'DEACTIVE_NEWS',
  'ACTIVE_NEWS',
  'PUBLISH_NEWS',
  'GET_BACKGROUND_HISTORY',
  'CREATE_BACKGROUND_HISTORY',
  'GET_GUIDELINES',
  'CREATE_GUIDELINES',
  'GET_METHODOLOGY',
  'CREATE_METHODOLOGY',
  'GET_MM_TOOLKIT',
  'CREATE_MM_TOOLKIT',
  'FETCH_NEWS_LIST',
  'FETCH_NEWS_BY_COUNTRY',
  'GET_NEWS_DETAIL',
  'CREATE_COMMENT',
  'UPDATE_COMMENT',
  'FETCH_COMMENT',
  'GET_COMMENT_BY_ID',
  'DEACTIVE_COMMENT',
  'FETCH_CAROUSEL',
  'FETCH_NEWS_CAROUSEL',
  'FETCH_ALL_AVAIABLE_COUNTRY',
  'FETCH_COUNTRY_UNASSIGNED_FOR_REVIEW',
  'FETCH_COUNTRY_UNASSIGNED_FOR_USER',
  'FETCH_USER_SCORING',
  'FETCH_ALL_USER_SCORING',
  'GET_USER_SCORING_BY_ID',
  'CREATE_USER_SCORING',
  'UPDATE_USER_SCORING',
  'FETCH_COUNTRY_REPORTS',
  'UPDATE_COUNTRY_REPORT',
  'CREATE_COUNTRY_REPORT',
  'GET_BY_ID_COUNTRY_REPORT',
  'PUBLISH_COUNTRY_REPORT',
  'UNPUBLISH_COUNTRY_REPORT',
  'ACTIVE_COUNTRY_REPORT',
  'DEACTIVE_COUNTRY_REPORT',
  'TERMINATE_COUNTRY_REPORT',
  'GET_FOR_VISITOR_COUNTRY_REPORT',
  'FETCH_REVIEWER_COUNTRY_REPORT',
  'FETCH_ALL_REVIEWER_COUNTRY_REPORT',
  'GET_REVIEWER_COUNTRY_REPORT_BY_ID',
  'EDITTING_REVIEWER_COUNTRY_REPORT',
  'SEND_APPROVE_REVIEWER_COUNTRY_REPORT',
  'UPDATE_COUNTRY',
  'FETCH_COUNTRIES',
  'GET_COUNTRY_BY_ID',
  'FETCH_REVIEWER_SCORING',
  'FETCH_ALL_REVIEWER_SCORING',
  'GET_REVIEWER_SCORING_BY_ID',
  'UPDATE_REVIEWER_SCORING',
  'EDITTING_REVIEWER_SCORING',
  'SEND_APPROVE_REVIEWER_SCORING',
  'GET_FOOTER',
  'CREATE_FOOTER',
  'FETCH_COUNTRY_SCORING',
  'GET_COUNTRY_SCORING_BY_ID',
  'FETCH_COUNTRY_SCORING',
  'DEACTIVE_COUNTRY',
  'ACTIVE_COUNTRY',
  'APPROVE_COUNTRY_SCORING',
  'UNAPPROVE_COUNTRY_SCORING',
  'REMOVE_COUNTRY_SCORING',
  'CREATE_REGIONAL_REPORT',
  'UPDATE_REGIONAL_REPORT',
  'ACTIVE_REGIONAL_REPORT',
  'PUBLISH_REGIONAL_REPORT',
  'INACTIVE_REGIONAL_REPORT',
  'FETCH_REGIONAL_REPORTS',
  'GET_REGIONAL_REPORT_BY_ID',
  'GET_FOR_VISITOR_REGIONAL_REPORT',
  'FETCH_SOCIAL_LINKS',
  'CREATE_SOCIAL_LINKS',
  'FETCH_FLOAT_SOCIAL',
  'FETCH_REGIONAL_REPORTS_USER',
  'FETCH_NOTIFICATIONS',
  'GET_NOTIFICATION_BY_ID',
  'MASK_AT_READ_NOFITICATIONS',
  'FETCH_COMPARE_EXPLORE_BY_YEARS',
  'FETCH_COMPARE_EXPLORE_BY_COUNTRIES',
  'FETCH_GALLERIES',
  'GET_GALLERY_BY_ID',
  'CREATE_GALLERY',
  'UPDATE_GALLERY',
  'DEACTIVE_GALLERY',
  'ACTIVE_GALLERY',
  'TERMINATE_GALLERY',
  'GET_REGIONAL_REPORTS_FINDINGS',
  'GET_COUNTRY_REPORTS_FINDINGS',
]);

const _loadingActions: ActionTypes = generateLoadingActions([
  'AUTH',
  'USER_MANAGEMENT',
  'COUNTRY',
  'NEWS_MANAGEMENT',
  'BACKGROUND_HISTORY',
  'GUIDELINES',
  'METHODOLOGY',
  'MM_TOOLKIT',
  'USER_NEWS',
  'COMMENT',
  'SET_NEWS_CAROUSEL',
  'USER_SCORING',
  'COUNTRY_REPORTS',
  'REVIEWER_COUNTRY_REPORT',
  'REVIEWER_SCORING',
  'FOOTER',
  'COUNTRY_MANAGEMENT',
  'COUNTRY_SCORING_MANAGEMENT',
  'REGIONAL_REPORTS',
  'SOCIAL_LINKS',
  'FLOAT_SOCIAL',
  'REGIONAL_REPORTS_USER',
  'NOTIFICATIONS',
  'NOTIFICATIONS_DASHBOARD',
  'COMPARE_AND_EXPORE',
  'GALLERY',
  'REGIONAL_REPORTS_FINDINGS',
  'COUNTRY_REPORTS_FINDINGS',
]);

const _singleActions: ActionTypes = {
  // Locale actions
  SET_LOCALE: 'SET_LOCALE',
  // Widget actions
  SET_NOTIFICATION_MESSAGE: 'SET_NOTIFICATION_MESSAGE',
  SET_START_LOADING: 'SET_START_LOADING',
  SET_ALERT_MESSAGE: 'SET_ALERT_MESSAGE',
  // Authentication actions
  LOGOUT: 'LOGOUT',
  SET_RESET_PASSWORD_STEP: 'SET_RESET_PASSWORD_STEP',
  RESET_CHANGE_PASSWORD_STATUS: 'RESET_CHANGE_PASSWORD_STATUS',

  // Users management actions
  SET_USER_TABLE_PAGINATION: 'SET_USER_TABLE_PAGINATION',
  SET_USER_TABLE_META: 'SET_USER_TABLE_META',
  RESET_USER_REDUCER: 'RESET_USER_REDUCER',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  // News actions
  SET_NEWS_TABLE_PAGINATION: 'SET_NEWS_TABLE_PAGINATION',
  SET_NEWS_TABLE_META: 'SET_NEWS_TABLE_META',
  RESET_NEWS_REDUCER: 'RESET_NEWS_REDUCER',
  RESET_USER_NEWS_BY_COUNTRY: 'RESET_USER_NEWS_BY_COUNTRY',
  SET_NEWS_DETAIL: 'SET_NEWS_DETAIL',
  // User news
  SET_USER_NEWS_DETAIL: 'SET_USER_NEWS_DETAIL',
  SET_USER_NEWS_PAGINATION: 'SET_USER_NEWS_PAGINATION',
  SET_NEWS_CAROUSEL_FETCH_LOADING: 'SET_NEWS_CAROUSEL_FETCH_LOADING',
  SET_USER_NEWS_META: 'SET_USER_NEWS_META',
  RESET_USER_NEWS_DETAIL: 'RESET_USER_NEWS_DETAIL',
  //Country Reports Actions
  RESET_COUNTRY_REPORTS_REDUCER: 'RESET_COUNTRY_REPORTS_REDUCER',
  SET_COUNTRY_REPORT_TABLE_PAGINATION: 'SET_COUNTRY_REPORT_TABLE_PAGINATION',
  SET_COUNTRY_REPORT_TABLE_META: 'SET_COUNTRY_REPORT_TABLE_META',
  SET_COUNTRY_REPORT_PREVIOUS: 'SET_COUNTRY_REPORT_PREVIOUS',
  RESET_NEW_TAB_FINDINGS_REDUCER: 'RESET_NEW_TAB_FINDINGS_REDUCER',
  SET_DATA_FINDINGS: 'SET_DATA_FINDINGS',
  // Background History actions
  RESET_BACKGROUND_HISTORY_REDUCER: 'RESET_BACKGROUND_HISTORY_REDUCER',
  // Guidelines actions
  RESET_GUIDELINES_REDUCER: 'RESET_GUIDELINES_REDUCER',
  // Methodology actions
  RESET_METHODOLOGY_REDUCER: 'RESET_METHODOLOGY_REDUCER',
  //Footer actions
  RESET_FOOTER_REDUCER: 'RESET_FOOTER_REDUCER',
  // MMToolkit actions
  RESET_MM_TOOLKIT_REDUCER: 'RESET_MM_TOOLKIT_REDUCER',
  //Comment
  RESET_COMMENT_REDUCER: 'RESET_COMMENT_REDUCER',
  // News carousel
  SET_NEWS_CAROUSEL: 'SET_NEWS_CAROUSEL',
  FETCH_NEWS_CAROUSEL: 'FETCH_NEWS_CAROUSEL',
  SET_NEWS_CAROUSEL_META: 'SET_NEWS_CAROUSEL_META',
  SET_NEWS_CAROUSEL_PAGINATION: 'SET_NEWS_CAROUSEL_PAGINATION',
  // User Scroring
  SET_USER_SCORING_PAGINATION: 'SET_USER_SCORING_PAGINATION',
  SET_USER_SCORING_META: 'SET_USER_SCORING_META',
  RESET_USER_SCORING_REDUCER: 'RESET_USER_SCORING_REDUCER',
  //Countries management actions
  SET_COUNTRY_TABLE_PAGINATION: 'SET_COUNTRY_TABLE_PAGINATION',
  SET_COUNTRY_TABLE_META: 'SET_COUNTRY_TABLE_META',
  RESET_COUNTRY_REDUCER: 'RESET_COUNTRY_REDUCER',
  // Reviewer Scoring
  SET_REVIEWER_SCORING_PAGINATION: 'SET_REVIEWER_SCORING_PAGINATION',
  SET_REVIEWER_SCORING_META: 'SET_REVIEWER_SCORING_META',
  RESET_REVIEWER_SCORING_REDUCER: 'RESET_REVIEWER_SCORING_REDUCER',
  //Reviewer Country Report
  SET_REVIEWER_COUNTRY_REPORT_PAGINATION:
    'SET_REVIEWER_COUNTRY_REPORT_PAGINATION',
  SET_REVIEWER_COUNTRY_REPORT_META: 'SET_REVIEWER_COUNTRY_REPORT_META',
  RESET_REVIEWER_COUNTRY_REPORT_REDUCER:
    'RESET_REVIEWER_COUNTRY_REPORT_REDUCER',
  // Regional report
  SET_DATA_SUBAREAS: 'SET_DATA_SUBAREAS',
  SET_DATA_RECOMMEN: 'SET_DATA_RECOMMEN',
  SET_DATA_OVERVIEW: 'SET_DATA_OVERVIEW',
  SET_DATA_ACKNOWLEDGMENTS: 'SET_DATA_ACKNOWLEDGMENTS',
  SET_DATA_BACKGROUND: 'SET_DATA_BACKGROUND',
  SET_DATA_RECOMMENDATIONS: 'SET_DATA_RECOMMENDATIONS',
  SET_DATA_REPORT_PDF: 'SET_DATA_REPORT_PDF',
  SET_DATA_COUNTRY_FULL_OPTIONS: 'SET_DATA_COUNTRY_FULL_OPTIONS',
  SET_DATA_FINDINGS_RECOMMENDATIONS: 'SET_DATA_FINDINGS_RECOMMENDATIONS',
  SET_DATA_COUNTRY_OPTIONS: 'SET_DATA_COUNTRY_OPTIONS',
  CLEAR_DATA_COUNTRY_REPORT: 'CLEAR_DATA_COUNTRY_REPORT',
  RESET_REGIONAL_REPORT_REDUCER: 'RESET_REGIONAL_REPORT_REDUCER',
  SET_REGIONAL_REPORT_META: 'SET_COUNTRY_REPORT_TABLE_META',
  SET_COUNTRY_REGIONAL_REPORT_PAGINATION:
    'SET_COUNTRY_REGIONAL_REPORT_PAGINATION',
  //Admin Scoring
  SET_COUNTRY_SCORING_PAGINATION: 'SET_USER_SCORING_PAGINATION',
  SET_COUNTRY_SCORING_META: 'SET_USER_SCORING_META',
  SET_COUNTRY_SCORING_MANAGEMENT: 'SET_COUNTRY_SCORING_MANAGEMENT',
  RESET_COUNTRY_SCORING_REDUCER: 'RESET_USER_SCORING_REDUCER',
  SET_COUNTRY_SCORING: 'SET_COUNTRY_SCORING',
  //Notifications
  RESET_NOTIFICATIONS_REDUCER: 'RESET_NOTIFICATIONS_REDUCER',
  SET_NOTIFICATIONS_PAGINATION: 'SET_NOTIFICATIONS_PAGINATION',
  SET_NOTIFICATIONS_META: 'SET_NOTIFICATIONS_META',
  SET_COUNT_NOTIFICATIONS: 'SET_COUNT_NOTIFICATIONS',
  SET_TOTAL_UNREAD_NOTIFICATIONS: 'SET_TOTAL_UNREAD_NOTIFICATIONS',
  SET_NOTIFICATIONS_DASHBOARD_PAGINATION:
    'SET_NOTIFICATIONS_DASHBOARD_PAGINATION',
  SET_NOTIFICATIONS_DASHBOARD_META: 'SET_NOTIFICATIONS_DASHBOARD_META',
  // Compare & Explore actions
  RESET_COMPARE_EXPLORE_REDUCER: 'RESET_COMPARE_EXPLORE_REDUCER',
  SAVE_COMPARE_EXPLORE_BY_COUNTRIES: 'SAVE_COMPARE_EXPLORE_BY_COUNTRIES',
  SAVE_COMPARE_EXPLORE_BY_YEARS: 'SAVE_COMPARE_EXPLORE_BY_YEARS',
  // Gallery management actions
  SET_GALLERY_PAGINATION: 'SET_GALLERY_PAGINATION',
  SET_GALLERY_META: 'SET_GALLERY_META',
  RESET_GALLERY_REDUCER: 'RESET_GALLERY_REDUCER',
};

const ACTION_TYPES = {
  ..._asyncActions,
  ..._singleActions,
  ..._loadingActions,
};

export default ACTION_TYPES;
