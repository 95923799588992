import React, { useMemo, useRef } from 'react';
import 'chart.js/auto';
import { ChartOptions, ChartData } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

interface ISubtitle {
  display?: boolean;
  align?: 'start' | 'center' | 'end';
  position?: 'top' | 'right' | 'bottom' | 'left';
  text?: string;
}
interface ISectionProps {
  data: ChartData<'bar'>;
  title: string;
  verticalText?: string;
  horizontalText?: string;
  subtitle?: ISubtitle;
  isShowLegend?: boolean;
  axis?: 'x' | 'y';
  onClick?(data: any): void;
}

const BarChart: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const {
    data,
    title,
    verticalText,
    horizontalText,
    subtitle = {
      display: false,
      align: 'end',
      position: 'bottom',
      text: '(*) Click on legend to show/hide element(s)',
    },
    isShowLegend = true,
    axis = 'x',
    onClick,
  } = props;
  const chartRef = useRef();

  const options: ChartOptions<'bar'> = useMemo(() => {
    return {
      responsive: true,
      interaction: {
        intersect: false,
      },
      indexAxis: axis,
      plugins: {
        filler: {
          propagate: false,
        },
        title: {
          display: true,
          text: title,
          align: 'start',
          fullSize: true,
          font: {
            size: 16,
          },
        },
        subtitle,
        legend: {
          display: isShowLegend,
          position: 'top',
          labels: {
            usePointStyle: true,
          },
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: Boolean(horizontalText),
            text: horizontalText,
          },
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          display: true,
          title: {
            display: Boolean(verticalText),
            text: verticalText,
          },
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            font: {
              size: 10,
            },
          },
        },
      },
      onHover: (event, chartElement) => {
        (event?.native?.target as HTMLInputElement).style.cursor =
          chartElement[0] ? 'pointer' : 'default';
      },
    };
  }, [props]);

  // Event
  const handleEvent = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (chartRef?.current) {
      const element = getElementAtEvent(chartRef.current, e)[0];
      if (!element) return;
      const targetKey = data.labels ? data.labels[element.index] : '';
      const value = data.datasets[element.datasetIndex].data[element.index];
      const callbackData = {
        targetKey,
        value,
      };
      if (onClick) onClick(callbackData);
    }
  };

  return (
    <Bar
      ref={chartRef}
      options={{ ...options }}
      data={data}
      onClick={(e) => handleEvent(e)}
    />
  );
};

export default BarChart;

// cubicInterpolationMode: 'monotone',
// tension: 0.4,
// use for smooth line
