import React from 'react';
import { TextEditor } from '@/Components/Common';
interface ISectionProps {
  disabled?: boolean;
  payload: string;
  onChange?(newValue?: string): void;
}

const APConclusionsAndRecommendations: React.FC<ISectionProps> = ({
  payload,
  onChange,
  disabled,
}) => {
  const renderMain = () => {
    return (
      <TextEditor
        disabled={disabled}
        value={payload}
        onChange={(newValue: string) => onChange && onChange(newValue)}
      />
    );
  };

  return renderMain();
};

export default APConclusionsAndRecommendations;
