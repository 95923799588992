import { sendRequest } from '@/Configs';
import { API } from '@constants';
import {
  ICompareAcrossCountries,
  ICompareAcrossYears,
} from '@interfaces/CompareAndExplore.interface';
const { COMPARE_EXPLORE } = API;

export const fetchCompareAndExploreByYears = async (
  payload: ICompareAcrossYears
) => {
  return sendRequest(COMPARE_EXPLORE.YEARS, 'GET', payload);
};

export const fetchCompareAndExploreByCountries = async (
  payload: ICompareAcrossCountries
) => {
  return sendRequest(COMPARE_EXPLORE.COUNTRIES, 'GET', payload);
};
