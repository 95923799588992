import { ROUTERS, BASIC_ROUTERS } from '@constants';

const USER_MANAGEMENT_BREADCRUMB = [
  {
    label: 'User Management',
    value: ROUTERS.USER_MANAGEMENT,
    type: 'parent',
  },
  {
    label: 'Create User',
    value: ROUTERS.CREATE_USER,
    type: 'child',
  },
  {
    label: 'Update User',
    value: ROUTERS.UPDATE_USER,
    type: 'child',
  },
];

const NEWS_MANAGEMENT_BREADCRUMB = [
  {
    label: 'News Management',
    value: ROUTERS.NEWS_MANAGEMENT,
    type: 'parent',
  },
  {
    label: 'Create News',
    value: ROUTERS.NEW_POST,
    type: 'child',
  },
  {
    label: 'Update News',
    value: ROUTERS.UPDATE_NEWS,
    type: 'child',
  },
];

const NEWS_BREADCRUMB = [
  {
    label: 'News',
    value: BASIC_ROUTERS.NEWS,
    type: 'parent',
  },
  {
    label: 'Detail',
    value: BASIC_ROUTERS.NEWS_DETAIL,
    type: 'child',
  },
];

const REGIONAL_REPORT_BREADCRUMB = [
  {
    label: 'Regional Report',
    value: ROUTERS.REGIONAL_REPORT_MANAGEMENT,
    type: 'parent',
  },
  {
    label: 'New Regional Report',
    value: ROUTERS.NEW_REPORT,
    type: 'child',
  },
  {
    label: 'Update Regional Report',
    value: ROUTERS.UPDATE_REGIONAL_REPORT,
    type: 'child',
  },
];

const COUNTRY_REPORT_BREADCRUMB = [
  {
    label: 'Country Report Management',
    value: ROUTERS.COUNTRY_REPORT_MANAGEMENT,
    type: 'parent',
  },
  {
    label: 'Country Report Detail',
    value: ROUTERS.COUNTRY_REPORT_DETAIL,
    type: 'child',
  },
  {
    label: 'Create New Country Report',
    value: ROUTERS.CREATE_COUNTRY_REPORT,
    type: 'child',
  },
];

const UP_COUNTRY_REPORT_BREADCRUMB = [
  {
    label: 'My Country Reports',
    value: ROUTERS.MY_REPORT,
    type: 'parent',
  },
  {
    label: 'Country Report Detail',
    value: ROUTERS.UPDATE_MY_COUNTRY_REPORT,
    type: 'child',
  },
  {
    label: 'New Country Report',
    value: ROUTERS.NEW_COUNTRY_REPORT,
    type: 'child',
  },
];

const RP_COUNTRY_REPORT_BREADCRUMB = [
  {
    label: 'Country Report',
    value: ROUTERS.REVIEW_COUNTRY_REPORT,
    type: 'parent',
  },
  {
    label: 'Country Report Details',
    value: ROUTERS.REVIEW_COUNTRY_REPORT_DETAIL,
    type: 'child',
  },
];

const COUNTRY_MANAGEMENT_BREADCRUMB = [
  {
    label: 'Country Management',
    value: ROUTERS.COUNTRY_MANAGEMENT,
    type: 'parent',
  },
  {
    label: 'Update Country',
    value: ROUTERS.UPDATE_COUNTRY,
    type: 'child',
  },
];

const REVIEWER_SCORING_BREADCRUMB = [
  {
    label: 'Country Scoring',
    value: ROUTERS.REVIEW_SCORING,
    type: 'parent',
  },
  {
    label: 'Scoring Detail',
    value: ROUTERS.REVIEW_SCORING_DETAIL,
    type: 'child',
  },
];

const ADMIN_SCORING_BREADCRUMB = [
  {
    label: 'Country Scoring Management',
    value: ROUTERS.COUNTRY_SCORING_MANAGEMENT,
    type: 'parent',
  },
  {
    label: 'Scoring Detail',
    value: ROUTERS.ADMIN_COUNTRY_SCORING_DETAIL,
    type: 'child',
  },
];

const USER_SCORING_BREADCRUMB = [
  {
    label: 'My Scoring',
    value: ROUTERS.MY_SCORING,
    type: 'parent',
  },
  {
    label: 'Scoring Detail',
    value: ROUTERS.UPDATE_SCORING,
    type: 'child',
  },
  {
    label: 'New Scoring',
    value: ROUTERS.SCORING_REPORT,
    type: 'child',
  },
];

const DASHBOARD_REVIEWER_BREADCRUMB = [
  {
    label: 'Dashboard Reviewer',
    value: ROUTERS.REVIEWER_DASHBOARD,
    type: 'parent',
  },
];

export {
  USER_MANAGEMENT_BREADCRUMB,
  NEWS_MANAGEMENT_BREADCRUMB,
  NEWS_BREADCRUMB,
  REGIONAL_REPORT_BREADCRUMB,
  COUNTRY_REPORT_BREADCRUMB,
  COUNTRY_MANAGEMENT_BREADCRUMB,
  USER_SCORING_BREADCRUMB,
  REVIEWER_SCORING_BREADCRUMB,
  ADMIN_SCORING_BREADCRUMB,
  DASHBOARD_REVIEWER_BREADCRUMB,
  UP_COUNTRY_REPORT_BREADCRUMB,
  RP_COUNTRY_REPORT_BREADCRUMB,
};
