import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  Stack,
  Box,
  Grid,
  Button,
  Tab,
  TextField,
  Breadcrumbs,
  Typography,
  Divider,
} from '@mui/material';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';
import { PreviewPDF, SkeletonLoading } from '@/Components/Common';
import Finding from './Finding';
import CivilSociety from './CivilSociety';
import { RegionalReportsUserAction } from '@actions';

import { useTypedDispatch, RootState } from '@/store';
import { NoDataWereFound } from '@/Components/Common';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import KeyFindings from './KeyFindings';
import { CommonColors } from '@/Themes';
const { getForVisitorRegionalReport } = RegionalReportsUserAction;

const listTab = [
  {
    label: 'Executive Summary',
    value: 'findingsAndRecommendations',
  },
  { label: 'CS Overview', value: 'civilSocietyOverview' },
  { label: 'Background', value: 'background' },
  {
    label: 'Findings',
    value: 'findingsBasedOnAreasAndSubareas',
  },
  { label: 'Recommendations', value: 'acknowledgements' },
  { label: 'PDF', value: 'previewPDF' },
];

const RegionalReport: React.FC = () => {
  const dispatch = useTypedDispatch();

  const dataRegional = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORTS_USER, 'dataRegional')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORTS_USER, 'isGetLoading')
  );

  const [value, setValue] = useState<string>('findingsAndRecommendations');
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');

  const [dataDefault, setDataDefault] = useState<any>(null);

  useEffect(() => {
    if (_.isEmpty(selectedYear)) dispatch(getForVisitorRegionalReport(''));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataRegional)) {
      setDataDefault(dataRegional);
      setSelectedYear(dataRegional?.year);
    } else {
      setDataDefault(null);
    }
  }, [dataRegional]);

  useEffect(() => {
    if (selectedYear && dataRegional?.year !== selectedYear) {
      dispatch(getForVisitorRegionalReport(selectedYear));
    }
  }, [selectedYear]);

  const handleFieldChange = (newValue: Date | null) => {
    if (newValue) {
      if (dayjs(newValue).isValid()) {
        setSelectedYear(_.toString(new Date(newValue).getFullYear()));
        setIsValidDateMsg('');
      } else setIsValidDateMsg('Year invalid date/time format');
    } else {
      setIsValidDateMsg('Please choose year to view reports');
    }
  };
  const handleChange = (_event: React.SyntheticEvent, newValue: string) =>
    setValue(newValue);

  const _renderTopSection = () => (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2 }}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            Regional Report
          </Typography>
        </Breadcrumbs>
        <DatePicker
          inputFormat="YYYY"
          views={['year']}
          label="Year"
          value={selectedYear || null}
          onChange={handleFieldChange}
          minDate={new Date('2013')}
          maxDate={new Date()}
          renderInput={(params: any) => (
            <TextField
              {...params}
              sx={{ width: 150, mb: 2 }}
              error={!!isValidDateMsg}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ background: CommonColors.mainColor }} />
      </Grid>
    </Grid>
  );

  const _renderBottomSection = () => {
    return (
      <Grid item xs={12} className="tab-container">
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}
          >
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {listTab.map((tab, index) => {
                return (
                  <Tab
                    key={`tab-${index}`}
                    sx={{ fontWeight: 'bold' }}
                    label={tab.label}
                    value={tab.value}
                    disabled={!selectedYear}
                  />
                );
              })}
              {/* <Tab
                style={{ color: CommonColors.oceanGreen }}
                disabled={isGetLoading || !dataDefault?.fileReport?.path}
                onClick={() => window.open(dataDefault?.fileReport?.path)}
                label={<CloudDownloadIcon />}
              /> */}
            </TabList>
          </Box>
          {selectedYear && (
            <>
              <TabPanel value="civilSocietyOverview">
                <CivilSociety />
              </TabPanel>
              <TabPanel value="findingsAndRecommendations">
                {dataDefault?.recommendation ? (
                  <KeyFindings payload={dataDefault?.recommendation} />
                ) : (
                  <NoDataWereFound height={300} />
                )}
              </TabPanel>
              <TabPanel value="findingsBasedOnAreasAndSubareas">
                <Finding year={selectedYear} />
              </TabPanel>
              <TabPanel value="acknowledgements">
                {dataDefault?.acknowledgment ? (
                  <Box
                    sx={{
                      img: {
                        maxWidth: '100%',
                        objectFit: 'cover',
                      },
                    }}
                    style={{ wordWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{
                      __html: dataDefault?.acknowledgment,
                    }}
                  />
                ) : (
                  <NoDataWereFound height={300} />
                )}
              </TabPanel>
              <TabPanel value="background">
                {dataDefault?.background ? (
                  <Box
                    sx={{
                      img: {
                        maxWidth: '100%',
                        objectFit: 'cover',
                      },
                    }}
                    style={{ wordWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{
                      __html: dataDefault?.background,
                    }}
                  />
                ) : (
                  <NoDataWereFound height={300} />
                )}
              </TabPanel>
              <TabPanel value="previewPDF">
                {dataDefault?.fileReport?.path ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          window.open(dataDefault?.fileReport?.path)
                        }
                        sx={{ mb: 2 }}
                      >
                        View Full Screen
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <PreviewPDF file={dataDefault?.fileReport?.path} />
                    </Grid>
                  </Grid>
                ) : (
                  <NoDataWereFound />
                )}
              </TabPanel>
            </>
          )}
          {!selectedYear && <NoDataWereFound />}
        </TabContext>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Stack>
        {_renderTopSection()}
        <Grid container alignItems="center">
          {/* <Grid item xs={12}>
            <Typography variant="subtitle2" color="error">
              {!_.isEmpty(selectedYear) ? isValidDateMsg : ''}
            </Typography>
          </Grid> */}
          <Grid item xs={12} mt={3}>
            <Grid container>
              {!isGetLoading ? (
                _renderBottomSection()
              ) : (
                <SkeletonLoading numberRender={5} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default RegionalReport;
