import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
  ENUMS,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    ...DEFAULT_PAGINATION,
    page: 1,
    limit: 12,
    status: ENUMS.STATUS.ACTIVE,
  },
  meta: null,
  galleries: [],
  gallery: null,
  isUploadSuccess: false,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_GALLERY_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_GALLERY_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_GALLERY_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_GALLERY_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_GALLERY_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_GALLERY_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_GALLERIES_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        isUploadSuccess: false,
        galleries: payload,
      };
    case ACTION_TYPES.FETCH_GALLERIES_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
        isUploadSuccess: false,
      };

    case ACTION_TYPES.GET_GALLERY_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        gallery: payload,
      };
    case ACTION_TYPES.GET_GALLERY_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        isUploadSuccess: true,
      };
    case ACTION_TYPES.CREATE_GALLERY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        isUploadSuccess: false,
      };
    case ACTION_TYPES.DEACTIVE_GALLERY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DEACTIVE_GALLERY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_GALLERY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_GALLERY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.TERMINATE_GALLERY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.TERMINATE_GALLERY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };

    default:
      return state;
  }
};
