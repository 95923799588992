import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router';
import _ from 'lodash';
import {
  Toolbar,
  Avatar,
  Grid,
  IconButton,
  Card,
  CardHeader,
  CardMedia,
  Drawer,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Badge,
  Box,
  Button,
  Tooltip,
  MenuItem,
  Typography,
  Menu,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { CommonStyles } from '@/Components/Common';
import NoAvatar from '@/Assets/NoAvatar/NoAvatar.png';

import Utils from '@utils';
import { LIST, ROUTERS, BASIC_ROUTERS, ENUMS } from '@constants';
import { useTypedDispatch, RootState } from '@/store';
import { AuthActions } from '@actions';
import { ISideBar } from '@interfaces/Menu.interface';
import { INotificationStructure } from '@/Interfaces/Notification.interface';

import { UserNotifications } from '@/Components/LayoutPart';
import {
  ADMIN_SETTINGS,
  REVIEWER_SETTINGS,
  USER_SETTINGS,
} from '@/Constants/Lists/UserSettings.list';

const { logout } = AuthActions;

const { MENU_LIST } = LIST;

interface SectionProps {
  payload: INotificationStructure[];
}

const AppBarMobile: React.FC<SectionProps> = ({ payload }) => {
  const path = useLocation().pathname;
  const dispatch = useTypedDispatch();
  const [open, setIsOpen] = useState(false);
  const [openItem, setOpenItem] = useState({});
  const userData = Utils.getSavedUserData();
  const fullname = Utils.getFullName();
  const email = Utils.getSavedUserEmail();
  const roleName = Utils.getUserRole();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const count: { read: number; unread: number } = useSelector(
    (state: RootState) => _.get(state.USER_NOTIFICATION, 'count')
  );

  const [isOpenNotifications, setIsOpenNotifications] =
    useState<boolean>(false);

  const handleClickTab = (value: string) => {
    if (value === ROUTERS.AUTH) dispatch(logout());
    Utils.redirect(value);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleProfile = () => {
    switch (roleName) {
      case ENUMS.ROLES.ADMIN:
        return Utils.redirect(ROUTERS.ACCOUNT_ADMIN);
      case ENUMS.ROLES.REVIEWER:
        return Utils.redirect(ROUTERS.ACCOUNT_REVIEWER);
      case ENUMS.ROLES.USER:
        return Utils.redirect(ROUTERS.PROFILE);
      default:
        return Utils.redirect(ROUTERS.HOME);
    }
  };

  const checkRoleName = () => {
    switch (roleName) {
      case ENUMS.ROLES.ADMIN:
        return ADMIN_SETTINGS;
      case ENUMS.ROLES.REVIEWER:
        return REVIEWER_SETTINGS;
      case ENUMS.ROLES.USER:
        return USER_SETTINGS;
      default:
        return [];
    }
  };

  const handleExpandClick = (value: string) =>
    setOpenItem({
      ...openItem,
      [value]: !openItem[value as keyof typeof openItem],
    });

  const _renderSettings = () => {
    const settings: any = checkRoleName();
    return _.map(settings, (setting, index) => {
      if (setting.label === 'Notifications') {
        return (
          <MenuItem
            key={index}
            onClick={() => {
              setIsOpenNotifications(true);
              setAnchorElUser(null);
            }}
          >
            <Badge
              badgeContent={count?.unread || 0}
              color="primary"
              sx={{ mr: 2 }}
            >
              {setting.icon}
            </Badge>
            <Typography align="center" sx={{ textTransform: 'capitalize' }}>
              {setting.label}
            </Typography>
          </MenuItem>
        );
      }
      return (
        <MenuItem key={index} onClick={() => handleClickTab(setting.value)}>
          {setting.icon}
          <Typography align="center" sx={{ textTransform: 'capitalize' }}>
            {setting.label}
          </Typography>
        </MenuItem>
      );
    });
  };

  const _renderSubOptions = (items: ISideBar[], key: number) => {
    return _.map(items, (item) => {
      const selected = item.value === path;
      return (
        <ListItemButton
          sx={{ pl: 4, ...CommonStyles.hoverBorderEffect }}
          key={`${key}${item.label}`}
          selected={selected}
          onClick={() => Utils.redirect(item.value)}
        >
          <ListItemText primary={item.label} />
        </ListItemButton>
      );
    });
  };

  const _renderMenu = () => {
    return _.map(MENU_LIST.USER_MENU, (item, index) => {
      const isOpenSub = openItem[item.value as keyof typeof openItem];
      return (
        <Box key={index}>
          <ListItemButton
            key={index}
            sx={CommonStyles.hoverBorderEffect}
            onClick={() => {
              if (item.subOptions) handleExpandClick(item.value);
              else handleClickTab(item.value);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
            {item.subOptions &&
              (isOpenSub ? <ExpandMore /> : <ChevronRightIcon />)}
          </ListItemButton>
          {item.subOptions && (
            <Collapse
              in={isOpenSub}
              timeout="auto"
              unmountOnExit
              key={item.label}
            >
              <List component="div">
                {_renderSubOptions(item.subOptions, index)}
              </List>
            </Collapse>
          )}
        </Box>
      );
    });
  };

  const _renderDrawer = () => {
    return (
      <Grid container spacing={1} direction="column" p={2} mt="50px">
        {userData && (
          <>
            <Grid item onClick={() => handleProfile()}>
              <Card sx={{ width: 1, cursor: 'pointer' }}>
                <CardHeader
                  title={fullname || 'Anonymous'}
                  subheader={email || ''}
                />
                <CardMedia
                  component="img"
                  height="100"
                  sx={{
                    objectFit: userData?.avatar?.path ? 'cover' : 'contain',
                    p: 1,
                  }}
                  image={userData?.avatar?.path || NoAvatar}
                  alt="Avatar"
                />
              </Card>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item>
          <List component="nav">
            {_renderMenu()}
            <Divider />
            {userData && (
              <ListItemButton
                sx={CommonStyles.hoverBorderEffect}
                onClick={() => dispatch(logout())}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </ListItemButton>
            )}
          </List>
        </Grid>
      </Grid>
    );
  };

  const _renderNotifications = () => (
    <UserNotifications
      width="75%"
      payload={payload}
      open={isOpenNotifications}
      onClose={() => setIsOpenNotifications(false)}
    />
  );

  return (
    <Toolbar sx={CommonStyles.displayInMobile}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setIsOpen(!open)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        {userData && (
          <Grid item>
            <Badge badgeContent={count?.unread || 0} color="primary">
              <Tooltip title="Open notifications">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="userAvatar"
                    src={userData?.avatar?.path || NoAvatar}
                  />
                </IconButton>
              </Tooltip>
            </Badge>
          </Grid>
        )}
        {!userData && path !== BASIC_ROUTERS.AUTH && (
          <Button
            variant="contained"
            color="burntSienna"
            onClick={() => Utils.replace(ROUTERS.AUTH)}
          >
            Login
          </Button>
        )}
      </Grid>
      <Drawer
        anchor="top"
        variant="temporary"
        open={open}
        onClose={() => setIsOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box' },
        }}
      >
        {_renderDrawer()}
        {userData && (
          <Menu
            sx={{ mt: '50px', zIndex: 10000 }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}
          >
            <MenuItem
              sx={{ width: 300 }}
              onClick={() => Utils.redirect(ROUTERS.PROFILE)}
            >
              <Card sx={{ width: 1 }}>
                <CardHeader
                  title={fullname || 'Anonymous'}
                  subheader={email || ''}
                />
                <CardMedia
                  component="img"
                  height="100"
                  sx={{
                    objectFit: userData?.avatar?.path ? 'cover' : 'contain',
                    p: 1,
                  }}
                  image={userData?.avatar?.path || NoAvatar}
                  alt="Avatar"
                />
              </Card>
            </MenuItem>
            {_renderSettings()}
            {_renderNotifications()}
          </Menu>
        )}
      </Drawer>
    </Toolbar>
  );
};

export default AppBarMobile;
