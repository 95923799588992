import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import {
  Divider,
  Grid,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';

import DefaultLayout from '@/Components/DefaultLayout';

import { ROUTERS } from '@/Constants';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';
import { ActiveBreadCrumb } from '@/Components/Common';
import { CountryActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { ICountryData } from '@/Interfaces/Country.interface';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { Popper } from '@/Components/LayoutPart';

const DEFAULT_COUNTRY_DATA: ICountryData = {
  id: '',
  name: '',
  description: '',
  extraData: '',
  status: '',
  reviewer: null,
};

const { getCountryById, updateCountry } = CountryActions;

const UpdateCountry = () => {
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;

  const [colorPicker, setColorPicker] = useState('#000');
  const [renderKey, setRenderKey] = useState<number>(Math.random());

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'isActionLoading')
  );
  const countryDetail: any = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'countryDetail')
  );

  const [countryData, setCountryData] =
    useState<ICountryData>(DEFAULT_COUNTRY_DATA);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getCountryById(locationState?.id));
    } else Utils.redirect(ROUTERS.COUNTRY_MANAGEMENT);
  }, [locationState]);

  useEffect(() => {
    if (countryDetail) {
      setCountryData(countryDetail);
      setRenderKey(Math.random());
    }
    if (countryDetail?.extraData && countryDetail?.extraData?.color) {
      setColorPicker(countryDetail.extraData.color);
    }
  }, [countryDetail]);

  //Events
  // const handleOnChange = (key: string, value: string | number) => {
  //   setCountryData({ ...countryData, [key]: value });
  // };

  const handleOnChangeGeoJson = (value: string) => {
    setCountryData({
      ...countryData,
      extraData: { ...countryData.extraData, code: value },
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      updateCountry({
        ...countryData,
        extraData: { ...countryData.extraData, color: colorPicker },
      })
    );
  };
  const _renderTitle = () => {
    return (
      <Grid item sx={{ mb: 3 }} xs={12}>
        <ActiveBreadCrumb screen="COUNTRY_MANAGEMENT" />
        <Divider sx={{ background: CommonColors.mainColor }} />
      </Grid>
    );
  };
  const _renderBody = () => {
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item mb={8} xs={12}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      placeholder="Name"
                      disabled
                      defaultValue={countryData?.name || ''}
                      key={`nameCountry${renderKey}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Color"
                      placeholder="Color picker"
                      value={colorPicker}
                      disabled={isGetLoading || isActionLoading}
                      onChange={(e) => {
                        setColorPicker(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Popper.ColorPopper
                              color={colorPicker}
                              onChangeColor={(value: string) => {
                                setColorPicker(value);
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Description"
                      rows={5}
                      multiline
                      defaultValue={countryData?.description || ''}
                      key={`description${renderKey}`}
                      disabled={isGetLoading || isActionLoading}
                      onChange={(e) =>
                        handleOnChange('description', e.target.value)
                      }
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Country code ALPHA-3"
                      defaultValue={countryData?.extraData?.code || ''}
                      key={`geoJson${renderKey}`}
                      disabled
                      helperText="Each country have unique ALPHA-3 code"
                      onChange={(e) => handleOnChangeGeoJson(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} mt={2} justifyContent="end">
              <Grid item>
                <Button
                  variant="contained"
                  color="fuzzyWuzzyBrown"
                  onClick={() => Utils.redirect(ROUTERS.COUNTRY_MANAGEMENT)}
                  disabled={isGetLoading || isActionLoading}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="oceanGreen"
                  loading={isGetLoading || isActionLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          {_renderTitle()}
          <Grid item xs={12}>
            <Grid container>{_renderBody()}</Grid>
          </Grid>
        </Grid>
      </form>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default UpdateCountry;
