import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import al from './al';

const resources = {
  en: {
    translation: en,
  },
  al: {
    translation: al,
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: true,
  lng: 'en',
  fallbackLng: 'en',
  // ns: ['en', 'al'],
  // defaultNS: ['en'],
  keySeparator: '.',
  saveMissing: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: false,
  },
  partialBundledLanguages: true,
});

export default i18n;
