import { sendRequest } from '@/Configs';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';
import { API } from '@constants';

const { GALLERY } = API;

export const fetchGalleries = async (payload: IPaginationFilter) => {
  return sendRequest(GALLERY.ROOT, 'GET', payload);
};

export const getGalleryById = async (id: string) => {
  return sendRequest(`${GALLERY.GET_BY_ID}${id}`, 'GET');
};

export const createGallery = async (payload: FormData) => {
  return sendRequest(`${GALLERY.CREATE}`, 'FORM_DATA', payload);
};

export const activeGallery = async (id: string) => {
  return sendRequest(`${GALLERY.ACTIVE}${id}`, 'PUT');
};

export const deactiveGallery = async (id: string) => {
  return sendRequest(`${GALLERY.DEACTIVE}${id}`, 'PUT');
};

export const terminateGallery = async (id: string) => {
  return sendRequest(`${GALLERY.TERMINATE}${id}`, 'PUT');
};
