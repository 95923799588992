import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Grid } from '@mui/material';

import DefaultLayout from '@/Components/DefaultLayout';
import { DataTable } from '@/Components/LayoutPart';
import { ActiveBreadCrumb } from '@/Components/Common';

import { CountryActions } from '@actions';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';
import { useTypedDispatch, RootState } from '@/store';

const { fetchCountries, resetCountryReducer } = CountryActions;

const CountryManagement: React.FC = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'pagination')
  );

  useEffect(() => {
    dispatch(fetchCountries({ ...pagination }));
    return () => {
      dispatch(resetCountryReducer());
    };
  }, []);

  const _renderMainSection = () => {
    return (
      <Grid item>
        <DataTable.CountryManagement />
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container direction="column" rowSpacing={4}>
        <ActiveBreadCrumb screen="COUNTRY_MANAGEMENT" />
        {_renderMainSection()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default CountryManagement;
