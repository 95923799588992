import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';

import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Skeleton,
  Divider,
  ListItem,
  ListItemText,
  List,
  Chip,
  Grow,
} from '@mui/material';

import {
  mainContentItemStyles,
  mainContentStyles,
  NewsTextHeaderItem,
} from './News.Styles';

import DefaultLayout from '@/Components/DefaultLayout';
import { ActiveBreadCrumb, SkeletonLoading } from '@/Components/Common';

import { useTypedDispatch, RootState } from '@/store';
import { CommentActions, NewsUserstActions } from '@/Actions';
import { CommonColors } from '@/Themes';
import { useSelector } from 'react-redux';
import Utils from '@/Utils';
import { DEFAULT_CONFIRM, ROUTERS } from '@constants';

import { INewsList } from '@/Interfaces/News.interface';
import {
  IComment,
  ICreateComment,
  IFecthPostIdComments,
} from '@/Interfaces/Comment.interface';
import { Dialogs } from '@/Components/LayoutPart';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const { getNewsById, resetNewsDetail } = NewsUserstActions;
const { createComment, deactiveComment, fetchComments } = CommentActions;

interface IMessageComment {
  comment: string;
  name: string;
  email: string;
}

const DEFAULT_COMMENT: ICreateComment = {
  comment: '',
  name: '',
  email: '',
  postId: '',
};

const Newsdetail: React.FC = () => {
  const locationState = useLocation().state;
  const dispatch = useTypedDispatch();
  const [indexComment, setIndexComment] = useState<number>(2);
  const [isMessage, setIsMessage] = useState<IMessageComment>({
    name: '',
    comment: '',
    email: '',
  });
  const [listTag, setListTag] = useState<string[]>([]);
  const [formComment, setFormComment] =
    useState<ICreateComment>(DEFAULT_COMMENT);
  const [confirmDelete, setConfirmDelete] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const detail: INewsList = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'newsDetail')
  );
  const pagination: IFecthPostIdComments = useSelector((state: RootState) =>
    _.get(state.COMMENT, 'pagination')
  );
  const comments: any = useSelector((state: RootState) =>
    _.get(state.COMMENT, 'comments')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'isGetLoading')
  );

  const myRef = useRef<HTMLDivElement>(null);
  const refNews = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      dispatch(resetNewsDetail());
    };
  }, []);

  useEffect(() => {
    if (detail && !_.isEmpty(detail?.tag)) {
      setListTag(detail.tag.split(';'));
    }
  }, [detail]);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getNewsById(locationState?.id));
      setFormComment({ ...formComment, postId: locationState?.id });
      if (refNews && refNews.current) {
        refNews.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      dispatch(fetchComments({ ...pagination, postId: locationState?.id }));
    } else {
      Utils.redirect(ROUTERS.NEWS);
    }
  }, [locationState]);

  const handleOnChange = (key: string, value: string) => {
    setIsMessage({ comment: '', name: '', email: '' });
    setFormComment({ ...formComment, [key]: value });
  };

  const onCancel = (type: 'delete') => {
    if (type === 'delete')
      setConfirmDelete({ ...confirmDelete, isOpen: false });
  };

  const handleDelete = (state: { id?: string; status: string }) => {
    const { id } = state;
    if (id) dispatch(deactiveComment(id, locationState?.id));
    setConfirmDelete({ ...confirmDelete, isOpen: false });
  };

  const validatePayload = () => {
    const requiredPayload: {
      key: string;
      label: string;
      value: any;
      type: string;
    }[] = [
      {
        key: 'email',
        label: 'email',
        value: formComment.email,
        type: 'email',
      },
    ];

    const result = Utils.detectValidPayload(requiredPayload);

    return result;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let message: IMessageComment = isMessage;
    const { isValid, invalidMsg } = validatePayload();
    if (!isValid) message = invalidMsg;
    if (!_.trim(formComment.comment))
      message = { ...message, comment: 'Comment cannot be empty!' };
    if (!_.trim(formComment.name))
      message = { ...message, name: 'Name cannot be empty!' };
    if (_.find(message, (mess) => mess.length > 0)) {
      setIsMessage(message);
    } else {
      dispatch(createComment(formComment));
      setFormComment({ ...DEFAULT_COMMENT, postId: locationState?.id });
      if (myRef && myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const _renderBody = () => {
    return (
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
              {detail?.title}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                ...NewsTextHeaderItem,
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  color: CommonColors.orangeRoughy,
                  textTransform: 'uppercase',
                  fontWeight: '600',
                }}
              >
                {detail?.category}
              </Typography>
              <Typography>
                {detail?.publishAt && Utils.getFormatDate(detail.publishAt)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sx={mainContentStyles}>
            <Box
              sx={{
                ...mainContentItemStyles,
                img: {
                  maxWidth: '100%',
                  objectFit: 'cover',
                },
              }}
              component="div"
              dangerouslySetInnerHTML={Utils.createMarkup(detail?.content)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography sx={{ fontStyle: 'italic' }} variant="subtitle2">
              Source:{' '}
              <Box
                component="span"
                sx={{
                  fontStyle: 'italic',
                  color: CommonColors.hippieBlue,
                  textTransform: 'capitalize',
                }}
              >
                {detail?.source}
              </Box>
            </Typography>
            <Typography sx={{ fontStyle: 'italic' }} variant="subtitle2">
              Tag:{' '}
              <Box
                component="span"
                sx={{
                  display: 'inline-flex',
                  gap: 1,
                  ml: 1,
                }}
              >
                {listTag &&
                  listTag.map((tagItem: string, index: number) => (
                    <Chip
                      onClick={() =>
                        Utils.redirect(ROUTERS.NEWS, {
                          keywordTag: tagItem,
                        })
                      }
                      key={`${tagItem}${index}`}
                      color="primary"
                      variant="outlined"
                      label={tagItem}
                    />
                  ))}
              </Box>
            </Typography>
            {!_.isEmpty(detail?.country) && (
              <Typography sx={{ fontStyle: 'italic' }} variant="subtitle2">
                Country:{' '}
                <Box
                  component="span"
                  sx={{
                    fontStyle: 'italic',
                    color: CommonColors.hippieBlue,
                    textTransform: 'capitalize',
                  }}
                >
                  {detail?.country?.name}
                </Box>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderLeaveReply = () => {
    return (
      <Box
        sx={{
          mt: 2,
          border: 1,
          borderRadius: 1,
          p: 3,
          color: CommonColors.tundora,
        }}
      >
        <Grid>
          <Typography variant="h6" component={'h6'} mb={1}>
            Leave a Reply
          </Typography>
          <Typography>
            Your email address will not be published. Required fields are marked
            *
          </Typography>
        </Grid>
        <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
          <Grid item xs={12} md={7}>
            <TextField
              margin="normal"
              error={isMessage.comment?.length > 0}
              helperText={isMessage.comment}
              value={formComment.comment}
              fullWidth
              multiline
              rows={8}
              placeholder="Comments"
              label="Comments *"
              name="comment"
              onChange={(e) => handleOnChange('comment', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              error={isMessage.name?.length > 0}
              helperText={isMessage.name}
              value={formComment.name}
              margin="normal"
              fullWidth
              placeholder="Name"
              label="Name *"
              name="name"
              onChange={(e) => handleOnChange('name', e.target.value)}
            />
            <TextField
              error={isMessage.email?.length > 0}
              value={formComment.email}
              helperText={isMessage.email}
              margin="normal"
              fullWidth
              placeholder="Email"
              label="Email *"
              name="email"
              onChange={(e) => handleOnChange('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="burntSienna"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: '2' }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const _renderBodyComment = () => {
    return (
      <List
        sx={{
          bgcolor: 'background.paper',
          px: 2,
        }}
      >
        {comments?.length ? (
          <Box>
            {_.map(
              comments.slice(0, indexComment),
              (newComment: IComment, index: number) => {
                return (
                  <Grow in={true} key={index} timeout={500}>
                    <ListItem sx={{ padding: 0 }}>
                      <Grid container mb={2}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                              fontSize: '0.9rem',
                              color: CommonColors.silverChalice,
                            }}
                          >
                            <Grid item>
                              <ListItemText>
                                <Typography
                                  component="h5"
                                  sx={{ color: '#0c61ed' }}
                                >
                                  {newComment?.name}
                                </Typography>
                              </ListItemText>
                            </Grid>
                            <Grid item>
                              <ListItemText>
                                {newComment?.createdAt &&
                                  Utils.getFormatDate(newComment?.createdAt)}
                              </ListItemText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} ml={3}>
                          <Grid container>
                            <Grid item xs={11}>
                              <ListItemText sx={{ textAlign: 'justify' }}>
                                {newComment?.comment}
                              </ListItemText>
                            </Grid>
                            {Utils.getUserRole() === 'admin' && (
                              <Grid
                                item
                                xs={1}
                                textAlign="end"
                                sx={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setConfirmDelete({
                                    isOpen: true,
                                    message: `This will permanently terminated this comment.`,
                                    state: {
                                      id: newComment?.id,
                                      status: '',
                                    },
                                  });
                                }}
                              >
                                <DeleteOutlineIcon color="error" />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grow>
                );
              }
            )}
          </Box>
        ) : (
          <Typography component="p" sx={{ fontSize: '14px', mb: 1 }}>
            There are no comments!
          </Typography>
        )}
      </List>
    );
  };
  const handleShowMoreComments = () => {
    if (indexComment >= _.size(comments)) {
      if (myRef && myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      _.delay(() => {
        setIndexComment(2);
      }, 900);
    } else
      _.delay(() => {
        setIndexComment(indexComment + 2);
      }, 300);
  };

  const _renderComments = () => {
    return (
      <Grid item mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              mt={2}
            >
              <Grid item display="flex">
                <Typography variant="h6">
                  Comments ({comments?.length})
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {_renderBodyComment()}
          </Grid>
          {_.size(comments) > 2 && (
            <Grid item xs={12} mb={4}>
              <Grid container mb={2} direction="row" justifyContent="center">
                <Grid item>
                  <Chip
                    onClick={() => handleShowMoreComments()}
                    color="primary"
                    variant="outlined"
                    label={
                      indexComment >= _.size(comments)
                        ? 'Show less'
                        : 'Show more'
                    }
                  ></Chip>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Box sx={{ width: 1, scrollMarginTop: 200 }} ref={refNews}>
        {isGetLoading ? (
          <Grid container direction={'column'} justifyContent={'center'}>
            <Grid item width={1} mb={2}>
              <Skeleton height={'75'} width={'200'} />
              <Divider sx={{ background: CommonColors.mainColor }} />
            </Grid>
            <Grid sx={NewsTextHeaderItem} item>
              <Skeleton width={'100%'} />
            </Grid>
            <Grid sx={NewsTextHeaderItem} item>
              <Skeleton width={'100%'} />
            </Grid>
            <Grid item width={1}>
              <Skeleton height={'50'} />
              <Skeleton height={'50'} />
              <Skeleton height={'50'} />
              <Skeleton height={'50'} />
              <Skeleton height={'50'} />
            </Grid>
            <Grid>
              <SkeletonLoading numberRender={3} />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={'column'} justifyContent={'center'}>
            <Grid item>
              <ActiveBreadCrumb screen="NEWS" />
            </Grid>
            {_renderBody()}
            <Box ref={myRef} />
            {_renderComments()}
            {_renderLeaveReply()}
          </Grid>
        )}
        <Dialogs.Confirm
          title="Are you sure you want to terminated this comment?"
          confirm={confirmDelete}
          onCancel={() => onCancel('delete')}
          callback={() => handleDelete(confirmDelete.state)}
        />
      </Box>
    );
  };
  return <DefaultLayout showCarousel content={renderMain()} />;
};

export default Newsdetail;
