import { SxProps, Theme } from '@mui/system';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { CommonColors } from '@/Themes';

export const mainStyles: SxProps<Theme> = {
  zIndex: 9999,
  background: 'white',
  color: CommonColors.mainColor,
};

export const headerStyles: SxProps<Theme> = {
  background: CommonColors.mainBackground,
  color: 'white',
};

export const logoStyles: SxProps<Theme> = {
  cursor: 'pointer',
  width: 210,
  maxWidth: 1,
  objectFit: 'cover',
  maxHeight: 60,
};

export const tabOptionStyles: SxProps<Theme> = {
  position: 'absolute',
  bottom: -20,
  height: 'max-content',
  left: '60%',
  width: '50%',
};

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
