import _ from 'lodash';
import { ACTION_TYPES, ENUMS, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { ICountryData, IUpdateCountry } from '@/Interfaces/Country.interface';

// SINGLE ACTIONS
const setFetchCountryLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_FETCH_LOADING,
    payload,
  };
};

const setGetCountryLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_GET_LOADING,
    payload,
  };
};

const setCountryActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_ACTION_LOADING,
    payload,
  };
};

const resetCountryReducer = () => {
  return {
    type: ACTION_TYPES.RESET_COUNTRY_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchAllSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_COUNTRIES_SUCCESS,
    payload,
  };
};

const fetchAllFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_COUNTRIES_FAILURE,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRIES_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_FAILURE,
  };
};

const setCountryPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_TABLE_PAGINATION,
    payload,
  };
};

const setCountryMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_TABLE_META,
    payload,
  };
};

const getByIdSuccess = (payload: ICountryData) => {
  return {
    type: ACTION_TYPES.GET_COUNTRY_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_COUNTRY_BY_ID_FAILURE,
  };
};

const fetchAllCountries = (getForNews?: boolean) => {
  return async (dispatch: any) => {
    dispatch(setFetchCountryLoading(true));
    await API.fetchCountries({
      page: 0,
      limit: 0,
      status: ENUMS.STATUS.ACTIVE,
      // orderBy: 'ASC',
      // sortBy: 'name',
      forNews: getForNews,
    })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllFail());
        else {
          const items = _.get(result, 'items');
          dispatch(fetchAllSuccess(items));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllFail());
      });
  };
};

const fetchAllAvaiableSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_AVAIABLE_COUNTRY_SUCCESS,
    payload,
  };
};

const fetchAllAvaiableFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_AVAIABLE_COUNTRY_FAILURE,
  };
};

const fetchAllAvaiableCountries = () => {
  return async (dispatch: any) => {
    dispatch(setFetchCountryLoading(true));
    await API.fetchAvaiableCountries()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllAvaiableFail());
        else {
          dispatch(fetchAllAvaiableSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllAvaiableFail());
      });
  };
};

const fetchUnassignedForReviewSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_REVIEW_SUCCESS,
    payload,
  };
};

const fetchUnassignedForReviewFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_REVIEW_FAILURE,
  };
};

const fetchCountryUnassignedForReview = () => {
  return async (dispatch: any) => {
    dispatch(setFetchCountryLoading(true));
    await API.fetchCountryUnassignedForReview()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchUnassignedForReviewFail());
        else {
          dispatch(fetchUnassignedForReviewSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchUnassignedForReviewFail());
      });
  };
};

const fetchUnassignedForUserSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_USER_SUCCESS,
    payload,
  };
};

const fetchUnassignedForUserFail = () => {
  return {
    type: ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_USER_FAILURE,
  };
};

const fetchCountryUnassignedForUser = () => {
  return async (dispatch: any) => {
    dispatch(setFetchCountryLoading(true));
    await API.fetchCountryUnassignedForUser()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchUnassignedForUserFail());
        else {
          dispatch(fetchUnassignedForUserSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchUnassignedForUserFail());
      });
  };
};

const fetchCountries = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchCountryLoading(true));
    await API.fetchCountries(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setCountryMeta(meta));
          dispatch(setCountryPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getCountryById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetCountryLoading(true));
    await API.getCountryById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const updateSuccess = (payload: IUpdateCountry) => {
  return {
    type: ACTION_TYPES.UPDATE_COUNTRY_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_COUNTRY_FAILURE,
  };
};

const updateCountry = (payload: IUpdateCountry) => {
  return async (dispatch: any) => {
    dispatch(setCountryActionLoading(true));
    await API.updateCountry(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
          Utils.redirect(ROUTERS.COUNTRY_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

export default {
  fetchAllCountries,
  fetchAllAvaiableCountries,
  fetchCountries,
  resetCountryReducer,
  getCountryById,
  updateCountry,
  fetchCountryUnassignedForReview,
  fetchCountryUnassignedForUser,
};
