import React, { useMemo } from 'react';
import 'chart.js/auto';
import { ChartOptions, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

interface ISectionProps {
  data: ChartData<'doughnut'>;
  title?: string;
  subtitle?: boolean;
  centerText?: string;
  isShowLegend?: boolean;
  isCalculatePercent?: boolean;
}

const DoughnutChart: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const {
    data,
    title,
    centerText,
    subtitle = true,
    isShowLegend = false,
    isCalculatePercent = true,
  } = props;

  const options: ChartOptions<'doughnut'> = useMemo(() => {
    return {
      responsive: true,
      interaction: {
        intersect: false,
      },
      plugins: {
        title: {
          display: true,
          text: title,
          align: 'start',
          fullSize: true,
        },
        subtitle: {
          display: subtitle,
          align: 'end',
          position: 'bottom',
          text: '(*) Click on legend to show/hide element(s)',
        },
        legend: {
          display: isShowLegend,
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              let totalDatasetSum = 0;
              const currentValue = context.dataset.data[context.dataIndex];
              if (!isCalculatePercent)
                return `${context.label}: ${currentValue}%`;
              context.dataset.data.map((el) => (totalDatasetSum += el));
              const percentage = (currentValue * 100) / totalDatasetSum;
              const roundedPercentage = Math.round(percentage * 100) / 100;
              return `${context.label}: ${currentValue} (${roundedPercentage}%)`;
            },
          },
        },
      },
    };
  }, [props]);

  return (
    <Doughnut
      options={{ ...options }}
      data={data}
      plugins={[
        {
          id: 'doughnut-text-plugin',
          afterDraw: (chart) => {
            if (centerText) {
              const width = chart.width;
              const height = chart.height;
              const ctx = chart.ctx;
              ctx.restore();
              ctx.font = '1.4em sans-serif';
              ctx.textBaseline = 'middle';
              const text = centerText;
              const bonus = !isShowLegend ? 20 : 0;
              const textX = Math.round(
                (width - ctx.measureText(text).width) / 2
              );
              const textY = height / 2;
              ctx.fillText(text, textX, textY + bonus);
              ctx.save();
            }
          },
        },
      ]}
    />
  );
};

export default DoughnutChart;
