import { store } from '@/store';
import { ACTION_TYPES } from '@constants';

import { INotifications } from '@/Interfaces/Widget.interface';

const setNotification = (payload: INotifications) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATION_MESSAGE,
    payload,
  };
};

const setIsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_START_LOADING,
    payload,
  };
};

const setAlert = (payload: INotifications) => {
  return {
    type: ACTION_TYPES.SET_ALERT_MESSAGE,
    payload,
  };
};

const pushNotification = (props: INotifications) => {
  return store.dispatch(setNotification(props));
};

const pushAlert = (props: INotifications) => {
  return store.dispatch(setAlert(props));
};

// Wait until all promises have settled (each may resolve to reject)
const dispatchMultipleRequests = async (instants: any = []) => {
  store.dispatch(setIsLoading(true));
  for (const instant of instants) {
    store.dispatch(instant);
  }
  store.dispatch(setIsLoading(false));
};

export { pushNotification, dispatchMultipleRequests, pushAlert };
