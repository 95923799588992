import React from 'react';

import OverViewUserTable from '@/Components/LayoutPart/DataTable/OverviewUser.table';
import { Grid } from '@mui/material';

const CivilSociety: React.FC = () => {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12} sx={{ width: 100 }}>
        <Grid container>
          <Grid item xs={12}>
            <OverViewUserTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CivilSociety;
