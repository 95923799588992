enum STATUS {
  WAITING_APPROVAL = 'waiting for approval',
  WAITING_PUBLISH = 'waiting for publish',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PUBLISHED = 'published',
  PUBLISH = 'publish',
  DRAFT = 'draft',
  REVIEW = 'review',
  EDITING = 'editing',
  APPROVAL = 'approval',
  ACCEPT = 'accept',
  DENY = 'deny',
  PENDING = 'pending',
  TERMINATED = 'terminated',
  DEACTIVATE = 'deactivate',
  //draft
  DELETED = 'deleted',
  IN_PROGRESS = 'in progress',
  REVIEWING = 'reviewing',
  APPROVED = 'approved',
}

export default STATUS;
