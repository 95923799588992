import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Stepper,
  Step,
  StepButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useLocation } from 'react-router-dom';
// import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';
import { ActiveBreadCrumb, SkeletonLoading } from '@/Components/Common';
import { Dialogs } from '@/Components/LayoutPart';

import { ReviewerScoringActions } from '@actions';
import {
  SCORING_TEMPLATE,
  STEP_OF_SCORINGS,
  ENUMS,
  ROUTERS,
  DEFAULT_CONFIRM,
} from '@constants';
import {
  TStepCode,
  ISubArea,
  IStandard,
  IIndicatorTemplate,
  ICreateAreaScore,
  ICreateTypeStandardScore,
  ICommentIndicator,
  ICreateIndicatorScore,
} from '@interfaces/CountryScoring.interface';
import ScoringDialog from './ReviewScoringDialog';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

// Declare constants
const {
  updateReviewerScoring,
  getReviewerScoringById,
  resetReviewerScoringReducer,
  sendScoringBackToUser,
  sendScoringToAdmin,
} = ReviewerScoringActions;

const ScoringReport: React.FC = () => {
  // Constructors
  const locationState = useLocation().state;
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.REVIEWER_SCORING, 'isActionLoading')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.REVIEWER_SCORING, 'isGetLoading')
  );
  const scoringDetail = useSelector((state: RootState) =>
    _.get(state.REVIEWER_SCORING, 'scoringDetail')
  );
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const [isHasIssue, setIsHasIssue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [submitIndicator, setSubmitIndicator] = useState<ICommentIndicator[]>(
    []
  );
  const [selectedLevel, setSelectedLevel] = useState<{
    selectedCode: TStepCode;
    selectedSubCode: string;
    selectedStandardCode: string;
  }>({
    selectedCode: 'area_1',
    selectedSubCode: '',
    selectedStandardCode: '',
  });
  const [formState, setFormState] = useState<{
    area: ICreateAreaScore[];
    indicators: IIndicatorTemplate[];
    indicatorType: string;
    defaultDialogStandard?: ICreateTypeStandardScore;
    standardResuls: {
      [key: string]: number;
    };
  }>({
    area: [],
    indicators: [],
    indicatorType: '',
    standardResuls: {},
  });
  const [standardReviewed, setStandardReviewed] = useState<{
    [key: string]: {
      totalReviewed: number;
      totalAccepted: number;
      totalDenied: number;
      dataComment?: ICommentIndicator[];
    };
  } | null>(null);
  const [defaultCommentIndicator, setDefaultCommentIndicator] = useState<
    ICommentIndicator[]
  >([]);
  const [confirmSendBack, setConfirmSendBack] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [confirmSendAdmin, setConfirmSendAdmin] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    return () => {
      dispatch(resetReviewerScoringReducer());
    };
  }, []);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getReviewerScoringById(locationState.id));
    } else Utils.redirect(ROUTERS.REVIEW_SCORING);
  }, [locationState]);

  useEffect(() => {
    if (scoringDetail) {
      setFormState({
        ...formState,
        standardResuls: _.get(scoringDetail, 'extraData.standardResuls'),
        area: _.get(scoringDetail, 'area') || [],
      });
      setStandardReviewed(_.get(scoringDetail, 'extraData.standardReviewed'));
      setSubmitIndicator([]);
      setRenderKey(Math.random());
      setDefaultCommentIndicator([]);
    }
  }, [scoringDetail]);

  useEffect(() => {
    if (standardReviewed) {
      const checkProcess = Utils.generateReviewScoringProcess(standardReviewed);
      const totalComplete = _.filter(checkProcess, (e) => e.isCompleted);
      const totalIssue = _.filter(checkProcess, (e) => e.isSendBack);
      if (totalComplete.length === 3) setIsAllCompleted(true);
      else setIsAllCompleted(false);
      if (totalIssue.length === 3) setIsHasIssue(true);
      else setIsHasIssue(false);
    }
  }, [standardReviewed]);

  // Events
  const handleFillForm = (
    data: IIndicatorTemplate[],
    key: string,
    code: string,
    subcode: string
  ) => {
    const findAreaIndex = _.findIndex(formState.area, [
      'code',
      selectedLevel.selectedCode,
    ]);
    if (findAreaIndex !== -1) {
      const findSubIndex = _.findIndex(formState.area[findAreaIndex].subArea, [
        'code',
        subcode,
      ]);
      if (findSubIndex !== -1) {
        const findStandardIndex = _.findIndex(
          formState.area[findAreaIndex].subArea[findSubIndex].standard,
          ['code', code]
        );
        if (findStandardIndex !== -1) {
          const findTypeStandardIndex = _.findIndex(
            formState.area[findAreaIndex].subArea[findSubIndex].standard[
              findStandardIndex
            ].typeStandard,
            ['type', key]
          );
          if (findTypeStandardIndex !== -1) {
            const standardSelectedKey = `${code}${key}`;
            setDefaultCommentIndicator(
              standardReviewed?.[standardSelectedKey]?.dataComment || []
            );
            setSelectedLevel({
              ...selectedLevel,
              selectedSubCode: subcode,
              selectedStandardCode: code,
            });
            setFormState({
              ...formState,
              indicatorType: key,
              indicators: data,
              defaultDialogStandard:
                formState.area[findAreaIndex].subArea[findSubIndex].standard[
                  findStandardIndex
                ].typeStandard[findTypeStandardIndex],
            });
            setIsOpen(true);
          }
        }
      }
    }
  };

  const _getStandardReviewed = useMemo(() => {
    const resolvedStandardReviewed: {
      [key: string]: {
        totalReviewed: number;
        totalAccepted: number;
        totalDenied: number;
        dataComment?: ICommentIndicator[];
      };
    } = {};
    _.each(standardReviewed, (sta, key) => {
      resolvedStandardReviewed[key] = {
        ...sta,
        dataComment: _.map(sta.dataComment, (el) => ({ ...el, comment: '' })),
      };
    });
    return resolvedStandardReviewed;
  }, [standardReviewed]);

  const saveScoring = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    status: string
  ) => {
    e.preventDefault();
    dispatch(
      updateReviewerScoring(
        {
          status,
          indicator: submitIndicator,
          extraData: {
            standardResuls: formState.standardResuls,
            standardReviewed: _getStandardReviewed,
          },
        },
        locationState?.id
      )
    );
  };

  const handleClose = (data: ICommentIndicator[]) => {
    if (data) {
      const newChangedIndicator = _.uniqBy(
        [...data, ...submitIndicator],
        'code'
      );
      const standardSelectedKey = `${selectedLevel.selectedStandardCode}${formState.indicatorType}`;
      let defaultIndicators: ICreateIndicatorScore[] = [];
      const newAreaForm = _.map(formState.area, (area) => {
        if (area.code === selectedLevel.selectedCode) {
          const { subArea } = area;
          const newSubArea = _.map(subArea, (sub) => {
            if (sub.code === selectedLevel.selectedSubCode) {
              const { standard } = sub;
              const newStandard = _.map(standard, (sta) => {
                if (sta.code === selectedLevel.selectedStandardCode) {
                  const { typeStandard } = sta;
                  const newTypeStandard = _.map(typeStandard, (typeSta) => {
                    if (typeSta.type === formState.indicatorType) {
                      const newDataIndicator = _.map(
                        typeSta.indicator,
                        (indi) => {
                          const findChangedIndi = _.find(data, [
                            'code',
                            indi.code,
                          ]);
                          if (findChangedIndi)
                            return {
                              ...indi,
                              status: findChangedIndi.status,
                            };
                          return indi;
                        }
                      );
                      defaultIndicators = newDataIndicator;
                      return {
                        ...typeSta,
                        indicator: newDataIndicator,
                      };
                    }
                    return { ...typeSta };
                  });
                  return { ...sta, typeStandard: newTypeStandard };
                }
                return { ...sta };
              });
              return {
                ...sub,
                standard: newStandard,
              };
            }
            return { ...sub };
          });
          return { ...area, subArea: newSubArea };
        }
        return { ...area };
      });
      let totalDenied = 0;
      let totalAccepted = 0;
      let totalReviewed = 0;
      _.each(defaultIndicators, (indicator) => {
        const indicatorStatus =
          _.get(indicator, 'status') || ENUMS.STATUS.PENDING;
        const findChangedIndicator = _.find(
          data,
          (el) => el.id === indicator?.id
        );
        if (indicatorStatus !== ENUMS.STATUS.PENDING) {
          totalReviewed += 1;
          if (findChangedIndicator) {
            if (findChangedIndicator.status === ENUMS.STATUS.DENY)
              totalDenied += 1;
            if (findChangedIndicator.status === ENUMS.STATUS.ACCEPT)
              totalAccepted += 1;
          } else {
            if (indicatorStatus === ENUMS.STATUS.DENY) totalDenied += 1;
            if (indicatorStatus === ENUMS.STATUS.ACCEPT) totalAccepted += 1;
          }
        } else {
          if (findChangedIndicator) {
            if (findChangedIndicator.status === ENUMS.STATUS.DENY)
              totalDenied += 1;
            if (findChangedIndicator.status === ENUMS.STATUS.ACCEPT)
              totalAccepted += 1;
            totalReviewed += 1;
          }
        }
      });
      setDefaultCommentIndicator([]);
      setStandardReviewed({
        ...standardReviewed,
        [standardSelectedKey]: {
          totalAccepted,
          totalDenied,
          totalReviewed,
          dataComment: data,
        },
      });
      setSubmitIndicator(newChangedIndicator);
      setFormState({ ...formState, area: newAreaForm });
    }
    setIsOpen(false);
  };

  const handleChangeStep = (code: TStepCode) => {
    setSelectedLevel({ ...selectedLevel, selectedCode: code });
  };

  const handleSendBack = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setConfirmSendBack({
      isOpen: true,
      message: `This scoring will be send back to user.`,
      state: {
        id: '',
        status: '',
      },
    });
  };

  const handleSubmitSendBack = () => {
    setConfirmSendBack(DEFAULT_CONFIRM);
    dispatch(
      sendScoringBackToUser(
        {
          status: ENUMS.STATUS.EDITING,
          indicator: submitIndicator,
          extraData: {
            standardResuls: formState.standardResuls,
            standardReviewed: _getStandardReviewed,
          },
        },
        locationState?.id
      )
    );
  };

  const onCancelSendBack = () => {
    setConfirmSendBack(DEFAULT_CONFIRM);
  };

  const handleSendToAdmin = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setConfirmSendAdmin({
      isOpen: true,
      message: `This scoring will be send to admin.`,
      state: {
        id: '',
        status: '',
      },
    });
  };

  const handleSubmitSendAdmin = () => {
    setConfirmSendAdmin(DEFAULT_CONFIRM);
    dispatch(
      sendScoringToAdmin(
        {
          indicator: submitIndicator,
          extraData: {
            standardResuls: formState.standardResuls,
            standardReviewed: _getStandardReviewed,
          },
          status: ENUMS.STATUS.REVIEW,
        },
        locationState?.id
      )
    );
  };

  const onCancelSendAdmin = () => {
    setConfirmSendAdmin(DEFAULT_CONFIRM);
  };

  // Renders
  const _renderSteps = () => {
    return _.map(STEP_OF_SCORINGS, (step) => {
      return (
        <Step key={step.code}>
          <StepButton
            disabled={isActionLoading || isGetLoading}
            onClick={() => handleChangeStep(step.code)}
          >
            {step.label}
          </StepButton>
        </Step>
      );
    });
  };

  const _renderStandards = (standards: IStandard[], subcode: string) => {
    // const OFFSET_SIZE = 4;
    // const resizeColumn = _.size(standards) === OFFSET_SIZE;
    return _.map(standards, (standard, index) => {
      const totalFilledLegislation =
        _.get(standardReviewed, `${standard.code}legislation`)?.totalReviewed ||
        0;
      const totalDeninedLegislation =
        _.get(standardReviewed, `${standard.code}legislation`)?.totalDenied ||
        0;
      const totalFilledPractice =
        _.get(standardReviewed, `${standard.code}practice`)?.totalReviewed || 0;
      const totalDeninedPractice =
        _.get(standardReviewed, `${standard.code}practice`)?.totalDenied || 0;
      return (
        <Grid
          item
          key={standard.code || index}
          xs={12}
          md={6}
          // sx={{
          //   position: 'relative',
          //   mr: index === 2 && resizeColumn ? '1px' : 0,
          //   right: '-1px', //grid breaks standard 3-4
          // }}
        >
          <Card>
            <CardContent sx={{ height: 120 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {standard.originalName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  pl={2}
                  sx={{ mx: 'auto', fontWeight: 400, fontSize: 12, mt: 2 }}
                >
                  ({standard.codeName})
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.legislation,
                    'legislation',
                    standard.code,
                    subcode
                  )
                }
                startIcon={
                  totalDeninedLegislation > 0 ? (
                    <WarningAmberIcon color="error" />
                  ) : null
                }
                color={
                  totalFilledLegislation === standard.legislation.length &&
                  !totalDeninedLegislation
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                legislation reviewed ({totalFilledLegislation}/
                {standard.legislation.length})
              </Button>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.practice,
                    'practice',
                    standard.code,
                    subcode
                  )
                }
                startIcon={
                  totalDeninedPractice > 0 ? (
                    <WarningAmberIcon color="error" />
                  ) : null
                }
                color={
                  totalFilledPractice === standard.practice.length &&
                  !totalDeninedPractice
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                practice reviewed ({totalFilledPractice}/
                {standard.practice.length})
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    });
  };

  const _renderSubAreas = (areas: ISubArea[]) => {
    return _.map(areas, (area) => {
      return (
        <Grid item key={area.code}>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item>
              <Typography gutterBottom variant="h5">
                {area.codeName}: {area.name}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: 13, ml: 2 }}
              >
                Principle: {area.principle}
              </Typography>
            </Grid>
            <Divider variant="middle" />
            <Grid item px={2} py={1}>
              <Grid container columnSpacing={3} rowSpacing={4}>
                {_renderStandards(area.standards, area.code)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const _renderScoringForm = () => {
    const selectedForm = _.find(SCORING_TEMPLATE, [
      'code',
      selectedLevel.selectedCode,
    ]);
    if (!selectedForm) return null;
    return (
      <Grid container direction="column">
        {_renderSubAreas(selectedForm.subAreas)}
      </Grid>
    );
  };

  const renderMain = () => {
    const findStep = _.find(STEP_OF_SCORINGS, [
      'code',
      selectedLevel.selectedCode,
    ]);
    return (
      <>
        <Dialogs.Confirm
          confirm={confirmSendBack}
          onCancel={() => onCancelSendBack()}
          callback={() => handleSubmitSendBack()}
        />
        <Dialogs.Confirm
          confirm={confirmSendAdmin}
          onCancel={() => onCancelSendAdmin()}
          callback={() => handleSubmitSendAdmin()}
        />
        <Grid container direction="column" rowSpacing={4}>
          <ScoringDialog
            open={isOpen}
            dialogTitle={formState.indicatorType}
            indicators={formState.indicators}
            handleClose={handleClose}
            defaultStandard={formState.defaultDialogStandard}
            defaultCommentIndicator={defaultCommentIndicator}
            isDisabledAll={
              _.get(scoringDetail, 'status') !== ENUMS.STATUS.REVIEW
            }
          />
          <Grid item xs={12}>
            <ActiveBreadCrumb screen="COUNTRY_SCORING" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={2}>
                <DatePicker
                  inputFormat="YYYY"
                  views={['year']}
                  label="Year"
                  disabled
                  value={_.get(scoringDetail, 'year') || null}
                  onChange={() => {}}
                  maxDate={new Date()}
                  renderInput={(params: any) => (
                    <TextField {...params} sx={{ width: 1 }} />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  key={`title${renderKey}`}
                  fullWidth
                  label="Title"
                  defaultValue={_.get(scoringDetail, 'title')}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container direction="column" p={2}>
              <Grid item>
                <Stepper
                  nonLinear
                  sx={{ width: 1 }}
                  alternativeLabel
                  activeStep={findStep?.step || 0}
                >
                  {_renderSteps()}
                </Stepper>
              </Grid>
              <Grid item>
                {!isGetLoading ? (
                  _renderScoringForm()
                ) : (
                  <SkeletonLoading numberRender={3} />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="end">
              <Grid item mr="auto">
                <Button
                  variant="contained"
                  color="burntSienna"
                  disabled={isActionLoading || isGetLoading}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => Utils.redirect(ROUTERS.REVIEW_SCORING)}
                >
                  Back to list
                </Button>
              </Grid>
              {_.get(scoringDetail, 'status') === ENUMS.STATUS.REVIEW && (
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="info"
                    loading={isActionLoading || isGetLoading}
                    startIcon={<SaveAsIcon />}
                    onClick={(e) => saveScoring(e, ENUMS.STATUS.REVIEW)}
                    disabled={_.isEmpty(submitIndicator)}
                  >
                    Save Editting
                  </LoadingButton>
                </Grid>
              )}
              {_.get(scoringDetail, 'status') === ENUMS.STATUS.REVIEW && (
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="fuzzyWuzzyBrown"
                    loading={isActionLoading || isGetLoading}
                    startIcon={<WarningAmberIcon />}
                    onClick={(e) => handleSendBack(e)}
                    disabled={!isHasIssue}
                  >
                    Send back to user
                  </LoadingButton>
                </Grid>
              )}
              {_.get(scoringDetail, 'status') === ENUMS.STATUS.REVIEW && (
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="oceanGreen"
                    loading={isActionLoading || isGetLoading}
                    startIcon={<ScheduleSendIcon />}
                    onClick={(e) => handleSendToAdmin(e)}
                    disabled={!isAllCompleted}
                  >
                    Send to admin
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return <DefaultLayout portalFor="REVIEWER" content={renderMain()} />;
};

export default ScoringReport;
