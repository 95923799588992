import alert from './alert';
import button from './button';
import tooltip from './tooltip';
import label from './label';
import placeholder from './placeholder';
import validation from './validation';
import table from './table';
import title from './title';

export default {
  alert,
  button,
  tooltip,
  label,
  placeholder,
  validation,
  table,
  title,
};
