import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  Button,
  Badge,
  CircularProgress,
  Grow,
} from '@mui/material';
import _ from 'lodash';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { RootState, useTypedDispatch } from '@/store';
import { NotificationsDashboardActions } from '@/Actions';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';

const { fetchNotifications, markAsReadNotifications } =
  NotificationsDashboardActions;

const AdminDashboard: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [indexNotifications, setIndexNotifications] = useState<number>(10);
  const notifications: any = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION_DASHBOARD, 'notifications')
  );
  const totalUnread: any = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION_DASHBOARD, 'totalUnread')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION_DASHBOARD, 'isFetchLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.NOTIFICATION_DASHBOARD, 'isActionLoading')
  );

  const handleMarkAsReadClick = (
    id: string,
    recordId: string,
    type: string
  ) => {
    const formData = new FormData();
    if (id) {
      formData.append('notificationIds[]', id);
      dispatch(markAsReadNotifications(formData, false, recordId, type));
    }
  };

  const handleMarkAsReadClickAll = () => {
    const formData = new FormData();
    formData.append('markAll', _.toString(1));
    dispatch(markAsReadNotifications(formData, true));
    dispatch(fetchNotifications());
  };

  const handleShowMoreNotifications = () => {
    if (indexNotifications >= _.size(notifications)) {
      _.delay(() => {
        setIndexNotifications(10);
      }, 300);
    } else {
      _.delay(() => {
        setIndexNotifications(indexNotifications + 10);
      }, 300);
    }
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  const _renderNotifications = () => {
    return (
      <>
        {notifications ? (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                sx={{
                  display: 'flex',
                  mb: 2,
                  justifyContent: 'space-between',
                }}
                component="div"
                id="nested-list-subheader"
              >
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography
                    sx={{
                      color: CommonColors.pickledBluewood,
                      fontSize: 22,
                      mr: 1,
                    }}
                    variant="subtitle2"
                  >
                    Notifications
                  </Typography>
                  <Badge
                    badgeContent={totalUnread?.unread}
                    sx={{
                      '& .MuiBadge-badge': {
                        minWidth: 6,
                        height: 17,
                      },
                    }}
                    color="primary"
                  >
                    <NotificationsIcon
                      sx={{
                        fontSize: 30,
                        color: CommonColors.mainColor,
                      }}
                    />
                  </Badge>
                </Box>
                <Button
                  startIcon={<DoneAllIcon />}
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => handleMarkAsReadClickAll()}
                  disabled={totalUnread?.unread === 0}
                >
                  Read all
                </Button>
              </ListSubheader>
            }
          >
            {!isFetchLoading ? (
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  {notifications &&
                    _.map(
                      notifications.slice(0, indexNotifications),
                      (item, index: number) => {
                        return (
                          <Grow in={true} key={index} timeout={500}>
                            <Grid container key={index}>
                              <Grid item xs={12} sx={{ position: 'relative' }}>
                                <ListItemButton
                                  disabled={isActionLoading}
                                  onClick={() =>
                                    handleMarkAsReadClick(
                                      item.id,
                                      item.recordId,
                                      item?.type || ''
                                    )
                                  }
                                >
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <ListItemText
                                        disableTypography
                                        primary={
                                          <Typography
                                            variant="body2"
                                            sx={{ fontSize: '12px' }}
                                          >
                                            {item?.createdAt &&
                                              Utils.getFormatDate(
                                                item?.createdAt
                                              )}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={11}>
                                      <ListItemText primary={item?.content} />
                                    </Grid>
                                    <Grid item xs={1}>
                                      {item.status === 'unread' && (
                                        <ListItemIcon
                                          sx={{
                                            ml: 2,
                                            position: 'absolute',
                                            top: '40%',
                                          }}
                                        >
                                          <Brightness1Icon
                                            sx={{
                                              fontSize: '14px',
                                              color: CommonColors.hippieBlue,
                                            }}
                                          />
                                        </ListItemIcon>
                                      )}
                                    </Grid>
                                  </Grid>
                                </ListItemButton>
                                <Divider />
                              </Grid>
                            </Grid>
                          </Grow>
                        );
                      }
                    )}
                </Grid>
                <Grid item xs={12}>
                  {_.size(notifications) > 10 && (
                    <Grid item xs={12}>
                      <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12} my={2}>
                          <Button
                            sx={{
                              width: 1,
                              background: CommonColors.mainColor,
                              borderRadius: '20px',
                              '&:hover': {
                                backgroundColor: CommonColors.mainColor,
                              },
                            }}
                            variant="contained"
                            onClick={() => handleShowMoreNotifications()}
                          >
                            {indexNotifications >= _.size(notifications) ? (
                              <>Show less</>
                            ) : (
                              <>Show more</>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </List>
        ) : (
          <Typography component="p" sx={{ fontSize: '14px' }}>
            You do not have any new notifications
          </Typography>
        )}
      </>
    );
  };

  // Renders
  const renderMain = () => {
    return (
      <Grid container pb={2}>
        <Grid item xs={12}>
          <BreadCrumb title="Dashboard" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ padding: 2, minHeight: 700 }} elevation={1}>
                <Grid>{_renderNotifications()}</Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default AdminDashboard;
