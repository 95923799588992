import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

import { PaginationActions } from '@/Components/Common';

import { CountryActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ROUTERS } from '@constants';
import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import Utils from '@/Utils';

const { COUNTRY_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchCountries } = CountryActions;

const CountryTable: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const countryTable: any =
    useSelector((state: RootState) => _.get(state.COUNTRY, 'countryTable')) ||
    [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'isFetchLoading')
  );
  const colSpan = COUNTRY_TABLE_HEADER.length + 1;

  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(fetchCountries({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchCountries({ ...pagination, limit: perPage }));
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(COUNTRY_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`} sx={{ fontWeight: '700' }}>
            {item.label}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [COUNTRY_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(countryTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            There is no country(s) to display
          </TableCell>
        </TableRow>
      );
    return _.map(countryTable, (item, index) => {
      return (
        <TableRow hover key={index}>
          {_.map(COUNTRY_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`}>
              {head?.value === 'status' ? (
                <Typography
                  variant="subtitle1"
                  textTransform="uppercase"
                  sx={{
                    fontSize: '0.875rem',
                    color:
                      (head?.value === 'status' &&
                        _.get(TableStatusColor, _.get(item, head.value))) ||
                      CommonColors.black,
                  }}
                >
                  {_.get(item, head.value)}
                </Typography>
              ) : (
                _.get(item, head.value)
              )}
            </TableCell>
          ))}
          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View Country">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() => {
                  Utils.redirect(ROUTERS.UPDATE_COUNTRY, { id: item.id });
                }}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default CountryTable;
