import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import _ from 'lodash';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const Confirm = (props: {
  title?: string;
  callback?(): void;
  confirm: IConfirmStructure;
  onCancel?(): void;
}) => {
  const { callback, confirm, onCancel, title = 'Are you sure?' } = props;

  const onSubmit = () => {
    if (callback) callback();
  };

  return (
    <Dialog open={confirm.isOpen}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <DialogContentText>
          {
            <WarningAmberIcon
              fontSize="large"
              sx={{
                mr: 1,
                color: 'orange',
              }}
            />
          }
        </DialogContentText>
        {title}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 400, wordBreak: 'break-all' }}>
        <DialogContentText>{confirm.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="fuzzyWuzzyBrown" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="oceanGreen" onClick={onSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
