import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import _ from 'lodash';

import { Box, Chip, Grid, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Popper } from '@/Components/LayoutPart';
import CloseIcon from '@mui/icons-material/Close';

import DefaultLayout from '@/Components/DefaultLayout';
import CardNewsContent from '@/Components/LayoutPart/NewsContent';
import { BreadCrumb } from '@/Components/LayoutPart';
import NewsLoadding from '@/Components/LayoutPart/NewsLoadding';
import { RootState, useTypedDispatch } from '@/store';
import { NewsUserstActions } from '@actions';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';
import { INewsList } from '@/Interfaces/News.interface';
import Utils from '@/Utils';
import { NoDataWereFound } from '@/Components/Common';
import { DEFAULT_PAGINATION, ROUTERS } from '@/Constants';

const { fetchNews } = NewsUserstActions;

const NewsList: React.FC = () => {
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;

  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'pagination')
  );
  const newsList: INewsList[] = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'newsList')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'isFetchLoading')
  );
  const count = Math.ceil(meta?.totalItems / pagination?.limit) || 0;

  useEffect(() => {
    if (!_.isEmpty(locationState?.keywordTag))
      dispatch(
        fetchNews({
          ...pagination,
          searchBy: 'tag',
          keyword: locationState.keywordTag,
        })
      );
    else dispatch(fetchNews({ ...pagination }));
  }, [locationState]);

  const handleChange = (_e: any, newPage: number) => {
    dispatch(fetchNews({ ...pagination, page: newPage }));
  };

  const onReset = () => {
    dispatch(
      fetchNews({
        ...DEFAULT_PAGINATION,
        status: 'publish',
        limit: 12,
        searchBy: 'title',
      })
    );
  };

  const _renderBody = () => {
    return (
      <>
        {isFetchLoading ? (
          <Grid container mt={4}>
            {_.map(new Array(12), (_i, index) => {
              return (
                <Grid key={index} height={'350px'} item xs={12} sm={6} md={4}>
                  <NewsLoadding />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container spacing={2} mt={4}>
            {_.map(newsList, (item: INewsList, index: number) => {
              return (
                <Grid
                  key={index}
                  height={'350px'}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  onClick={() => {
                    Utils.redirect(ROUTERS.NEWS_DETAIL, { id: item.id });
                  }}
                >
                  <CardNewsContent data={item} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  const _renderPagination = () => {
    return (
      <Grid item xs={12} display="flex" justifyContent="center" my={4}>
        <Pagination
          count={count}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container>
        <Grid item width={1} mb={1} textAlign="center">
          <BreadCrumb title={'News'} />
        </Grid>
        <Popper.FilterPopper
          data={pagination}
          onApply={(data) => dispatch(fetchNews(data))}
          filterFor="NEWS_STATUS_USER"
        />
        <Grid item display="flex" height={43}>
          {pagination.keyword && (
            <Chip
              sx={{ minWidth: 50, height: 1, ml: 2 }}
              label={
                <Box display="flex" maxWidth={550} pt={'4px'}>
                  <Typography
                    sx={{ textTransform: 'capitalize', fontWeight: 550 }}
                  >
                    {pagination.searchBy}
                  </Typography>
                  {pagination.keyword && (
                    <Typography
                      sx={{
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        maxHeight: 1,
                        textOverflow: 'ellipsis',
                      }}
                    >
                      : {pagination.keyword}
                    </Typography>
                  )}
                  {pagination.keyword && (
                    <Box onClick={() => onReset()}>
                      <CloseIcon
                        color="error"
                        sx={{
                          mt: '2px',
                          ml: 1,
                          fontSize: 20,
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              }
              variant="outlined"
            />
          )}
        </Grid>
        {!isFetchLoading && _.isEmpty(newsList) && <NoDataWereFound />}
        {_renderBody()}
        {_renderPagination()}
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default NewsList;
