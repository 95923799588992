import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

import { PaginationActions } from '@/Components/Common';
import { Dialogs } from '@/Components/LayoutPart';

import { NewsManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ENUMS, ROUTERS, DEFAULT_CONFIRM } from '@constants';
import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import Utils from '@/Utils';
import { IDropdownOption } from '@/Interfaces/Dropdown.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const STATUS_DROPDOWN = [
  {
    label: 'PUBLISH',
    value: ENUMS.STATUS.PUBLISH,
  },
  {
    label: 'ACTIVE',
    value: ENUMS.STATUS.ACTIVE,
  },
  {
    label: 'INACTIVE',
    value: ENUMS.STATUS.INACTIVE,
  },
];

const { NEWS_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchNews, deactiveNews, activeNews, publishNews } =
  NewsManagementActions;

const NewsTable: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const newsTable: any =
    useSelector((state: RootState) =>
      _.get(state.NEWS_MANAGEMENT, 'newsTable')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'isFetchLoading')
  );
  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const colSpan = NEWS_TABLE_HEADER.length + 5;

  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(fetchNews({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchNews({ ...pagination, limit: perPage }));
  };

  const onChangeOption = (value: string, id: string) => {
    const description = `Are you sure you want to ${value} this news.`;
    setConfirmDialog({
      isOpen: true,
      message: description,
      state: {
        id: id,
        status: value,
      },
    });
  };

  const handleSubmitChangeStatus = (state: { id?: string; status: string }) => {
    const { id, status } = state;
    if (id && status === ENUMS.STATUS.INACTIVE)
      dispatch(deactiveNews(id, pagination));
    else if (id && status === ENUMS.STATUS.PUBLISH)
      dispatch(publishNews(id, pagination));
    else if (id && status === ENUMS.STATUS.ACTIVE)
      dispatch(activeNews(id, pagination));
    else dispatch(fetchNews(pagination));
    setConfirmDialog(DEFAULT_CONFIRM);
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchNews(pagination));
  };
  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        <TableCell width={450} sx={{ fontWeight: '700' }}>
          Title
        </TableCell>
        {_.map(NEWS_TABLE_HEADER, (item) => (
          <TableCell
            width={170}
            key={`head-${item.value}`}
            sx={{ fontWeight: '700' }}
          >
            {item.label}
          </TableCell>
        ))}
        <TableCell align="center" width={120} sx={{ fontWeight: '700' }}>
          Total Comments
        </TableCell>
        <TableCell width={150} align="center" sx={{ fontWeight: '700' }}>
          Published At
        </TableCell>
        <TableCell align="center" width={120} sx={{ fontWeight: '700' }}>
          Status
        </TableCell>
        <TableCell align="center" width={120} sx={{ fontWeight: '700' }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [NEWS_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(newsTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no news(s) to display
          </TableCell>
        </TableRow>
      );
    return _.map(newsTable, (item, index) => {
      return (
        <TableRow hover key={index}>
          <TableCell>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
                width: 300,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis',
              }}
              onClick={() => {
                Utils.redirect(ROUTERS.UPDATE_NEWS, { id: item.id });
              }}
            >
              {item.title}
            </Typography>
          </TableCell>
          {_.map(NEWS_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={innerKey}>
              <Typography
                key={`row${innerKey}-${index}`}
                sx={{
                  width: 130,
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden !important',
                }}
              >
                {head.value !== 'tag' && _.get(item, head.value)}
                {head.value === 'tag' &&
                  _.get(item, head.value) &&
                  _.get(item, head.value).replaceAll(';', ', ')}
              </Typography>
            </TableCell>
          ))}
          <TableCell align="center">{item?.comment?.length}</TableCell>
          <TableCell align="center">
            {item?.status === 'publish' && (
              <Typography>
                {item?.publishAt ? Utils.getFormatTime(item.publishAt) : 'N/I'}
              </Typography>
            )}
          </TableCell>
          <TableCell key={`status.${index}`} align="center">
            <TextField
              defaultValue={item?.status}
              key={`status${Math.random()}`}
              select
              size="small"
              sx={{
                width: '130px',
                '& .MuiSelect-select': {
                  color:
                    _.get(TableStatusColor, _.get(item, 'status')) ||
                    CommonColors.black,
                },
              }}
              onChange={(e) => onChangeOption(e?.target?.value, item.id)}
            >
              {STATUS_DROPDOWN.map(
                (option: IDropdownOption, innerKey: number) => {
                  return (
                    <MenuItem
                      key={`${option.label}-${innerKey}`}
                      value={option.value}
                      sx={{
                        color:
                          _.get(TableStatusColor, _.get(option, 'value')) ||
                          CommonColors.black,
                        display:
                          (item?.status === option.value && item?.status) ||
                          (item?.status === ENUMS.STATUS.INACTIVE &&
                            option.value !== ENUMS.STATUS.ACTIVE)
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <Typography fontSize={14}>{option.label}</Typography>
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </TableCell>
          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View detail">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() => {
                  Utils.redirect(ROUTERS.UPDATE_NEWS, { id: item.id });
                }}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 3}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Dialogs.Confirm
        confirm={confirmDialog}
        onCancel={() => onCancel()}
        callback={() => handleSubmitChangeStatus(confirmDialog.state)}
      />
      {_renderTable()}
    </Box>
  );
};

export default NewsTable;
