import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ClearIcon from '@mui/icons-material/Clear';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import dayjs from 'dayjs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';

import { UserScoringActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  SCORING_TEMPLATE,
  STEP_OF_SCORINGS,
  ENUMS,
  ROUTERS,
  DEFAULT_SCORING_FORM,
} from '@constants';
import {
  TStepCode,
  ISubArea,
  IStandard,
  IIndicatorTemplate,
  ICreateAreaScore,
  ICreateTypeStandardScore,
  ICreateIndicatorScore,
} from '@interfaces/CountryScoring.interface';
import ScoringDialog from './ScoringDialog';
import Utils from '@/Utils';
import { ActiveBreadCrumb } from '@/Components/Common';

// Declare constants
const { createNewScoring } = UserScoringActions;
const ScoringReport: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'isActionLoading')
  );
  const [selectedCode, setSelectedCode] = useState<TStepCode>('area_1');
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [indicators, setIndicators] = useState<IIndicatorTemplate[]>([]);
  const [selectedSubCode, setSelectedSubCode] = useState<string>('');
  const [selectedStandard, setSelectedStandard] = useState<string>('');
  const [year, setYear] = useState(dayjs().year());
  const [reportTitle, setReportTitle] = useState('');
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [isMessageYear, setIsMessageYear] = useState<string>('');
  const [areaForm, setAreaForm] =
    useState<ICreateAreaScore[]>(DEFAULT_SCORING_FORM);
  const [standardResuls, setStandardResuls] = useState<{
    [key: string]: number;
  }>();
  const [defaultDialogStandard, setDefaultDialogStandard] = useState<
    ICreateTypeStandardScore | undefined
  >(undefined);
  useEffect(() => {
    if (standardResuls) {
      const checkProcess = Utils.generateScoringProcess(standardResuls);
      const totalComplete = _.filter(checkProcess, (e) => e.isCompleted);
      if (totalComplete.length === 3) setIsAllCompleted(true);
      setRenderKey(Math.random());
    }
  }, [standardResuls]);

  // Events
  const handleFillForm = (
    data: IIndicatorTemplate[],
    key: string,
    code: string,
    subcode: string
  ) => {
    const findAreaIndex = _.findIndex(areaForm, ['code', selectedCode]);
    if (findAreaIndex !== -1) {
      const findSubIndex = _.findIndex(areaForm[findAreaIndex].subArea, [
        'code',
        subcode,
      ]);
      if (findSubIndex !== -1) {
        const findStandardIndex = _.findIndex(
          areaForm[findAreaIndex].subArea[findSubIndex].standard,
          ['code', code]
        );
        if (findStandardIndex !== -1) {
          const findTypeStandardIndex = _.findIndex(
            areaForm[findAreaIndex].subArea[findSubIndex].standard[
              findStandardIndex
            ].typeStandard,
            ['type', key]
          );
          if (findTypeStandardIndex !== -1) {
            setDefaultDialogStandard(
              areaForm[findAreaIndex].subArea[findSubIndex].standard[
                findStandardIndex
              ].typeStandard[findTypeStandardIndex]
            );
          }
        }
      }
    }
    setDialogTitle(key);
    setIndicators(data);
    setSelectedStandard(code);
    setSelectedSubCode(subcode);
    setIsOpen(true);
  };

  const onClearForm = () => {
    setYear(dayjs().year());
    setIsMessageYear('');
    setReportTitle('');
    setAreaForm([...DEFAULT_SCORING_FORM]);
    setSelectedCode('area_1');
    setStandardResuls({});
    setDialogTitle('');
    setIndicators([]);
    setSelectedSubCode('');
    setSelectedStandard('');
  };

  const createAsDraft = () =>
    dispatch(
      createNewScoring({
        title: reportTitle,
        year: _.toString(year),
        area: areaForm,
        status: ENUMS.STATUS.DRAFT,
        extraData: {
          standardResuls,
        },
      })
    );

  const sendToReviewer = () =>
    dispatch(
      createNewScoring({
        title: reportTitle,
        year: _.toString(year),
        area: areaForm,
        status: ENUMS.STATUS.REVIEW,
        extraData: {
          standardResuls,
        },
      })
    );

  const handleClose = (data: ICreateIndicatorScore[]) => {
    if (data) {
      const newAreaForm = _.map(areaForm, (area) => {
        if (area.code === selectedCode) {
          const { subArea } = area;
          const newSubArea = _.map(subArea, (sub) => {
            if (sub.code === selectedSubCode) {
              const { standard } = sub;
              const newStandard = _.map(standard, (sta) => {
                if (sta.code === selectedStandard) {
                  const { typeStandard } = sta;
                  const newTypeStandard = _.map(typeStandard, (typeSta) => {
                    if (typeSta.type === dialogTitle) {
                      return {
                        ...typeSta,
                        indicator: data,
                      };
                    }
                    return { ...typeSta };
                  });
                  return { ...sta, typeStandard: newTypeStandard };
                }
                return { ...sta };
              });
              return {
                ...sub,
                standard: newStandard,
              };
            }
            return { ...sub };
          });
          return { ...area, subArea: newSubArea };
        }
        return { ...area };
      });
      setAreaForm(newAreaForm);
      setStandardResuls({
        ...standardResuls,
        [`${selectedStandard}${dialogTitle}`]: data.length,
      });
    }
    setIsOpen(false);
  };

  const handleChangeStep = (code: TStepCode) => {
    const newAreaForm = [...areaForm];
    const findAreaIndex = _.findIndex(areaForm, ['code', code]);
    if (findAreaIndex === -1) newAreaForm.push({ code, subArea: [] });
    setAreaForm(newAreaForm);
    setSelectedCode(code);
  };

  const handleOnchangeTitle = (e: any) => setReportTitle(e.target.value);

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = _.toNumber(Utils.getFormatDate(newValue, 'YYYY'));
      if (!dayjs(newValue).isBefore(dayjs()) || yearSelect < 1900) {
        setIsMessageYear('Please choose year valid');
      } else {
        setIsMessageYear('');
        setYear(yearSelect);
      }
    } else setIsMessageYear('Please choose year valid');
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const status = event.nativeEvent.submitter?.name;
    if (status === ENUMS.STATUS.DRAFT) createAsDraft();
    if (status === ENUMS.STATUS.REVIEW) sendToReviewer();
  };

  // Renders
  const _renderSteps = () => {
    return _.map(STEP_OF_SCORINGS, (step) => {
      return (
        <Step key={step.code}>
          <StepButton
            disabled={isActionLoading}
            onClick={() => handleChangeStep(step.code)}
          >
            {step.label}
          </StepButton>
        </Step>
      );
    });
  };

  const _renderStandards = (standards: IStandard[], subcode: string) => {
    const OFFSET_SIZE = 4;
    const resizeColumn = _.size(standards) === OFFSET_SIZE;
    return _.map(standards, (standard, index) => {
      const totalFilledLegislation =
        _.get(standardResuls, `${standard.code}legislation`) || 0;
      const totalFilledPractice =
        _.get(standardResuls, `${standard.code}practice`) || 0;
      return (
        <Grid
          item
          key={`new${standard.code}`}
          xs={12}
          md={4}
          sx={{
            position: 'relative',
            mr: index === 2 && resizeColumn ? '1px' : 0,
            right: '-1px', //grid breaks standard 3-4
          }}
        >
          <Card title={standard.name}>
            <CardContent sx={{ height: 150 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {standard.originalName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  pl={2}
                  sx={{ mx: 'auto', fontWeight: 400, fontSize: 12, mt: 2 }}
                >
                  ({standard.codeName})
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.legislation,
                    'legislation',
                    standard.code,
                    subcode
                  )
                }
                color={
                  totalFilledLegislation === standard.legislation.length
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                legislation filled ({totalFilledLegislation}/
                {standard.legislation.length})
              </Button>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.practice,
                    'practice',
                    standard.code,
                    subcode
                  )
                }
                color={
                  totalFilledPractice === standard.practice.length
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                practice filled ({totalFilledPractice}/
                {standard.practice.length})
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    });
  };

  const _renderSubAreas = (areas: ISubArea[]) => {
    return _.map(areas, (area) => {
      return (
        <Grid item key={`new${area.code}`}>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item>
              <Typography gutterBottom variant="h5">
                {area.codeName}: {area.name}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: 13, ml: 2 }}
              >
                Principle: {area.principle}
              </Typography>
            </Grid>
            <Divider variant="middle" />
            <Grid item px={2} py={1}>
              <Grid container columnSpacing={3} rowSpacing={4}>
                {_renderStandards(area.standards, area.code)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const _renderScoringForm = () => {
    const selectedForm = _.find(SCORING_TEMPLATE, ['code', selectedCode]);
    if (!selectedForm) return null;
    return (
      <Grid container direction="column">
        {_renderSubAreas(selectedForm.subAreas)}
      </Grid>
    );
  };

  const renderMain = () => {
    const findStep = _.find(STEP_OF_SCORINGS, ['code', selectedCode]);
    return (
      <Box component="form" onSubmit={handleSubmit}>
        <ScoringDialog
          open={isOpen}
          dialogTitle={dialogTitle}
          indicators={indicators}
          handleClose={handleClose}
          defaultStandard={defaultDialogStandard}
        />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActiveBreadCrumb screen="USER_SCORING" />
          </Grid>
          <Grid item xs={6} md={2}>
            <DatePicker
              inputFormat="YYYY"
              views={['year']}
              label="Year"
              value={year.toString() || null}
              onChange={handleChangeYear}
              maxDate={new Date()}
              key={`yearSelect${renderKey}`}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  sx={{ width: 1 }}
                  error={!!isMessageYear}
                  helperText={isMessageYear}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              fullWidth
              label="Title"
              required
              defaultValue={reportTitle}
              key={renderKey}
              onChange={(e) => handleOnchangeTitle(e)}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" p={2}>
          <Grid item>
            <Stepper
              nonLinear
              sx={{ width: 1 }}
              alternativeLabel
              activeStep={findStep?.step || 0}
            >
              {_renderSteps()}
            </Stepper>
          </Grid>
          <Grid item>{_renderScoringForm()}</Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="end">
          <Grid item mr="auto">
            <Button
              variant="contained"
              color="burntSienna"
              disabled={isActionLoading}
              startIcon={<ArrowBackIcon />}
              onClick={() => Utils.redirect(ROUTERS.MY_SCORING)}
            >
              Back to My Scoring
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              color="fuzzyWuzzyBrown"
              disabled={isActionLoading}
              startIcon={<ClearIcon />}
              onClick={onClearForm}
            >
              Clear
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              color="info"
              loading={isActionLoading}
              startIcon={<ReceiptIcon />}
              name={ENUMS.STATUS.DRAFT}
              disabled={Utils.isEmptyOrSpaces(reportTitle) || !year}
            >
              Save as Draft
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              color="oceanGreen"
              loading={isActionLoading}
              disabled={!isAllCompleted}
              startIcon={<ScheduleSendIcon />}
              name={ENUMS.STATUS.REVIEW}
            >
              Send to Reviewer
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default ScoringReport;
