import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { DataTable } from '@/Components/LayoutPart';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';

interface IFindingStructure {
  payload: string;
}

const KeyFindings: React.FC<IFindingStructure> = ({ payload }) => {
  const parsePayload = payload ? JSON.parse(payload) : {};
  return (
    <Box sx={{ sm: { px: 2 } }}>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid item xs={12}>
          {parsePayload?.executive && (
            <Box>
              <Box display="flex" alignItems="center">
                <AcUnitIcon
                  sx={{
                    color: CommonColors.mainColor,
                    fontSize: 14,
                  }}
                />
                <Typography ml={1} variant="h6" sx={{ fontWeight: 550 }}>
                  Executive Summary
                </Typography>
              </Box>
              <Box
                ml={2}
                component="div"
                dangerouslySetInnerHTML={Utils.createMarkup(
                  parsePayload?.executive
                )}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataTable.KeyFindingsRecommendationsUser
            keyFindings={parsePayload.findings}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeyFindings;
