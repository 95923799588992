/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
  MenuItem,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SwipeableViews from 'react-swipeable-views';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { TextEditor, PreviewPDF, SkeletonLoading } from '@/Components/Common';
import { MM_TOOLKIT_FORM, MM_TOOLKIT_OPTIONS } from '@/Constants';
import { MMToolkitActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';

import {
  IMMToolkitStandar,
  IMMToolkitTemplate,
  TStepCode,
} from '@interfaces/CountryScoring.interface';
import { DragDropUpload } from '@/Components/Common';

const { getMMToolkit, createMMToolkit } = MMToolkitActions;

const APMMToolkit: React.FC = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const mmToolkitContent: any | null = useSelector((state: RootState) =>
    _.get(state.MM_TOOLKIT, 'mmToolkitContent')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.MM_TOOLKIT, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.MM_TOOLKIT, 'isActionLoading')
  );
  const [selectedSubAreaIndex, setSelectedSubAreaIndex] = useState<number>(0);
  const [selectedStandardIndex, setSelectedStandardIndex] = useState<number>(0);
  const [mmToolkitForm, setMMToolkitForm] = useState<IMMToolkitTemplate[]>([]);
  const [selectedArea, setSelectedArea] = useState<TStepCode | string>(
    'area_1'
  );
  const [areaContent, setAreaContent] = useState<IMMToolkitTemplate | null>(
    null
  );
  const [file, setFile] = useState<File[]>([]);
  const [currentFilePath, setCurrentFilePath] = useState('');

  useEffect(() => {
    dispatch(getMMToolkit());
  }, []);

  useEffect(() => {
    const getContent = _.get(mmToolkitContent, 'content');
    const content = _.isString(getContent) ? JSON.parse(getContent) : null;
    const form = content || MM_TOOLKIT_FORM;
    setMMToolkitForm(form);
    setAreaContent(_.find(form, ['code', selectedArea]));
  }, [mmToolkitContent]);

  useEffect(() => {
    generateAssets();
  }, [mmToolkitContent?.file]);

  const generateAssets = async () => {
    if (!_.isEmpty(mmToolkitContent?.file)) {
      const isInternal = mmToolkitContent?.file?.storageService === 'Internal';
      const fileSrc = isInternal
        ? await Utils.getFileFromURL(
            mmToolkitContent.file.path,
            mmToolkitContent.file.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            mmToolkitContent.file.path,
            mmToolkitContent.file.nameOriginal,
            'pdf'
          );
      if (fileSrc) {
        setFile([fileSrc as File]);
        setCurrentFilePath(mmToolkitContent?.file?.path);
      }
    }
  };

  // Events
  const handleChangeArea = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const newMMToolkitForm: IMMToolkitTemplate[] = _.map(mmToolkitForm, (f) => {
      if (f.code === selectedArea && areaContent) return areaContent;
      return f;
    });
    const area = _.find(newMMToolkitForm, ['code', e.target.value]) || null;
    setMMToolkitForm(newMMToolkitForm);
    setAreaContent(area);
    setSelectedArea(e.target.value);
    setSelectedSubAreaIndex(0);
    setSelectedStandardIndex(0);
  };

  const onChangeStandard = (
    key: 'practice' | 'legislation' | 'name',
    value: string,
    code: string
  ) => {
    const currentSubArea = areaContent?.subAreas[selectedSubAreaIndex];
    if (!currentSubArea) return;
    const currentStandard = _.find(currentSubArea.standards, ['code', code]);
    if (!currentStandard) return;
    const newStandard = { ...currentStandard, [key]: value };
    const standardIndex = _.findIndex(
      currentSubArea.standards,
      (standard) => standard.code === code
    );
    const newAreaContent = { ...areaContent };
    newAreaContent.subAreas[selectedSubAreaIndex].standards[standardIndex] =
      newStandard;
    setAreaContent(areaContent);
  };

  const onChangePrinciple = (value: string) => {
    const currentSubArea = areaContent?.subAreas[selectedSubAreaIndex];
    if (!currentSubArea) return;
    const newAreaContent = { ...areaContent };
    newAreaContent.subAreas[selectedSubAreaIndex] = {
      ...currentSubArea,
      principle: value,
    };
    setAreaContent(newAreaContent);
  };

  const onSaveMMToolkit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', 'MM Toolkit');
    formData.append('content', JSON.stringify(mmToolkitForm));
    if (!_.isEmpty(file)) formData.append('file', file[0]);
    else formData.append('fileRemove', JSON.stringify(true));
    dispatch(createMMToolkit(formData));
  };

  const handleChangeFile = async (newFiles: File[]) => {
    setFile(newFiles);
    if (newFiles && newFiles.length > 0) {
      const firstFile = newFiles[0];
      const newPath = await Utils.getURLFromFile(firstFile);
      setCurrentFilePath(newPath as string);
    } else setCurrentFilePath('');
  };

  // Renders
  const _renderTitle = () => {
    return (
      <Grid item xs={10}>
        <BreadCrumb title="The Monitoring Matrix (Tool-kit)" />
      </Grid>
    );
  };

  const _renderOptions = () => {
    return (
      <TextField
        select
        defaultValue={selectedArea}
        fullWidth
        disabled={isGetLoading || isActionLoading}
        label="Toolkit Part"
        key={`Part${selectedArea}`}
        onChange={(e) => handleChangeArea(e)}
      >
        {_.map(MM_TOOLKIT_OPTIONS, (option, index) => {
          return (
            <MenuItem key={`${option.label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const _renderSubAreaNavigator = (standards: IMMToolkitStandar[]) => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            startIcon={<NavigateBeforeIcon />}
            color="oceanGreen"
            disabled={!selectedStandardIndex}
            onClick={() => setSelectedStandardIndex(selectedStandardIndex - 1)}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            endIcon={<NavigateNextIcon />}
            color="oceanGreen"
            disabled={
              selectedStandardIndex + 1 === standards.length ||
              !standards.length
            }
            onClick={() => setSelectedStandardIndex(selectedStandardIndex + 1)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderStandards = (standards: IMMToolkitStandar[]) => {
    return _.map(standards, (standard, index) => {
      return (
        <Grid container key={standard.code} p={2} spacing={2}>
          <Grid item xs={12}>
            <TextField
              defaultValue={standard.name}
              multiline
              key={`${standard.code}${index}`}
              rows={3}
              fullWidth
              label={standard.codeName}
              onBlur={(e) =>
                onChangeStandard('name', e.target.value, standard.code)
              }
              disabled={isGetLoading || isActionLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom textAlign="center">
                  Legistation
                </Typography>
                <TextEditor
                  key={`leg${index}`}
                  value={standard.legislation}
                  disabled={isGetLoading || isActionLoading}
                  onChange={(e: string) =>
                    onChangeStandard('legislation', e, standard.code)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom textAlign="center">
                  Practice
                </Typography>
                <TextEditor
                  key={`prac${index}`}
                  value={standard.practice}
                  disabled={isGetLoading || isActionLoading}
                  onChange={(e: string) =>
                    onChangeStandard('practice', e, standard.code)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const _renderContent = () => {
    if (!areaContent) return;
    const { subAreas } = areaContent;
    return _.map(subAreas, (subArea) => {
      const { standards } = subArea;
      return (
        <Grid container key={subArea.code} spacing={4}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6">
                  {subArea.codeName}: {subArea.name}
                </Typography>
              </Grid>
              <Grid item xs={12} pb={1}>
                <TextField
                  defaultValue={subArea?.principle}
                  multiline
                  key={`Principle${Math.random()}`}
                  rows={3}
                  fullWidth
                  label={'Principle'}
                  onBlur={(e) => onChangePrinciple(e.target.value)}
                  // onChangeStandard('name', e.target.value, standard.code)
                  disabled={isGetLoading || isActionLoading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {_renderSubAreaNavigator(standards)}
          </Grid>
          {!_.isEmpty(standards) && (
            <Grid item xs={12}>
              <SwipeableViews
                key={`swipe${subArea.code}`}
                axis="x"
                index={selectedStandardIndex}
                onChangeIndex={(i) => setSelectedStandardIndex(i)}
              >
                {_renderStandards(standards)}
              </SwipeableViews>
            </Grid>
          )}
        </Grid>
      );
    });
  };

  const renderMain = () => {
    if (isGetLoading) return <SkeletonLoading numberRender={5} />;
    return (
      <Grid container direction="column" rowSpacing={3} pb={3}>
        {_renderTitle()}
        <Grid item>{_renderOptions()}</Grid>
        <Grid item>
          {!_.isEmpty(areaContent?.subAreas) && (
            <SwipeableViews
              axis="x"
              index={selectedSubAreaIndex}
              onChangeIndex={(i) => setSelectedSubAreaIndex(i)}
            >
              {_renderContent()}
            </SwipeableViews>
          )}
        </Grid>
        {selectedArea !== 'preview_pdf' && (
          <Grid item>
            <Grid container>
              <Grid item xs={2}>
                <Button
                  startIcon={<NavigateBeforeIcon />}
                  color="burntSienna"
                  disabled={!selectedSubAreaIndex}
                  onClick={() => {
                    setSelectedSubAreaIndex(selectedSubAreaIndex - 1);
                    setSelectedStandardIndex(0);
                  }}
                >
                  Prev Sub-Area
                </Button>
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={2} textAlign="right">
                <Button
                  endIcon={<NavigateNextIcon />}
                  color="burntSienna"
                  disabled={
                    selectedSubAreaIndex + 1 === areaContent?.subAreas.length ||
                    !areaContent?.subAreas.length
                  }
                  onClick={() => {
                    setSelectedSubAreaIndex(selectedSubAreaIndex + 1);
                    setSelectedStandardIndex(0);
                  }}
                >
                  Next Sub-Area
                </Button>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        )}
        {selectedArea === 'preview_pdf' && (
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <DragDropUpload
                  type="PDF"
                  files={file}
                  onFileChange={handleChangeFile}
                />
              </Grid>
            </Grid>
            {file && currentFilePath && (
              <Grid container sx={{ mt: 4 }}>
                <Grid item xs={12}>
                  <PreviewPDF file={currentFilePath} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item>
          <Grid container justifyContent="end">
            <Grid item>
              <LoadingButton
                variant="contained"
                color="oceanGreen"
                loading={isGetLoading || isActionLoading}
                onClick={(e) => onSaveMMToolkit(e)}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default APMMToolkit;
