import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TextField,
} from '@mui/material';

import { IReportTemplate } from '@/Interfaces/RegionalReport';
import { REPORT_KEY } from '@/Constants/RegionalReport';
import { RootState, useTypedDispatch } from '@/store';
import { RegionalActions } from '@/Actions';

const { saveDefaultOverview } = RegionalActions;

const OverViewTable = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const defaultOverview: IReportTemplate[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultOverview')
  );
  const selectedCountry: string[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultCountryOptions')
  );
  const countryFullOptions: string[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultCountryFullOptions')
  );

  useEffect(() => {
    const results: IReportTemplate[] = _.map(countryFullOptions, (country) => {
      if (defaultOverview) {
        const findedCountry = _.find(defaultOverview, [
          'value',
          _.get(country, 'value'),
        ]);
        if (findedCountry) return findedCountry;
      }
      return {
        name: _.get(country, 'label') || '',
        value: _.get(country, 'value') || '',
        content: {
          numberOfRegisteredOrganizations: '',
          keyCivilSocietyLaws: '',
          relevantChangesInLegalFrameworks: '',
          stateFunding: '',
          humanResources: '',
          csoGovernmentCooperation: '',
          otherKeyChallenges: '',
        },
      };
    });
    dispatch(saveDefaultOverview(results));
  }, [countryFullOptions]);

  // Events
  const handleOnChange = (
    contentKey: string,
    content: string,
    dataKey: string
  ) => {
    const newForm = _.map(defaultOverview, (item) => {
      if (item.value === dataKey)
        return {
          ...item,
          content: { ...item.content, [contentKey]: content },
        };
      return item;
    });
    // setOverviewState(newForm);
    dispatch(saveDefaultOverview(newForm));
  };

  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        <TableCell />
        {_.map(REPORT_KEY, (item) => (
          <TableCell
            align="center"
            key={`head-${item.value}`}
            sx={{ width: 300 }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }, []);

  const _renderTableBody = () => {
    return _.map(_.sortBy(defaultOverview, ['name']), (item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.name}</TableCell>
          {_.map(REPORT_KEY, (head, innerKey) => (
            <TableCell
              align="center"
              key={`row${innerKey}-${index}`}
              sx={{ padding: '4px' }}
            >
              <TextField
                variant="outlined"
                value={_.get(item.content, head.value)}
                rows={4}
                multiline
                InputProps={{
                  style: {
                    padding: '5px 2px 5px 5px',
                  },
                }}
                onChange={(e) =>
                  handleOnChange(head.value, e.target.value, item.value)
                }
              />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const _renderTable = () => {
    return (
      <Paper sx={{ overflowX: 'auto', width: 1 }}>
        {selectedCountry.length != 0 && (
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>{_renderTableHeader}</TableHead>
            <TableBody>{_renderTableBody()}</TableBody>
          </Table>
        )}
      </Paper>
    );
  };

  return _renderTable();
};

export default OverViewTable;
