const ADMIN_ROUTER_ROOT = '/admin';
const USER_ROUTER_ROOT = '/';
const REVIEWER_ROUTER_ROOT = '/reviewer';

const BASIC_ROUTERS = {
  FORBIDEN: '/forbiden',
  NOT_FOUND: '*',
  AUTH: '/auth',
  CHANGE_PASSWORD: '/auth/change-password',
  FORGOT_PASSWORD: '/auth/forgot-password',
  VERIFY_ACCOUNT: '/verify-account',
  COUNTRY_REPORT: `${USER_ROUTER_ROOT}country-report`,
  NEWS: `${USER_ROUTER_ROOT}news`,
  NEWS_DETAIL: `${USER_ROUTER_ROOT}news/detail`,
  HOME: USER_ROUTER_ROOT,
  ABOUT_BACKGROUND: `${USER_ROUTER_ROOT}background`,
  ABOUT_MMTOOLKIT: `${USER_ROUTER_ROOT}the-monitoring-matrix-toolkit`,
  GUIDELINES: `${USER_ROUTER_ROOT}guidelines`,
  METHODOLOGY: `${USER_ROUTER_ROOT}methodology`,
  REGIONAL_REPORT: `${USER_ROUTER_ROOT}regional-report`,
  COMPARE_EXPLORE_YEAR: `${USER_ROUTER_ROOT}compare-explore-year`,
  COMPARE_EXPLORE_COUNTRY: `${USER_ROUTER_ROOT}compare-explore-country`,
};

const ADMIN_ROUTERS = {
  ADMIN_DASHBOARD: ADMIN_ROUTER_ROOT,
  USER_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/users`,
  CREATE_USER: `${ADMIN_ROUTER_ROOT}/users/create`,
  UPDATE_USER: `${ADMIN_ROUTER_ROOT}/users/update`,
  GALLERY_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/galleries`,
  ACCOUNT_ADMIN: `${ADMIN_ROUTER_ROOT}/account`,
  NEWS_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/news`,
  NEW_POST: `${ADMIN_ROUTER_ROOT}/news/new-post`,
  UPDATE_NEWS: `${ADMIN_ROUTER_ROOT}/news/update`,
  FOOTER_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/footer-management`,
  CONTACTS: `${ADMIN_ROUTER_ROOT}/contacts`,
  ADMIN_BACKGROUND: `${ADMIN_ROUTER_ROOT}/background`,
  ADMIN_GUIDELINES: `${ADMIN_ROUTER_ROOT}/guidelines`,
  ADMIN_METHODOLOGY: `${ADMIN_ROUTER_ROOT}/methodology`,
  ADMIN_MMTOOLKIT: `${ADMIN_ROUTER_ROOT}/the-monitoring-matrix-toolkit`,
  SOCIAL_LINKS: `${ADMIN_ROUTER_ROOT}/social-links`,
  COUNTRY_REPORT_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/country-reports`,
  CREATE_COUNTRY_REPORT: `${ADMIN_ROUTER_ROOT}/country-reports/create`,
  // UPDATE_COUNTRY_REPORT: `${ADMIN_ROUTER_ROOT}/country-reports/update`,
  COUNTRY_REPORT_DETAIL: `${ADMIN_ROUTER_ROOT}/country-reports/detail`,
  REGIONAL_REPORT_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/regional-reports`,
  NEW_REPORT: `${ADMIN_ROUTER_ROOT}/regional-reports/create`,
  UPDATE_REGIONAL_REPORT: `${ADMIN_ROUTER_ROOT}/regional-reports/update`,
  COUNTRY_SCORING_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/country-scoring`,
  COUNTRY_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/countries`,
  UPDATE_COUNTRY: `${ADMIN_ROUTER_ROOT}/countries/update`,
  ADMIN_COUNTRY_SCORING_DETAIL: `${ADMIN_ROUTER_ROOT}/country-scoring/detail`,
  // COMPARE_EXPLORE_YEAR: `${ADMIN_ROUTER_ROOT}/compare-explore-year`,
  // COMPARE_EXPLORE_COUNTRY: `${ADMIN_ROUTER_ROOT}/compare-explore-country`,
};

const USER_ROUTERS = {
  SCORING_REPORT: `${USER_ROUTER_ROOT}scoring`,
  COUNTRY_SCORING: `${USER_ROUTER_ROOT}country-scoring`,
  MY_SCORING: `${USER_ROUTER_ROOT}my-scoring`,
  UPDATE_SCORING: `${USER_ROUTER_ROOT}scoring/detail`,
  PROFILE: `${USER_ROUTER_ROOT}profile`,
  MY_REPORT: `${USER_ROUTER_ROOT}my-reports`,
  NEW_COUNTRY_REPORT: `${USER_ROUTER_ROOT}new-country-report`,
  UPDATE_MY_COUNTRY_REPORT: `${USER_ROUTER_ROOT}country-report-detail`,
  MY_REPORT_CHART: `${USER_ROUTER_ROOT}country-report/get-chart`,
  REGIONAL_REPORTS_DETAIL: `${USER_ROUTER_ROOT}regional-report-detail`,
};

const REVIEWER_ROUTERS = {
  REVIEWER_DASHBOARD: REVIEWER_ROUTER_ROOT,
  ACCOUNT_REVIEWER: `${REVIEWER_ROUTER_ROOT}/account`,
  REVIEW_SCORING: `${REVIEWER_ROUTER_ROOT}/review-scoring`,
  REVIEW_SCORING_DETAIL: `${REVIEWER_ROUTER_ROOT}/review-scoring/detail`,
  REVIEW_COUNTRY_REPORT: `${REVIEWER_ROUTER_ROOT}/country-report`,
  REVIEW_COUNTRY_REPORT_DETAIL: `${REVIEWER_ROUTER_ROOT}/country-report/detail`,
};

export { BASIC_ROUTERS, ADMIN_ROUTERS, USER_ROUTERS, REVIEWER_ROUTERS };

export default {
  ...BASIC_ROUTERS,
  ...ADMIN_ROUTERS,
  ...USER_ROUTERS,
  ...REVIEWER_ROUTERS,
};
