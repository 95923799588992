import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Grid,
  TextField,
  Collapse,
  Typography,
  Divider,
  TextFieldProps,
  Chip,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import {
  UploadImage,
  ActiveBreadCrumb,
  Dropdown,
  CountryAsyncDropdown,
} from '@/Components/Common';
import DefaultLayout from '@/Components/DefaultLayout';
import { Dialogs } from '@/Components/LayoutPart';

import Utils from '@/Utils';
import { ROUTERS, ENUMS, DEFAULT_CONFIRM } from '@/Constants';
import { UserManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';

import { IUserData } from '@interfaces/User.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { CommonColors } from '@themes';

const { getUserById, updateUser, resetUserReducer } = UserManagementActions;

const DEFAULT_USERDATA: IUserData = {
  firstName: '',
  lastName: '',
};

const UpdateUser: React.FC = () => {
  const locationState = useLocation().state;
  const dispatch = useTypedDispatch();
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'isActionLoading')
  );
  const userProfile: any = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'userProfile')
  );

  const [tabActive, setTabActive] = useState<boolean>(true);
  const [userData, setUserData] = useState<IUserData>(DEFAULT_USERDATA);
  const [avatar, setAvatar] = useState<File[]>([]);

  const [roleCode, setRoleCode] = useState<any>('');
  const [isTerminatedUser, setIsTerminatedUser] = useState<boolean>(false);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [countryId, setCountryId] = useState<string>('');
  const [countryAssignIds, setCountryAssignIds] = useState<string[]>([]);
  const [countryUnassignIds, setcountryUnassignIds] = useState<string[]>([]);
  const [assignedCountries, setAssignedCountries] = useState<
    { id: string; name: string; extraData: string }[]
  >([]);
  const [waringMessage, setWaringMessage] = useState<string>('');

  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const [isValidDateMsg, setIsValidDateMsg] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetUserReducer());
    };
  }, []);

  useEffect(() => {
    if (locationState && locationState.id) {
      dispatch(getUserById(locationState.id));
    } else {
      Utils.redirect(ROUTERS.USER_MANAGEMENT);
    }
  }, [locationState]);

  useEffect(() => {
    if (userProfile) generateDetails();
  }, [userProfile]);

  useEffect(() => {
    if (waringMessage)
      Utils.sleep(5000)
        .then(() => setWaringMessage(''))
        .catch(() => {});
  }, [waringMessage]);

  const generateDetails = async () => {
    setUserData(_.get(userProfile, 'userData'));
    setRoleCode(_.get(userProfile, 'role.roleCode'));
    setIsTerminatedUser(
      _.get(userProfile, 'status') === ENUMS.STATUS.TERMINATED
    );
    setCountryId(_.get(userProfile, 'country.id'));
    setAssignedCountries(_.get(userProfile, 'countryReview') || []);

    setCountryAssignIds([]);
    setcountryUnassignIds([]);
    setRenderKey(Math.random());

    if (userProfile.userData.avatar) {
      const avatarSrc = await Utils.getFileFromURL(
        userProfile.userData.avatar.path,
        userProfile.userData.avatar.nameOriginal
      );
      if (avatarSrc) setAvatar([avatarSrc]);
    }
  };

  const onCancel = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const handleDelete = (state: { id?: string; status: string }) => {
    const { id } = state;
    if (id) {
      const newAssignedCountries = _.filter(assignedCountries, (item) => {
        return item.id !== id;
      });
      if (_.isEmpty(newAssignedCountries)) {
        setWaringMessage(
          'Each reviewer must be have at least one country to review'
        );
      } else {
        setcountryUnassignIds([...countryUnassignIds, id]);
        setAssignedCountries(newAssignedCountries);
      }
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const handleOnChange = (key: string, value: string | number | Dayjs) => {
    setUserData({ ...userData, [key]: value });
  };

  const handleChangeDate = (key: string, newValue: Dayjs | null) => {
    setUserData({
      ...userData,
      [key]: newValue ? dayjs(newValue).format('YYYY-MM-DD') : '',
    });
  };

  const handleFilesChange = (key: 'avatar', items: any[]) =>
    key === 'avatar' && setAvatar(items);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if ((userData?.dob && dayjs(userData?.dob).isValid()) || !userData?.dob) {
      const formData = new FormData();
      if (roleCode === ENUMS.ROLES.REVIEWER) {
        _.each(countryAssignIds, (id, index) => {
          formData.append(`countryAssignIds[${index}]`, id);
        });
        _.each(countryUnassignIds, (id, index) => {
          formData.append(`countryUnassignIds[${index}]`, id);
        });
      }
      _.map(userData, (el: any, key) => {
        if (key !== 'id' && key !== 'avatar')
          formData.append(`userData[${key}]`, el || '');
      });
      if (!_.isEmpty(avatar)) formData.append('userData[avatar]', avatar[0]);
      else formData.append('avatarRemove', JSON.stringify(true));
      dispatch(updateUser(formData, locationState?.id));
    } else setIsValidDateMsg('Dob invalid date/time format');
  };

  // Renders
  const _renderMainForm = (
    children: JSX.Element,
    title: string,
    isHasExpand?: boolean
  ) => (
    <Grid item>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            {!isHasExpand && (
              <Grid item sx={{ py: 0.5, px: 1 }}>
                <Typography variant="h5">{title}</Typography>
              </Grid>
            )}
            {isHasExpand && (
              <ListItemButton
                onClick={() => setTabActive(!tabActive)}
                sx={{ py: 0.5, px: 1, borderRadius: 1 }}
              >
                <ListItemText
                  primary={<Typography variant="h5">{title}</Typography>}
                />
                {tabActive ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderUserInformation = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <UploadImage
            disabled={isGetLoading || isActionLoading || isTerminatedUser}
            label=""
            onFileChange={(file) => handleFilesChange('avatar', file)}
            files={avatar}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                defaultValue={userData?.firstName || ''}
                key={`firstName${renderKey}`}
                label="First Name"
                disabled={isGetLoading || isActionLoading || isTerminatedUser}
                onChange={(e) => handleOnChange('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                label="Last name"
                value={userData?.lastName || ''}
                key={`lastName${renderKey}`}
                required
                disabled={isGetLoading || isActionLoading || isTerminatedUser}
                onChange={(e) => handleOnChange('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <DatePicker
                label="DOB"
                inputFormat="MM/DD/YYYY"
                value={userData?.dob ? dayjs(userData?.dob) : null}
                views={['day', 'month', 'year']}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={!_.isEmpty(isValidDateMsg)}
                    helperText={isValidDateMsg}
                    // required
                  />
                )}
                disabled={isGetLoading || isActionLoading || isTerminatedUser}
                onChange={(newValue: Dayjs | null) => {
                  if (dayjs(newValue).isValid()) setIsValidDateMsg('');
                  handleChangeDate('dob', newValue);
                }}
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Dropdown
                listOf="GENDER"
                key={`gender${renderKey}`}
                value={userData?.gender}
                disabled={isGetLoading || isActionLoading || isTerminatedUser}
                onChange={(val) => handleOnChange('gender', val)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Dropdown
                label="Role"
                listOf="ROLES"
                key={`role${renderKey}`}
                value={roleCode || ''}
                disabled
                required
                onChange={(val) => setRoleCode(val)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              {roleCode === ENUMS.ROLES.REVIEWER && (
                <CountryAsyncDropdown
                  label="Countries"
                  roleCode="reviewer"
                  multiples
                  disabled={isGetLoading || isActionLoading || isTerminatedUser}
                  getFor="UNASSIGNED_FOR_REVIEW"
                  defaultValue={countryAssignIds}
                  onChange={(ids) => setCountryAssignIds(ids)}
                  required
                  isEditAssignCountries
                />
              )}
              {roleCode === ENUMS.ROLES.USER && (
                <CountryAsyncDropdown
                  label="Country"
                  roleCode="user"
                  disabled
                  defaultValue={countryId || ''}
                  required
                  isEditAssignCountries
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {roleCode === ENUMS.ROLES.REVIEWER && (
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Assigned Countries:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {_.map(assignedCountries, (item) => {
                      return (
                        <Chip
                          size="medium"
                          sx={{
                            mr: 1,
                            mb: 1,
                            borderColor: `${_.get(item, 'extraData.color')}`,
                          }}
                          key={item.id}
                          id={item.id}
                          label={item.name}
                          variant="outlined"
                          onDelete={() =>
                            setConfirmDialog({
                              isOpen: true,
                              message: `This will remove this assigned country.`,
                              state: {
                                id: item.id,
                                status: '',
                              },
                            })
                          }
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: CommonColors.fuzzyWuzzyBrown }}
                >
                  {waringMessage}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderUserContact = () => {
    return (
      <Collapse in={tabActive} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              type="number"
              fullWidth
              label="Phone Number"
              defaultValue={userData?.phoneNumber || ''}
              key={`phoneNumber${renderKey}`}
              disabled={isGetLoading || isActionLoading || isTerminatedUser}
              onChange={(e) => handleOnChange('phoneNumber', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="City"
              defaultValue={userData?.city || ''}
              key={`city${renderKey}`}
              disabled={isGetLoading || isActionLoading || isTerminatedUser}
              onChange={(e) => handleOnChange('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              type="number"
              fullWidth
              label="Zip Code"
              defaultValue={userData?.zipCode || ''}
              key={`zipCode${renderKey}`}
              disabled={isGetLoading || isActionLoading || isTerminatedUser}
              onChange={(e) => handleOnChange('zipCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Address"
              defaultValue={userData?.address || ''}
              key={`address${renderKey}`}
              rows={5}
              multiline
              disabled={isGetLoading || isActionLoading || isTerminatedUser}
              onChange={(e) => handleOnChange('address', e.target.value)}
            />
          </Grid>
        </Grid>
      </Collapse>
    );
  };

  const _renderUserAccount = () => {
    return (
      <Grid container spacing={2}>
        <Grid sx={{ cursor: 'not-allowed' }} item xs={12} md={6} lg={4} mt={1}>
          <TextField
            fullWidth
            required
            defaultValue={userProfile?.email || ''}
            key={`email${renderKey}`}
            label="Email"
            disabled={true}
            onChange={() => {}}
          />
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" rowSpacing={4}>
          <ActiveBreadCrumb screen="USER_MANAGEMENT" />
          {_renderMainForm(_renderUserAccount(), 'Account')}
          {_renderMainForm(_renderUserInformation(), 'Information')}
          {_renderMainForm(_renderUserContact(), 'Contact', true)}
          <Grid container justifyContent="end" spacing={2} mt={2}>
            <Grid item>
              <Button
                variant="contained"
                color="fuzzyWuzzyBrown"
                onClick={() => Utils.redirect(ROUTERS.USER_MANAGEMENT)}
                disabled={isGetLoading || isActionLoading}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                color="oceanGreen"
                loading={isGetLoading || isActionLoading}
                disabled={isTerminatedUser}
              >
                Save
              </LoadingButton>
            </Grid>
            <Grid item>
              <Dialogs.Confirm
                title="Are you sure remove this country?"
                onCancel={() => onCancel()}
                confirm={confirmDialog}
                callback={() => handleDelete(confirmDialog.state)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default UpdateUser;
