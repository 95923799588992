import React, { useRef, useState, useEffect } from 'react';
import { Box, CssBaseline } from '@mui/material';

import { mainStyles } from './DefaultLayout.styles';
import Widgets from '../Widgets';
import UserLayout from './UserLayout';
import AdminLayout from './AdminLayout';
import ReviewerLayout from './ReviewerLayout';
import { FloatAction } from '../Common';
// import { ChartColumn } from '../Common';

type PORTAL_TYPE = 'USER' | 'REVIEWER' | 'ADMIN';
interface SectionProps {
  content: JSX.Element;
  portalFor?: PORTAL_TYPE;
  showCarousel?: boolean;
}

const DefaultLayout: React.FC<SectionProps> = (props: SectionProps) => {
  const { content, portalFor = 'USER', showCarousel } = props;
  const mainRef = useRef<HTMLInputElement>(null);
  const [pos, setPos] = useState(false);

  const handleTop = () => {
    if (mainRef.current != null) {
      mainRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setPos(false);
    }
  };

  const handleScroll = () => {
    if (mainRef.current != null && mainRef.current.scrollTop > 50) {
      if (!pos) setPos(true);
    } else {
      setPos(false);
    }
  };

  useEffect(() => {
    let temp: HTMLElement;
    if (mainRef.current != null) {
      temp = mainRef.current;
      temp.addEventListener('scroll', handleScroll);
    }
    return () => temp.removeEventListener('scroll', handleScroll);
  });

  const renderPotal = () => {
    switch (portalFor) {
      case 'ADMIN':
        return <AdminLayout content={content} />;
      case 'REVIEWER':
        return <ReviewerLayout content={content} />;
      default:
        return <UserLayout content={content} showCarousel={showCarousel} />;
    }
  };
  return (
    <Box sx={mainStyles} ref={mainRef}>
      <CssBaseline />
      {renderPotal()}
      <Widgets.Notification />
      <Widgets.Loading />
      <Widgets.Alert />
      {portalFor === 'USER' && <FloatAction callback={handleTop} pos={pos} />}
    </Box>
  );
};

export default DefaultLayout;
