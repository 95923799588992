/* eslint-disable indent */
import React from 'react';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ActiveBreadCrumb } from '@/Components/Common';
import { useState } from 'react';
import DefaultLayout from '@/Components/DefaultLayout';
import { Grid, MenuItem, TextField } from '@mui/material';
import { REGIONAL_REPORT_OPTIONS, ROUTERS, DEFAULT_CONFIRM } from '@/Constants';
import {
  Overview,
  Recommendations,
  Background,
  Acknowledgments,
  ReportPDF,
  Subareas,
} from './ReportParts';
import { LoadingButton } from '@mui/lab';
import Utils from '@/Utils';
import {
  IFindings,
  IFindingStructure,
  IRecommendations,
  IRegionalReportStructure,
  IReportTemplate,
  ISubareas,
  TStepRegionalReports,
} from '@interfaces/RegionalReport';
import { useLocation } from 'react-router-dom';
import { DEFAULT_REGIONAL_REPORT } from '@/Constants/RegionalReport';
import { RootState, useTypedDispatch } from '@/store';
import { RegionalActions } from '@/Actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { Dialogs } from '@/Components/LayoutPart';

const { updateRegionalReport, getRegionalReportById, resetDataRegionalReport } =
  RegionalActions;

const UpdataReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;
  const [selectedParts, setSelectedParts] =
    useState<TStepRegionalReports>('overview');
  const [fileReport, setFileReport] = useState<File[]>([]);
  const [yearState, setYearState] = useState<string>();
  const [titleState, setTitleState] = useState<string>('');
  const [subareasForm, setSubareasForm] = useState<ISubareas>();
  const [formRegional, setFormRegional] = useState<IRegionalReportStructure>(
    DEFAULT_REGIONAL_REPORT
  );
  const [currentFilePath, setCurrentFilePath] = useState('');

  const executive: IRecommendations = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultRecommendations.executive')
  );
  const defaultAcknowledgments: string = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultAcknowledgments')
  );
  const defaultBackground: string = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultBackground')
  );
  const findingState: IFindingStructure = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultSubareas')
  );
  const recommendations: string = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultRecomment')
  );
  const overviewForm: IReportTemplate[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultOverview')
  );
  const findingsRecommendations: IFindings = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultFindingsRecommendations')
  );

  const fileReportStorage = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'fileReport')
  );

  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'isActionLoading')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'isGetLoading')
  );
  const year: any = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultReportYear')
  );
  const title: any = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultReportTitle')
  );

  const [confirmBack, setConfirmBack] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    setSubareasForm({ ...subareasForm, findingState, recommendations });
  }, [findingState, recommendations]);

  useEffect(() => {
    setFormRegional({
      ...formRegional,
      recommendation: {
        findings: findingsRecommendations,
        executive: executive,
      },
      acknowledgment: defaultAcknowledgments,
      background: defaultBackground,
      finding: subareasForm,
      civilSociety: overviewForm,
    });
  }, [
    findingsRecommendations,
    executive,
    defaultAcknowledgments,
    defaultBackground,
    subareasForm,
    overviewForm,
  ]);

  useEffect(() => {
    setYearState(year);
    setTitleState(title);
  }, [year, title]);

  useEffect(() => {
    generateAssets();
  }, [fileReportStorage]);

  const generateAssets = async () => {
    if (!_.isEmpty(fileReportStorage)) {
      const isInternal = fileReportStorage?.storageService === 'Internal';
      const fileSrc = isInternal
        ? await Utils.getFileFromURL(
            fileReportStorage.path,
            fileReportStorage.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            fileReportStorage.path,
            fileReportStorage.nameOriginal,
            'pdf'
          );
      if (fileSrc) {
        setFileReport([fileSrc as File]);
        setCurrentFilePath(fileReportStorage?.path);
      }
    }
  };

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getRegionalReportById(locationState.id));
    } else Utils.redirect(ROUTERS.REGIONAL_REPORT_MANAGEMENT);
    return () => {
      dispatch(resetDataRegionalReport());
    };
  }, [locationState]);

  const handleChangePage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setSelectedParts(e.target.value as TStepRegionalReports);
  };

  const handleBack = () => {
    setConfirmBack({
      isOpen: true,
      message: 'Changes are not saved',
      state: {
        id: '',
        status: '',
      },
    });
  };

  const handleSubmitBack = () => {
    Utils.redirect(ROUTERS.REGIONAL_REPORT_MANAGEMENT);
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const onCancelBack = () => {
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const handleChangeFilePDF = async (newFiles: File[]) => {
    setFileReport(newFiles);
    if (newFiles && newFiles.length > 0) {
      const firstFile = newFiles[0];
      const newPath = await Utils.getURLFromFile(firstFile);
      setCurrentFilePath(newPath as string);
    } else setCurrentFilePath('');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    if (titleState) formData.append('title', _.trim(titleState));
    if (yearState) formData.append('year', _.trim(yearState));
    if (formRegional.acknowledgment)
      formData.append('acknowledgment', _.trim(formRegional.acknowledgment));
    if (formRegional.background)
      formData.append('background', _.trim(formRegional.background));
    if (formRegional.civilSociety) {
      const civilSociety = _.filter(
        formRegional.civilSociety,
        (item) =>
          _.trim(item?.content?.csoGovernmentCooperation) ||
          _.trim(item?.content?.humanResources) ||
          _.trim(item?.content?.keyCivilSocietyLaws) ||
          _.trim(item?.content?.numberOfRegisteredOrganizations) ||
          _.trim(item?.content?.otherKeyChallenges) ||
          _.trim(item?.content?.relevantChangesInLegalFrameworks) ||
          _.trim(item?.content?.stateFunding)
      );
      formData.append('civilSociety', JSON.stringify(civilSociety));
    }
    if (formRegional.recommendation) {
      const finding = _.filter(
        formRegional.recommendation.findings,
        (item) => _.trim(item.findings) || _.trim(item.recommendations)
      );
      const recommendation = {
        findings: finding,
        executive: formRegional.recommendation.executive,
      };
      formData.append('recommendation', JSON.stringify(recommendation));
    }
    if (formRegional.finding)
      formData.append('finding', JSON.stringify(formRegional.finding));
    if (!_.isEmpty(fileReport)) formData.append('fileReport', fileReport[0]);
    else formData.append('fileReportRemove', JSON.stringify(true));
    dispatch(updateRegionalReport(locationState.id, formData));
  };

  const _renderPages = (value: TStepRegionalReports) => {
    switch (value) {
      case 'overview':
        return (
          <Overview
            onUnfocus={(data: IReportTemplate[]) => {
              setFormRegional({ ...formRegional, civilSociety: data });
            }}
          />
        );
      case 'recommendations':
        return (
          <Recommendations
            onUnfocus={(data: IRecommendations) => {
              setFormRegional({ ...formRegional, recommendation: data });
            }}
          />
        );
      case 'acknowledgements':
        return (
          <Acknowledgments
            onUnfocus={(data: string) => {
              setFormRegional({ ...formRegional, acknowledgment: data });
            }}
          />
        );
      case 'background':
        return (
          <Background
            onUnfocus={(data: string) => {
              setFormRegional({ ...formRegional, background: data });
            }}
          />
        );
      case 'preview_pdf':
        return (
          <ReportPDF
            defaultURL={currentFilePath}
            file={fileReport}
            onFileChange={(file: any[]) => handleChangeFilePDF(file)}
          />
        );
      case 'areas':
        return (
          <Subareas
            onUnfocus={(data: ISubareas) => {
              setFormRegional({ ...formRegional, finding: data });
            }}
            hiddenToolbar
          />
        );
      default:
        return '';
    }
  };

  const _renderTitle = () => {
    return (
      <Grid item sx={{ mb: 3 }} xs={12}>
        <ActiveBreadCrumb screen="REGIONAL_REPORT" />
      </Grid>
    );
  };
  const _renderYear = () => {
    return (
      <DatePicker
        inputFormat="YYYY"
        views={['year']}
        label="Year"
        value={yearState}
        onChange={(newValue: Date | null) => {
          if (!_.isEmpty(newValue))
            setYearState(_.toString(new Date(newValue).getFullYear()));
        }}
        maxDate={new Date()}
        renderInput={(params: any) => (
          <TextField {...params} required fullWidth />
        )}
        disabled={isActionLoading}
      />
    );
  };

  const _renderOptions = () => {
    return (
      <TextField
        select
        fullWidth
        defaultValue={selectedParts}
        key={`Parts${selectedParts}`}
        label="Parts"
        onChange={(e) => handleChangePage(e)}
      >
        {_.map(REGIONAL_REPORT_OPTIONS, (option, index) => {
          return (
            <MenuItem key={`${option.label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const renderMain = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        sx={{ width: 1 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Dialogs.Confirm
          confirm={confirmBack}
          onCancel={onCancelBack}
          callback={() => handleSubmitBack()}
        />
        <Grid item>{_renderTitle()}</Grid>
        <Grid item px={2} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {_renderYear()}
            </Grid>
            <Grid item xs={9}>
              <TextField
                label="Title"
                value={titleState || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTitleState(e.target.value);
                }}
                fullWidth
                disabled={isActionLoading}
              />
            </Grid>
            <Grid item xs={12}>
              {_renderOptions()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item px={2} xs={12}>
          {_renderPages(selectedParts)}
        </Grid>
        <Grid item my={2} xs={12}>
          <Grid container px={2} spacing={2} justifyContent="end">
            <Grid item>
              <LoadingButton
                variant="contained"
                color="fuzzyWuzzyBrown"
                loading={isGetLoading || isActionLoading}
                onClick={() => handleBack()}
              >
                Back
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                color="oceanGreen"
                loading={isGetLoading || isActionLoading}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default UpdataReport;
