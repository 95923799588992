import React from 'react';

import { Grid } from '@mui/material';
import { DragDropUpload, PreviewPDF } from '@/Components/Common';
interface IPropsReportPDF {
  file: File[];
  defaultURL?: string;
  onFileChange(newFiles: File[]): void;
}
const ReportPDF: React.FC<IPropsReportPDF> = ({
  file,
  defaultURL,
  onFileChange,
}) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <DragDropUpload onFileChange={onFileChange} type="PDF" files={file} />
      </Grid>
      {defaultURL && file && (
        <Grid item xs={12} mt={4}>
          <PreviewPDF file={defaultURL} />
        </Grid>
      )}
    </Grid>
  );
};

export default ReportPDF;
