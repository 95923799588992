import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetReviewerCountryReportLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_GET_LOADING,
    payload,
  };
};

const setFetchReviewerCountryReportLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_FETCH_LOADING,
    payload,
  };
};

const setReviewerCountryReportActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_ACTION_LOADING,
    payload,
  };
};

const setReviewerCountryReportPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_PAGINATION,
    payload,
  };
};

const setReviewerCountryReportMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_META,
    payload,
  };
};

const resetReviewerCountryReportReducer = () => {
  return {
    type: ACTION_TYPES.RESET_REVIEWER_COUNTRY_REPORT_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_REVIEWER_COUNTRY_REPORT_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_REVIEWER_COUNTRY_REPORT_FAILURE,
  };
};

const fetchReviewerCountryReport = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchReviewerCountryReportLoading(true));
    await API.fetchCountryReports(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setReviewerCountryReportMeta(meta));
          dispatch(setReviewerCountryReportPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const fetchAllSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_ALL_REVIEWER_COUNTRY_REPORT_SUCCESS,
    payload,
  };
};

const fetchAllFail = () => {
  return {
    type: ACTION_TYPES.FETCH_ALL_REVIEWER_COUNTRY_REPORT_FAILURE,
  };
};

const fetchAllReviewerCountryReport = () => {
  return async (dispatch: any) => {
    dispatch(setFetchReviewerCountryReportLoading(true));
    await API.fetchCountryScoring({
      page: 0,
      limit: 0,
      orderBy: 'DESC',
      sortBy: 'year',
    })
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchAllFail());
        else {
          const items = _.get(result, 'items');
          dispatch(fetchAllSuccess(items));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchAllFail());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_REVIEWER_COUNTRY_REPORT_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_REVIEWER_COUNTRY_REPORT_BY_ID_FAILURE,
  };
};

const getReviewerCountryReportById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetReviewerCountryReportLoading(true));
    await API.getCountryReportById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const editSuccess = () => {
  return {
    type: ACTION_TYPES.EDITTING_REVIEWER_COUNTRY_REPORT_SUCCESS,
  };
};

const editFail = () => {
  return {
    type: ACTION_TYPES.EDITTING_REVIEWER_COUNTRY_REPORT_FAILURE,
  };
};

const sendCountryReportBackToUser = (
  id: string,
  payload: { status: string; comment?: string }
) => {
  return async (dispatch: any) => {
    dispatch(setReviewerCountryReportActionLoading(true));
    await API.editingCountryReport(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(editFail());
        else {
          dispatch(editSuccess());
          Utils.redirect(ROUTERS.REVIEW_COUNTRY_REPORT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(editFail());
      });
  };
};

const sendSuccess = () => {
  return {
    type: ACTION_TYPES.SEND_APPROVE_REVIEWER_COUNTRY_REPORT_SUCCESS,
  };
};

const sendFail = () => {
  return {
    type: ACTION_TYPES.SEND_APPROVE_REVIEWER_COUNTRY_REPORT_FAILURE,
  };
};

const sendCountryReportToAdmin = (
  id: string,
  payload: { status: string; comment?: string }
) => {
  return async (dispatch: any) => {
    dispatch(setReviewerCountryReportActionLoading(true));
    await API.reviewCountryReport(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(sendFail());
        else {
          const innerResponse: any = await API.watingPublicCountryReport(id);
          const resolved: any = await Utils.resolveResponse(innerResponse);
          if (!resolved) await dispatch(sendFail());
          else {
            dispatch(sendSuccess());
            Utils.redirect(ROUTERS.REVIEW_COUNTRY_REPORT);
          }
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(sendFail());
      });
  };
};

export default {
  fetchReviewerCountryReport,
  fetchAllReviewerCountryReport,
  getReviewerCountryReportById,
  resetReviewerCountryReportReducer,
  sendCountryReportBackToUser,
  sendCountryReportToAdmin,
};
