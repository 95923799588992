import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  Grid,
  Stepper,
  Step,
  StepButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { useLocation } from 'react-router-dom';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DefaultLayout from '@/Components/DefaultLayout';
import { ActiveBreadCrumb, SkeletonLoading } from '@/Components/Common';
import { Dialogs } from '@/Components/LayoutPart';
import { CountryScoringAction } from '@actions';
import { DEFAULT_CONFIRM, ENUMS } from '@/Constants';
import { useTypedDispatch, RootState } from '@/store';

import Utils from '@/Utils';
import { SCORING_TEMPLATE, STEP_OF_SCORINGS, ROUTERS } from '@constants';
import {
  TStepCode,
  ISubArea,
  IStandard,
  IIndicatorTemplate,
  ICreateAreaScore,
  ICreateTypeStandardScore,
  ICommentIndicator,
} from '@interfaces/CountryScoring.interface';
import ScoringDialog from './AdminScoringDialog';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';

// Declare constants
const {
  getCountryScoringById,
  resetCountryScoringReducer,
  approveScoring,
  unApproveScoring,
} = CountryScoringAction;

const ScoringReport: React.FC = () => {
  // Constructors
  const locationState = useLocation().state;
  const dispatch = useTypedDispatch();
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'isActionLoading')
  );
  const scoringDetail = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'scoringDetail')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'pagination')
  );
  const [isOpen, setIsOpen] = useState(false);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [selectedLevel, setSelectedLevel] = useState<{
    selectedCode: TStepCode;
    selectedSubCode: string;
    selectedStandardCode: string;
  }>({
    selectedCode: 'area_1',
    selectedSubCode: '',
    selectedStandardCode: '',
  });
  const [formState, setFormState] = useState<{
    area: ICreateAreaScore[];
    indicators: IIndicatorTemplate[];
    indicatorType: string;
    defaultDialogStandard?: ICreateTypeStandardScore;
    standardResuls: {
      [key: string]: number;
    };
  }>({
    area: [],
    indicators: [],
    indicatorType: '',
    standardResuls: {},
  });
  const [standardReviewed, setStandardReviewed] = useState<{
    [key: string]: {
      totalReviewed: number;
      totalAccepted: number;
      totalDenied: number;
      dataComment?: ICommentIndicator[];
    };
  } | null>(null);
  const [defaultCommentIndicator, setDefaultCommentIndicator] = useState<
    ICommentIndicator[]
  >([]);
  const [status, setStatus] = useState('');
  const [confirmSendBackReviewer, setConfirmSendBackReviewer] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [confirmApprove, setConfirmApprove] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    return () => {
      dispatch(resetCountryScoringReducer());
    };
  }, []);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getCountryScoringById(locationState.id));
    } else Utils.redirect(ROUTERS.COUNTRY_SCORING_MANAGEMENT);
  }, [locationState]);
  useEffect(() => {
    if (scoringDetail) {
      setStatus(_.get(scoringDetail, 'status'));
      setFormState({
        ...formState,
        standardResuls: _.get(scoringDetail, 'extraData.standardResuls'),
        area: _.get(scoringDetail, 'area') || [],
      });
      setStandardReviewed(_.get(scoringDetail, 'extraData.standardReviewed'));
      setRenderKey(Math.random());
    }
  }, [scoringDetail]);

  // Events
  const handleFillForm = (
    data: IIndicatorTemplate[],
    key: string,
    code: string,
    subcode: string
  ) => {
    const findAreaIndex = _.findIndex(formState.area, [
      'code',
      selectedLevel.selectedCode,
    ]);
    if (findAreaIndex !== -1) {
      const findSubIndex = _.findIndex(formState.area[findAreaIndex].subArea, [
        'code',
        subcode,
      ]);
      if (findSubIndex !== -1) {
        const findStandardIndex = _.findIndex(
          formState.area[findAreaIndex].subArea[findSubIndex].standard,
          ['code', code]
        );
        if (findStandardIndex !== -1) {
          const findTypeStandardIndex = _.findIndex(
            formState.area[findAreaIndex].subArea[findSubIndex].standard[
              findStandardIndex
            ].typeStandard,
            ['type', key]
          );
          if (findTypeStandardIndex !== -1) {
            const standardSelectedKey = `${subcode}${key}`;
            setDefaultCommentIndicator(
              standardReviewed?.[standardSelectedKey]?.dataComment || []
            );
            setSelectedLevel({
              ...selectedLevel,
              selectedSubCode: subcode,
              selectedStandardCode: code,
            });
            setFormState({
              ...formState,
              indicatorType: key,
              indicators: data,
              defaultDialogStandard:
                formState.area[findAreaIndex].subArea[findSubIndex].standard[
                  findStandardIndex
                ].typeStandard[findTypeStandardIndex],
            });
            setIsOpen(true);
          }
        }
      }
    }
  };

  const handleClose = () => {
    setDefaultCommentIndicator([]);
    setIsOpen(false);
  };

  const handleChangeStep = (code: TStepCode) => {
    setSelectedLevel({ ...selectedLevel, selectedCode: code });
  };

  const handleSendBackReviewer = () =>
    setConfirmSendBackReviewer({
      isOpen: true,
      message: `This report will be send back to reviewer.`,
      state: {
        id: '',
        status: '',
      },
    });

  const handleSubmitSendBackReviewer = () => {
    setConfirmSendBackReviewer(DEFAULT_CONFIRM);
    dispatch(unApproveScoring(locationState?.id, pagination));
  };

  const handleApprove = () =>
    setConfirmApprove({
      isOpen: true,
      message: `This report will be approve.`,
      state: {
        id: '',
        status: '',
      },
    });

  const handleSubmitApprove = () => {
    setConfirmApprove(DEFAULT_CONFIRM);
    dispatch(approveScoring(locationState?.id, pagination));
  };
  // Renders
  const _renderSteps = () => {
    return _.map(STEP_OF_SCORINGS, (step) => {
      return (
        <Step key={step.code}>
          <StepButton
            disabled={isGetLoading}
            onClick={() => handleChangeStep(step.code)}
          >
            {step.label}
          </StepButton>
        </Step>
      );
    });
  };

  const _renderStandards = (standards: IStandard[], subcode: string) => {
    // const OFFSET_SIZE = 4;
    // const resizeColumn = _.size(standards) === OFFSET_SIZE;
    return _.map(standards, (standard, index) => {
      const totalFilledLegislation =
        _.get(standardReviewed, `${standard.code}legislation`)?.totalReviewed ||
        0;
      const totalDeninedLegislation =
        _.get(standardReviewed, `${standard.code}legislation`)?.totalDenied ||
        0;
      const totalFilledPractice =
        _.get(standardReviewed, `${standard.code}practice`)?.totalReviewed || 0;
      const totalDeninedPractice =
        _.get(standardReviewed, `${standard.code}practice`)?.totalDenied || 0;
      return (
        <Grid
          item
          key={standard.code || index}
          xs={12}
          md={6}
          // sx={{
          //   position: 'relative',
          //   mr: index === 2 && resizeColumn ? '1px' : 0,
          //   right: '-1px', //grid breaks standard 3-4
          // }}
        >
          <Card>
            <CardContent sx={{ height: 120 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {standard.originalName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  pl={2}
                  sx={{ mx: 'auto', fontWeight: 400, fontSize: 12, mt: 2 }}
                >
                  ({standard.codeName})
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.legislation,
                    'legislation',
                    standard.code,
                    subcode
                  )
                }
                startIcon={
                  totalDeninedLegislation > 0 ? (
                    <WarningAmberIcon color="error" />
                  ) : null
                }
                color={
                  totalFilledLegislation === standard.legislation.length &&
                  !totalDeninedLegislation
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                legislation reviewed ({totalFilledLegislation}/
                {standard.legislation.length})
              </Button>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.practice,
                    'practice',
                    standard.code,
                    subcode
                  )
                }
                startIcon={
                  totalDeninedPractice > 0 ? (
                    <WarningAmberIcon color="error" />
                  ) : null
                }
                color={
                  totalFilledPractice === standard.practice.length &&
                  !totalDeninedPractice
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                practice reviewed ({totalFilledPractice}/
                {standard.practice.length})
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    });
  };

  const _renderSubAreas = (areas: ISubArea[]) => {
    return _.map(areas, (area) => {
      return (
        <Grid item key={area.code}>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item>
              <Typography gutterBottom variant="h5">
                {area.codeName}: {area.name}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: 13, ml: 2 }}
              >
                Principle: {area.principle}
              </Typography>
            </Grid>
            <Divider variant="middle" />
            <Grid item px={2} py={1}>
              <Grid container columnSpacing={3} rowSpacing={4}>
                {_renderStandards(area.standards, area.code)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const _renderScoringForm = () => {
    const selectedForm = _.find(SCORING_TEMPLATE, [
      'code',
      selectedLevel.selectedCode,
    ]);
    if (!selectedForm) return null;
    return (
      <Grid container direction="column">
        {_renderSubAreas(selectedForm.subAreas)}
      </Grid>
    );
  };

  const renderMain = () => {
    const findStep = _.find(STEP_OF_SCORINGS, [
      'code',
      selectedLevel.selectedCode,
    ]);
    return (
      <Grid container direction="column" rowSpacing={4} pb={2}>
        <ScoringDialog
          open={isOpen}
          dialogTitle={formState.indicatorType}
          indicators={formState.indicators}
          handleClose={() => handleClose()}
          defaultStandard={formState.defaultDialogStandard}
          defaultCommentIndicator={defaultCommentIndicator}
        />
        <Grid item xs={12}>
          <ActiveBreadCrumb screen="COUNTRY_SCORING_ADMIN" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>
              <DatePicker
                inputFormat="YYYY"
                views={['year']}
                label="Year"
                value={_.get(scoringDetail, 'year') || null}
                onChange={() => {}}
                maxDate={new Date()}
                renderInput={(params: any) => (
                  <TextField {...params} required fullWidth />
                )}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                key={`title${renderKey}`}
                fullWidth
                label="Title"
                defaultValue={_.get(scoringDetail, 'title')}
                disabled
                onChange={() => {}}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" p={2}>
            <Grid item>
              <Stepper
                nonLinear
                sx={{ width: 1 }}
                alternativeLabel
                activeStep={findStep?.step || 0}
              >
                {_renderSteps()}
              </Stepper>
            </Grid>
            <Grid item>
              {!isGetLoading ? (
                _renderScoringForm()
              ) : (
                <SkeletonLoading numberRender={3} />
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              mt: 2,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="burntSienna"
              disabled={isGetLoading}
              startIcon={<ArrowBackIcon />}
              onClick={() => Utils.redirect(ROUTERS.COUNTRY_SCORING_MANAGEMENT)}
            >
              Back to list
            </Button>
            {status === ENUMS.STATUS.WAITING_APPROVAL && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <LoadingButton
                  variant="contained"
                  color="fuzzyWuzzyBrown"
                  loading={isActionLoading || isGetLoading}
                  startIcon={<SaveAsIcon />}
                  onClick={() => handleSendBackReviewer()}
                >
                  Send back to Reviewer
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="oceanGreen"
                  loading={isActionLoading || isGetLoading}
                  startIcon={<ScheduleSendIcon />}
                  onClick={() => handleApprove()}
                >
                  Approve
                </LoadingButton>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Dialogs.Confirm
            confirm={confirmSendBackReviewer}
            onCancel={() => setConfirmSendBackReviewer(DEFAULT_CONFIRM)}
            callback={() => handleSubmitSendBackReviewer()}
          />
          <Dialogs.Confirm
            confirm={confirmApprove}
            onCancel={() => setConfirmApprove(DEFAULT_CONFIRM)}
            callback={() => handleSubmitApprove()}
          />
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default ScoringReport;
