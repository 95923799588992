import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Stack,
  Box,
  Tab,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import { TextEditor } from '@/Components/Common';
import { CommonColors } from '@/Themes';
import {
  TExecutiveSummaryKey,
  IExecutiveSummaryStructure,
} from '@/Interfaces/CountryReport.interface';

interface ISectionProps {
  payload: IExecutiveSummaryStructure;
  onChange(newPayload: IExecutiveSummaryStructure): void;
  disabled?: boolean;
}

const DEFAULT_DETAILS = {
  keyFindingsAndRecommendations: [
    {
      finding: '',
      recommendation: '',
    },
  ],

  executiveSummary: '',
};

const APExecutiveSummary: React.FC<ISectionProps> = ({
  payload,
  onChange,
  disabled,
}) => {
  const [details, setDetails] = useState<IExecutiveSummaryStructure>(
    payload || DEFAULT_DETAILS
  );

  const [tabActive, setTabActive] = useState<TExecutiveSummaryKey>(
    'keyFindingsAndRecommendations'
  );

  useEffect(() => {
    if (!_.isEmpty(payload)) setDetails(payload);
  }, [payload]);

  useEffect(() => {
    if (details) onChange(details);
  }, [details]);

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: TExecutiveSummaryKey
  ) => setTabActive(newValue);

  const handleChangeKeyFindingsAndRecommendations = (
    index: number,
    value: string,
    key: string
  ) => {
    const newKeyFindingsAndRecommendations =
      details.keyFindingsAndRecommendations &&
      _.map(details.keyFindingsAndRecommendations, (item, i: number) => {
        return index === i ? { ...item, [key]: value } : item;
      });
    if (newKeyFindingsAndRecommendations)
      setDetails({
        ...details,
        keyFindingsAndRecommendations: newKeyFindingsAndRecommendations,
      });
  };

  const onAddNewItem = () => {
    const newKeyFindingsAndRecommendations =
      details?.keyFindingsAndRecommendations && [
        ...details.keyFindingsAndRecommendations,
        { finding: '', recommendation: '' },
      ];
    if (newKeyFindingsAndRecommendations)
      setDetails({
        ...details,
        keyFindingsAndRecommendations: newKeyFindingsAndRecommendations,
      });
  };

  const onDeteleItemFindingsAndRecommendations = (index: number) => {
    const newKeyFindingsAndRecommendations =
      details.keyFindingsAndRecommendations &&
      _.filter(
        details.keyFindingsAndRecommendations,
        (_item, i: number) => index !== i
      );
    setDetails({
      ...details,
      keyFindingsAndRecommendations: newKeyFindingsAndRecommendations,
    });
  };

  const _renderKeyFindingsAndRecommendations = () => (
    <Grid container>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              >
                Key findings
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              >
                Key recommendations
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(
            details?.keyFindingsAndRecommendations,
            (item, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ with: 5, p: 0 }}>
                    <Typography variant="subtitle1">{index + 1}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      disabled={disabled}
                      value={item.finding}
                      fullWidth
                      variant="outlined"
                      rows={3}
                      multiline
                      onChange={(e) =>
                        handleChangeKeyFindingsAndRecommendations(
                          index,
                          e.target.value,
                          'finding'
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={disabled}
                      value={item.recommendation}
                      fullWidth
                      variant="outlined"
                      rows={3}
                      multiline
                      onChange={(e) =>
                        handleChangeKeyFindingsAndRecommendations(
                          index,
                          e.target.value,
                          'recommendation'
                        )
                      }
                    />
                  </TableCell>
                  {!disabled && (
                    <TableCell sx={{ width: 5, p: 0 }}>
                      <IconButton
                        onClick={() =>
                          onDeteleItemFindingsAndRecommendations(index)
                        }
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
      {!disabled && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
        >
          <Button
            sx={{
              background: CommonColors.mainColor,
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: CommonColors.mainColor,
              },
            }}
            variant="contained"
            onClick={onAddNewItem}
          >
            Add New Key
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const _renderTabs = () => (
    <Box>
      <TabPanel value="keyFindingsAndRecommendations" sx={{ p: 0, mt: 2 }}>
        {_renderKeyFindingsAndRecommendations()}
      </TabPanel>
      <TabPanel value="executiveSummary" sx={{ p: 0, mt: 2 }}>
        <TextEditor
          disabled={disabled}
          value={details.executiveSummary}
          onChange={(newValue: string) =>
            setDetails({ ...details, executiveSummary: newValue })
          }
        />
      </TabPanel>
    </Box>
  );

  const renderMain = () => (
    <TabContext value={tabActive}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleTabChange}>
          <Tab
            label="Key Findings & Recommendations"
            value="keyFindingsAndRecommendations"
          />
          <Tab label="Executive Summary" value="executiveSummary" />
        </TabList>
      </Box>
      {_renderTabs()}
    </TabContext>
  );

  return <Stack>{renderMain()}</Stack>;
};

export default APExecutiveSummary;
