import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';

import { UserScoringActions } from '@actions';
import { SCORING_TEMPLATE, STEP_OF_SCORINGS, ENUMS, ROUTERS } from '@constants';
import {
  TStepCode,
  ISubArea,
  IStandard,
  IIndicatorTemplate,
  ICreateAreaScore,
  ICreateTypeStandardScore,
  ICreateIndicatorScore,
  IUpdateScore,
  ICommentIndicator,
} from '@interfaces/CountryScoring.interface';
import ScoringDialog from './ScoringDialog';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';
import MandatoryField from '@/Components/LayoutPart/Dialogs/MandatoryField';
import { SkeletonLoading, ActiveBreadCrumb } from '@/Components/Common';

// Declare constants
const { updateUserScoring, getUserScoringById, resetUserScoringReducer } =
  UserScoringActions;

const ScoringReport: React.FC = () => {
  // Constructors
  const locationState = useLocation().state;
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'isActionLoading')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'isGetLoading')
  );
  const scoringDetail = useSelector((state: RootState) =>
    _.get(state.USER_SCORING, 'scoringDetail')
  );
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [isMessage, setIsMessage] = useState<string>('');
  const [submitForm, setSubmitForm] = useState<IUpdateScore>({
    title: '',
    status: '',
    updateIndicator: [],
    newIndicator: [],
    year: dayjs().year().toString(),
  });
  const [isOpenPopupError, setIsOpenPopupError] = useState<boolean>(false);
  const [selectedLevel, setSelectedLevel] = useState<{
    selectedCode: TStepCode;
    selectedSubCode: string;
    selectedStandardCode: string;
  }>({
    selectedCode: 'area_1',
    selectedSubCode: '',
    selectedStandardCode: '',
  });
  const [formState, setFormState] = useState<{
    area: ICreateAreaScore[];
    indicators: IIndicatorTemplate[];
    indicatorType: string;
    defaultDialogStandard?: ICreateTypeStandardScore;
    standardResuls: {
      [key: string]: number;
    };
    standardReviewed?: {
      [key: string]: {
        totalReviewed: number;
        totalAccepted: number;
        totalDenied: number;
        dataComment?: ICommentIndicator[];
      };
    };
    changedIndicator: ICreateIndicatorScore[];
  }>({
    area: [],
    indicators: [],
    indicatorType: '',
    standardResuls: {},
    changedIndicator: [],
  });

  useEffect(() => {
    return () => {
      dispatch(resetUserScoringReducer());
    };
  }, []);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getUserScoringById(locationState.id));
    } else Utils.redirect(ROUTERS.MY_SCORING);
  }, [locationState]);

  useEffect(() => {
    if (scoringDetail) {
      setSubmitForm({
        title: _.get(scoringDetail, 'title'),
        year: _.get(scoringDetail, 'year'),
        extraData: _.get(scoringDetail, 'extraData'),
        status: _.get(scoringDetail, 'status'),
        updateIndicator: [],
        newIndicator: [],
      });
      setFormState({
        ...formState,
        standardResuls: _.get(scoringDetail, 'extraData.standardResuls'),
        standardReviewed: _.get(scoringDetail, 'extraData.standardReviewed'),
        area: _.get(scoringDetail, 'area') || [],
        changedIndicator: [],
      });
      setRenderKey(Math.random());
    }
  }, [scoringDetail]);

  useEffect(() => {
    const checkProcess = Utils.generateScoringProcess(formState.standardResuls);
    const totalComplete = _.filter(checkProcess, (e) => e.isCompleted);
    if (totalComplete.length === 3) setIsAllCompleted(true);
  }, [formState]);

  // Events
  const handleFillForm = (
    data: IIndicatorTemplate[],
    key: string,
    code: string,
    subcode: string
  ) => {
    const findAreaIndex = _.findIndex(formState.area, [
      'code',
      selectedLevel.selectedCode,
    ]);
    if (findAreaIndex !== -1) {
      const findSubIndex = _.findIndex(formState.area[findAreaIndex].subArea, [
        'code',
        subcode,
      ]);
      if (findSubIndex !== -1) {
        const findStandardIndex = _.findIndex(
          formState.area[findAreaIndex].subArea[findSubIndex].standard,
          ['code', code]
        );
        if (findStandardIndex !== -1) {
          const findTypeStandardIndex = _.findIndex(
            formState.area[findAreaIndex].subArea[findSubIndex].standard[
              findStandardIndex
            ].typeStandard,
            ['type', key]
          );
          if (findTypeStandardIndex !== -1) {
            setFormState({
              ...formState,
              indicatorType: key,
              indicators: data,
              defaultDialogStandard:
                formState.area[findAreaIndex].subArea[findSubIndex].standard[
                  findStandardIndex
                ].typeStandard[findTypeStandardIndex],
            });
            setSelectedLevel({
              ...selectedLevel,
              selectedSubCode: subcode,
              selectedStandardCode: code,
            });
            setIsOpen(true);
          }
        }
      }
    }
  };

  const saveScoring = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    status: string
  ) => {
    e.preventDefault();
    const updateIndicator: ICreateIndicatorScore[] = [];
    const newIndicator: ICreateIndicatorScore[] = [];
    if (Utils.isEmptyOrSpaces(submitForm.title)) {
      setIsOpenPopupError(true);
      setIsMessage('Title cannot be empty');
    } else {
      _.map(formState.changedIndicator, (indi) => {
        if (_.has(indi, 'typeStandardId')) newIndicator.push(indi);
        else updateIndicator.push(indi);
      });
      dispatch(
        updateUserScoring(
          {
            ...submitForm,
            status,
            updateIndicator,
            newIndicator,
            extraData: {
              ...submitForm.extraData,
              standardResuls: formState.standardResuls,
            },
          },
          locationState?.id
        )
      );
    }
  };

  const handleOnchangeTitle = (e: any) => {
    setSubmitForm({ ...submitForm, title: e.target.value });
    setIsMessage('');
  };

  const handleClose = (data: ICreateIndicatorScore[]) => {
    if (data) {
      const newAreaForm = _.map(formState.area, (area) => {
        if (area.code === selectedLevel.selectedCode) {
          const { subArea } = area;
          const newSubArea = _.map(subArea, (sub) => {
            if (sub.code === selectedLevel.selectedSubCode) {
              const { standard } = sub;
              const newStandard = _.map(standard, (sta) => {
                if (sta.code === selectedLevel.selectedStandardCode) {
                  const { typeStandard } = sta;
                  const newTypeStandard = _.map(typeStandard, (typeSta) => {
                    if (typeSta.type === formState.indicatorType) {
                      return {
                        ...typeSta,
                        indicator: data,
                      };
                    }
                    return { ...typeSta };
                  });
                  return { ...sta, typeStandard: newTypeStandard };
                }
                return { ...sta };
              });
              return {
                ...sub,
                standard: newStandard,
              };
            }
            return { ...sub };
          });
          return { ...area, subArea: newSubArea };
        }
        return { ...area };
      });
      const newChangedIndicator = _.uniqBy(
        [...data, ...formState.changedIndicator],
        'code'
      );
      const standardSelectedKey = `${selectedLevel.selectedStandardCode}${formState.indicatorType}`;
      setFormState({
        ...formState,
        area: newAreaForm,
        standardResuls: {
          ...formState.standardResuls,
          [`${standardSelectedKey}`]: data.length,
        },
        changedIndicator: newChangedIndicator,
      });
    }
    setIsOpen(false);
  };

  const handleChangeStep = (code: TStepCode) => {
    setSelectedLevel({ ...selectedLevel, selectedCode: code });
  };

  // Renders
  const _renderSteps = () => {
    return _.map(STEP_OF_SCORINGS, (step) => {
      return (
        <Step key={step.code}>
          <StepButton
            disabled={isActionLoading || isGetLoading}
            onClick={() => handleChangeStep(step.code)}
          >
            {step.label}
          </StepButton>
        </Step>
      );
    });
  };

  const _renderStandards = (standards: IStandard[], subcode: string) => {
    const OFFSET_SIZE = 4;
    const resizeColumn = _.size(standards) === OFFSET_SIZE;
    return _.map(standards, (standard, index) => {
      const totalFilledLegislation =
        _.get(formState.standardResuls, `${standard.code}legislation`) || 0;
      const totalFilledPractice =
        _.get(formState.standardResuls, `${standard.code}practice`) || 0;
      const totalDeninedLegislation =
        _.get(formState.standardReviewed, `${standard.code}legislation`)
          ?.totalDenied || 0;
      const totalDeninedPractice =
        _.get(formState.standardReviewed, `${standard.code}practice`)
          ?.totalDenied || 0;
      return (
        <Grid
          item
          key={standard.code}
          xs={12}
          md={4}
          sx={{
            position: 'relative',
            mr: index === 2 && resizeColumn ? '1px' : 0,
            right: '-1px', //grid breaks standard 3-4
          }}
        >
          <Card>
            <CardContent sx={{ height: 150 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {standard.originalName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  pl={2}
                  sx={{ mx: 'auto', fontWeight: 400, fontSize: 12, mt: 2 }}
                >
                  ({standard.codeName})
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.legislation,
                    'legislation',
                    standard.code,
                    subcode
                  )
                }
                color={
                  totalFilledLegislation === standard.legislation.length
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
                startIcon={
                  totalDeninedLegislation > 0 ? (
                    <WarningAmberIcon color="error" />
                  ) : null
                }
              >
                legislation filled ({totalFilledLegislation}/
                {standard.legislation.length})
              </Button>
              <Button
                onClick={() =>
                  handleFillForm(
                    standard.practice,
                    'practice',
                    standard.code,
                    subcode
                  )
                }
                startIcon={
                  totalDeninedPractice > 0 ? (
                    <WarningAmberIcon color="error" />
                  ) : null
                }
                color={
                  totalFilledPractice === standard.practice.length
                    ? 'oceanGreen'
                    : 'fuzzyWuzzyBrown'
                }
              >
                practice filled ({totalFilledPractice}/
                {standard.practice.length})
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    });
  };

  const _renderSubAreas = (areas: ISubArea[]) => {
    return _.map(areas, (area) => {
      return (
        <Grid item key={area.code}>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item>
              <Typography gutterBottom variant="h5">
                {area.codeName}: {area.name}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: 13, ml: 2 }}
              >
                Principle: {area.principle}
              </Typography>
            </Grid>
            <Divider variant="middle" />
            <Grid item px={2} py={1}>
              <Grid container columnSpacing={3} rowSpacing={4}>
                {_renderStandards(area.standards, area.code)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };
  const _renderScoringForm = () => {
    const selectedForm = _.find(SCORING_TEMPLATE, [
      'code',
      selectedLevel.selectedCode,
    ]);
    if (!selectedForm) return null;
    return (
      <Grid container direction="column">
        {_renderSubAreas(selectedForm.subAreas)}
      </Grid>
    );
  };

  const renderMain = () => {
    const findStep = _.find(STEP_OF_SCORINGS, [
      'code',
      selectedLevel.selectedCode,
    ]);
    const isShowButtons =
      submitForm.status === ENUMS.STATUS.EDITING ||
      submitForm.status === ENUMS.STATUS.DRAFT;
    return (
      <Box>
        <MandatoryField
          messages={[isMessage]}
          isOpen={isOpenPopupError}
          onCancel={() => setIsOpenPopupError(false)}
        />
        <ScoringDialog
          open={isOpen}
          dialogTitle={formState.indicatorType}
          indicators={formState.indicators}
          handleClose={handleClose}
          defaultStandard={formState.defaultDialogStandard}
          statusScoring={submitForm.status}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActiveBreadCrumb screen="USER_SCORING" />
          </Grid>
          <Grid item xs={6} md={2}>
            <DatePicker
              inputFormat="YYYY"
              views={['year']}
              label="Year"
              disabled
              value={submitForm.year}
              onChange={() => {}}
              maxDate={new Date()}
              renderInput={(params: any) => (
                <TextField {...params} sx={{ width: 1 }} required />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              disabled={
                submitForm?.status === ENUMS.STATUS.REVIEW ||
                submitForm?.status === ENUMS.STATUS.APPROVAL ||
                submitForm?.status === ENUMS.STATUS.TERMINATED
              }
              key={`title${renderKey}`}
              fullWidth
              label="Title"
              required
              defaultValue={submitForm.title}
              error={isMessage?.length > 0}
              helperText={isMessage}
              onChange={(e) => {
                setIsMessage('');
                handleOnchangeTitle(e);
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" p={2}>
          <Grid item>
            <Stepper
              nonLinear
              sx={{ width: 1 }}
              alternativeLabel
              activeStep={findStep?.step || 0}
            >
              {_renderSteps()}
            </Stepper>
          </Grid>
          <Grid item>
            {!isGetLoading ? (
              _renderScoringForm()
            ) : (
              <SkeletonLoading numberRender={3} />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="end">
          <Grid item mr="auto">
            <Button
              variant="contained"
              color="burntSienna"
              disabled={isActionLoading || isGetLoading}
              startIcon={<ArrowBackIcon />}
              onClick={() => Utils.redirect(ROUTERS.MY_SCORING)}
            >
              Back to My Scoring
            </Button>
          </Grid>
          {isShowButtons && (
            <Grid item>
              <LoadingButton
                variant="contained"
                color="info"
                loading={isActionLoading || isGetLoading}
                startIcon={<SaveAsIcon />}
                onClick={(e) =>
                  saveScoring(
                    e,
                    submitForm.status === ENUMS.STATUS.EDITING
                      ? ENUMS.STATUS.EDITING
                      : ENUMS.STATUS.DRAFT
                  )
                }
                disabled={
                  (_.isEmpty(formState.changedIndicator) ||
                    submitForm.status === ENUMS.STATUS.REVIEW) &&
                  submitForm.title === _.get(scoringDetail, 'title')
                }
              >
                {submitForm.status === ENUMS.STATUS.EDITING
                  ? 'Save editing'
                  : 'Save as Draft'}
              </LoadingButton>
            </Grid>
          )}
          {isShowButtons && (
            <Grid item>
              <LoadingButton
                variant="contained"
                color="oceanGreen"
                loading={isActionLoading || isGetLoading}
                disabled={
                  (!isAllCompleted &&
                    submitForm.status === ENUMS.STATUS.DRAFT) ||
                  submitForm.status === ENUMS.STATUS.REVIEW
                }
                startIcon={<ScheduleSendIcon />}
                onClick={(e) => saveScoring(e, ENUMS.STATUS.REVIEW)}
              >
                Send to Reviewer
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default ScoringReport;
