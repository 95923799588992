import { useMemo, useState } from 'react';

import {
  Divider,
  Grid,
  TextField,
  Button,
  SxProps,
  Theme,
  MenuItem,
  Box,
  Chip,
  Typography,
} from '@mui/material';

import DefaultLayout from '@/Components/DefaultLayout';

import { LIST, ROUTERS } from '@/Constants';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';
import {
  ActiveBreadCrumb,
  CountryAsyncDropdown,
  TextEditor,
} from '@/Components/Common';
import { NewsManagementActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { ICreatePost } from '@/Interfaces/Post.interface';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const styleSheet: SxProps<Theme> = {
  '& .ql-editor': {
    border: '1px solid #f1f1f1',
    padding: '20px',
    maxHeight: '500px',
    minHeight: '350px',
  },
};

const DEFAULT_NEWS_MANAGEMENT: ICreatePost = {
  title: '',
  content: '',
  category: 'Uncategorized',
  publishAt: '',
  source: '',
  tag: '',
  country: '',
};

const { createNewNews } = NewsManagementActions;
const { DROPDOWN_LIST } = LIST;

const NewPost = () => {
  const dispatch = useTypedDispatch();
  const [isMessage, setIsMessage] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [tagMessage, setTagMessage] = useState<string>('');
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'isActionLoading')
  );
  const [newsData, setNewsData] = useState<ICreatePost>(
    DEFAULT_NEWS_MANAGEMENT
  );

  const handleOnChange = (key: string, value: string) => {
    setNewsData({ ...newsData, [key]: value });
    setIsMessage('');
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const valueInput = inputValue.replaceAll(';', '').trim();
      if (valueInput) {
        setSelectedItem([...selectedItem, valueInput]);
        setInputValue('');
        if (tagMessage) setTagMessage('');
      }
    }
    if (event.key === 'Backspace' && !inputValue) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  };

  const handleDeleteTag = (index: number) => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(index, 1);
    setSelectedItem(newSelectedItem);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (_.isEmpty(selectedItem)) {
      setTagMessage(
        'Please add tag to the new by enter text and pressing the enter key'
      );
    } else {
      const tagResolve = selectedItem.join(';');
      if (_.trim(newsData?.content)) {
        if (tagResolve) formData.append('tag', _.trim(tagResolve));
        if (newsData.category) formData.append('category', newsData.category);
        if (_.trim(newsData?.content))
          formData.append('content', _.trim(newsData?.content));
        if (newsData.source) formData.append('source', newsData.source);
        if (newsData.country) formData.append('country', newsData.country);
        if (newsData.title) formData.append('title', newsData.title);
        dispatch(createNewNews(formData));
      } else setIsMessage('Content cannot be empty!');
    }
  };

  const handleClear = () => {
    setNewsData(DEFAULT_NEWS_MANAGEMENT);
    setSelectedItem([]);
    setIsMessage('');
  };

  const _renderTitle = () => {
    return (
      <Grid item sx={{ mb: 3 }} xs={12}>
        <ActiveBreadCrumb screen="NEWS_MANAGEMENT" />
        <Divider sx={{ background: CommonColors.mainColor }} />
      </Grid>
    );
  };

  const _renderCategory = useMemo(() => {
    return (
      <TextField
        select
        fullWidth
        required
        label="Category"
        value={newsData?.category || 'Uncategorized'}
        disabled={isActionLoading}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange('category', e.target.value)
        }
      >
        {_.map(DROPDOWN_LIST.NEWS_CATEGORY_LIST, (category, index) => {
          return (
            <MenuItem key={index} value={category.value}>
              <Box component="div" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                {category.label}
              </Box>
            </MenuItem>
          );
        })}
      </TextField>
    );
  }, [newsData]);

  const _renderBody = () => {
    return (
      <Grid item xs={12}>
        <Grid container sx={styleSheet}>
          <Grid item mb={8} xs={12}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={newsData.title}
                  required
                  fullWidth
                  label="Title"
                  name="title"
                  onChange={(e) => handleOnChange('title', e.target.value)}
                  disabled={isActionLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {_renderCategory}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={newsData.source}
                      required
                      fullWidth
                      label="Source"
                      name="source"
                      disabled={isActionLoading}
                      onChange={(e) => handleOnChange('source', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={inputValue}
                      error={!!tagMessage}
                      helperText={
                        tagMessage ||
                        'Add tag to the new by enter text and pressing the enter key'
                      }
                      sx={{
                        '& .MuiInputBase-fullWidth.MuiInputBase-formControl': {
                          display: 'flex',
                          flexWrap: 'wrap',
                        },
                        input: {
                          flex: 1,
                        },
                      }}
                      InputProps={{
                        startAdornment:
                          selectedItem &&
                          selectedItem.map(
                            (item: string, indexSelect: number) => (
                              <Chip
                                key={item + indexSelect}
                                sx={{ mr: 1, my: 1 }}
                                label={item}
                                onDelete={() => handleDeleteTag(indexSelect)}
                              />
                            )
                          ),
                      }}
                      fullWidth
                      label="Tag *"
                      name="tag"
                      disabled={isActionLoading}
                      onKeyDown={handleKeyDown}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                        setTagMessage('');
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CountryAsyncDropdown
                      getForNews
                      defaultValue={newsData.country}
                      label="Countries"
                      onChange={(ids) => {
                        handleOnChange('country', ids);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextEditor
                  message={isMessage}
                  value={newsData.content}
                  disabled={isActionLoading}
                  onChange={(newContent: string) =>
                    handleOnChange('content', newContent)
                  }
                />
                {isMessage && (
                  <Typography ml={2} fontSize="12px" color="error">
                    {isMessage}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} mt={2} justifyContent="end">
              <Grid item>
                <Button
                  variant="contained"
                  color="fuzzyWuzzyBrown"
                  onClick={() => Utils.redirect(ROUTERS.NEWS_MANAGEMENT)}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="bismark"
                  onClick={() => handleClear()}
                  disabled={isActionLoading}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="oceanGreen"
                  loading={isActionLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          {_renderTitle()}
          <Grid item xs={12}>
            <Grid container>{_renderBody()}</Grid>
          </Grid>
        </Grid>
      </form>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default NewPost;
