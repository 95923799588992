import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { ICreateGuideline } from '@interfaces/PageManagement.interface';

// SINGLE ACTIONS
const setGetGuidelinesLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_GUIDELINES_GET_LOADING,
    payload,
  };
};

const setGuidelinesActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_GUIDELINES_ACTION_LOADING,
    payload,
  };
};

const resetGuidelinesReducer = () => {
  return {
    type: ACTION_TYPES.RESET_GUIDELINES_REDUCER,
  };
};

// ASYNC ACTIONS
const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_GUIDELINES_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_GUIDELINES_FAILURE,
  };
};

const getGuidelines = () => {
  return async (dispatch: any) => {
    dispatch(setGetGuidelinesLoading(true));
    await API.getGuideLine()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else dispatch(getSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_GUIDELINES_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_GUIDELINES_FAILURE,
  };
};

const createGuidelines = (payload: ICreateGuideline) => {
  return async (dispatch: any) => {
    dispatch(setGuidelinesActionLoading(true));
    await API.createGuideLine(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else dispatch(createSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

export default {
  getGuidelines,
  resetGuidelinesReducer,
  createGuidelines,
};
