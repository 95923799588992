import { Outlet } from 'react-router-dom';
import { ROUTERS } from '@constants';

// Protected Route
import ProtectedRoute from './Protected.router';
import { ReviewerContainer } from '@containers';

const ReviewerRouters = {
  element: <Outlet />,
  path: ROUTERS.REVIEWER_DASHBOARD,
  children: [
    {
      path: `${ROUTERS.REVIEWER_DASHBOARD}`,
      element: (
        <ProtectedRoute location={ROUTERS.REVIEWER_DASHBOARD}>
          <ReviewerContainer.ReviewerDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.ACCOUNT_REVIEWER}`,
      element: (
        <ProtectedRoute location={ROUTERS.ACCOUNT_REVIEWER}>
          <ReviewerContainer.ReviewerAccount />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.REVIEW_SCORING}`,
      element: (
        <ProtectedRoute location={ROUTERS.REVIEW_SCORING}>
          <ReviewerContainer.ReviewScoringList />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.REVIEW_SCORING_DETAIL}`,
      element: (
        <ProtectedRoute location={ROUTERS.REVIEW_SCORING_DETAIL}>
          <ReviewerContainer.ReviewScoringDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.REVIEW_COUNTRY_REPORT}`,
      element: (
        <ProtectedRoute location={ROUTERS.REVIEW_COUNTRY_REPORT}>
          <ReviewerContainer.RVCountryReport />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTERS.REVIEW_COUNTRY_REPORT_DETAIL}`,
      element: (
        <ProtectedRoute location={ROUTERS.REVIEW_COUNTRY_REPORT_DETAIL}>
          <ReviewerContainer.RVUpdateCountryReport />
        </ProtectedRoute>
      ),
    },
  ],
};

export default ReviewerRouters;
