import React from 'react';
import _ from 'lodash';
import {
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
} from '@mui/material';

import { CommonColors } from '@/Themes';
import { EVALUATION_TABLE } from '@/Constants/CountryScoring';
import { ICategoryTable } from '@/Interfaces/CountryScoring.interface';

interface SectionProps {
  onChange: (e: any) => void;
  value?: number;
  payload: any;
  disabled?: boolean;
}

const EvaluationForm: React.FC<SectionProps> = ({
  onChange,
  value,
  payload,
  disabled,
}) => {
  const _renderTableBody = () =>
    _.map(EVALUATION_TABLE, (table: ICategoryTable, index: number) => {
      const isChecked = table.score === Number(value);
      return (
        <TableRow key={index} sx={{ width: 1 }}>
          <TableCell
            sx={{
              fontWeight: 700,
              color: CommonColors.black,
              fontSize: '1em',
            }}
            component="th"
            scope="row"
          >
            <Typography
              component="h2"
              sx={{ fontWeight: 'bold', color: table?.color, mb: 0.5 }}
            >
              {table?.title}
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: '0.9em',
                whiteSpace: 'pre-wrap',
                lineHeight: '19px',
                px: 1,
              }}
            >
              {payload[table.key]}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              fontStyle: 'italic',
              color: CommonColors.black,
              textAlign: 'center',
            }}
          >
            <Checkbox
              value={table.score}
              checked={isChecked}
              disabled={disabled}
              onChange={(e) => onChange(e.target.value)}
            />
          </TableCell>
        </TableRow>
      );
    });

  return (
    <Table sx={{ width: 1 }}>
      <TableHead sx={{ background: CommonColors.black }}>
        <TableRow>
          <TableCell
            sx={{ color: CommonColors.white, textTransform: 'uppercase' }}
          >
            Category
          </TableCell>
          <TableCell
            sx={{
              color: CommonColors.white,
              textTransform: 'uppercase',
              minWidth: '100px',
              textAlign: 'center',
            }}
          >
            Evaluation
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{_renderTableBody()}</TableBody>
    </Table>
  );
};

export default EvaluationForm;
