import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import Utils from '@utils';
import API from '@/APIs';

const setNewsCarouselPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_NEWS_CAROUSEL_PAGINATION,
    payload,
  };
};

const setNewsCarouselMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_NEWS_CAROUSEL_META,
    payload,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_CAROUSEL_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_CAROUSEL_FAILURE,
  };
};

const setFetchNewsCarousel = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NEWS_CAROUSEL_FETCH_LOADING,
    payload,
  };
};

const fetchNewsCarousel = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchNewsCarousel(true));
    await API.fetchPosts(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setNewsCarouselMeta(meta));
          dispatch(setNewsCarouselPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

export default {
  fetchNewsCarousel,
};
