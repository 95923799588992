import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';

import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Grid,
  Typography,
  Button,
  Box,
} from '@mui/material';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import {
  NoDataWereFound,
  CommonStyles,
  ChartComponent,
  TreeDropdown,
} from '@/Components/Common';
import { LIST, ROUTERS } from '@/Constants';
import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { IFindingStructure } from '@/Interfaces/CountryReport.interface';
const { REGIONAL_REPORT_USER_LIST } = LIST.DROPDOWN_LIST;

interface ISectionProps {
  payload: IFindingStructure;
  year: any;
  country: string;
  code: any;
}
// { area: string; subArea: string; standard: string }

const Finding: React.FC<ISectionProps> = ({ payload, year, country, code }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [areaSelected, setAreaSelected] = useState<string>('area_1');
  const [dataFindingBased, setDataFindingBased] = useState<string | string[]>();
  const [openItems, setOpenItems] = useState<string[]>(['area_1']);
  const [selectState, setSelectState] = useState<string>(
    'area_1_sub_area_1_1_standard_1'
  );
  const [openItemsStandards, setOpenItemsStandards] = useState<string[]>([
    'area_1_sub_area_1_1',
  ]);
  const [openDialogimageChart, setOpenDialogimageChart] =
    useState<boolean>(false);
  const [subAreas, setSubareas] = useState<string>('area_1_sub_area_1_1');

  const chartForYear: any = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'chartData')
  );

  const [standardIndex, setStandardIndex] = useState<number>(0);
  //set default open fiding dropdown
  useEffect(() => {
    if (!_.isEmpty(code) && !_.isEmpty(payload)) {
      setOpenItems([code?.area]);
      setOpenItemsStandards([code?.subArea]);
      setAreaSelected(code?.area || '');
      setSubareas(code?.subArea || '');
      setSelectState(code?.standard || '');
      setDataFindingBased(
        payload?.[code?.area]?.[code?.subArea]?.[code?.standard]
      );
      setStandardIndex(
        _.findIndex(
          _.keys(payload?.[code?.area]?.[code?.subArea]),
          (standard) => standard === code?.standard
        )
      );
    } else
      setDataFindingBased(
        payload?.area_1?.area_1_sub_area_1_1?.area_1_sub_area_1_1_standard_1
      );
  }, [code, payload]);
  //handle move to top
  useEffect(() => {
    if (selectState !== 'area_1_sub_area_1_1_standard_1') scrollToTop();
  }, [selectState]);

  const scrollToTop = useCallback(
    () =>
      (
        window.document.querySelector('.tab-container') as HTMLElement
      )?.scrollIntoView({
        behavior: 'smooth',
      }),
    [selectState]
  );

  const handleOnClickSub = (_area: string, _sub: string, _standard: string) => {
    if (!_.isEmpty(payload)) {
      const areaGet = _.get(payload?.[_area as any]?.[_sub as any], _standard);
      setDataFindingBased(areaGet);
    }
    setSelectState(_standard);
    setAreaSelected(_area);
    setSubareas(_sub);
  };

  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const handleOpenItemsStandards = (value: string) => {
    let currentItems = [...openItemsStandards];
    if (_.includes(openItemsStandards, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItemsStandards(currentItems);
  };

  const handleChangeIndex = (index: number) => setStandardIndex(index);

  const _renderChart = (standardCode?: string) => {
    if (!_.isEmpty(areaSelected) && !chartForYear && !selectState) return null;
    const getChart: any = Utils.generateStandardCountryReportBarChart(
      areaSelected,
      standardCode || selectState || '',
      chartForYear
    );
    return (
      <Box component="div" width={{ sm: 1, md: 1 }}>
        <ChartComponent.BarChart
          title=""
          verticalText=""
          horizontalText=""
          data={getChart?.chartData}
          axis="x"
          isShowLegend={false}
        />
        {openDialogimageChart && <ChartComponent.ChartScoring />}
      </Box>
    );
  };

  const _renderContent = (area: string, subArea: string, standard: string) => {
    if (_.isEmpty(area) || _.isEmpty(subArea) || _.isEmpty(standard)) return;
    const indicatorColor = CommonColors.mainColor;
    const areaLabel = REGIONAL_REPORT_USER_LIST.filter(
      (item: any) => item.value === area
    );
    const subAreaLabel = areaLabel[0]?.subAreas?.filter(
      (item: any) => item.value === subArea
    );

    const standardLabel = subAreaLabel[0]?.standards.filter(
      (item: any) => item.value === standard
    );

    return (
      <Box minHeight={500} sx={{ width: 1 }}>
        <Grid container sx={{ p: 2, position: 'relative' }}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Box display="flex">
              <AcUnitIcon
                sx={{
                  color: indicatorColor,
                  fontSize: 14,
                  mt: 1,
                }}
              />
              <Typography ml={1} variant="h6">
                {standardLabel?.[0]?.label}
              </Typography>
            </Box>
            {!_.isEmpty(chartForYear) && !openDialogimageChart && (
              <Box>
                <Button
                  href={`${ROUTERS.MY_REPORT_CHART}?year=${year}&countryId=${country}&code=${areaSelected}&subCode=${subAreas}&standCode=${selectState}`}
                  target="_blank"
                >
                  <OpenInNewIcon />
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            {!_.isEmpty(chartForYear) && !openDialogimageChart && (
              <Box width={{ sm: 350, md: 430 }} sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    float: 'left',
                    width: 1,
                    mr: 2,
                  }}
                >
                  {_renderChart()}
                </Box>
                <Button
                  sx={{ position: 'absolute', right: 0 }}
                  onClick={() => setOpenDialogimageChart(true)}
                >
                  <ZoomOutMapIcon />
                </Button>
              </Box>
            )}
            {!openDialogimageChart && (
              <Box
                sx={{
                  fontSize: 16,
                  textAlign: 'justify',
                  mt: 4,
                  wordWrap: 'break-word',
                  img: {
                    maxWidth: '100%',
                  },
                }}
                dangerouslySetInnerHTML={Utils.createMarkup(dataFindingBased)}
              />
            )}
          </Grid>
          <Grid item mb={4} sx={{ background: 'while', width: 1 }}>
            {openDialogimageChart && (
              <Box
                sx={{
                  position: 'relative',
                  top: 0,
                  right: 0,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      background: 'white',
                      position: 'absolute',
                      right: 0,
                    }}
                  >
                    <Button
                      href={`${ROUTERS.MY_REPORT_CHART}?year=${year}&countryId=${country}&code=${areaSelected}&subCode=${subAreas}&standCode=${selectState}`}
                      target="_blank"
                    >
                      Open in new tab
                    </Button>
                    <Button onClick={() => setOpenDialogimageChart(false)}>
                      <ZoomInMapIcon />
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ background: 'white', width: 1 }}>
                  {_renderChart()}
                </Box>
                <Box
                  sx={{
                    fontSize: 16,
                    textAlign: 'justify',
                    mt: 4,
                    img: {
                      maxWidth: '100%',
                    },
                  }}
                  dangerouslySetInnerHTML={Utils.createMarkup(dataFindingBased)}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const _renderFindings = () => {
    return (
      <Grid container>
        <Grid item xs={4}>
          {_.map(REGIONAL_REPORT_USER_LIST, (area, index) => {
            const isOpenSub = _.includes(openItems, area.value);
            return (
              <Box key={index}>
                <ListItemButton
                  onClick={() => {
                    if (area) handleOpenItems(area.value);
                  }}
                >
                  <ListItemText primary={area.label} />
                  {isOpenSub ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={isOpenSub} timeout="auto" unmountOnExit>
                  <List>
                    {_.map(area.subAreas, (sub, key) => {
                      const isOpenStandards = _.includes(
                        openItemsStandards,
                        sub.value
                      );
                      return (
                        <Box key={key}>
                          <ListItemButton
                            key={`${key}${sub.label}`}
                            sx={{ pl: 4, ...CommonStyles.hoverBorderEffect }}
                            onClick={() => handleOpenItemsStandards(sub.value)}
                          >
                            <Box>
                              {isOpenStandards ? (
                                <RemoveIcon sx={{ fontSize: 12, mr: 1 }} />
                              ) : (
                                <AddIcon sx={{ fontSize: 12, mr: 1 }} />
                              )}
                            </Box>

                            <ListItemText primary={sub.label} />
                          </ListItemButton>
                          <Collapse
                            in={isOpenStandards}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List>
                              {_.map(sub.standards, (listItem, listItemKey) => {
                                const selected = listItem.value === selectState;
                                return (
                                  <Box ml={2} key={listItemKey}>
                                    <ListItemButton
                                      key={`${listItem}${listItem.label}`}
                                      sx={{
                                        pl: 4,
                                        ...CommonStyles.hoverBorderEffect,
                                      }}
                                      selected={selected}
                                      onClick={() =>
                                        handleOnClickSub(
                                          area.value as string,
                                          sub.value as string,
                                          listItem.value as string
                                        )
                                      }
                                    >
                                      <ListItemText primary={listItem.label} />
                                    </ListItemButton>
                                  </Box>
                                );
                              })}
                            </List>
                          </Collapse>
                        </Box>
                      );
                    })}
                  </List>
                </Collapse>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={8}>
          {!_.isEmpty(payload) ? (
            <Grid container spacing={2} p={2}>
              {_renderContent(areaSelected, subAreas, selectState)}
            </Grid>
          ) : (
            <NoDataWereFound height={300} />
          )}
        </Grid>
      </Grid>
    );
  };

  const _renderContentOnMobile = () => {
    const areaLabel = _.filter(
      REGIONAL_REPORT_USER_LIST,
      (item: any) => item?.value === areaSelected
    );
    const subAreaLabel = _.filter(
      areaLabel?.[0]?.subAreas,
      (item: any) => item?.value === subAreas
    );

    return (
      <Grid item xs={12} sx={{ mt: 2 }}>
        <TreeDropdown
          disabled={!payload}
          listOf="COUNTRY_REPORT"
          helperText="Choose a sub area for view"
          defaultValue={code?.subArea || subAreas}
          onChange={(value, parent) => {
            if (value && parent?.level1) {
              setAreaSelected(_.toString(parent?.level1));
              setSubareas(_.toString(value));
            }
          }}
        />
        {payload ? (
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Button
                startIcon={<NavigateBeforeIcon />}
                color="burntSienna"
                disabled={!standardIndex}
                onClick={() => setStandardIndex(standardIndex - 1)}
              >
                Back
              </Button>
              <Button
                endIcon={<NavigateNextIcon />}
                color="burntSienna"
                disabled={
                  standardIndex + 1 === _.size(subAreaLabel?.[0]?.standards)
                }
                onClick={() => setStandardIndex(standardIndex + 1)}
              >
                Next
              </Button>
            </Box>
            <SwipeableViews
              containerStyle={{ height: '100%' }}
              axis="x"
              index={standardIndex}
              onChangeIndex={handleChangeIndex}
            >
              {subAreaLabel?.[0]?.standards &&
                _.map(subAreaLabel?.[0]?.standards, (standard) => {
                  return (
                    <Box key={standard.value}>
                      <Typography variant="subtitle1" textAlign="center">
                        {standard.label}
                      </Typography>
                      <Box>{_renderChart(standard.value)}</Box>
                      <Box
                        sx={{
                          fontSize: 16,
                          textAlign: 'justify',
                          mt: 4,
                          wordWrap: 'break-word',
                          img: {
                            maxWidth: 1,
                          },
                        }}
                        dangerouslySetInnerHTML={Utils.createMarkup(
                          payload?.[areaSelected]?.[subAreas]?.[standard?.value]
                        )}
                      />
                    </Box>
                  );
                })}
            </SwipeableViews>
          </Box>
        ) : (
          <NoDataWereFound />
        )}
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      {matches ? (
        <Grid item xs={12}>
          <List component="div" sx={{ ml: '20px' }}>
            <List sx={{ p: 2 }}>{_renderFindings()}</List>
          </List>
        </Grid>
      ) : (
        _renderContentOnMobile()
      )}
    </Grid>
  );
};

export default Finding;
