import React, { useMemo } from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { ChartOptions, ChartData } from 'chart.js';
import { Line } from 'react-chartjs-2';

interface ISectionProps {
  data: ChartData<'line'>;
  title: string;
  verticalText?: string;
  horizontalText?: string;
  subtitle?: boolean;
  minYear?: string;
  isShowLegend?: boolean;
}

const LineChart: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const {
    data,
    title,
    verticalText,
    horizontalText,
    subtitle = true,
    minYear,
    isShowLegend = true,
  } = props;

  const options: ChartOptions<'line'> = useMemo(() => {
    return {
      responsive: true,
      interaction: {
        intersect: false,
      },
      plugins: {
        filler: {
          propagate: false,
        },
        title: {
          display: true,
          text: title,
          align: 'start',
          fullSize: true,
          font: {
            size: 19,
          },
        },
        subtitle: {
          display: subtitle,
          align: 'end',
          position: 'bottom',
          text: '(*) Click on legend to show/hide element(s)',
        },
        legend: {
          display: isShowLegend,
          position: 'top',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const currentValue = context.dataset.data[context.dataIndex];
              return `${currentValue}M`;
            },
          },
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: Boolean(horizontalText),
            text: horizontalText,
          },
          type: 'time',
          time: {
            unit: 'year',
            offsetAfterAutoskip: true,
            tooltipFormat: 'MM/YYYY',
          },
          min: minYear,
        },
        y: {
          display: true,
          title: {
            display: Boolean(verticalText),
            text: verticalText,
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: (value) => {
              return `${value}M`;
            },
          },
        },
      },
    };
  }, [props]);

  return <Line options={{ ...options }} data={data} />;
};

export default LineChart;

// cubicInterpolationMode: 'monotone',
// tension: 0.4,
// use for smooth line
