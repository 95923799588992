import { sendRequest } from '@/Configs';
import { API } from '@constants';

import { IFooterStructure } from '@/Interfaces/Footer.inteface';

const { FOOTER } = API;

export const createFooter = async (payload: IFooterStructure) => {
  return sendRequest(FOOTER.CREATE, 'POST', payload);
};

export const getFooter = async () => {
  return sendRequest(FOOTER.ROOT, 'GET');
};
