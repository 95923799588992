import React, { useCallback } from 'react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { useDropzone } from 'react-dropzone';

import {
  InputLabel,
  Box,
  Typography,
  Button,
  Stack,
  Chip,
  IconButton,
} from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CommonColors } from '@/Themes';
import CloseIcon from '@mui/icons-material/Close';
const ACCEPT_BY_TYPE = {
  IMAGE: {
    'image/png': ['.png'],
    'image/jpg': ['.jpg', '.jpeg'],
  },
  DOC: {
    'application/msword': ['.doc', '.docx'],
    'application/pdf': ['.pdf'],
  },
  VIDEO: {
    'video/mp4': ['.mp4'],
    'video/avi': ['.avi'],
  },
  ALL: {
    'image/png': ['.png'],
    'image/jpg': ['.jpg', '.jpeg'],
    'application/msword': ['.doc', '.docx'],
    'application/pdf': ['.pdf'],
  },
};

const FILE_TYPES = {
  VIDEO: ['mp4', 'm4v', 'avi'],
  IMAGE: ['jpg', 'jpeg', 'png'],
  DOC: ['doc', 'docx', 'pdf'],
  ALL: ['mp4', 'm4v', 'avi', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf'],
};

interface IUploadFileInput {
  label: string;
  onFileChange(newFiles: File[]): void;
  files: File[];
  isMultiple?: boolean;
  message?: string;
  accept?: string;
  containerSx?: object;
  required?: boolean;
  disabled?: boolean;
  type?: 'IMAGE' | 'DOC' | 'ALL' | 'VIDEO';
}

function bytesToMegaBytes(bytes: number) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

const UploadFileInput: React.FC<IUploadFileInput> = ({
  label,
  onFileChange,
  isMultiple,
  message = '',
  files = [],
  containerSx,
  required = false,
  disabled = false,
  type = 'DOC',
}) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    if (!isEmpty(acceptedFiles)) onFileChange(acceptedFiles);
  }, []);

  const getFileExt = (filename: string) => {
    const fileExt = filename.split('.').pop();
    if (includes(FILE_TYPES.VIDEO, fileExt)) return 'VIDEO';
    if (includes(FILE_TYPES.IMAGE, fileExt)) return 'IMAGE';
    if (includes(FILE_TYPES.DOC, fileExt)) return 'DOC';
    return 'IMAGE';
  };

  const fileValidator = (file: File) => {
    const fileType = getFileExt(file.name);
    const limitSize =
      fileType === 'VIDEO' || fileType === 'DOC' ? 5242880 : 2097152;
    if (file.size > limitSize) {
      return {
        code: 'size-too-large',
        message: `File is larger than ${bytesToMegaBytes(limitSize)} MB`,
      };
    }
    return null;
  };

  const generateAccept = () => {
    const resolveType: 'DOC' | 'IMAGE' | 'ALL' = type as
      | 'DOC'
      | 'IMAGE'
      | 'ALL';
    const getCurrentAccept = ACCEPT_BY_TYPE[resolveType];
    if (!isEmpty(getCurrentAccept)) return getCurrentAccept;
    return ACCEPT_BY_TYPE.ALL;
  };

  const { fileRejections, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      maxFiles: 10,
      multiple: isMultiple,
      onDrop,
      disabled,
      noClick: true,
      accept: generateAccept(),
      minSize: 0,
      validator: fileValidator,
    });

  const onRemoveFile = (index: number) => {
    const resolveNewFiles = filter(
      files,
      (_file: File, fileIndex: number) => fileIndex !== index
    );
    onFileChange(resolveNewFiles);
  };

  const renderDimension = () => {
    const resolveType: 'DOC' | 'VIDEO' | 'IMAGE' | 'ALL' = type as
      | 'DOC'
      | 'IMAGE'
      | 'VIDEO'
      | 'ALL';
    const dimensionType = FILE_TYPES[resolveType];
    const limitSize =
      resolveType === 'VIDEO' || resolveType === 'DOC' ? 5242880 : 2097152;
    return (
      <Typography
        component="span"
        fontSize="13px"
        sx={{
          display: 'block',
          ml: 0.5,
          textAlign: 'center',
        }}
      >
        (Allow file types: {dimensionType.join(', ')} - Limit{' '}
        {bytesToMegaBytes(limitSize)} MB)
      </Typography>
    );
  };

  const acceptedFileItems = files.map((file: any, index: number) => {
    return (
      <Box
        component="div"
        key={`file.path-${index}`}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography
          sx={{
            display: ' inline-block',
            width: 'max-content',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            mr: 1,
            fontSize: '14px',
          }}
        >
          {file.path || file.name}
        </Typography>
        <Chip
          variant="outlined"
          color="primary"
          sx={{ fontSize: '14px', fontWeight: '500' }}
          label={`${bytesToMegaBytes(file.size)} Mb`}
        />
        <IconButton onClick={() => onRemoveFile(index)} disabled={disabled}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  });

  const fileRejectionItems = fileRejections.map(
    (payload: { file: any; errors: any }) => (
      <Stack direction="column" key={payload.file.path}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            height: '36px',
          }}
        >
          <Typography sx={{ fontSize: 'inherit', mr: 1 }}>
            {payload.file.path}
          </Typography>
          <Chip
            variant="outlined"
            color="error"
            sx={{ fontSize: '14px', fontWeight: '500' }}
            label={`${bytesToMegaBytes(payload.file.size)} Mb`}
          />
        </Box>
        <Stack direction="column">
          {payload.errors.map((e: any) => {
            let errorMsg = e.message;
            if (e.code === 'file-invalid-type') errorMsg = 'File is wrong type';
            return (
              <Typography sx={{ fontSize: '13px', ml: 3 }} key={e.code}>
                {errorMsg}
              </Typography>
            );
          })}
        </Stack>
      </Stack>
    )
  );

  return (
    <Box
      sx={{
        borderRadius: '15px',
        borderBottom: 'none',
        alignItems: 'center',
        width: '100%',
        ...containerSx,
      }}
      component="div"
    >
      <InputLabel className="break-long-words">
        <Box
          component="div"
          sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <Typography fontSize="15px">{label}</Typography>
        </Box>
        {required && (
          <Typography component="span" color="error" ml={0.5}>
            *
          </Typography>
        )}
      </InputLabel>
      <Box
        component="div"
        sx={{
          position: 'relative',
          boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
          mt: 1,
        }}
      >
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: '5px',
            transition: 'all ease 0.25s',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: !isEmpty(message) ? 'error.main' : '#ccc',
            background: isDragActive ? CommonColors.loblolly : 'white',
            '&:hover': {
              borderColor: !isEmpty(message)
                ? 'error.main'
                : 'rgba(0, 0, 0, 0.87)',
            },
            '&:focus-within': {
              borderColor: !isEmpty(message) ? 'error.main' : '#283845',
            },
            userSelect: disabled ? 'none' : 'default',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} accept="application/pdf" />
          <Stack
            direction="column"
            sx={{ alignItems: 'center', p: 3, width: '100%' }}
          >
            <CloudUploadIcon
              fontSize="large"
              sx={{
                color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary',
              }}
            />
            <Typography
              sx={{
                fontSize: '14px',
                mt: 1,
                mb: 2,
                color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary',
              }}
            >
              {isDragActive
                ? 'Drop the files here'
                : "Drag 'n' drop some files here, or click to select files"}
              {renderDimension()}
            </Typography>
            <Button variant="contained" disabled={disabled} onClick={open}>
              Upload
            </Button>
            {!isEmpty(acceptedFileItems) && (
              <Stack direction="column" sx={{ mt: 2, width: '100%' }}>
                <Chip
                  label="Accepted"
                  variant="outlined"
                  color="success"
                  sx={{
                    width: 'max-content',
                    fontSize: '14px',
                    fontWeight: '500',
                    borderRadius: '4px',
                  }}
                />
                {acceptedFileItems}
              </Stack>
            )}
            {!isEmpty(fileRejectionItems) && (
              <Stack direction="column" sx={{ mt: 2, width: '100%' }}>
                <Chip
                  label="Rejected"
                  variant="outlined"
                  color="error"
                  sx={{
                    width: 'max-content',
                    fontSize: '14px',
                    fontWeight: '500',
                    borderRadius: '4px',
                  }}
                />
                {fileRejectionItems}
              </Stack>
            )}
          </Stack>
        </Box>
        <WarningAmberIcon
          color="error"
          fontSize="small"
          sx={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            opacity: `${!isEmpty(message) ? 1 : 0}`,
            transition: 'all ease 0.25s',
          }}
        />
        <Box
          component="label"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '13px',
            color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary',
          }}
        >
          {files.length}/{isMultiple ? 10 : 1} selected
        </Box>
      </Box>
      {message && (
        <Typography fontSize="13px" color="error">
          *{message}
        </Typography>
      )}
    </Box>
  );
};

export default UploadFileInput;
