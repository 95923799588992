import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Hidden,
} from '@mui/material';

import { BreadCrumb } from '@/Components/LayoutPart';
import DefaultLayout from '@/Components/DefaultLayout';
import { NoDataWereFound, SkeletonLoading } from '@/Components/Common';
import AboutNav from './AboutNav';

import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { MethodologyActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';

import { ICreateMethodology } from '@/Interfaces/PageManagement.interface';

import { textTable, titleTable, headerTable } from './About.styles';

const { getMethodology } = MethodologyActions;

type SubHeaderKey =
  | 'fullyDisablingEnvironment'
  | 'disablingEnvironment'
  | 'partiallyEnablingEnvironment'
  | 'enablingEnvironment'
  | 'fullyEnablingEnvironment';
interface SubKey {
  legislation: string;
  practice: string;
}

const DEFAULT_TITLE_TABLE = [
  {
    title: 'Fully disabling environment (1)',
    color: CommonColors.red,
  },
  {
    title: 'Disabling environment (2)',
    color: CommonColors.orange,
  },
  {
    title: 'Partially enabling environment (3)',
    color: CommonColors.yellow,
  },
  { title: 'Enabling environment (4)', color: CommonColors.limeGreen },
  { title: 'Fully enabling environment (5)', color: CommonColors.forestGreen },
];

const Methodology: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.METHODOLOGY, 'isGetLoading')
  );
  const methodologyContent: ICreateMethodology = useSelector(
    (state: RootState) => _.get(state.METHODOLOGY, 'methodologyContent')
  );

  const [details, setDetails] = useState<ICreateMethodology>({
    title: 'Methodology',
    content: '',
    extraData: '',
  });

  useEffect(() => {
    dispatch(getMethodology());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(methodologyContent)) {
      const { title, content, extraData } = methodologyContent;
      setDetails({
        title,
        content,
        extraData,
      });
    }
  }, [methodologyContent]);

  const _renderColumns = useMemo(() => {
    const dataTable = _.omit(details.extraData, ['note']);
    let myKey = 0;
    return _.map(dataTable, (data: SubKey, key: SubHeaderKey) => {
      myKey++;
      return (
        <TableRow key={key}>
          <TableCell
            sx={{
              fontWeight: 700,
              color: CommonColors.white,
              fontSize: '100%',
              textAlign: 'center',
              background: DEFAULT_TITLE_TABLE[myKey - 1]?.color,
              border: 'none',
            }}
            component="th"
            scope="row"
          >
            {DEFAULT_TITLE_TABLE[myKey - 1]?.title}
          </TableCell>
          <TableCell sx={textTable}>{data.legislation}</TableCell>
          <TableCell sx={textTable}>{data.practice}</TableCell>
          <TableCell
            sx={{
              fontStyle: 'italic',
              fontSize: '100%',
              color: CommonColors.black,
              textAlign: 'center',
              borderRight: '1px solid #E0E0E0',
            }}
          >
            {myKey}
          </TableCell>
        </TableRow>
      );
    });
  }, [details?.content]);

  const _renderTable = () => {
    if (!details.content) return;
    return (
      <Table>
        <TableHead sx={headerTable}>
          <TableRow sx={{ borderRight: `1px solid ${CommonColors.cosmic}` }}>
            <TableCell sx={titleTable}>Category/code</TableCell>
            <TableCell sx={titleTable}>Legislation</TableCell>
            <TableCell sx={titleTable}>Practice</TableCell>
            <TableCell sx={titleTable}>Scale</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{_renderColumns}</TableBody>
      </Table>
    );
  };

  const _renderSubTable = useMemo(() => {
    const noteList = _.values(details?.extraData?.note);
    if (!_.isEmpty(noteList) && _.isArray(noteList)) {
      return _.map(noteList, (note: string, index: number) => (
        <Typography
          component="p"
          sx={{ fontStyle: 'italic', fontSize: '14px' }}
          key={index}
        >
          {note}
        </Typography>
      ));
    }
    return null;
  }, [details?.extraData?.note]);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          img: {
            maxWidth: 1,
            objectFit: 'cover',
            wordWrap: 'break-word',
          },
          minHeight: 700,
        }}
        dangerouslySetInnerHTML={Utils.createMarkup(details.content)}
      />
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadCrumb title={details.title || 'Methodology'} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={12} lg={2}>
            <AboutNav />
          </Grid>
        </Hidden>
        {!isGetLoading ? (
          <Grid item md={12} lg={10} sx={{ width: 1 }}>
            {methodologyContent ? (
              <Grid container>
                <Grid item xs={12}>
                  {_renderContent()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: 1,
                    overflowX: {
                      xs: 'scroll',
                      sm: 'scroll',
                      md: 'hidden',
                      lg: 'hidden',
                    },
                  }}
                >
                  {_renderTable()}
                </Grid>
                <Grid item xs={12}>
                  {_renderSubTable}
                </Grid>
              </Grid>
            ) : (
              <Grid item md={12} lg={10}>
                <NoDataWereFound />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <SkeletonLoading numberRender={5} />
          </Grid>
        )}
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default Methodology;
