import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { NoDataWereFound, SkeletonLoading } from '@/Components/Common';

import { CommonColors } from '@/Themes';
import { ROUTERS } from '@constants';
import { RootState, useTypedDispatch } from '@/store';
import {
  CountryActions,
  CountryReportsActions,
  NewsUserstActions,
} from '@actions';
import Utils from '@/Utils';

// Declare actions
const TOPO_JSON =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';

const { fetchAllCountries } = CountryActions;
const { getForVisitorCountryReport, resetCountryReportsReducer } =
  CountryReportsActions;
const { fetchNewsByCountry, resetNewsByCountry } = NewsUserstActions;

const countryCoordinates = {
  ALB: [19.919025, 41.153332],
  BIH: [17.679076, 43.915886],
  'CS-KM': [20.902977, 42.602636],
  MKD: [21.745275, 41.608635],
  MNE: [19.37439, 42.708678],
  ME: [37.09024, -95.712891],
  TUR: [32.243322, 38.963745],
  SRB: [21.005859, 44.016521],
};

const convertCountryName = {
  'Bosnia and Herzegovina': 'BOSNIA & HERZEGOVINA',
  'Republic of Serbia': 'SERBIA',
};

const MapComponent: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const isMobile = useMediaQuery('(min-width:600px)');

  const countries =
    useSelector((state: RootState) => _.get(state.COUNTRY, 'countries')) || [];
  const footerContent: any = useSelector((state: RootState) =>
    _.get(state.FOOTER, 'footerContent')
  );

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isGetLoading')
  );
  const countryReportDetails = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'getForVisitor')
  );
  const newsByCountry: any[] = useSelector((state: RootState) =>
    _.get(state.NEWS_USER, 'newsByCountry')
  );

  const executive =
    _.get(countryReportDetails, 'executive') &&
    JSON.parse(_.get(countryReportDetails, 'executive'));
  const keyFindings = _.map(
    _.get(executive, 'key.keyFindingsAndRecommendations'),
    (key) => key.finding
  );
  const [content, setContent] = useState({ title: '', content: '' });
  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    id: '',
  });
  const [lastestPost, setLastestPost] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAllCountries());
    return () => {
      dispatch(resetCountryReportsReducer());
      dispatch(resetNewsByCountry());
    };
  }, []);

  useEffect(() => {
    if (footerContent) {
      const { about } = footerContent;
      setContent((about && JSON.parse(about)) || { title: '', content: '' });
    }
  }, [footerContent]);

  useEffect(() => {
    if (!_.isEmpty(newsByCountry)) {
      //This error occurs when you mutate the state directly instead of creating a new copy of the state
      const news = _.cloneDeep(newsByCountry);
      const getLastestPost = news
        .sort((a: any, b: any) => {
          const aTime = Date.parse(a.publishAt);
          const bTime = Date.parse(b.publishAt);
          return bTime - aTime;
        })
        .slice(0, 2);
      setLastestPost(getLastestPost);
    }
  }, [newsByCountry]);

  const findingCountry = (id: string): { id: string; color: string } | null => {
    let result = null;
    _.forEach(countries, (country) => {
      if (_.get(country, 'extraData.code') === id)
        result = {
          id: _.get(country, 'id'),
          color: _.get(country, 'extraData.color'),
        };
    });
    return result;
  };

  const getCoordinates = (countryCode: keyof typeof countryCoordinates) =>
    countryCoordinates[countryCode] || undefined;

  const handleCloseDialog = () => {
    setIsOpen(false);
    setSelectedCountry({
      name: '',
      id: '',
    });
    setLastestPost([]);
    dispatch(resetCountryReportsReducer());
    dispatch(resetNewsByCountry());
  };

  const _renderLeftSide = () => {
    return (
      <Grid item xs={12} md={5}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h4" gutterBottom>
              {content.title}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              sx={{
                img: {
                  maxWidth: 1,
                  objectFit: 'cover',
                },
              }}
              dangerouslySetInnerHTML={Utils.createMarkup(content.content)}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => Utils.redirect(ROUTERS.REGIONAL_REPORT)}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
            >
              Read more
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderDialogCountry = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: {
                xs: '100%',
                lg: '600px',
              }, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 700 }}>
          {_.upperCase(selectedCountry.name)}
        </DialogTitle>
        <DialogContent>
          {!isGetLoading ? (
            <Grid container spacing={2}>
              {!_.isEmpty(keyFindings) ? (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ p: 1, textTransform: 'uppercase', fontWeight: 600 }}
                  >
                    Key Findings
                  </Typography>
                  <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                    {_.map(keyFindings, (key, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          fontSize: 14,
                          px: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontWeight: 500 }}>
                          {++index}.
                        </Box>
                        <Typography
                          component="p"
                          sx={{ fontSize: 14, mb: 1, textIndent: '8px' }}
                        >
                          {key}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ) : (
                <NoDataWereFound
                  height={isMobile ? 200 : 300}
                  imageHeight={150}
                />
              )}
              {!_.isEmpty(lastestPost) && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ p: 1, textTransform: 'uppercase', fontWeight: 600 }}
                  >
                    Latest News
                  </Typography>
                  {_.map(lastestPost, (post, index) => (
                    <Tooltip title="Click for read more" key={index} arrow>
                      <Card
                        sx={{
                          cursor: 'pointer',
                          marginX: 0.6,
                          flexDirection: 'column',
                          ':hover': {
                            boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
                            transform: 'translate(0px, -3px)',
                          },
                          '.MuiCardContent-root:last-child': {
                            p: 1,
                          },
                        }}
                        onClick={() =>
                          Utils.redirect(ROUTERS.NEWS_DETAIL, {
                            id: post?.id,
                          })
                        }
                      >
                        <CardContent sx={{ display: 'flex' }}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ minWidth: 'max-content' }}
                          >
                            {post?.publishAt &&
                              Utils.getFormatDate(post.publishAt)}
                          </Typography>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ mx: 1 }}
                          />
                          <Typography
                            sx={{
                              fontSize: 15,
                              fontWeight: 'bold',
                              lineClamp: 1,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {post?.title}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Tooltip>
                  ))}
                </Grid>
              )}
            </Grid>
          ) : (
            <SkeletonLoading numberRender={3} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            startIcon={<CloseIcon />}
            color="fuzzyWuzzyBrown"
          >
            Close
          </Button>
          {!_.isEmpty(keyFindings) && (
            <Button
              onClick={() => {
                if (countryReportDetails?.country?.id)
                  Utils.redirect(ROUTERS.COUNTRY_REPORT, {
                    id: countryReportDetails?.country?.id,
                    year: new Date().getFullYear().toString(),
                  });
              }}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
            >
              Go to Country
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const _renderMap = (geographies: any[]) => {
    return _.map(geographies, (geo) => {
      const nameofCountry = geo.properties?.name || 'unknown';
      const isRequired = findingCountry(geo.id);
      const coordinates = getCoordinates(geo.id) as any;
      return (
        <React.Fragment key={geo.rsmKey}>
          <Tooltip
            title="Click for Country Info"
            arrow
            followCursor
            placement="top"
          >
            <Geography
              name={nameofCountry}
              geography={geo}
              stroke={isRequired ? CommonColors.mainColor : CommonColors.gray}
              strokeWidth={isRequired ? 2 : 0.5}
              fill={isRequired?.color || 'none'}
              onClick={() => {
                setIsOpen(true);
                if (isRequired) {
                  setSelectedCountry({
                    name: nameofCountry,
                    id: isRequired.id,
                  });
                  dispatch(
                    fetchNewsByCountry({
                      page: 0,
                      limit: 0,
                      searchBy: 'country',
                      keyword:
                        convertCountryName[
                          nameofCountry as keyof typeof convertCountryName
                        ] || nameofCountry,
                    })
                  );
                  dispatch(
                    getForVisitorCountryReport(
                      {
                        countryId: isRequired.id,
                        year: new Date().getFullYear().toString(),
                      },
                      true
                    )
                  );
                }
              }}
              style={{
                default: { outline: 'none' },
                hover: {
                  outline: 'none',
                  cursor: isRequired ? 'pointer' : 'none',
                  strokeWidth: isRequired ? 3 : 0.5,
                },
                pressed: { outline: 'none' },
              }}
            />
          </Tooltip>
          {isRequired && (
            <Marker coordinates={coordinates}>
              <text
                style={{
                  fontWeight: 'bold',
                  pointerEvents: 'none',
                  fontSize: 12,
                  textShadow: '1px 2px 3px #000000',
                }}
                textAnchor="middle"
                fill="#ffffff"
              >
                {(geo?.id === 'CS-KM' ? 'RKS' : geo?.id) || nameofCountry}
              </text>
            </Marker>
          )}
        </React.Fragment>
      );
    });
  };

  const _renderRightSide = () => {
    return (
      <Grid item xs={12} md={7} sx={{ maxHeight: 500 }} overflow="hidden">
        <ComposableMap
          projection="geoStereographic"
          projectionConfig={{
            rotate: [-25.0, -42.0, 0],
            scale: 6000,
          }}
          height={500}
        >
          <Geographies geography={TOPO_JSON}>
            {({ geographies }) => _renderMap(geographies)}
          </Geographies>
        </ComposableMap>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" mt={2}>
      <Grid item>
        <Grid container columnSpacing={6}>
          {_renderLeftSide()}
          {_renderRightSide()}
          {_renderDialogCountry()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(MapComponent);
