import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const DEFAULT_PAGINATION: IPaginationFilter = {
  page: 1,
  limit: 25,
  orderBy: 'ASC',
  keyword: '',
  status: '',
  dateFrom: '',
  dateTo: '',
};

const DEFAULT_LOADING_STATES = {
  isFetchLoading: false,
  isGetLoading: false,
  isActionLoading: false,
};

export { DEFAULT_PAGINATION, DEFAULT_LOADING_STATES };
