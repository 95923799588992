import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { ICreateMethodology } from '@interfaces/PageManagement.interface';

// SINGLE ACTIONS
const setGetMethodologyLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_METHODOLOGY_GET_LOADING,
    payload,
  };
};

const setMethodologyActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_METHODOLOGY_ACTION_LOADING,
    payload,
  };
};

const resetMethodologyReducer = () => {
  return {
    type: ACTION_TYPES.RESET_METHODOLOGY_REDUCER,
  };
};

// ASYNC ACTIONS
const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_METHODOLOGY_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_METHODOLOGY_FAILURE,
  };
};

const getMethodology = () => {
  return async (dispatch: any) => {
    dispatch(setGetMethodologyLoading(true));
    await API.getMethodology()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else dispatch(getSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_METHODOLOGY_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_METHODOLOGY_FAILURE,
  };
};

const createMethodology = (payload: ICreateMethodology) => {
  return async (dispatch: any) => {
    dispatch(setMethodologyActionLoading(true));
    await API.createMethodology(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else dispatch(createSuccess(result));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

export default {
  getMethodology,
  resetMethodologyReducer,
  createMethodology,
};
