export default {
  SAVED_SECURE_TOKEN: '@MM:secure_token',
  SAVED_SECURE_REFRESH_TOKEN: '@MM:secure_refresh_token',
  SAVED_USER_DATA: '@MM:udata',
  SAVED_FULL_NAME: '@MM:full_name',
  SAVED_REMEMBER: '@MM:remember',
  ROLE_KEY: '@MM:role_key',
  LOCALE_KEY: '@MM:locale',
  SAVED_USER_EMAIL: '@MM:uemail',
  SAVED_USER_ID: '@MM:uid',
  SAVED_COOKIE_CONSENT: '@MM:cookie_policy',
};
