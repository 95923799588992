import React from 'react';
import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { CommonStyles, TextEditor } from '@/Components/Common';
import { IFindingStructure } from '@/Interfaces/CountryReport.interface';
import { LIST } from '@/Constants';

const { REGIONAL_REPORT_LIST } = LIST.DROPDOWN_LIST;

const { REGIONAL_REPORT_LIST_DATA } = LIST.DROPDOWN_LIST;

interface IPropsSubareas {
  payload: IFindingStructure;
  disabled?: boolean;
  onUnfocus(data?: IFindingStructure): void;
  hiddenToolbar?: boolean;
}

const Subareas: React.FC<IPropsSubareas> = ({
  payload,
  onUnfocus,
  disabled,
  hiddenToolbar,
}) => {
  const [selectArea, setSelectArea] = useState<string>('area_1_sub_area_1_1');
  const [parentState, setParentState] = useState<any>('area_1');
  const [subareasForm, setSubareasForm] = useState<IFindingStructure>(payload);
  const subareasFormRef = useRef<any>(null);
  subareasFormRef.current = subareasForm;

  const [openItems, setOpenItems] = useState<string[]>(['area_1']);
  const [selectState, setSelectState] = useState<string>('area_1_sub_area_1_1');

  useEffect(() => {
    if (payload) setSubareasForm(payload);
  }, [payload]);

  useEffect(() => {
    if (subareasForm) onUnfocus(subareasForm);
  }, [subareasForm]);

  const handleOnChangeFinding = (
    key: string,
    value: string,
    parent: string,
    standard: string
  ) => {
    const data = {
      ...subareasFormRef?.current,
      [parent]: {
        ..._.get(subareasFormRef?.current, [parent]),
        [key]: {
          ..._.get(_.get(subareasFormRef?.current, [parent]), [key]),
          [standard]: value,
        },
      },
    };
    setSubareasForm(data);
  };

  const handleOnchangeTreeDropdown = (e: string, parent: string) => {
    setSelectArea(e);
    setParentState(parent);
    setSelectState(e);
  };

  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const _renderBodyFinding = (area: string, parent: any) => {
    const valueEditor: any = subareasFormRef?.current?.[parent]?.[area] || '';
    const subArea = REGIONAL_REPORT_LIST_DATA.filter(
      (item: any) => item.value === parent
    );
    const standard = subArea[0]?.subAreas.filter(
      (item: any) => item.value === area
    );
    return (
      <Grid container spacing={4}>
        {_.map(standard[0]?.standards, (item: any, index: number) => {
          return (
            <Grid item xs={12} key={index}>
              <Typography fontWeight={600} mb={1}>
                {item.label}
              </Typography>
              <TextEditor
                disabled={disabled}
                key={item.label}
                value={_.get(valueEditor, item.value)}
                onChange={(e: any) =>
                  handleOnChangeFinding(area, e, parent, item.value)
                }
                hiddenToolbar={hiddenToolbar}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const _renderFinding = () => {
    return (
      <Grid container spacing={2} mt={2}>
        <Grid item xs={4}>
          {_.map(REGIONAL_REPORT_LIST, (area, index) => {
            const isOpenSub = _.includes(openItems, area.value);
            return (
              <Box key={index}>
                <ListItemButton
                  key={index}
                  onClick={() => {
                    if (area) handleOpenItems(area.value);
                  }}
                >
                  <ListItemText primary={area.label} />
                  {isOpenSub ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={isOpenSub} timeout="auto" unmountOnExit>
                  <List>
                    {_.map(area.subAreas, (sub, key) => {
                      const selected = sub.value === selectState;
                      return (
                        <Box key={key}>
                          <ListItemButton
                            key={`${key}${sub.label}`}
                            sx={{
                              pl: 4,
                              ...CommonStyles.hoverBorderEffect,
                            }}
                            selected={selected}
                            onClick={() =>
                              handleOnchangeTreeDropdown(sub.value, area.value)
                            }
                          >
                            <ListItemText
                              primaryTypographyProps={{ fontSize: 14 }}
                              primary={sub.label}
                            />
                          </ListItemButton>
                        </Box>
                      );
                    })}
                  </List>
                </Collapse>
              </Box>
            );
          })}
        </Grid>
        {selectArea && (
          <Grid item xs={8}>
            {_renderBodyFinding(selectArea, parentState)}
          </Grid>
        )}
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {_renderFinding()}
        </Grid>
      </Grid>
    );
  };

  return <Box>{renderMain()}</Box>;
};

export default Subareas;
