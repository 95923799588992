import React, { useState, useEffect } from 'react';
import { Grid, IconButton, ClickAwayListener } from '@mui/material';

import { ChromePicker } from 'react-color';
import MainPopper from './MainPopper';

import RectangleIcon from '@mui/icons-material/Rectangle';
// Actions

interface ISectionProps {
  color?: string;
  onChangeColor(color: string): void;
}

const ColorPopper: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { color, onChangeColor } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [colorPicker, setColorPicker] = useState<string>('#000');

  useEffect(() => {
    if (color && colorPicker !== color) {
      setColorPicker(color);
    }
  }, [color]);
  // Renders

  const _renderFilterPopup = () => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false);
        }}
      >
        <Grid container m={-3}>
          <Grid item xs={12} sx={{ cursor: 'default' }}>
            <ChromePicker
              color={colorPicker}
              onChange={(e) => {
                setColorPicker(e.hex);
              }}
              onChangeComplete={(e) => {
                onChangeColor(e.hex);
              }}
              disableAlpha
            />
          </Grid>
        </Grid>
      </ClickAwayListener>
    );
  };

  return (
    <MainPopper
      content={_renderFilterPopup()}
      open={isOpen}
      placement="bottom-start"
      style={{ minWidth: 'unset' }}
      onClose={() => setIsOpen(false)}
    >
      <IconButton sx={{ ml: 1 }} onClick={() => setIsOpen(true)}>
        <RectangleIcon
          fontSize="large"
          sx={{ color: colorPicker, borderRadius: 4 }}
        />
      </IconButton>
    </MainPopper>
  );
};

export default ColorPopper;
