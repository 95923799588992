import {
  IMMToolkitTemplate,
  IMMToolkitDropdown,
} from '@interfaces/CountryScoring.interface';

const MM_TOOLKIT_FORM: IMMToolkitTemplate[] = [
  {
    code: 'area_1',
    codeName: 'Area 1',
    name: 'Basic Legal Guarantees of Freedoms',
    subAreas: [
      {
        code: 'area_1_sub_area_1_1',
        codeName: 'Sub-Area 1.1',
        name: 'FREEDOM OF ASSOCIATION',
        principle:
          'Freedom of association is guaranteed and exercised freely by everybody',
        standards: [
          {
            code: 'area_1_sub_area_1_1_standard_1',
            codeName: 'STANDARD 1.1.1',
            name: 'All individuals and legal entities can freely establish, join and participate in informal and/or registered organizations offline and online',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_1_sub_area_1_1_standard_2',
            codeName: 'STANDARD 1.1.2',
            name: 'CSOs operate freely without unwarranted state interference in their internal governance and activities',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_1_sub_area_1_1_standard_3',
            codeName: 'STANDARD 1.1.3',
            name: 'CSOs can freely seek and secure financial resources from various domestic and foreign sources to support their activities',
            legislation: '',
            practice: '',
          },
        ],
      },
      {
        code: 'area_1_sub_area_1_2',
        codeName: 'Sub-Area 1.2',
        name: 'RELATED FREEDOMS',
        principle:
          'Freedoms of assembly and expression are guaranteed to everybody',
        standards: [
          {
            code: 'area_1_sub_area_1_2_standard_1',
            codeName: 'STANDARD 1.2.1',
            name: 'CSO representatives, individually or through their organizations enjoy freedom of peaceful assembly',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_1_sub_area_1_2_standard_2',
            codeName: 'STANDARD 1.2.2',
            name: 'CSO representatives, individually or through their organizations enjoy freedom of expression',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_1_sub_area_1_2_standard_3',
            codeName: 'STANDARD 1.2.3',
            name: 'Civil society representatives, individually and through their organizations, have the rights to safely receive and impart information through any media',
            legislation: '',
            practice: '',
          },
        ],
      },
    ],
  },
  {
    code: 'area_2',
    codeName: 'Area 2',
    name: `Framework for CSOs' Financial Viability and Sustainability`,
    subAreas: [
      {
        code: 'area_2_sub_area_2_1',
        codeName: 'Sub-Area 2.1',
        name: 'TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
        principle: 'CSOs and donors enjoy favourable tax treatment',
        standards: [
          {
            code: 'area_2_sub_area_2_1_standard_1',
            codeName: 'STANDARD 2.1.1',
            name: 'Tax benefits are available on various income sources of CSOs',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_2_sub_area_2_1_standard_2',
            codeName: 'STANDARD 2.1.2',
            name: 'Incentives are provided for individual and corporate giving',
            legislation: '',
            practice: '',
          },
        ],
      },
      {
        code: 'area_2_sub_area_2_2',
        codeName: 'Sub-Area 2.2',
        name: 'STATE SUPPORT',
        principle:
          'State support to CSOs is provided in a transparent way and spent in an accountable manner',
        standards: [
          {
            code: 'area_2_sub_area_2_2_standard_1',
            codeName: 'STANDARD 2.2.1',
            name: 'Public funding is available for institutional development of CSOs, project support and cofinancing of EU and other grants.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_2_sub_area_2_2_standard_2',
            codeName: 'STANDARD 2.2.2',
            name: 'Public funding is distributed in a prescribed and transparent manner',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_2_sub_area_2_2_standard_3',
            codeName: 'STANDARD 2.2.3',
            name: 'There is a clear system of accountability, monitoring and evaluation of public funding',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_2_sub_area_2_2_standard_4',
            codeName: 'STANDARD 2.2.4',
            name: 'Non-financial support is available from the state',
            legislation: '',
            practice: '',
          },
        ],
      },
      {
        code: 'area_2_sub_area_2_3',
        codeName: 'Sub-Area 2.3',
        name: 'HUMAN RESOURCES',
        principle:
          'State policies and the legal environment stimulate and facilitate employment, volunteering and other engagements with CSOs',
        standards: [
          {
            code: 'area_2_sub_area_2_3_standard_1',
            codeName: 'STANDARD 2.3.1',
            name: 'CSOs are treated in an equal manner to other employers.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_2_sub_area_2_3_standard_2',
            codeName: 'STANDARD 2.3.2',
            name: 'There are enabling volunteering policies and laws',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_2_sub_area_2_3_standard_3',
            codeName: 'STANDARD 2.3.3',
            name: 'The educational system promotes civic engagement',
            legislation: '',
            practice: '',
          },
        ],
      },
    ],
  },
  {
    code: 'area_3',
    codeName: 'Area 3',
    name: 'Government – CSO Relationship',
    subAreas: [
      {
        code: 'area_3_sub_area_3_1',
        codeName: 'Sub-Area 3.1',
        name: 'FRAMEWORK AND PRACTICES FOR COOPERATION',
        principle:
          'There is a strategic approach to furthering state-CSO cooperation and CSO development',
        standards: [
          {
            code: 'area_3_sub_area_3_1_standard_1',
            codeName: 'STANDARD 3.1.1',
            name: 'The State recognizes, through policies and strategies, the importance of the development of and cooperation with the sector.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_3_sub_area_3_1_standard_2',
            codeName: 'STANDARD 3.1.2',
            name: 'The State recognizes, through the operation of its institutions, the importance of the development of and cooperation with the sector.',
            legislation: '',
            practice: '',
          },
        ],
      },
      {
        code: 'area_3_sub_area_3_2',
        codeName: 'Sub-Area 3.2',
        name: 'INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
        principle:
          'CSOs are effectively included in the policy and decision-making process',
        standards: [
          {
            code: 'area_3_sub_area_3_2_standard_1',
            codeName: 'STANDARD 3.2.1',
            name: 'There are standards enabling CSO involvement in decision-making, which allow for CSO input in a timely manner.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_3_sub_area_3_2_standard_2',
            codeName: 'STANDARD 3.2.2',
            name: 'All draft policies and laws are easily accessible to the public in a timely manner.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_3_sub_area_3_2_standard_3',
            codeName: 'STANDARD 3.2.3',
            name: 'CSO representatives are equal partners in discussions in cross-sector bodies and are selected through clearly defined criteria and processes.',
            legislation: '',
            practice: '',
          },
        ],
      },
      {
        code: 'area_3_sub_area_3_3',
        codeName: 'Sub-Area 3.3',
        name: 'COLLABORATION IN SERVICE PROVISION',
        principle:
          'There is a supportive environment  for CSO involvement in service provision',
        standards: [
          {
            code: 'area_3_sub_area_3_3_standard_1',
            codeName: 'STANDARD 3.3.1',
            name: 'CSOs are engaged in different services and compete for state contracts on an equal basis to other providers.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_3_sub_area_3_3_standard_2',
            codeName: 'STANDARD 3.3.2',
            name: 'The state has committed to funding services and the funding is predictable and available over a longer-term period.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_3_sub_area_3_3_standard_3',
            codeName: 'STANDARD 3.3.3',
            name: 'The state has clearly defined procedures for contracting services which allow for transparent selection of service providers, including CSOs.',
            legislation: '',
            practice: '',
          },
          {
            code: 'area_3_sub_area_3_3_standard_4',
            codeName: 'STANDARD 3.3.4',
            name: 'There is a clear system of accountability, monitoring and evaluation of service provision.',
            legislation: '',
            practice: '',
          },
        ],
      },
    ],
  },
];

const MM_TOOLKIT_OPTIONS: IMMToolkitDropdown[] = [
  {
    label: 'Area 1: Basic Legal Guarantees of Freedoms',
    value: 'area_1',
  },
  {
    label: `Area 2: Framework for CSOs' Financial Viability and Sustainability`,
    value: 'area_2',
  },
  {
    label: `Area 3: Government – CSO Relationship`,
    value: 'area_3',
  },
  {
    label: 'PDF',
    value: 'preview_pdf',
  },
];

const MM_TOOLKIT_USER_OPTIONS: IMMToolkitDropdown[] = [
  {
    label: 'Basic Legal Guarantees of Freedoms',
    value: 'area_1',
  },
  {
    label: `Framework for CSOs' Financial Viability and Sustainability`,
    value: 'area_2',
  },
  {
    label: `Government – CSO Relationship`,
    value: 'area_3',
  },
  {
    label: 'PDF',
    value: 'preview_pdf',
  },
];

export { MM_TOOLKIT_FORM, MM_TOOLKIT_OPTIONS, MM_TOOLKIT_USER_OPTIONS };
