import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
} from '@mui/material';

import PreviewIcon from '@mui/icons-material/Preview';

import { PaginationActions } from '@/Components/Common';

import { CountryReportsActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';

import Utils from '@/Utils';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ROUTERS, ENUMS } from '@constants';

import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';

const { USER_COUNTRY_REPORT_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchCountryReports } = CountryReportsActions;

const UserCountryReportTable: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();

  const countryReportTable: any =
    useSelector((state: RootState) =>
      _.get(state.COUNTRY_REPORTS, 'payload')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isFetchLoading')
  );
  const colSpan = USER_COUNTRY_REPORT_TABLE_HEADER.length + 3;

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(
      fetchCountryReports({
        ...pagination,
        limit: page,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(
      fetchCountryReports({
        ...pagination,
        limit: perPage,
      })
    );
  };

  const generateStatusLabel = (status: string) => {
    switch (status) {
      case ENUMS.STATUS.WAITING_PUBLISH:
        return ENUMS.STATUS.IN_PROGRESS;
      case ENUMS.STATUS.PUBLISH:
        return ENUMS.STATUS.PUBLISHED;
      case ENUMS.STATUS.TERMINATED:
        return ENUMS.STATUS.DELETED;
      case ENUMS.STATUS.REVIEW:
        return ENUMS.STATUS.REVIEWING;
      default:
        return status;
    }
  };

  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(USER_COUNTRY_REPORT_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`} sx={{ fontWeight: '700' }}>
            {item.label || ''}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Status
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [USER_COUNTRY_REPORT_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(countryReportTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            There is no country report to display
          </TableCell>
        </TableRow>
      );
    return _.map(countryReportTable, (item, index) => {
      return (
        <TableRow hover key={index}>
          {_.map(USER_COUNTRY_REPORT_TABLE_HEADER, (head, innerKey) => {
            return (
              <TableCell key={`row${innerKey}-${index}`}>
                {head.value === 'year'
                  ? item?.year
                  : _.get(item, head.value) ?? 'N/I'}
              </TableCell>
            );
          })}
          <TableCell
            key={`status.${index}`}
            align="center"
            sx={{ color: _.get(TableStatusColor, item?.status) }}
          >
            {_.upperCase(generateStatusLabel(item?.status))}
          </TableCell>

          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View Detail">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() =>
                  Utils.redirect(ROUTERS.UPDATE_MY_COUNTRY_REPORT, {
                    id: item?.id,
                  })
                }
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{ ...tableContainerStyles, width: { xs: '60vh', md: 1 } }}
      >
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default UserCountryReportTable;
