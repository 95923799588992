import isEmpty from 'lodash/isEmpty';
import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const wrapDropStyle = (
  message: string,
  isDragActive: boolean,
  disabled: boolean
): SxProps<Theme> => {
  return {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '5px',
    transition: 'all ease 0.25s',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: !isEmpty(message) ? 'error.main' : '#ccc',
    background: isDragActive ? CommonColors.loblolly : 'white',
    boxShadow: '0 7px 25px rgba(0,0,0,0.08)',
    cursor: disabled ? 'default' : 'pointer',
    '&:hover': {
      borderColor: !disabled
        ? !isEmpty(message)
          ? 'error.main'
          : 'rgba(0, 0, 0, 0.87)'
        : 'none',
    },
    '&:focus-within': {
      borderColor: !disabled
        ? !isEmpty(message)
          ? 'error.main'
          : '#283845'
        : 'none',
    },
    userSelect: disabled ? 'none' : 'default',
  };
};

export const typographyFileStyle: SxProps<Theme> = {
  display: ' inline-block',
  width: 'max-content',
  maxWidth: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden !important',
  textOverflow: 'ellipsis',
  mr: 1,
  fontSize: '14px',
};

export const typographyInfoStyle = (disabled: boolean): SxProps<Theme> => {
  return {
    fontSize: '14px',
    mt: 1,
    mb: 2,
    color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary',
    textAlign: 'center',
  };
};

export const warningAmberStyle = (message: string): SxProps<Theme> => {
  return {
    position: 'absolute',
    top: '10px',
    left: '10px',
    opacity: `${!isEmpty(message) ? 1 : 0}`,
    transition: 'all ease 0.25s',
  };
};
