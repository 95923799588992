/* eslint-disable indent */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  Suspense,
  lazy,
} from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  // Stack,
  TextField,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Divider,
  Grow,
  ListItem,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

import DefaultLayout from '@/Components/DefaultLayout';
import {
  DragDropUpload,
  UploadImage,
  ActiveBreadCrumb,
  SkeletonLoading,
} from '@/Components/Common';

import DialogMessages from './DialogMessages';
import { Dialogs } from '@/Components/LayoutPart';

// Lazy-loaded
const Subareas = lazy(() => import('./Subareas'));
const UPBackground = lazy(() => import('./Background'));
const UPExecutiveSummary = lazy(() => import('./ExecutiveSummary'));
const UPCSOverview = lazy(() => import('./CSOverview'));
const UPConclusionsAndRecommendations = lazy(
  () => import('./ConclusionsAndRecommendations')
);

import { CountryActions, CountryReportsActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { ENUMS, ROUTERS, LIST, DEFAULT_CONFIRM } from '@/Constants';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';

import { ICountryReportStructure } from '@/Interfaces/CountryReport.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

import { DEFAULT_COUNTRY_REPORT } from '@/Constants/CountryScoring';
import { DEFAULT_LABEL_SUB_AREA } from '@/Constants/CountryScoring/CountryReportCollection';
const { DROPDOWN_LIST } = LIST;
const { FINDING_MENU } = DROPDOWN_LIST;
const { fetchAllCountries } = CountryActions;

const {
  getCountryReportById,
  updateCountryReport,
  resetCountryReportsReducer,
} = CountryReportsActions;

type TSelectedOption =
  | 'keyFindings'
  | 'CSOverview'
  | 'background'
  | 'findings'
  | 'conclusionsAndRecommendations';

type FILE_KEY_TYPES = 'logo' | 'fileEnglish' | 'fileLocal';

const APUpdateCountryReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isActionLoading')
  );

  const countryDetails: ICountryReportStructure = useSelector(
    (state: RootState) => _.get(state.COUNTRY_REPORTS, 'details') || null
  );

  const [details, setDetails] = useState<ICountryReportStructure>(
    DEFAULT_COUNTRY_REPORT
  );
  const detailsRef = useRef<any>(null);
  detailsRef.current = details;

  const isShowButtons =
    details.status &&
    (details.status === ENUMS.STATUS.DRAFT ||
      details.status === ENUMS.STATUS.EDITING);

  const isDisabled =
    isGetLoading ||
    isActionLoading ||
    details.status === ENUMS.STATUS.REVIEW ||
    details.status === ENUMS.STATUS.PUBLISH ||
    details.status === ENUMS.STATUS.TERMINATED ||
    details.status === ENUMS.STATUS.WAITING_PUBLISH;

  const [logoState, setLogo] = useState<File[]>([]);
  const [fileEnglishState, setFileEnglish] = useState<File[]>([]);
  const [fileLocalState, setFileLocal] = useState<File[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedOption, setSelectedOption] =
    useState<TSelectedOption>('keyFindings');
  const [messages, setMessages] = useState<any[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [confirmBack, setConfirmBack] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    dispatch(fetchAllCountries());
    return () => {
      dispatch(resetCountryReportsReducer());
      setDetails(DEFAULT_COUNTRY_REPORT);
      setLogo([]);
      setFileEnglish([]);
      setFileLocal([]);
      setSelectedCountry('');
      setIsCompleted(false);
      setSelectedOption('keyFindings');
    };
  }, []);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getCountryReportById(locationState.id));
    } else Utils.redirect(ROUTERS.MY_REPORT);
  }, [locationState]);

  useEffect(() => {
    generateDetails(countryDetails);
  }, [countryDetails]);

  useEffect(() => {
    checkEmptyField();
  }, [details]);

  useEffect(() => {
    generateAssets();
  }, [details?.logo, details?.fileEnglish, details?.fileLocal]);

  const generateDetails = (detail: ICountryReportStructure) => {
    if (!_.isEmpty(detail)) {
      const {
        title,
        backgroundCountry,
        conclusionRecommendation,
        country,
        executive,
        finding,
        year,
        logo,
        fileEnglish,
        fileLocal,
        status,
        commentCountryReport,
      } = detail;

      const resolveDetails = {
        title,
        backgroundCountry: backgroundCountry || '',
        conclusionRecommendation: conclusionRecommendation || '',
        countryId: country?.id,
        executive: executive ? JSON.parse(executive) : '',
        finding: finding ? JSON.parse(finding) : '',
        year: dayjs().year(year),
        status,
        logo,
        fileEnglish,
        fileLocal,
        commentCountryReport,
      };

      setDetails(resolveDetails);
    }
  };

  const generateAssets = async () => {
    if (details?.logo) {
      const isInternal = details?.logo?.storageService === 'Internal';
      const logoSrc = isInternal
        ? await Utils.getFileFromURL(
            details?.logo.path,
            details?.logo.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            details?.logo.path,
            details?.logo.nameOriginal
          );
      if (logoSrc) setLogo([logoSrc as File]);
    }
    if (details?.fileEnglish) {
      const isInternal = details?.fileEnglish?.storageService === 'Internal';
      const fileEnglishSrc = isInternal
        ? await Utils.getFileFromURL(
            details?.fileEnglish.path,
            details?.fileEnglish.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            details?.fileEnglish.path,
            details?.fileEnglish.nameOriginal,
            'pdf'
          );
      if (fileEnglishSrc) setFileEnglish([fileEnglishSrc as File]);
    }
    if (details?.fileLocal) {
      const isInternal = details?.fileLocal?.storageService === 'Internal';
      const fileLocalSrc = isInternal
        ? await Utils.getFileFromURL(
            details?.fileLocal.path,
            details?.fileLocal.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            details?.fileLocal.path,
            details?.fileLocal.nameOriginal,
            'pdf'
          );
      if (fileLocalSrc) setFileLocal([fileLocalSrc as File]);
    }
  };

  const checkEmptyField = useCallback(() => {
    const messageErrors: any[] = [];
    const resolveDetails = _.omit(details, [
      'commentCountryReport',
      'countryId',
      'title',
    ]);
    _.forIn(resolveDetails, (value, key) => {
      if (_.isEmpty(value) && _.isString(value)) {
        messageErrors.push(`${_.startCase(key)} should not be empty!`);
      }
      //resolve executive
      if (key === 'executive') {
        for (const [_key, _value] of Object.entries(value)) {
          if (_key === 'key' && _value) {
            _.each(_value, (subVal, _subKey) => {
              //Check key findings & key recommandations empty value
              if (_.isArray(subVal)) {
                if (
                  _.isEmpty(subVal) ||
                  _.some(
                    subVal,
                    (v) => v && _.some(v, (_v) => Utils.isEmptyOrSpaces(_v))
                  )
                )
                  messageErrors.push(
                    `Key Findings & Recommendations: ${_.startCase(
                      _subKey
                    )} should not be empty!`
                  );
              } else if (_.isEmpty(subVal) && _.isString(subVal))
                messageErrors.push(`Executive Summary should not be empty!`);
            });
          }
          //Check key CSOverview empty value
          if (
            _key === 'civil' &&
            _value &&
            _.some(_value, (v) => Utils.isEmptyOrSpaces(v))
          )
            messageErrors.push(`CS Overview should not be empty!`);
        }
      }
      //resolve finding
      if (key === 'finding' && value) {
        _.forEach(
          value,
          (areas) =>
            areas &&
            _.forEach(Object.keys(areas), (subAreas) => {
              if (
                areas[subAreas] &&
                _.some(areas[subAreas], (v) => Utils.isEmptyOrSpaces(v))
              )
                messageErrors.push(
                  `Findings: ${
                    DEFAULT_LABEL_SUB_AREA[
                      subAreas as keyof typeof DEFAULT_LABEL_SUB_AREA
                    ]
                  }  should not be empty!`
                );
            })
        );
      }
    });
    if (_.size(messageErrors) === 0) setIsCompleted(true);
    else setIsCompleted(false);
    setMessages(messageErrors);
  }, [details]);

  const handleBack = () => {
    setConfirmBack({
      isOpen: true,
      message: 'Changes are not saved',
      state: {
        id: '',
        status: '',
      },
    });
  };

  const handleSubmitBack = () => {
    Utils.redirect(ROUTERS.MY_REPORT);
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const onCancelBack = () => {
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const handleChangeOption = (value: TSelectedOption) =>
    setSelectedOption(value);

  const handleFilesChange = (key: FILE_KEY_TYPES, items: any[]) => {
    if (key === 'logo') setLogo(items);
    if (key === 'fileEnglish') setFileEnglish(items);
    if (key === 'fileLocal') setFileLocal(items);
  };

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = _.toNumber(Utils.getFormatDate(newValue, 'YYYY'));
      if (!dayjs(newValue).isBefore(dayjs()) || yearSelect < 1900)
        setIsValidDateMsg('Please choose year valid');
      else {
        setIsValidDateMsg('');
        setDetails({
          ...details,
          year: dayjs(newValue).year().toString(),
        });
      }
    } else setIsValidDateMsg('Please choose year valid');
  };

  const handleSubmit = (event: any, confirmContinue?: boolean) => {
    event.preventDefault();
    const status = event.nativeEvent.submitter?.name;
    if (_.isEmpty(messages) || confirmContinue) {
      setIsOpenDialog(false);
      const formData = new FormData();
      if (details.title) formData.append('title', _.trim(details.title));
      if (details.year) {
        const resolveYear = _.toString(dayjs(details.year).year());
        formData.append('year', _.trim(resolveYear));
      }
      if (status) formData.append('status', _.trim(status));
      if (details.countryId)
        formData.append(
          'countryId',
          _.trim(selectedCountry || details.countryId)
        );
      if (details.executive)
        formData.append('executive', JSON.stringify(details.executive));
      if (details.finding)
        formData.append('finding', JSON.stringify(details.finding));
      if (details.conclusionRecommendation)
        formData.append(
          'conclusionRecommendation',
          _.trim(details.conclusionRecommendation)
        );
      if (details.backgroundCountry)
        formData.append('backgroundCountry', _.trim(details.backgroundCountry));
      if (!_.isEmpty(logoState)) formData.append('logo', logoState[0]);
      else formData.append('logoRemove', JSON.stringify(true));
      if (!_.isEmpty(fileEnglishState))
        formData.append('fileEnglish', fileEnglishState[0]);
      else formData.append('fileEnglishRemove', JSON.stringify(true));
      if (!_.isEmpty(fileLocalState))
        formData.append('fileLocal', fileLocalState[0]);
      else formData.append('fileLocalRemove', JSON.stringify(true));
      dispatch(
        updateCountryReport(
          details?.id || locationState?.id,
          formData,
          status === ENUMS.STATUS.REVIEW
        )
      );
    } else setIsOpenDialog(true);
  };

  const onCancel = () => setIsOpenDialog(false);

  const _renderCountryReportTitle = () => (
    <TextField
      label="Title"
      value={details.title}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setDetails({ ...detailsRef?.current, title: e.target.value });
      }}
      fullWidth
      disabled={isDisabled}
    />
  );

  const _renderYearOptions = () => (
    <DatePicker
      inputFormat="YYYY"
      views={['year']}
      label="Year"
      value={details.year || null}
      onChange={handleChangeYear}
      maxDate={new Date()}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={!_.isEmpty(isValidDateMsg)}
          helperText={isValidDateMsg}
          required
          fullWidth
        />
      )}
      disabled={isDisabled}
    />
  );

  const _renderOptions = () => {
    return (
      <TextField
        select
        fullWidth
        value={selectedOption}
        label="Parts"
        onChange={(e) => handleChangeOption(e.target.value as TSelectedOption)}
      >
        {_.map(FINDING_MENU, (option, index) => {
          return (
            <MenuItem key={`${option.label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const _renderAssets = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="h2" textTransform="uppercase">
            Attached files
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item lg={4} md={12} sm={12}>
              <UploadImage
                label="Partner logo"
                files={logoState}
                onFileChange={(items: File[]) =>
                  handleFilesChange('logo', items)
                }
                disabled={isDisabled}
                type="LOGO"
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6}>
              <DragDropUpload
                label="Upload report in English language"
                files={fileEnglishState}
                onFileChange={(items: File[]) =>
                  handleFilesChange('fileEnglish', items)
                }
                type="PDF"
                disabled={isDisabled}
              />
              {/* {!_.isEmpty(details.fileEnglish) && (
                <Stack alignItems="center">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    startIcon={<FileDownloadIcon />}
                    color="oceanGreen"
                    onClick={() => window.open(details.fileEnglish?.path)}
                  >
                    English language
                  </Button>
                </Stack>
              )} */}
            </Grid>
            <Grid item lg={4} md={4} sm={6}>
              <DragDropUpload
                label="Upload report in local language"
                files={fileLocalState}
                onFileChange={(items: File[]) =>
                  handleFilesChange('fileLocal', items)
                }
                type="PDF"
                disabled={isDisabled}
              />
              {/* {!_.isEmpty(details.fileLocal) && (
                <Stack alignItems="center">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    startIcon={<FileDownloadIcon />}
                    color="oceanGreen"
                    onClick={() => window.open(details.fileLocal?.path)}
                  >
                    Locallanguage
                  </Button>
                </Stack>
              )} */}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const _renderContent = (type: TSelectedOption) => {
    switch (type) {
      case 'keyFindings':
        return (
          <UPExecutiveSummary
            disabled={isDisabled}
            payload={details?.executive?.key}
            onChange={(newPayload: any) => {
              setDetails({
                ...detailsRef?.current,
                executive: {
                  ...detailsRef?.current?.executive,
                  key: newPayload,
                },
              });
            }}
          />
        );
      case 'CSOverview':
        return (
          <UPCSOverview
            disabled={isDisabled}
            payload={details?.executive?.civil}
            onChange={(newPayload: any) => {
              setDetails({
                ...detailsRef?.current,
                executive: {
                  ...detailsRef?.current?.executive,
                  civil: newPayload,
                },
              });
            }}
          />
        );
      case 'findings':
        return (
          <Subareas
            disabled={isDisabled}
            payload={details?.finding}
            onUnfocus={(newPayload) =>
              setDetails({ ...detailsRef?.current, finding: newPayload })
            }
            hiddenToolbar
          />
        );
      case 'background':
        return (
          <UPBackground
            disabled={isDisabled}
            payload={details.backgroundCountry}
            onChange={(newContent: string) =>
              setDetails({
                ...detailsRef?.current,
                backgroundCountry: newContent,
              })
            }
          />
        );
      case 'conclusionsAndRecommendations':
        return (
          <UPConclusionsAndRecommendations
            disabled={isDisabled}
            payload={details.conclusionRecommendation}
            onChange={(newContent: string) =>
              setDetails({
                ...detailsRef?.current,
                conclusionRecommendation: newContent,
              })
            }
          />
        );
      default:
        return <></>;
    }
  };

  const _renderDialogErrorMessages = () => {
    return (
      <DialogMessages
        isOpen={isOpenDialog}
        messages={messages}
        onCancel={() => onCancel()}
        onContinue={(e, confirm?: boolean) => handleSubmit(e, confirm)}
      />
    );
  };

  const _renderButtonGroup = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="burntSienna"
          onClick={() =>
            details.status === ENUMS.STATUS.DRAFT ||
            details.status === ENUMS.STATUS.EDITING
              ? handleBack()
              : Utils.redirect(ROUTERS.MY_REPORT)
          }
          disabled={isActionLoading || isGetLoading}
          startIcon={<ArrowBackIcon />}
        >
          Back to my report
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isShowButtons && (
            <LoadingButton
              type="submit"
              variant="contained"
              color="info"
              loading={isActionLoading || isGetLoading}
              startIcon={<SaveAsIcon />}
              name={details.status}
              disabled={details.status === ENUMS.STATUS.REVIEW || !details.year}
            >
              {details.status === ENUMS.STATUS.EDITING
                ? 'Save editing'
                : 'Save as Draft'}
            </LoadingButton>
          )}
          {isShowButtons && (
            <LoadingButton
              type="submit"
              variant="contained"
              color="oceanGreen"
              loading={isActionLoading || isGetLoading}
              disabled={
                details.status === ENUMS.STATUS.REVIEW ||
                details.status === ENUMS.STATUS.WAITING_PUBLISH ||
                !isCompleted
              }
              startIcon={<ScheduleSendIcon />}
              name={ENUMS.STATUS.REVIEW}
            >
              Send to reviewer
            </LoadingButton>
          )}
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box component="form" onSubmit={(e) => handleSubmit(e, undefined)}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <ActiveBreadCrumb screen="UP_COUNTRY_REPORT" />
          </Grid>
          {isGetLoading ? (
            <SkeletonLoading numberRender={5} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={9}>
                {_renderCountryReportTitle()}
              </Grid>
              <Grid item xs={3}>
                {_renderYearOptions()}
              </Grid>
              <Grid item xs={12}>
                {_renderAssets()}
              </Grid>
              <Grid item lg={3} md={6} sm={6}>
                {_renderOptions()}
              </Grid>
              <Grid item xs={12}>
                <Suspense fallback={<SkeletonLoading numberRender={5} />}>
                  {_renderContent(selectedOption)}
                </Suspense>
              </Grid>
              {!_.isEmpty(details?.commentCountryReport) && (
                <Grid item xs={12}>
                  <Divider sx={{ mt: 3, mb: 3 }} />
                  <Typography variant="overline" sx={{ fontWeight: 700 }}>
                    Reviewer Comments
                    {`(${_.size(details?.commentCountryReport)})`}
                  </Typography>
                  {_.map(
                    details?.commentCountryReport,
                    (comment: any, index: number) => {
                      return (
                        <Grow in={true} key={index} timeout={500}>
                          <ListItem sx={{ padding: 0 }}>
                            <Grid container mb={2}>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{
                                    fontSize: '0.9rem',
                                    color: CommonColors.silverChalice,
                                  }}
                                >
                                  <Grid item>
                                    <ListItemText>
                                      <Typography
                                        component="h5"
                                        sx={{ color: '#0c61ed' }}
                                      >
                                        Comment {++index}
                                      </Typography>
                                    </ListItemText>
                                  </Grid>
                                  <Grid item>
                                    <ListItemText>
                                      {comment?.createdAt &&
                                        Utils.getFormatDate(comment?.createdAt)}
                                    </ListItemText>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} ml={3}>
                                <Grid container>
                                  <Grid item xs={11}>
                                    <ListItemText>
                                      {comment?.content}
                                    </ListItemText>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Grow>
                      );
                    }
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                {_renderButtonGroup()}
              </Grid>

              <Grid item xs={12}>
                {_renderDialogErrorMessages()}
                <Dialogs.Confirm
                  confirm={confirmBack}
                  onCancel={onCancelBack}
                  callback={() => handleSubmitBack()}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };
  return <DefaultLayout content={renderMain()} />;
};

export default APUpdateCountryReport;
