import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { POST } = API;

export const createPost = async (payload: FormData) => {
  return sendRequest(POST.ROOT, 'FORM_DATA', payload);
};

export const updatePost = async (id: string, payload: FormData) => {
  return sendRequest(`${POST.ROOT}${id}`, 'PUT_FORM_DATA', payload);
};

export const fetchPosts = async (payload: IPaginationFilter) => {
  return sendRequest(POST.ROOT, 'GET', payload);
};

export const getPostById = async (id: string) => {
  return sendRequest(`${POST.GET_BY_ID}${id}`, 'GET');
};

export const activePost = async (id: string) => {
  return sendRequest(`${POST.ACTIVE}${id}`, 'PUT');
};

export const deactivePost = async (id: string) => {
  return sendRequest(`${POST.DEACTIVE}${id}`, 'PUT');
};

export const publishPost = async (id: string) => {
  return sendRequest(`${POST.PUBLISH}${id}`, 'PUT');
};
