import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Container,
  Box,
  Grid,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import DefaultLayout from '@/Components/DefaultLayout';

import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';
import { ROUTERS } from '@constants';

const { requestForgotPassword, setForgotPasswordStep, resetPassword } =
  AuthActions;

const STEP_FORM_FORGOT = [
  { id: 1, label: 'Enter Email' },
  { id: 2, label: 'Enter Code & New Password' },
];

const ForgotPassword: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const forgotStep =
    useSelector((state: RootState) => _.get(state.AUTH, 'forgotStep')) || 0;
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );
  const [forgotForm, setForgotForm] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmationNewPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState({
    newPassword: '',
    confirmationNewPassword: '',
  });

  const [isShowPassWord, setIsShowPassWord] = useState<{
    newPass: boolean;
    confirmPass: boolean;
  }>({ newPass: false, confirmPass: false });

  useEffect(() => {
    const email = searchParams.get('e') || '';
    const code = searchParams.get('c') || '';
    if (email && code) {
      setForgotForm({ ...forgotForm, email, code });
      dispatch(setForgotPasswordStep(1));
    }
    return () => {
      dispatch(setForgotPasswordStep(0));
      setIsShowPassWord({ newPass: false, confirmPass: false });
    };
  }, []);

  // Events
  const handleSendCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(requestForgotPassword({ email: forgotForm.email }));
  };

  const validPassword = () => {
    if (forgotForm.newPassword !== forgotForm.confirmationNewPassword) {
      setErrorMessage({
        ...errorMessage,
        confirmationNewPassword: 'Passwords do not match',
      });
      return false;
    } else if (forgotForm.newPassword.length < 6) {
      setErrorMessage({
        ...errorMessage,
        newPassword: 'Password must be at least 6 characters',
      });
      return false;
    }
    return true;
  };

  const handleChangePassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validPassword()) {
      dispatch(resetPassword(forgotForm));
      setForgotForm({
        email: forgotForm.email,
        code: '',
        newPassword: '',
        confirmationNewPassword: '',
      });
    }
  };

  const handleClickShowPass = (key: string, value: boolean) => {
    setIsShowPassWord({ ...isShowPassWord, [key]: value });
  };

  const _renderContents = () => {
    switch (forgotStep) {
      case 0:
        return (
          <Box component="form" onSubmit={handleSendCode}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} md={5}>
                <TextField
                  required
                  label="Email address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  fullWidth
                  disabled={isActionLoading}
                  size="medium"
                  value={forgotForm.email}
                  onChange={(e) =>
                    setForgotForm({ ...forgotForm, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={isActionLoading}
                  sx={{ px: 4, py: 1.9 }}
                >
                  Send
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Container maxWidth="xs">
            <Box component="form" onSubmit={handleChangePassword}>
              <TextField
                required
                label="Verify Code"
                name="code"
                fullWidth
                autoFocus
                sx={{ mb: 2 }}
                value={forgotForm.code}
                onChange={(e) =>
                  setForgotForm({ ...forgotForm, code: e.target.value })
                }
              />
              <TextField
                required
                label="New password"
                name="newPassword"
                error={errorMessage.newPassword.length > 0}
                helperText={errorMessage.newPassword}
                autoComplete="current-password"
                fullWidth
                type={!isShowPassWord.newPass ? 'text' : 'password'}
                disabled={isActionLoading}
                sx={{ mb: 2 }}
                value={forgotForm.newPassword}
                onChange={(e) => {
                  setForgotForm({
                    ...forgotForm,
                    newPassword: e.target.value,
                  });
                  setErrorMessage({
                    ...errorMessage,
                    newPassword: '',
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickShowPass(
                            'newPass',
                            !isShowPassWord.newPass
                          )
                        }
                      >
                        {!isShowPassWord.newPass ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                label="Confirm New Password"
                name="confirmationNewPassword"
                autoComplete="current-password"
                fullWidth
                type={!isShowPassWord.confirmPass ? 'text' : 'password'}
                error={errorMessage.confirmationNewPassword.length > 0}
                helperText={errorMessage.confirmationNewPassword}
                disabled={isActionLoading}
                value={forgotForm.confirmationNewPassword}
                onChange={(e) => {
                  setForgotForm({
                    ...forgotForm,
                    confirmationNewPassword: e.target.value,
                  });
                  setErrorMessage({
                    ...errorMessage,
                    confirmationNewPassword: '',
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickShowPass(
                            'confirmPass',
                            !isShowPassWord.confirmPass
                          )
                        }
                      >
                        {!isShowPassWord.confirmPass ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={5}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    loading={isActionLoading}
                    sx={{ px: 1, py: 1.3, mt: 4 }}
                  >
                    Reset Password
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Container>
        );
      default:
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="outlined"
              onClick={() => Utils.replace(ROUTERS.AUTH)}
              endIcon={<KeyIcon />}
            >
              Back to Login
            </Button>
          </Box>
        );
    }
  };

  const renderMain = () => (
    <Grid
      container
      justifyContent="center"
      bgcolor={CommonColors.mainBackground}
      sx={{ my: 10 }}
    >
      <Grid item xs={12} md={8} justifyContent="center">
        <Stepper activeStep={forgotStep} alternativeLabel sx={{ mb: 12 }}>
          {STEP_FORM_FORGOT.map(({ id, label }) => (
            <Step key={id}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {_renderContents()}
      </Grid>
    </Grid>
  );
  return <DefaultLayout content={renderMain()} />;
};

export default ForgotPassword;
