import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Grid, Button } from '@mui/material';

import DefaultLayout from '@/Components/DefaultLayout';
import { Popper, DataTable } from '@/Components/LayoutPart';
import { ActiveBreadCrumb } from '@/Components/Common';

import { NewsManagementActions } from '@actions';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';
import { ROUTERS } from '@/Constants';
import Utils from '@/Utils';
import { useTypedDispatch, RootState } from '@/store';

const { fetchNews, resetNewsReducer } = NewsManagementActions;

const NewsManagement: React.FC = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'pagination')
  );

  useEffect(() => {
    dispatch(fetchNews(pagination));
    return () => {
      dispatch(resetNewsReducer());
    };
  }, []);

  const _renderTopSection = () => {
    return (
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item xs={4} sx={{ position: 'relative' }}>
            <Popper.FilterPopper
              data={pagination}
              onApply={(data) => dispatch(fetchNews(data))}
              filterFor="NEWS_STATUS"
            />
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="burntSienna"
              onClick={() => Utils.redirect(ROUTERS.NEW_POST)}
            >
              New Post
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderBottomSection = () => {
    return (
      <Grid item>
        <DataTable.NewsManagementTable />
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container direction="column" rowSpacing={4}>
        <ActiveBreadCrumb screen="NEWS_MANAGEMENT" />
        {_renderTopSection()}
        {_renderBottomSection()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default NewsManagement;
