import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
// import { TransitionSlide } from '@/Components/Common';
import { Scoring } from '@/Components/LayoutPart';
import {
  IIndicatorTemplate,
  ICreateTypeStandardScore,
  ICommentIndicator,
} from '@interfaces/CountryScoring.interface';
import { ENUMS } from '@constants';
import { CommonColors } from '@/Themes';

interface SectionProps {
  open: boolean;
  handleClose(): void;
  indicators: IIndicatorTemplate[];
  dialogTitle: string;
  defaultStandard?: ICreateTypeStandardScore;
  defaultCommentIndicator?: ICommentIndicator[];
}

const ScoringDialog: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const {
    open,
    handleClose,
    indicators,
    dialogTitle,
    defaultStandard,
    defaultCommentIndicator,
  } = props;
  const [selectedIndicator, setSelectedIndicator] = useState<number>(0);
  const [callbackReview, setCallbackReview] = useState<ICommentIndicator[]>([]);
  const dialogRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!open) {
      setCallbackReview([]);
      setSelectedIndicator(0);
    } else {
      setCallbackReview(defaultCommentIndicator || []);
    }
  }, [open]);

  useEffect(() => {
    if (dialogRef?.current)
      dialogRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  }, [selectedIndicator]);

  // Events
  const onClose = async (reason?: string) => {
    if (reason === 'backdropClick') return;
    return handleClose();
  };

  const handleChangeIndex = (index: number) => {
    setSelectedIndicator(index);
  };

  // Renders
  const _renderContent = () => {
    return _.map(indicators, (indicator) => {
      const findIndicator = _.find(defaultStandard?.indicator || [], [
        'code',
        indicator.code,
      ]);
      const findReviewed = _.find(callbackReview, ['code', indicator.code]);
      const isDenied =
        _.get(
          _.isEmpty(findReviewed) ? findIndicator : findReviewed,
          'status'
        ) === ENUMS.STATUS.DENY;
      const isAccepted =
        _.get(
          _.isEmpty(findReviewed) ? findIndicator : findReviewed,
          'status'
        ) === ENUMS.STATUS.ACCEPT;
      const commentIndicator = _.get(findIndicator, 'commentIndicator') || [];
      let indicatorColor = CommonColors.mainColor;
      if (isAccepted) indicatorColor = CommonColors.oceanGreen;
      if (isDenied) indicatorColor = CommonColors.fuzzyWuzzyBrown;
      return (
        <Box key={indicator.code}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <Typography
                gutterBottom
                variant="h6"
                sx={{ color: indicatorColor }}
              >
                {indicator.codeName}: {indicator.name}
                {isDenied && (
                  <DoNotDisturbIcon color="error" fontSize="large" />
                )}
                {isAccepted && <CheckIcon color="success" fontSize="large" />}
              </Typography>
            </Grid>
            <Grid item>
              <Scoring
                indicator={indicator}
                defaultValue={findIndicator}
                callback={() => {}}
                disabled
              />
            </Grid>
            {!_.isEmpty(commentIndicator) && (
              <Grid item>
                <Typography gutterBottom variant="h5" my={1}>
                  Comments:
                </Typography>
                {_.map(commentIndicator, (comment, index) => {
                  return (
                    <Typography
                      my={1}
                      ml={2}
                      gutterBottom
                      key={_.get(comment, 'id')}
                    >
                      {index + 1}
                      {'. '}
                      {_.get(comment, 'content')}
                    </Typography>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Box>
      );
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={(_e, r) => onClose(r)}
      // TransitionComponent={TransitionSlide}
      fullScreen
    >
      <DialogTitle sx={{ textTransform: 'uppercase' }}>
        {dialogTitle}
        <Grid container>
          <Grid item xs={1}>
            <Button
              startIcon={<NavigateBeforeIcon />}
              color="burntSienna"
              disabled={!selectedIndicator}
              onClick={() => setSelectedIndicator(selectedIndicator - 1)}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={10} />
          <Grid item xs={1} textAlign="right">
            <Button
              endIcon={<NavigateNextIcon />}
              color="burntSienna"
              disabled={selectedIndicator + 1 === indicators.length}
              onClick={() => setSelectedIndicator(selectedIndicator + 1)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent ref={dialogRef}>
        <SwipeableViews
          axis="x"
          index={selectedIndicator}
          onChangeIndex={handleChangeIndex}
        >
          {_renderContent()}
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CloseIcon />}
          onClick={() => onClose()}
          variant="contained"
          color="fuzzyWuzzyBrown"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScoringDialog;
