import { sendRequest } from '@/Configs';
import { API } from '@/Constants';
import {
  INewTabFilter,
  IPaginationFilter,
} from '@/Interfaces/PaginationMeta.interface';

const { REGIONAL_REPORT } = API;

export const getRegionalReportById = async (id: string) => {
  return sendRequest(`${REGIONAL_REPORT.GET_BY_ID}${id}`, 'GET');
};

export const createRegionalReport = async (payload: FormData) => {
  return sendRequest(`${REGIONAL_REPORT.CREATE}`, 'POST', payload);
};

export const fetchRegionalReports = async (payload: IPaginationFilter) => {
  return sendRequest(REGIONAL_REPORT.ROOT, 'GET', payload);
};

export const getForVisitorRegionalReport = async (payload: {
  year: string;
}) => {
  return sendRequest(`${REGIONAL_REPORT.GET_FOR_VISITOR}`, 'GET', payload);
};

export const updateRegionaReport = async (id: string, payload: FormData) => {
  return sendRequest(
    `${REGIONAL_REPORT.UPDATE}${id}`,
    'PUT_FORM_DATA',
    payload
  );
};

export const activeRegionalReport = async (id: string) => {
  return sendRequest(`${REGIONAL_REPORT.ACTIVE}${id}`, 'PUT');
};

export const publishRegionalReport = async (id: string) => {
  return sendRequest(`${REGIONAL_REPORT.PUBLISH}${id}`, 'PUT');
};

export const inactiveRegionalReport = async (id: string) => {
  return sendRequest(`${REGIONAL_REPORT.INACTIVE}${id}`, 'PUT');
};

export const getRegionalReportFindings = async (payload: INewTabFilter) => {
  return sendRequest(`${REGIONAL_REPORT.GET_FOR_VISITOR}`, 'GET', payload);
};
