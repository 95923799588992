import React, { useEffect } from 'react';
import _ from 'lodash';

import { Grid } from '@mui/material';
import OverViewTable from '@/Components/LayoutPart/DataTable/Overview.table';
import { CountryAsyncDropdown } from '@/Components/Common';
import { RootState, useTypedDispatch } from '@/store';
import { RegionalActions } from '@/Actions';
import { IReportTemplate } from '@/Interfaces/RegionalReport';
import { useSelector } from 'react-redux';

const { saveCountryFullOptions, saveCountryOptions } = RegionalActions;

interface IPropsOverview {
  onUnfocus(data: IReportTemplate[]): void;
}

const Overview: React.FC<IPropsOverview> = ({ onUnfocus }) => {
  const dispatch = useTypedDispatch();
  const countryFullOptions: string[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultCountryFullOptions')
  );
  const selectedCountry: string[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultCountryOptions')
  );
  const overviewForm: IReportTemplate[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultOverview')
  );
  const allCountries: IReportTemplate[] = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'countries')
  );

  useEffect(() => {
    onUnfocus(overviewForm);
  }, [overviewForm]);

  useEffect(() => {
    if (_.isEmpty(countryFullOptions) && !_.isEmpty(selectedCountry)) {
      const results: any = [];
      _.each(allCountries, (country) => {
        if (_.includes(selectedCountry, _.get(country, 'id')))
          results.push({
            label: _.get(country, 'name'),
            value: _.get(country, 'id'),
            code: _.get(country, 'extraData.code'),
          });
      });
      if (!_.isEmpty(results)) dispatch(saveCountryFullOptions(results));
    }
  }, [countryFullOptions, selectedCountry]);

  const _renderFilter = () => {
    return (
      <Grid item xs={12}>
        <CountryAsyncDropdown
          multiples
          defaultValue={selectedCountry}
          label="Countries"
          onChange={(ids) => {
            dispatch(saveCountryOptions(ids));
          }}
          onChangeFullOptions={(e) => dispatch(saveCountryFullOptions(e))}
        />
      </Grid>
    );
  };

  const _renderTable = () => {
    return (
      <Grid item xs={12} sx={{ width: 100 }}>
        <Grid container>
          <Grid item xs={12}>
            <OverViewTable />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container rowSpacing={2}>
      {_renderFilter()}
      {_renderTable()}
    </Grid>
  );
};

export default Overview;
