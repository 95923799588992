import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { useDropzone } from 'react-dropzone';

import {
  InputLabel,
  Box,
  Typography,
  Stack,
  Chip,
  IconButton,
} from '@mui/material';
import {
  typographyFileStyle,
  typographyInfoStyle,
  warningAmberStyle,
  wrapDropStyle,
} from './DragDropUpload.style';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CommonColors } from '@/Themes';
import CloseIcon from '@mui/icons-material/Close';

const ACCEPT_BY_TYPE = {
  IMAGE: {
    'image/png': ['.png'],
    'image/jpg': ['.jpg', '.jpeg'],
  },
  DOC: {
    'application/msword': ['.doc', '.docx'],
    'application/pdf': ['.pdf'],
  },
  VIDEO: {
    'video/mp4': ['.mp4'],
    'video/avi': ['.avi'],
  },
  ALL: {
    'image/png': ['.png'],
    'image/jpg': ['.jpg', '.jpeg'],
    'video/mp4': ['.mp4'],
    'video/avi': ['.avi'],
    'application/msword': ['.doc', '.docx'],
    'application/pdf': ['.pdf'],
  },
  PDF: {
    'application/pdf': ['.pdf'],
  },
};

const FILE_TYPES = {
  VIDEO: ['mp4', 'm4v', 'avi'],
  IMAGE: ['jpg', 'jpeg', 'png'],
  DOC: ['doc', 'docx', 'pdf'],
  ALL: ['mp4', 'm4v', 'avi', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf'],
  PDF: ['pdf'],
};

type FormatFile = 'IMAGE' | 'DOC' | 'ALL' | 'VIDEO' | 'PDF';

interface IDragDropUpload {
  label?: string;
  onFileChange(newFiles: File[]): void;
  files: File[];
  isMultiple?: boolean;
  message?: string;
  accept?: string;
  containerSx?: object;
  required?: boolean;
  maxSize?: number;
  disabled?: boolean;
  type?: FormatFile;
}

const megaBytesToBytes = (megaBytes: number) => {
  return megaBytes * 1048576;
};

const bytesToMegaBytes = (bytes: number) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};
const DragDropUpload: React.FC<IDragDropUpload> = ({
  label,
  onFileChange,
  isMultiple,
  message = '',
  files = [],
  maxSize,
  containerSx,
  required = false,
  disabled = false,
  type = 'DOC',
}) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!isEmpty(acceptedFiles)) {
        if (isMultiple) {
          onFileChange([...files, ...acceptedFiles]);
        } else {
          onFileChange(acceptedFiles);
        }
      }
    },
    [files]
  );

  const fileValidator = (file: File) => {
    if (maxSize && file && file.size > megaBytesToBytes(maxSize)) {
      return {
        code: 'size-too-large',
        message: `File is larger than ${maxSize} MB`,
      };
    }
    return null;
  };

  const generateAccept = () => {
    const resolveType: FormatFile = type as FormatFile;
    const getCurrentAccept = ACCEPT_BY_TYPE[resolveType];
    if (!isEmpty(getCurrentAccept)) return getCurrentAccept;
    return ACCEPT_BY_TYPE.ALL;
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles: 10,
      multiple: isMultiple,
      onDrop,
      disabled,
      accept: generateAccept(),
      minSize: 0,
      validator: fileValidator,
    });

  const onRemoveFile = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    event.stopPropagation();
    const resolveNewFiles = filter(
      files,
      (_file: File, fileIndex: number) => fileIndex !== index
    );
    onFileChange(resolveNewFiles);
  };

  const renderDimension = () => {
    const resolveType: FormatFile = type as FormatFile;
    const dimensionType = FILE_TYPES[resolveType];
    return (
      <Typography
        component="span"
        fontSize="13px"
        sx={{
          display: 'block',
          ml: 0.5,
          textAlign: 'center',
        }}
      >
        Allow file types:
        {dimensionType.join(', ')}
        {maxSize && `- Limit ${maxSize} MB`}
      </Typography>
    );
  };

  const acceptedFileItems =
    !isEmpty(files) &&
    files.length > 0 &&
    files.map((file: any, index: number) => {
      return (
        <Box
          component="div"
          key={`file.path-${index}`}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography sx={typographyFileStyle}>
            {file.path || file.name}
          </Typography>
          <Chip
            variant="outlined"
            color="primary"
            sx={{ fontSize: '14px', fontWeight: '500' }}
            label={`${bytesToMegaBytes(file.size)} Mb`}
          />
          <IconButton
            onClick={(event) => onRemoveFile(event, index)}
            disabled={disabled}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    });

  const fileRejectionItems = fileRejections.map(
    (payload: { file: any; errors: any }) => (
      <Stack direction="column" key={payload.file.path}>
        <Box sx={{ fontSize: '14px' }}>
          <Typography sx={{ fontSize: 'inherit', mr: 1 }}>
            {payload.file.path}
          </Typography>
        </Box>
        <Stack direction="column">
          {payload.errors.map((e: any) => {
            let errorMsg = e.message;
            if (e.code === 'file-invalid-type') errorMsg = 'File is wrong type';
            return (
              <Typography
                sx={{ fontSize: '13px', color: CommonColors.red }}
                key={e.code}
              >
                {errorMsg}
              </Typography>
            );
          })}
        </Stack>
      </Stack>
    )
  );

  return (
    <Box
      sx={{
        borderRadius: '15px',
        borderBottom: 'none',
        alignItems: 'center',
        width: 1,
        ...containerSx,
      }}
      component="div"
    >
      {label && (
        <InputLabel>
          <Box
            component="div"
            sx={{ display: 'flex', alignItems: 'center', width: 1 }}
          >
            <Typography fontSize="15px">{label}</Typography>
          </Box>
          {required && (
            <Typography component="span" color="error" ml={0.5}>
              *
            </Typography>
          )}
        </InputLabel>
      )}
      <Box
        component="div"
        sx={{
          position: 'relative',
          mt: 1,
        }}
      >
        <Box
          component="div"
          sx={wrapDropStyle(message, isDragActive, disabled)}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Stack
            direction="column"
            sx={{ alignItems: 'center', p: 3, width: 1 }}
          >
            <CloudUploadIcon
              fontSize="large"
              sx={{
                color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary',
              }}
            />
            <Typography sx={typographyInfoStyle(disabled)}>
              {isDragActive
                ? 'Drop the files here'
                : "Drag 'n' drop some files here, or click to select files"}
              {renderDimension()}
            </Typography>
            {!isEmpty(acceptedFileItems) && (
              <Stack direction="column" sx={{ mt: 5, width: 1 }}>
                {acceptedFileItems}
              </Stack>
            )}
          </Stack>
        </Box>
        {!isEmpty(fileRejectionItems) && (
          <Stack direction="column" sx={{ mt: 2, width: 1 }}>
            {fileRejectionItems}
          </Stack>
        )}
        <WarningAmberIcon
          color="error"
          fontSize="small"
          sx={warningAmberStyle(message)}
        />
        <Box
          component="label"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '13px',
            color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary',
          }}
        >
          {files?.length}/{isMultiple ? 10 : 1} selected
        </Box>
      </Box>
      {message && (
        <Typography fontSize="13px" color="error">
          *{message}
        </Typography>
      )}
    </Box>
  );
};

export default DragDropUpload;
