import * as AuthApi from './Authentication.api';
import * as UserApi from './User.api';
import * as RoleApi from './Role.api';
import * as CountryApi from './Country.api';
import * as PostApi from './Post.api';
import * as CommentApi from './Comment.api';
import * as PageManagementApi from './PageManagement.api';
import * as CountryScoringApi from './CountryScoring.api';
import * as CountryReportApi from './CountryReport.api';
import * as FooterApi from './Footer.api';
import * as RegionalReportApi from './RegionalReport.api';
import * as SocialLinkApi from './SocialLink.api';
import * as NofiticationApi from './Notifitaction.api';
import * as CompareAndExploreApi from './CompareAndExplore.api';
import * as GalleryApi from './Gallery.api';

export default {
  ...AuthApi,
  ...UserApi,
  ...RoleApi,
  ...CountryApi,
  ...PostApi,
  ...CommentApi,
  ...PageManagementApi,
  ...CountryReportApi,
  ...CountryScoringApi,
  ...FooterApi,
  ...RegionalReportApi,
  ...SocialLinkApi,
  ...NofiticationApi,
  ...CompareAndExploreApi,
  ...GalleryApi,
};
