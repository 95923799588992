export default {
  //Common
  search: 'Search',
  searchBy: 'Search By',
  name: 'Name',
  status: 'Status',
  allType: 'All Type',
  active: 'Active',
  inActive: 'Inactive',
  activate: 'Activate',
  deactivate: 'Deactivate',
  modify: 'Modify',

  submited: 'Submited',
  rejected: 'Rejected',

  edit: 'Edit',
  action: 'Action',
  remove: 'Remove',
  move: 'Move',
  close: 'Close',
  deny: 'Deny',
  reject: 'Reject',
  confirm: 'Confirm',
  file: 'File',
  logo: 'Logo',
  cv: 'CV',
  document: 'Document',
  video: 'Video',
  website: 'Website',
  uploadedDoc: 'Uploaded doc',
  view: 'View',
  avatar: 'Avatar',
  contact: 'Contact',
  desc: 'Description',
  date: 'Date',
  fromDate: 'From Date',
  toDate: 'To Date',
  time: 'Time',
  startTime: 'Start Time',
  endTime: 'End Time',
  quickAction: 'Quick Action',
  nofitications: 'Nofitications',
  content: 'Content',

  //Select Box
  choose: 'Choose',
  noOption: 'Option is empty!',

  //Dashboard
  dashboard: 'Dashboard',

  //User
  user: 'User',
  profile: 'Profile',
  modifyProfile: 'Modify Profile',
  userName: 'Username',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  age: 'Age',
  gender: 'Gender',
  address: 'Address',
  phone: 'Phone',
  phoneNumber: 'Phone Number',
  areaOfExpertise: 'Area Of Expertise',
  yearOfExperience: 'Years Of Experience',
  contacts: 'Contacts',
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  showPassword: 'Show Password',

  //Event
  event: 'Event',
  events: 'Events',
  eventManagement: 'Event Management',
  eventList: 'Event List',
  addEvent: 'Add New Event',
  modifyEvent: 'Modify Event',
  eventDetails: 'Event Details',
  eventCalendar: 'Event Calendar',
  eventDate: 'Event Date',
  eventName: 'Event Name',
  eventTime: 'Event Time',
  eventSummary: 'Event Summary',
  eventReport: 'Event Report',
  eventReportStatistics: 'Event Report Statistics',
  addNewEvent: 'Add New Event',
  restrictedAccessEvent: 'Restricted access event',
  //Event Report
  reportStatistics: 'Report Statistics',
  reportEventStatistics: 'Report Event Statistics',
  restrictedAccess: 'Restricted access',
  registeredUsers: 'Registered Users',
  registeredUsersManagement: 'Registered Users Management',
  registrationRequests: 'Registration Requests',
  registrationRequestsManagement: 'Registration Request Management',
  approvedUsers: 'Approved Users',
  restrictedUsers: 'Restricted Users',
  attendees: 'Attendees',
  coHost: 'Co-host',
  nameCoHost: 'Name Co-host',
  emailCoHost: 'Email Co-host',
  assignCoHost: 'Assign Co-host',
  coHostOrSpeaker: 'Co-host/Speaker',
  //Venue
  venue: 'Venue',

  // Event Type
  eventType: 'Event Type',

  //Event Admin
  eventAdmin: 'Event Admin',
  eventAdmins: 'Event Admins',
  eventAdminManagement: 'Event Admin Management',
  eventAdminList: 'Event Admin List',
  addEventAdmin: 'Add New Event Admin',
  modifyEventAdmin: 'Modify Event Admin',
  eventAdminDetails: 'Event Admin Details',
  eventAdminName: 'Event Admin Name',
  adminName: 'Admin Name',
  enterAdminName: 'Enter Admin Name',

  //Sponsor
  sponsorAdd: 'add new',
  orderSponsor: 'Order sponsor',
  sponsor: 'Sponsor',
  sponsorTitle: 'SPONSOR',
  sponsorList: 'Sponsor Management',
  sponsorCreate: 'Add New Sponsor',
  sponsorModify: 'Modify Sponsor',
  sponsorOrder: 'Order Sponsor',
  sponsorUnorder: 'Unorder Sponsor',
  sponsorName: 'Sponsor Name',
  sponsorWeb: 'Sponsor Web',
  sponsorDescription: 'Description',
  sponsorLogo: 'Logo',

  //Booth
  booth: 'Booth',
  boothList: 'Booth List',
  logoOrBanner: 'Logo/Banner',
  eventUser: 'Event User',
  addBooth: 'Add New Booth',
  modifyBooth: 'Modify Booth',
  boothInfo: 'Booth Information',

  //Pitching Event User
  pitchingEventUser: 'Pitching Event User',
  pitchingEventUserList: 'Pitching Event User List',
  pitchingEventUserDetails: 'Pitching Event User Details',
  pitchingEventUserManagement: 'Pitching Event User Management',
  coFounders: 'Co-Founders',
  personalDetails: 'Personal Details',
  downloadDocumentAvailable:
    'Document available for the audience to download (optional)',
  videoAvailable: 'Presentation Video available for the audience (optional)',
  noVideoWasUploaded: 'No video was uploaded',
  questionSolveProblem: 'What problem does it solve?',
  descOfBusiness: 'Description of the business',
  headquarters: 'Headquarters (city, country)',
  startupName: 'Startup Name',
  industry: 'Industry',
  industryOrStudent: 'Industry/Student',
  confirmIsStudent: 'Yes (Degree/field of study)',
  confirmIsIndustry: 'No (Industry/company(optional))',
  hearFrom: 'Hear from',
};
