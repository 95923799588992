import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Grid, Box, Hidden } from '@mui/material';

import { BreadCrumb } from '@/Components/LayoutPart';
import DefaultLayout from '@/Components/DefaultLayout';
import { NoDataWereFound, SkeletonLoading } from '@/Components/Common';

import AboutNav from './AboutNav';

import Utils from '@/Utils';
import { GuidelinesActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';

import { ICreateGuideline } from '@/Interfaces/PageManagement.interface';

const { getGuidelines } = GuidelinesActions;

const Guidelines: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.GUIDELINES, 'isGetLoading')
  );
  const guidelinesContent: ICreateGuideline = useSelector((state: RootState) =>
    _.get(state.GUIDELINES, 'guidelinesContent')
  );

  const [details, setDetails] = useState<ICreateGuideline>({
    title: 'EU CS Guidelines',
    content: '',
  });

  useEffect(() => {
    dispatch(getGuidelines());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(guidelinesContent)) {
      const { title, content } = guidelinesContent;
      setDetails({
        title,
        content,
      });
    }
  }, [guidelinesContent]);

  const _renderContent = () => (
    <Box
      sx={{
        img: {
          maxWidth: 1,
          objectFit: 'cover',
          wordWrap: 'break-word',
        },
        minHeight: 700,
      }}
      dangerouslySetInnerHTML={Utils.createMarkup(details.content)}
    />
  );

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadCrumb title={details.title} />
        </Grid>
        <Hidden mdDown>
          <Grid item md={12} lg={2}>
            <AboutNav />
          </Grid>
        </Hidden>
        {!isGetLoading ? (
          <Grid item md={12} lg={10}>
            {guidelinesContent ? (
              <Grid item xs={12}>
                {_renderContent()}
              </Grid>
            ) : (
              <Grid item xs={9}>
                <NoDataWereFound />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <SkeletonLoading numberRender={5} />
          </Grid>
        )}
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default Guidelines;
