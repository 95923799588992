import { NoDataWereFound } from '@/Components/Common';
import { KEY_FINDINGS } from '@/Constants/RegionalReport';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import _ from 'lodash';
interface IProps {
  keyFindings: string[];
}
const KeyFindingsRecommendationsUser: React.FC<IProps> = (data) => {
  const _renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell />
        {_.map(KEY_FINDINGS, (item) => (
          <TableCell
            sx={{ width: 0.5 }}
            align="center"
            key={`head-${item.value}`}
          >
            <Typography fontWeight="bold">{item.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };
  const _renderTableBody = () => {
    return _.map(data, (item) => {
      return _.map(item, (list, listKey) => {
        return (
          <TableRow key={listKey}>
            <TableCell
              sx={{
                borderRight: '1px solid #E0E0E0',
              }}
            >
              {listKey + 1}
            </TableCell>
            {_.map(KEY_FINDINGS, (head, innerKey) => (
              <TableCell
                key={`row${innerKey}-${listKey}`}
                sx={{
                  borderRight: '1px solid #E0E0E0',
                  wordWrap: 'break-word',
                  maxWidth: 250,
                  verticalAlign: 'text-top',
                }}
              >
                {_.get(list, head.value)}
              </TableCell>
            ))}
          </TableRow>
        );
      });
    });
  };
  const _renderTable = () => {
    return (
      <Box>
        <Paper
          sx={{
            position: 'relative',
            overflowX: {
              xs: 'scroll',
              sm: 'hidden',
            },
            width: {
              xs: '100%',
              lg: '50%',
            },
            m: '0 auto',
          }}
        >
          <Table>
            <TableHead>{_renderTableHeader()}</TableHead>
            <TableBody>{_renderTableBody()}</TableBody>
          </Table>
          {_.isEmpty(data?.keyFindings) && <NoDataWereFound height={350} />}
        </Paper>
      </Box>
    );
  };
  return _renderTable();
};
export default KeyFindingsRecommendationsUser;
