import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  floatSocials: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_FLOAT_SOCIAL_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_FLOAT_SOCIAL_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.FETCH_FLOAT_SOCIAL_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        floatSocials: payload,
      };
    case ACTION_TYPES.FETCH_FLOAT_SOCIAL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    default:
      return state;
  }
};
