import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const NewsTextHeader: SxProps<Theme> = {
  direction: 'column',
  justifyContent: 'space-between',
  width: '100%',
  px: 5,
  pb: 3,
};
export const NewsTextHeaderItem: SxProps<Theme> = {
  display: 'flex',
  color: CommonColors.silverChalice,
};
export const mainContentStyles: SxProps<Theme> = {
  display: 'block',
  boxSizing: 'border-box',
};
export const mainContentItemStyles: SxProps<Theme> = {
  color: CommonColors.black,
  fontSize: '12pt',
};
export const iconStyles: SxProps<Theme> = {
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  border: 1,
};
