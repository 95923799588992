import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Grid,
  Typography,
  Button,
  Box,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SwipeableViews from 'react-swipeable-views';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import {
  NoDataWereFound,
  CommonStyles,
  ChartComponent,
  TreeDropdown,
} from '@/Components/Common';
import { LIST, ROUTERS } from '@/Constants';
import Utils from '@/Utils';
import { CommonColors } from '@/Themes';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const { REGIONAL_REPORT_USER_LIST } = LIST.DROPDOWN_LIST;

interface IFindingStructure {
  year: string;
}

const Finding: React.FC<IFindingStructure> = ({ year }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [areaSelected, setAreaSelected] = useState<string>('area_1');
  const [dataFindingBased, setDataFindingBased] = useState<string[]>();
  const [labeFinding, setLabelFindings] = useState<any>(
    'area_1_sub_area_1_1_standard_1'
  );
  const [openItems, setOpenItems] = useState<string[]>(['area_1']);
  const [selectState, setSelectState] = useState<string>(
    'area_1_sub_area_1_1_standard_1'
  );
  const [openItemsStandards, setOpenItemsStandards] = useState<string[]>([
    'area_1_sub_area_1_1',
  ]);
  const [finding, setFinding] = useState<any>();
  const [openDialogimageChart, setOpenDialogimageChart] =
    useState<boolean>(false);
  const [subAreas, setSubareas] = useState<string>('area_1_sub_area_1_1');
  const [standardIndex, setStandardIndex] = useState<number>(0);

  // const [expanded, setExpanded] = useState<{
  //   findings: boolean;
  //   recommendations: boolean;
  // }>({ findings: true, recommendations: false });
  const chartForYear: any = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORTS_USER, 'chartForYear')
  );
  const dataRegional = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORTS_USER, 'dataRegional.finding')
  );

  const handleChangeIndex = (index: number) => setStandardIndex(index);

  useEffect(() => {
    if (!_.isEmpty(dataRegional)) {
      const data = JSON.parse(dataRegional);
      setFinding(data?.findingState);
      setDataFindingBased(
        data.findingState?.area_1?.area_1_sub_area_1_1
          ?.area_1_sub_area_1_1_standard_1
      );
    } else {
      setFinding(null);
      setDataFindingBased([]);
    }
  }, [dataRegional]);

  const handleOnClickSub = (
    labelStandard: any,
    area: string,
    sub: string,
    standard: string
  ) => {
    if (!_.isEmpty(dataRegional)) {
      //get content standard
      const areaGet = _.get(finding?.[area]?.[sub], standard);
      setDataFindingBased(areaGet);
    }
    setLabelFindings(labelStandard);
    setSelectState(standard);
    setAreaSelected(area);
    setSubareas(sub);
  };

  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const handleOpenItemsStandards = (value: string) => {
    let currentItems = [...openItemsStandards];
    if (_.includes(openItemsStandards, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItemsStandards(currentItems);
  };

  useEffect(() => {
    if (areaSelected !== 'area_1' && subAreas !== 'area_1_sub_area_1_1')
      scrollToTop();
  }, [selectState]);

  const scrollToTop = useCallback(
    () =>
      (
        window.document.querySelector('.tab-container') as HTMLElement
      )?.scrollIntoView({
        behavior: 'smooth',
      }),
    [selectState]
  );

  // const handleClick = (key: string, value: boolean) =>
  //   setExpanded({ ...expanded, [key]: value });

  const _renderChart = (subCode: string, standardCode: string) => {
    if (!_.isEmpty(areaSelected) && !chartForYear) return null;
    const getChartArea: any = _.find(chartForYear, ['code', areaSelected]);
    if (!getChartArea) return null;
    const getChartSubArea = _.find(getChartArea?.charts, ['code', subCode]);
    if (!getChartSubArea) return null;
    const getChart = _.find(getChartSubArea?.charts, ['code', standardCode]);
    return (
      <Box component="div" width={{ sm: 1, md: 1 }}>
        <ChartComponent.BarChart
          title=""
          verticalText="Score (%)"
          horizontalText=""
          data={getChart?.chartData}
          axis="x"
          isShowLegend={false}
        />
        {openDialogimageChart && <ChartComponent.ChartScoring />}
      </Box>
    );
  };

  const _renderContentOnMobile = () => {
    const areaLabel = _.filter(
      REGIONAL_REPORT_USER_LIST,
      (item: any) => item?.value === areaSelected
    );
    const subAreaLabel = _.filter(
      areaLabel?.[0]?.subAreas,
      (item: any) => item?.value === subAreas
    );
    return (
      <Grid item xs={12}>
        <TreeDropdown
          disabled={!dataRegional}
          listOf="REGIONAL_REPORT"
          helperText="Choose a sub area for view"
          defaultValue={subAreas}
          onChange={(value, parent) => {
            if (value && parent?.level1) {
              setAreaSelected(_.toString(parent?.level1));
              setSubareas(_.toString(value));
            }
          }}
        />
        {dataRegional ? (
          <Box sx={{ textAlign: 'right' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Button
                startIcon={<NavigateBeforeIcon />}
                color="burntSienna"
                disabled={!standardIndex}
                onClick={() => setStandardIndex(standardIndex - 1)}
              >
                Back
              </Button>
              <Button
                endIcon={<NavigateNextIcon />}
                color="burntSienna"
                disabled={
                  standardIndex + 1 === _.size(subAreaLabel?.[0]?.standards)
                }
                onClick={() => setStandardIndex(standardIndex + 1)}
              >
                Next
              </Button>
            </Box>
            <SwipeableViews
              containerStyle={{ height: '100%' }}
              axis="x"
              index={standardIndex}
              onChangeIndex={handleChangeIndex}
            >
              {subAreaLabel?.[0]?.standards &&
                _.map(subAreaLabel?.[0]?.standards, (standard) => {
                  const data = JSON.parse(dataRegional);
                  return (
                    <Box key={standard.value}>
                      <Typography variant="subtitle1" textAlign="center">
                        {standard.label}
                      </Typography>
                      <Box>{_renderChart(subAreas, standard.value)}</Box>
                      <Box
                        sx={{
                          fontSize: 16,
                          textAlign: 'justify',
                          mt: 4,
                          wordWrap: 'break-word',
                          img: {
                            maxWidth: 1,
                          },
                        }}
                        dangerouslySetInnerHTML={Utils.createMarkup(
                          data?.findingState?.[areaSelected]?.[subAreas]?.[
                            standard?.value
                          ]
                        )}
                      />
                    </Box>
                  );
                })}
            </SwipeableViews>
          </Box>
        ) : (
          <NoDataWereFound />
        )}
      </Grid>
    );
  };

  const _renderContent = (area: any, subArea: any, standard: any) => {
    const indicatorColor = CommonColors.mainColor;
    const AreaLabel = REGIONAL_REPORT_USER_LIST.filter(
      (item: any) => item.value === area
    );
    const subAreaLabel = AreaLabel[0]?.subAreas.filter(
      (item: any) => item.value === subArea
    );
    const standardLabel = subAreaLabel[0].standards.filter(
      (item: any) => item.value === standard
    );
    if (!_.isEmpty(chartForYear) || !_.isEmpty(dataFindingBased))
      return (
        <Box minHeight={500} width={1}>
          {labeFinding && (
            <Grid container sx={{ p: 2, position: 'relative' }}>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Box display="flex">
                  <AcUnitIcon
                    sx={{
                      color: indicatorColor,
                      fontSize: 14,
                      mt: 1,
                    }}
                  />
                  <Typography ml={1} variant="h6">
                    {standardLabel[0]?.label}
                  </Typography>
                </Box>
                {!_.isEmpty(chartForYear) && !openDialogimageChart && (
                  <Box>
                    <Button
                      href={`${ROUTERS.REGIONAL_REPORTS_DETAIL}?year=${year}&code=${areaSelected}&subCode=${subAreas}&standCode=${selectState}`}
                      target="_blank"
                    >
                      <OpenInNewIcon />
                    </Button>
                  </Box>
                )}
              </Grid>
              {!openDialogimageChart && (
                <Grid item xs={12}>
                  <Box>
                    <Box
                      width={{ sm: 350, md: 430 }}
                      sx={{ position: 'relative' }}
                    >
                      <Box
                        sx={{
                          float: 'left',
                          width: 1,
                          mr: 2,
                        }}
                      >
                        {_renderChart(subAreas, selectState)}
                      </Box>
                      {chartForYear?.length > 0 && (
                        <Button
                          sx={{ position: 'absolute', right: 0 }}
                          onClick={() => setOpenDialogimageChart(true)}
                        >
                          <ZoomOutMapIcon />
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{ fontSize: 16, textAlign: 'justify', mt: 4 }}
                    style={{ wordWrap: 'break-word' }}
                    dangerouslySetInnerHTML={Utils.createMarkup(
                      dataFindingBased
                    )}
                  />
                </Grid>
              )}
              <Grid item mb={4} sx={{ background: 'while', width: 1 }}>
                <Box>
                  {openDialogimageChart && (
                    <Box
                      sx={{
                        position: 'relative',
                        top: 0,
                        right: 0,
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            background: 'white',
                            position: 'absolute',
                            right: 0,
                          }}
                        >
                          <Button
                            href={`${ROUTERS.REGIONAL_REPORTS_DETAIL}?year=${year}&code=${areaSelected}&subCode=${subAreas}&standCode=${selectState}`}
                            target="_blank"
                          >
                            Open in new tab
                          </Button>
                          <Button
                            onClick={() => setOpenDialogimageChart(false)}
                          >
                            <ZoomInMapIcon />
                          </Button>
                        </Box>
                      </Box>
                      <Box sx={{ background: 'white', width: 1, pb: 4 }}>
                        {_renderChart(subAreas, selectState)}
                      </Box>
                      <Box
                        sx={{ fontSize: 16, textAlign: 'justify', mt: 4 }}
                        style={{ wordWrap: 'break-word' }}
                        dangerouslySetInnerHTML={Utils.createMarkup(
                          dataFindingBased
                        )}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      );
    return <NoDataWereFound height={300} />;
  };

  const _renderFindings = () => {
    // if (!_.isEmpty(findingState))
    return (
      <Grid container>
        <Grid item xs={12} sm={4}>
          {_.map(REGIONAL_REPORT_USER_LIST, (area, index) => {
            const isOpenSub = _.includes(openItems, area.value);
            return (
              <Box key={index}>
                <ListItemButton
                  onClick={() => {
                    if (area) handleOpenItems(area.value);
                  }}
                >
                  <ListItemText primary={area.label} />
                  {isOpenSub ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={isOpenSub} timeout="auto" unmountOnExit>
                  <List>
                    {_.map(area.subAreas, (sub, key) => {
                      const isOpenStandards = _.includes(
                        openItemsStandards,
                        sub.value
                      );
                      return (
                        <Box key={key}>
                          <ListItemButton
                            key={`${key}${sub.label}`}
                            sx={{
                              pl: 4,
                              ...CommonStyles.hoverBorderEffect,
                            }}
                            onClick={() => handleOpenItemsStandards(sub.value)}
                          >
                            <Box
                              sx={{ position: 'absolute', left: 12, top: 12 }}
                            >
                              {isOpenStandards ? (
                                <RemoveIcon sx={{ fontSize: 12, mr: 1 }} />
                              ) : (
                                <AddIcon sx={{ fontSize: 12, mr: 1 }} />
                              )}
                            </Box>

                            <ListItemText primary={sub.label} />
                          </ListItemButton>
                          <Collapse
                            in={isOpenStandards}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List>
                              {_.map(sub.standards, (listItem, listItemKey) => {
                                const selected = listItem.value === selectState;
                                return (
                                  <Box ml={2} key={listItemKey}>
                                    <ListItemButton
                                      key={`${listItem}${listItem.label}`}
                                      sx={{
                                        pl: 4,
                                        ...CommonStyles.hoverBorderEffect,
                                      }}
                                      selected={selected}
                                      onClick={() =>
                                        handleOnClickSub(
                                          listItem,
                                          area.value,
                                          sub.value,
                                          listItem.value
                                        )
                                      }
                                    >
                                      <ListItemText primary={listItem.label} />
                                    </ListItemButton>
                                  </Box>
                                );
                              })}
                            </List>
                          </Collapse>
                        </Box>
                      );
                    })}
                  </List>
                </Collapse>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2} p={2}>
            {_renderContent(areaSelected, subAreas, selectState)}
          </Grid>
        </Grid>
      </Grid>
    );
    // return <NoDataWereFound height={300} />;
  };

  return (
    <Grid container spacing={2}>
      {matches ? (
        <Grid item xs={12}>
          <List component="div" sx={{ ml: '20px' }}>
            {/* <ListItemButton
            onClick={() => handleClick('findings', !expanded.findings)}
            sx={{ ...CommonStyles.listButtonStyles }}
          >
            <ListItemText
              primary={
                <Typography component="h2" sx={{ fontWeight: 'bold' }}>
                  Findings
                </Typography>
              }
            />
            {expanded.findings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton> */}
            {/* <Collapse in={expanded.findings} timeout="auto" unmountOnExit> */}
            <List sx={{ p: 2 }}>{_renderFindings()}</List>
            {/* </Collapse> */}
          </List>
        </Grid>
      ) : (
        _renderContentOnMobile()
      )}
    </Grid>
  );
};

export default Finding;
