import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
  Button,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';

import { PaginationActions } from '@/Components/Common';

import { RegionalActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ROUTERS, ENUMS, DEFAULT_CONFIRM } from '@constants';
import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import Utils from '@/Utils';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import Dialogs from '../Dialogs';
import { IDropdownOption } from '@/Interfaces/Dropdown.interface';

const STATUS_DROPDOWN = [
  {
    label: 'PUBLISHED',
    value: ENUMS.STATUS.PUBLISH,
  },
  {
    label: 'ACTIVE',
    value: ENUMS.STATUS.ACTIVE,
  },
  {
    label: 'INACTIVE',
    value: ENUMS.STATUS.INACTIVE,
  },
];

interface IRegionalReport {
  search: any;
}

const { REGIONAL_TABLE_HEADER } = TABLES;
// Declare actions
const {
  fetchAllRegionalReports,
  activeRegionalReport,
  publishRegionalReport,
  inactiveRegionalReport,
} = RegionalActions;

const RegionalTable: React.FC<IRegionalReport> = ({ search }) => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [valueChange, setValueChange] = useState<any>({
    id: '',
    status: '',
  });
  const regionalTable: any =
    useSelector((state: RootState) =>
      _.get(state.REGIONAL_REPORT, 'defaultRegionalReportList')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'isFetchLoading')
  );
  const colSpan = REGIONAL_TABLE_HEADER.length + 1;

  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(fetchAllRegionalReports({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchAllRegionalReports({ ...pagination, limit: perPage }));
  };

  const handleSubmitChangeStatus = (state: { id?: string; status: string }) => {
    const { id, status } = state;
    if (id && status === ENUMS.STATUS.ACTIVE)
      dispatch(activeRegionalReport(id, search));
    if (id && status === ENUMS.STATUS.PUBLISH)
      dispatch(publishRegionalReport(id, search));
    if (id && status === ENUMS.STATUS.INACTIVE)
      dispatch(inactiveRegionalReport(id, search));
    setConfirmDialog(DEFAULT_CONFIRM);
    setValueChange({ id: '', status: '' });
  };

  const handleChangeStatus = (value: string, id: string) => {
    setValueChange({ id, value });
    const description = `Are you sure you want to ${value} this country report.`;
    setConfirmDialog({
      isOpen: true,
      message: description,
      state: {
        id: id,
        status: value,
      },
    });
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchAllRegionalReports(search));
    setValueChange({ id: '', status: '' });
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(REGIONAL_TABLE_HEADER, (item) => (
          <TableCell
            key={`head-${item.value}`}
            sx={{ fontWeight: '700', maxWidth: 250 }}
          >
            {item.label}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Status
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          PDF
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [REGIONAL_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(regionalTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 2}>
            There is no country(s) to display
          </TableCell>
        </TableRow>
      );
    return _.map(regionalTable, (item, index) => {
      const colorGet = valueChange?.id === item?.id && valueChange?.status;
      return (
        <TableRow hover key={index}>
          {_.map(REGIONAL_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`} sx={{ maxWidth: 350 }}>
              {head.value === 'year'
                ? item?.year
                : _.get(item, head.value) ?? 'N/I'}
            </TableCell>
          ))}
          <TableCell key={`status.${index}`} align="center">
            <TextField
              defaultValue={item?.status}
              key={`status-${item?.id}`}
              size="small"
              select
              onChange={(e) => handleChangeStatus(e.target.value, item.id)}
              disabled={item?.status === ENUMS.STATUS.TERMINATED}
              sx={{
                minWidth: 125,
                '& .MuiSelect-select': {
                  opacity: 1,
                  color:
                    _.get(
                      TableStatusColor,
                      colorGet || _.get(item, 'status')
                    ) || CommonColors.black,
                },
                '& .MuiSelect-select.MuiOutlinedInput-input.Mui-disabled': {
                  opacity: 1,
                  WebkitTextFillColor: 'unset',
                },
              }}
            >
              {STATUS_DROPDOWN.map(
                (option: IDropdownOption, innerKey: number) => {
                  return (
                    <MenuItem
                      key={`${option.label}-${innerKey}`}
                      value={option.value}
                      sx={{
                        '&.Mui-disabled.MuiMenuItem-gutters.Mui-selected': {
                          opacity: 1,
                        },
                        color:
                          _.get(TableStatusColor, _.get(option, 'value')) ||
                          CommonColors.black,
                        display:
                          (item?.status === option.value && item?.status) ||
                          (item?.status === ENUMS.STATUS.INACTIVE &&
                            option.value !== ENUMS.STATUS.ACTIVE)
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <Typography fontSize={14}>{option.label}</Typography>
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </TableCell>
          <TableCell>
            <Button
              href={`${_.get(item, 'fileReport.path')}`}
              disabled={!item.fileReport}
              target="_blank"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <PictureAsPdf fontSize="medium" />
            </Button>
          </TableCell>
          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View Detail">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() => {
                  Utils.redirect(ROUTERS.UPDATE_REGIONAL_REPORT, {
                    id: item.id,
                  });
                }}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 2}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan + 2} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      {_renderTable()}
      <Dialogs.Confirm
        confirm={confirmDialog}
        onCancel={() => onCancel()}
        callback={() => handleSubmitChangeStatus(confirmDialog.state)}
      />
    </Box>
  );
};

export default RegionalTable;
