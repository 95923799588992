import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';

import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Divider,
} from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionSlide } from '@/Components/Common';

import Utils from '@/Utils';

import { IMMToolkitStandar } from '@interfaces/CountryScoring.interface';

interface SectionProps {
  open: boolean;
  handleClose(): void;
  standards: IMMToolkitStandar[];
  standardActive: IMMToolkitStandar;
}

const MMToolkitDialog: React.FC<SectionProps> = (props: SectionProps) => {
  const { open, handleClose, standards, standardActive } = props;
  const [standardIndex, setStandardIndex] = useState<number>(0);

  useEffect(() => {
    return () => setStandardIndex(0);
  }, []);

  useEffect(() => {
    if (standardActive) {
      const getIndex = _.findIndex(
        standards,
        (standard: IMMToolkitStandar) => standard === standardActive
      );
      if (getIndex !== -1) setStandardIndex(getIndex);
    }
  }, [standardActive]);

  const onClose = () => handleClose();

  const handleChangeIndex = (index: number) => setStandardIndex(index);

  const _renderTitle = () => {
    return (
      <Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Button
              startIcon={<NavigateBeforeIcon />}
              color="burntSienna"
              disabled={!standardIndex}
              onClick={() => setStandardIndex(standardIndex - 1)}
            >
              Back
            </Button>
            <Typography variant="h5">
              {standards[standardIndex]?.codeName}
            </Typography>
            <Button
              endIcon={<NavigateNextIcon />}
              color="burntSienna"
              disabled={standardIndex + 1 === standards.length}
              onClick={() => setStandardIndex(standardIndex + 1)}
            >
              Next
            </Button>
          </Box>
          <Typography variant="h6">{standards[standardIndex]?.name}</Typography>
        </Box>
        <Typography
          component="h2"
          sx={{
            textAlign: 'center',
            mb: 3,
            mt: 3,
            fontWeight: 'bold',
          }}
        >
          INDICATORS
        </Typography>
        <Divider sx={{ mb: 1 }} />
      </Box>
    );
  };

  const _renderContent = useCallback(() => {
    return _.map(standards, (standard: IMMToolkitStandar, index: number) => {
      const { legislation, practice } = standard;

      const _renderLegislationList = () => (
        <Box
          sx={{
            img: {
              maxWidth: '100%',
              objectFit: 'cover',
            },
          }}
          dangerouslySetInnerHTML={Utils.createMarkup(legislation)}
        />
      );

      const _renderPracticeList = () => (
        <Box
          sx={{
            img: {
              maxWidth: '100%',
              objectFit: 'cover',
            },
          }}
          dangerouslySetInnerHTML={Utils.createMarkup(practice)}
        />
      );

      return (
        <Grid container key={index}>
          <Grid item md={5.5} sm={12}>
            <Typography
              component="h2"
              sx={{ fontWeight: '700', textAlign: 'center' }}
            >
              Legislation
            </Typography>
            {_renderLegislationList()}
          </Grid>
          <Grid
            item
            xs={1}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Divider
              orientation="vertical"
              style={{ height: '50%', width: '1px' }}
            />
          </Grid>
          <Grid item md={5.5} sm={12}>
            <Typography
              component="h2"
              sx={{ fontWeight: '700', textAlign: 'center' }}
            >
              Practice
            </Typography>
            {_renderPracticeList()}
          </Grid>
        </Grid>
      );
    });
  }, [standards]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => onClose()}
      TransitionComponent={TransitionSlide}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{_renderTitle()}</DialogTitle>
      <DialogContent>
        <SwipeableViews
          axis="x"
          index={standardIndex}
          onChangeIndex={handleChangeIndex}
        >
          {_renderContent()}
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CloseIcon />}
          onClick={() => onClose()}
          variant="contained"
          color="fuzzyWuzzyBrown"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MMToolkitDialog;
