/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Stack,
  MenuItem,
  TextField,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import DefaultLayout from '@/Components/DefaultLayout';
import {
  DragDropUpload,
  ActiveBreadCrumb,
  UploadImage,
  SkeletonLoading,
} from '@/Components/Common';
import { Dialogs } from '@/Components/LayoutPart';

import Subareas from './Subareas';
import APBackground from './Background';
import APExecutiveSummary from './ExecutiveSummary';
import APCSOverview from './CSOverview';
import APConclusionsAndRecommendations from './ConclusionsAndRecommendations';

import { CountryActions, CountryReportsActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { ENUMS, ROUTERS, DEFAULT_CONFIRM, LIST } from '@/Constants';
import { DEFAULT_COUNTRY_REPORT } from '@/Constants/CountryScoring';
import Utils from '@/Utils';

import { ICountryReportStructure } from '@/Interfaces/CountryReport.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const { fetchAllCountries } = CountryActions;

const { DROPDOWN_LIST } = LIST;
const { FINDING_MENU } = DROPDOWN_LIST;

const {
  getCountryReportById,
  publishCountryReport,
  unPublishCountryReport,
  resetCountryReportsReducer,
} = CountryReportsActions;

interface ICountryPayloadStructure {
  id: string;
  name: string;
  status: string | boolean;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  extraData?: any;
}

interface ICountryOptions {
  label: string;
  value: string;
  code: string;
}

type TSelectedOption =
  | 'keyFindings'
  | 'CSOverview'
  | 'background'
  | 'findings'
  | 'conclusionsAndRecommendations';

type FILE_KEY_TYPES = 'logo' | 'fileEnglish' | 'fileLocal';

const APUpdateCountryReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isActionLoading')
  );

  const countries: ICountryPayloadStructure[] = useSelector(
    (state: RootState) => _.get(state.COUNTRY, 'countries') || null
  );
  const countryDetails: ICountryReportStructure = useSelector(
    (state: RootState) => _.get(state.COUNTRY_REPORTS, 'details') || null
  );
  const pagination = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'pagination')
  );

  const [details, setDetails] = useState<ICountryReportStructure>(
    DEFAULT_COUNTRY_REPORT
  );

  const [logoState, setLogo] = useState<File[]>([]);
  const [fileEnglishState, setFileEnglish] = useState<File[]>([]);
  const [fileLocalState, setFileLocal] = useState<File[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedOption, setSelectedOption] =
    useState<TSelectedOption>('keyFindings');
  const [countryOptions, setCountryOptions] = useState<ICountryOptions[]>([]);
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');

  const [confirmSendBackReviewer, setConfirmSendBackReviewer] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [confirmPublish, setConfirmPublish] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    dispatch(fetchAllCountries());
    return () => {
      dispatch(resetCountryReportsReducer());
      setDetails(DEFAULT_COUNTRY_REPORT);
      setLogo([]);
      setFileEnglish([]);
      setFileLocal([]);
      setSelectedCountry('');
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(countries)) {
      const resolveCountries = _.map(
        countries,
        (country: ICountryPayloadStructure) => {
          return {
            label: country.name,
            value: country.id,
            code:
              country?.extraData?.code === 'CS-KM'
                ? 'XK'
                : Utils.getCountryISO2(country?.extraData?.code) || '',
          };
        }
      );
      setCountryOptions(resolveCountries);
    }
  }, [countries]);

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getCountryReportById(locationState.id));
    } else Utils.redirect(ROUTERS.COUNTRY_REPORT_MANAGEMENT);
  }, [locationState]);

  useEffect(() => {
    generateDetails(countryDetails);
  }, [countryDetails]);

  useEffect(() => {
    generateAssets();
  }, [details?.logo, details?.fileEnglish, details?.fileLocal]);

  const generateDetails = (detail: ICountryReportStructure) => {
    if (!_.isEmpty(detail)) {
      const {
        title,
        backgroundCountry,
        conclusionRecommendation,
        country,
        executive,
        finding,
        year,
        logo,
        fileEnglish,
        fileLocal,
        commentCountryReport,
        status,
      } = detail;

      const resolveDetails = {
        title,
        backgroundCountry: backgroundCountry || '',
        conclusionRecommendation: conclusionRecommendation || '',
        countryId: country?.id,
        executive: executive ? JSON.parse(executive) : '',
        finding: finding ? JSON.parse(finding) : '',
        year: dayjs().year(year),
        logo,
        fileEnglish,
        fileLocal,
        commentCountryReport,
        status,
      };

      setDetails(resolveDetails);
    }
  };

  const generateAssets = async () => {
    if (details?.logo) {
      const isInternal = details?.logo?.storageService === 'Internal';
      const logoSrc = isInternal
        ? await Utils.getFileFromURL(
            details?.logo.path,
            details?.logo.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            details?.logo.path,
            details?.logo.nameOriginal
          );
      if (logoSrc) setLogo([logoSrc as File]);
    }
    if (details?.fileEnglish) {
      const isInternal = details?.fileEnglish?.storageService === 'Internal';
      const fileEnglishSrc = isInternal
        ? await Utils.getFileFromURL(
            details?.fileEnglish.path,
            details?.fileEnglish.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            details?.fileEnglish.path,
            details?.fileEnglish.nameOriginal,
            'pdf'
          );
      if (fileEnglishSrc) setFileEnglish([fileEnglishSrc as File]);
    }
    if (details?.fileLocal) {
      const isInternal = details?.fileLocal?.storageService === 'Internal';
      const fileLocalSrc = isInternal
        ? await Utils.getFileFromURL(
            details?.fileLocal.path,
            details?.fileLocal.nameOriginal
          )
        : await Utils.getAWSFileAsBlob(
            details?.fileLocal.path,
            details?.fileLocal.nameOriginal,
            'pdf'
          );
      if (fileLocalSrc) setFileLocal([fileLocalSrc as File]);
    }
  };

  const handleChangeOption = (value: TSelectedOption) =>
    setSelectedOption(value);

  const handleChangeCountry = (value: string) => setSelectedCountry(value);

  const handleFilesChange = (key: FILE_KEY_TYPES, items: any[]) => {
    if (key === 'logo') setLogo(items);
    if (key === 'fileEnglish') setFileEnglish(items);
    if (key === 'fileLocal') setFileLocal(items);
  };

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = _.toNumber(Utils.getFormatDate(newValue, 'YYYY'));
      if (!dayjs(newValue).isBefore(dayjs()) || yearSelect < 1900)
        setIsValidDateMsg('Please choose year valid');
      else {
        setIsValidDateMsg('');
        setDetails({
          ...details,
          year: _.toString(new Date(newValue).getFullYear()),
        });
      }
    } else setIsValidDateMsg('Please choose year valid');
  };

  const handleSendBackReviewer = () =>
    setConfirmSendBackReviewer({
      isOpen: true,
      message: `This report will be send back to reviewer.`,
      state: {
        id: '',
        status: '',
      },
    });

  const handleSubmitSendBackReviewer = () => {
    setConfirmSendBackReviewer(DEFAULT_CONFIRM);
    dispatch(
      unPublishCountryReport(details.id || locationState?.id, pagination)
    );
  };

  const onCancelSendBackReviewer = () => {
    setConfirmSendBackReviewer(DEFAULT_CONFIRM);
  };

  const handlePublish = () =>
    setConfirmPublish({
      isOpen: true,
      message: `This will publish this report.`,
      state: {
        id: '',
        status: '',
      },
    });

  const handleSubmitPublish = () => {
    setConfirmSendBackReviewer(DEFAULT_CONFIRM);
    dispatch(publishCountryReport(details.id || locationState?.id, pagination));
  };

  const onCancelPublish = () => {
    setConfirmPublish(DEFAULT_CONFIRM);
  };

  const _renderCountryReportTitle = () =>
    details?.title && (
      <TextField
        label="Title"
        value={details.title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setDetails({ ...details, title: e.target.value });
        }}
        fullWidth
        disabled
      />
    );

  const _renderCountryOptions = () => {
    return (
      <TextField
        select
        fullWidth
        value={details.countryId || selectedCountry}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeCountry(e.target.value)
        }
        label="Country"
        disabled
        required
      >
        {_.map(countryOptions, (option, index) => {
          return (
            <MenuItem
              key={`${option.label}-${index}`}
              value={option?.value || ''}
            >
              {_.upperCase(option.label)}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const _renderYearOptions = () => (
    <DatePicker
      inputFormat="YYYY"
      views={['year']}
      label="Year"
      value={details.year || null}
      onChange={handleChangeYear}
      maxDate={new Date()}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={!_.isEmpty(isValidDateMsg)}
          helperText={isValidDateMsg}
          required
          fullWidth
        />
      )}
      disabled
    />
  );

  const _renderOptions = () => {
    return (
      <TextField
        select
        fullWidth
        value={selectedOption}
        label="Parts"
        onChange={(e) => handleChangeOption(e.target.value as TSelectedOption)}
      >
        {_.map(FINDING_MENU, (option, index) => {
          return (
            <MenuItem key={`${option.label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const _renderAssets = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="h2" textTransform="uppercase">
            Attached files
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <UploadImage
                label="Partner logo"
                files={logoState}
                onFileChange={(items: File[]) =>
                  handleFilesChange('logo', items)
                }
                disabled
                type="LOGO"
              />
            </Grid>
            <Grid item xs={4}>
              <DragDropUpload
                label="Upload report in English language"
                files={fileEnglishState}
                onFileChange={(items: File[]) =>
                  handleFilesChange('fileEnglish', items)
                }
                type="PDF"
                disabled
              />
              {!_.isEmpty(details.fileEnglish) && (
                <Stack alignItems="center">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    startIcon={<FileDownloadIcon />}
                    color="oceanGreen"
                    onClick={() => window.open(details.fileEnglish?.path)}
                  >
                    English language
                  </Button>
                </Stack>
              )}
            </Grid>
            <Grid item xs={4}>
              <DragDropUpload
                label="Upload report in local language"
                files={fileLocalState}
                onFileChange={(items: File[]) =>
                  handleFilesChange('fileLocal', items)
                }
                type="PDF"
                disabled
              />
              {!_.isEmpty(details.fileLocal) && (
                <Stack alignItems="center">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    startIcon={<FileDownloadIcon />}
                    color="oceanGreen"
                    onClick={() => window.open(details.fileLocal?.path)}
                  >
                    Local language
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const _renderContent = (type: TSelectedOption) => {
    switch (type) {
      case 'keyFindings':
        return <APExecutiveSummary payload={details?.executive?.key} />;
      case 'CSOverview':
        return <APCSOverview payload={details?.executive?.civil} />;
      case 'findings':
        return (
          <Subareas
            disabled
            payload={details?.finding}
            onUnfocus={(newPayload) => {
              setDetails({ ...details, finding: newPayload });
            }}
            hiddenToolbar
          />
        );
      case 'background':
        return (
          <APBackground
            disabled
            payload={details.backgroundCountry}
            onChange={(newContent: string) =>
              setDetails({ ...details, backgroundCountry: newContent || '' })
            }
          />
        );
      case 'conclusionsAndRecommendations':
        return (
          <APConclusionsAndRecommendations
            disabled
            payload={details.conclusionRecommendation}
            onChange={(newContent: string) =>
              setDetails({
                ...details,
                conclusionRecommendation: newContent || '',
              })
            }
          />
        );
      default:
        return <></>;
    }
  };

  const _renderButtonGroup = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="burntSienna"
          onClick={() => Utils.redirect(ROUTERS.COUNTRY_REPORT_MANAGEMENT)}
          disabled={isActionLoading || isGetLoading}
          startIcon={<ArrowBackIcon />}
        >
          Back to my report
        </Button>
        {details.status && details.status === ENUMS.STATUS.WAITING_PUBLISH && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <LoadingButton
              variant="contained"
              color="fuzzyWuzzyBrown"
              loading={isActionLoading || isGetLoading}
              startIcon={<SaveAsIcon />}
              onClick={() => handleSendBackReviewer()}
            >
              Send back to Reviewer
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="oceanGreen"
              loading={isActionLoading || isGetLoading}
              startIcon={<ScheduleSendIcon />}
              onClick={() => handlePublish()}
            >
              Publish
            </LoadingButton>
          </Box>
        )}
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <ActiveBreadCrumb screen="COUNTRY_REPORT" />
          </Grid>
          {isGetLoading ? (
            <SkeletonLoading numberRender={5} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {_renderCountryReportTitle()}
              </Grid>
              <Grid item xs={6}>
                {_renderCountryOptions()}
              </Grid>
              <Grid item xs={6}>
                {_renderYearOptions()}
              </Grid>
              <Grid item xs={12}>
                {_renderAssets()}
              </Grid>
              <Grid item xs={4}>
                {_renderOptions()}
              </Grid>
              <Grid item xs={12}>
                {_renderContent(selectedOption)}
              </Grid>
              <Grid item xs={12}>
                {_renderButtonGroup()}
              </Grid>
              <Grid item xs={12}>
                <Dialogs.Confirm
                  confirm={confirmSendBackReviewer}
                  onCancel={() => onCancelSendBackReviewer()}
                  callback={() => handleSubmitSendBackReviewer()}
                />
                <Dialogs.Confirm
                  confirm={confirmPublish}
                  onCancel={() => onCancelPublish()}
                  callback={() => handleSubmitPublish()}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default APUpdateCountryReport;
