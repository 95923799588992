import { sendRequest } from '@/Configs';
import { API } from '@constants';

import { ISocial } from '@/Interfaces/SocialLink.interface';

const { SOCIAL_LINKS } = API;

export const fetchSocialLinks = async () => {
  return sendRequest(SOCIAL_LINKS.ROOT, 'GET');
};

export const createSocialLink = async (payload: ISocial) => {
  return sendRequest(SOCIAL_LINKS.CREATE, 'POST', payload);
};
