import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';

import {
  Grid,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ActiveBreadCrumb } from '@/Components/Common';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';
import Utils from '@/Utils';
import { DEFAULT_PAGINATION, ENUMS, ROUTERS } from '@/Constants';
import { RegionalActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';
import { DataTable } from '@/Components/LayoutPart';

const { fetchAllRegionalReports } = RegionalActions;

const { STATUS } = ENUMS;

const STATUS_OPTIONS = [
  { label: 'ALL', value: 'all' },
  {
    label: 'PUBLISH',
    value: STATUS.PUBLISH,
  },
  {
    label: 'ACTIVE',
    value: STATUS.ACTIVE,
  },
  {
    label: 'INACTIVE',
    value: STATUS.INACTIVE,
  },
];

const APRegionalReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [search, setSearch] = useState<any>();
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'pagination')
  );

  useEffect(() => {
    setSearch(pagination);
    dispatch(fetchAllRegionalReports({ ...pagination, status: '' }));
    return () => {
      setSearch(pagination);
    };
  }, []);

  const handleClear = () => {
    setSearch({ ...DEFAULT_PAGINATION, title: '' });
    dispatch(
      fetchAllRegionalReports({
        ...pagination,
        status: '',
        year: '',
        title: '',
      })
    );
  };

  const handleChangeSearch = (key: string, value: string) => {
    dispatch(
      fetchAllRegionalReports({
        ...search,
        title: search.title.trim(),
        [key]: value,
      })
    );
    setSearch({ ...search, [key]: value });
  };

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = Utils.getFormatDate(newValue, 'YYYY');
      if (!dayjs(newValue).isBefore(dayjs()) || +yearSelect < 1900)
        setIsValidDateMsg('Please choose year valid');
      else {
        setIsValidDateMsg('');
        handleChangeSearch('year', yearSelect);
      }
    } else {
      setIsValidDateMsg('');
      const filter = _.omit(search, ['year']);
      dispatch(fetchAllRegionalReports(filter));
      setSearch(filter);
    }
  };

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            label="Search by title"
            placeholder="Search by title"
            value={search?.title}
            onChange={(e: any) =>
              setSearch({ ...search, title: e.target.value })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                dispatch(
                  fetchAllRegionalReports({
                    ...search,
                    title: _.trim(search?.title),
                  })
                );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      dispatch(
                        fetchAllRegionalReports({
                          ...search,
                          title: _.trim(search?.title),
                        })
                      )
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <DatePicker
            inputFormat="YYYY"
            views={['year']}
            label="Year"
            value={search?.year || null}
            onChange={handleChangeYear}
            minDate={new Date('2013')}
            maxDate={new Date()}
            renderInput={(params: any) => (
              <TextField
                sx={{ width: 120 }}
                {...params}
                error={!!isValidDateMsg}
                helperText={isValidDateMsg}
              />
            )}
          />
          <TextField
            select
            sx={{ width: 150 }}
            label="Status"
            value={search?.status || 'all'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeSearch(
                'status',
                e.target.value === 'all' ? '' : e.target.value
              )
            }
          >
            {_.map(STATUS_OPTIONS, (status, index) => {
              return (
                <MenuItem key={index} value={status.value}>
                  {status.label}
                </MenuItem>
              );
            })}
          </TextField>
          <Button
            size="large"
            variant="outlined"
            onClick={() => handleClear()}
            sx={{ height: 56 }}
          >
            Clear Filters
          </Button>
        </Box>
        <Box>
          <Button
            size="large"
            variant="contained"
            color="burntSienna"
            onClick={() => Utils.redirect(ROUTERS.NEW_REPORT)}
          >
            New Report
          </Button>
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ActiveBreadCrumb screen="REGIONAL_REPORT" />
        </Grid>
        <Grid item xs={12}>
          {_renderTopSection()}
        </Grid>
        <Grid item xs={12}>
          <DataTable.RegionalReportTable search={search} />
        </Grid>
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default APRegionalReport;
