import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { TextEditor } from '@/Components/Common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTypedDispatch, RootState } from '@/store';
import { useSelector } from 'react-redux';
import { RegionalActions } from '@/Actions';
import { IFindings, IRecommendations } from '@/Interfaces/RegionalReport';
import { CommonColors } from '@/Themes';
import { KEY_FINDINGS } from '@/Constants/RegionalReport';

const { saveDefaultRecommendations, saveDefaultFindings } = RegionalActions;

interface IPropsRecommendation {
  onUnfocus(value: IRecommendations): void;
}
const Recommendations: React.FC<IPropsRecommendation> = ({ onUnfocus }) => {
  const dispatch = useTypedDispatch();
  const executive: string = useSelector(
    (state: RootState) =>
      _.get(state.REGIONAL_REPORT, 'defaultRecommendations').executive
  );
  const findingsRecommendations: IFindings[] = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultFindingsRecommendations')
  );

  const handleOnchangeExecutive = (value: string) => {
    const newRecommendations = {
      findings: findingsRecommendations,
      executive: value,
    };
    dispatch(saveDefaultRecommendations(newRecommendations));
  };

  const handleAddMore = () => {
    const newAddmore = [
      ...findingsRecommendations,
      { findings: '', recommentdations: '' },
    ];
    dispatch(saveDefaultFindings(newAddmore));
  };

  const handleOnChange = (contentKey: string, value: string, key: number) => {
    const newForm = _.map(findingsRecommendations, (item, index) => {
      if (index === key) {
        return {
          ...item,
          [contentKey]: value,
        };
      }
      return item;
    });
    dispatch(saveDefaultFindings(newForm));
  };

  const handRemove = (key: number) => {
    const newForm = _.filter(
      findingsRecommendations,
      (_item, index) => index !== key
    );
    dispatch(saveDefaultFindings(newForm));
  };

  useEffect(() => {
    onUnfocus({
      findings: findingsRecommendations,
      executive: executive,
    });
  }, [executive, findingsRecommendations]);

  const _renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell />
        {_.map(KEY_FINDINGS, (item) => (
          <TableCell align="center" key={`head-${item.value}`}>
            <Typography variant="h6" fontSize="1rem" fontWeight="bold">
              {item.label}
            </Typography>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    );
  };

  const _renderTableBody = () => {
    if (!_.isArray(findingsRecommendations)) return null;
    return _.map(findingsRecommendations, (item, index) => {
      return (
        <TableRow key={index}>
          <TableCell sx={{ with: 10, p: 0 }} align="center">
            <Typography variant="h6">{index + 1}</Typography>
          </TableCell>
          {_.map(KEY_FINDINGS, (head, innerKey) => (
            <TableCell align="center" key={`row${innerKey}-${index}`}>
              <TextField
                fullWidth
                variant="outlined"
                value={_.get(item, head.value)}
                rows={2}
                multiline
                onChange={(e) =>
                  handleOnChange(head.value, e.target.value, index)
                }
              />
            </TableCell>
          ))}
          <TableCell sx={{ p: 0, width: 10 }}>
            <Button onClick={() => handRemove(index)}>
              <ClearIcon color="error" />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              Key Findings and Key Recommendations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead>{_renderTableHeader()}</TableHead>
              <TableBody>{_renderTableBody()}</TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                sx={{
                  background: CommonColors.mainColor,
                  borderRadius: '20px',
                  '&:hover': {
                    backgroundColor: CommonColors.mainColor,
                  },
                }}
                variant="contained"
                onClick={() => handleAddMore()}
              >
                Add Key
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Executive Summary</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextEditor
              value={executive}
              disabled={false}
              onChange={(newContent: string) =>
                handleOnchangeExecutive(newContent)
              }
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Recommendations;
