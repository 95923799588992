import {
  ACTION_TYPES,
  DEFAULT_LOADING_STATES,
  DEFAULT_PAGINATION,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  countries: [],
  avaiableCountries: [],
  pagination: DEFAULT_PAGINATION,
  meta: null,
  countryTable: [],
  countryDetail: null,
  unassignedForReview: [],
  unassignedForUser: [],
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_COUNTRY_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COUNTRY_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COUNTRY_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_COUNTRY_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        countries: payload,
      };
    case ACTION_TYPES.FETCH_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.FETCH_ALL_AVAIABLE_COUNTRY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        avaiableCountries: payload,
      };
    case ACTION_TYPES.FETCH_ALL_AVAIABLE_COUNTRY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.SET_COUNTRY_TABLE_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_COUNTRY_TABLE_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isFetchLoading: false,
        countryTable: payload,
      };
    case ACTION_TYPES.FETCH_COUNTRY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_COUNTRY_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        countryDetail: payload,
      };
    case ACTION_TYPES.GET_COUNTRY_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        userProfile: payload,
        isActionLoading: false,
      };
    case ACTION_TYPES.UPDATE_COUNTRY_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        unassignedForReview: payload,
      };
    case ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_REVIEW_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        unassignedForUser: payload,
      };
    case ACTION_TYPES.FETCH_COUNTRY_UNASSIGNED_FOR_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
