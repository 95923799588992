import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  Typography,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
  Tooltip,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { SkeletonLoading } from '@/Components/Common';

import { useTypedDispatch, RootState } from '@/store';
import { NotificationActions } from '@/Actions';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';
import { INotificationStructure } from '@/Interfaces/Notification.interface';

const { fetchNotifications, markAsReadNotifications } = NotificationActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: INotificationStructure[];
  width?: string;
  authorEl?: 'left' | 'right' | 'top' | 'bottom';
}

const UserNotifications: React.FC<SectionProps> = ({
  open,
  onClose,
  width,
  payload,
  authorEl = 'left',
}) => {
  const dispatch = useTypedDispatch();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.USER_NOTIFICATION, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.USER_NOTIFICATION, 'isActionLoading')
  );

  const count: { read: number; unread: number } = useSelector(
    (state: RootState) => _.get(state.USER_NOTIFICATION, 'count')
  );

  const handleMaskAtRead = () => {
    const formData = new FormData();
    formData.append('markAll', JSON.stringify(1));
    dispatch(markAsReadNotifications(formData, true));
    dispatch(fetchNotifications());
  };

  const handleViewDetails = (id: string, recordId: string, type: string) => {
    const formData = new FormData();
    const newPayload = [];
    newPayload.push(id);
    formData.append('notificationIds[]', id);
    dispatch(markAsReadNotifications(formData, false, recordId, type));
    onClose();
  };

  const _renderNotifications = () => {
    return (
      <Drawer
        anchor={authorEl}
        style={{ zIndex: 10000 }}
        open={open}
        onClose={onClose}
        PaperProps={{ style: { width: width || '25%' } }}
      >
        <Grid container spacing={2} sx={{ px: 2 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  pt: 2,
                  pb: 2,
                }}
              >
                <NotificationsActiveIcon
                  sx={{ fontSize: '25px', color: CommonColors.mainColor }}
                />
                <Typography variant="h6">Notifications</Typography>
              </Box>

              <Button
                disabled={count.unread === 0 || _.isEmpty(payload)}
                startIcon={<DoneAllIcon />}
                sx={{ textTransform: 'capitalize' }}
                onClick={() => handleMaskAtRead()}
              >
                Read all
              </Button>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {isGetLoading || isActionLoading ? (
              <SkeletonLoading numberRender={5} />
            ) : !_.isEmpty(payload) ? (
              <List>
                {_.map(
                  payload,
                  (notification: INotificationStructure, index) => (
                    <ListItem
                      key={notification.id || index}
                      disablePadding
                      onClick={() =>
                        handleViewDetails(
                          notification.id,
                          notification.recordId,
                          notification?.type || ''
                        )
                      }
                    >
                      <Tooltip title="Click to view details">
                        <ListItemButton>
                          <FiberManualRecordIcon
                            sx={{
                              mr: 1,
                              color:
                                notification.status === 'read'
                                  ? CommonColors.alto
                                  : CommonColors.hippieBlue,
                            }}
                          />
                          <ListItemText
                            primary={notification?.content}
                            secondary={`${Utils.getFormatDate(
                              notification?.createdAt
                            )}`}
                          />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  )
                )}
              </List>
            ) : (
              <Box component="p" sx={{ fontSize: '14px' }}>
                You do not have any new notifications
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider />
      </Drawer>
    );
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center" justifyContent="center">
          {_renderNotifications()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserNotifications;
