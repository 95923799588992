import React, { useState } from 'react';
import _ from 'lodash';

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { CommonColors } from '@/Themes';

import { labelStyles } from './CollapseContent.styles';

type TTitleCollapse = 'MANDATORY' | 'ADDIONTIONAL';
interface ISectionProps {
  payload: string[];
  type: TTitleCollapse;
}

const CollapseContent: React.FC<ISectionProps> = ({ payload, type }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const _renderTitle = (t: TTitleCollapse) => {
    switch (t) {
      case 'MANDATORY':
        return (
          <Box sx={labelStyles}>
            <WarningAmberIcon />
            <Typography sx={{ fontWeight: 'bold' }}>
              Mandatory data to be collected:
            </Typography>
          </Box>
        );
      case 'ADDIONTIONAL':
        return (
          <Box sx={labelStyles}>
            <ErrorOutlineIcon />
            <Typography sx={{ fontWeight: 'bold' }}>
              {' '}
              Additional data to be collected:
            </Typography>
          </Box>
        );
      default:
        return '';
    }
  };

  const _renderContent = () => (
    <Accordion
      sx={{
        background:
          type === 'ADDIONTIONAL'
            ? CommonColors.hippieBlue
            : CommonColors.fuzzyWuzzyBrown,
      }}
      expanded={expanded}
      onClick={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {_renderTitle(type)}
      </AccordionSummary>
      <AccordionDetails>
        <List component="nav">
          {_.map(payload, (item: string, index: number) => {
            return (
              <ListItemButton key={index}>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ color: 'white' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ sx: { color: 'white' } }}
                  primary={item}
                />
              </ListItemButton>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );

  return <Box>{_renderContent()}</Box>;
};

export default CollapseContent;
