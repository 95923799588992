import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Grid,
  Stepper,
  Step,
  StepButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  // Box,
  Hidden,
} from '@mui/material';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import MMToolkitDialog from './MMToolkitDialog';

import { MMToolkitActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';

import {
  IMMToolkitStandar,
  IMMToolkitTemplate,
  TStepCode,
  IMMToolkitSubArea,
} from '@interfaces/CountryScoring.interface';
import {
  NoDataWereFound,
  PreviewPDF,
  SkeletonLoading,
} from '@/Components/Common';

import AboutNav from './../AboutNav';
import { MM_TOOLKIT_USER_OPTIONS } from '@/Constants/CountryScoring';

const DEFAULT_STANDARD_DETAILS = {
  code: '',
  codeName: '',
  name: '',
  legislation: '',
  practice: '',
};

const { getMMToolkit } = MMToolkitActions;

const MMToolkit: React.FC = () => {
  const dispatch = useTypedDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.MM_TOOLKIT, 'isGetLoading')
  );

  const mmToolkitContent: IMMToolkitTemplate | null = useSelector(
    (state: RootState) => _.get(state.MM_TOOLKIT, 'mmToolkitContent')
  );

  const [selectedCode, setSelectedCode] = useState<TStepCode>('area_1');
  const [isOpen, setIsOpen] = useState(false);
  const [standards, setStandards] = useState<IMMToolkitStandar[]>([]);
  const [selectedStardands, setSelectedStardands] = useState<
    IMMToolkitStandar[]
  >([]);
  const [selectedStardand, setSelectedStardand] = useState<IMMToolkitStandar>(
    DEFAULT_STANDARD_DETAILS
  );
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    dispatch(getMMToolkit());
  }, []);

  useEffect(() => {
    const getContent = _.get(mmToolkitContent, 'content');
    const content = _.isString(getContent) ? JSON.parse(getContent) : null;
    setFile(_.get(mmToolkitContent, 'file'));
    setStandards(content);
  }, [mmToolkitContent]);

  const handleFillForm = (
    originStandards: IMMToolkitStandar[],
    standard: IMMToolkitStandar
  ) => {
    setSelectedStardands(originStandards);
    setSelectedStardand(standard);
    setIsOpen(true);
  };

  const _renderSteps = useCallback(() => {
    const findStep = _.findIndex(
      MM_TOOLKIT_USER_OPTIONS,
      (step) => step.value === selectedCode
    );
    return (
      <Stepper
        nonLinear
        sx={{ width: 1, mb: 2 }}
        alternativeLabel
        activeStep={findStep || 0}
      >
        {_.map(MM_TOOLKIT_USER_OPTIONS, (step) => {
          return (
            <Step key={step.value}>
              <StepButton onClick={() => setSelectedCode(step.value)}>
                {step.label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    );
  }, [standards, selectedCode, matches]);

  const _renderStandards = (originStandards: IMMToolkitStandar[]) => {
    const OFFSET_SIZE = 4;
    const resizeColumn = _.size(originStandards) === OFFSET_SIZE;

    return _.map(
      originStandards,
      (standard: IMMToolkitStandar, index: number) => {
        return (
          <Grid
            item
            xs={12}
            md={4}
            key={standard.code || index}
            sx={{
              position: 'relative',
              mr: index === 2 && resizeColumn ? '1px' : 0,
              right: '-1px',
            }} //grid breaks standard 3-4
          >
            <Card>
              <CardContent sx={{ height: 160 }}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                  // sx={{
                  //   fontSize: 20,
                  //   mb: 2,
                  //   height: 47,
                  //   overflow: 'hidden',
                  //   textOverflow: 'ellipsis',
                  //   display: '-webkit-box',
                  //   WebkitLineClamp: '2',
                  //   WebkitBoxOrient: 'vertical',
                  // }}
                >
                  {standard.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, fontSize: 12 }}
                  pl={2}
                  pt={1}
                  textAlign="center"
                >
                  ({standard.codeName})
                </Typography>
              </CardContent>
              <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  onClick={() => handleFillForm(originStandards, standard)}
                >
                  View Indicators
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      }
    );
  };

  const _renderSubAreas = (areas: IMMToolkitSubArea[]) => {
    return _.map(areas, (area: IMMToolkitSubArea, index: number) => {
      return (
        <Grid item key={area.code || index}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant={matches ? 'h5' : 'h6'}>
                {area.name}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: 13, ml: 2 }}
              >
                {area.principle}
              </Typography>
            </Grid>
            <Divider variant="middle" />
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {_renderStandards(area.standards)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const _renderContent = () => {
    const selectedStep =
      (_.find(standards, ['code', selectedCode]) as any) ||
      selectedCode === 'preview_pdf';
    if (!selectedStep) return <NoDataWereFound />;
    else {
      if (selectedCode === 'preview_pdf') {
        if (!file) return <NoDataWereFound />;
        return (
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => window.open(file?.path)}
                sx={{ mb: 2 }}
              >
                View Full Screen
              </Button>
            </Grid>
            <Grid item xs={12}>
              <PreviewPDF file={file?.path} />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container direction="column" spacing={3}>
            {_renderSubAreas(selectedStep.subAreas)}
          </Grid>
        );
      }
    }
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BreadCrumb title="The Monitoring Matrix (Tool-kit)" />
        </Grid>
        <Hidden mdDown>
          <Grid item md={12} lg={2}>
            <AboutNav />
          </Grid>
        </Hidden>
        {!isGetLoading ? (
          <Grid item md={12} lg={10}>
            {mmToolkitContent ? (
              <Grid item xs={12} sx={{ minHeight: 600 }}>
                <Grid item xs={12}>
                  <MMToolkitDialog
                    open={isOpen}
                    standardActive={selectedStardand}
                    standards={selectedStardands}
                    handleClose={() => setIsOpen(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  {_renderSteps()}
                </Grid>
                <Grid item xs={12}>
                  {_renderContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <NoDataWereFound />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <SkeletonLoading numberRender={5} />
          </Grid>
        )}
      </Grid>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default MMToolkit;
