import React from 'react';
import { Box } from '@mui/material';

interface IPreviewPDFStructure {
  file?: string;
  style?: object;
}

const PreviewPDF: React.FC<IPreviewPDFStructure> = ({ file }) => {
  return (
    <Box
      component="object"
      data={file}
      type="application/pdf"
      sx={{ width: 1, height: '700px' }}
    >
      <iframe
        width="100%"
        height="700px"
        src={`https://docs.google.com/viewer?url=${file}&embedded=true`}
      />
    </Box>
  );
};
export default PreviewPDF;
