import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Typography, TextField } from '@mui/material';
import { ICivilSocietyOverviewTableStructure } from '@/Interfaces/CountryReport.interface';
import { DEFAULT_LABEL_CIVIL_SOCIETY } from '@/Constants/CountryScoring';

interface ISectionProps {
  payload: ICivilSocietyOverviewTableStructure;
  onChange(newPayload: ICivilSocietyOverviewTableStructure): void;
  disabled?: boolean;
}

const DEFAULT_DETAILS = {
  numberOfRegistered: '',
  mainCivil: '',
  relevant: '',
  stateFunding: '',
  humanResources: '',
  cso: '',
  otherkey: '',
};

const CSOverview: React.FC<ISectionProps> = ({
  payload,
  onChange,
  disabled,
}) => {
  const [details, setDetails] = useState<ICivilSocietyOverviewTableStructure>(
    payload || DEFAULT_DETAILS
  );

  useEffect(() => {
    if (!_.isEmpty(payload)) setDetails(payload);
  }, [payload]);

  useEffect(() => {
    if (details) onChange(details);
  }, [details]);

  const handleChange = (key: string, value: string) =>
    setDetails({ ...details, [key]: value });

  const _renderMain = () => {
    return (
      <Grid container spacing={2}>
        {details &&
          _.map(Object.keys(details), (item, index) => {
            return (
              <Grid
                item
                xs={index === _.size(details) - 1 ? 12 : 6}
                key={index}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {
                    DEFAULT_LABEL_CIVIL_SOCIETY[
                      item as keyof typeof DEFAULT_LABEL_CIVIL_SOCIETY
                    ]
                  }
                </Typography>
                <TextField
                  disabled={disabled}
                  value={details[item as keyof typeof details]}
                  fullWidth
                  variant="outlined"
                  rows={3}
                  multiline
                  onChange={(e) => handleChange(item, e.target.value)}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return _renderMain();
};

export default CSOverview;
