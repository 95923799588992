import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: { ...DEFAULT_PAGINATION, sortBy: 'year', orderBy: 'DESC' },
  meta: null,
  payload: [],
  details: {},
  getForVisitor: {},
  lastedNewsByCountry: [],
  previous: [],
  chartForYear: null,
  chartData: null,
  defaultSubareas: [],
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_COUNTRY_REPORT_TABLE_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_COUNTRY_REPORT_TABLE_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_COUNTRY_REPORTS_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COUNTRY_REPORTS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
        getForVisitor: {},
      };
    }
    case ACTION_TYPES.SET_COUNTRY_REPORTS_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_COUNTRY_REPORT_PREVIOUS: {
      return {
        ...state,
        previous: payload,
      };
    }
    case ACTION_TYPES.SET_DATA_FINDINGS:
      return {
        ...state,
        defaultSubareas: payload,
      };
    case ACTION_TYPES.RESET_COUNTRY_REPORTS_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_COUNTRY_REPORTS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        payload,
      };
    case ACTION_TYPES.FETCH_COUNTRY_REPORTS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.CREATE_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        payload,
      };
    case ACTION_TYPES.CREATE_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.GET_BY_ID_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        details: payload,
      };
    case ACTION_TYPES.GET_BY_ID_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.UPDATE_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
        details: payload,
      };
    case ACTION_TYPES.UPDATE_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DEACTIVE_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DEACTIVE_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.TERMINATE_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.TERMINATE_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.PUBLISH_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.PUBLISH_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.UNPUBLISH_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.UNPUBLISH_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.GET_FOR_VISITOR_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        getForVisitor: payload?.getForVisitor,
        previous: payload?.getPrevious,
        chartForYear: payload?.getCharts,
        chartData: payload?.chartData,
        lastedNewsByCountry: payload?.getNews,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.GET_FOR_VISITOR_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        getForVisitor: {},
        previous: [],
        chartForYear: null,
        chartData: [],
        lastedNewsByCountry: [],
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    default:
      return state;
  }
};
