import React from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { CommonColors } from '@/Themes';

const DEFAULT_CHART_SCORING = [
  {
    title: '0 – 20 Fully disabling',
    color: CommonColors.red,
  },
  {
    title: '20 – 40 Disabling',
    color: CommonColors.orange,
  },
  {
    title: '40 – 60 Partially enabling',
    color: CommonColors.yellow,
  },
  { title: '60 – 80 Enabling', color: CommonColors.limeGreen },
  {
    title: '80 - 100 Fully enabling',
    color: CommonColors.forestGreen,
  },
];

interface SectionProps {
  mainStyles?: object;
  labelStyles?: object;
}

const ChartScoring: React.FC<SectionProps> = ({ mainStyles, labelStyles }) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        mt: 2,
        gap: 2,
        ...mainStyles,
      }}
    >
      {_.map(DEFAULT_CHART_SCORING, (subItem, index) => {
        return (
          <Box
            key={index}
            sx={{
              background: subItem.color,
              textAlign: 'center',
              fontSize: 12,
              fontWeight: 'bold',
              p: 1,
              color: CommonColors.black,
              ...labelStyles,
            }}
          >
            {subItem.title}
          </Box>
        );
      })}
    </Box>
  );
};
export default ChartScoring;
