import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: { ...DEFAULT_PAGINATION, sortBy: 'year', orderBy: 'DESC' },
  meta: null,
  countryReportList: [],
  countryReportDetail: null,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_REVIEWER_COUNTRY_REPORT_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_REVIEWER_COUNTRY_REPORT_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_REVIEWER_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        countryReportList: payload,
      };
    case ACTION_TYPES.FETCH_REVIEWER_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.FETCH_ALL_REVIEWER_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        countryReportList: payload,
      };
    case ACTION_TYPES.FETCH_ALL_REVIEWER_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_REVIEWER_COUNTRY_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        countryReportDetail: payload,
      };
    case ACTION_TYPES.GET_REVIEWER_COUNTRY_REPORT_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.EDITTING_REVIEWER_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.EDITTING_REVIEWER_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.SEND_APPROVE_REVIEWER_COUNTRY_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.SEND_APPROVE_REVIEWER_COUNTRY_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
