import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { PaginationActions } from '@/Components/Common';
import { UserManagementActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ENUMS, ROUTERS, DEFAULT_CONFIRM } from '@constants';
import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import Utils from '@/Utils';
import Dialogs from '../Dialogs';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const { USER_TABLE_HEADER } = TABLES;

const { fetchUsers, deactiveUser, activeUser, terminateUser } =
  UserManagementActions;

const UserTable: React.FC = () => {
  const dispatch = useTypedDispatch();
  const userTable: any =
    useSelector((state: RootState) =>
      _.get(state.USER_MANAGEMENT, 'userTable')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'isFetchLoading')
  );
  const colSpan = USER_TABLE_HEADER.length + 2;
  const [confirmChangeStatus, setConfirmChangeStatus] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [confirmDelete, setConfirmDelete] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(fetchUsers({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchUsers({ ...pagination, limit: perPage }));
  };

  const handleChangeStatus = (status: string, id: string) => {
    const statusLabel = status === ENUMS.STATUS.ACTIVE ? 'deactive' : 'active';
    setConfirmChangeStatus({
      isOpen: true,
      message: `This will ${statusLabel} this user.`,
      state: {
        id,
        status,
      },
    });
  };

  const handleDelete = (state: { id?: string; status: string }) => {
    const { id } = state;
    if (id) dispatch(terminateUser(id, pagination));
    setConfirmDelete({ ...confirmDelete, isOpen: false });
  };

  const handleSubmit = (state: { id?: string; status: string }) => {
    const { id, status } = state;
    if (id && status === ENUMS.STATUS.INACTIVE) {
      dispatch(activeUser(id, pagination));
    } else {
      if (id) dispatch(deactiveUser(id, pagination));
    }
    setConfirmChangeStatus({ ...confirmChangeStatus, isOpen: false });
  };

  const onCancel = (type: 'status' | 'delete') => {
    if (type === 'status') {
      setConfirmChangeStatus({ ...confirmChangeStatus, isOpen: false });
    } else {
      setConfirmDelete({ ...confirmDelete, isOpen: false });
    }
    dispatch(fetchUsers(pagination));
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        {_.map(USER_TABLE_HEADER, (item) => (
          <TableCell key={`head-${item.value}`} sx={{ fontWeight: '700' }}>
            {item.label}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: '700' }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [USER_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(userTable))
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            There is no user(s) to display
          </TableCell>
        </TableRow>
      );
    return _.map(userTable, (item, index) => {
      const IS_ADMIN = _.get(item, 'role.roleCode') === ENUMS.ROLES.ADMIN;
      return (
        <TableRow hover key={index}>
          {_.map(USER_TABLE_HEADER, (head, innerKey) => {
            if (head.value && head.value === 'countryReview') {
              const countryReview = _.get(item, head.value);
              if (!_.isEmpty(countryReview)) {
                return (
                  <TableCell key={`row${innerKey}-${index}`}>
                    {_.map(countryReview, (country) => country.name).join(', ')}
                  </TableCell>
                );
              }
            }
            return (
              <TableCell key={`row${innerKey}-${index}`}>
                {head?.value === 'status' || head?.value === 'role.roleCode' ? (
                  <Typography
                    variant="subtitle1"
                    textTransform="uppercase"
                    sx={{
                      fontSize: '0.875rem',
                      color:
                        (head?.value === 'status' &&
                          _.get(TableStatusColor, _.get(item, head.value))) ||
                        CommonColors.black,
                    }}
                  >
                    {_.get(item, head.value)}
                  </Typography>
                ) : !_.isEmpty(_.get(item, head.value)) ? (
                  _.get(item, head.value)
                ) : (
                  'N/I'
                )}
              </TableCell>
            );
          })}
          <TableCell key={`action.${index}`}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="View Profile">
                <IconButton
                  sx={{ color: CommonColors.bismark }}
                  onClick={() => {
                    Utils.redirect(ROUTERS.UPDATE_USER, { id: item.id });
                  }}
                >
                  <PreviewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  item?.status === ENUMS.STATUS.ACTIVE ? 'Deactive' : 'Active'
                }
              >
                <Switch
                  disabled={IS_ADMIN || item.status === ENUMS.STATUS.TERMINATED}
                  key={`active.${index}`}
                  defaultChecked={item?.status === ENUMS.STATUS.ACTIVE}
                  color="success"
                  onChange={() =>
                    IS_ADMIN || item.status === ENUMS.STATUS.TERMINATED
                      ? {}
                      : handleChangeStatus(item?.status, item.id)
                  }
                />
              </Tooltip>
              <Tooltip title="Terminated user">
                <>
                  <IconButton
                    disabled={
                      IS_ADMIN || item.status === ENUMS.STATUS.TERMINATED
                    }
                    sx={{ color: CommonColors.bismark }}
                    onClick={() =>
                      setConfirmDelete({
                        isOpen: true,
                        message: `This will permanently terminated this user.`,
                        state: {
                          id: item.id,
                          status: '',
                        },
                      })
                    }
                  >
                    <DeleteOutlineIcon
                      color={
                        IS_ADMIN || item.status === ENUMS.STATUS.TERMINATED
                          ? 'inherit'
                          : 'error'
                      }
                    />
                  </IconButton>
                </>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan - 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Dialogs.Confirm
        confirm={confirmChangeStatus}
        onCancel={() => onCancel('status')}
        callback={() => handleSubmit(confirmChangeStatus.state)}
      />
      <Dialogs.Confirm
        title="Are you sure you want to terminated this user?"
        confirm={confirmDelete}
        onCancel={() => onCancel('delete')}
        callback={() => handleDelete(confirmDelete.state)}
      />
      {_renderTable()}
    </Box>
  );
};

export default UserTable;
