import React from 'react';
import _ from 'lodash';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IMandatoryField {
  messages?: any[];
  title?: string;
  isOpen?: boolean;
  onCancel?(): void;
}

const MandatoryField: React.FC<IMandatoryField> = (props: IMandatoryField) => {
  const {
    isOpen = false,
    messages,
    onCancel,
    title = 'Please fill all the mandatory fields',
  } = props;
  return (
    <Dialog open={isOpen}>
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 350 }}
      >
        <DialogContentText>
          {
            <WarningAmberIcon
              fontSize="large"
              sx={{
                color: 'orange',
              }}
            />
          }
        </DialogContentText>
        {title}
      </DialogTitle>
      <DialogContent>
        {messages &&
          _.map(messages, (message, index) => (
            <Typography variant="body1" key={`message${index}`}>
              {message}
            </Typography>
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="fuzzyWuzzyBrown" onClick={onCancel}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MandatoryField;
