import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  yearBarCharts: [],
  countriesBarCharts: [],
  previousYears: {
    countryId: '',
    collapsedSubAreaOfYear: [],
    listStandardsOfYear: [],
    listYears: [],
  },
  previousCountries: {
    listCountries: [],
    year: '',
    collapsedSubAreaOfCountries: [],
    listStandardsOfCountries: [],
    listCountriesName: [],
  },
};

const RESOLVED_DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  yearBarCharts: [],
  countriesBarCharts: [],
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_COMPARE_AND_EXPORE_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_COMPARE_EXPLORE_REDUCER:
      return payload
        ? DEFAULT_STATES
        : { ...state, ...RESOLVED_DEFAULT_STATES };
    case ACTION_TYPES.SAVE_COMPARE_EXPLORE_BY_YEARS:
      return { ...state, previousYears: payload };
    case ACTION_TYPES.SAVE_COMPARE_EXPLORE_BY_COUNTRIES:
      return { ...state, previousCountries: payload };

    case ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_YEARS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        yearBarCharts: payload,
      };
    case ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_YEARS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
        yearBarCharts: [],
      };
    case ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_COUNTRIES_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        countriesBarCharts: payload,
        isFetchLoading: false,
      };
    case ACTION_TYPES.FETCH_COMPARE_EXPLORE_BY_COUNTRIES_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
        countriesBarCharts: [],
      };
    default:
      return state;
  }
};
