import {
  IDropdownOption,
  IDropdownArea,
  IDropdownAreaUser,
} from '@interfaces/Dropdown.interface';
import ENUMS from '../Enums';

const GENDER: IDropdownOption[] = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

const COUNTRY_REPORT_LIST: IDropdownArea[] = [
  {
    label: 'Area 1: Basic Legal Guarantees of Freedoms',
    value: 'area_1',
    subAreas: [
      {
        label: 'Sub-Area 1.1: FREEDOM OF ASSOCIATION',
        value: 'area_1_sub_area_1_1',
      },
      {
        label: 'Sub-Area 1.2: RELATED FREEDOMS',
        value: 'area_1_sub_area_1_2',
      },
    ],
  },
  {
    label: 'Area 2: Framework for CSO Financial Viability and Sustainability',
    value: 'area_2',
    subAreas: [
      {
        label: 'Sub-Area 2.1: TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
        value: 'area_2_sub_area_2_1',
      },
      {
        label: 'Sub-Area 2.2: STATE SUPPORT',
        value: 'area_2_sub_area_2_2',
      },
      {
        label: 'Sub-Area 2.3: HUMAN RESOURCES',
        value: 'area_2_sub_area_2_3',
      },
    ],
  },
  {
    label: 'Area 3: Government-CSO Relationship',
    value: 'area_3',
    subAreas: [
      {
        label: 'Sub-Area 3.1: FRAMEWORK AND PRACTICES FOR COOPERATION',
        value: 'area_3_sub_area_3_1',
      },
      {
        label:
          'Sub-Area 3.2: INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
        value: 'area_3_sub_area_3_2',
      },
      {
        label: 'Sub-Area 3.3: COLLABORATION IN SERVICE PROVISION',
        value: 'area_3_sub_area_3_3',
      },
    ],
  },
];

const ROLE_LIST: IDropdownOption[] = [
  {
    label: 'Admin',
    value: ENUMS.ROLES.ADMIN,
  },
  {
    label: 'Reviewer',
    value: ENUMS.ROLES.REVIEWER,
  },
  {
    label: 'User',
    value: ENUMS.ROLES.USER,
  },
];

const ROLE_LIST_ADMIN: IDropdownOption[] = [
  {
    label: 'Reviewer',
    value: ENUMS.ROLES.REVIEWER,
  },
  {
    label: 'User',
    value: ENUMS.ROLES.USER,
  },
];

const ORDER_BY_LIST: IDropdownOption[] = [
  {
    label: 'ASC',
    value: 'ASC',
  },
  {
    label: 'DESC',
    value: 'DESC',
  },
];

const USER_STATUS_LIST: IDropdownOption[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: 'ACTIVE',
    value: ENUMS.STATUS.ACTIVE,
  },
  {
    label: 'INACTIVE',
    value: ENUMS.STATUS.INACTIVE,
  },
  // {
  //   label: 'TERMINATED',
  //   value: ENUMS.STATUS.TERMINATED,
  // },
];

const NEWS_STATUS_LIST: IDropdownOption[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: 'PUBLISH',
    value: ENUMS.STATUS.PUBLISH,
  },
  {
    label: 'ACTIVE',
    value: ENUMS.STATUS.ACTIVE,
  },
  {
    label: 'INACTIVE',
    value: ENUMS.STATUS.INACTIVE,
  },
];

const NEWS_CATEGORY_LIST: IDropdownOption[] = [
  {
    label: 'Uncategorized',
    value: 'Uncategorized',
  },
  {
    label: 'Albania',
    value: 'Albania',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    label: 'Kosovo',
    value: 'Kosovo',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
  },
  {
    label: 'Macedonia',
    value: 'Macedonia',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Regional news',
    value: 'Regional news',
  },
  {
    label: 'BCSDN news',
    value: 'BCSDN news',
  },
];

const GALLERY_STATUS_LIST: IDropdownOption[] = [
  {
    label: 'ACTIVE',
    value: ENUMS.STATUS.ACTIVE,
  },
  {
    label: 'INACTIVE',
    value: ENUMS.STATUS.INACTIVE,
  },
];

const REGIONAL_REPORT_LIST: IDropdownArea[] = [
  {
    label: 'Area 1: Basic Legal Guarantees of Freedoms',
    value: 'area_1',
    subAreas: [
      {
        label: 'Sub-Area 1.1: FREEDOM OF ASSOCIATION',
        value: 'area_1_sub_area_1_1',
      },
      {
        label: 'Sub-Area 1.2: RELATED FREEDOMS',
        value: 'area_1_sub_area_1_2',
      },
    ],
  },
  {
    label: 'Area 2: Framework for CSO Financial Viability and Sustainability',
    value: 'area_2',
    subAreas: [
      {
        label: 'Sub-Area 2.1: TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
        value: 'area_2_sub_area_2_1',
      },
      {
        label: 'Sub-Area 2.2: STATE SUPPORT',
        value: 'area_2_sub_area_2_2',
      },
      {
        label: 'Sub-Area 2.3: HUMAN RESOURCES',
        value: 'area_2_sub_area_2_3',
      },
    ],
  },
  {
    label: 'Area 3: Government-CSO Relationship',
    value: 'area_3',
    subAreas: [
      {
        label: 'Sub-Area 3.1: FRAMEWORK AND PRACTICES FOR COOPERATION',
        value: 'area_3_sub_area_3_1',
      },
      {
        label:
          'Sub-Area 3.2: INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
        value: 'area_3_sub_area_3_2',
      },
      {
        label: 'Sub-Area 3.3: COLLABORATION IN SERVICE PROVISION',
        value: 'area_3_sub_area_3_3',
      },
    ],
  },
];

const REGIONAL_REPORT_LIST_DATA: IDropdownArea[] = [
  {
    label: 'Area 1: Basic Legal Guarantees of Freedoms',
    value: 'area_1',
    subAreas: [
      {
        label: 'Sub-Area 1.1: FREEDOM OF ASSOCIATION',
        value: 'area_1_sub_area_1_1',
        standards: [
          {
            label: 'Standard 1: Establishment of and Participation in CSOs',
            value: 'area_1_sub_area_1_1_standard_1',
          },
          {
            label: 'Standard 2: State Interference',
            value: 'area_1_sub_area_1_1_standard_2',
          },
          {
            label: 'Standard 3: Securing Financial Resources',
            value: 'area_1_sub_area_1_1_standard_3',
          },
        ],
      },
      {
        label: 'Sub-Area 1.2: STATE INTERFERENCE',
        value: 'area_1_sub_area_1_2',
        standards: [
          {
            label: 'Standard 1: Freedom of Peaceful Assembly',
            value: 'area_1_sub_area_1_2_standard_1',
          },
          {
            label: 'Standard 2: Freedom of Expression',
            value: 'area_1_sub_area_1_2_standard_2',
          },
          {
            label: 'Standard 3: Access to Information',
            value: 'area_1_sub_area_1_2_standard_3',
          },
        ],
      },
    ],
  },
  {
    label: 'Area 2: Framework for CSO Financial Viability and Sustainability',
    value: 'area_2',
    subAreas: [
      {
        label: 'Sub-Area 2.1: TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
        value: 'area_2_sub_area_2_1',
        standards: [
          {
            label: 'Standard 1: Tax Benefits',
            value: 'area_2_sub_area_2_1_standard_1',
          },
          {
            label: 'Standard 2: Incentives for Individual/Corporate Giving',
            value: 'area_2_sub_area_2_1_standard_2',
          },
        ],
      },
      {
        label: 'Sub-Area 2.2: STATE SUPPORT',
        value: 'area_2_sub_area_2_2',
        standards: [
          {
            label: 'Standard 1: Public Funding Availability',
            value: 'area_2_sub_area_2_2_standard_1',
          },
          {
            label: 'Standard 2: Public Funding Distribution',
            value: 'area_2_sub_area_2_2_standard_2',
          },
          {
            label:
              'Standard 3: Accountability, Monitoring and Evaluation of Public Funding',
            value: 'area_2_sub_area_2_2_standard_3',
          },
          {
            label: 'Standard 4: Non-Financial Support',
            value: 'area_2_sub_area_2_2_standard_4',
          },
        ],
      },
      {
        label: 'Sub-Area 2.3: HUMAN RESOURCES',
        value: 'area_2_sub_area_2_3',
        standards: [
          {
            label: 'Standard 1: Employment in CSOs',
            value: 'area_2_sub_area_2_3_standard_1',
          },
          {
            label: 'Standard 2: Volunteering in CSOs',
            value: 'area_2_sub_area_2_3_standard_2',
          },
          {
            label: 'Standard 3: Non-Formal Education',
            value: 'area_2_sub_area_2_3_standard_3',
          },
        ],
      },
    ],
  },
  {
    label: 'Area 3: Government-CSO Relationship',
    value: 'area_3',
    subAreas: [
      {
        label: 'Sub-Area 3.1: FRAMEWORK AND PRACTICES FOR COOPERATION',
        value: 'area_3_sub_area_3_1',
        standards: [
          {
            label:
              'Standard 1: State Policies and Strategies for Development of and Cooperation with Civil Society',
            value: 'area_3_sub_area_3_1_standard_1',
          },
          {
            label:
              'Standard 2: Institutions and Mechanisms for Development of and Cooperation with Civil Society',
            value: 'area_3_sub_area_3_1_standard_2',
          },
        ],
      },
      {
        label:
          'Sub-Area 3.2: INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
        value: 'area_3_sub_area_3_2',
        standards: [
          {
            label: 'Standard 1: Standards for CSO Involvement',
            value: 'area_3_sub_area_3_2_standard_1',
          },
          {
            label: 'Standard 2: Public Access to Draft Policies and Laws',
            value: 'area_3_sub_area_3_2_standard_2',
          },
          {
            label: 'Standard 3: CSOs’ Representation in Cross-Sector Bodies',
            value: 'area_3_sub_area_3_2_standard_3',
          },
        ],
      },
      {
        label: 'Sub-Area 3.3: COLLABORATION IN SERVICE PROVISION',
        value: 'area_3_sub_area_3_3',
        standards: [
          {
            label:
              'Standard 1: CSO Engagement in Service Provision and Competition for State Contracts',
            value: 'area_3_sub_area_3_3_standard_1',
          },
          {
            label: 'Standard 2: State Funding for CSO-Provided Services',
            value: 'area_3_sub_area_3_3_standard_2',
          },
          {
            label: 'Standard 3: Procedures for Contracting Services',
            value: 'area_3_sub_area_3_3_standard_3',
          },
          {
            label:
              'Standard 4: Accountability, Monitoring and Evaluation of Service Provision',
            value: 'area_3_sub_area_3_3_standard_4',
          },
        ],
      },
    ],
  },
];

const ABOUT_MENU: IDropdownOption[] = [
  {
    label: 'Background',
    value: 'background',
  },
  {
    label: 'EU CS Guidelines',
    value: 'guidelines',
  },
  {
    label: 'Methodology',
    value: 'methodology',
  },
  {
    label: 'MM Toolkit',
    value: 'the-monitoring-matrix-toolkit',
  },
];

const FINDING_MENU: IDropdownOption[] = [
  {
    label: 'Executive Summary',
    value: 'keyFindings',
  },
  {
    label: 'CS Overview',
    value: 'CSOverview',
  },
  {
    label: 'Background',
    value: 'background',
  },
  {
    label: 'Findings',
    value: 'findings',
  },
  {
    label: 'Recommendations',
    value: 'conclusionsAndRecommendations',
  },
];

const REGIONAL_REPORT_USER_LIST: IDropdownAreaUser[] = [
  {
    label: 'Area 1: Basic Legal Guarantees of Freedoms',
    value: 'area_1',
    subAreas: [
      {
        label: 'Sub-Area 1.1: FREEDOM OF ASSOCIATION',
        value: 'area_1_sub_area_1_1',
        standards: [
          {
            label: '1.1.1: Establishment of and Participation in CSOs',
            value: 'area_1_sub_area_1_1_standard_1',
          },
          {
            label: '1.1.2: State Interference',
            value: 'area_1_sub_area_1_1_standard_2',
          },
          {
            label: '1.1.3: Securing Financial Resources',
            value: 'area_1_sub_area_1_1_standard_3',
          },
        ],
      },
      {
        label: 'Sub-Area 1.2: RELATED FREEDOMS',
        value: 'area_1_sub_area_1_2',
        standards: [
          {
            label: '1.2.1: Freedom of Peaceful Assembly',
            value: 'area_1_sub_area_1_2_standard_1',
          },
          {
            label: '1.2.2: Freedom of Expression',
            value: 'area_1_sub_area_1_2_standard_2',
          },
          {
            label: '1.2.3: Access to Information',
            value: 'area_1_sub_area_1_2_standard_3',
          },
        ],
      },
    ],
  },
  {
    label: 'Area 2: Framework for CSO Financial Viability and Sustainability',
    value: 'area_2',
    subAreas: [
      {
        label: 'Sub-Area 2.1: TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
        value: 'area_2_sub_area_2_1',
        standards: [
          {
            label: '2.1.1: Tax Benefits',
            value: 'area_2_sub_area_2_1_standard_1',
          },
          {
            label: '2.1.2: Incentives for Individual/Corporate Giving',
            value: 'area_2_sub_area_2_1_standard_2',
          },
        ],
      },
      {
        label: 'Sub-Area 2.2: STATE SUPPORT',
        value: 'area_2_sub_area_2_2',
        standards: [
          {
            label: '2.2.1: Public Funding Availability',
            value: 'area_2_sub_area_2_2_standard_1',
          },
          {
            label: '2.2.2: Public Funding Distribution',
            value: 'area_2_sub_area_2_2_standard_2',
          },
          {
            label:
              '2.2.3: Accountability, Monitoring and Evaluation of Public Funding',
            value: 'area_2_sub_area_2_2_standard_3',
          },
          {
            label: '2.2.4: Non-Financial Support',
            value: 'area_2_sub_area_2_2_standard_4',
          },
        ],
      },
      {
        label: 'Sub-Area 2.3: HUMAN RESOURCES',
        value: 'area_2_sub_area_2_3',
        standards: [
          {
            label: '2.3.1: Employment in CSOs',
            value: 'area_2_sub_area_2_3_standard_1',
          },
          {
            label: '2.3.2: Volunteering in CSOs',
            value: 'area_2_sub_area_2_3_standard_2',
          },
          {
            label: '2.3.3: Non-Formal Education',
            value: 'area_2_sub_area_2_3_standard_3',
          },
        ],
      },
    ],
  },
  {
    label: 'Area 3: Government-CSO Relationship',
    value: 'area_3',
    subAreas: [
      {
        label: 'Sub-Area 3.1: FRAMEWORK AND PRACTICES FOR COOPERATION',
        value: 'area_3_sub_area_3_1',
        standards: [
          {
            label:
              '3.1.1: State Policies and Strategies for Development of and Cooperation with Civil Society',
            value: 'area_3_sub_area_3_1_standard_1',
          },
          {
            label:
              '3.1.2: Institutions and Mechanisms for Development of and Cooperation with Civil Society',
            value: 'area_3_sub_area_3_1_standard_2',
          },
        ],
      },
      {
        label:
          'Sub-Area 3.2: INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
        value: 'area_3_sub_area_3_2',
        standards: [
          {
            label: '3.2.1: Standards for CSO Involvement',
            value: 'area_3_sub_area_3_2_standard_1',
          },
          {
            label: '3.2.2: Public Access to Draft Policies and Laws',
            value: 'area_3_sub_area_3_2_standard_2',
          },
          {
            label: '3.2.3: CSOs’ Representation in Cross-Sector Bodies',
            value: 'area_3_sub_area_3_2_standard_3',
          },
        ],
      },
      {
        label: 'Sub-Area 3.3: COLLABORATION IN SERVICE PROVISION',
        value: 'area_3_sub_area_3_3',
        standards: [
          {
            label:
              '3.3.1: CSO Engagement in Service Provision and Competition for State Contracts',
            value: 'area_3_sub_area_3_3_standard_1',
          },
          {
            label: '3.3.2: State Funding for CSO-Provided Services',
            value: 'area_3_sub_area_3_3_standard_2',
          },
          {
            label: '3.3.3: Procedures for Contracting Services',
            value: 'area_3_sub_area_3_3_standard_3',
          },
          {
            label:
              '3.3.4: Accountability, Monitoring and Evaluation of Service Provision',
            value: 'area_3_sub_area_3_3_standard_4',
          },
        ],
      },
    ],
  },
];

const COUNTRY_REPORT_STATUS_LIST: IDropdownOption[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: 'PUBLISHED',
    value: ENUMS.STATUS.PUBLISH,
  },
  {
    label: 'IN PROGRESS',
    value: ENUMS.STATUS.WAITING_PUBLISH,
  },
  {
    label: 'REVIEWING',
    value: ENUMS.STATUS.REVIEW,
  },
  {
    label: 'EDITING',
    value: ENUMS.STATUS.EDITING,
  },
  {
    label: 'DRAFT',
    value: ENUMS.STATUS.DRAFT,
  },
];

const COUNTRY_SCORING_STATUS_LIST: IDropdownOption[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: 'APPROVAL',
    value: ENUMS.STATUS.APPROVAL,
  },
  {
    label: 'WAITING FOR APPROVAL',
    value: ENUMS.STATUS.WAITING_APPROVAL,
  },
  {
    label: 'REVIEWING',
    value: ENUMS.STATUS.REVIEW,
  },
  {
    label: 'EDITING',
    value: ENUMS.STATUS.EDITING,
  },
  {
    label: 'DRAFT',
    value: ENUMS.STATUS.DRAFT,
  },
];

export default {
  COUNTRY_REPORT_LIST,
  GENDER,
  ROLE_LIST,
  ORDER_BY_LIST,
  USER_STATUS_LIST,
  NEWS_STATUS_LIST,
  NEWS_CATEGORY_LIST,
  GALLERY_STATUS_LIST,
  REGIONAL_REPORT_LIST,
  ROLE_LIST_ADMIN,
  REGIONAL_REPORT_LIST_DATA,
  ABOUT_MENU,
  FINDING_MENU,
  REGIONAL_REPORT_USER_LIST,
  COUNTRY_REPORT_STATUS_LIST,
  COUNTRY_SCORING_STATUS_LIST,
};
