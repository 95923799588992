import React from 'react';
import _ from 'lodash';

import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { NoDataWereFound } from '@/Components/Common';
import { DEFAULT_LABEL_CIVIL_SOCIETY } from '@/Constants/CountryScoring';
import { ICivilSocietyOverviewTableStructure } from '@/Interfaces/CountryReport.interface';

interface ISectionProps {
  payload: ICivilSocietyOverviewTableStructure;
}

const CSOverview: React.FC<ISectionProps> = ({ payload }) => {
  const renderMain = () => {
    return payload ? (
      <Table sx={{ mt: 1 }}>
        <TableBody>
          {_.map(Object.keys(DEFAULT_LABEL_CIVIL_SOCIETY), (key, index) => {
            return (
              <TableRow key={`${key}-${index}`}>
                <TableCell
                  variant="head"
                  sx={{ width: '30%', border: '1px solid #E0E0E0' }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {
                      DEFAULT_LABEL_CIVIL_SOCIETY[
                        key as keyof typeof DEFAULT_LABEL_CIVIL_SOCIETY
                      ]
                    }
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '70%', border: '1px solid #E0E0E0' }}>
                  {payload && payload[key as keyof typeof payload]}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    ) : (
      <NoDataWereFound />
    );
  };

  return renderMain();
};

export default CSOverview;
