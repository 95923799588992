import { IMMToolkitTemplate } from '@interfaces/CountryScoring.interface';

const COMPARE_AREA_1: IMMToolkitTemplate = {
  code: 'area_1',
  codeName: 'Area 1',
  name: 'Basic Legal Guarantees of Freedoms',
  subAreas: [
    {
      code: 'area_1_sub_area_1_1',
      codeName: 'Sub-Area 1.1',
      name: 'FREEDOM OF ASSOCIATION',
      standards: [
        {
          code: 'area_1_sub_area_1_1_standard_1',
          codeName: '1.1.1',
          name: 'All individuals and legal entities can freely establish, join and participate in informal and/or registered organizations offline and online',
          originalName: 'ESTABLISHMENT AND PARTICIPATION IN CSOs',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_1_sub_area_1_1_standard_2',
          codeName: '1.1.2',
          originalName: 'STATE INTERFERENCE',
          name: 'CSOs operate freely without unwarranted state interference in their internal governance and activities',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_1_sub_area_1_1_standard_3',
          codeName: '1.1.3',
          originalName: 'SECURING FINANCIAL RESOURCES',
          name: 'CSOs can freely seek and secure financial resources from various domestic and foreign sources to support their activities',
          legislation: '',
          practice: '',
        },
      ],
    },
    {
      code: 'area_1_sub_area_1_2',
      codeName: 'Sub-Area 1.2',
      name: 'RELATED FREEDOMS',
      standards: [
        {
          code: 'area_1_sub_area_1_2_standard_1',
          codeName: '1.2.1',
          originalName: 'FREEDOM OF PEACEFUL ASSEMBLY',
          name: 'CSO representatives, individually or through their organizations enjoy freedom of peaceful assembly',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_1_sub_area_1_2_standard_2',
          codeName: '1.2.2',
          originalName: 'FREEDOM OF EXPRESSION',
          name: 'CSO representatives, individually or through their organizations enjoy freedom of expression',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_1_sub_area_1_2_standard_3',
          codeName: '1.2.3',
          originalName: 'ACCESS TO INFORMATION',
          name: 'Civil society representatives, individually and through their organizations, have the rights to safely receive and impart information through any media',
          legislation: '',
          practice: '',
        },
      ],
    },
  ],
};

const COMPARE_AREA_2: IMMToolkitTemplate = {
  code: 'area_2',
  codeName: 'Area 2',
  name: `Framework for CSOs' Financial Viability and Sustainability`,
  subAreas: [
    {
      code: 'area_2_sub_area_2_1',
      codeName: 'Sub-Area 2.1',
      name: 'TAX/FISCAL TREATMENT FOR CSOS AND THEIR DONORS',
      standards: [
        {
          code: 'area_2_sub_area_2_1_standard_1',
          codeName: '2.1.1',
          originalName: 'TAX BENEFITS',
          name: 'Tax benefits are available on various income sources of CSOs',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_2_sub_area_2_1_standard_2',
          codeName: '2.1.2',
          originalName: 'INCENTIVES FOR INDIVIDUAL/CORPORATE GIVING',
          name: 'Incentives are provided for individual and corporate giving',
          legislation: '',
          practice: '',
        },
      ],
    },
    {
      code: 'area_2_sub_area_2_2',
      codeName: 'Sub-Area 2.2',
      name: 'STATE SUPPORT',
      standards: [
        {
          code: 'area_2_sub_area_2_2_standard_1',
          codeName: '2.2.1',
          originalName: 'PUBLIC FUNDING AVAILABILITY',
          name: 'Public funding is available for institutional development of CSOs, project support and cofinancing of EU and other grants.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_2_sub_area_2_2_standard_2',
          codeName: '2.2.2',
          originalName: 'PUBLIC FUNDING DISTRIBUTION',
          name: 'Public funding is distributed in a prescribed and transparent manner',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_2_sub_area_2_2_standard_3',
          codeName: '2.2.3',
          originalName:
            'ACCOUNTABILITY, MONITORING AND EVALUATION OF PUBLIC FUNDING',
          name: 'There is a clear system of accountability, monitoring and evaluation of public funding',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_2_sub_area_2_2_standard_4',
          codeName: '2.2.4',
          originalName: 'NON-FINANCIAL SUPPORT',
          name: 'Non-financial support is available from the state',
          legislation: '',
          practice: '',
        },
      ],
    },
    {
      code: 'area_2_sub_area_2_3',
      codeName: 'Sub-Area 2.3',
      name: 'HUMAN RESOURCES',
      standards: [
        {
          code: 'area_2_sub_area_2_3_standard_1',
          codeName: '2.3.1',
          originalName: 'EMPLOYMENT IN CSOs',
          name: 'CSOs are treated in an equal manner to other employers.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_2_sub_area_2_3_standard_2',
          codeName: '2.3.2',
          originalName: 'VOLUNTEERING IN CSOs',
          name: 'There are enabling volunteering policies and laws',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_2_sub_area_2_3_standard_3',
          codeName: '2.3.3',
          originalName: 'NON-FORMAL EDUCATION',
          name: 'The educational system promotes civic engagement',
          legislation: '',
          practice: '',
        },
      ],
    },
  ],
};

const COMPARE_AREA_3: IMMToolkitTemplate = {
  code: 'area_3',
  codeName: 'Area 3',
  name: 'Government – CSO Relationship',
  subAreas: [
    {
      code: 'area_3_sub_area_3_1',
      codeName: 'Sub-Area 3.1',
      name: 'FRAMEWORK AND PRACTICES FOR COOPERATION',
      standards: [
        {
          code: 'area_3_sub_area_3_1_standard_1',
          codeName: '3.1.1',
          originalName:
            'STATE POLICIES AND STRATEGIES FOR DEVELOPMENT OF AND COOPERATION WITH CIVIL SOCIETY',
          name: 'The State recognizes, through policies and strategies, the importance of the development of and cooperation with the sector.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_3_sub_area_3_1_standard_2',
          codeName: '3.1.2',
          originalName:
            'INSTITUTIONS AND MECHANISMS FOR DEVELOPMENT OF AND COOPERATION WITH CIVIL SOCIETY',
          name: 'The State recognizes, through the operation of its institutions, the importance of the development of and cooperation with the sector.',
          legislation: '',
          practice: '',
        },
      ],
    },
    {
      code: 'area_3_sub_area_3_2',
      codeName: 'Sub-Area 3.2',
      name: 'INVOLVEMENT IN POLICY-AND DECISION-MAKING PROCESSES',
      standards: [
        {
          code: 'area_3_sub_area_3_2_standard_1',
          codeName: '3.2.1',
          originalName: 'STANDARDS FOR CSO INVOLVEMENT',
          name: 'There are standards enabling CSO involvement in decision-making, which allow for CSO input in a timely manner.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_3_sub_area_3_2_standard_2',
          codeName: '3.2.2',
          originalName: 'PUBLIC ACCESS TO DRAFT POLICIES AND LAWS',
          name: 'All draft policies and laws are easily accessible to the public in a timely manner.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_3_sub_area_3_2_standard_3',
          codeName: '3.2.3',
          originalName: 'CSO REPRESENTATION IN CROSS-SECTOR BODIES',
          name: 'CSO representatives are equal partners in discussions in cross-sector bodies and are selected through clearly defined criteria and processes.',
          legislation: '',
          practice: '',
        },
      ],
    },
    {
      code: 'area_3_sub_area_3_3',
      codeName: 'Sub-Area 3.3',
      name: 'COLLABORATION IN SERVICE PROVISION',
      standards: [
        {
          code: 'area_3_sub_area_3_3_standard_1',
          codeName: '3.3.1',
          originalName:
            'CSO ENGAGEMENT IN SERVICE PROVISION AND COMPETITION FOR STATE CONTRACTS',
          name: 'CSOs are engaged in different services and compete for state contracts on an equal basis to other providers.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_3_sub_area_3_3_standard_2',
          codeName: '3.3.2',
          originalName: 'STATE FUNDING FOR CSO-PROVIDED SERVICES',
          name: 'The state has committed to funding services and the funding is predictable and available over a longer-term period.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_3_sub_area_3_3_standard_3',
          codeName: '3.3.3',

          originalName: 'PROCEDURES FOR CONTRACTING SERVICES',
          name: 'The state has clearly defined procedures for contracting services which allow for transparent selection of service providers, including CSOs.',
          legislation: '',
          practice: '',
        },
        {
          code: 'area_3_sub_area_3_3_standard_4',
          codeName: '3.3.4',
          originalName:
            'ACCOUNTABILITY, MONITORING AND EVALUATION OF SERVICE PROVISION',
          name: 'There is a clear system of accountability, monitoring and evaluation of service provision.',
          legislation: '',
          practice: '',
        },
      ],
    },
  ],
};

export default { COMPARE_AREA_1, COMPARE_AREA_2, COMPARE_AREA_3 };
