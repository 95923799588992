import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Grid, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { TextEditor } from '@/Components/Common';
import { Dialogs } from '@/Components/LayoutPart';

import { BackgroundActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { ICreateBackground } from '@/Interfaces/PageManagement.interface';
import { DEFAULT_CONFIRM } from '@constants';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const {
  getBackgroundHistory,
  resetBackgroundReducer,
  createBackgroundHistory,
} = BackgroundActions;

const APBackground: React.FC = () => {
  const dispatch = useTypedDispatch();

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.BACKGROUND_HISTORY, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.BACKGROUND_HISTORY, 'isActionLoading')
  );
  const backgroundContent: ICreateBackground = useSelector((state: RootState) =>
    _.get(state.BACKGROUND_HISTORY, 'backgroundContent')
  );

  const [renderKey, setRenderkey] = useState<number>();
  const [details, setDetails] = useState<ICreateBackground>({
    title: 'Background',
    content: '',
  });
  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    dispatch(getBackgroundHistory());
    return () => {
      dispatch(resetBackgroundReducer());
      setDetails({
        title: 'Background',
        content: '',
      });
    };
  }, []);

  useEffect(() => {
    if (backgroundContent && backgroundContent?.title) {
      const { title = 'Background', content } = backgroundContent;
      setDetails({
        title,
        content,
      });
      setRenderkey(Math.random());
    }
  }, [backgroundContent]);

  const handleInputChange = (newValue: string) =>
    setDetails({ ...details, content: newValue });

  const handleSubmit = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    dispatch(createBackgroundHistory(details));
  };

  const onCancel = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const _renderTitle = () => {
    return (
      <Grid item xs={12}>
        <BreadCrumb title="Background" />
      </Grid>
    );
  };

  const _renderContent = () => {
    return (
      <Grid item xs={12}>
        <TextEditor
          value={details.content}
          onChange={(e: any) => handleInputChange(e)}
          height={700}
          key={renderKey}
          disabled={isGetLoading || isActionLoading}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 2,
            mt: 2,
            mb: 2,
          }}
        >
          <LoadingButton
            variant="contained"
            onClick={() =>
              setConfirmDialog({
                ...confirmDialog,
                isOpen: true,
                message: `Are you sure you want to change the content?`,
              })
            }
            color="oceanGreen"
            loading={isGetLoading || isActionLoading}
          >
            Save
          </LoadingButton>
          <Dialogs.Confirm
            confirm={confirmDialog}
            onCancel={() => onCancel()}
            callback={() => handleSubmit()}
          />
        </Box>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container direction="column">
        {_renderTitle()}
        {_renderContent()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default APBackground;
