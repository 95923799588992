import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { Grid, TextField } from '@mui/material';

import { useTypedDispatch, RootState } from '@/store';
import { SocialLinkActions } from '@actions';

import { ISocial } from '@/Interfaces/SocialLink.interface';
import { LoadingButton } from '@mui/lab';
const { fetchSocialLinks, createNewSocialLinks } = SocialLinkActions;

const DEFAULT_SOCIAL_DATA: ISocial = {
  email: '',
  facebook: '',
  instagram: '',
  twitter: '',
};

const Contacts = () => {
  const dispatch = useTypedDispatch();
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [socialData, setSocialData] = useState<ISocial>(DEFAULT_SOCIAL_DATA);
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.SOCIAL_LINKS, 'isFetchLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.SOCIAL_LINKS, 'isActionLoading')
  );
  const socialLinks = useSelector((state: RootState) =>
    _.get(state.SOCIAL_LINKS, 'socialLinks')
  );

  const handleOnChange = (key: string, value: string) => {
    setSocialData({ ...socialData, [key]: value });
  };

  useEffect(() => {
    dispatch(fetchSocialLinks());
  }, []);

  useEffect(() => {
    if (socialLinks) {
      setSocialData(socialLinks);
      setRenderKey(Math.random());
    }
  }, [socialLinks]);

  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, facebook, instagram, twitter } = socialData;
    dispatch(createNewSocialLinks({ email, facebook, instagram, twitter }));
  };

  const _renderTitle = () => {
    return (
      <Grid item xs={12}>
        <BreadCrumb title="Social Network Links" />
      </Grid>
    );
  };
  const _renderBody = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Grid container rowSpacing={8}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    autoComplete="email"
                    defaultValue={socialData?.email || ''}
                    placeholder="Email"
                    key={`email${renderKey}`}
                    onChange={(e) => handleOnChange('email', e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="LinkedIn"
                    defaultValue={socialData?.instagram || ''}
                    key={`instagram${renderKey}`}
                    placeholder="https://www.linkedin.com"
                    inputProps={{ pattern: 'https://.*' }}
                    onChange={(e) =>
                      handleOnChange('instagram', e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Facebook"
                    name="facebook"
                    defaultValue={socialData?.facebook || ''}
                    key={`facebook${renderKey}`}
                    placeholder="https://facebook.com"
                    inputProps={{ pattern: 'https://.*' }}
                    onChange={(e) => handleOnChange('facebook', e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Twitter"
                    defaultValue={socialData?.twitter || ''}
                    key={`twitter${renderKey}`}
                    placeholder="https://twitter.com"
                    inputProps={{ pattern: 'https://.*' }}
                    onChange={(e) => handleOnChange('twitter', e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Grid item>
                  <LoadingButton
                    type="submit"
                    color="oceanGreen"
                    variant="contained"
                    loading={isFetchLoading || isActionLoading}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };
  const renderMain = () => {
    return (
      <Grid container direction="column">
        {_renderTitle()}
        {_renderBody()}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Contacts;
