import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { TextEditor } from '@/Components/Common';
import { RegionalActions } from '@/Actions';

const { saveDefaultBackground } = RegionalActions;

interface IPropsBackground {
  onUnfocus(value: string): void;
}
const Background: React.FC<IPropsBackground> = ({ onUnfocus }) => {
  const dispatch = useTypedDispatch();
  const defaultBackground: string = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultBackground')
  );

  const handleOnchange = (e: string) => {
    dispatch(saveDefaultBackground(e));
  };

  useEffect(() => {
    onUnfocus(defaultBackground);
  }, [defaultBackground]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextEditor
          value={defaultBackground}
          disabled={false}
          onChange={(e: string) => handleOnchange(e)}
        />
      </Grid>
    </Grid>
  );
};

export default Background;
