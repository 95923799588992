import {
  ACTION_TYPES,
  DEFAULT_LOADING_STATES,
  DEFAULT_PAGINATION,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    ...DEFAULT_PAGINATION,
    year: '',
    title: '',
  },
  meta: null,
  defaultSubareas: [],
  defaultRecomment: null,
  defaultOverview: [],
  defaultBackground: null,
  defaultAcknowledgments: null,
  defaultRecommendations: {},
  defaultReportYear: null,
  defaultReportTitle: null,
  defaultCountryFullOptions: [],
  defaultCountryOptions: [],
  defaultRegionalReportList: null,
  fileReport: null,
  defaultFindingsRecommendations: [],
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_COUNTRY_REGIONAL_REPORT_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_REGIONAL_REPORT_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_REGIONAL_REPORTS_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_REGIONAL_REPORTS_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_REGIONAL_REPORTS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.CLEAR_DATA_COUNTRY_REPORT: {
      return {
        ...state,
        defaultSubareas: [],
        defaultRecomment: null,
        defaultOverview: [],
        defaultBackground: null,
        defaultAcknowledgments: null,
        defaultRecommendations: {},
        defaultReportPDF: null,
        defaultCountryFullOptions: [],
        defaultCountryOptions: [],
        defaultFindingsRecommendations: [],
      };
    }
    case ACTION_TYPES.SET_DATA_COUNTRY_FULL_OPTIONS: {
      return {
        ...state,
        defaultCountryFullOptions: payload,
      };
    }
    case ACTION_TYPES.SET_DATA_COUNTRY_OPTIONS: {
      return {
        ...state,
        defaultCountryOptions: payload,
      };
    }
    case ACTION_TYPES.SET_DATA_SUBAREAS:
      return {
        ...state,
        defaultSubareas: payload,
      };
    case ACTION_TYPES.SET_DATA_RECOMMEN:
      return {
        ...state,
        defaultRecomment: payload,
      };
    case ACTION_TYPES.SET_DATA_OVERVIEW:
      return {
        ...state,
        defaultOverview: payload,
      };
    case ACTION_TYPES.SET_DATA_BACKGROUND:
      return {
        ...state,
        defaultBackground: payload,
      };
    case ACTION_TYPES.SET_DATA_ACKNOWLEDGMENTS:
      return {
        ...state,
        defaultAcknowledgments: payload,
      };
    case ACTION_TYPES.SET_DATA_RECOMMENDATIONS:
      return {
        ...state,
        defaultRecommendations: payload,
      };
    case ACTION_TYPES.SET_DATA_FINDINGS_RECOMMENDATIONS:
      return {
        ...state,
        defaultFindingsRecommendations: payload,
      };
    case ACTION_TYPES.CREATE_REGIONAL_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.CREATE_REGIONAL_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.UPDATE_REGIONAL_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
        ...payload,
      };
    case ACTION_TYPES.UPDATE_REGIONAL_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.FETCH_REGIONAL_REPORTS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
        isFetchLoading: false,
        defaultRegionalReportList: payload,
      };
    case ACTION_TYPES.FETCH_REGIONAL_REPORTS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_REGIONAL_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isGetLoading: false,
        defaultRegionalReportDetail: payload,
        ...payload,
      };
    case ACTION_TYPES.GET_REGIONAL_REPORT_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.ACTIVE_REGIONAL_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.ACTIVE_REGIONAL_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.PUBLISH_REGIONAL_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.PUBLISH_REGIONAL_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.INACTIVE_REGIONAL_REPORT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.INACTIVE_REGIONAL_REPORT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.RESET_REGIONAL_REPORT_REDUCER:
      return DEFAULT_STATES;
    default:
      return state;
  }
};
