import React, { useState, useEffect, useMemo, memo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Grid, Typography, Box } from '@mui/material';

import Utils from '@/Utils';
import { FooterActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { IFooterStructure } from '@/Interfaces/Footer.inteface';
import { mainStyles } from './Footer.styles';

const { getFooter } = FooterActions;

const DEFAULT_DETAILS = {
  rulesOwnership: {
    title: 'Rules And Ownership',
    content: '',
  },
  about: { title: 'What Is the Monitoring Matrix?', content: '' },
  contact: { title: 'Contact Information', content: '' },
  extraData: '',
};

const Footer: React.FC = () => {
  const dispatch = useTypedDispatch();
  const footerContent: any = useSelector((state: RootState) =>
    _.get(state.FOOTER, 'footerContent')
  );
  const [details, setDetails] = useState<IFooterStructure>(DEFAULT_DETAILS);

  useEffect(() => {
    if (!footerContent) dispatch(getFooter());
  }, []);

  useEffect(() => {
    generateDetails();
  }, [footerContent]);

  const generateDetails = async () => {
    if (!_.isEmpty(footerContent)) {
      const {
        rulesOwnership,
        about,
        contact,
        logoRuleOwnership,
        logoAbout,
        logoContact,
      } = footerContent;
      const resolveDetails = {
        rulesOwnership: JSON.parse(rulesOwnership),
        about: JSON.parse(about),
        contact: JSON.parse(contact),
        logoRuleOwnership,
        logoAbout,
        logoContact,
      };
      setDetails(resolveDetails);
    }
  };

  const countTagsInHTMLString = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const allElements = doc.getElementsByTagName('p');
    return allElements.length;
  };

  // Renders
  const _renderLeftSide = useMemo(() => {
    return (
      <Grid
        container
        sx={{
          pt: {
            xs: 3,
            lg: 1,
          },
          pb: 0,
          pr: {
            xs: 3,
            lg: 1,
          },
          pl: {
            xs: 3,
            lg: 1,
          },
        }}
      >
        <Grid item xs={7} md={8} lg={7.5}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">{details.contact.title}</Typography>
          </Grid>
          <Box
            sx={{
              img: {
                objectFit: 'cover',
              },
              '& p': {
                lineHeight: 1,
                fontSize: 12,
                marginBlockStart: {
                  xs: '1em',
                  lg:
                    countTagsInHTMLString(details.contact.content) === 1
                      ? 0
                      : '1em',
                },
                marginBlockEnd: {
                  xs: '1em',
                  lg:
                    countTagsInHTMLString(details.contact.content) === 1
                      ? 0
                      : '1em',
                },
              },
            }}
            dangerouslySetInnerHTML={Utils.createMarkup(
              details.contact.content
            )}
          />
        </Grid>
        <Grid item xs={5} md={4} lg={4.5}>
          {details?.logoContact && (
            <Box
              component="img"
              title={details.logoContact.nameOriginal}
              sx={{
                objectFit: 'cover',
                maxWidth: {
                  xs: 80,
                  lg: 60,
                },
                height: 'auto',
              }}
              src={details.logoContact.path}
            />
          )}
        </Grid>
      </Grid>
    );
  }, [details]);

  const _renderRightSide = useMemo(() => {
    return (
      <Grid
        container
        sx={{
          pt: {
            xs: 3,
            lg: 1,
          },
          pb: 0,
          pr: {
            xs: 3,
            lg: 1,
          },
          pl: {
            xs: 3,
            lg: 1,
          },
          textAlign: { xs: 'left', md: 'center' },
        }}
      >
        <Grid item xs={12} md={6} lg={7}>
          <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
            {details.rulesOwnership.title}
          </Typography>
          <Box
            sx={{
              img: {
                maxWidth: '50%',
                height: 'auto',
                objectFit: 'cover',
              },
              '& p': {
                lineHeight: 1,
                fontSize: 12,
                marginBlockStart: {
                  xs: '1em',
                  lg:
                    countTagsInHTMLString(details.contact.content) === 1
                      ? 0
                      : '1em',
                },
                marginBlockEnd: {
                  xs: '1em',
                  lg:
                    countTagsInHTMLString(details.contact.content) === 1
                      ? 0
                      : '1em',
                },
              },
            }}
            dangerouslySetInnerHTML={Utils.createMarkup(
              details.rulesOwnership.content
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                // xs: 'column',
                lg: 'row',
              },
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'start',
                lg: 'space-between',
              },
              gap: 2,
              // mb: { xs: 2, lg: 0 },
              mt: { xs: 0, lg: 2 },
            }}
          >
            {details?.logoRuleOwnership && (
              <Box
                component="img"
                title={details.logoRuleOwnership.nameOriginal}
                maxHeight={{ xs: 1 }}
                sx={{
                  maxWidth: 150,
                  height: 'auto',
                  // objectFit: 'cover',
                  // mr: 2,
                }}
                src={details.logoRuleOwnership.path}
              />
            )}
            {details?.logoAbout && (
              <Box
                component="img"
                title={details.logoAbout.nameOriginal}
                sx={{ maxWidth: 100, height: 'auto', objectFit: 'auto' }}
                src={details.logoAbout.path}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }, [details]);

  return (
    <Grid container direction="column" sx={mainStyles}>
      <Grid
        container
        sx={{
          width: 1,
          maxWidth: 1200,
          m: 'auto',
          mb: 1,
        }}
      >
        <Grid item xs={12} lg={4.5} sm={6}>
          {_renderLeftSide}
        </Grid>

        <Grid item xs={12} lg={7.5} sm={6}>
          {_renderRightSide}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Footer);
