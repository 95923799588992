import { sendRequest } from '@/Configs';
import { API } from '@constants';
import {
  INewTabFilter,
  IPaginationFilter,
} from '@/Interfaces/PaginationMeta.interface';

const { COUNTRY_REPORT } = API;

export const fetchCountryReports = async (payload: IPaginationFilter) => {
  return sendRequest(COUNTRY_REPORT.ROOT, 'GET', payload);
};

export const getCountryReportById = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.GET_BY_ID}${id}`, 'GET');
};

export const getForVisitorCountryReport = async (payload: {
  countryId: string;
  year: string;
}) => {
  return sendRequest(`${COUNTRY_REPORT.GET_FOR_VISITOR}`, 'GET', payload);
};

export const createCountryReport = async (payload: FormData) => {
  return sendRequest(`${COUNTRY_REPORT.CREATE}`, 'POST', payload);
};

export const updateCountryReport = async (id: string, payload: FormData) => {
  return sendRequest(`${COUNTRY_REPORT.UPDATE}${id}`, 'PUT_FORM_DATA', payload);
};

export const activeCountryReport = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.ACTIVE}${id}`, 'PUT');
};

export const deactiveCountryReport = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.DEACTIVE}${id}`, 'PUT');
};
export const terminateCountryReport = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.TERMINATE}${id}`, 'PUT');
};

export const publishCountryReport = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.PUBLISH}${id}`, 'PUT');
};

export const editingCountryReport = async (
  id: string,
  payload: { status: string; comment?: string }
) => {
  return sendRequest(`${COUNTRY_REPORT.EDITING}${id}`, 'PUT', payload);
};

export const reviewCountryReport = async (
  id: string,
  payload: { status: string; comment?: string }
) => {
  return sendRequest(`${COUNTRY_REPORT.EDITING}${id}`, 'PUT', payload);
};

export const watingPublicCountryReport = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.WAITING_PUBLIC}${id}`, 'PUT');
};

export const unPublishCountryReport = async (id: string) => {
  return sendRequest(`${COUNTRY_REPORT.REVIEW}${id}`, 'PUT');
};

export const getCountryReportFindings = async (payload: INewTabFilter) => {
  return sendRequest(`${COUNTRY_REPORT.GET_FOR_VISITOR}`, 'GET', payload);
};
