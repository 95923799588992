import _ from 'lodash';
import { ACTION_TYPES } from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';

const getRegionalReportsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REGIONAL_REPORTS_GET_LOADING,
    payload,
  };
};

const getRegionalForVisitorSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_FOR_VISITOR_REGIONAL_REPORT_SUCCESS,
    payload,
  };
};

const getRegionalForVisitorFail = () => {
  return {
    type: ACTION_TYPES.GET_FOR_VISITOR_REGIONAL_REPORT_FAILURE,
  };
};

const getForVisitorRegionalReport = (year: string) => {
  return async (dispatch: any) => {
    dispatch(getRegionalReportsLoading(true));
    await API.getForVisitorRegionalReport({ year })
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) {
          await dispatch(getRegionalForVisitorFail());
        } else {
          const getDataRegional = _.get(result, 'main');
          const getChart = _.get(result, 'chart');
          const resolvedChart = _.map(getChart, (el, key) => {
            return {
              code: key,
              charts: Utils.generateRegionalReportBarChart(el),
            };
          });
          await dispatch(
            getRegionalForVisitorSuccess({
              getDataRegional,
              chartForYear: resolvedChart,
            })
          );
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getRegionalForVisitorFail());
      });
  };
};

export default {
  getForVisitorRegionalReport,
};
