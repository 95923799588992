import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { CommonStyles, TextEditor } from '@/Components/Common';
import { ISubareas, IFindingStructure } from '@/Interfaces/RegionalReport';
import { useTypedDispatch, RootState } from '@/store';
import { RegionalActions } from '@/Actions';
import { LIST } from '@/Constants';

const { REGIONAL_REPORT_LIST } = LIST.DROPDOWN_LIST;

const { saveDefaultSubareas } = RegionalActions;
const { REGIONAL_REPORT_LIST_DATA } = LIST.DROPDOWN_LIST;

interface IPropsSubareas {
  onUnfocus(data?: ISubareas): void;
  hiddenToolbar?: boolean;
}

const Subareas: React.FC<IPropsSubareas> = ({ onUnfocus, hiddenToolbar }) => {
  const dispatch = useTypedDispatch();
  // const [selectedPart, setSelectedPart] = useState<string>('finding');
  const [selectArea, setSelectArea] = useState<string>('area_1_sub_area_1_1');
  const [parentState, setParentState] = useState<any>('area_1');
  const [subareasForm, setSubareasForm] = useState<ISubareas>();
  const [openItems, setOpenItems] = useState<string[]>(['area_1']);
  const [selectState, setSelectState] = useState<string>('area_1_sub_area_1_1');
  const findingState: IFindingStructure = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultSubareas')
  );
  const findingStateRef = useRef<any>(null);
  findingStateRef.current = findingState;

  const recommendations: string = useSelector((state: RootState) =>
    _.get(state.REGIONAL_REPORT, 'defaultRecomment')
  );

  // const handleOnchangeSelectOptions = (e: any) => {
  //   setSelectedPart(e.target.value);
  //   setSelectArea('');
  // };

  const handleOnChangeFinding = (
    key: string,
    value: string,
    parent: string,
    standard: string
  ) => {
    if (_.isEmpty(findingStateRef.current)) {
      const data = {
        [parent]: {
          [key]: {
            [standard]: value,
          },
        },
      };
      dispatch(saveDefaultSubareas(data));
    } else {
      const data = {
        ...findingStateRef.current,
        [parent]: {
          ..._.get(findingStateRef.current, [parent]),
          [key]: {
            ..._.get(_.get(findingStateRef.current, [parent]), [key]),
            [standard]: value,
          },
        },
      };
      dispatch(saveDefaultSubareas(data));
    }
  };

  // const handleOchangRecommendations = (value: string) => {
  //   dispatch(saveDefaultRecomment(value));
  // };

  const handleOnchangeTreeDropdown = (e: string, parent: string) => {
    setSelectArea(e);
    setParentState(parent);
    setSelectState(e);
  };

  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  useEffect(() => {
    return setSubareasForm({ ...subareasForm, findingState, recommendations });
  }, [findingState, recommendations]);

  useEffect(() => {
    onUnfocus(subareasForm);
  }, [subareasForm]);

  // const _renderSelectOptions = () => {
  //   return (
  //     <TextField
  //       select
  //       fullWidth
  //       defaultValue={selectedPart}
  //       key={`finding${selectedPart}`}
  //       onChange={(e) => handleOnchangeSelectOptions(e)}
  //     >
  //       {_.map(DEFAULT_FINDING, (option, index) => {
  //         return (
  //           <MenuItem key={`${option.label}-${index}`} value={option.value}>
  //             {option.label}
  //           </MenuItem>
  //         );
  //       })}
  //     </TextField>
  //   );
  // };

  // const _renderBody = (value: string) => {
  //   switch (value) {
  //     case 'finding':
  //       return _renderFinding();
  //     case 'recommendations':
  //       return _renderRecommendations();
  //     default:
  //       return '';
  //   }
  // };

  const _renderBodyFinding = (area: string, parent: any) => {
    const valueEditor: any = findingState?.[parent]?.[area] || '';
    const subArea = REGIONAL_REPORT_LIST_DATA.filter(
      (item: any) => item.value === parent
    );
    const standard = subArea[0]?.subAreas.filter(
      (item: any) => item.value === area
    );
    return (
      <Grid container spacing={4}>
        {_.map(standard[0]?.standards, (item: any, index: number) => {
          return (
            <Grid item xs={12} key={index}>
              <Typography fontWeight={600} mb={1}>
                {item.label}
              </Typography>
              <TextEditor
                key={item.label}
                value={_.get(valueEditor, item.value)}
                onChange={(e: any) =>
                  handleOnChangeFinding(area, e, parent, item.value)
                }
                hiddenToolbar={hiddenToolbar}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const _renderFinding = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} mt={2}>
          {_.map(REGIONAL_REPORT_LIST, (area, index) => {
            const isOpenSub = _.includes(openItems, area.value);
            return (
              <Box key={index}>
                <ListItemButton
                  key={index}
                  onClick={() => {
                    if (area) handleOpenItems(area.value);
                  }}
                >
                  <ListItemText primary={area.label} />
                  {isOpenSub ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItemButton>
                <Collapse in={isOpenSub} timeout="auto" unmountOnExit>
                  <List>
                    {_.map(area.subAreas, (sub, key) => {
                      const selected = sub.value === selectState;
                      return (
                        <Box key={key}>
                          <ListItemButton
                            key={`${key}${sub.label}`}
                            sx={{
                              pl: 4,
                              ...CommonStyles.hoverBorderEffect,
                            }}
                            selected={selected}
                            onClick={() =>
                              handleOnchangeTreeDropdown(sub.value, area.value)
                            }
                          >
                            <ListItemText
                              primaryTypographyProps={{ fontSize: 14 }}
                              primary={sub.label}
                            />
                          </ListItemButton>
                        </Box>
                      );
                    })}
                  </List>
                </Collapse>
              </Box>
            );
          })}
        </Grid>
        {selectArea && (
          <Grid item xs={8}>
            {_renderBodyFinding(selectArea, parentState)}
          </Grid>
        )}
      </Grid>
    );
  };

  // const _renderRecommendations = () => {
  //   return (
  //     <TextEditor
  //       value={recommendations}
  //       onChange={(e: string) => handleOchangRecommendations(e)}
  //     />
  //   );
  // };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={3}>
              {/* {_renderSelectOptions()} */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {_renderFinding()}
        </Grid>
      </Grid>
    );
  };

  return <Box>{renderMain()}</Box>;
};

export default Subareas;
