import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { SxProps, Theme } from '@mui/system';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
// import { TransitionSlide } from '@/Components/Common';
import { Scoring } from '@/Components/LayoutPart';
import {
  IIndicatorTemplate,
  ICreateTypeStandardScore,
  ICreateIndicatorScore,
} from '@interfaces/CountryScoring.interface';
import { ENUMS } from '@constants';
import { CommonColors } from '@/Themes';

const ButtonClose: SxProps<Theme> = {
  overflow: 'hidden',
  textAlign: 'center',
  span: {
    width: '0px',
    transition: 'all 0.3s linear',
    opacity: 0,
    overflow: 'hidden',
  },
  '&:hover': {
    span: {
      width: '100%',
      opacity: 1,
    },
  },
};

interface ICallbackIndicator {
  evaluate: number;
  description?: string;
}

interface SectionProps {
  open: boolean;
  handleClose(data?: ICreateIndicatorScore[]): void;
  indicators: IIndicatorTemplate[];
  dialogTitle: string;
  defaultStandard?: ICreateTypeStandardScore;
  statusScoring?: string;
}

const ScoringDialog: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const {
    open,
    handleClose,
    indicators,
    dialogTitle,
    defaultStandard,
    statusScoring,
  } = props;
  const [selectedIndicator, setSelectedIndicator] = useState<number>(0);
  const [callbackStandard, setCallbackStandard] = useState<
    ICreateIndicatorScore[]
  >([]);
  const [typeStandardId, setStandardId] = useState<any>('');
  const [isAllAccepted, setIsAllAccepted] = useState(false);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const dialogRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!open) {
      setCallbackStandard([]);
      setSelectedIndicator(0);
      setStandardId('');
      setIsAllAccepted(false);
      setTotalCompleted(0);
      if (dialogRef?.current)
        dialogRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    } else if (defaultStandard && open) {
      const sortIndicator = _.sortBy(defaultStandard?.indicator, ['code']);
      const denyIndex = _.findIndex(sortIndicator, [
        'status',
        ENUMS.STATUS.DENY,
      ]);
      if (denyIndex !== -1) setSelectedIndicator(denyIndex);
      setCallbackStandard(defaultStandard.indicator);
      setTotalCompleted(defaultStandard.indicator.length);
      setStandardId(_.get(defaultStandard, 'id'));
    }
  }, [open]);

  useEffect(() => {
    if (defaultStandard) {
      const totalIndicators = indicators.length;
      const acceptedIndicators = _.filter(
        defaultStandard.indicator,
        (indicator) => indicator?.status === ENUMS.STATUS.ACCEPT
      );
      if (totalIndicators === acceptedIndicators.length) setIsAllAccepted(true);
    }
  }, [defaultStandard]);

  // Events
  const submitForm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    handleClose(callbackStandard);
  };

  const onClose = async (reason?: string) => {
    if (reason === 'backdropClick') return;
    return handleClose();
  };

  const handleChangeIndex = (index: number) => {
    setSelectedIndicator(index);
  };

  const onChangeCallback = (data: ICallbackIndicator, code: string) => {
    const findIndex = _.findIndex(callbackStandard, ['code', code]);
    const newData = [...callbackStandard];
    if (findIndex !== -1) newData[findIndex] = { ...data, code };
    else newData.push({ ...data, code, typeStandardId });
    setTotalCompleted(newData.length);
    setCallbackStandard(newData);
  };

  // Renders

  const _renderContent = () => {
    return _.map(indicators, (indicator) => {
      const findIndicator = _.find(callbackStandard, ['code', indicator.code]);
      const commentIndicator = _.get(findIndicator, 'commentIndicator') || [];
      const isDenied = _.get(findIndicator, 'status') === ENUMS.STATUS.DENY;
      const isAccepted = _.get(findIndicator, 'status') === ENUMS.STATUS.ACCEPT;

      let indicatorColor = CommonColors.mainColor;
      if (isAccepted) indicatorColor = CommonColors.oceanGreen;
      if (isDenied) indicatorColor = CommonColors.fuzzyWuzzyBrown;
      return (
        <Box
          key={indicator.code}
          sx={{
            maxHeight: {
              xs: '75vh',
              md: '80vh',
              lg: '65vh',
            },
            overflow: 'auto',
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Typography
                gutterBottom
                variant="h6"
                sx={{ color: indicatorColor }}
              >
                {indicator.codeName}: {indicator.name}
                {isDenied && (
                  <DoNotDisturbIcon color="error" fontSize="large" />
                )}
                {isAccepted && <CheckIcon color="success" fontSize="large" />}
              </Typography>
            </Grid>
            <Grid item>
              <Scoring
                indicator={indicator}
                callback={(data) => onChangeCallback(data, indicator.code)}
                defaultValue={findIndicator}
                disabled={isAccepted || statusScoring === ENUMS.STATUS.REVIEW}
              />
            </Grid>
            {!_.isEmpty(commentIndicator) && (
              <Grid item>
                <Typography gutterBottom variant="h5" my={1}>
                  Comments:
                </Typography>
                {_.map(commentIndicator, (comment, index) => {
                  return (
                    <Typography
                      my={1}
                      ml={2}
                      gutterBottom
                      key={_.get(comment, 'id')}
                    >
                      {index + 1}
                      {'. '}
                      {_.get(comment, 'content')}
                    </Typography>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Box>
      );
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={(_e, r) => onClose(r)}
      // TransitionComponent={TransitionSlide}
      fullScreen
    >
      <DialogTitle sx={{ textTransform: 'uppercase' }}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          {dialogTitle}
          <Button
            onClick={() => onClose()}
            variant="contained"
            // size="small"
            color="fuzzyWuzzyBrown"
            sx={ButtonClose}
          >
            <CloseIcon />
            <Typography component="span" sx={{ fontSize: 14 }}>
              Close
            </Typography>
          </Button>
        </Box>
        <Grid container>
          <Grid item xs={2}>
            <Button
              startIcon={<NavigateBeforeIcon />}
              color="burntSienna"
              disabled={!selectedIndicator}
              onClick={() => setSelectedIndicator(selectedIndicator - 1)}
            >
              Previous indicator
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Typography
              textAlign="center"
              variant="body1"
              sx={{
                color:
                  totalCompleted < indicators.length
                    ? CommonColors.fuzzyWuzzyBrown
                    : CommonColors.oceanGreen,
              }}
            >
              {totalCompleted} / {indicators.length}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Button
              endIcon={<NavigateNextIcon />}
              color="burntSienna"
              disabled={selectedIndicator + 1 === indicators.length}
              onClick={() => setSelectedIndicator(selectedIndicator + 1)}
            >
              Next Indicator
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent ref={dialogRef}>
        <SwipeableViews
          axis="x"
          index={selectedIndicator}
          onChangeIndex={handleChangeIndex}
        >
          {_renderContent()}
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        {(statusScoring === ENUMS.STATUS.DRAFT ||
          statusScoring === ENUMS.STATUS.EDITING ||
          !statusScoring) && (
          <Button
            onClick={(e) => submitForm(e)}
            startIcon={<SaveIcon />}
            variant="contained"
            color="oceanGreen"
            sx={{ mr: 1 }}
            disabled={isAllAccepted}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ScoringDialog;
