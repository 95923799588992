import _ from 'lodash';
import { ChartData, ChartDataset } from 'chart.js';
import { CommonColors } from '@themes';

interface IInputBarChartData {
  [key: string]: {
    legislation: number;
    practice: number;
    total: number;
  };
}

interface IInputRegionalBarChartData {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        legislation: number;
        practice: number;
        total: number;
      };
    };
  };
}

const generateCompareBarChart = (
  payload: IInputBarChartData
): ChartData<'bar'> => {
  const labels: string[] = _.keys(payload);
  const datasets: ChartDataset<'bar'>[] = [
    {
      label: 'Legislation',
      data: _.map(payload, (el) => el.legislation),
      backgroundColor: CommonColors.perano,
    },
    {
      label: 'Practice',
      data: _.map(payload, (el) => el.practice),
      backgroundColor: CommonColors.burntSienna,
    },
  ];

  return {
    labels,
    datasets,
  };
};

const _resolveRangeColor = (point: number) => {
  if (point > 0 && point <= 20) return CommonColors.red;
  if (point > 20 && point <= 40) return CommonColors.orange;
  if (point > 40 && point <= 60) return CommonColors.yellow;
  if (point > 60 && point <= 80) return CommonColors.limeGreen;
  if (point > 80 && point <= 100) return CommonColors.forestGreen;
  return '';
};

const generateCountryReportBarChart = (
  code: string,
  payload: IInputBarChartData
): { code: string; chartData: ChartData<'bar'> } => {
  const labels: string[] = _.map(_.keys(payload).sort(), (label) => {
    const splitedKey: any = label.split('_');
    const standard = `${splitedKey[6]} ${splitedKey[4]}.${splitedKey[5]}.${splitedKey[7]}`;
    return standard.toUpperCase();
  });
  const dataLegis: number[] = [];
  const dataPra: number[] = [];
  const backgroundLegis: string[] = [];
  const backgroundPra: string[] = [];
  _.each(_.keys(payload).sort(), (key) => {
    const legisPoint = payload[key].legislation;
    const pracPoint = payload[key].practice;
    dataLegis.push(legisPoint);
    dataPra.push(pracPoint);
    backgroundLegis.push(_resolveRangeColor(legisPoint));
    backgroundPra.push(_resolveRangeColor(pracPoint));
  });
  const datasets: ChartDataset<'bar'>[] = [
    {
      label: 'Legislation',
      data: dataLegis,
      backgroundColor: backgroundLegis,
    },
    {
      label: 'Practice',
      data: dataPra,
      backgroundColor: backgroundPra,
    },
  ];

  return {
    code,
    chartData: {
      labels,
      datasets,
    },
  };
};

const generateStandardCountryReportBarChart = (
  area: string,
  standard: string,
  payload: IInputBarChartData
) => {
  const getArea = _.get(payload, [area]);
  const getStandard = _.get(getArea, [standard]);
  const legisPoint = getStandard?.legislation;
  const pracPoint = getStandard?.practice;
  const backgroundLegis = _resolveRangeColor(legisPoint);
  const backgroundPra = _resolveRangeColor(pracPoint);
  const datasets: ChartDataset<'bar'>[] = [
    {
      label: 'Score',
      data: [legisPoint, pracPoint],
      backgroundColor: [backgroundLegis, backgroundPra],
    },
  ];

  return {
    chartData: {
      labels: ['Legislation', 'Practice'],
      datasets,
    },
  };
};

const _generateRegionalBar = (
  code: string,
  payload: IInputBarChartData
): { code: string; chartData: ChartData<'bar'> } => {
  const labels: string[] = _.map(_.keys(payload).sort(), (label) => label);
  const dataLegis: number[] = [];
  const dataPra: number[] = [];
  const totalData: number[] = [];
  const backgroundLegis: string[] = [];
  const backgroundPra: string[] = [];
  const backgroundTotal: string[] = [];
  _.each(_.keys(payload).sort(), (key) => {
    const legisPoint = payload[key].legislation;
    const pracPoint = payload[key].practice;
    const totalPoint = payload[key].total;
    dataLegis.push(legisPoint);
    dataPra.push(pracPoint);
    totalData.push(totalPoint);
    backgroundLegis.push(_resolveRangeColor(legisPoint));
    backgroundPra.push(_resolveRangeColor(pracPoint));
    backgroundTotal.push(_resolveRangeColor(totalPoint));
  });
  const datasets: ChartDataset<'bar'>[] = [
    {
      label: 'Legislation',
      data: dataLegis,
      backgroundColor: backgroundLegis,
    },
    {
      label: 'Practice',
      data: dataPra,
      backgroundColor: backgroundPra,
    },
    {
      label: 'Total Score',
      data: totalData,
      backgroundColor: backgroundTotal,
    },
  ];
  return {
    code,
    chartData: {
      labels,
      datasets,
    },
  };
};

const generateRegionalReportBarChart = (
  payload: IInputRegionalBarChartData
): {
  code: string;
  charts: { code: string; chartData: ChartData<'bar'> }[];
}[] => {
  return _.map(payload, (el, key) => {
    const charts = _.map(el, (chart, innerKey) =>
      _generateRegionalBar(innerKey, chart)
    );
    return {
      code: key,
      charts,
    };
  });
};

export {
  generateCompareBarChart,
  generateCountryReportBarChart,
  generateStandardCountryReportBarChart,
  generateRegionalReportBarChart,
};
