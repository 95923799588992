import _ from 'lodash';

import API from '@/APIs';
import Utils from '@utils';
import { ACTION_TYPES, ROUTERS } from '@constants';

import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetGalleryLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_GALLERY_GET_LOADING,
    payload,
  };
};

const setFetchGalleryLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_GALLERY_FETCH_LOADING,
    payload,
  };
};

const setActionGalleryLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_GALLERY_ACTION_LOADING,
    payload,
  };
};

const setGalleryPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_GALLERY_PAGINATION,
    payload,
  };
};

const setGalleryMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_GALLERY_META,
    payload,
  };
};

const resetGalleryReducer = () => {
  return {
    type: ACTION_TYPES.RESET_GALLERY_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_GALLERIES_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_GALLERIES_FAILURE,
  };
};

const fetchGalleries = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchGalleryLoading(true));
    await API.fetchGalleries(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setGalleryMeta(meta));
          dispatch(setGalleryPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_GALLERY_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_GALLERY_BY_ID_FAILURE,
  };
};

const getGalleryById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetGalleryLoading(true));
    await API.getGalleryById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error: { type: string; message: string | string[] }) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_GALLERY_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_GALLERY_FAILURE,
  };
};

const createGallery = (payload: FormData, type?: 'MANAGEMENT' | 'EDITOR') => {
  return async (dispatch: any) => {
    dispatch(setActionGalleryLoading(true));
    await API.createGallery(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
          if (type === 'MANAGEMENT') Utils.redirect(ROUTERS.GALLERY_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_GALLERY_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_GALLERY_FAILURE,
  };
};

const activeGallery = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setActionGalleryLoading(true));
    await API.activeGallery(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(activeFail());
        else dispatch(activeSuccess());
        await dispatch(fetchGalleries(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_GALLERY_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_GALLERY_FAILURE,
  };
};

const deactiveGallery = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setActionGalleryLoading(true));
    await API.deactiveGallery(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(deactiveFail());
        else dispatch(deactiveSuccess());
        await dispatch(fetchGalleries(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

const terminateSuccess = () => {
  return {
    type: ACTION_TYPES.TERMINATE_GALLERY_SUCCESS,
  };
};

const terminateFail = () => {
  return {
    type: ACTION_TYPES.TERMINATE_GALLERY_FAILURE,
  };
};

const terminateGallery = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setActionGalleryLoading(true));
    await API.terminateGallery(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(terminateFail());
        else dispatch(terminateSuccess());
        await dispatch(fetchGalleries(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(terminateFail());
      });
  };
};

export default {
  fetchGalleries,
  getGalleryById,
  createGallery,
  activeGallery,
  deactiveGallery,
  resetGalleryReducer,
  terminateGallery,
};
