import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IUpdateUser } from '@interfaces/User.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetUserLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_MANAGEMENT_GET_LOADING,
    payload,
  };
};

const setFetchUserLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_MANAGEMENT_FETCH_LOADING,
    payload,
  };
};

const setUserActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_USER_MANAGEMENT_ACTION_LOADING,
    payload,
  };
};

const setUserPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_USER_TABLE_PAGINATION,
    payload,
  };
};

const setUserMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_USER_TABLE_META,
    payload,
  };
};

const setUserProfile = (payload: IUpdateUser | null) => {
  return {
    type: ACTION_TYPES.SET_USER_PROFILE,
    payload,
  };
};

const resetUserReducer = () => {
  return {
    type: ACTION_TYPES.RESET_USER_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_USERS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_USERS_FAILURE,
  };
};

const fetchUsers = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchUserLoading(true));
    await API.fetchUsers(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setUserMeta(meta));
          dispatch(setUserPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdateUser) => {
  return {
    type: ACTION_TYPES.GET_USER_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_USER_BY_ID_FAILURE,
  };
};

const getUserById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetUserLoading(true));
    await API.getUserById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_USER_FAILURE,
  };
};

const createNewUser = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading(true));
    await API.createUser(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
          Utils.redirect(ROUTERS.USER_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = (payload: IUpdateUser) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_USER_FAILURE,
  };
};

const updateUser = (payload: FormData, id: string) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading(true));
    await API.updateUser(payload, id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          await dispatch(updateSuccess(result));
          Utils.redirect(ROUTERS.USER_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_USER_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_USER_FAILURE,
  };
};

const activeUser = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading(true));
    await API.activeUser(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(activeFail());
        else dispatch(activeSuccess());
        await dispatch(fetchUsers(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_USER_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_USER_FAILURE,
  };
};

const deactiveUser = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading(true));
    await API.deactiveUser(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(deactiveFail());
        else dispatch(deactiveSuccess());
        await dispatch(fetchUsers(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

const terminateSuccess = () => {
  return {
    type: ACTION_TYPES.TERMINATE_USER_SUCCESS,
  };
};

const terminateFail = () => {
  return {
    type: ACTION_TYPES.TERMINATE_USER_FAILURE,
  };
};

const terminateUser = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setUserActionLoading(true));
    await API.terminateUser(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(terminateFail());
        else dispatch(terminateSuccess());
        await dispatch(fetchUsers(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(terminateFail());
      });
  };
};

export default {
  setUserPagination,
  fetchUsers,
  getUserById,
  createNewUser,
  updateUser,
  activeUser,
  deactiveUser,
  resetUserReducer,
  setUserProfile,
  terminateUser,
};
