import React, { useEffect } from 'react';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { Grid, Button } from '@mui/material';

import DefaultLayout from '@/Components/DefaultLayout';
import { Popper, DataTable } from '@/Components/LayoutPart';
import { ActiveBreadCrumb } from '@/Components/Common';
import { UserManagementActions } from '@actions';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

import Utils from '@/Utils';
import { ROUTERS } from '@/Constants';
import { useTypedDispatch, RootState } from '@/store';

const { fetchUsers, resetUserReducer } = UserManagementActions;

const UserManagement: React.FC = () => {
  // Constuctors
  const dispatch = useTypedDispatch();
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.USER_MANAGEMENT, 'pagination')
  );
  useEffect(() => {
    dispatch(fetchUsers(pagination));
    return () => {
      dispatch(resetUserReducer());
    };
  }, []);

  const _renderTopSection = () => {
    return (
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={8}>
          <Popper.FilterPopper
            data={pagination}
            onApply={(data) => dispatch(fetchUsers(data))}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            size="large"
            sx={{ float: 'right' }}
            variant="contained"
            color="burntSienna"
            onClick={() => Utils.redirect(ROUTERS.CREATE_USER)}
          >
            New User
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderBottomSection = () => {
    return <DataTable.UserManagementTable />;
  };

  const renderMain = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ActiveBreadCrumb screen="USER_MANAGEMENT" />
        </Grid>
        <Grid item xs={12}>
          {_renderTopSection()}
        </Grid>
        <Grid item xs={12}>
          {_renderBottomSection()}
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default UserManagement;
