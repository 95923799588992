import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IUpdatePost } from '@interfaces/Post.interface';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';

// SINGLE ACTIONS
const setGetNewsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NEWS_MANAGEMENT_GET_LOADING,
    payload,
  };
};

const setFetchNewsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NEWS_MANAGEMENT_FETCH_LOADING,
    payload,
  };
};

const setNewsActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_NEWS_MANAGEMENT_ACTION_LOADING,
    payload,
  };
};

const setNewsPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_NEWS_TABLE_PAGINATION,
    payload,
  };
};

const setNewsMeta = (payload: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_NEWS_TABLE_META,
    payload,
  };
};

const setNewsDetail = (payload: IUpdatePost | null) => {
  return {
    type: ACTION_TYPES.SET_NEWS_DETAIL,
    payload,
  };
};

const resetNewsReducer = () => {
  return {
    type: ACTION_TYPES.RESET_NEWS_REDUCER,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_NEWS_FAILURE,
  };
};

const fetchNews = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setFetchNewsLoading(true));
    await API.fetchPosts(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setNewsMeta(meta));
          dispatch(setNewsPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: IUpdatePost) => {
  return {
    type: ACTION_TYPES.GET_NEWS_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_NEWS_BY_ID_FAILURE,
  };
};

const getNewsById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setGetNewsLoading(true));
    await API.getPostById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_NEWS_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_NEWS_FAILURE,
  };
};

const createNewNews = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading(true));
    await API.createPost(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess());
          Utils.redirect(ROUTERS.NEWS_MANAGEMENT);
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = (payload: IUpdatePost) => {
  return {
    type: ACTION_TYPES.UPDATE_NEWS_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_NEWS_FAILURE,
  };
};

const updateNews = (id: string, payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading(true));
    await API.updatePost(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_NEWS_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_NEWS_FAILURE,
  };
};

const activeNews = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading(true));
    await API.activePost(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(activeFail());
        else dispatch(activeSuccess());
        await dispatch(fetchNews(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const deactiveSuccess = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_NEWS_SUCCESS,
  };
};

const deactiveFail = () => {
  return {
    type: ACTION_TYPES.DEACTIVE_NEWS_FAILURE,
  };
};

const deactiveNews = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading(true));
    await API.deactivePost(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(deactiveFail());
        else dispatch(deactiveSuccess());
        await dispatch(fetchNews(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deactiveFail());
      });
  };
};

const publishSuccess = () => {
  return {
    type: ACTION_TYPES.PUBLISH_NEWS_SUCCESS,
  };
};

const publishFail = () => {
  return {
    type: ACTION_TYPES.PUBLISH_NEWS_FAILURE,
  };
};

const publishNews = (id: string, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading(true));
    await API.publishPost(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(publishFail());
        else dispatch(publishSuccess());
        await dispatch(fetchNews(payload));
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(publishFail());
      });
  };
};

export default {
  setNewsPagination,
  fetchNews,
  getNewsById,
  createNewNews,
  updateNews,
  activeNews,
  deactiveNews,
  resetNewsReducer,
  setNewsDetail,
  publishNews,
};
