import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Backdrop, CircularProgress } from '@mui/material';
import { RootState } from '@/store';

const Loading = () => {
  // Constructors
  const startLoading: boolean = useSelector((state: RootState) =>
    _.get(state.WIDGET, 'startLoading')
  );

  const loading = useMemo(() => {
    if (startLoading)
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      );
    return null;
  }, [startLoading]);

  return loading;
};

export default Loading;
