import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';

// SINGLE ACTIONS
const setFetchFloatSocialLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_FLOAT_SOCIAL_FETCH_LOADING,
    payload,
  };
};

// ASYNC ACTIONS
const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_FLOAT_SOCIAL_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_FLOAT_SOCIAL_FAILURE,
  };
};

const fetchFloatSocial = () => {
  return async (dispatch: any) => {
    dispatch(setFetchFloatSocialLoading(true));
    await API.fetchSocialLinks()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          if (!_.isEmpty(result)) {
            dispatch(fetchSuccess(result));
          }
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

export default {
  fetchFloatSocial,
};
