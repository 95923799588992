import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const initialState = {
  ...DEFAULT_LOADING_STATES,
  requestHasError: false,
  requestIsSuccess: false,
  isRefreshTokenSuccess: false,
  isChangePasswordSuccess: false,
  selfProfile: null,
  forgotStep: 0,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_AUTH_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_AUTH_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_CHANGE_PASSWORD_STATUS: {
      return {
        ...state,
        isChangePasswordSuccess: false,
      };
    }

    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
        selfProfile: null,
      };
    case ACTION_TYPES.SET_RESET_PASSWORD_STEP:
      return {
        ...state,
        forgotStep: payload,
      };

    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isRefreshTokenSuccess: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };

    case ACTION_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isRefreshTokenSuccess: true,
      };
    case ACTION_TYPES.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isRefreshTokenSuccess: false,
      };
    case ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
        forgotStep: 1,
      };
    case ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        forgotStep: 0,
      };
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
        forgotStep: 3,
      };
    case ACTION_TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        forgotStep: 0,
      };

    case ACTION_TYPES.GET_SELF_PROFILE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isGetLoading: false,
        selfProfile: payload,
      };
    case ACTION_TYPES.GET_SELF_PROFILE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
        selfProfile: null,
      };
    case ACTION_TYPES.UPDATE_SELF_PROFILE_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
        selfProfile: payload,
      };
    case ACTION_TYPES.UPDATE_SELF_PROFILE_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        selfProfile: null,
      };
    case ACTION_TYPES.UPDATE_SELF_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
        isChangePasswordSuccess: true,
      };
    case ACTION_TYPES.UPDATE_SELF_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        isChangePasswordSuccess: false,
      };
    case ACTION_TYPES.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.VERIFY_ACCOUNT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
