import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { PaginationActions } from '@/Components/Common';
import { Dialogs } from '@/Components/LayoutPart';

import { CountryScoringAction } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@interfaces/PaginationMeta.interface';
import { CommonColors, TableStatusColor } from '@/Themes';
import { TABLES, ENUMS, ROUTERS, DEFAULT_CONFIRM } from '@constants';

import { tableFooterStyles, tableContainerStyles } from './DataTable.styles';
import Utils from '@/Utils';
import { IDropdownOption } from '@/Interfaces/Dropdown.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';

const STATUS_DROPDOWN = [
  {
    label: 'Approved',
    value: ENUMS.STATUS.APPROVAL,
    disabled: false,
  },
  {
    label: 'Reviewing',
    value: ENUMS.STATUS.REVIEW,
    disabled: false,
  },
  {
    label: 'Waiting for Approval',
    value: ENUMS.STATUS.WAITING_APPROVAL,
    disabled: true,
  },
  {
    label: 'Deleted',
    value: ENUMS.STATUS.TERMINATED,
    disabled: true,
  },
];

const { COUNTRY_SCORING_TABLE_HEADER } = TABLES;
// Declare actions
const { fetchCountryScoring, approveScoring, removeScoring, unApproveScoring } =
  CountryScoringAction;

const CountryScoringManagement: React.FC = () => {
  // Declare reducers, dispatch
  const dispatch = useTypedDispatch();
  const scoringList: any =
    useSelector((state: RootState) =>
      _.get(state.COUNTRY_SCORING_MANAGEMENT, 'scoringList')
    ) || [];
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'pagination')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_SCORING_MANAGEMENT, 'isFetchLoading')
  );
  const [confirmDelete, setConfirmDelete] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const [confirmChangeStatus, setConfirmChangeStatus] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const colSpan = COUNTRY_SCORING_TABLE_HEADER.length + 2;
  // Events
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let page = pagination.page;
    if (newPage === 0) page = 1;
    else if (newPage < page) page -= 1;
    else if (newPage >= page) page += 1;
    dispatch(fetchCountryScoring({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchCountryScoring({ ...pagination, limit: perPage }));
  };

  const onChangeOption = (value: string, id: string) => {
    let description = ``;
    if (value === ENUMS.STATUS.APPROVAL)
      description = `This country scoring will be approval.`;
    if (value === ENUMS.STATUS.REVIEW)
      description = `This country scoring will be send back to reviewer.`;
    setConfirmChangeStatus({
      isOpen: true,
      message: description,
      state: {
        id: id,
        status: value,
      },
    });
  };

  const handleSubmitChangeStatus = (state: { id?: string; status: string }) => {
    const { id, status } = state;
    if (id && status === ENUMS.STATUS.APPROVAL)
      dispatch(approveScoring(id, pagination));
    else if (id && status === ENUMS.STATUS.REVIEW)
      dispatch(unApproveScoring(id, pagination));
    else dispatch(fetchCountryScoring(pagination));
    setConfirmChangeStatus(DEFAULT_CONFIRM);
  };

  const onCancelChangeStatus = () => {
    setConfirmChangeStatus(DEFAULT_CONFIRM);
    dispatch(fetchCountryScoring(pagination));
  };

  const handleClickDelete = (id: string) => {
    setConfirmDelete({
      isOpen: true,
      message: `This country scoring will be delete.`,
      state: {
        id: id,
        status: '',
      },
    });
  };

  const handleSubmitDelete = (state: { id?: string; status: string }) => {
    if (state?.id) {
      dispatch(removeScoring(state.id, pagination));
    }
    setConfirmDelete(DEFAULT_CONFIRM);
  };

  const onCancelDelete = () => {
    setConfirmDelete(DEFAULT_CONFIRM);
    dispatch(fetchCountryScoring(pagination));
  };

  // Renders
  const _renderTableHeader = useMemo(() => {
    return (
      <TableRow>
        <TableCell width={450} sx={{ fontWeight: 700 }}>
          Title
        </TableCell>
        {_.map(COUNTRY_SCORING_TABLE_HEADER, (item) => (
          <TableCell
            key={`head-${item.value}`}
            width={170}
            sx={{ fontWeight: 700 }}
          >
            {item.label}
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: 700 }}>
          Status
        </TableCell>
        <TableCell align="center" width={270} sx={{ fontWeight: 700 }}>
          Actions
        </TableCell>
      </TableRow>
    );
  }, [COUNTRY_SCORING_TABLE_HEADER]);

  const _renderTableBody = () => {
    if (_.isEmpty(scoringList))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no scoring to display
          </TableCell>
        </TableRow>
      );
    return _.map(scoringList, (item, index) => {
      return (
        <TableRow hover key={index}>
          <TableCell>{item?.title}</TableCell>
          {_.map(COUNTRY_SCORING_TABLE_HEADER, (head, innerKey) => (
            <TableCell key={`row${innerKey}-${index}`}>
              {_.get(item, head.value)}
            </TableCell>
          ))}
          <TableCell key={`status.${index}`} align="center">
            <TextField
              sx={{
                minWidth: 110,
                '& .MuiSelect-select': {
                  color:
                    _.get(TableStatusColor, _.get(item, 'status')) ||
                    CommonColors.black,
                },
                '& .MuiSelect-select.MuiOutlinedInput-input.Mui-disabled': {
                  opacity: 1,
                  WebkitTextFillColor: 'unset',
                },
              }}
              defaultValue={item?.status}
              key={`status${Math.random()}`}
              select
              fullWidth
              size="small"
              onChange={(e) =>
                item?.status === ENUMS.STATUS.TERMINATED
                  ? {}
                  : onChangeOption(e?.target?.value, item.id)
              }
              disabled={item?.status === ENUMS.STATUS.TERMINATED}
            >
              {STATUS_DROPDOWN.map(
                (option: IDropdownOption, innerKey: number) => {
                  return (
                    <MenuItem
                      key={`${option.label}-${innerKey}`}
                      value={option.value}
                      disabled={option?.disabled}
                      sx={{
                        '&.Mui-disabled.MuiMenuItem-gutters.Mui-selected': {
                          opacity: 1,
                        },
                        color:
                          _.get(TableStatusColor, _.get(option, 'value')) ||
                          CommonColors.black,
                        display:
                          item?.status === option?.value ||
                          option?.value === ENUMS.STATUS.TERMINATED ||
                          (item?.status === ENUMS.STATUS.APPROVAL &&
                            option?.value === ENUMS.STATUS.WAITING_APPROVAL)
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <Typography fontSize={'14px'}>
                        {_.upperCase(option.label)}
                      </Typography>
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </TableCell>
          <TableCell key={`action.${index}`} align="center">
            <Tooltip title="View Detail">
              <IconButton
                sx={{ color: CommonColors.bismark }}
                onClick={() => {
                  Utils.redirect(ROUTERS.ADMIN_COUNTRY_SCORING_DETAIL, {
                    id: item.id,
                  });
                }}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  sx={{
                    color: CommonColors.bismark,
                  }}
                  onClick={() => handleClickDelete(item.id)}
                  disabled={item?.status === ENUMS.STATUS.TERMINATED}
                >
                  <DeleteOutlineIcon
                    color={
                      item?.status === ENUMS.STATUS.TERMINATED
                        ? 'inherit'
                        : 'error'
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    if (!meta) return null;
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={colSpan + 1}
            count={meta.totalItems}
            rowsPerPage={meta.itemsPerPage}
            page={meta.currentPage - 1}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan + 1} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Dialogs.Confirm
        confirm={confirmDelete}
        onCancel={() => onCancelDelete()}
        callback={() => handleSubmitDelete(confirmDelete.state)}
      />
      <Dialogs.Confirm
        confirm={confirmChangeStatus}
        onCancel={() => onCancelChangeStatus()}
        callback={() => handleSubmitChangeStatus(confirmChangeStatus.state)}
      />
      {_renderTable()}
    </Box>
  );
};

export default CountryScoringManagement;
