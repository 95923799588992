import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { pushNotification, pushAlert } from './Widget.utils';
import {
  S3,
  GetObjectCommand,
  GetObjectCommandOutput,
} from '@aws-sdk/client-s3';
import { COUNTRY_ISO_MAPPING } from '@/Constants/CountryScoring';

import { history } from '@/store';
import { ROUTERS, ENUMS } from '@/Constants';

import { INavAbout } from '@/Interfaces/PageManagement.interface';

const s3Client = new S3({
  credentials: {
    accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  },
  region: import.meta.env.VITE_AWS_REGION,
});

// Check network connection
const checkNetworkConnection = () => {
  return navigator.onLine;
};

// Redirect screen
const redirect = (location: string, state?: any) => {
  return history.push(location, state);
};

const replace = (location: string, state?: any) => {
  return history.replace(location, state);
};

// Check life of token
const checkTokenLifeTime = (token: string | null) => {
  if (!token) {
    pushAlert({
      message: 'Please login to continue...',
      type: 'warning',
    });
    return false;
  }
  const decodedToken: any = jwt_decode(token);
  const dateNow = new Date();
  if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
    pushNotification({
      message: 'Your token has been expired!',
      type: 'error',
    });
    return false;
  }
  return true;
};

// Return app run mode
const checkAppMode = (modeType?: string) => {
  if (modeType) return import.meta.env.MODE === modeType;
  return import.meta.env.MODE;
};

// Sleep for delay
const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const createMarkup = (html: any) => {
  return { __html: html };
};

const generateScoringProcess = (data?: {
  [key: string]: number;
}): { label: string; process: string; isCompleted: boolean }[] => {
  if (!data)
    return [
      {
        label: 'Area 1',
        process: `0 / 45`,
        isCompleted: false,
      },
      {
        label: 'Area 2',
        process: `0 / 51`,
        isCompleted: false,
      },
      {
        label: 'Area 3',
        process: `0 / 55`,
        isCompleted: false,
      },
    ];
  let area1 = 0;
  let area2 = 0;
  let area3 = 0;
  _.forEach(data, (value, key) => {
    if (_.includes(key, 'area_1_sub')) area1 += value;
    if (_.includes(key, 'area_2_sub')) area2 += value;
    if (_.includes(key, 'area_3_sub')) area3 += value;
  });
  return [
    {
      label: 'Area 1',
      process: `${area1} / 45`,
      isCompleted: area1 === 45,
    },
    {
      label: 'Area 2',
      process: `${area2} / 51`,
      isCompleted: area2 === 51,
    },
    {
      label: 'Area 3',
      process: `${area3} / 55`,
      isCompleted: area3 === 55,
    },
  ];
};

const generateReviewScoringProcess = (data?: {
  [key: string]: {
    totalAccepted: number;
    totalDenied: number;
    totalReviewed: number;
    dataComment?: any;
  };
}): {
  label: string;
  process: string;
  accepted?: string;
  denied?: string;
  isCompleted: boolean;
  isSendBack: boolean;
}[] => {
  if (!data)
    return [
      {
        label: 'Area 1',
        process: `0 / 45`,
        isCompleted: false,
        isSendBack: false,
      },
      {
        label: 'Area 2',
        process: `0 / 51`,
        isSendBack: false,
        isCompleted: false,
      },
      {
        label: 'Area 3',
        process: `0 / 55`,
        isSendBack: false,
        isCompleted: false,
      },
    ];
  let area1TotalReviewed = 0;
  let area2TotalReviewed = 0;
  let area3TotalReviewed = 0;

  let area1TotalAccepted = 0;
  let area2TotalAccepted = 0;
  let area3TotalAccepted = 0;

  let area1TotalDenied = 0;
  let area2TotalDenied = 0;
  let area3TotalDenied = 0;
  _.forEach(data, (value, key) => {
    if (_.includes(key, 'area_1_sub')) {
      area1TotalReviewed += value.totalReviewed;
      area1TotalAccepted += value.totalAccepted || 0;
      area1TotalDenied += value.totalDenied || 0;
    }
    if (_.includes(key, 'area_2_sub')) {
      area2TotalReviewed += value.totalReviewed;
      area2TotalAccepted += value.totalAccepted || 0;
      area2TotalDenied += value.totalDenied || 0;
    }
    if (_.includes(key, 'area_3_sub')) {
      area3TotalReviewed += value.totalReviewed;
      area3TotalAccepted += value.totalAccepted || 0;
      area3TotalDenied += value.totalDenied || 0;
    }
  });
  return [
    {
      label: 'Area 1',
      process: `Total indicators reviewed: ${area1TotalReviewed} / 45`,
      accepted: `Total indicators accepted: ${area1TotalAccepted}`,
      denied: `Total indicators denied: ${area1TotalDenied}`,
      isCompleted: area1TotalAccepted - area1TotalDenied === 45,
      isSendBack: area1TotalAccepted + area1TotalDenied === 45,
    },
    {
      label: 'Area 2',
      process: ` Total indicators reviewed: ${area2TotalReviewed} / 51`,
      accepted: `Total indicators accepted: ${area2TotalAccepted}`,
      denied: `Total indicators denied: ${area2TotalDenied}`,
      isCompleted: area2TotalAccepted - area2TotalDenied === 51,
      isSendBack: area2TotalAccepted + area2TotalDenied === 51,
    },
    {
      label: 'Area 3',
      process: `Total indicators reviewed: ${area3TotalReviewed} / 55`,
      accepted: `Total indicators accepted: ${area3TotalAccepted}`,
      denied: `Total indicators denied: ${area3TotalDenied}`,
      isCompleted: area3TotalAccepted - area3TotalDenied === 55,
      isSendBack: area3TotalAccepted + area3TotalDenied === 55,
    },
  ];
};

const getFileFromURL = async (url: string, filename: string) => {
  const resolveUrl = url.replace(/\\/g, '/').replace('src/storage/file', '');
  const blob = await fetch(resolveUrl, {}).then((r) => r.blob());
  const file = new File([blob], filename);
  return file;
};

const getURLFromFile = async (file: File) => {
  try {
    const dataURL = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event?.target?.result);
      };
      reader.readAsDataURL(file);
    });
    return dataURL;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const detectValidPayload = (
  payload: {
    key: string;
    value: any;
    type: string;
    isUpperCase?: boolean;
  }[]
) => {
  let isValid = true;
  const invalidMsg: any = {};
  if (!_.isEmpty(payload) && _.isArray(payload)) {
    _.forEach(
      payload,
      (item: {
        key: string;
        value: any;
        type: string;
        isUpperCase?: boolean;
      }) => {
        const { key, value, type, isUpperCase } = item;
        let message = '';
        const convertKey = isUpperCase
          ? _.upperCase(key.replace(/([A-Z])/g, ' $1').trim())
          : _.capitalize(key.replace(/([A-Z])/g, ' $1').trim());
        if (type === 'text') {
          const isString = _.toString(_.trim(value));
          if (!isString) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
            else message = `${convertKey} is invalid format`;
          }
        }
        if (type === 'mail-string-array' && typeof value === 'string') {
          if (!_.trim(value)) message = `${convertKey} cannot be empty`;
          else {
            const resolveValud = value;
            const emails = resolveValud.split(',');
            const regexEmail = new RegExp(
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g
            );
            const result = emails.every((email: string) =>
              email.match(regexEmail)
            );
            message = result
              ? ''
              : `${convertKey} contains value is invalid email format`;
          }
        }
        if (type === 'number') {
          const convertNumber = _.toNumber(_.trim(value));
          if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          else if (_.isNaN(convertNumber))
            message = `${convertKey} invalid format`;
        }
        if (type === 'age') {
          const convertNumber = _.toNumber(value);
          if (_.isNaN(convertNumber)) message = `${convertKey} invalid format`;
          else if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          else if (_.isNumber(convertNumber) && convertNumber < 18)
            message = `${convertKey} at least 18 years old`;
        }
        if (type === 'file') {
          const isFile = value instanceof File;
          if (!isFile) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          }
        }
        if (type === 'email') {
          const isEmail = String(value)
            .toLowerCase()
            .match(
              /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            );
          if (!isEmail) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty!`;
            else message = 'Email is invalid format!';
          }
        }
        if (type === 'account') {
          const isEmail = String(value)
            .toLowerCase()
            .match(
              /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            );
          if (!isEmail) {
            if (_.isEmpty(value)) message = `Email cannot be empty`;
            else message = 'Email is invalid format';
          }
        }
        if (type === 'date') {
          if (!value) message = `${convertKey} cannot be empty`;
          else {
            const dateCheck = dayjs(`${value}`).isValid();
            if (!dateCheck) message = `${convertKey} is invalid format`;
          }
        }

        if (type === 'time') {
          if (!value) message = `${convertKey} cannot be empty`;
          else {
            const timeCheck = dayjs(`${value}`, 'HH:mm:ss', true).isValid();
            if (!timeCheck) message = `${convertKey} is invalid format`;
          }
        }

        if (type === 'web') {
          const isWebsite = String(value).match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
          );
          if (!isWebsite) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
            else message = 'Website is invalid format';
          }
        }
        if (type === 'password') {
          if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          else {
            const isPassword = String(value).match(
              /[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]$/
            );
            if (!isPassword)
              message = `${convertKey} contains characters that aren't allowed`;
            if (String(value).length < 6)
              message = `${convertKey} contains least at 6 characters`;
          }
        }
        if (!_.isEmpty(message)) isValid = false;
        _.assign(invalidMsg, { [key]: message });
      }
    );
  }
  return { isValid, invalidMsg };
};

const asStream = (response: GetObjectCommandOutput) => {
  return response.Body as ReadableStream;
};

const asBlob = async (response: GetObjectCommandOutput) => {
  return new Response(asStream(response)).blob();
};

const getAWSFileAsBlob = (url: string, filename: string, type?: string) => {
  return new Promise(async (resolve) => {
    const key: string = _.last(url.split('/')) || '';
    try {
      const command = new GetObjectCommand({
        Bucket: import.meta.env.VITE_AWS_PUBLIC_BUCKET_NAME,
        Key: key,
        ResponseContentType: 'application/json',
      });

      const response = await s3Client.send(command);
      if (response.Body) {
        const blobFile = await asBlob(response);
        let file;
        if (type === 'pdf')
          file = new File([blobFile], filename, {
            type: 'application/pdf',
          });
        else file = new File([blobFile], filename);
        resolve(file);
      }
      resolve(null);
    } catch (err) {
      resolve(null);
    }
  });
};

const removeAccents = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
};

const getCountryISO2 = (countryCode?: string) => {
  if (!countryCode) return null;
  return COUNTRY_ISO_MAPPING[countryCode as keyof typeof COUNTRY_ISO_MAPPING];
};

const getFormatDate = (value: any, typeFormat?: string) => {
  if (value) {
    const getValue =
      typeof value === 'string' ? value.replaceAll(',', '') : value;
    return dayjs(getValue).format(typeFormat || 'MMM D, YYYY');
  }
  return 'No Date';
};
const getFormatTime = (value: any, typeFormat?: string) => {
  if (value) {
    const getValue =
      typeof value === 'string' ? value.replaceAll(',', '') : value;
    return dayjs(getValue).format(typeFormat || 'MMM D, YYYY');
  }
  return 'No Date';
};

const directNavAbout = (link: INavAbout) => {
  switch (link) {
    case 'guidelines':
      return redirect(ROUTERS.GUIDELINES);
    case 'the-monitoring-matrix-toolkit':
      return redirect(ROUTERS.ABOUT_MMTOOLKIT);
    case 'methodology':
      return redirect(ROUTERS.METHODOLOGY);
    default:
      return redirect(ROUTERS.ABOUT_BACKGROUND);
  }
};

const generateStatusLabel = (text: string) => {
  let newKey;
  const findKey = _.find(Object.values(ENUMS.STATUS), (value: string) =>
    text.includes(value)
  );
  if (findKey) {
    switch (findKey) {
      case ENUMS.STATUS.WAITING_PUBLISH:
        newKey = ENUMS.STATUS.IN_PROGRESS;
        break;
      case ENUMS.STATUS.TERMINATED:
        newKey = ENUMS.STATUS.DELETED;
        break;
      case ENUMS.STATUS.REVIEW:
        newKey = ENUMS.STATUS.REVIEWING;
        break;
      default:
        newKey = findKey;
        break;
    }
    return _.replace(text, findKey, newKey);
  } else return text;
};

const moveToTop = () => {
  document.getElementById('moveToTop')?.click();
};

const isEmptyOrSpaces = (str: string | null) =>
  str === null || str.match(/^ *$/) !== null;

export {
  checkNetworkConnection,
  redirect,
  checkTokenLifeTime,
  checkAppMode,
  sleep,
  createMarkup,
  generateScoringProcess,
  getFileFromURL,
  generateReviewScoringProcess,
  detectValidPayload,
  getAWSFileAsBlob,
  removeAccents,
  getCountryISO2,
  getFormatDate,
  directNavAbout,
  replace,
  generateStatusLabel,
  moveToTop,
  isEmptyOrSpaces,
  getFormatTime,
  getURLFromFile,
};
