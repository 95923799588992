import { useEffect } from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import { NewTabFindings } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { LIST } from '@/Constants';
import { CommonColors } from '@/Themes';
import { ChartComponent } from '@/Components/Common';

const { REGIONAL_REPORT_USER_LIST } = LIST.DROPDOWN_LIST;
const { getRegionalReportFindings, resetNewTabFindingsReducer } =
  NewTabFindings;

const OpenNewTabFindings = () => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const indicatorColor = CommonColors.mainColor;
  const year = searchParams.get('year');
  const areasCode = searchParams.get('code');
  const subAreasCode = searchParams.get('subCode');
  const standardsCode = searchParams.get('standCode');

  const chartForYear: any = useSelector((state: RootState) =>
    _.get(state.NEW_TAB_FINDINGS, 'chartForYear')
  );

  useEffect(() => {
    dispatch(
      getRegionalReportFindings({
        year: year,
        code: areasCode,
        subCode: subAreasCode,
      })
    );
    return () => {
      dispatch(resetNewTabFindingsReducer());
    };
  }, []);

  const currentItems = _.filter(
    REGIONAL_REPORT_USER_LIST,
    (el) => el.value === areasCode
  );

  const _renderChart = (subCode: any, standardCode: any) => {
    if (!_.isEmpty(areasCode) && !chartForYear) return null;
    const getChartArea: any = _.find(chartForYear, ['code', areasCode]);
    if (!getChartArea) return null;
    const getChartSubArea = _.find(getChartArea?.charts, ['code', subCode]);
    if (!getChartSubArea) return null;
    const getChart = _.find(getChartSubArea?.charts, ['code', standardCode]);
    return (
      <Box component="div" width={{ sm: 1, md: 1 }}>
        <ChartComponent.BarChart
          title=""
          verticalText="Score (%)"
          horizontalText=""
          data={getChart?.chartData}
          axis="x"
          isShowLegend={false}
        />
        <ChartComponent.ChartScoring />
      </Box>
    );
  };

  const _renderContent = () => {
    return (
      <Box>
        {_.map(currentItems, (area, keyArea) => {
          const subAreaLabel = _.filter(
            area.subAreas,
            (el) => el.value === subAreasCode
          );
          return (
            <Box key={keyArea} sx={{ px: 8, py: 4 }}>
              <Box>
                {_.map(subAreaLabel, (subAreas, keySubAreas) => {
                  const standardLabel = _.filter(
                    subAreas?.standards,
                    (el) => el.value === standardsCode
                  );
                  return (
                    <Box key={keySubAreas}>
                      <Box>
                        {_.map(standardLabel, (standard, keyStandard) => {
                          return (
                            <Box key={keyStandard} ml={2} mt={1}>
                              <Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex">
                                    <AcUnitIcon
                                      sx={{
                                        color: indicatorColor,
                                        fontSize: 12,
                                        mt: 1,
                                      }}
                                    />
                                    <Typography ml={1} variant="h6">
                                      {standard?.label}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    px: 10,
                                    mb: 14,
                                  }}
                                >
                                  {_renderChart(subAreasCode, standardsCode)}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  return <Box>{_renderContent()}</Box>;
};
export default OpenNewTabFindings;
