import { sendRequest } from '@/Configs';
import { API } from '@constants';

const { NOFITICATION } = API;

export const fetchNotifications = async (payload?: any) => {
  return sendRequest(NOFITICATION.ROOT, 'GET', payload);
};

export const getNotificationById = async (id: string) => {
  return sendRequest(`${NOFITICATION.GET_BY_ID}${id}`, 'GET');
};

export const maskAsReadNotifications = async (payload?: FormData) => {
  return sendRequest(NOFITICATION.MARK_AS_READ, 'PUT', payload);
};
