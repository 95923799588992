import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import AuthReducer from './Authentication.reducer';
import WidgetReducer from './Widget.reducer';
import UserReducer from './UserManagement.reducer';
import CountryReducer from './Country.reducer';
import NewsManagenentReducer from './NewsManagenent.reducer';
import BackgroundReducer from './BackgroundHistory.reducer';
import GuidelinesReducer from './GuideLine.reducer';
import MethodologyReducer from './Methodology.reducer';
import MMToolkitReducer from './MMToolkit.reducer';
import NewsUserReducer from './NewsUser.reducer';
import CommentReducer from './Comment.reducer';
import NewsCarouselReducer from './NewsCarousel.reducer';
import UserScoringReducer from './UserScoring.reducer';
import CountryReportsReducer from './CountryReports.reducer';
import ReviewerScoringReducer from './ReviewerScoring.reducer';
import FooterReducer from './Footer.reducer';
import RegionalRePortReducer from './RegionalReport.reducer';
import CountryScoring from './CountryScoring.reducer';
import SocialLink from './SocialLink.reducer';
import FloatSocialReducer from './FloatSocial.reducer';
import RegionalReportsUserReducer from './RegionalReportsUser.reducer';
import NotificationsDashboardReducer from './Notifications.dashboard.reducer';
import UserNotificationReducer from './UserNotification.reducer';
import CompareAndExploreReducer from './CompareAndExplore.reducer';
import GalleryReducer from './Gallery.reducer';
import ReviewerCountryReportsReducer from './ReviewerCountryReports.reducer';
import NewTabFindingsReducer from './NewTabFindings.reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    ROUTER: connectRouter(history),
    AUTH: AuthReducer,
    WIDGET: WidgetReducer,
    USER_MANAGEMENT: UserReducer,
    COUNTRY: CountryReducer,
    NEWS_MANAGEMENT: NewsManagenentReducer,
    BACKGROUND_HISTORY: BackgroundReducer,
    GUIDELINES: GuidelinesReducer,
    METHODOLOGY: MethodologyReducer,
    MM_TOOLKIT: MMToolkitReducer,
    NEWS_USER: NewsUserReducer,
    COMMENT: CommentReducer,
    NEWS_CAROUSEL: NewsCarouselReducer,
    USER_SCORING: UserScoringReducer,
    COUNTRY_REPORTS: CountryReportsReducer,
    REVIEWER_SCORING: ReviewerScoringReducer,
    FOOTER: FooterReducer,
    REGIONAL_REPORT: RegionalRePortReducer,
    COUNTRY_SCORING_MANAGEMENT: CountryScoring,
    SOCIAL_LINKS: SocialLink,
    FLOAT_SOCIAL: FloatSocialReducer,
    REGIONAL_REPORTS_USER: RegionalReportsUserReducer,
    NOTIFICATION_DASHBOARD: NotificationsDashboardReducer,
    USER_NOTIFICATION: UserNotificationReducer,
    COMPARE_EXPLORE: CompareAndExploreReducer,
    GALLERY: GalleryReducer,
    REVIEWER_COUNTRY_REPORTS: ReviewerCountryReportsReducer,
    NEW_TAB_FINDINGS: NewTabFindingsReducer,
  });
export default createRootReducer;
