import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { MenuItem, TextField, Grid, Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DefaultLayout from '@/Components/DefaultLayout';
import { ActiveBreadCrumb } from '@/Components/Common';
import { DataTable } from '@/Components/LayoutPart';

import Utils from '@/Utils';
import { ENUMS, DEFAULT_PAGINATION } from '@/Constants';
import { useTypedDispatch, RootState } from '@/store';
import { CountryReportsActions, CountryActions } from '@/Actions';
import { IPaginationFilter } from '@interfaces/PaginationMeta.interface';

const { fetchCountryReports } = CountryReportsActions;
const { fetchAllCountries } = CountryActions;
interface ICountryPayloadStructure {
  id: string;
  name: string;
  status: string | boolean;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  extraData?: any;
}

interface ICountryOptions {
  label: string;
  value: string;
  code: string;
}

const { STATUS } = ENUMS;

const STATUS_OPTIONS = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: 'PUBLISHED',
    value: STATUS.PUBLISH,
  },
  {
    label: 'IN PROGRESS',
    value: STATUS.WAITING_PUBLISH,
  },
  {
    label: 'EDITING',
    value: STATUS.EDITING,
  },
  {
    label: 'DELETED',
    value: STATUS.TERMINATED,
  },
];

const APCountryReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const countries: ICountryPayloadStructure[] = useSelector(
    (state: RootState) => _.get(state.COUNTRY, 'countries') || null
  );

  const [filter, setFilter] = useState<IPaginationFilter>(DEFAULT_PAGINATION);

  const [countryOptions, setCountryOptions] = useState<ICountryOptions[]>([]);
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');

  useEffect(() => {
    dispatch(fetchAllCountries());
    return () => {
      setFilter(DEFAULT_PAGINATION);
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(countries)) {
      const resolveCountries = _.map(
        countries,
        (country: ICountryPayloadStructure) => {
          return {
            label: country.name,
            value: country.id,

            code:
              // countryCode alpha 2: Kosovo
              country?.extraData?.code === 'CS-KM'
                ? 'XK'
                : Utils.getCountryISO2(country?.extraData?.code) || '',
          };
        }
      );

      setCountryOptions(resolveCountries);
    }
  }, [countries]);

  useEffect(() => {
    if (filter) {
      const resolveStatus = filter?.status === 'all' ? '' : filter?.status;
      dispatch(
        fetchCountryReports({
          ...filter,
          status: resolveStatus,
          orderBy: 'DESC',
          sortBy: 'year',
        })
      );
    }
  }, [filter]);

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = Utils.getFormatDate(newValue, 'YYYY');
      if (!dayjs(newValue).isBefore(dayjs()) || +yearSelect < 1900)
        setIsValidDateMsg('Please choose year valid');
      else {
        setIsValidDateMsg('');
        setFilter({
          ...filter,
          year: yearSelect,
        });
      }
    } else {
      setIsValidDateMsg('');
      setFilter(_.omit(filter, ['year']));
    }
  };

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            select
            sx={{ width: 300 }}
            label="Search by country"
            value={filter?.countryId ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilter({ ...filter, countryId: e.target.value })
            }
          >
            {_.map(countryOptions, (country, index) => {
              return (
                <MenuItem key={index} value={country.value}>
                  <Box
                    component="div"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  >
                    <Box
                      component="img"
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${_.lowerCase(
                        country.code
                      )}.png`}
                      srcSet={`https://flagcdn.com/w40/${_.lowerCase(
                        country.code
                      )}.png 2x`}
                      alt=""
                    />
                    {country.label}
                  </Box>
                </MenuItem>
              );
            })}
          </TextField>
          <DatePicker
            inputFormat="YYYY"
            views={['year']}
            label="Year"
            value={filter.year || null}
            onChange={handleChangeYear}
            minDate={new Date('2013')}
            maxDate={new Date()}
            renderInput={(params: any) => (
              <TextField
                {...params}
                sx={{ width: 180 }}
                error={!!isValidDateMsg}
                helperText={isValidDateMsg}
              />
            )}
          />
          <TextField
            select
            sx={{ width: 200 }}
            label="Status"
            value={filter?.status || 'all'}
            defaultValue=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilter({
                ...filter,
                status: e.target.value,
              })
            }
          >
            {_.map(STATUS_OPTIONS, (status, index) => {
              return (
                <MenuItem key={index} value={status.value}>
                  {status.label}
                </MenuItem>
              );
            })}
          </TextField>
          <Button
            size="large"
            variant="outlined"
            onClick={() => setFilter(DEFAULT_PAGINATION)}
            sx={{ height: 56 }}
          >
            Clear Filters
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderDataTable = () => {
    return <DataTable.CountryReportTable />;
  };

  const renderMain = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ActiveBreadCrumb screen="COUNTRY_REPORT" />
        </Grid>
        <Grid item xs={12}>
          {_renderTopSection()}
        </Grid>
        <Grid item xs={12}>
          {_renderDataTable()}
        </Grid>
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default APCountryReport;
