import React, { useState, useEffect, useMemo, memo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Stack,
  Tab,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  Divider,
  Breadcrumbs,
  Skeleton,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';
import {
  NoDataWereFound,
  PreviewPDF,
  SkeletonLoading,
} from '@/Components/Common';

import Utils from '@/Utils';
import { useTypedDispatch, RootState } from '@/store';
import { CountryReportsActions, CountryActions } from '@/Actions';
import { CommonColors } from '@/Themes';

import { IDropdownOption } from '@/Interfaces/Dropdown.interface';

import Finding from './Finding';
import ExecutiveSummary from './ExecutiveSummary';
import CSOverview from './CSOverview';

interface ICountryPayloadStructure {
  id: string;
  name: string;
  status: string | boolean;
  extraData?: any;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface ICountryOptions {
  label: string;
  value: string;
  code: string;
}

const { fetchAllCountries } = CountryActions;
const { getForVisitorCountryReport, resetCountryReportsReducer } =
  CountryReportsActions;

const SWITCH_PREVIEW_DROPDOWN = [
  {
    label: 'English',
    value: 'ENGLISH',
  },
  {
    label: 'Local',
    value: 'LOCAL',
  },
];

const CountryReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isGetLoading')
  );

  const isGetCountryLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'isGetLoading')
  );

  const countryReportDetails = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'getForVisitor')
  );

  const countries: ICountryPayloadStructure[] = useSelector(
    (state: RootState) => _.get(state.COUNTRY, 'countries') || null
  );

  const [countryOptions, setCountryOptions] = useState<ICountryOptions[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  // const [selectedYear, setSelectedYear] = useState<string | null>(
  //   dayjs().year(2013).toString() as unknown as string
  // );
  const [selectedYear, setSelectedYear] = useState<string | null>('');

  const [details, setDetails] = useState<any>(null);
  const [value, setValue] = useState<string>('keyFindings');
  const valueRef = React.useRef<any>(null);
  valueRef.current = value;

  const [selectedPreview, setSelectedPreview] = useState<string>('ENGLISH');
  const [urlPreview, setUrlPreview] = useState<string>('');
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');
  const [locationCode, setLocationCode] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  useEffect(() => {
    dispatch(fetchAllCountries());
    return () => {
      setIsLoadingImage(true);
      dispatch(resetCountryReportsReducer());
    };
  }, []);

  useEffect(() => {
    if (locationState?.code) setLocationCode(locationState?.code);
    if (locationState?.country) setValue('findings');
    if (locationState?.year) setSelectedYear(locationState?.year);
    if (!_.isEmpty(locationState?.id) || !_.isEmpty(locationState?.country)) {
      setSelectedCountry(locationState.id || locationState?.country);
    }
  }, [locationState, countryOptions]);

  useEffect(() => {
    if (!_.isEmpty(locationCode)) setLocationCode('');
  }, [value]);

  useEffect(() => {
    if (!_.isEmpty(countries)) {
      const resolveCountries = _.map(
        countries,
        (country: ICountryPayloadStructure) => {
          if (country?.extraData?.code === 'CS-KM') {
            return {
              label: country.name,
              value: country.id,
              code: 'XK', // countryCode alpha 2: Kosovo
            };
          }
          return {
            label: country.name,
            value: country.id,
            code: Utils.getCountryISO2(country?.extraData?.code) || '',
          };
        }
      );
      setCountryOptions(resolveCountries);
    }
  }, [countries]);

  useEffect(() => {
    if (
      !_.isEmpty(selectedCountry) &&
      (countryReportDetails?.year !== dayjs(selectedYear).year().toString() ||
        selectedCountry !== countryReportDetails?.country?.id)
    ) {
      setIsLoadingImage(true);
      dispatch(
        getForVisitorCountryReport({
          countryId: selectedCountry,
          year: !_.isEmpty(selectedYear)
            ? _.toString(dayjs(selectedYear).year())
            : '',
        })
      );
    }
  }, [selectedCountry, selectedYear]);

  useEffect(() => {
    generateDetails();
  }, [countryReportDetails]);

  useEffect(() => {
    if (selectedPreview === 'ENGLISH')
      setUrlPreview(_.get(details, 'fileEnglish.path') || '');
    else setUrlPreview(_.get(details, 'fileLocal.path') || '');
  }, [selectedPreview, details]);

  const generateDetails = () => {
    if (!_.isEmpty(countryReportDetails)) {
      const {
        title,
        backgroundCountry,
        conclusionRecommendation,
        country,
        executive,
        finding,
        year,
        logo,
        fileEnglish,
        fileLocal,
      } = countryReportDetails;
      const parseExecutive = executive && JSON.parse(executive);
      const parseFinding = finding && JSON.parse(finding);
      const resolveDetails = {
        title,
        backgroundCountry,
        conclusionRecommendation,
        country,
        executive: parseExecutive,
        finding: parseFinding,
        year,
        logo,
        fileEnglish,
        fileLocal,
      };
      setSelectedYear(dayjs().year(countryReportDetails.year).toString());
      setDetails(resolveDetails);
    } else setDetails(null);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: string) =>
    setValue(newValue);

  const handleChangePreview = (valueSelect: string) => {
    setSelectedPreview(valueSelect);
  };

  const _renderCountryName = (countryId: string) => {
    const findCountryById = _.find(
      countryOptions,
      (country) => country.value === countryId
    );
    return (
      findCountryById && (
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color="lemonGrass"
            sx={{
              fontSize: matches ? '2.2em' : '2em',
              fontWeight: '900',
              letterSpacing: '4px',
              mb: 1,
            }}
            textAlign="center"
          >
            {findCountryById?.label}
          </Typography>
          <Typography variant={matches ? 'h5' : 'h6'} textAlign="center">
            {details?.title}
          </Typography>
        </Grid>
      )
    );
  };

  const _renderSearchByCountry = useMemo(() => {
    return (
      <TextField
        select
        fullWidth
        label="Search by country"
        value={selectedCountry ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSelectedCountry(e.target.value)
        }
      >
        {_.map(countryOptions, (country, index) => {
          return (
            <MenuItem key={index} value={country.value}>
              <Box component="div" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                <Box
                  component="img"
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${_.lowerCase(
                    country.code
                  )}.png`}
                  srcSet={`https://flagcdn.com/w40/${_.lowerCase(
                    country.code
                  )}.png 2x`}
                  alt=""
                />
                {country.label}
              </Box>
            </MenuItem>
          );
        })}
      </TextField>
    );
  }, [selectedCountry, countryOptions, matches]);

  const _renderTopSection = () => (
    <Grid
      container
      sx={{
        mb: {
          sx: 1,
          sm: 2,
          md: 3,
          lg: 4,
        },
      }}
    >
      <Grid
        item
        md={4}
        sm={12}
        sx={{
          mt: {
            md: 0,
            lg: 2,
          },
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            sx={{
              color: CommonColors.mainColor,
              letterSpacing: 5,
              textAlign: 'center',
            }}
            variant="h4"
          >
            Country Report
          </Typography>
        </Breadcrumbs>
      </Grid>
      {matches && (
        <Grid item xs={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              mb: 2,
            }}
          >
            <Box sx={{ minWidth: '300px' }}>{_renderSearchByCountry}</Box>
            <Box
              sx={{
                minWidth: 'max-content',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <DatePicker
                inputFormat="YYYY"
                views={['year']}
                label="Year"
                value={selectedYear}
                onChange={(newValue: string | null) => {
                  if (dayjs(newValue).isValid()) {
                    setSelectedYear(newValue);
                    setIsValidDateMsg('');
                  } else
                    setIsValidDateMsg(
                      newValue
                        ? 'Year invalid date/time format'
                        : 'Please choose a year to view country report'
                    );
                }}
                minDate={dayjs().year(2013).toString()}
                maxDate={dayjs().toString()}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    sx={{ width: 150 }}
                    error={!_.isEmpty(isValidDateMsg)}
                  />
                )}
              />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider sx={{ background: CommonColors.mainColor }} />
      </Grid>
      {!matches && (
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={12}>
            {_renderSearchByCountry}
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              inputFormat="YYYY"
              views={['year']}
              label="Year"
              value={selectedYear}
              onChange={(newValue: string | null) => {
                if (dayjs(newValue).isValid()) {
                  setSelectedYear(newValue);
                  setIsValidDateMsg('');
                } else
                  setIsValidDateMsg(
                    newValue
                      ? 'Year invalid date/time format'
                      : 'Please choose a year to view country report'
                  );
              }}
              maxDate={dayjs() as unknown as string}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{ width: 150 }}
                  error={!_.isEmpty(isValidDateMsg)}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const _renderPreviewPDF = useMemo(() => {
    if (!urlPreview) return null;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => window.open(urlPreview)}
            sx={{ mb: 2 }}
          >
            View Full Screen
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PreviewPDF file={urlPreview} />
        </Grid>
      </Grid>
    );
  }, [urlPreview]);

  const _renderBottomSection = () => {
    return (
      <Grid container spacing={2}>
        {_renderCountryName(selectedCountry)}
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            {details?.logo && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  // alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Implemented by:
                </Typography>
                <Skeleton
                  sx={{
                    height: 150,
                    width: 200,
                    display: isLoadingImage ? 'block' : 'none',
                  }}
                />
                <Box
                  sx={{
                    maxHeight: 150,
                    maxWidth: 200,
                    objectFit: 'cover',
                    ml: 1,
                    display: isLoadingImage ? 'none' : 'block',
                  }}
                  component="img"
                  onLoad={() => setIsLoadingImage(false)}
                  src={details?.logo?.path}
                  alt={details?.logo?.nameOriginal}
                />
              </Grid>
            )}
            {/* <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="end">
                <Grid item xs={6} md={5} lg={4}>
                  {details?.fileEnglish && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="oceanGreen"
                      endIcon={<CloudDownloadIcon />}
                      onClick={() => window.open(details?.fileEnglish?.path)}
                    >
                      English
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6} md={6} lg={5} xl={4}>
                  {details?.fileLocal && (
                    <Button
                      variant="contained"
                      fullWidth
                      endIcon={<CloudDownloadIcon />}
                      color="oceanGreen"
                      onClick={() => window.open(details?.fileLocal?.path)}
                    >
                      Local
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="tab-container"
          //remove padding mui tab
          sx={{
            '& .MuiTabPanel-root': {
              padding: 0,
              mt: 1,
            },
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  sx={{ fontWeight: 'bold' }}
                  label="Executive Summary"
                  value="keyFindings"
                />
                <Tab
                  sx={{ fontWeight: 'bold' }}
                  label="CS Overview"
                  value="CSOverview"
                />
                <Tab
                  sx={{ fontWeight: 'bold' }}
                  label="Background"
                  value="background"
                />
                <Tab
                  sx={{ fontWeight: 'bold' }}
                  label="Findings"
                  value="findings"
                />
                <Tab
                  sx={{ fontWeight: 'bold' }}
                  label="Recommendations"
                  value="conclusionsAndRecomendations"
                />
                <Tab
                  sx={{ fontWeight: 'bold' }}
                  label="PDF"
                  value="previewPDF"
                />
              </TabList>
            </Box>
            <TabPanel value="keyFindings">
              <ExecutiveSummary payload={details?.executive?.key} />
            </TabPanel>
            <TabPanel value="CSOverview">
              <CSOverview payload={details?.executive?.civil} />
            </TabPanel>
            <TabPanel value="background">
              {details?.backgroundCountry ? (
                <Box
                  component="div"
                  sx={{
                    img: {
                      maxWidth: '100%',
                      objectFit: 'cover',
                      wordWrap: 'break-word',
                    },
                  }}
                  dangerouslySetInnerHTML={Utils.createMarkup(
                    details?.backgroundCountry
                  )}
                />
              ) : (
                <NoDataWereFound />
              )}
            </TabPanel>
            <TabPanel value="findings">
              <Finding
                payload={details?.finding}
                year={details?.year}
                country={details?.country?.id}
                code={locationCode}
              />
            </TabPanel>
            <TabPanel value="conclusionsAndRecomendations">
              {details?.conclusionRecommendation ? (
                <Box
                  component="div"
                  sx={{
                    img: {
                      maxWidth: '100%',
                      objectFit: 'cover',
                      wordWrap: 'break-word',
                    },
                  }}
                  dangerouslySetInnerHTML={Utils.createMarkup(
                    details?.conclusionRecommendation
                  )}
                />
              ) : (
                <NoDataWereFound />
              )}
            </TabPanel>
            <TabPanel value="backgroundCountryOverView">
              {details?.backgroundCountry ? (
                <Box
                  component="div"
                  sx={{
                    img: {
                      maxWidth: '100%',
                      objectFit: 'cover',
                      wordWrap: 'break-word',
                    },
                  }}
                  dangerouslySetInnerHTML={Utils.createMarkup(
                    details?.backgroundCountry
                  )}
                />
              ) : (
                <NoDataWereFound />
              )}
            </TabPanel>
            <TabPanel value="previewPDF">
              <Grid container spacing={3} mt={1}>
                {(details?.fileEnglish || details?.fileLocal) && (
                  <Grid item>
                    <TextField
                      defaultValue={selectedPreview}
                      key={`status${Math.random()}`}
                      select
                      size="medium"
                      sx={{ width: 200 }}
                      onChange={(e: any) => handleChangePreview(e.target.value)}
                    >
                      {SWITCH_PREVIEW_DROPDOWN.map(
                        (option: IDropdownOption, innerIndex: number) => {
                          return (
                            <MenuItem
                              key={`${option.label}-${innerIndex}`}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        }
                      )}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {_renderPreviewPDF}
                  {!urlPreview && <NoDataWereFound />}
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Stack>
        {_renderTopSection()}
        {!isGetLoading && !isGetCountryLoading ? (
          _renderBottomSection()
        ) : (
          <SkeletonLoading numberRender={5} />
        )}
      </Stack>
    );
  };

  return <DefaultLayout content={renderMain()} />;
};

export default memo(CountryReport);
