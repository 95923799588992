import UserManagementTable from './UserManagement.table';
import NewsManagementTable from './NewsManagement.table';
import CountryReportTable from './CountryReport.table';
import UserCountryReportTable from './UserCountryReport.table';
import ReviewerCountryReportTable from './ReviewerCountryReport.table';
import CountryManagement from './CountryManagement.table';
import CountryScoringManagement from './CountryScoringManagement.table.';
import OverViewUserTable from './OverviewUser.table';
import KeyFindingsRecommendationsUser from './KeyFidingsRecommendationsUser.table';
import RegionalReportTable from './RegionalReportsManagement.table';
import MyScoringUserTable from './MyScoringUser.table';
import MyScoringReviewerTable from './MyScoringReviewer.table';

export default {
  UserManagementTable,
  NewsManagementTable,
  CountryReportTable,
  UserCountryReportTable,
  ReviewerCountryReportTable,
  CountryManagement,
  CountryScoringManagement,
  OverViewUserTable,
  KeyFindingsRecommendationsUser,
  RegionalReportTable,
  MyScoringUserTable,
  MyScoringReviewerTable,
};
