import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import {
  TextField,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import DefaultLayout from '@/Components/DefaultLayout';
import {
  DragDropUpload,
  UploadImage,
  ActiveBreadCrumb,
} from '@/Components/Common';

import DialogMessages from './DialogMessages';
import UPBackground from './Background';
import UPExecutiveSummary from './ExecutiveSummary';
import CSOverview from './CSOverview';
import Subareas from './Subareas';
import UPConclusionsAndRecommendations from './ConclusionsAndRecommendations';

import { CountryReportsActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';
import { ENUMS, ROUTERS, LIST, DEFAULT_CONFIRM } from '@/Constants';
import { DEFAULT_COUNTRY_REPORT } from '@/Constants/CountryScoring';
import { DEFAULT_LABEL_SUB_AREA } from '@/Constants/CountryScoring/CountryReportCollection';

import { ICountryReportStructure } from '@/Interfaces/CountryReport.interface';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { Dialogs } from '@/Components/LayoutPart';

const { DROPDOWN_LIST } = LIST;
const { FINDING_MENU } = DROPDOWN_LIST;

const { createCountryReport } = CountryReportsActions;

type TSelectedOption =
  | 'keyFindings'
  | 'CSOverview'
  | 'background'
  | 'findings'
  | 'conclusionsAndRecommendations';

type FILE_KEY_TYPES = 'logo' | 'fileEnglish' | 'fileLocal';

const UPCreateCountryReport: React.FC = () => {
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COUNTRY_REPORTS, 'isActionLoading')
  );

  const [details, setDetails] = useState<ICountryReportStructure>({
    ...DEFAULT_COUNTRY_REPORT,
    year: new Date(),
  });
  const detailsRef = useRef<any>(null);
  detailsRef.current = details;

  const [logo, setLogo] = useState<File[]>([]);
  const [fileEnglish, setFileEnglish] = useState<File[]>([]);
  const [fileLocal, setFileLocal] = useState<File[]>([]);
  const [selectedOption, setSelectedOption] =
    useState<TSelectedOption>('keyFindings');

  const [messages, setMessages] = useState<any[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const [confirmBack, setConfirmBack] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    return () => {
      setDetails(DEFAULT_COUNTRY_REPORT);
      setLogo([]);
      setFileEnglish([]);
      setFileLocal([]);
      setMessages([]);
    };
  }, []);

  useEffect(() => {
    checkEmptyField();
  }, [details]);

  const onCancel = () => setIsOpenDialog(false);

  const handleBack = () => {
    setConfirmBack({
      isOpen: true,
      message: 'Changes are not saved',
      state: {
        id: '',
        status: '',
      },
    });
  };

  const handleSubmitBack = () => {
    Utils.redirect(ROUTERS.MY_REPORT);
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const onCancelBack = () => {
    setConfirmBack(DEFAULT_CONFIRM);
  };

  const checkEmptyField = () => {
    const messageErrors: any[] = [];
    const resolveDetails = _.omit(details, [
      'commentCountryReport',
      'countryId',
      'title',
    ]);
    _.forIn(resolveDetails, (value, key) => {
      if (_.isEmpty(value) && _.isString(value)) {
        messageErrors.push(`${_.startCase(key)} should not be empty!`);
      }
      //resolve executive
      if (key === 'executive') {
        for (const [_key, _value] of Object.entries(value)) {
          if (_key === 'key' && _value) {
            _.each(_value, (subVal, _subKey) => {
              //Check key findings & key recommandations empty value
              if (_.isArray(subVal)) {
                if (
                  _.isEmpty(subVal) ||
                  _.some(
                    subVal,
                    (v) => v && _.some(v, (_v) => Utils.isEmptyOrSpaces(_v))
                  )
                )
                  messageErrors.push(
                    `Key Findings & Recommendations: ${_.startCase(
                      _subKey
                    )} should not be empty!`
                  );
              } else if (_.isEmpty(subVal) && _.isString(subVal))
                messageErrors.push('Executive Summary should not be empty!');
            });
          }
          //Check key CSOverview empty value
          if (
            _key === 'civil' &&
            _value &&
            _.some(_value, (v) => Utils.isEmptyOrSpaces(v))
          )
            messageErrors.push(`CS Overview should not be empty!`);
        }
      }
      //resolve finding
      if (key === 'finding') {
        if (_.isEqual(value, DEFAULT_COUNTRY_REPORT.finding))
          messageErrors.push(`Finding should not be empty!`);
        else {
          _.forEach(value, (areas: any) => {
            _.forEach(Object.keys(areas), (subAreas: any) => {
              if (
                _.some(areas[subAreas], (standard) =>
                  Utils.isEmptyOrSpaces(standard)
                )
              ) {
                messageErrors.push(
                  `Findings: ${
                    DEFAULT_LABEL_SUB_AREA[
                      subAreas as keyof typeof DEFAULT_LABEL_SUB_AREA
                    ]
                  }  should not be empty!`
                );
              }
            });
          });
        }
      }
    });
    if (_.size(messageErrors) === 0) setIsCompleted(true);
    else setIsCompleted(false);
    setMessages(messageErrors);
  };

  const handleChangeOption = (value: TSelectedOption) =>
    setSelectedOption(value);

  const handleFilesChange = (key: FILE_KEY_TYPES, items: any[]) => {
    if (key === 'logo') setLogo(items);
    if (key === 'fileEnglish') setFileEnglish(items);
    if (key === 'fileLocal') setFileLocal(items);
  };

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = _.toNumber(Utils.getFormatDate(newValue, 'YYYY'));
      if (!dayjs(newValue).isBefore(dayjs()) || yearSelect < 1900)
        setIsValidDateMsg('Please choose year valid');
      else {
        setIsValidDateMsg('');
        setDetails({
          ...detailsRef?.current,
          year: dayjs(newValue).year().toString(),
        });
      }
    } else setIsValidDateMsg('Please choose year valid');
  };

  const handleSubmit = (event: any, confirmContinue?: boolean) => {
    event.preventDefault();
    const status = event.nativeEvent.submitter?.name;
    if (_.isEmpty(messages) || confirmContinue) {
      setIsOpenDialog(false);
      const formData = new FormData();
      if (details.title) formData.append('title', _.trim(details.title));
      if (details.year) {
        const resolveYear = _.toString(dayjs(details.year).year());
        formData.append('year', _.trim(resolveYear));
      }
      if (status) formData.append('status', _.trim(status));
      if (details.countryId)
        formData.append('countryId', _.trim(details.countryId));
      if (details.executive)
        formData.append('executive', JSON.stringify(details.executive));
      if (details.finding)
        formData.append('finding', JSON.stringify(details.finding));

      formData.append(
        'conclusionRecommendation',
        _.trim(details.conclusionRecommendation) || ''
      );

      formData.append(
        'backgroundCountry',
        _.trim(details.backgroundCountry) || ''
      );

      if (!_.isEmpty(logo)) formData.append('logo', logo[0]);
      if (!_.isEmpty(fileEnglish))
        formData.append('fileEnglish', fileEnglish[0]);
      if (!_.isEmpty(fileLocal)) formData.append('fileLocal', fileLocal[0]);
      dispatch(createCountryReport(formData));
    } else setIsOpenDialog(true);
  };

  const _renderDialogErrorMessages = () => (
    <DialogMessages
      onContinue={(e, confirm?: boolean) => handleSubmit(e, confirm)}
      isOpen={isOpenDialog}
      messages={messages}
      onCancel={() => onCancel()}
    />
  );

  const _renderCountryReportTitle = () => (
    <TextField
      label="Title"
      value={details.title}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setDetails({ ...detailsRef?.current, title: e.target.value });
      }}
      fullWidth
      disabled={isActionLoading}
    />
  );

  const _renderYearOptions = () => (
    <DatePicker
      inputFormat="YYYY"
      views={['year']}
      label="Year"
      value={details?.year}
      onChange={handleChangeYear}
      maxDate={new Date()}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={!_.isEmpty(isValidDateMsg)}
          helperText={isValidDateMsg}
          required
          fullWidth
        />
      )}
      disabled={isActionLoading}
    />
  );

  const _renderOptions = () => {
    return (
      <TextField
        select
        fullWidth
        value={selectedOption}
        label="Parts"
        onChange={(e) => handleChangeOption(e.target.value as TSelectedOption)}
      >
        {_.map(FINDING_MENU, (option, index) => {
          return (
            <MenuItem key={`${option.label}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const _renderAssets = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="h2" textTransform="uppercase">
            Attached files
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item lg={4} md={12} sm={12}>
              <UploadImage
                label="Partner logo"
                files={logo}
                onFileChange={(items: File[]) =>
                  handleFilesChange('logo', items)
                }
                disabled={isActionLoading}
                type="LOGO"
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6}>
              <DragDropUpload
                label="Upload report in English language"
                files={fileEnglish}
                onFileChange={(items: File[]) =>
                  handleFilesChange('fileEnglish', items)
                }
                type="PDF"
                disabled={isActionLoading}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6}>
              <DragDropUpload
                label="Upload report in local language"
                files={fileLocal}
                onFileChange={(items: File[]) =>
                  handleFilesChange('fileLocal', items)
                }
                type="PDF"
                disabled={isActionLoading}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const _renderContent = (type: TSelectedOption) => {
    switch (type) {
      case 'keyFindings':
        return (
          <UPExecutiveSummary
            payload={details?.executive?.key}
            onChange={(newPayload: any) => {
              setDetails({
                ...detailsRef?.current,
                executive: {
                  ...detailsRef?.current?.executive,
                  key: newPayload,
                },
              });
            }}
          />
        );
      case 'CSOverview':
        return (
          <CSOverview
            payload={details?.executive?.civil}
            onChange={(newPayload: any) => {
              setDetails({
                ...detailsRef?.current,
                executive: {
                  ...detailsRef?.current?.executive,
                  civil: newPayload,
                },
              });
            }}
          />
        );
      case 'findings':
        return (
          <Subareas
            payload={details?.finding}
            onUnfocus={(newPayload) =>
              setDetails({ ...detailsRef?.current, finding: newPayload })
            }
            hiddenToolbar
          />
        );
      case 'background':
        return (
          <UPBackground
            payload={details.backgroundCountry}
            onChange={(newContent: string) =>
              setDetails({
                ...detailsRef?.current,
                backgroundCountry: newContent,
              })
            }
          />
        );
      case 'conclusionsAndRecommendations':
        return (
          <UPConclusionsAndRecommendations
            payload={details.conclusionRecommendation}
            onChange={(newContent: string) =>
              setDetails({
                ...detailsRef?.current,
                conclusionRecommendation: newContent,
              })
            }
          />
        );
      default:
        return <></>;
    }
  };

  const _renderButtonGroup = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="burntSienna"
          onClick={() => handleBack()}
          startIcon={<ArrowBackIcon />}
          disabled={isActionLoading}
        >
          Back to my report
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="info"
            loading={isActionLoading}
            startIcon={<SaveAsIcon />}
            name={ENUMS.STATUS.DRAFT}
            disabled={!details.year || !_.isEmpty(isValidDateMsg)}
          >
            Save as draft
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="oceanGreen"
            loading={isActionLoading}
            disabled={!isCompleted}
            startIcon={<ScheduleSendIcon />}
            name={ENUMS.STATUS.REVIEW}
          >
            Send to reviewer
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          '::-webkit-validation-bubble-message': {
            zIndex: 99998, // header 99999
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <ActiveBreadCrumb screen="UP_COUNTRY_REPORT" />
          </Grid>
          <Grid item xs={9}>
            {_renderCountryReportTitle()}
          </Grid>
          <Grid item xs={3}>
            {_renderYearOptions()}
          </Grid>
          <Grid item xs={12}>
            {_renderAssets()}
          </Grid>
          <Grid item lg={3} md={6} sm={6}>
            {_renderOptions()}
          </Grid>
          <Grid item xs={12}>
            {_renderContent(selectedOption)}
          </Grid>
          <Grid item xs={12}>
            {_renderButtonGroup()}
          </Grid>
          <Grid item xs={12}>
            {_renderDialogErrorMessages()}
            <Dialogs.Confirm
              confirm={confirmBack}
              onCancel={onCancelBack}
              callback={() => handleSubmitBack()}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };
  return <DefaultLayout content={renderMain()} />;
};

export default UPCreateCountryReport;
