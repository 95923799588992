import { SxProps, Theme } from '@mui/system';

import { CommonColors } from '@/Themes';

const { bismark } = CommonColors;

export const mainStyles: SxProps<Theme> = {
  flexGrow: 1,
  position: 'fixed',
  zIndex: 3,
};

export const fabStyle = (pos: boolean): SxProps<Theme> => {
  return {
    width: '40px',
    height: '40px',
    position: 'fixed',
    bottom: 16,
    right: 68,
    flexDirection: 'row',
    opacity: !pos ? '0' : '1',
    transition: 'all .3s',
  };
};

export const speedDialStyles: SxProps<Theme> = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  '& .MuiFab-primary': {
    width: '40px',
    height: '40px',
    backgroundColor: `${bismark}`,
    marginTop: -2,
  },
};

export const iconSpeedDialStyles: SxProps<Theme> = {
  color: `${bismark}`,
  mt: 1,
};
