import { useMemo } from 'react';
import { Link, Box, SpeedDial, SpeedDialAction, Fab } from '@mui/material';
import {
  MailOutline,
  LinkedIn,
  Facebook,
  Twitter,
  KeyboardArrowUp,
  Share,
} from '@mui/icons-material';

import { useEffect } from 'react';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  fabStyle,
  iconSpeedDialStyles,
  mainStyles,
  speedDialStyles,
} from './FloatAction.styles';
import { FloatSocials } from '@/Actions';
import { IFloatSocial } from '@/Interfaces/FloatAction.interface';

const { fetchFloatSocial } = FloatSocials;

const FloatAction = (props: { callback?(): void; pos: boolean }) => {
  const dispatch = useTypedDispatch();
  const floatSocials: IFloatSocial = useSelector((state: RootState) =>
    _.get(state.FLOAT_SOCIAL, 'floatSocials')
  );
  const { callback, pos } = props;

  useEffect(() => {
    if (!floatSocials) dispatch(fetchFloatSocial());
  }, []);

  const moveToTop = () => {
    if (callback) callback();
  };

  const resolveIcon = (key: string, link: string) => {
    switch (key) {
      case 'facebook':
        return (
          <SpeedDialAction
            key={key}
            icon={
              <Link href={link} key={key} target="_blank">
                <Facebook sx={{ ...iconSpeedDialStyles, color: '#1877F2' }} />
              </Link>
            }
            tooltipTitle={'Facebook'}
          />
        );
      case 'twitter':
        return (
          <SpeedDialAction
            key={key}
            icon={
              <Link href={link} key={key} target="_blank">
                <Twitter sx={{ ...iconSpeedDialStyles, color: '#1DA1F2' }} />
              </Link>
            }
            tooltipTitle={'Twitter'}
          />
        );
      case 'instagram':
        return (
          <SpeedDialAction
            key={key}
            icon={
              <Link href={link} key={key} target="_blank">
                <LinkedIn sx={{ ...iconSpeedDialStyles, color: '#1976D2' }} />
              </Link>
            }
            tooltipTitle={'LinkedIn'}
          />
        );
      case 'email':
        return (
          <SpeedDialAction
            key={key}
            icon={
              <Link href={`mailto:${link}`} key={key} target="_blank">
                <MailOutline
                  sx={{ ...iconSpeedDialStyles, color: '#CD201F' }}
                />
              </Link>
            }
            tooltipTitle={'Email'}
          />
        );
      default:
        return null;
    }
  };

  const _renderActions = useMemo(() => {
    if (!floatSocials) return null;
    return _.map(floatSocials, (socialLink, key) => {
      if (socialLink) return resolveIcon(key, socialLink);
      return;
    });
  }, [floatSocials]);

  return (
    <Box sx={mainStyles}>
      <Fab
        onClick={moveToTop}
        sx={fabStyle(pos)}
        size="small"
        title="scroll to top"
        id="moveToTop"
      >
        <KeyboardArrowUp />
      </Fab>
      <SpeedDial
        ariaLabel="Social SpeedDial"
        sx={speedDialStyles}
        icon={<Share />}
      >
        {_renderActions}
      </SpeedDial>
    </Box>
  );
};

export default FloatAction;
