import React, { useMemo } from 'react';
import 'chart.js/auto';
import { ChartOptions, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';

interface ISectionProps {
  data: ChartData<'pie'>;
  title?: string;
  subtitle?: boolean;
  isShowLegend?: boolean;
}

const PieChart: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { data, title, subtitle = true, isShowLegend = false } = props;

  const options: ChartOptions<'pie'> = useMemo(() => {
    return {
      responsive: true,
      interaction: {
        intersect: false,
      },
      plugins: {
        title: {
          display: true,
          text: title,
          align: 'start',
          fullSize: true,
        },
        subtitle: {
          display: subtitle,
          align: 'center',
          position: 'right',
          text: '(*) Click on legend to show/hide element(s)',
        },
        legend: {
          display: isShowLegend,
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              let totalDatasetSum = 0;
              context.dataset.data.map((el) => (totalDatasetSum += el));
              const currentValue = context.dataset.data[context.dataIndex];
              const percentage = (currentValue * 100) / totalDatasetSum;
              const roundedPercentage = Math.round(percentage * 100) / 100;
              return `${context.label}: ${currentValue} (${roundedPercentage}%)`;
            },
          },
        },
      },
    };
  }, [props]);

  return <Pie options={{ ...options }} data={data} />;
};

export default PieChart;
