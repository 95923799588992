import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { CommonColors } from '@/Themes';
import { Breadcrumbs, Divider, Grid, Typography } from '@mui/material';

import Utils from '@/Utils';
import {
  USER_MANAGEMENT_BREADCRUMB,
  NEWS_MANAGEMENT_BREADCRUMB,
  REGIONAL_REPORT_BREADCRUMB,
  COUNTRY_REPORT_BREADCRUMB,
  COUNTRY_MANAGEMENT_BREADCRUMB,
  USER_SCORING_BREADCRUMB,
  REVIEWER_SCORING_BREADCRUMB,
  ADMIN_SCORING_BREADCRUMB,
  RP_COUNTRY_REPORT_BREADCRUMB,
  UP_COUNTRY_REPORT_BREADCRUMB,
} from '@/Constants';

import { BreadCrumbStucture } from '@/Interfaces/BreadCrumb';
import {
  DASHBOARD_REVIEWER_BREADCRUMB,
  NEWS_BREADCRUMB,
} from '@/Constants/BreadCrumb';
interface IScreenSctructure {
  screen:
    | 'USER_MANAGEMENT'
    | 'ACCOUNT'
    | 'DASHBOARD'
    | 'NEWS_MANAGEMENT'
    | 'NEWS'
    | 'REGIONAL_REPORT'
    | 'COUNTRY_REPORT'
    | 'UP_COUNTRY_REPORT'
    | 'RP_COUNTRY_REPORT'
    | 'COUNTRY_MANAGEMENT'
    | 'COUNTRY_SCORING'
    | 'USER_SCORING'
    | 'COUNTRY_SCORING_ADMIN'
    | 'NOTIFICATIONS'
    | 'DASHBOARD_REVIEWER';
}

const ActiveBreadCrumb: React.FC<IScreenSctructure> = ({
  screen,
}: IScreenSctructure) => {
  const { pathname } = useLocation();

  const _getBreadCrumbList = () => {
    switch (screen) {
      case 'USER_MANAGEMENT':
        return USER_MANAGEMENT_BREADCRUMB;
      case 'NEWS_MANAGEMENT':
        return NEWS_MANAGEMENT_BREADCRUMB;
      case 'NEWS':
        return NEWS_BREADCRUMB;
      case 'REGIONAL_REPORT':
        return REGIONAL_REPORT_BREADCRUMB;
      case 'COUNTRY_REPORT':
        return COUNTRY_REPORT_BREADCRUMB;
      case 'UP_COUNTRY_REPORT':
        return UP_COUNTRY_REPORT_BREADCRUMB;
      case 'RP_COUNTRY_REPORT':
        return RP_COUNTRY_REPORT_BREADCRUMB;
      case 'COUNTRY_MANAGEMENT':
        return COUNTRY_MANAGEMENT_BREADCRUMB;
      case 'COUNTRY_SCORING':
        return REVIEWER_SCORING_BREADCRUMB;
      case 'USER_SCORING':
        return USER_SCORING_BREADCRUMB;
      case 'COUNTRY_SCORING_ADMIN':
        return ADMIN_SCORING_BREADCRUMB;
      case 'DASHBOARD_REVIEWER':
        return DASHBOARD_REVIEWER_BREADCRUMB;
      default:
        return [];
    }
  };

  const _renderBreadCrub = () => {
    const options = _getBreadCrumbList();
    return _.map(options, (option: BreadCrumbStucture, index: number) => {
      const isActive = option.value === pathname;
      if ((option.type === 'child' && isActive) || option.type === 'parent') {
        return (
          <Typography
            sx={{
              cursor: isActive ? 'default' : 'pointer',
              pointerEvents: isActive ? 'none' : 'auto',
              color: isActive ? CommonColors.mainColor : CommonColors.loblolly,
              '&:hover': {
                color: CommonColors.mainColor,
              },
            }}
            key={index}
            component="div"
            variant="h4"
            onClick={() => Utils.redirect(option.value)}
          >
            {option.label}
          </Typography>
        );
      }
      return null;
    });
  };

  return (
    <Grid item xs={12}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {_renderBreadCrub()}
      </Breadcrumbs>
      <Divider sx={{ background: CommonColors.mainColor }} />
    </Grid>
  );
};
export default ActiveBreadCrumb;
