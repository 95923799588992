export default {
  AUTH: {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/changePassword',
    FORGOT_PASSWORD: 'auth/forgot-password',
    RESET_PASSWORD: 'auth/reset-password',
    REFRESH_TOKEN: 'auth/refresh',
    VERIFY_ACCOUNT: 'auth/verify-account',
  },
  USER: {
    FETCH: 'users/',
    GET_BY_ID: 'users/get-by-id/',
    GET_SELF: 'users/get-for-self',
    UPDATE: 'users/update/',
    UPDATE_SELF: 'users/update-for-self',
    UPDATE_SELF_PASSWORD: 'users/update-password-for-self',
    ACTIVE: 'users/activate/',
    DEACTIVE: 'users/deactivate/',
    REGISTER: 'users/register-account',
    TERMINATE: 'users/terminated/',
  },
  ROLE: {
    ROOT: 'roles/',
    GET_BY_ID: 'roles/get-by-id/',
    ACTIVE: 'roles/activate/',
    DEACTIVE: 'roles/deactivate/',
  },
  COUNTRY: {
    ROOT: 'countries/',
    GET_BY_ID: 'countries/get-by-id/',
    AVAIABLE: 'countries/not-assign',
    UPDATE: 'countries/',
    UNASSIGN_FOR_REVIEW: 'countries/unassigned-for-reviewer',
    UNASSIGN_FOR_USER: 'countries/unassigned-for-user',
  },
  POST: {
    ROOT: 'posts/',
    GET_BY_ID: 'posts/get-by-id/',
    ACTIVE: 'posts/activate/',
    DEACTIVE: 'posts/deactivate/',
    PUBLISH: 'posts/publish/',
  },
  COMMENT: {
    ROOT: 'comments',
    GET_BY_ID: 'comments/get-by-id/',
    ACTIVE: 'comments/activate/',
    DEACTIVE: 'comments/deactivate/',
  },
  TOOL_KIT: {
    ROOT: 'tool-kits/',
  },
  METHODOLOGY: {
    ROOT: 'methodologies/',
  },
  GUIDELINE: {
    ROOT: 'guidelines/',
  },
  BACKGROUND: {
    ROOT: 'backgrounds/',
  },
  COUNTRY_SCORE: {
    ROOT: 'country-scores/',
    GET_BY_ID: 'country-scores/get-by-id/',
    UPDATE: 'country-scores/update/',
    EDITING: 'country-scores/editing/',
    APPROVAL: 'country-scores/approval/',
    REVIEW: 'country-scores/review/',
    WAITING: 'country-scores/waiting-approval/',
    TERMINATE: 'country-scores/terminated/',
  },
  COUNTRY_REPORT: {
    ROOT: 'country-reports',
    CREATE: 'country-reports',
    UPDATE: 'country-reports/update/',
    GET_BY_ID: 'country-reports/get-by-id/',
    GET_FOR_VISITOR: 'country-reports/get-for-visitor',
    ACTIVE: 'country-reports/activate/',
    DEACTIVE: 'country-reports/deactivate/',
    TERMINATE: 'country-reports/terminated/',
    PUBLISH: 'country-reports/publish/',
    EDITING: 'country-reports/editing/',
    REVIEW: 'country-reports/review/',
    WAITING_PUBLIC: 'country-reports/waiting-publish/',
  },
  REGIONAL_REPORT: {
    ROOT: 'regional-reports/',
    CREATE: 'regional-reports',
    UPDATE: 'regional-reports/update/',
    GET_BY_ID: 'regional-reports/get-by-id/',
    GET_FOR_VISITOR: 'regional-reports/get-for-visitor',
    ACTIVE: 'regional-reports/activate/',
    PUBLISH: 'regional-reports/publish/',
    INACTIVE: 'regional-reports/deactivate/',
  },
  FOOTER: {
    ROOT: 'footers',
    CREATE: 'footers',
  },
  SOCIAL_LINKS: {
    ROOT: 'social-networks/',
    CREATE: 'social-networks/',
  },
  NOFITICATION: {
    ROOT: 'notifications/',
    GET_BY_ID: 'notifications/get-by-id/',
    MARK_AS_READ: 'notifications/mark-as-read/',
  },
  COMPARE_EXPLORE: {
    YEARS: 'explore-compare/across-years/',
    COUNTRIES: 'explore-compare/across-countries/',
  },
  GALLERY: {
    ROOT: 'galleries',
    CREATE: 'galleries',
    GET_BY_ID: 'galleries/get-by-id/',
    ACTIVE: 'galleries/activate/',
    DEACTIVE: 'galleries/deactivate/',
    TERMINATE: 'galleries/terminated/',
  },
};
