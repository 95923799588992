import { CommonColors } from '@/Themes';
import {
  Breadcrumbs,
  Divider,
  Grid,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

const mainTitleStyles: SxProps<Theme> = {
  color: CommonColors.mainColor,
  letterSpacing: 5,
  mb: 1,
};

interface ITitlePageAbout {
  title: string;
  sx?: SxProps;
}

const BreadCrumb: React.FC<ITitlePageAbout> = ({
  title,
  sx,
}: ITitlePageAbout) => {
  return (
    <Grid
      item
      sx={{
        width: 1,
        mb: {
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
        },
        ...sx,
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Typography sx={mainTitleStyles} variant="h4">
          {title}
        </Typography>
      </Breadcrumbs>
      <Divider sx={{ background: CommonColors.mainColor }} />
    </Grid>
  );
};
export default BreadCrumb;
