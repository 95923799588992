import React, { useRef, useState, useEffect, createRef, useMemo } from 'react';
import _ from 'lodash';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import SaveIcon from '@mui/icons-material/Save';
// import { TransitionSlide } from '@/Components/Common';
import { Scoring } from '@/Components/LayoutPart';
import {
  IIndicatorTemplate,
  ICreateTypeStandardScore,
  ICommentIndicator,
  ICreateIndicatorScore,
} from '@interfaces/CountryScoring.interface';
import { ENUMS } from '@constants';
import { CommonColors } from '@/Themes';

interface SectionProps {
  open: boolean;
  handleClose(data?: ICommentIndicator[]): void;
  indicators: IIndicatorTemplate[];
  dialogTitle: string;
  defaultStandard?: ICreateTypeStandardScore;
  defaultCommentIndicator?: ICommentIndicator[];
  isDisabledAll?: boolean;
}

const ScoringDialog: React.FC<SectionProps> = (props: SectionProps) => {
  // Constructors
  const {
    open,
    handleClose,
    indicators,
    dialogTitle,
    defaultStandard,
    defaultCommentIndicator,
    isDisabledAll,
  } = props;
  const [selectedIndicator, setSelectedIndicator] = useState<number>(0);
  const [callbackReview, setCallbackReview] = useState<ICommentIndicator[]>([]);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const dialogRef = useRef<HTMLInputElement>(null);

  const commentRef: any = useMemo(() => {
    if (!_.isEmpty(indicators))
      return new Array(indicators.length).map(() => createRef());
    return [];
  }, []);

  useEffect(() => {
    if (!open) {
      setCallbackReview([]);
      setErrorIndex(null);
      setSelectedIndicator(0);
    } else {
      const sortIndicator = _.sortBy(defaultStandard?.indicator, ['code']);
      const denyIndex = _.findIndex(sortIndicator, [
        'status',
        ENUMS.STATUS.DENY,
      ]);
      if (denyIndex !== -1) setSelectedIndicator(denyIndex);
      setCallbackReview(defaultCommentIndicator || []);
    }
  }, [open]);

  useEffect(() => {
    setRenderKey(Math.random());
  }, [callbackReview]);

  useEffect(() => {
    if (!_.isEmpty(defaultCommentIndicator)) setRenderKey(Math.random());
  }, [defaultCommentIndicator]);

  useEffect(() => {
    if (dialogRef?.current)
      dialogRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  }, [selectedIndicator]);

  // Events
  const onClose = async (reason?: string) => {
    if (reason === 'backdropClick') return;
    return handleClose();
  };

  const handleChangeIndex = (index: number) => {
    setSelectedIndicator(index);
  };

  const onChangeIndicator = (
    value: string,
    code: string,
    defaultIndicator?: ICreateIndicatorScore
  ) => {
    const findIndex = _.findIndex(callbackReview, ['code', code]);
    const newData = [...callbackReview];
    if (findIndex !== -1) {
      if (value) setErrorIndex(null);
      newData[findIndex] = { ...newData[findIndex], comment: value };
    } else if (defaultIndicator?.id) {
      // if (!value) return setErrorIndex(index);
      newData.push({
        id: defaultIndicator.id,
        code,
        comment: value,
        status: defaultIndicator?.status || ENUMS.STATUS.PENDING,
      });
      setErrorIndex(null);
    }
    setCallbackReview(newData);
  };

  const handleScrollToComment = (index: number) => {
    if (!_.isEmpty(commentRef)) {
      commentRef[index].scrollTo({
        top: commentRef[index].scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const onChangeIndicatorStatus = (
    value: string,
    code: string,
    indicatorIndex: number,
    defaultIndicator?: ICreateIndicatorScore
  ) => {
    const findIndex = _.findIndex(callbackReview, ['code', code]);
    const newData = [...callbackReview];
    if (findIndex !== -1) {
      newData[findIndex] = { ...newData[findIndex], status: value };
      if (!newData[findIndex].comment && value === ENUMS.STATUS.DENY) {
        setErrorIndex(indicatorIndex);
        handleScrollToComment(indicatorIndex);
        return;
      } else setErrorIndex(null);
    } else if (defaultIndicator?.id) {
      if (value === ENUMS.STATUS.DENY) {
        setErrorIndex(indicatorIndex);
        handleScrollToComment(indicatorIndex);
        return;
      } else {
        newData.push({
          id: defaultIndicator.id,
          code,
          comment: '',
          status: value,
        });
        setErrorIndex(null);
      }
    }
    if (selectedIndicator + 1 < indicators.length) {
      setSelectedIndicator(selectedIndicator + 1);
      setCallbackReview(newData);
    }
    if (selectedIndicator + 1 === indicators.length) {
      handleClose(newData);
      setCallbackReview([]);
    }
    return;
  };

  // Renders

  const _renderContent = () => {
    return _.map(indicators, (indicator, outerKey) => {
      const findIndicator = _.find(defaultStandard?.indicator || [], [
        'code',
        indicator.code,
      ]);
      const findReviewed = _.find(callbackReview, ['code', indicator.code]);
      const isDenied =
        _.get(
          _.isEmpty(findReviewed) ? findIndicator : findReviewed,
          'status'
        ) === ENUMS.STATUS.DENY;
      const isAccepted =
        _.get(
          _.isEmpty(findReviewed) ? findIndicator : findReviewed,
          'status'
        ) === ENUMS.STATUS.ACCEPT;
      const commentIndicator = _.get(findIndicator, 'commentIndicator') || [];
      let indicatorColor = CommonColors.mainColor;
      if (isAccepted) indicatorColor = CommonColors.oceanGreen;
      if (isDenied) indicatorColor = CommonColors.fuzzyWuzzyBrown;
      return (
        <Box key={indicator.code}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <Grid
                container
                sx={{
                  maxHeight: {
                    xs: '75vh',
                    md: '80vh',
                    lg: '65vh',
                  },
                  overflow: 'auto',
                }}
                spacing={2}
                ref={(ref) => (commentRef[outerKey] = ref)}
              >
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h6"
                    sx={{ color: indicatorColor }}
                  >
                    {indicator.codeName}: {indicator.name}
                    {isDenied && (
                      <DoNotDisturbIcon color="error" fontSize="large" />
                    )}
                    {isAccepted && (
                      <CheckIcon color="success" fontSize="large" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Scoring
                    indicator={indicator}
                    defaultValue={findIndicator}
                    callback={() => {}}
                    disabled
                  />
                </Grid>
                {!_.isEmpty(commentIndicator) && (
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" my={1}>
                      Comments:
                    </Typography>
                    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                      {_.map(commentIndicator, (comment, index) => {
                        return (
                          <Typography
                            my={1}
                            ml={2}
                            gutterBottom
                            key={_.get(comment, 'id')}
                          >
                            {index + 1}
                            {'. '}
                            {_.get(comment, 'content')}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} pb={3}>
                  <TextField
                    multiline
                    rows={3}
                    key={renderKey}
                    fullWidth
                    label="Comment"
                    placeholder="Comment..."
                    defaultValue={findReviewed?.comment}
                    disabled={isDisabledAll}
                    onFocus={() => setErrorIndex(null)}
                    onBlur={(e) => {
                      onChangeIndicator(
                        _.trim(e.target.value),
                        indicator.code,
                        findIndicator
                      );
                    }}
                    error={outerKey === errorIndex}
                    helperText={
                      outerKey === errorIndex
                        ? 'Please add reason for deny this indicator'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {!isDisabledAll && (
                <Grid container justifyContent="center" spacing={2} pb={1}>
                  <Grid item>
                    <Button
                      startIcon={<DoNotDisturbIcon />}
                      onClick={() =>
                        onChangeIndicatorStatus(
                          ENUMS.STATUS.DENY,
                          indicator.code,
                          outerKey,
                          findIndicator
                        )
                      }
                      variant="contained"
                      color="fuzzyWuzzyBrown"
                    >
                      {isDenied ? 'Denied' : 'Deny'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() =>
                        onChangeIndicatorStatus(
                          ENUMS.STATUS.ACCEPT,
                          indicator.code,
                          outerKey,
                          findIndicator
                        )
                      }
                      startIcon={<CheckIcon />}
                      variant="contained"
                      color="oceanGreen"
                    >
                      {isAccepted ? 'Accepted' : 'Accept'}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      );
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={(_e, r) => onClose(r)}
      // TransitionComponent={TransitionSlide}
      fullScreen
    >
      <DialogTitle sx={{ textTransform: 'uppercase' }}>
        {dialogTitle}
        <Grid container>
          <Grid item xs={1}>
            <Button
              startIcon={<NavigateBeforeIcon />}
              color="burntSienna"
              disabled={!selectedIndicator}
              onClick={() => setSelectedIndicator(selectedIndicator - 1)}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={10} />
          <Grid item xs={1} textAlign="right">
            <Button
              endIcon={<NavigateNextIcon />}
              color="burntSienna"
              disabled={selectedIndicator + 1 === indicators.length}
              onClick={() => setSelectedIndicator(selectedIndicator + 1)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent ref={dialogRef}>
        <SwipeableViews
          axis="x"
          index={selectedIndicator}
          onChangeIndex={handleChangeIndex}
        >
          {_renderContent()}
        </SwipeableViews>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CloseIcon />}
          onClick={() => onClose()}
          variant="contained"
          color="fuzzyWuzzyBrown"
        >
          {!isDisabledAll ? 'Discard & Close' : 'Close'}
        </Button>
        {!isDisabledAll && (
          <Button
            onClick={() => handleClose(callbackReview)}
            startIcon={<SaveIcon />}
            variant="contained"
            color="oceanGreen"
            disabled={_.isEmpty(callbackReview)}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ScoringDialog;
