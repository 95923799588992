import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@/Constants';
import API from '@/APIs';
import {
  IFindings,
  IFindingStructure,
  IRecommendations,
  IReportTemplate,
} from '@/Interfaces/RegionalReport';
import Utils from '@/Utils';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';

const fetchRegionalReportLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REGIONAL_REPORTS_FETCH_LOADING,
    payload,
  };
};

const getRegionalReportLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REGIONAL_REPORTS_GET_LOADING,
    payload,
  };
};

const setRegionalReportActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REGIONAL_REPORTS_ACTION_LOADING,
    payload,
  };
};

const setRegionalReportMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_REGIONAL_REPORT_META,
    payload,
  };
};

const setRegionalReportPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_COUNTRY_REGIONAL_REPORT_PAGINATION,
    payload,
  };
};

const clearDataCountryReports = () => {
  return {
    type: ACTION_TYPES.CLEAR_DATA_COUNTRY_REPORT,
  };
};

const resetDataRegionalReport = () => {
  return {
    type: ACTION_TYPES.RESET_REGIONAL_REPORT_REDUCER,
  };
};

const saveCountryFullOptions = (payload: string[]) => {
  return {
    type: ACTION_TYPES.SET_DATA_COUNTRY_FULL_OPTIONS,
    payload,
  };
};

const saveCountryOptions = (payload: string[]) => {
  return {
    type: ACTION_TYPES.SET_DATA_COUNTRY_OPTIONS,
    payload,
  };
};

const saveDefaultSubareas = (payload: IFindingStructure) => {
  return {
    type: ACTION_TYPES.SET_DATA_SUBAREAS,
    payload,
  };
};

const saveDefaultRecomment = (payload: any) => {
  return {
    type: ACTION_TYPES.SET_DATA_RECOMMEN,
    payload,
  };
};

const saveDefaultOverview = (payload: IReportTemplate[]) => {
  return {
    type: ACTION_TYPES.SET_DATA_OVERVIEW,
    payload,
  };
};

const saveDefaultRecommendations = (payload: IRecommendations) => {
  return {
    type: ACTION_TYPES.SET_DATA_RECOMMENDATIONS,
    payload,
  };
};

const saveDefaultAckowledgments = (payload: string) => {
  return {
    type: ACTION_TYPES.SET_DATA_ACKNOWLEDGMENTS,
    payload,
  };
};

const saveDefaultBackground = (payload: string) => {
  return {
    type: ACTION_TYPES.SET_DATA_BACKGROUND,
    payload,
  };
};

const saveDefaultFindings = (payload: IFindings[]) => {
  return {
    type: ACTION_TYPES.SET_DATA_FINDINGS_RECOMMENDATIONS,
    payload,
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_REGIONAL_REPORT_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_REGIONAL_REPORT_FAILURE,
  };
};

const createRegionalReport = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setRegionalReportActionLoading(true));
    await API.createRegionalReport(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
          Utils.redirect(ROUTERS.REGIONAL_REPORT_MANAGEMENT);
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_REGIONAL_REPORT_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_REGIONAL_REPORT_FAILURE,
  };
};

const updateRegionalReport = (id: string, payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setRegionalReportActionLoading(true));
    await API.updateRegionaReport(id, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          const finding: any = JSON.parse(_.get(result, 'finding'));
          const civilSociety = JSON.parse(_.get(result, 'civilSociety'));
          const fileReport = _.get(result, 'fileReport');
          const recommendation = JSON.parse(_.get(result, 'recommendation'));
          const data = {
            defaultSubareas: finding.findingState,
            defaultRecomment: finding.recommendations,
            defaultOverview: civilSociety,
            defaultBackground: _.get(result, 'background'),
            defaultAcknowledgments: _.get(result, 'acknowledgment'),
            defaultRecommendations: recommendation,
            defaultFindingsRecommendations: recommendation.findings,
            defaultReportYear: _.get(result, 'year'),
            defaultReportTitle: _.get(result, 'title'),
            defaultCountryOptions: _.map(civilSociety, 'value'),
            fileReport: fileReport,
          };
          dispatch(updateSuccess(data));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_REGIONAL_REPORTS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_REGIONAL_REPORTS_FAILURE,
  };
};

const fetchAllRegionalReports = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(fetchRegionalReportLoading(true));
    await API.fetchRegionalReports(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setRegionalReportMeta(meta));
          dispatch(setRegionalReportPagination(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_REGIONAL_REPORT_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_REGIONAL_REPORT_BY_ID_FAILURE,
  };
};

const getRegionalReportById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getRegionalReportLoading(true));
    await API.getRegionalReportById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          const finding = JSON.parse(_.get(result, 'finding'));
          const civilSociety = JSON.parse(_.get(result, 'civilSociety'));
          const fileReport = _.get(result, 'fileReport');
          const recommendation = JSON.parse(_.get(result, 'recommendation'));
          const payload = {
            defaultSubareas: finding.findingState,
            defaultRecomment: finding.recommendations,
            defaultOverview: civilSociety,
            defaultBackground: _.get(result, 'background'),
            defaultAcknowledgments: _.get(result, 'acknowledgment'),
            defaultRecommendations: recommendation,
            defaultFindingsRecommendations: recommendation.findings,
            defaultReportYear: _.get(result, 'year'),
            defaultReportTitle: _.get(result, 'title'),
            defaultCountryOptions: _.map(civilSociety, 'value'),
            fileReport: fileReport,
          };
          dispatch(getByIdSuccess(payload));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const activeSuccess = () => {
  return {
    type: ACTION_TYPES.ACTIVE_REGIONAL_REPORT_SUCCESS,
  };
};

const activeFail = () => {
  return {
    type: ACTION_TYPES.ACTIVE_REGIONAL_REPORT_FAILURE,
  };
};

const activeRegionalReport = (id: string, pagination: any) => {
  return async (dispatch: any) => {
    dispatch(setRegionalReportActionLoading(true));
    await API.activeRegionalReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(activeFail());
          await dispatch(fetchAllRegionalReports(pagination));
        } else {
          await dispatch(activeSuccess());
          await dispatch(fetchAllRegionalReports(pagination));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(activeFail());
      });
  };
};

const publishSuccess = () => {
  return {
    type: ACTION_TYPES.PUBLISH_REGIONAL_REPORT_SUCCESS,
  };
};

const publishFail = () => {
  return {
    type: ACTION_TYPES.PUBLISH_REGIONAL_REPORT_FAILURE,
  };
};

const publishRegionalReport = (id: string, pagination: any) => {
  return async (dispatch: any) => {
    dispatch(setRegionalReportActionLoading(true));
    await API.publishRegionalReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(publishFail());
          await dispatch(fetchAllRegionalReports(pagination));
        } else {
          dispatch(publishSuccess());
          await dispatch(fetchAllRegionalReports(pagination));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(publishFail());
      });
  };
};

const inactiveSuccess = () => {
  return {
    type: ACTION_TYPES.INACTIVE_REGIONAL_REPORT_SUCCESS,
  };
};

const inactiveFail = () => {
  return {
    type: ACTION_TYPES.INACTIVE_REGIONAL_REPORT_FAILURE,
  };
};

const inactiveRegionalReport = (id: string, pagination: any) => {
  return async (dispatch: any) => {
    dispatch(setRegionalReportActionLoading(true));
    await API.inactiveRegionalReport(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(inactiveFail());
          await dispatch(fetchAllRegionalReports(pagination));
        } else {
          await dispatch(inactiveSuccess());
          await dispatch(fetchAllRegionalReports(pagination));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(inactiveFail());
      });
  };
};

export default {
  saveDefaultSubareas,
  saveDefaultOverview,
  saveDefaultRecommendations,
  saveDefaultAckowledgments,
  saveDefaultBackground,
  saveDefaultRecomment,
  saveCountryFullOptions,
  saveCountryOptions,
  createRegionalReport,
  updateRegionalReport,
  clearDataCountryReports,
  fetchAllRegionalReports,
  getRegionalReportById,
  activeRegionalReport,
  publishRegionalReport,
  resetDataRegionalReport,
  saveDefaultFindings,
  inactiveRegionalReport,
};
