import React, { useState, useEffect, memo } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';

import Utils from '@/Utils';
import { LIST } from '@/Constants';

import { CommonColors } from '@/Themes';

import { INavAbout } from '@/Interfaces/PageManagement.interface';
import { IDropdownOption } from '@interfaces/Dropdown.interface';

const { DROPDOWN_LIST } = LIST;

const { ABOUT_MENU } = DROPDOWN_LIST;

const AboutNav: React.FC = () => {
  const { pathname } = useLocation();

  const [navAbout, setNavAbout] = useState<INavAbout | ''>('');

  useEffect(() => {
    setNavAbout(_.trimStart(pathname, '/') as INavAbout);
    return () => {
      setNavAbout('');
    };
  }, []);

  useEffect(() => {
    if (pathname && _.trimStart(pathname, '/') !== navAbout)
      Utils.redirect(navAbout);
  }, [navAbout]);

  return (
    <List>
      {_.map(ABOUT_MENU, (menuItem: IDropdownOption) => (
        <ListItem
          disablePadding
          selected={menuItem.value === navAbout}
          key={menuItem.value}
        >
          <ListItemButton
            onClick={() => {
              setNavAbout(menuItem.value as INavAbout);
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    color: CommonColors.mainColor,
                  }}
                >
                  {menuItem.label}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default memo(AboutNav);
