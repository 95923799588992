import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemText,
  Card,
  Typography,
  LinearProgress,
  Checkbox,
  CardHeader,
  Collapse,
  ListItemButton,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Button,
} from '@mui/material';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CompareIcon from '@mui/icons-material/Compare';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import DefaultLayout from '@/Components/DefaultLayout';
import { BreadCrumb } from '@/Components/LayoutPart';
import { CountryAsyncDropdown, ChartComponent } from '@/Components/Common';
import { RootState, useTypedDispatch } from '@/store';
import { CompareAndExporeActions } from '@/Actions';
import { COMPARE_AND_EXPLORE, ROUTERS } from '@constants';
import Utils from '@/Utils';
import { IMMToolkitTemplate } from '@interfaces/CountryScoring.interface';
import { IFilterOfCountry } from '@/Interfaces/CompareAndExplore.interface';
import { CommonColors } from '@/Themes';
import MandatoryField from '@/Components/LayoutPart/Dialogs/MandatoryField';

const {
  fetchCompareAndExploreByCountries,
  saveCompareAndExploreByCountries,
  resetCompareAndExploreReducer,
} = CompareAndExporeActions;

const { COMPARE_AREA_1, COMPARE_AREA_2, COMPARE_AREA_3 } = COMPARE_AND_EXPLORE;

const CompareCountry: React.FC = () => {
  const dispatch = useTypedDispatch();
  const countriesBarCharts: any =
    useSelector((state: RootState) =>
      _.get(state.COMPARE_EXPLORE, 'countriesBarCharts')
    ) || [];

  const countries: any = useSelector((state: RootState) =>
    _.get(state.COUNTRY, 'countries')
  );

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'isFetchLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'isActionLoading')
  );
  const requestHasError = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'requestHasError')
  );
  const previousFilter: IFilterOfCountry = useSelector((state: RootState) =>
    _.get(state.COMPARE_EXPLORE, 'previousCountries')
  );
  const [filterOfCountries, setFilterOfCountries] = useState<{
    listCountries: string[];
    year: number | string;
    collapsedSubAreaOfCountries: string[];
    listStandardsOfCountries: string[];
    listCountriesName: string[];
  }>({
    listCountries: [],
    year: '',
    collapsedSubAreaOfCountries: [],
    listStandardsOfCountries: [],
    listCountriesName: [],
  });
  const [message, setMessage] = useState({
    countries: '',
    year: '',
    standards: '',
  });
  const refChart = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      dispatch(resetCompareAndExploreReducer());
    };
  }, []);

  useEffect(() => {
    setFilterOfCountries(previousFilter);
    if (
      !_.some(previousFilter, (filter) =>
        _.isNumber(filter) ? _.isEmpty(_.toString(filter)) : _.isEmpty(filter)
      )
    ) {
      dispatch(
        fetchCompareAndExploreByCountries({
          year: previousFilter.year,
          listCountries: previousFilter.listCountriesName,
          listStandards: _.orderBy(previousFilter.listStandardsOfCountries),
        })
      );
    }
  }, [previousFilter]);

  useEffect(() => {
    if (
      refChart?.current &&
      !requestHasError &&
      !_.isEmpty(countriesBarCharts)
    ) {
      refChart.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [requestHasError, countriesBarCharts]);

  // Events
  const handleChangeStandardOfCountries = (code: string) => {
    if (_.includes(filterOfCountries.listStandardsOfCountries, code))
      setFilterOfCountries({
        ...filterOfCountries,
        listStandardsOfCountries: _.filter(
          filterOfCountries.listStandardsOfCountries,
          (el) => el !== code
        ),
      });
    else
      setFilterOfCountries({
        ...filterOfCountries,
        listStandardsOfCountries: [
          ...filterOfCountries.listStandardsOfCountries,
          code,
        ],
      });
  };

  const handleCollapseSubareaOfOfCountries = (code: string) => {
    if (_.includes(filterOfCountries.collapsedSubAreaOfCountries, code))
      setFilterOfCountries({
        ...filterOfCountries,
        collapsedSubAreaOfCountries: _.filter(
          filterOfCountries.collapsedSubAreaOfCountries,
          (el) => el !== code
        ),
      });
    else
      setFilterOfCountries({
        ...filterOfCountries,
        collapsedSubAreaOfCountries: [
          ...filterOfCountries.collapsedSubAreaOfCountries,
          code,
        ],
      });
  };

  const startCompareCountries = () => {
    if (
      _.isEmpty(_.toString(filterOfCountries.year)) ||
      _.isEmpty(filterOfCountries.listCountries) ||
      _.isEmpty(filterOfCountries.listStandardsOfCountries)
    ) {
      setMessage({
        year: _.isEmpty(_.toString(filterOfCountries.year))
          ? 'Please choose year'
          : '',
        countries: _.isEmpty(filterOfCountries.listCountries)
          ? 'Please choose countries'
          : '',
        standards: _.isEmpty(filterOfCountries.listStandardsOfCountries)
          ? 'Please choose list standards'
          : '',
      });
    } else {
      dispatch(saveCompareAndExploreByCountries(filterOfCountries));
      dispatch(
        fetchCompareAndExploreByCountries({
          year: filterOfCountries.year,
          listCountries: filterOfCountries.listCountriesName,
          listStandards: _.orderBy(filterOfCountries.listStandardsOfCountries),
        })
      );
    }
  };
  // Renders
  const _renderBarChart = (chart: any, key: string) => {
    return _.map(chart, (barChart: any) => {
      const xs = filterOfCountries.listCountries.length <= 4 ? 6 : 12;
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={xs}
          lg={xs}
          key={`${key}${_.get(barChart, 'label')}`}
        >
          <ChartComponent.BarChart
            title=""
            verticalText="Score (%)"
            horizontalText={_.get(barChart, 'label')}
            data={barChart?.chartData}
            subtitle={{
              display: true,
              align: 'start',
              position: 'bottom',
              text: '(*) Click on the graph to get the reports for this country',
            }}
            axis="x"
            onClick={(data) => {
              const targetCountry = _.find(
                countries,
                (country) => country?.name === data?.targetKey
              );
              Utils.redirect(ROUTERS.COUNTRY_REPORT, {
                code: {
                  area: barChart?.code?.areaCode,
                  subArea: barChart?.code?.subCode,
                  standard: barChart?.code?.standardCode,
                },
                country: targetCountry?.id,
                year: _.toString(filterOfCountries?.year),
              });
            }}
          />
        </Grid>
      );
    });
  };

  const _renderOptionsForCountries = (area: IMMToolkitTemplate) => {
    const { subAreas } = area;
    return (
      <Grid item xs={12} md={4} key={`year${area.code}`} p={2}>
        <Typography
          variant="h6"
          height={70}
        >{`${area.codeName}: ${area.name}`}</Typography>
        <List dense component="div" role="list" key={`year${area.code}`}>
          {_.map(subAreas, (subArea) => {
            const { standards } = subArea;
            const isSelected = _.includes(
              filterOfCountries.collapsedSubAreaOfCountries,
              subArea.code
            );
            return (
              <Box key={`year${subArea.code}`}>
                <ListItemButton
                  onClick={() =>
                    handleCollapseSubareaOfOfCountries(subArea.code)
                  }
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {subArea.codeName}: {subArea.name}
                      </Typography>
                    }
                  />
                  {isSelected ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse
                  in={isSelected}
                  timeout="auto"
                  unmountOnExit
                  key={`year${subArea.code}`}
                >
                  <Box key={`year${subArea.code}`} pl={2}>
                    <List component="div" disablePadding>
                      {_.map(standards, (sta) => {
                        return (
                          <Box key={`year${sta.code}box`} pl={2}>
                            <ListItemButton
                              onClick={() =>
                                handleChangeStandardOfCountries(sta.code)
                              }
                              title={sta.name}
                            >
                              <Checkbox
                                checked={_.includes(
                                  filterOfCountries.listStandardsOfCountries,
                                  sta.code
                                )}
                              />
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    <b>{sta.codeName}:</b>
                                    <br />
                                    {sta.originalName}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </Box>
                        );
                      })}
                    </List>
                  </Box>
                </Collapse>
              </Box>
            );
          })}
        </List>
      </Grid>
    );
  };

  const _renderByCountries = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <DatePicker
            value={new Date(_.toString(filterOfCountries.year))}
            onlyYearPicker
            disabled={isFetchLoading}
            onChange={(newValue: DateObject) => {
              setMessage({ ...message, year: '' });
              setFilterOfCountries({
                ...filterOfCountries,
                year: new DateObject(newValue).year,
              });
            }}
            hideYear
            minDate={new Date('2013')}
            maxDate={new Date()}
            render={(_value: any, openCalendar: any) => {
              return (
                <TextField
                  label={
                    filterOfCountries.year
                      ? `${filterOfCountries.year} is chosen`
                      : 'Choose one year'
                  }
                  disabled
                  error={!_.isEmpty(message.year)}
                  helperText={message.year}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: !_.isEmpty(message.year)
                          ? CommonColors.error
                          : 'inherit', //color error MUI
                      },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={openCalendar}>
                          <CalendarMonthIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} sm={9} lg={4}>
          <CountryAsyncDropdown
            disabled={isFetchLoading}
            defaultValue={filterOfCountries.listCountries}
            multiples
            required={!filterOfCountries.listCountries}
            getFor="ALL"
            label="Choose countries to compare"
            onChange={(ids: string[], labels: string[]) => {
              setMessage({ ...message, countries: '' });
              setFilterOfCountries({
                ...filterOfCountries,
                listCountries: ids,
                listCountriesName: labels,
              });
            }}
            message={message.countries}
          />
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="large"
              sx={{ height: 56 }}
              variant="outlined"
              disabled={isActionLoading}
              onClick={() => {
                setMessage({
                  year: '',
                  countries: '',
                  standards: '',
                });
                dispatch(resetCompareAndExploreReducer(true));
                setFilterOfCountries({
                  listCountries: [],
                  year: '',
                  collapsedSubAreaOfCountries: [],
                  listStandardsOfCountries: [],
                  listCountriesName: [],
                });
              }}
            >
              Clear All
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              ':hover': {
                transform: 'none',
              },
            }}
          >
            <CardHeader
              sx={{ px: 2, py: 1 }}
              title="Choose standards"
              subheader={`${
                filterOfCountries.listStandardsOfCountries.length
              }/${24} standards selected`}
              action={
                <LoadingButton
                  loading={isFetchLoading}
                  variant="contained"
                  color="burntSienna"
                  startIcon={<CompareIcon />}
                  onClick={() => startCompareCountries()}
                  disabled={
                    filterOfCountries.listStandardsOfCountries.length === 0
                  }
                >
                  Start Compare (
                  {filterOfCountries.listStandardsOfCountries.length})
                </LoadingButton>
              }
            />
            <Divider />
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {_renderOptionsForCountries(COMPARE_AREA_1)}
              {_renderOptionsForCountries(COMPARE_AREA_2)}
              {_renderOptionsForCountries(COMPARE_AREA_3)}
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} ref={refChart}>
          {isFetchLoading && <LinearProgress />}
          <Grid container spacing={2}>
            {_renderBarChart(countriesBarCharts, 'countries')}
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    countriesBarCharts,
    filterOfCountries,
    isFetchLoading,
    previousFilter,
    message,
  ]);

  const renderMain = () => {
    return (
      <Grid container pb={2}>
        <MandatoryField
          messages={[message.standards]}
          isOpen={!!message.standards}
          title="Mandatory fields"
          onCancel={() => setMessage({ ...message, standards: '' })}
        />
        <Grid item xs={12}>
          <BreadCrumb title="Compare Across Countries" />
        </Grid>
        <Grid item xs={12} mt={2}>
          {_renderByCountries}
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="USER" content={renderMain()} />;
};

export default CompareCountry;
