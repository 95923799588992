import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  IconButton,
  Card,
  CardHeader,
  Avatar,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import { sideBarStyles, Drawer, DrawerHeader } from './Sidebar.styles';
import { CommonStyles } from '@/Components/Common';
import NoAvatar from '@/Assets/NoAvatar/NoAvatar.png';

import { LIST, ROUTERS } from '@constants';
import { ISideBar } from '@interfaces/Menu.interface';
import Utils from '@utils';
import { CommonColors } from '@/Themes';
import { useTypedDispatch } from '@/store';
import { AuthActions } from '@/Actions';

const { REVIEWER_MENU, ADMIN_MENU } = LIST.MENU_LIST;

const { logout } = AuthActions;

interface ISectionProps {
  useFor?: 'ADMIN' | 'REVIEWER';
}

const SideBarComponent: React.FC<ISectionProps> = (props: ISectionProps) => {
  const dispatch = useTypedDispatch();
  // Constructors
  const { useFor } = props;
  const path = useLocation().pathname;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openItems, setOpenItems] = useState<string[]>([]);
  const userData = Utils.getSavedUserData();
  const fullname = Utils.getFullName();
  const email = Utils.getSavedUserEmail();
  const menu = useFor === 'REVIEWER' ? REVIEWER_MENU : ADMIN_MENU;

  // Events
  const handleCollapse = () => {
    if (!isCollapsed) {
      setOpenItems([]);
    }
    setIsCollapsed(!isCollapsed);
  };

  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  // Renders
  const _renderSubOptions = (items: ISideBar[], key: number) => {
    return _.map(items, (item) => {
      const selected = item.value === path || _.includes(path, item.value);
      return (
        <ListItemButton
          sx={{ pl: 4, ...CommonStyles.hoverBorderEffect }}
          key={`${key}${item.label}`}
          selected={selected}
          onClick={() => Utils.redirect(item.value)}
        >
          <ListItemText primary={item.label} />
        </ListItemButton>
      );
    });
  };

  const _renderParentItems = () => {
    return _.map(menu, (item, index) => {
      const { subOptions, label, value, icon } = item;
      const isOpenSub =
        _.includes(openItems, value) || _.find(subOptions, ['value', path]);
      return (
        <Box key={index}>
          <ListItemButton
            key={index}
            selected={value === path}
            onClick={() => {
              if (isCollapsed) setIsCollapsed(false);
              else if (subOptions) handleOpenItems(value);
              else {
                if (value === ROUTERS.AUTH) dispatch(logout());
                else Utils.redirect(value);
              }
            }}
            sx={{
              ...CommonStyles.hoverBorderEffect,
              justifyContent: !isCollapsed ? 'flex-end' : 'center',
              '.MuiListItemIcon-root': {
                minWidth: !isCollapsed ? '56px' : 'auto',
              },
            }}
          >
            <ListItemIcon sx={{ color: 'white' }}>{icon}</ListItemIcon>
            {!isCollapsed && <ListItemText primary={label} />}
            {subOptions &&
              !isCollapsed &&
              (isOpenSub ? <ExpandMore /> : <ChevronRightIcon />)}
          </ListItemButton>
          {subOptions && (
            <Collapse
              in={isOpenSub && !isCollapsed}
              timeout="auto"
              unmountOnExit
              key={label}
            >
              <List component="div">
                {_renderSubOptions(subOptions, index)}
              </List>
            </Collapse>
          )}
          <Divider />
        </Box>
      );
    });
  };

  const _renderSidebar = () => {
    return (
      <List sx={{ width: 1, pb: 0 }} component="nav">
        {_renderParentItems()}
      </List>
    );
  };

  return (
    <Drawer
      variant="permanent"
      open={!isCollapsed}
      PaperProps={{
        sx: { background: CommonColors.mainBackground, color: 'white' },
      }}
    >
      <DrawerHeader>
        <Card
          sx={{
            width: 1,
            background: CommonColors.mainBackground,
            color: 'white',
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                alt="userAvatar"
                src={userData?.avatar?.path || NoAvatar}
                sx={{ cursor: 'pointer', mr: '1px' }}
                onClick={() => handleCollapse()}
              />
            }
            title={fullname || 'Anonymous'}
            subheader={email || 'abc@gmail.com'}
            subheaderTypographyProps={{ sx: { color: 'white' } }}
            action={
              <IconButton
                sx={{ color: 'white' }}
                onClick={() => handleCollapse()}
              >
                <MenuOpenIcon />
              </IconButton>
            }
          />
        </Card>
      </DrawerHeader>
      <DrawerHeader>
        <Grid container direction="column" sx={sideBarStyles}>
          <Grid item>{_renderSidebar()}</Grid>
        </Grid>
      </DrawerHeader>
    </Drawer>
  );
};

export default SideBarComponent;
