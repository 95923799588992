import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';

import { Box, MenuItem, TextField, Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DefaultLayout from '@/Components/DefaultLayout';
import { ActiveBreadCrumb } from '@/Components/Common';
import { DEFAULT_PAGINATION, LIST, ROUTERS } from '@/Constants';
import { useTypedDispatch } from '@/store';
import { CountryReportsActions } from '@actions';
import Utils from '@/Utils';
import { DataTable } from '@/Components/LayoutPart';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';

const { fetchCountryReports } = CountryReportsActions;
const { DROPDOWN_LIST } = LIST;
const { COUNTRY_REPORT_STATUS_LIST } = DROPDOWN_LIST;

const DEFAULT_FILTER = {
  ...DEFAULT_PAGINATION,
  sortBy: 'year',
  orderBy: 'DESC',
};

const MyReports: React.FC = () => {
  const dispatch = useTypedDispatch();
  const [filter, setFilter] = useState<IPaginationFilter>(DEFAULT_FILTER);
  const [isValidDateMsg, setIsValidDateMsg] = useState<string>('');

  useEffect(() => {
    if (filter) {
      const resolveStatus = filter?.status === 'all' ? '' : filter?.status;
      dispatch(
        fetchCountryReports({
          ...filter,
          status: resolveStatus,
        })
      );
    }
  }, [filter]);

  const handleChangeYear = (newValue: Date | null) => {
    if (!_.isEmpty(newValue)) {
      const yearSelect = Utils.getFormatDate(newValue, 'YYYY');
      if (!dayjs(newValue).isBefore(dayjs()) || +yearSelect < 1900)
        setIsValidDateMsg('Please choose year valid');
      else {
        setIsValidDateMsg('');
        setFilter({
          ...filter,
          year: yearSelect,
        });
      }
    } else {
      setIsValidDateMsg('');
      setFilter(_.omit(filter, ['year']));
    }
  };

  const _renderTopSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <DatePicker
            inputFormat="YYYY"
            views={['year']}
            label="Year"
            value={filter?.year || null}
            onChange={handleChangeYear}
            minDate={new Date('2013')}
            maxDate={new Date()}
            renderInput={(params: any) => (
              <TextField
                {...params}
                sx={{ width: 180 }}
                error={!!isValidDateMsg}
                helperText={isValidDateMsg}
              />
            )}
          />
          <TextField
            select
            sx={{ width: 200 }}
            label="Status"
            value={filter?.status || 'all'}
            defaultValue=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilter({
                ...filter,
                status: e.target.value,
              })
            }
          >
            {_.map(COUNTRY_REPORT_STATUS_LIST, (status, index) => {
              return (
                <MenuItem key={index} value={status.value}>
                  {status.label}
                </MenuItem>
              );
            })}
          </TextField>
          <Button
            size="large"
            variant="outlined"
            onClick={() => setFilter(DEFAULT_FILTER)}
            sx={{ height: 56 }}
          >
            Clear Filters
          </Button>
        </Box>
        <Box>
          <Button
            size="large"
            variant="contained"
            color="burntSienna"
            onClick={() => Utils.redirect(ROUTERS.NEW_COUNTRY_REPORT)}
          >
            New Report
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderMain = () => {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item xs={12}>
          <ActiveBreadCrumb screen="UP_COUNTRY_REPORT" />
        </Grid>
        <Grid item xs={12}>
          {_renderTopSection()}
        </Grid>
        <Grid item xs={12}>
          <DataTable.UserCountryReportTable />
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout content={_renderMain()} />;
};

export default MyReports;
