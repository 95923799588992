import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Grid,
  TextField,
  Button,
  SxProps,
  Theme,
  CircularProgress,
  Box,
  MenuItem,
  Chip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import DefaultLayout from '@/Components/DefaultLayout';

import { LIST, ROUTERS } from '@/Constants';
import Utils from '@/Utils';
import {
  ActiveBreadCrumb,
  CountryAsyncDropdown,
  TextEditor,
} from '@/Components/Common';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { CountryActions, NewsManagementActions } from '@/Actions';
import { useTypedDispatch, RootState } from '@/store';
import { IUpdatePost } from '@/Interfaces/Post.interface';
import { Dialogs } from '@/Components/LayoutPart';
import { INewsList } from '@/Interfaces/News.interface';
import { CommonColors } from '@/Themes';

const styleSheet: SxProps<Theme> = {
  '& .ql-editor': {
    border: '1px solid #f1f1f1',
    padding: '20px',
    maxHeight: '500px',
    minHeight: '350px',
  },
};

const DEFAULT_NEWS_MANAGEMENT: IUpdatePost = {
  id: '',
  title: '',
  content: '',
  image: '',
  status: '',
};

const { fetchAllCountries } = CountryActions;
const { updateNews, getNewsById } = NewsManagementActions;
const { DROPDOWN_LIST } = LIST;

const UpdateNews = () => {
  const locationState = useLocation().state;
  const dispatch = useTypedDispatch();
  const [openComment, setOpenComment] = useState(false);
  const [renderKey, setRenderkey] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [tagMessage, setTagMessage] = useState<string>('');
  const [isMessage, setIsMessage] = useState<string>('');
  const [countryState, setCountryState] = useState<string>('');

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'isGetLoading')
  );
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'isActionLoading')
  );
  const newsDetail: INewsList = useSelector((state: RootState) =>
    _.get(state.NEWS_MANAGEMENT, 'newsDetail')
  );

  const [newsData, setNewsData] = useState<IUpdatePost>(
    DEFAULT_NEWS_MANAGEMENT
  );

  useEffect(() => {
    if (locationState && locationState?.id) {
      dispatch(getNewsById(locationState.id));
    } else Utils.redirect(ROUTERS.NEWS_MANAGEMENT);
  }, [locationState]);

  useEffect(() => {
    dispatch(fetchAllCountries(true));
  }, []);

  useEffect(() => {
    if (newsDetail) {
      setNewsData(newsDetail);
      setRenderkey(Math.random());
      if (newsDetail?.tag) {
        const listSelectedTag = newsDetail.tag.split(';');
        setSelectedItem(listSelectedTag);
      }
      if (newsDetail.country) {
        setCountryState(newsDetail.country.id);
      }
    }
  }, [newsDetail]);

  const handleOnChange = (key: string, value: string) => {
    setNewsData({ ...newsData, [key]: value });
    setIsMessage('');
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const valueInput = inputValue.replaceAll(';', '').trim();
      if (valueInput) {
        setSelectedItem([...selectedItem, valueInput]);
        setInputValue('');
        if (tagMessage) setTagMessage('');
      }
    }
    if (event.key === 'Backspace' && !inputValue) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  };

  const handleDeleteTag = (index: number) => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(index, 1);
    setSelectedItem(newSelectedItem);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (_.isEmpty(selectedItem)) {
      setTagMessage(
        'Please add tag to the new by enter text and pressing the enter key'
      );
    } else {
      const tagResolve = selectedItem.join(';');
      setInputValue('');
      if (_.trim(newsData?.content)) {
        if (tagResolve) formData.append('tag', _.trim(tagResolve));
        if (newsData.category) formData.append('category', newsData.category);
        if (_.trim(newsData?.content))
          formData.append('content', _.trim(newsData?.content));
        if (newsData.source) formData.append('source', newsData.source);
        if (countryState) formData.append('country', countryState);
        if (newsData.title) formData.append('title', newsData.title);
        dispatch(updateNews(newsData.id, formData));
      } else setIsMessage('Content cannot be left blank!');
    }
  };

  const handleOpenComment = () => {
    setOpenComment(true);
  };

  const handleCloseComment = () => {
    setOpenComment(false);
  };

  const _renderTitle = () => {
    return (
      <Grid item sx={{ mb: 3 }} xs={12}>
        <ActiveBreadCrumb screen="NEWS_MANAGEMENT" />
      </Grid>
    );
  };

  const _renderCategory = useMemo(() => {
    return (
      <TextField
        select
        fullWidth
        label="Category"
        required
        value={newsData?.category || 'Uncategorized'}
        disabled={isGetLoading || isActionLoading}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChange('category', e.target.value)
        }
      >
        {_.map(DROPDOWN_LIST.NEWS_CATEGORY_LIST, (category, index) => {
          return (
            <MenuItem key={index} value={category.value}>
              <Box component="div">{category.label}</Box>
            </MenuItem>
          );
        })}
      </TextField>
    );
  }, [newsData]);

  const _renderBody = () => {
    return (
      <Grid item xs={12}>
        <Grid container sx={styleSheet}>
          <Grid item mb={8} xs={12}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <TextField
                  defaultValue={newsData?.title || ''}
                  required
                  key={`title${Math.random()}`}
                  fullWidth
                  disabled={isGetLoading || isActionLoading}
                  label="Title"
                  name="title"
                  onBlur={(e) => handleOnChange('title', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {_renderCategory}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      defaultValue={newsData?.source || ''}
                      disabled={isGetLoading || isActionLoading}
                      key={renderKey}
                      required
                      fullWidth
                      label="Source"
                      name="source"
                      onChange={(e) => handleOnChange('source', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={inputValue}
                      error={!!tagMessage}
                      helperText={
                        tagMessage ||
                        'Add tag by enter text and pressing the enter key'
                      }
                      sx={{
                        '& .MuiInputBase-fullWidth.MuiInputBase-formControl': {
                          display: 'flex',
                          flexWrap: 'wrap',
                        },
                        input: {
                          flex: 1,
                        },
                      }}
                      InputProps={{
                        startAdornment:
                          selectedItem &&
                          selectedItem.map((item: string, index: number) => (
                            <Chip
                              key={item + index}
                              sx={{ mr: 1, my: 1 }}
                              label={item}
                              onDelete={() => handleDeleteTag(index)}
                            />
                          )),
                      }}
                      fullWidth
                      label="Tag *"
                      name="tag"
                      disabled={isActionLoading}
                      onKeyDown={handleKeyDown}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                        setTagMessage('');
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CountryAsyncDropdown
                      getForNews
                      defaultValue={countryState}
                      label="Countries"
                      onChange={(ids) => {
                        setCountryState(ids);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {newsData?.status === 'publish' && (
                  <Typography>
                    <AcUnitIcon
                      sx={{
                        color: CommonColors.mainColor,
                        fontSize: 14,
                        mr: 1,
                      }}
                    />
                    Published: {Utils.getFormatDate(newsData?.publishAt)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextEditor
                  message={isMessage}
                  disabled={isGetLoading || isActionLoading}
                  value={newsData?.content}
                  onChange={(newContent: string) =>
                    handleOnChange('content', newContent)
                  }
                  key={renderKey}
                />
                {isMessage && (
                  <Typography ml={2} fontSize="12px" color="error">
                    {isMessage}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} mt={2} justifyContent="end">
              <Grid item>
                <Button
                  variant="contained"
                  color="fuzzyWuzzyBrown"
                  onClick={() => Utils.redirect(ROUTERS.NEWS_MANAGEMENT)}
                  disabled={isGetLoading || isActionLoading}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="bismark"
                  onClick={handleOpenComment}
                  disabled={
                    isGetLoading ||
                    isActionLoading ||
                    newsDetail?.comment?.length <= 0
                  }
                >
                  Comments
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="oceanGreen"
                  loading={isGetLoading || isActionLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderComment = () => {
    return (
      <Grid item>
        <Dialogs.DialogsCommment
          open={openComment}
          onClose={handleCloseComment}
          locationState={locationState?.id}
        />
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          {_renderTitle()}
          <Grid item xs={12}>
            {isGetLoading ? (
              <Grid container>
                <Grid item xs={12} textAlign="center">
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid container>{_renderBody()}</Grid>
            )}
          </Grid>
          {_renderComment()}
        </Grid>
      </form>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default UpdateNews;
