import ChangePassword from './ChangePassword';
import DialogsCommment from './Comment';
import Confirm from './Confirm';
import DialogMessages from './Messages';
import MandatoryField from './MandatoryField';
import Media from './Media';

export default {
  ChangePassword,
  DialogsCommment,
  Confirm,
  DialogMessages,
  MandatoryField,
  Media,
};
