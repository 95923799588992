import React, { useState, useEffect } from 'react';
import TreeSelect from 'mui-tree-select';
import { TextField, SxProps } from '@mui/material';
import _ from 'lodash';

import { ITreeSelectCountryStandard } from '@interfaces/TreeSelect.interface';
import { IDropdownArea } from '@interfaces/Dropdown.interface';
import { LIST } from '@/Constants';

interface ISectionProps {
  onChange?(
    value: string | number,
    parent?: { [key: string]: string } | null
  ): void;
  listOf?: 'COUNTRY_REPORT' | 'REGIONAL_REPORT';
  sx?: SxProps;
  disabled?: boolean;
  helperText?: string;
  defaultValue?: string;
}

const { COUNTRY_REPORT_LIST, REGIONAL_REPORT_LIST } = LIST.DROPDOWN_LIST;

const TreeDropdown: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const {
    listOf = 'COUNTRY_REPORT',
    onChange,
    sx,
    disabled,
    helperText,
    defaultValue = '',
  } = props;
  const [labelState, setLabelState] = useState('');
  const [optionState, setOptionState] = useState<IDropdownArea[]>([]);
  const [renderKey, setRenderKey] = useState(Math.random());

  const _getOptions = (): IDropdownArea[] => {
    switch (listOf) {
      case 'COUNTRY_REPORT':
        return COUNTRY_REPORT_LIST;
      case 'REGIONAL_REPORT':
        return REGIONAL_REPORT_LIST;
      default:
        return [];
    }
  };

  useEffect(() => {
    setOptionState(_getOptions());
  }, []);

  useEffect(() => {
    if (defaultValue) setRenderKey(Math.random());
  }, [defaultValue]);

  // Events
  const handleOnChange = (option: any) => {
    if (!option) setLabelState('');
    let newLabel = '';
    let parent: { [key: string]: string } | null = null;
    _.forEach(optionState, (area) => {
      _.forEach(area.subAreas, (sub) => {
        if (_.has(sub, 'standards')) {
          _.forEach(sub.standards, (sta) => {
            if (sta.value === option?.value?.value) {
              newLabel = `${area.label} > ${sub.label} > ${sta.label}`;
              parent = {
                level1: area.value,
                level2: sub.value,
              };
            }
          });
        } else {
          if (sub.value === option?.value?.value) {
            newLabel = `${area.label} > ${sub.label}`;
            parent = {
              level1: area.value,
            };
          }
        }
      });
    });
    if (newLabel) setLabelState(newLabel);
    if (onChange) onChange(option?.value?.value, parent);
  };

  const getNode = (node: any) => {
    if (node) return node.getChildren();
    return optionState.map(
      (area) => new ITreeSelectCountryStandard(area, optionState)
    );
  };

  const getDefaultValue = () => {
    if (!defaultValue) return null;
    let result: any = null;
    _.forEach(optionState, (area) => {
      _.forEach(area.subAreas, (sub) => {
        if (_.has(sub, 'standards')) {
          _.forEach(sub.standards, (sta) => {
            if (sta.value === defaultValue) result = sta;
          });
        } else {
          if (sub.value === defaultValue) result = sub;
        }
      });
    });
    return result;
  };

  return (
    <TreeSelect
      key={renderKey}
      sx={sx}
      getChildren={(node: any) => getNode(node)}
      getParent={(node: any) => node.getParent()}
      isBranch={(node: any) => node.isBranch()}
      defaultValue={
        getDefaultValue()
          ? new ITreeSelectCountryStandard(getDefaultValue(), optionState)
          : getDefaultValue()
      }
      disabled={disabled}
      isOptionEqualToValue={(option, v) => option.isEqual(v)}
      renderInput={(params) => (
        <TextField {...params} label={labelState} helperText={helperText} />
      )}
      onChange={(_el, op) => handleOnChange(op)}
    />
  );
};

export default TreeDropdown;
