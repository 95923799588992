export default {
  submit: 'Submit',
  cancel: 'Cancel ',
  clear: 'Clear',
  save: 'Save',
  reset: 'Reset',
  ok: 'Ok',
  close: 'Close',
  back: 'Back',
  loadMore: 'Load more',
  changePassword: 'Change Password',
  userRegistrationForm: 'User Resgistration Form',
  upload: 'Upload',
  export: 'Export',
  viewAll: 'View All',
  users: 'Users',

  //Pitching Event User
  sendForInfo: 'Send for modification',
  rejectRequest: 'Reject request',
  confirmRequest: 'Confirm request',
};
